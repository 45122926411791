import * as Apollo from '@apollo/client'
import { gql } from '@apollo/client'
import {
  AggregateLienWaiverStatus,
  AgingAmountType,
  AgingIntervalType,
  AuthProviderType,
  BillingType,
  CacheType,
  CashForecastPeriodType,
  ChangeOrderRequestEventType,
  ChangeOrderRequestStatus,
  CollectionsNotificationAnchor,
  CollectionsNotificationGranularity,
  CollectionsNotificationModifier,
  CollectionsNotificationType,
  CollectionsTaskStatus,
  CompanyListSortCriteria,
  CompanyMfaRequirement,
  CompanyProjectRole,
  CompanyType,
  CompanyUserRole,
  ComplianceStatus,
  ContractCommentType,
  ContractEventType,
  ContractListContractStatusFilter,
  ContractListPayAppStatusFilter,
  ContractListSortCriteria,
  ContractListSubmitViaFilter,
  ContractMonthlyStatusFilter,
  ContractPaymentTermsType,
  ContractStatus,
  ContractUserRole,
  DashboardBillingStatus,
  DocumentExpirationFrequency,
  DocumentRequestStatus,
  DocumentType,
  DueToType,
  EmailType,
  ForecastCurve,
  FormTemplateAnnotationImageType,
  FormTemplateAnnotationMetadataFieldType,
  FormTemplateAnnotationType,
  FormTemplateFont,
  FormTemplateOnboardingSignatureType,
  FormTemplateStatus,
  FormTemplateTag,
  FormTemplateType,
  FormWorkerRole,
  ImportIntegrationChangeOrdersMethod,
  ImportIntegrationComboJobMethod,
  ImportIntegrationProjectNameSource,
  ImportOperationStatus,
  IntegrationProjectsFilterField,
  IntegrationSyncResultCode,
  IntegrationType,
  LienWaiverCategory,
  LienWaiverType,
  LineItemType,
  NotaryTag,
  NotificationType,
  PaginatedListSortOrder,
  PayAppCollectionsNotificationStatus,
  PayAppCommentType,
  PayAppEventType,
  PayAppListSortCriteria,
  PayAppRequirementCondition,
  PayAppStatus,
  PayAppStatusFilter,
  PaymentDateEstimationType,
  Permission,
  PhysicalSignatureRequired,
  PreSitelinePayAppStatus,
  ProjectOnboardingFormType,
  RateTableStatus,
  RequiredFieldForForms,
  RetentionTrackingLevel,
  Sage100InvoiceTaxType,
  SearchResultType,
  SignatureAnnotationType,
  SignatureFont,
  SovLineItemProgressEventType,
  StoredFileType,
  StoredMaterialsCarryoverType,
  TaxCalculationType,
  TemplateListSortCriteria,
  TemplateListStatusFilter,
  TextAlignment,
  Tutorial,
  UserStatus,
  VendorComplianceStatus,
  VendorContractCommitmentType,
  VendorLienWaiverReminderFrequency,
  VendorListSortCriteria,
  WriteSyncOperationsSortCriteria,
  WriteSyncOperationStatus,
} from 'siteline-common-all'
import {
  Boolean,
  DateTime,
  Decimal,
  EmailAddress,
  Float,
  ID,
  Int,
  JSONObject,
  LatLng,
  PhoneNumber,
  SafeInt,
  String,
  Upload,
  URL,
} from 'siteline-common-web/src/scalars.js'
export type Maybe<T> = T | null
export type InputMaybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never
}
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: ID['input']; output: ID['output'] }
  String: { input: String['input']; output: String['output'] }
  Boolean: { input: Boolean['input']; output: Boolean['output'] }
  Int: { input: Int['input']; output: Int['output'] }
  Float: { input: Float['input']; output: Float['output'] }
  DateTime: { input: DateTime['input']; output: DateTime['output'] }
  Decimal: { input: Decimal['input']; output: Decimal['output'] }
  EmailAddress: { input: EmailAddress['input']; output: EmailAddress['output'] }
  JSONObject: { input: JSONObject['input']; output: JSONObject['output'] }
  LatLng: { input: LatLng['input']; output: LatLng['output'] }
  PhoneNumber: { input: PhoneNumber['input']; output: PhoneNumber['output'] }
  SafeInt: { input: SafeInt['input']; output: SafeInt['output'] }
  URL: { input: URL['input']; output: URL['output'] }
  Upload: { input: Upload['input']; output: Upload['output'] }
}

export type AbandonedPayAppsData = {
  readonly __typename: 'AbandonedPayAppsData'
  readonly abandonedPayAppsCount: Scalars['SafeInt']['output']
  readonly abandonedPayAppsIds: ReadonlyArray<Scalars['ID']['output']>
  readonly companyCreatedAt: Scalars['DateTime']['output']
  readonly companyId: Scalars['ID']['output']
  readonly companyName: Scalars['String']['output']
  readonly sitelinePointOfContactId: Maybe<Scalars['ID']['output']>
  readonly sitelinePointOfContactName: Maybe<Scalars['String']['output']>
}

export type AddCompanyIntegrationInput = {
  readonly companyId: Scalars['ID']['input']
  readonly importChangeOrdersMethod: ImportIntegrationChangeOrdersMethod
  readonly importComboJobMethod: ImportIntegrationComboJobMethod
  readonly importProjectNameSource: ImportIntegrationProjectNameSource
  readonly label?: InputMaybe<Scalars['String']['input']>
  readonly locationId?: InputMaybe<Scalars['ID']['input']>
  readonly metadata?: InputMaybe<Scalars['JSONObject']['input']>
  readonly revertPayAppsToDraftWhenTexturaInvoiceRejected: Scalars['Boolean']['input']
  readonly shouldImportNewProjectSov: Scalars['Boolean']['input']
  readonly type: IntegrationType
}

export type AddFormTemplateCommentInput = {
  readonly formTemplateId: Scalars['ID']['input']
  readonly message: Scalars['String']['input']
}

export type AddIntegrationInput = {
  readonly companyIntegrationId: Scalars['ID']['input']
  readonly contractId: Scalars['ID']['input']
}

export type AgaveDebuggerSessionUrlInput = {
  readonly companyIntegrationId: Scalars['ID']['input']
}

export type AggregateBillingForecast = {
  readonly __typename: 'AggregateBillingForecast'
  readonly forecastByMonth: ReadonlyArray<AggregateForecastMonth>
  readonly numContractsWithForecast: Scalars['SafeInt']['output']
  readonly projectedAmountThisMonth: Scalars['SafeInt']['output']
}

export type AggregateCashForecast = {
  readonly __typename: 'AggregateCashForecast'
  /** The amount that has been billed, not yet paid, and is predicted to be paid in the current period */
  readonly amountProjectedThisPeriod: Scalars['SafeInt']['output']
  readonly forecast: ReadonlyArray<CashForecastPeriod>
  /** The total amount outstanding across all periods */
  readonly totalOpenInvoiceAmount: Scalars['SafeInt']['output']
  /** Same as `totalUnknownPredictedInvoiceAmount`, but excluding pre-Siteline pay apps */
  readonly totalPayAppUnknownPredictedInvoiceAmount: Scalars['SafeInt']['output']
  /** The total amount outstanding in pay apps with an unknown predicted payment date. */
  readonly totalUnknownPredictedInvoiceAmount: Scalars['SafeInt']['output']
}

export type AggregateChangeOrderData = {
  readonly __typename: 'AggregateChangeOrderData'
  readonly totalAmountApproved: Scalars['SafeInt']['output']
  readonly totalAmountAuthorizedToProceed: Scalars['SafeInt']['output']
  readonly totalAmountProceedingWithWork: Scalars['SafeInt']['output']
  readonly totalAmountUnapproved: Scalars['SafeInt']['output']
  readonly totalOriginalContractAmount: Scalars['SafeInt']['output']
  readonly totalRevisedContractAmount: Scalars['SafeInt']['output']
}

export type AggregateChangeOrderDataByContract = {
  readonly __typename: 'AggregateChangeOrderDataByContract'
  readonly aggregateChangeOrderData: AggregateChangeOrderData
  readonly contract: Contract
}

export type AggregateForecastMonth = {
  readonly __typename: 'AggregateForecastMonth'
  readonly amountBilled: Maybe<Scalars['SafeInt']['output']>
  /** Zero-indexed month, from 0 to 11 */
  readonly month: Scalars['SafeInt']['output']
  readonly projectedAmount: Maybe<Scalars['SafeInt']['output']>
  /** Year in YYYY format */
  readonly year: Scalars['SafeInt']['output']
}

export { AggregateLienWaiverStatus }

export { AgingAmountType }

export type AgingBreakdown = {
  readonly __typename: 'AgingBreakdown'
  readonly amountAged30Days: Scalars['SafeInt']['output']
  readonly amountAged60Days: Scalars['SafeInt']['output']
  readonly amountAged90Days: Scalars['SafeInt']['output']
  readonly amountAged120Days: Scalars['SafeInt']['output']
  readonly amountAgedCurrent: Scalars['SafeInt']['output']
  readonly amountAgedTotal: Scalars['SafeInt']['output']
  /** Total amount aged, but only for pay apps which payment is overdue */
  readonly amountAgedTotalOverdueOnly: Scalars['SafeInt']['output']
  readonly hasPayAppsAgedOver120Days: Scalars['Boolean']['output']
  readonly numAged30Days: Scalars['SafeInt']['output']
  readonly numAged60Days: Scalars['SafeInt']['output']
  readonly numAged90Days: Scalars['SafeInt']['output']
  readonly numAged120Days: Scalars['SafeInt']['output']
  readonly numCurrent: Scalars['SafeInt']['output']
}

export { AgingIntervalType }

/**
 * A line item that only contains an amount and a fee.
 * Used for Quick Billing only.
 * Current billed = amount * (1 + feePercent)
 * Eg: "Campbell #A1245, amount $12,345, fee 10%"
 * Proof of material cost is stored in item attachments.
 */
export type AmountLineItem = {
  readonly __typename: 'AmountLineItem'
  /** Current amount billed for this line item */
  readonly amount: Scalars['SafeInt']['output']
  /** Amount due, post tax. This is amountDuePreTax + amountDueTaxAmount */
  readonly amountDuePostTax: Scalars['SafeInt']['output']
  /** Amount + fee. This is pre-tax. */
  readonly amountDuePreTax: Scalars['SafeInt']['output']
  /** Tax amount on the amount due. Tax rate is available on `taxGroup` */
  readonly amountDueTaxAmount: Scalars['SafeInt']['output']
  /**
   * Pay-app attachments that act as backup for this line item.
   * Typically this would be a vendor invoice.
   */
  readonly attachments: ReadonlyArray<PayAppAttachment>
  readonly createdAt: Scalars['DateTime']['output']
  readonly description: Scalars['String']['output']
  /**
   * Fee percentage to apply on top of `amount`.
   * For MATERIALS line item types, this is initialized to `contract.materialFeePercent`
   * but it can be adjusted manually.
   */
  readonly feePercent: Scalars['Float']['output']
  readonly id: Scalars['ID']['output']
  readonly payApp: PayApp
  /** Order in which to display the amount line items. First line item starts at 1. */
  readonly sortOrder: Scalars['SafeInt']['output']
  /** Optional tax rate to apply to the line item. It must be a tax group from the subcontractor company. */
  readonly taxGroup: Maybe<TaxGroup>
  /**
   * Pinned tax rate applied which is set to the tax group's tax percent at signing time.
   * If it is not set, we fall back to the tax group's tax percent (or 0 if it is not set).
   * Value is between 0 and 1.
   */
  readonly taxPercent: Maybe<Scalars['Float']['output']>
  readonly type: LineItemType
  readonly updatedAt: Scalars['DateTime']['output']
}

/**
 * Describes how to override the field of a template annotation.
 * This belongs to a template variant and only affects contracts that use this variant.
 */
export type AnnotationOverride = {
  readonly __typename: 'AnnotationOverride'
  readonly annotationPermanentId: Scalars['ID']['output']
  readonly copyDefaultValueFromPreviousAnnotationValue: Maybe<Scalars['Boolean']['output']>
  readonly createdAt: Scalars['DateTime']['output']
  readonly defaultValueKey: Maybe<Scalars['String']['output']>
  readonly doNotRetainOnReset: Maybe<Scalars['Boolean']['output']>
  readonly fieldType: Maybe<FormTemplateAnnotationMetadataFieldType>
  readonly fontColor: Maybe<Scalars['String']['output']>
  readonly fontFamily: Maybe<FormTemplateFont>
  readonly id: Scalars['ID']['output']
  readonly imageType: Maybe<FormTemplateAnnotationImageType>
  readonly isOptional: Maybe<Scalars['Boolean']['output']>
  readonly prefix: Maybe<Scalars['String']['output']>
  readonly selectedKey: Maybe<Scalars['String']['output']>
  readonly signatureType: Maybe<SignatureAnnotationType>
  readonly suffix: Maybe<Scalars['String']['output']>
  readonly syncTag: Maybe<Scalars['String']['output']>
  readonly textAlignment: Maybe<TextAlignment>
  readonly updatedAt: Scalars['DateTime']['output']
  readonly userVisibleName: Maybe<Scalars['String']['output']>
  readonly variant: FormTemplateVariant
  readonly wrapText: Maybe<Scalars['Boolean']['output']>
}

export type ApplyAnnotationOverrideInput = {
  readonly annotationPermanentId: Scalars['ID']['input']
  readonly copyDefaultValueFromPreviousAnnotationValue?: InputMaybe<Scalars['Boolean']['input']>
  readonly defaultValueKey?: InputMaybe<Scalars['String']['input']>
  readonly doNotRetainOnReset?: InputMaybe<Scalars['Boolean']['input']>
  readonly fieldType?: InputMaybe<FormTemplateAnnotationMetadataFieldType>
  readonly fontColor?: InputMaybe<Scalars['String']['input']>
  readonly fontFamily?: InputMaybe<FormTemplateFont>
  readonly imageType?: InputMaybe<FormTemplateAnnotationImageType>
  readonly isOptional?: InputMaybe<Scalars['Boolean']['input']>
  readonly prefix?: InputMaybe<Scalars['String']['input']>
  readonly selectedKey?: InputMaybe<Scalars['String']['input']>
  readonly signatureType?: InputMaybe<SignatureAnnotationType>
  readonly suffix?: InputMaybe<Scalars['String']['input']>
  readonly syncTag?: InputMaybe<Scalars['String']['input']>
  readonly textAlignment?: InputMaybe<TextAlignment>
  /** Undefined to ignore, null to unset */
  readonly userVisibleName?: InputMaybe<Scalars['String']['input']>
  readonly variantId: Scalars['ID']['input']
  readonly wrapText?: InputMaybe<Scalars['Boolean']['input']>
}

/**
 * An auth provider is a way to authenticate a user via Firebase.
 * Currently, we only support email and SAML.
 */
export type AuthProvider = {
  readonly __typename: 'AuthProvider'
  /** Only provided for type == SAML */
  readonly samlProviderId: Maybe<Scalars['String']['output']>
  readonly type: AuthProviderType
}

export { AuthProviderType }

export type BilledPerCostCode = {
  readonly __typename: 'BilledPerCostCode'
  readonly balanceToFinish: Scalars['SafeInt']['output']
  readonly costCode: Maybe<Scalars['String']['output']>
  readonly grossBilledThisMonth: Scalars['SafeInt']['output']
  readonly netBilledThisMonth: Scalars['SafeInt']['output']
  readonly retentionHeldAllTime: Scalars['SafeInt']['output']
  readonly retentionHeldThisMonth: Scalars['SafeInt']['output']
  readonly scheduledValue: Scalars['SafeInt']['output']
  readonly totalGrossBilled: Scalars['SafeInt']['output']
  readonly totalNetBilled: Scalars['SafeInt']['output']
}

export type BilledPerTaxGroup = {
  readonly __typename: 'BilledPerTaxGroup'
  readonly scheduledValue: Scalars['SafeInt']['output']
  readonly taxAmountThisPeriod: Scalars['SafeInt']['output']
  readonly taxGroup: TaxGroup
}

/** Billing breakdown by cost code and contract */
export type BillingByCostCodeAndJob = {
  readonly __typename: 'BillingByCostCodeAndJob'
  readonly contract: Contract
  readonly costCode: Maybe<Scalars['String']['output']>
  readonly grossBilling: Scalars['SafeInt']['output']
  readonly netBilling: Scalars['SafeInt']['output']
  readonly retentionHeld: Scalars['SafeInt']['output']
  readonly scheduledValue: Maybe<Scalars['SafeInt']['output']>
}

export type BillingDashboard = {
  readonly __typename: 'BillingDashboard'
  readonly billingSummary: BillingDashboardSummary
  readonly contracts: ReadonlyArray<BillingDashboardContract>
}

export type BillingDashboardContract = {
  readonly __typename: 'BillingDashboardContract'
  readonly billingStatus: DashboardBillingStatus
  readonly contract: Contract
  readonly projectMetrics: ProjectMetrics
}

export type BillingDashboardContractWithLineItems = {
  readonly __typename: 'BillingDashboardContractWithLineItems'
  readonly billingStatus: DashboardBillingStatus
  readonly contract: Contract
  readonly lineItems: ReadonlyArray<LineItemMetrics>
  readonly projectMetrics: ProjectMetrics
}

export type BillingDashboardSummary = {
  readonly __typename: 'BillingDashboardSummary'
  /** If undefined, the previous month had no amount billed and no amount in draft */
  readonly amountBilledAndDraftMonthOverMonthPercent: Maybe<Scalars['Float']['output']>
  /**
   * Amount of progress billed across all projects this month, including pay apps in draft. Does
   * not include retention billed and does not subtract retention held.
   */
  readonly amountBilledAndDraftThisMonth: Scalars['SafeInt']['output']
  /** If undefined, no amount of money was submitted for billing in the previous month */
  readonly amountBilledMonthOverMonthPercent: Maybe<Scalars['Float']['output']>
  /**
   * Amount of progress billed across all projects this month, excluding pay apps in draft. Does not
   * include retention billed and does not subtract retention held.
   */
  readonly amountBilledThisMonth: Scalars['SafeInt']['output']
  /** If undefined, the previous month had no draft money outstanding */
  readonly amountInDraftMonthOverMonthPercent: Maybe<Scalars['Float']['output']>
  /**
   * Amount of progress billing still in draft as of this month. Does not include retention billed and
   * does not subtract retention held.
   */
  readonly amountInDraftThisMonth: Scalars['SafeInt']['output']
  /** Amount billed each month over the last n months */
  readonly monthlyBillingBreakdown: ReadonlyArray<MonthlyBillingBreakdown>
  /** If undefined, the previous month had no amount billed and no amount in draft */
  readonly netAmountBilledAndDraftMonthOverMonthPercent: Maybe<Scalars['Float']['output']>
  /**
   * Net amount billed across all projects this month, including pay apps in draft.
   * Deducts retention held and adds retention billed.
   */
  readonly netAmountBilledAndDraftThisMonth: Scalars['SafeInt']['output']
  /** If undefined, the previous month had no draft money outstanding */
  readonly netAmountInDraftMonthOverMonthPercent: Maybe<Scalars['Float']['output']>
  /**
   * Net amount of progress billing still in draft as of this month.
   * Deducts retention held and adds retention billed.
   */
  readonly netAmountInDraftThisMonth: Scalars['SafeInt']['output']
  readonly payAppStatusBreakdown: PayAppStatusBreakdown
  readonly progressBilledAndDraftMinusRetentionHeldMonthOverMonthPercent: Maybe<
    Scalars['Float']['output']
  >
  /**
   * Progress billed this month minus retention held.
   * Retention billed is not included.
   * This includes drafts if payAppStatus includes drafts.
   */
  readonly progressBilledAndDraftMinusRetentionHeldThisMonth: Scalars['SafeInt']['output']
  readonly progressBilledAndDraftMonthOverMonthPercent: Maybe<Scalars['Float']['output']>
  /**
   * Progress billed this month, not deducting retention held.
   * Retention billed is not included.
   * This is the same as amountBilledAndDraftThisMonth.
   */
  readonly progressBilledAndDraftThisMonth: Scalars['SafeInt']['output']
  readonly progressBilledMinusRetentionHeldMonthOverMonthPercent: Maybe<Scalars['Float']['output']>
  /**
   * Progress billed this month minus retention held.
   * Retention billed is not included.
   * This does not include drafts, even if payAppStatus includes drafts.
   */
  readonly progressBilledMinusRetentionHeldThisMonth: Scalars['SafeInt']['output']
  /** If undefined, the previous month had no project billed */
  readonly projectsBilledMonthOverMonthPercent: Maybe<Scalars['Float']['output']>
  readonly projectsBilledThisMonth: Scalars['SafeInt']['output']
  readonly retentionBilledAndDraftMonthOverMonthPercent: Maybe<Scalars['Float']['output']>
  /** Retention billed this month, including drafts if payAppStatus includes drafts */
  readonly retentionBilledAndDraftThisMonth: Scalars['SafeInt']['output']
  readonly retentionBilledMonthOverMonthPercent: Maybe<Scalars['Float']['output']>
  /** Retention billed this month */
  readonly retentionBilledThisMonth: Scalars['SafeInt']['output']
  /** Percent change in retentionHeldThisMonth. If undefined, the previous month held no retention. */
  readonly retentionHeldMonthOverMonthPercent: Maybe<Scalars['Float']['output']>
  /**
   * Retention held, including only submitted billing in the given month. This is a gross amount and
   * does not subtract retention billed.
   */
  readonly retentionHeldThisMonth: Scalars['SafeInt']['output']
  /**
   * Total retention held through a given month, from all draft and submitted pay apps. This is net
   * retention and subtracts retention billed, i.e. it is the total amount of retention held at the
   * end of the month.
   */
  readonly retentionHeldThruMonth: Scalars['SafeInt']['output']
  /**
   * Total number of active projects the current user has access to. This includes:
   * 1. All projects billing this month, including quick bills submitted this month
   * 2. All lump sum and unit price projects that did not bill this month
   */
  readonly totalProjectsThisMonth: Scalars['SafeInt']['output']
}

export type BillingTotalsData = {
  readonly __typename: 'BillingTotalsData'
  readonly companyCreatedAt: Scalars['DateTime']['output']
  readonly companyId: Scalars['ID']['output']
  readonly companyName: Scalars['String']['output']
  readonly month: Scalars['DateTime']['output']
  readonly sitelinePointOfContactId: Maybe<Scalars['ID']['output']>
  readonly sitelinePointOfContactName: Maybe<Scalars['String']['output']>
  readonly submittedPayAppCount: Scalars['SafeInt']['output']
  readonly totalBilledThisMonth: Scalars['Float']['output']
}

export type BillingTotalsInput = {
  readonly endMonth?: InputMaybe<Scalars['String']['input']>
  /** This is in YYYY-MM format */
  readonly startMonth: Scalars['String']['input']
}

export { BillingType }

export { CacheType }

export type CashForecastContract = {
  readonly __typename: 'CashForecastContract'
  readonly contract: Contract
  readonly hasInvoicePredictedPaymentPassed: Scalars['Boolean']['output']
  readonly id: Scalars['ID']['output']
  readonly numComments: Scalars['Int']['output']
  readonly paymentPeriods: ReadonlyArray<ContractPaymentPeriod>
}

export type CashForecastPeriod = {
  readonly __typename: 'CashForecastPeriod'
  /**
   * The total amount that has already been billed, is not yet paid, and is predicted to be paid in
   * this period
   */
  readonly amountOutstanding: Scalars['SafeInt']['output']
  readonly period: CashForecastPeriodType
  /**
   * The first day of the period that this represents.
   * If the forecast is monthly and this represents May, startDate will be "<year>-05-01".
   * If the forecast is weekly, start date will be the Monday of the week that this represents.
   * Format: YYYY-MM-DD
   */
  readonly startDate: Scalars['String']['output']
}

export { CashForecastPeriodType }

export type ChangeOrderLogUseData = {
  readonly __typename: 'ChangeOrderLogUseData'
  readonly companyCreatedAt: Scalars['DateTime']['output']
  readonly companyId: Scalars['ID']['output']
  readonly companyName: Scalars['String']['output']
  readonly percentProjectsWithChangeOrder: Scalars['Float']['output']
  readonly sitelinePointOfContactId: Maybe<Scalars['ID']['output']>
  readonly sitelinePointOfContactName: Maybe<Scalars['String']['output']>
}

export type ChangeOrderReport = {
  readonly __typename: 'ChangeOrderReport'
  readonly changeOrderSummaryReport: ChangeOrderSummaryReport
  readonly pendingChangeOrderReport: PendingChangeOrderReport
}

/** Represents a change order request, managed by a subcontractor for submitting to the project's GC for review */
export type ChangeOrderRequest = {
  readonly __typename: 'ChangeOrderRequest'
  /** The current or final amount of the change order request, including any GC adjustments */
  readonly amount: Maybe<Scalars['SafeInt']['output']>
  /** Once approved, the subcontractor may upload the final completed SCO form */
  readonly approvedScoFile: Maybe<StoredFile>
  readonly attachments: ReadonlyArray<ChangeOrderRequestAttachment>
  /** A list of comments on the change order request */
  readonly comments: ReadonlyArray<ChangeOrderRequestComment>
  readonly contract: Contract
  readonly createdAt: Scalars['DateTime']['output']
  readonly emailedContacts: ReadonlyArray<CompanyContact>
  readonly events: ReadonlyArray<ChangeOrderRequestEvent>
  /** Form template version is locked in when the request is sent */
  readonly formTemplateVersion: Maybe<FormTemplateVersion>
  readonly formValues: ReadonlyArray<FormTemplateAnnotationValue>
  readonly generalContractorNumber: Maybe<Scalars['String']['output']>
  /** Whether the contract rate table was swapped or updated since the COR was created */
  readonly hasContractRateTableChanged: Scalars['Boolean']['output']
  /** Whether fees have changed on the contract since the COR was created */
  readonly haveContractFeesChanged: Scalars['Boolean']['output']
  /** Whether taxes have changed on the contract since the COR was created */
  readonly haveContractTaxesChanged: Scalars['Boolean']['output']
  readonly id: Scalars['ID']['output']
  /** If the COR was imported from a change order log, mark the time it was imported */
  readonly importedAt: Maybe<Scalars['DateTime']['output']>
  readonly internalNotes: Maybe<Scalars['String']['output']>
  readonly internalNumber: Maybe<Scalars['String']['output']>
  /**
   * Change order requests that the user has linked to this one. COR links are bidirectional and all
   * linked change order requests should have the same set of SOV line items.
   */
  readonly linkedChangeOrderRequests: ReadonlyArray<ChangeOrderRequest>
  /** Return fields missing from the contract or project that are required on the COR form template */
  readonly missingRequiredFields: ReadonlyArray<RequiredFieldForForms>
  readonly name: Scalars['String']['output']
  readonly proceedingWithWork: Scalars['Boolean']['output']
  /** Once the COR is submitted, this is the amount at the time of the most recent submission */
  readonly proposedAmount: Maybe<Scalars['SafeInt']['output']>
  /** Once the SOR is submitted, this is the time of the most recent submission */
  readonly proposedAt: Maybe<Scalars['DateTime']['output']>
  /** Link back to rate table that is used to price the change order request. */
  readonly rateTable: Maybe<RateTable>
  /**
   * Only if pricing the change order.
   * Warning: this includes *all* fees, including group fees.
   */
  readonly rateTableFees: ReadonlyArray<ChangeOrderRequestRateTableFee>
  /** Only used for pricing the change order */
  readonly rateTableGroups: ReadonlyArray<ChangeOrderRequestRateTableGroup>
  /** Used for pricing the change order */
  readonly rateTableItems: ReadonlyArray<ChangeOrderRequestRateTableItem>
  readonly reason: Maybe<Scalars['String']['output']>
  /** Amount of time in days (eg. 1 day or 1.5 days). Only used for template variables on forms */
  readonly scheduleImpact: Maybe<Scalars['Float']['output']>
  /** Change order requests do not support forms with physical signatures */
  readonly signature: Maybe<Signature>
  readonly sovLineItems: ReadonlyArray<SovLineItem>
  readonly status: ChangeOrderRequestStatus
  readonly statusChangedAt: Scalars['DateTime']['output']
  readonly statusLogs: ReadonlyArray<ChangeOrderRequestStatusLog>
  /** The human-readable unit of measurement for each unit, e.g. LF, EA, HR for UNIT_PRICE contract. */
  readonly unitName: Maybe<Scalars['String']['output']>
  /** A price per unit, in cents. This is used for UNIT_PRICE contracts. */
  readonly unitPrice: Maybe<Scalars['Decimal']['output']>
  readonly updatedAt: Scalars['DateTime']['output']
  /** Should this COR be included in a log of change orders submitted to GC */
  readonly visibleToGc: Scalars['Boolean']['output']
}

/** Represents a backup document uploaded to a change order request that can be accessed via a URL */
export type ChangeOrderRequestAttachment = {
  readonly __typename: 'ChangeOrderRequestAttachment'
  readonly changeOrderRequest: ChangeOrderRequest
  readonly createdAt: Scalars['DateTime']['output']
  readonly description: Maybe<Scalars['String']['output']>
  readonly file: StoredFile
  readonly id: Scalars['ID']['output']
  /** Internal-only attachments will never get included in the generated PDF package for the COR */
  readonly isInternalOnly: Scalars['Boolean']['output']
  readonly name: Scalars['String']['output']
  readonly updatedAt: Scalars['DateTime']['output']
  readonly uploadId: Scalars['ID']['output']
}

/** Represents a comment from a user on a change order request */
export type ChangeOrderRequestComment = {
  readonly __typename: 'ChangeOrderRequestComment'
  readonly changeOrderRequest: ChangeOrderRequest
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  readonly message: Maybe<Scalars['String']['output']>
  readonly user: User
}

/** Represents a single event that occurred on a change order request */
export type ChangeOrderRequestEvent = {
  readonly __typename: 'ChangeOrderRequestEvent'
  readonly changeOrderRequest: ChangeOrderRequest
  readonly createdAt: Scalars['DateTime']['output']
  readonly createdBy: User
  readonly id: Scalars['ID']['output']
  /** Values in this dictionary depend on the event type */
  readonly metadata: Scalars['JSONObject']['output']
  readonly type: ChangeOrderRequestEventType
}

/**
 * Feed of change order request events returned in paginated lists. Cursor is the latest created time
 * and hasNext indicates if there are more events to be returned.
 */
export type ChangeOrderRequestEventFeed = {
  readonly __typename: 'ChangeOrderRequestEventFeed'
  readonly changeOrderRequestEvents: ReadonlyArray<ChangeOrderRequestEvent>
  readonly cursor: Scalars['DateTime']['output']
  readonly hasNext: Scalars['Boolean']['output']
}

export { ChangeOrderRequestEventType }

/** A COR-level or group-level fee on a priced change order request. */
export type ChangeOrderRequestRateTableFee = {
  readonly __typename: 'ChangeOrderRequestRateTableFee'
  readonly changeOrderRequest: ChangeOrderRequest
  readonly createdAt: Scalars['DateTime']['output']
  readonly description: Scalars['String']['output']
  /** If provided, this fee applies to a specific group */
  readonly group: Maybe<ChangeOrderRequestRateTableGroup>
  readonly id: Scalars['ID']['output']
  /** If provided, this fee overrides another fee at the COR (global) level. It must be a group fee in this case. */
  readonly overridesFee: Maybe<ChangeOrderRequestRateTableFee>
  readonly percent: Scalars['Float']['output']
  /** Link back to the contract rate table fee that was used to create this fee, if it still exists. */
  readonly rateTableFee: Maybe<ContractRateTableFee>
  readonly updatedAt: Scalars['DateTime']['output']
}

/** A rate table item group on a change order request rate table */
export type ChangeOrderRequestRateTableGroup = {
  readonly __typename: 'ChangeOrderRequestRateTableGroup'
  /** Total amount after all fees and taxes */
  readonly amountPostFeesPostTax: Scalars['SafeInt']['output']
  /** Amount after fees, but before tax applies */
  readonly amountPostFeesPreTax: Scalars['SafeInt']['output']
  /** Amount before any fee or tax applies */
  readonly amountPreFeesPreTax: Scalars['SafeInt']['output']
  readonly changeOrderRequest: ChangeOrderRequest
  readonly createdAt: Scalars['DateTime']['output']
  readonly fees: ReadonlyArray<ChangeOrderRequestRateTableFee>
  readonly id: Scalars['ID']['output']
  readonly items: ReadonlyArray<ChangeOrderRequestRateTableItem>
  readonly name: Scalars['String']['output']
  /** Link back to the rate table group that was used to create the pay app item, if it still exists. */
  readonly rateTableGroup: Maybe<RateTableGroup>
  /** This is the pinned tax for this group, which is either the default tax group or the category override. */
  readonly taxGroup: Maybe<TaxGroup>
  readonly type: LineItemType
  readonly updatedAt: Scalars['DateTime']['output']
}

/** A line item on a change order request rate table */
export type ChangeOrderRequestRateTableItem = {
  readonly __typename: 'ChangeOrderRequestRateTableItem'
  readonly changeOrderRequest: ChangeOrderRequest
  readonly code: Maybe<Scalars['String']['output']>
  readonly createdAt: Scalars['DateTime']['output']
  readonly currentPriced: Scalars['SafeInt']['output']
  readonly currentUnitsPriced: Scalars['Float']['output']
  readonly description: Scalars['String']['output']
  /** Group that the line item belongs to */
  readonly group: ChangeOrderRequestRateTableGroup
  readonly id: Scalars['ID']['output']
  /** Whether this item was manually added to the COR and doesn't have a matching rate table item */
  readonly isOneOffItem: Scalars['Boolean']['output']
  /** Link back to the rate table item that was used to create the COR item, if it still exists. */
  readonly rateTableItem: Maybe<RateTableItem>
  readonly sortOrder: Scalars['SafeInt']['output']
  readonly unitName: Maybe<Scalars['String']['output']>
  readonly unitRate: Scalars['SafeInt']['output']
  readonly updatedAt: Scalars['DateTime']['output']
}

export { ChangeOrderRequestStatus }

/** Represents a log of all statuses for a change order request and when the transition occurred. */
export type ChangeOrderRequestStatusLog = {
  readonly __typename: 'ChangeOrderRequestStatusLog'
  readonly changeOrderRequest: ChangeOrderRequest
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  readonly status: ChangeOrderRequestStatus
  readonly statusUpdatedAt: Scalars['DateTime']['output']
  readonly user: User
}

export type ChangeOrderSummaryReport = {
  readonly __typename: 'ChangeOrderSummaryReport'
  readonly aggregateChangeOrderDataByContract: ReadonlyArray<AggregateChangeOrderDataByContract>
  readonly aggregateChangeOrderTotals: AggregateChangeOrderData
}

export type CloneFormTemplateInput = {
  readonly id: Scalars['ID']['input']
  readonly originalFile?: InputMaybe<Scalars['Upload']['input']>
  readonly userVisibleName: Scalars['String']['input']
}

export type CloudLoggingLogsInput = {
  readonly autoPaginate?: InputMaybe<Scalars['Boolean']['input']>
  readonly filter: Scalars['String']['input']
  readonly orderBy?: InputMaybe<Scalars['String']['input']>
  readonly pageSize: Scalars['Int']['input']
  readonly pageToken?: InputMaybe<Scalars['String']['input']>
}

export type CloudLoggingLogsResponse = {
  readonly __typename: 'CloudLoggingLogsResponse'
  readonly entries: ReadonlyArray<Scalars['JSONObject']['output']>
  readonly nextPageToken: Maybe<Scalars['String']['output']>
}

export { CollectionsNotificationAnchor }

export { CollectionsNotificationGranularity }

export { CollectionsNotificationModifier }

/** A record of sending a collection notification, for a specific pay app and notification type. */
export type CollectionsNotificationSendLog = {
  readonly __typename: 'CollectionsNotificationSendLog'
  readonly gcContacts: ReadonlyArray<CompanyContact>
  readonly id: Scalars['ID']['output']
  readonly internalCcRecipients: ReadonlyArray<User>
  /**
   * This is set when a user manually triggers a notification by clicking 'Send now' on the frontend,
   * and will be empty when the notification is automatically sent at its scheduled time.
   */
  readonly manuallyTriggeredBy: Maybe<User>
  readonly notification: PayAppCollectionsNotification
  readonly sendTime: Scalars['DateTime']['output']
  readonly sender: User
}

export { CollectionsNotificationType }

/** A user-provided task for collections on a pay app */
export type CollectionsTask = {
  readonly __typename: 'CollectionsTask'
  readonly createdAt: Scalars['DateTime']['output']
  /** User who created and is assigned to this task */
  readonly createdBy: User
  readonly description: Scalars['String']['output']
  readonly dueDate: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  readonly payApp: PayApp
  readonly status: CollectionsTaskStatus
  readonly updatedAt: Scalars['DateTime']['output']
}

export { CollectionsTaskStatus }

/** Represents a single company that can either be a general contractor or subcontractor. */
export type Company = {
  readonly __typename: 'Company'
  /** Setting for determining what reference date to use to calculate A/R aging for this company */
  readonly agingIntervalType: AgingIntervalType
  /**
   * If true, allow over-billing on lump sum projects. By default, over-billing is not allowed. Note
   * that it is allowed for all companies on unit price projects.
   */
  readonly allowLumpSumOverbilling: Scalars['Boolean']['output']
  /** Whether the company does tax calculations, used for reporting */
  readonly calculatesTaxes: Scalars['Boolean']['output']
  /** Integrations for this company. Some of them might be archived. */
  readonly companyIntegrations: ReadonlyArray<CompanyIntegration>
  readonly contacts: ReadonlyArray<CompanyContact>
  readonly createdAt: Scalars['DateTime']['output']
  /** Default change order request templates to use on a new project. */
  readonly defaultChangeOrderLogTemplate: Maybe<FormTemplate>
  /** Default change order request templates to use on a new project. */
  readonly defaultChangeOrderRequestTemplate: Maybe<FormTemplate>
  /**
   * Default number of days prior to actual deadline that will be shown as the internal deadline.
   * Used as the default for new contracts. If not set, defaults to 3 days.
   */
  readonly defaultDaysBeforePayAppDue: Maybe<Scalars['Int']['output']>
  /**
   * Default pay app requirements to use on a new lump sum project. If the list is undefined, it hasn't
   * been set up yet. If it is empty, it has been set up to have 0 requirements by default.
   */
  readonly defaultLumpSumRequirements: Maybe<ReadonlyArray<PayAppRequirementGroup>>
  /** If the company sets a custom due date for pay apps, it will be the default for new contracts */
  readonly defaultPayAppDueOnDayOfMonth: Maybe<Scalars['Int']['output']>
  /** Default primary lien waiver templates to use on a new project. */
  readonly defaultPrimaryLienWaivers: Maybe<SubcontractorLienWaiverTemplateSet>
  /**
   * Default template to use when creating QUICK pay apps.
   * When creating quick projects, the contract will be set up with this template in its
   * pay app requirements.
   */
  readonly defaultQuickBillingTemplate: Maybe<FormTemplate>
  /** If the company sets a custom default retention percent, it will be the default for new contracts */
  readonly defaultRetentionPercent: Maybe<Scalars['Float']['output']>
  /** Whether new contracts are created with the setting enabled to show audit trail on signed forms */
  readonly defaultShowAuditItemOnSignedPackages: Scalars['Boolean']['output']
  /**
   * Default pay app requirements to use on a new T&M project. If the list is undefined, it
   * hasn't been set up yet. If it is empty, it has been set up to have 0 requirements by default.
   */
  readonly defaultTimeAndMaterialsRequirements: Maybe<ReadonlyArray<PayAppRequirementGroup>>
  /**
   * Default pay app requirements to use on a new unit price project. If the list is undefined, it
   * hasn't been set up yet. If it is empty, it has been set up to have 0 requirements by default.
   */
  readonly defaultUnitPriceRequirements: Maybe<ReadonlyArray<PayAppRequirementGroup>>
  /** Default vendor lien waiver templates to use on a new project. */
  readonly defaultVendorLienWaivers: Maybe<SubcontractorLienWaiverTemplateSet>
  /**
   * If true, the billing worksheet tool is enabled for all projects under this company. This means
   * users will have the ability to add worksheet line items for line items on those projects' SOVs.
   */
  readonly enableBillingWorksheets: Scalars['Boolean']['output']
  readonly expectedNumberOfProjects: Maybe<Scalars['SafeInt']['output']>
  readonly formTemplateCollections: ReadonlyArray<CompanyFormTemplateCollection>
  /** Whether the company has any SOVs that use cost codes, used for reporting */
  readonly hasCostCodes: Scalars['Boolean']['output']
  readonly id: Scalars['ID']['output']
  /**
   * Integration metadata for this company, with mappings of different types for different integration
   * types. Used for storing mappings for related companies, e.g. a sub's general contractors.
   */
  readonly integrationMappings: Scalars['JSONObject']['output']
  /** Whether the company is a demo company */
  readonly isDemoCompany: Scalars['Boolean']['output']
  readonly leadPMs: ReadonlyArray<User>
  readonly locations: ReadonlyArray<Location>
  readonly metadata: CompanyMetadata
  /** The MFA requirement for the company */
  readonly mfaRequirement: CompanyMfaRequirement
  readonly name: Scalars['String']['output']
  /** Shorter version of the formal company name, shown in the nav bar and included in searches */
  readonly nickname: Maybe<Scalars['String']['output']>
  readonly notarySignature: Maybe<StoredFile>
  readonly notaryStamp: Maybe<StoredFile>
  /** Used for admin users to tag companies with notary type requirements */
  readonly notaryTags: ReadonlyArray<NotaryTag>
  readonly payAppFormTemplateSets: ReadonlyArray<CompanyPayAppFormTemplateSet>
  readonly phoneNumber: Maybe<Scalars['PhoneNumber']['output']>
  readonly picture: Maybe<StoredFile>
  readonly primaryLienWaiverTemplateSets: ReadonlyArray<CompanyLienWaiverTemplateSet>
  readonly secondarySignature: Maybe<StoredFile>
  /** Siteline CSM team member assigned to this company */
  readonly sitelinePointOfContact: Maybe<User>
  /** Company setting for stored materials handling */
  readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
  /** Tax groups that are used by this company. Tax-aware line items must link to one of these tax groups. */
  readonly taxGroups: ReadonlyArray<TaxGroup>
  readonly type: CompanyType
  readonly updatedAt: Scalars['DateTime']['output']
  readonly users: ReadonlyArray<CompanyUser>
  /** The frequency at which to send automatic vendor lien waiver reminders */
  readonly vendorLienWaiverReminderFrequency: VendorLienWaiverReminderFrequency
  readonly vendorLienWaiverTemplateSets: ReadonlyArray<CompanyLienWaiverTemplateSet>
  readonly vendors: ReadonlyArray<Vendor>
}

/** Represents a contact at another company who may receive pay apps or primary lien waivers */
export type CompanyContact = {
  readonly __typename: 'CompanyContact'
  /** An archived company contact should not be surfaced to the user, except relating to past documents */
  readonly archivedAt: Maybe<Scalars['DateTime']['output']>
  readonly company: Company
  readonly companyName: Scalars['String']['output']
  readonly createdAt: Scalars['DateTime']['output']
  readonly email: Scalars['EmailAddress']['output']
  readonly fullName: Scalars['String']['output']
  readonly id: Scalars['ID']['output']
  readonly jobTitle: Maybe<Scalars['String']['output']>
  readonly phoneNumber: Maybe<Scalars['PhoneNumber']['output']>
  readonly updatedAt: Scalars['DateTime']['output']
}

/** A collection of form template sets of different types that can be used as defaults for new projects */
export type CompanyFormTemplateCollection = {
  readonly __typename: 'CompanyFormTemplateCollection'
  readonly billingType: BillingType
  readonly changeOrderLog: Maybe<FormTemplateVariant>
  readonly changeOrderRequest: Maybe<FormTemplateVariant>
  readonly company: Company
  readonly generalContractor: Maybe<Company>
  readonly id: Scalars['ID']['output']
  /** If this is the main default template collection for this company */
  readonly isDefaultTemplateCollection: Scalars['Boolean']['output']
  readonly name: Scalars['String']['output']
  readonly payAppForms: Maybe<CompanyPayAppFormTemplateSet>
  readonly primaryLienWaivers: Maybe<CompanyLienWaiverTemplateSet>
  readonly state: Maybe<Scalars['String']['output']>
  readonly vendorLienWaivers: Maybe<CompanyLienWaiverTemplateSet>
}

export type CompanyInput = {
  readonly defaultQuickBillingTemplateId?: InputMaybe<Scalars['ID']['input']>
  readonly expectedNumberOfProjects?: InputMaybe<Scalars['SafeInt']['input']>
  readonly isDemoCompany?: InputMaybe<Scalars['Boolean']['input']>
  readonly locations: ReadonlyArray<LocationInput>
  readonly metadata: CompanyMetadataInput
  readonly name: Scalars['String']['input']
  readonly phoneNumber: Scalars['PhoneNumber']['input']
  readonly picture?: InputMaybe<StoredFileInput>
  readonly type: CompanyType
}

/**
 * A company-level integration.
 * Each contract integration must reference a company integration.
 * Companies can have multiple integrations of the same type.
 */
export type CompanyIntegration = {
  readonly __typename: 'CompanyIntegration'
  /**
   * When archived, the integration no longer shows up in admin/web
   * and contract integrations linked to it are considered inactive
   */
  readonly archivedAt: Maybe<Scalars['DateTime']['output']>
  readonly company: Company
  readonly createdAt: Scalars['DateTime']['output']
  /** Whether the credentials are ready be auto-rotated */
  readonly credentialsReadyForAutoRotation: Scalars['Boolean']['output']
  /** Alias for credentials.updatedAt, necessary because credentials are not exposed to the API */
  readonly credentialsUpdatedAt: Maybe<Scalars['DateTime']['output']>
  readonly id: Scalars['ID']['output']
  /** How change orders should be imported on the SOV, when reading the SOV line items into Siteline */
  readonly importChangeOrdersMethod: ImportIntegrationChangeOrdersMethod
  /**
   * For integrations that support "combo jobs", where line items may link to different jobs, this is
   * the method we use to determine how those jobs should be handled in Siteline. Currently applies
   * only to Sage 300 CRE, but all methods may not be supported by all integrations.
   *
   * A "combo job" is a project in an integration where the contract line items link to more than one
   * job. Customers may handle these differently, but often different PMs are responsible for billing
   * only the subset of the SOV linked to a specific job, and the GC may receive one or multiple
   * invoices.
   */
  readonly importComboJobMethod: ImportIntegrationComboJobMethod
  /** Whether to import projects with the name defaulting to the job name or the contract name */
  readonly importProjectNameSource: ImportIntegrationProjectNameSource
  readonly integrations: ReadonlyArray<Integration>
  /** Optional label to differentiate this integration from others of the same type */
  readonly label: Maybe<Scalars['String']['output']>
  /** Indicates that this integration is specific to a company office. If null, it applies to all offices */
  readonly location: Maybe<Location>
  readonly longName: Scalars['String']['output']
  /** Integration specific metadata that applies across projects. Fields depend on the integration type. */
  readonly metadata: Scalars['JSONObject']['output']
  /**
   * Whether to revert pay apps to a DRAFT state when we detect that a Textura invoice is rejected by the GC.
   * Note that this does not affect the case where an invoice is rejected *and* the GC modifies the billing values,
   * in which case the pay app is not reverted (and billing is updated automatically).
   */
  readonly revertPayAppsToDraftWhenTexturaInvoiceRejected: Scalars['Boolean']['output']
  readonly shortName: Scalars['String']['output']
  /**
   * Whether to automatically import the SOV for projects when initially onboarded, if supported by
   * this integration type
   */
  readonly shouldImportNewProjectSov: Scalars['Boolean']['output']
  readonly type: IntegrationType
  readonly updatedAt: Scalars['DateTime']['output']
}

/**
 * A lien waiver template set that a company can use as default primary or vendor lien waiver
 * forms on a new project
 */
export type CompanyLienWaiverTemplateSet = {
  readonly __typename: 'CompanyLienWaiverTemplateSet'
  readonly company: Company
  readonly createdAt: Scalars['DateTime']['output']
  readonly generalContractor: Maybe<Company>
  readonly id: Scalars['ID']['output']
  /** True for vendor lien waivers, false for primary lien waivers */
  readonly isVendorSet: Scalars['Boolean']['output']
  readonly lienWaivers: Maybe<SubcontractorLienWaiverTemplateSet>
  readonly name: Scalars['String']['output']
  readonly state: Maybe<Scalars['String']['output']>
  readonly updatedAt: Scalars['DateTime']['output']
}

export { CompanyListSortCriteria }

/** Represents unstructured metadata about a given company. */
export type CompanyMetadata = {
  readonly __typename: 'CompanyMetadata'
  /** If true, restricts users from signing up unless their email is in the list of domains. */
  readonly domainSignup: Scalars['Boolean']['output']
  /** This is a list of email domains for a company, which restricts who can sign up by email. */
  readonly domains: ReadonlyArray<Scalars['String']['output']>
  /**
   * Represents what day of the month (up to 28) to remind users to create pay apps. If not set, the
   * email will go out on the 2nd Monday of the month by default.
   */
  readonly monthlyCreatePayAppReminderDate: Maybe<Scalars['Int']['output']>
  /** Represents the specialty subtrade of the contractor, if one exists. */
  readonly subtrade: Maybe<Scalars['String']['output']>
}

export type CompanyMetadataInput = {
  readonly domainSignup: Scalars['Boolean']['input']
  readonly domains: ReadonlyArray<Scalars['String']['input']>
  readonly monthlyCreatePayAppReminderDate?: InputMaybe<Scalars['Int']['input']>
  readonly subtrade?: InputMaybe<Scalars['String']['input']>
}

export { CompanyMfaRequirement }

/**
 * A set of form templates of a certain billing type that a company can use as the default pay app
 * form group for new projects
 */
export type CompanyPayAppFormTemplateSet = {
  readonly __typename: 'CompanyPayAppFormTemplateSet'
  readonly billingType: BillingType
  readonly company: Company
  readonly createdAt: Scalars['DateTime']['output']
  readonly generalContractor: Maybe<Company>
  readonly id: Scalars['ID']['output']
  readonly name: Scalars['String']['output']
  readonly payAppRequirementGroups: ReadonlyArray<PayAppRequirementGroup>
  readonly state: Maybe<Scalars['String']['output']>
  readonly updatedAt: Scalars['DateTime']['output']
}

/** Represents metadata around a company that is working on a given project. */
export type CompanyProjectMetadata = {
  readonly __typename: 'CompanyProjectMetadata'
  readonly company: Company
  /**
   * Used if the subcontractor uses a distinct name for the company on this project. If not set, company name
   * will always default to the name on the company model.
   */
  readonly companyName: Maybe<Scalars['String']['output']>
  readonly role: CompanyProjectRole
  readonly selectedAddress: Maybe<Location>
}

export type CompanyProjectMetadataInput = {
  readonly companyId: Scalars['ID']['input']
  readonly selectedAddressId: Scalars['ID']['input']
}

export { CompanyProjectRole }

export { CompanyType }

/** Represents a user that exists at a company, along with their role. */
export type CompanyUser = {
  readonly __typename: 'CompanyUser'
  readonly company: Company
  /** The user's office location. Must be one of the company's locations. */
  readonly companyLocation: Location
  readonly createdAt: Scalars['DateTime']['output']
  /** If provided, emails the user sends from this company will be sent from this email address */
  readonly emailAlias: Maybe<CompanyUserEmailAlias>
  readonly id: Scalars['ID']['output']
  readonly isSitelineAdmin: Scalars['Boolean']['output']
  readonly permissions: ReadonlyArray<Permission>
  readonly role: CompanyUserRole
  readonly status: UserStatus
  readonly updatedAt: Scalars['DateTime']['output']
  readonly user: User
}

/**
 * Represents an email alias used by a user who belongs to multiple companies. Used when sending or
 * receiving emails under that company, while their primary email address is still used for login.
 */
export type CompanyUserEmailAlias = {
  readonly __typename: 'CompanyUserEmailAlias'
  readonly companyUser: CompanyUser
  readonly emailAddress: Scalars['EmailAddress']['output']
  readonly id: Scalars['ID']['output']
  readonly isVerified: Scalars['Boolean']['output']
  readonly verificationCodeExpiresAt: Scalars['DateTime']['output']
}

export type CompanyUserInput = {
  readonly contractIds: ReadonlyArray<Scalars['ID']['input']>
  readonly isSitelineAdmin: Scalars['Boolean']['input']
  readonly permissions: ReadonlyArray<Permission>
  readonly role: CompanyUserRole
  readonly user: UserInput
}

export { CompanyUserRole }

export { ComplianceStatus }

export type ComplianceUseData = {
  readonly __typename: 'ComplianceUseData'
  readonly companyCreatedAt: Scalars['DateTime']['output']
  readonly companyId: Scalars['ID']['output']
  readonly companyName: Scalars['String']['output']
  readonly percentProjectsWithComplianceRequirements: Scalars['Float']['output']
  readonly sitelinePointOfContactId: Maybe<Scalars['ID']['output']>
  readonly sitelinePointOfContactName: Maybe<Scalars['String']['output']>
}

export type Contract = {
  readonly __typename: 'Contract'
  /** Whether the project has any forms that are marked as not ready for manual stored material handling */
  readonly allFormsReadyForManualStoredMaterials: Scalars['Boolean']['output']
  /**
   * Whether taxes are applied to retention held/released.
   * Most ERPs apply tax on the current billed only (not deducting retention), so this is false by default.
   */
  readonly appliesTaxToRetention: Scalars['Boolean']['output']
  readonly archivedAt: Maybe<Scalars['DateTime']['output']>
  /** Form templates that are associated with this contract */
  readonly associatedFormTemplates: ReadonlyArray<FormTemplateAssociatedContract>
  /** Rolling average number of days from pay app submission to paid, over the last 6 months */
  readonly averageDaysToPaid: Scalars['Float']['output']
  /** The forecast, or billing projection, for this contract */
  readonly billingForecast: Maybe<ContractBillingForecast>
  readonly billingType: BillingType
  /** Optional form template to use for keeping track of change order logs */
  readonly changeOrderLogTemplate: Maybe<FormTemplate>
  /** Template to use for creating and submitting change order requests */
  readonly changeOrderRequestTemplate: Maybe<FormTemplate>
  /** List of change order requests for this contract. */
  readonly changeOrderRequests: ReadonlyArray<ChangeOrderRequest>
  /** Collections notifications settings on this contract */
  readonly collectionsNotifications: ReadonlyArray<ContractCollectionsNotification>
  readonly comments: ReadonlyArray<ContractComment>
  readonly company: Company
  readonly complianceStatus: Maybe<ComplianceStatus>
  /** Start date of the contract */
  readonly contractDate: Maybe<Scalars['DateTime']['output']>
  readonly contractNumber: Maybe<Scalars['String']['output']>
  /** Custom template variables, accessible in the `contract` key. Default to {}. */
  readonly contractTemplateVariables: Scalars['JSONObject']['output']
  /** Cost analysis derived from ERP or manually entered by user */
  readonly cost: Maybe<ContractCost>
  readonly createdAt: Scalars['DateTime']['output']
  /**
   * Represents the number of days before the pay app is due. Used to notify sub PMs to update their
   * invoice before the deadline.
   */
  readonly daysBeforePayAppDue: Scalars['Int']['output']
  /** Contains the last list of Users notified for requesting review on a COR or the COR log */
  readonly defaultChangeOrderRequestReviewContacts: ReadonlyArray<User>
  /** Team members who are notified when forms have been partially completed */
  readonly defaultFormsReviewContacts: ReadonlyArray<User>
  /** Containts a default list of contacts to submit pay apps to */
  readonly defaultGcContacts: ReadonlyArray<CompanyContact>
  /** Contains the last list of User references that were notified that the invoice is ready for review */
  readonly defaultInvoiceReviewContacts: ReadonlyArray<User>
  /** Tracks what initial retention % to use on the first pay app. Range 0-1. */
  readonly defaultRetentionPercent: Maybe<Scalars['Float']['output']>
  /**
   * Default tax group to use for all new line items on this contract.
   * Tax groups can be later updated on individual line items.
   */
  readonly defaultTaxGroup: Maybe<TaxGroup>
  /**
   * The billing end of the earliest pay app to be submitted on Siteline, or null if no pay apps are
   * submitted. Note that the earliest pay app may not be Pay App #1, since there may be pre-Siteline
   * billing. It's possible for this to be null but `hasStartedBilling` to be true (if there is
   * pre-Siteline billing but there are no pay apps or pre-Siteline pay apps), so this shouldn't be
   * used as a proxy for whether billing has started on a contract.
   */
  readonly earliestPayAppBillingEnd: Maybe<Scalars['DateTime']['output']>
  /**
   * If true, will automatically mark pay apps as paid when detected from integrations.
   * Default is true. This can be disabled in contract settings.
   */
  readonly enableAutoMarkAsPaidFromIntegrations: Scalars['Boolean']['output']
  readonly events: ReadonlyArray<ContractEvent>
  /** Files stored on the contract */
  readonly files: ReadonlyArray<StoredFile>
  /**
   * The billing end of Pay App #1, which may be a Siteline pay app or pre-Siteline pay app. Null
   * if no pay apps or the first pay app's date is not known.
   */
  readonly firstPayAppBillingEnd: Maybe<Scalars['DateTime']['output']>
  /** True if there is a past pay app count or any pay apps have been submitted on Siteline */
  readonly hasStartedBilling: Scalars['Boolean']['output']
  /**
   * If any of the pay app requirement forms on this contract are tagged with SWORN_STATEMENT,
   * regardless of whether they have finished processing, this will be true.
   */
  readonly hasSwornStatementForms: Scalars['Boolean']['output']
  readonly id: Scalars['ID']['output']
  /** Whether to generate the change order log as part of the pay app package for every pay app */
  readonly includeChangeOrderLogInPayAppPackage: Maybe<Scalars['Boolean']['output']>
  readonly integrations: ReadonlyArray<Integration>
  /** Internal project billing notes and reminders added by users on the project */
  readonly internalNotes: Maybe<Scalars['String']['output']>
  /**
   * Project number as defined by the subcontractor. This is the project number that customers use to
   * identify the project and it is rare for them to use `project.projectNumber`.
   */
  readonly internalProjectNumber: Maybe<Scalars['String']['output']>
  /** The total amount due across pay apps that have been submitted but not yet paid out */
  readonly invoiceAmountOutstanding: Scalars['SafeInt']['output']
  /**
   * Whether the subcontractor is receiving payment via joint check. Inferred from joint check settings
   * on the contract's vendor lien waivers.
   */
  readonly isJointCheck: Scalars['Boolean']['output']
  readonly lastEditedAt: Maybe<Scalars['DateTime']['output']>
  readonly lastEditedBy: Maybe<User>
  readonly leadPMs: ReadonlyArray<User>
  /**
   * Contains a list of CompanyContact references that were emailed the legal documents for a
   * contract on the project.
   */
  readonly legalRequirementContacts: ReadonlyArray<CompanyContact>
  /** Contains a list of all Legal requirements for a contract on the project. */
  readonly legalRequirements: ReadonlyArray<LegalRequirement>
  readonly lienWaiverTemplates: Maybe<SubcontractorLienWaiverTemplateSet>
  /**
   * The day of the month to use as thru date for all lien waiver requests on this project.
   * If undefined, will use the last day of the month. If defined, max date is 28.
   */
  readonly lienWaiverThroughDate: Maybe<Scalars['SafeInt']['output']>
  /** All lien waivers associated with this contract */
  readonly lienWaivers: ReadonlyArray<LienWaiver>
  /** The sum of amounts on all conditional lien waivers requested for this contract */
  readonly lowerTierConditionalAmountWaived: Scalars['SafeInt']['output']
  readonly lowerTierLienWaiverTemplates: Maybe<SubcontractorLienWaiverTemplateSet>
  /** The sum of amounts on all unconditional lien waivers requested for this contract */
  readonly lowerTierUnconditionalAmountWaived: Scalars['SafeInt']['output']
  /** Fields missing that are required to generate this contract's vendor lien waiver forms */
  readonly missingRequiredVendorLienWaiverFields: ReadonlyArray<RequiredFieldForForms>
  /** The next pay app number, if you were to create a new pay app. */
  readonly nextPayAppNumber: Scalars['Int']['output']
  /** Tracks the status of onboarding legal requirements for a project from the Compliance module */
  readonly onboardedProjectComplianceStatus: OnboardedProjectComplianceStatus
  /** Tracks the status of onboarding vendors/lien waivers for a project from the Vendor module */
  readonly onboardedProjectVendorsStatus: OnboardedProjectVendorsStatus
  /** Tracks the status of the onboarding steps for a new contract. */
  readonly onboardedStatus: OnboardedProjectContractStatus
  /** Skipped pay app months, including reasons for not billing */
  readonly optedOutMonths: ReadonlyArray<ContractOptedOutMonth>
  /**
   * The total amount requested across conditional vendor lien waivers on this contract minus the total
   * amount requested in unconditional vendor lien waivers on this contract
   */
  readonly outstandingVendorLienWaiverAmount: Scalars['SafeInt']['output']
  /**
   * If a project is onboarded midway through, this number indicates how many pay apps have
   * already been processed. i.e. If the project has 6 completed pay apps and will start using
   * Siteline on its 7th pay app, this number will be set to 6. For new projects, the number is
   * left as null. For new projects, this number is set to 0.
   */
  readonly pastPayAppCount: Scalars['Int']['output']
  /** Contains a list of all PayApp requirements for a contract on the project. */
  readonly payAppRequirementGroups: ReadonlyArray<PayAppRequirementGroup>
  readonly payApps: ReadonlyArray<PayApp>
  /**
   * The number of days corresponding to the type of payment terms. If `paymentTermsType` is:
   * - NetPayment, then this is the contractual number of days to be paid (i.e. if NET30, this is 30)
   * - PayWhenPaid, then this is the expected number of days to receive payment
   */
  readonly paymentTerms: Maybe<Scalars['SafeInt']['output']>
  readonly paymentTermsType: Maybe<ContractPaymentTermsType>
  /**
   * The percent complete for this contract. Will return null if billingType is equal to QUICK.
   * Range 0-1.
   */
  readonly percentComplete: Maybe<Scalars['Float']['output']>
  /**
   * Amount billed pre-siteline.
   * This is only for T&M contracts. Other contracts must use the SOV to indicate pre-siteline billed.
   */
  readonly preSitelineBilled: Maybe<Scalars['SafeInt']['output']>
  readonly preSitelinePayApps: ReadonlyArray<PreSitelinePayApp>
  /** The computed total amount of retention held pre-siteline in cents. */
  readonly preSitelineRetention: Scalars['SafeInt']['output']
  /**
   * Pre-siteline retention override amount in cents.
   * This is required for standard/line-item tracking.
   */
  readonly preSitelineRetentionHeldOverride: Maybe<Scalars['SafeInt']['output']>
  /** Primary lien waivers for a contract. */
  readonly primaryLienWaivers: ReadonlyArray<LienWaiver>
  /** The amount billed in progress to date for this contract, but not yet paid out */
  readonly progressAmountOutstanding: Scalars['SafeInt']['output']
  /**
   * Progress remaining to bill after the last submitted pay app. If no pay apps are submitted,
   * this is the progress remaining after any pre-Siteline progress completed.
   */
  readonly progressRemaining: Scalars['SafeInt']['output']
  readonly project: Project
  /** Internal project number (as defined by the subcontractor), or GC project number if it does not exist */
  readonly projectNumber: Maybe<Scalars['String']['output']>
  /**
   * For T&M contracts, the rate table to use when creating new pay apps. For lump sum and unit price
   * contracts, the rate table to use for pricing change order requests.
   */
  readonly rateTable: Maybe<RateTable>
  /** Default tax group to use for all rate table groups that don't have a `rateTableTaxGroup` override. */
  readonly rateTableDefaultTaxGroup: Maybe<TaxGroup>
  /**
   * Contract-level fees that apply to the current rate table.
   * These are copied when creating a new pay app or pricing a new change order request.
   * Warning: this includes *all* fees, including group fees.
   */
  readonly rateTableFees: ReadonlyArray<ContractRateTableFee>
  /**
   * Tax calculation type for rate table groups on T&M contracts, or for the COR pricing tool.
   * As opposed to `taxCalculationType`, this field only applies to rate table groups and does not
   * affect SOV line items or quick bills.
   */
  readonly rateTableTaxCalculationType: TaxCalculationType
  /**
   * Contract-level tax groups that apply to a specific rate table group, overriding the default tax group.
   * These are copied when creating a new pay app or pricing a new change order request.
   */
  readonly rateTableTaxGroups: ReadonlyArray<ContractRateTableTaxGroup>
  /** Attachments that should be copied to pay apps as backup when they're created */
  readonly recurringPayAppAttachments: ReadonlyArray<ContractPayAppAttachment>
  /** The amount billed in retention to date for this contract, but not yet paid out */
  readonly retentionAmountOutstanding: Scalars['SafeInt']['output']
  /** Total amount of retention held on the SOV to date, derived only from submitted pay apps */
  readonly retentionHeldOnBilledPayApps: Scalars['SafeInt']['output']
  /** Track retention at the pay app level instead of at the line item progress level */
  readonly retentionTrackingLevel: RetentionTrackingLevel
  readonly role: CompanyProjectRole
  /** Whether to round all retention values for each line item to the nearest dollar. */
  readonly roundRetention: Scalars['Boolean']['output']
  /** One of the addresses on the subcontractor */
  readonly selectedAddress: Location
  /** Whether signed packages generated from this contract should contain an audit page */
  readonly showAuditItemOnSignedPackages: Scalars['Boolean']['output']
  /** A list of months in MM-YYYY format for months that are not being billed for. */
  readonly skippedPayAppMonths: ReadonlyArray<Scalars['String']['output']>
  /** Not provided if billingType is QUICK */
  readonly sov: Maybe<Sov>
  readonly status: ContractStatus
  /** Contract level setting for stored materials handling. This overrides company settings */
  readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
  /**
   * Tax calculation type for line items on Lump-Sum, Unit-price, or Quick bill contracts.
   * As opposed to `rateTableTaxCalculationType`, this field only applies to SOV line items or Quick bills,
   * and it does not affect the pricing tool or T&M contracts.
   */
  readonly taxCalculationType: TaxCalculationType
  readonly timeZone: Scalars['String']['output']
  readonly updatedAt: Scalars['DateTime']['output']
  /** List of all users who are working on this contract */
  readonly users: ReadonlyArray<ContractUser>
  readonly vendorComplianceStatus: Maybe<VendorComplianceStatus>
  readonly vendorContracts: ReadonlyArray<VendorContract>
  /** All vendor invoice import operations for this contract. Global imports are not included. */
  readonly vendorInvoiceImportOperations: ReadonlyArray<VendorInvoiceImportOperation>
  readonly vendorNumPendingLegalDocuments: Scalars['Int']['output']
  readonly vendorNumPendingLienWaivers: Scalars['Int']['output']
  /** Vendor number for this contract, as defined by the General Contractor */
  readonly vendorNumber: Maybe<Scalars['String']['output']>
  /** List of GC recipients who will receive vendor materials, e.g., lien waivers & legal docs */
  readonly vendorSubmitToContacts: ReadonlyArray<CompanyContact>
  /**
   * Internal project vendors module ('lower tier') bulletin board. Notes and reminders are added
   * here by users on the project.
   */
  readonly vendorsInternalNotes: Maybe<Scalars['String']['output']>
}

export type ContractPayAppsArgs = {
  months?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>
}

export type ContractBilledPerTaxGroup = {
  readonly __typename: 'ContractBilledPerTaxGroup'
  readonly contract: Contract
  readonly scheduledValue: Scalars['SafeInt']['output']
  readonly state: Scalars['String']['output']
  readonly taxAmountThisPeriod: Scalars['SafeInt']['output']
  readonly taxGroup: TaxGroup
}

/** A billing projection over a series of months for a single contract */
export type ContractBillingForecast = {
  readonly __typename: 'ContractBillingForecast'
  readonly contract: Contract
  readonly createdAt: Scalars['DateTime']['output']
  /**
   * The curve used to generate the projection amounts. If the user edits the amounts after
   * generating the curve, it will be saved as a CUSTOM curve.
   */
  readonly curve: ForecastCurve
  /**
   * The first month that was editable when the forecast was created. This is used for clients to
   * determine when the user started projecting monthly values, as opposed to values that shouldn't be
   * included in the projection because they weren't editable at the time of creation (those values
   * may still be included as zeroes in `projectedAmountPerMonth` because the total forecast starts
   * at the contract's first billing month. Note that this may not be the same as the `createdAt`
   * month, since current or future pay apps could have been submitted already when the forecast was
   * created.
   */
  readonly firstEditableMonthWhenCreated: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  readonly lastEditedBy: User
  /**
   * The projected billing amounts for each month. Each element in the array corresponds to a
   * consecutive month, starting with `startingMonth`.
   */
  readonly projectedAmountPerMonth: ReadonlyArray<Scalars['SafeInt']['output']>
  /**
   * The amount that is considered unallocated in the billing at the current time.
   * Equivalent to the total contract balance remaining (from the last pay app submitted)
   * minus the amount projected from this month forward.
   */
  readonly remainingAmount: Scalars['SafeInt']['output']
  /**
   * The first month to include in the forecast. The first element in the `amountsProjected` array
   * corresponds to this month.
   */
  readonly startingMonth: Scalars['DateTime']['output']
  readonly timeZone: Scalars['String']['output']
  readonly updatedAt: Scalars['DateTime']['output']
}

/**
 * A type of notification (or reminders) to send to general contractors for collection of payments.
 * For instance, we can reminder GCs that a due date is coming up.
 * When a pay app is submitted, we copy the notification settings to new `PayAppCollectionsNotification` rows.
 */
export type ContractCollectionsNotification = {
  readonly __typename: 'ContractCollectionsNotification'
  readonly archivedAt: Maybe<Scalars['DateTime']['output']>
  /** Template of the notification body. This is not editable by the user. */
  readonly bodyTemplate: Scalars['String']['output']
  readonly contract: Contract
  readonly createdAt: Scalars['DateTime']['output']
  /** Recipients of the notification */
  readonly gcContacts: ReadonlyArray<CompanyContact>
  readonly id: Scalars['ID']['output']
  /** Internal CC'd recipients of the notification */
  readonly internalCcRecipients: ReadonlyArray<User>
  /** Number of days/weeks/months */
  readonly intervalAmount: Scalars['SafeInt']['output']
  /** Submission date / due date */
  readonly intervalAnchor: CollectionsNotificationAnchor
  /** Day / week / month */
  readonly intervalGranularity: CollectionsNotificationGranularity
  /** Before / after */
  readonly intervalModifier: CollectionsNotificationModifier
  /** Who last edited this contract notification */
  readonly lastEditedBy: Maybe<User>
  /** Pay app notifications based on this contract notification */
  readonly payAppNotifications: ReadonlyArray<PayAppCollectionsNotification>
  /** Person to send the notification on behalf of */
  readonly sender: User
  /** Template of the notification subject. This is not editable by the user. */
  readonly subjectTemplate: Scalars['String']['output']
  /** Type of notification to send */
  readonly type: CollectionsNotificationType
  readonly updatedAt: Scalars['DateTime']['output']
}

export type ContractComment = {
  readonly __typename: 'ContractComment'
  readonly contract: Contract
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  readonly message: Scalars['String']['output']
  readonly type: ContractCommentType
  readonly user: User
}

export { ContractCommentType }

/**
 * Cost analysis for a single contract.
 * Can be read from ERP or manually entered.
 */
export type ContractCost = {
  readonly __typename: 'ContractCost'
  readonly additionalCommittedCost: Scalars['SafeInt']['output']
  readonly billingsInExcessOfCost: Scalars['SafeInt']['output']
  readonly contract: Contract
  readonly contractTotalBilled: Scalars['SafeInt']['output']
  readonly contractTotalValue: Scalars['SafeInt']['output']
  readonly costPercentageCompleted: Scalars['Float']['output']
  readonly costToDate: Scalars['SafeInt']['output']
  readonly createdAt: Scalars['DateTime']['output']
  readonly earnedRevenue: Scalars['SafeInt']['output']
  readonly id: Scalars['ID']['output']
  readonly manuallyUpdatedBy: Maybe<User>
  readonly totalEstimatedCost: Scalars['SafeInt']['output']
  readonly updatedAt: Scalars['DateTime']['output']
  readonly updatedFromIntegrationType: Maybe<IntegrationType>
}

export type ContractEvent = {
  readonly __typename: 'ContractEvent'
  readonly contract: Contract
  readonly createdAt: Scalars['DateTime']['output']
  readonly createdBy: User
  readonly id: Scalars['ID']['output']
  readonly isAdmin: Scalars['Boolean']['output']
  readonly metadata: Scalars['JSONObject']['output']
  readonly type: ContractEventType
}

export { ContractEventType }

export { ContractListContractStatusFilter }

export type ContractListOverview = {
  readonly __typename: 'ContractListOverview'
  readonly activeCount: Scalars['Int']['output']
  readonly archivedCount: Scalars['Int']['output']
  readonly fullyBilledCount: Scalars['Int']['output']
  readonly onboardingCount: Scalars['Int']['output']
}

export { ContractListPayAppStatusFilter }

export { ContractListSortCriteria }

export { ContractListSubmitViaFilter }

export { ContractMonthlyStatusFilter }

export type ContractOptedOutMonth = {
  readonly __typename: 'ContractOptedOutMonth'
  readonly contract: Contract
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  /** Month in MM-YYYY format that is not being billed for */
  readonly month: Scalars['String']['output']
  readonly reason: Maybe<Scalars['String']['output']>
  /** The user who opted out of billing for the month */
  readonly user: Maybe<User>
}

/** Represents an attachment that will be copied as backup on every pay app created on a contract */
export type ContractPayAppAttachment = {
  readonly __typename: 'ContractPayAppAttachment'
  readonly contract: Contract
  readonly createdAt: Scalars['DateTime']['output']
  readonly description: Maybe<Scalars['String']['output']>
  readonly file: StoredFile
  readonly id: Scalars['ID']['output']
  readonly isEmailAttachment: Scalars['Boolean']['output']
  readonly isInternalOnly: Scalars['Boolean']['output']
  readonly name: Scalars['String']['output']
  readonly updatedAt: Scalars['DateTime']['output']
}

export type ContractPaymentPeriod = {
  readonly __typename: 'ContractPaymentPeriod'
  readonly dateRange: Maybe<PaymentPeriodDateRange>
  readonly hasRetentionOnlyPayApps: Scalars['Boolean']['output']
  readonly payApps: ReadonlyArray<PayApp>
  readonly predictedPaymentAmount: Scalars['SafeInt']['output']
}

export { ContractPaymentTermsType }

export type ContractRateTableFee = {
  readonly __typename: 'ContractRateTableFee'
  readonly contract: Contract
  /** The name of the fee, e.g. Overhead, Markup, Profit */
  readonly description: Scalars['String']['output']
  /** If provided, this fee applies to a specific group */
  readonly group: Maybe<RateTableGroup>
  readonly id: Scalars['ID']['output']
  /** If provided, this fee overrides another fee at the contract (global) level. It must be a group fee in this case. */
  readonly overridesFee: Maybe<ContractRateTableFee>
  /** This fee is added to the amount billed on a line item */
  readonly percent: Scalars['Float']['output']
}

export type ContractRateTableTaxGroup = {
  readonly __typename: 'ContractRateTableTaxGroup'
  readonly contract: Contract
  readonly id: Scalars['ID']['output']
  readonly rateTableGroup: RateTableGroup
  readonly taxGroup: TaxGroup
}

export { ContractStatus }

/** Users who are members of the provided contract */
export type ContractUser = {
  readonly __typename: 'ContractUser'
  readonly contract: Contract
  readonly id: Scalars['ID']['output']
  readonly role: ContractUserRole
  readonly user: User
}

export { ContractUserRole }

export type ContractsByIdInput = {
  readonly contractIds: ReadonlyArray<Scalars['ID']['input']>
}

export type ConvertRetentionTrackingLevelInput = {
  readonly contractId: Scalars['ID']['input']
  readonly retentionTrackingLevel: RetentionTrackingLevel
}

export type CostCodeReport = {
  readonly __typename: 'CostCodeReport'
  readonly billedByCostCodeAndJobAllTime: ReadonlyArray<BillingByCostCodeAndJob>
  readonly billedByCostCodeAndJobThisMonth: ReadonlyArray<BillingByCostCodeAndJob>
  readonly billedPerCostCode: ReadonlyArray<BilledPerCostCode>
  readonly costCodeSummary: BillingDashboardSummary
}

export type CreateCompanyContactInput = {
  readonly companyId: Scalars['ID']['input']
  readonly companyName: Scalars['String']['input']
  readonly email: Scalars['EmailAddress']['input']
  readonly fullName: Scalars['String']['input']
}

export type CreateCompanyFormTemplateCollectionInput = {
  readonly billingType: BillingType
  readonly changeOrderLogVariantId?: InputMaybe<Scalars['ID']['input']>
  readonly changeOrderRequestVariantId?: InputMaybe<Scalars['ID']['input']>
  readonly companyId: Scalars['ID']['input']
  readonly generalContractorCompanyId?: InputMaybe<Scalars['ID']['input']>
  readonly name: Scalars['String']['input']
  readonly payAppTemplateSetId?: InputMaybe<Scalars['ID']['input']>
  readonly primaryLienWaiverTemplateSetId?: InputMaybe<Scalars['ID']['input']>
  readonly state?: InputMaybe<Scalars['String']['input']>
  readonly vendorLienWaiverTemplateSetId?: InputMaybe<Scalars['ID']['input']>
}

export type CreateFormAnnotationInput = {
  readonly annotation: FormAnnotationInput
  /** The form template version on which this annotation is being created */
  readonly formTemplateVersionId: Scalars['ID']['input']
}

export type CreateFormAnnotationsInput = {
  readonly annotations: ReadonlyArray<FormAnnotationInput>
  readonly formTemplateVersionId: Scalars['ID']['input']
}

export type CreateFormTemplateInput = {
  readonly type: FormTemplateType
  readonly userVisibleName: Scalars['String']['input']
}

export type CreateFormTemplateVersionInput = {
  readonly file: Scalars['Upload']['input']
  readonly formTemplateId: Scalars['ID']['input']
}

export type CreateLienWaiverFormTemplateSetInput = {
  readonly companyId: Scalars['ID']['input']
  readonly generalContractorCompanyId?: InputMaybe<Scalars['ID']['input']>
  readonly isVendorSet: Scalars['Boolean']['input']
  readonly lienWaivers: SubcontractorLienWaiverTemplateSetInput
  readonly name: Scalars['String']['input']
  readonly state?: InputMaybe<Scalars['String']['input']>
}

export type CreateLocationInput = {
  readonly companyId: Scalars['ID']['input']
  readonly location: LocationInput
}

export type CreatePayAppFormTemplateSetInput = {
  readonly billingType: BillingType
  readonly companyId: Scalars['ID']['input']
  readonly generalContractorCompanyId?: InputMaybe<Scalars['ID']['input']>
  readonly name: Scalars['String']['input']
  readonly requirementGroups: ReadonlyArray<UpdatePayAppRequirementGroupInput>
  readonly state?: InputMaybe<Scalars['String']['input']>
}

export type CreateSovLineItemGroupInput = {
  readonly contractId: Scalars['ID']['input']
  readonly groupCode?: InputMaybe<Scalars['String']['input']>
  readonly groupName: Scalars['String']['input']
}

export type CreateTemplateVariantInput = {
  readonly formTemplateId: Scalars['ID']['input']
  readonly internalName: Scalars['String']['input']
  readonly userVisibleName?: InputMaybe<Scalars['String']['input']>
}

export type CreateTestTemplateInput = {
  readonly file: Scalars['Upload']['input']
}

export type CreateUserEducationCampaignInput = {
  readonly buttonLabel?: InputMaybe<Scalars['String']['input']>
  readonly buttonLink?: InputMaybe<Scalars['String']['input']>
  readonly endDate: Scalars['DateTime']['input']
  readonly includeNewTag: Scalars['Boolean']['input']
  readonly message: Scalars['String']['input']
  readonly name: Scalars['String']['input']
  readonly startDate: Scalars['DateTime']['input']
  readonly targetPermissions: ReadonlyArray<Permission>
  readonly title: Scalars['String']['input']
}

export { DashboardBillingStatus }

export type DeleteAnnotationOverrideInput = {
  readonly annotationPermanentId: Scalars['ID']['input']
  readonly variantId: Scalars['ID']['input']
}

export type DeleteCacheKeysInput = {
  readonly pattern: Scalars['String']['input']
  readonly type: CacheType
}

export type DeleteCompanyFormTemplateCollectionInput = {
  readonly collectionId: Scalars['ID']['input']
}

export type DeleteIntegrationCredentialInput = {
  readonly companyId: Scalars['ID']['input']
  readonly type: IntegrationType
}

export type DeleteLienWaiverFormTemplateSetInput = {
  readonly templateSetId: Scalars['ID']['input']
}

export type DeleteLienWaiverRequestsForMonthInput = {
  readonly companyId: Scalars['ID']['input']
  readonly month: Scalars['SafeInt']['input']
  readonly year: Scalars['SafeInt']['input']
}

export type DeletePayAppFormTemplateSetInput = {
  readonly templateSetId: Scalars['ID']['input']
}

export type DeletionResult = {
  readonly __typename: 'DeletionResult'
  /** The ID of the entity that was deleted */
  readonly id: Scalars['ID']['output']
}

export type DisableUserInput = {
  readonly companyId: Scalars['ID']['input']
  readonly userId: Scalars['ID']['input']
}

export { DocumentExpirationFrequency }

export { DocumentRequestStatus }

/** Represents a single send action of a legal document to a contact at a GC */
export type DocumentSendLog = {
  readonly __typename: 'DocumentSendLog'
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  readonly legalDocument: LegalDocument
  readonly recipients: ReadonlyArray<CompanyContact>
  readonly sender: User
}

export { DocumentType }

/** Represents an unsaved location object that was imported. */
export type DraftLocation = {
  readonly __typename: 'DraftLocation'
  readonly city: Scalars['String']['output']
  readonly coordinates: Scalars['LatLng']['output']
  readonly country: Scalars['String']['output']
  readonly county: Maybe<Scalars['String']['output']>
  readonly postalCode: Maybe<Scalars['String']['output']>
  readonly state: Scalars['String']['output']
  readonly street1: Maybe<Scalars['String']['output']>
  readonly street2: Maybe<Scalars['String']['output']>
  readonly timeZone: Scalars['String']['output']
}

export { DueToType }

export { EmailType }

export type EnableIntegrationCredentialAutoRotationInput = {
  readonly companyIntegrationId: Scalars['ID']['input']
  readonly emailAddress: Scalars['EmailAddress']['input']
  readonly onePasswordItemId: Scalars['String']['input']
  readonly onePasswordVaultId: Scalars['String']['input']
}

export type EnableLienWaiverInput = {
  readonly contractId: Scalars['ID']['input']
  readonly templates: SubcontractorLienWaiverTemplateSetInput
}

export type EnableUserInput = {
  readonly companyId: Scalars['ID']['input']
  readonly userId: Scalars['ID']['input']
}

export type EstimatedPayAppPaymentDate = {
  readonly __typename: 'EstimatedPayAppPaymentDate'
  readonly estimationMethod: PaymentDateEstimationType
  readonly paymentDate: Scalars['DateTime']['output']
}

export type ExportIntegrationCredentialsToPostmanInput = {
  readonly companyIntegrationId: Scalars['ID']['input']
}

export { ForecastCurve }

export type FormAnnotationInput = {
  readonly copyDefaultValueFromPreviousAnnotationValue: Scalars['Boolean']['input']
  readonly defaultValueKey?: InputMaybe<Scalars['String']['input']>
  readonly doNotRetainOnReset: Scalars['Boolean']['input']
  readonly dynamicFieldTag?: InputMaybe<Scalars['String']['input']>
  readonly fieldType?: InputMaybe<FormTemplateAnnotationMetadataFieldType>
  readonly fontColor: Scalars['String']['input']
  readonly fontFamily: FormTemplateFont
  readonly height: Scalars['Float']['input']
  readonly id: Scalars['ID']['input']
  readonly imageType?: InputMaybe<FormTemplateAnnotationImageType>
  readonly isOptional: Scalars['Boolean']['input']
  /** If the form has more than one page, this is the 1-indexed page the annotation is on */
  readonly pageNumber: Scalars['Int']['input']
  readonly prefix?: InputMaybe<Scalars['String']['input']>
  readonly selectedKey?: InputMaybe<Scalars['String']['input']>
  readonly signatureType?: InputMaybe<SignatureAnnotationType>
  readonly suffix?: InputMaybe<Scalars['String']['input']>
  readonly textAlignment: TextAlignment
  readonly type: FormTemplateAnnotationType
  readonly userVisibleName: Scalars['String']['input']
  readonly width: Scalars['Float']['input']
  readonly wrapText: Scalars['Boolean']['input']
  readonly xStart: Scalars['Float']['input']
  readonly yStart: Scalars['Float']['input']
}

/**
 * Represents a single template that can be used on many pay apps across many companies.
 * One template has one version or more. The latest version is used when rendering entities that don't
 * have pinned templates. For instance, a legal requirement will render using the latest version
 * of its template, unless the document has a pinned `formTemplateVersion` field.
 */
export type FormTemplate = {
  readonly __typename: 'FormTemplate'
  /**
   * The subcontractor, GC, or owner that created the original form. For example, the AGA G702's
   * associated company is AGA while the Webcor Cover Sheet's associated company would be Webcor (not
   * the sub that sent us the form to onboard). If no company is associated (i.e. the Base G702), this
   * would be undefined.
   */
  readonly associatedCompany: Maybe<Company>
  /** Contracts that this template is being built for */
  readonly associatedContracts: ReadonlyArray<FormTemplateAssociatedContract>
  /** User who will build the FormTemplate */
  readonly builder: User
  /** Free form comments on the form template. */
  readonly comments: ReadonlyArray<FormTemplateComment>
  readonly createdAt: Scalars['DateTime']['output']
  /** Target date to have the form built by */
  readonly dueDate: Maybe<Scalars['DateTime']['output']>
  /** If this template is a duplicate, this is a reference to the built duplicate template. */
  readonly duplicateTemplate: Maybe<FormTemplate>
  readonly id: Scalars['String']['output']
  /**
   * Whether the form template is ready to be shown to customers and used when generating forms.
   * True if the form template status is COMPLETED or if `skippedValidation` is true.
   */
  readonly isCustomerReady: Scalars['Boolean']['output']
  readonly latestVersion: Maybe<FormTemplateVersion>
  /** The original template that was submitted by the customer */
  readonly originalFile: Maybe<StoredFile>
  /** Form manager during the build/validator process. Usually a CSM. */
  readonly owner: User
  /** Which company, if any, requested this form template to be built */
  readonly requestingCompany: Maybe<Company>
  /**
   * Sometimes a customer needs a form onboarded ASAP before we have time to validate. In that case,
   * the owner is allowed to skip validation on the form and add it to a project immediately. However,
   * the form remains in the same state it was before so that it can be finished. i.e. if it was in
   * validation state, it will stay in that state until it is validated. Once the form is completed,
   * this boolean will revert to false.
   */
  readonly skippedValidation: Scalars['Boolean']['output']
  /** Latest status in FormTemplateStatusLog */
  readonly status: FormTemplateStatus
  /** Insertion time of the latest status in FormTemplateStatusLog */
  readonly statusChangedAt: Scalars['DateTime']['output']
  /** The current status of the template. Only COMPLETED forms should be added to live projects. */
  readonly statusLogs: ReadonlyArray<FormTemplateStatusLog>
  /** Tags for a template. i.e. a template could have NOTARIZED and G702. */
  readonly tags: ReadonlyArray<FormTemplateTag>
  readonly type: FormTemplateType
  readonly updatedAt: Scalars['DateTime']['output']
  /** This is the name of the form under the thumbnail on the sign page */
  readonly userVisibleName: Scalars['String']['output']
  /** User who will validate the FormTemplate */
  readonly validator: Maybe<User>
  readonly variants: ReadonlyArray<FormTemplateVariant>
  readonly versions: ReadonlyArray<FormTemplateVersion>
}

/** Represents a single annotation that will be rendered on top of a PDF FormTemplate. */
export type FormTemplateAnnotation = {
  readonly __typename: 'FormTemplateAnnotation'
  /**
   * Only for type USER_ENTERED_FIELD. Indicates whether the default value of this annotation
   * Should be copied from the previous annotation value (using the permanent ID).
   */
  readonly copyDefaultValueFromPreviousAnnotationValue: Scalars['Boolean']['output']
  readonly createdAt: Scalars['DateTime']['output']
  /**
   * Only for type USER_ENTERED_FIELD. Indicates the template variable path/key to use as a
   * default annotation value.
   */
  readonly defaultValueKey: Maybe<Scalars['String']['output']>
  /**
   * Only for type USER_ENTERED_FIELD. Must also have defaultValueKey set. Indicates whether
   * this value should always be cleared when retaining annotations.
   */
  readonly doNotRetainOnReset: Scalars['Boolean']['output']
  /**
   * Used for dynamic templates to match annotations to template tags.
   * Usage in XLSX/DOCX: {$field}
   * Usage in annotation metadata: field
   */
  readonly dynamicFieldTag: Maybe<Scalars['String']['output']>
  /**
   * Only for type USER_ENTERED_FIELD. Known type of an annotation.
   * Annotations are opaque by default and we don't know what they mean. It makes it difficult to
   * make sense of what an annotation value represents – which is fine for most annotations but
   * not when we need to extract things like "lien-waiver amount".
   * To workaround that, `metadata.fieldType` allows to tag specific annotations for meaningful
   * processing of annotation values.
   */
  readonly fieldType: Maybe<FormTemplateAnnotationMetadataFieldType>
  /**
   * This is the 6 digit hex code of the color, which will get transformed to the rgb() equivalent
   * when we actually render in the PDF because that's what the library requires.
   */
  readonly fontColor: Scalars['String']['output']
  readonly fontFamily: FormTemplateFont
  readonly formTemplateVersion: FormTemplateVersion
  readonly height: Scalars['Float']['output']
  readonly id: Scalars['ID']['output']
  /** Only for type IMAGE: type of image to render. */
  readonly imageType: Maybe<FormTemplateAnnotationImageType>
  /** Whether the annotation must have a value. */
  readonly isOptional: Scalars['Boolean']['output']
  /**
   * If a form has more than one page, this is the page number that the annotation is on. This
   * number is NOT zero-indexed, it begins at 1 because that is how pdf.js handles it.
   */
  readonly pageNumber: Scalars['Int']['output']
  /**
   * An identifier that persists across versions of the template, used to match annotations together
   * after a template version was cloned.
   */
  readonly permanentId: Scalars['ID']['output']
  /** Only for type TEMPLATE_VARIABLE. Text to insert before the template variable. */
  readonly prefix: Maybe<Scalars['String']['output']>
  /** Only for type TEMPLATE_VARIABLE. Indicates the template variable path/key. */
  readonly selectedKey: Maybe<Scalars['String']['output']>
  /** Only for type SIGNATURE: type of signature to render. */
  readonly signatureType: Maybe<SignatureAnnotationType>
  /** Only for type TEMPLATE_VARIABLE. Text to insert after the template variable. */
  readonly suffix: Maybe<Scalars['String']['output']>
  /**
   * Used to sync annotations on the same template and across different templates.
   * An annotation changes its value automatically when the user types in another annotation with
   * the same sync tag.
   */
  readonly syncTag: Maybe<Scalars['String']['output']>
  /** Horizontal alignment of text within the annotation bounds. Defaults to LEFT */
  readonly textAlignment: TextAlignment
  readonly type: FormTemplateAnnotationType
  readonly updatedAt: Scalars['DateTime']['output']
  /**
   * When we ask the user to type something into the annotation for the FormTemplateAnnotationValue, we
   * need to tell them what specifically they're typing in (eg. invoice number, etc).
   */
  readonly userVisibleName: Scalars['String']['output']
  readonly width: Scalars['Float']['output']
  /** Whether text should wrap within the annotation bounds. Defaults to false. Font size will not be reduced to fit. */
  readonly wrapText: Scalars['Boolean']['output']
  readonly xStart: Scalars['Float']['output']
  readonly yStart: Scalars['Float']['output']
}

export { FormTemplateAnnotationImageType }

export { FormTemplateAnnotationMetadataFieldType }

export { FormTemplateAnnotationType }

/**
 * Represents a user-entered value for any FormTemplateAnnotation that is of type
 * FormTemplateAnnotationType.USER_ENTERED_FIELD.
 */
export type FormTemplateAnnotationValue = {
  readonly __typename: 'FormTemplateAnnotationValue'
  readonly annotation: FormTemplateAnnotation
  readonly createdAt: Scalars['DateTime']['output']
  readonly createdBy: Maybe<User>
  readonly id: Scalars['ID']['output']
  readonly metadata: Scalars['JSONObject']['output']
  readonly updatedAt: Scalars['DateTime']['output']
  readonly value: Scalars['String']['output']
}

export type FormTemplateAssociatedContract = {
  readonly __typename: 'FormTemplateAssociatedContract'
  readonly contract: Contract
  readonly createdAt: Scalars['DateTime']['output']
  readonly formTemplate: FormTemplate
  readonly id: Scalars['String']['output']
  /**
   * If the form template will not be used in the way it was provided, this field can be flipped to
   * true. When flipped to true, this form template will no longer block the contract's onboarding
   * status from flipping to "done", since this form template is not expected to be used for the type
   * it was provided.
   */
  readonly ignoreProvidedAsFormType: Scalars['Boolean']['output']
  readonly providedAsFormType: Maybe<ProjectOnboardingFormType>
}

/** Represents a comment from a user on a FormTemplate. */
export type FormTemplateComment = {
  readonly __typename: 'FormTemplateComment'
  readonly createdAt: Scalars['DateTime']['output']
  readonly formTemplate: FormTemplate
  readonly id: Scalars['ID']['output']
  readonly isSystemGenerated: Scalars['Boolean']['output']
  readonly message: Scalars['String']['output']
  readonly user: User
}

export { FormTemplateFont }

export { FormTemplateOnboardingSignatureType }

export { FormTemplateStatus }

/** Represents a log of all statuses for a form template and when the transition occurred. */
export type FormTemplateStatusLog = {
  readonly __typename: 'FormTemplateStatusLog'
  readonly createdAt: Scalars['DateTime']['output']
  readonly formTemplate: FormTemplate
  readonly id: Scalars['ID']['output']
  readonly status: FormTemplateStatus
  readonly statusUpdatedAt: Scalars['DateTime']['output']
  readonly user: User
}

export { FormTemplateTag }

export { FormTemplateType }

/**
 * A template variant.
 * A single template has 1 or more variants, and each variant can override the annotations of the
 * the original template.
 */
export type FormTemplateVariant = {
  readonly __typename: 'FormTemplateVariant'
  readonly annotationOverrides: ReadonlyArray<AnnotationOverride>
  /** Whether $0 amounts should render as empty strings */
  readonly hidesZeroDollarAmounts: Maybe<Scalars['Boolean']['output']>
  readonly id: Scalars['ID']['output']
  readonly internalName: Scalars['String']['output']
  readonly isDefaultVariant: Scalars['Boolean']['output']
  /**
   * Whether percentages should be rounded.
   * By default, percentages are displayed with 2 decimal places.
   * Note that this does not affect tax and fee percentages, which have their own precision.
   */
  readonly roundPercentages: Maybe<Scalars['Boolean']['output']>
  readonly template: FormTemplate
  /** If true, use the company's notary signature image if available and skip the notary flow (download + upload). */
  readonly useCompanyNotarySignatureIfAvailable: Maybe<Scalars['Boolean']['output']>
  readonly userVisibleName: Maybe<Scalars['String']['output']>
}

/**
 * Version of a form template. Used by pay-apps/lien-waivers/legal-documents to pin templates to
 * one version in time.
 */
export type FormTemplateVersion = {
  readonly __typename: 'FormTemplateVersion'
  /** This is only used with static PDF templates, or when type equals StoredFileType.PDF */
  readonly annotations: ReadonlyArray<FormTemplateAnnotation>
  readonly createdAt: Scalars['DateTime']['output']
  readonly file: StoredFile
  readonly formTemplate: FormTemplate
  readonly id: Scalars['ID']['output']
  /** Whether this version uses the correct template variables for manual stored materials */
  readonly isReadyForManualStoredMaterials: Scalars['Boolean']['output']
  /** Text extracted from this version */
  readonly text: Maybe<FormTemplateVersionText>
  readonly updatedAt: Scalars['DateTime']['output']
  readonly versionNumber: Scalars['Int']['output']
}

export type FormTemplateVersionEntities = {
  readonly __typename: 'FormTemplateVersionEntities'
  readonly legalDocuments: ReadonlyArray<LegalDocument>
  readonly lienWaivers: ReadonlyArray<LienWaiver>
  readonly payApps: ReadonlyArray<PayApp>
}

/**
 * Text extracted from a form template version file, via OCR.
 * All lines of text and pages are concatenated together, with a newline character separating line or page.
 * We use this to run form matching on the whole set of templates.
 * The text field is indexed with a gin index, so it can be searched for text efficiently.
 */
export type FormTemplateVersionText = {
  readonly __typename: 'FormTemplateVersionText'
  readonly id: Scalars['ID']['output']
  readonly text: Scalars['String']['output']
  readonly version: FormTemplateVersion
}

export type FormTrackerData = {
  readonly __typename: 'FormTrackerData'
  readonly companyCreatedAt: Scalars['DateTime']['output']
  readonly companyId: Scalars['ID']['output']
  readonly companyName: Scalars['String']['output']
  readonly completedFormIds: ReadonlyArray<Scalars['ID']['output']>
  readonly completedFormsCount: Scalars['SafeInt']['output']
  readonly formsCompletedRatio: Scalars['Float']['output']
  readonly requestedFormIds: ReadonlyArray<Scalars['ID']['output']>
  readonly requestedFormsCount: Scalars['SafeInt']['output']
  readonly sitelinePointOfContactId: Maybe<Scalars['ID']['output']>
  readonly sitelinePointOfContactName: Maybe<Scalars['String']['output']>
}

export type FormTrackerInput = {
  /**
   * Optional start date so that you can narrow in on companies that may have submitted a new set of
   * forms recently
   */
  readonly startDate?: InputMaybe<Scalars['DateTime']['input']>
}

export type FormTurnaroundData = {
  readonly __typename: 'FormTurnaroundData'
  /** Basic info for all companies whose IDs are referenced in the forms list */
  readonly companies: ReadonlyArray<MinimalFormTurnaroundCompany>
  readonly forms: ReadonlyArray<FormTurnaroundFormData>
  /** Basic info for all users whose IDs are referenced in the forms list */
  readonly users: ReadonlyArray<MinimalFormTurnaroundUser>
}

export type FormTurnaroundDataInput = {
  readonly endDate: Scalars['DateTime']['input']
  readonly endStatus?: InputMaybe<FormTemplateStatus>
  readonly formWorkerId?: InputMaybe<Scalars['ID']['input']>
  readonly formWorkerRole?: InputMaybe<FormWorkerRole>
  readonly requestingCompanyId?: InputMaybe<Scalars['ID']['input']>
  readonly startDate: Scalars['DateTime']['input']
  readonly startStatus?: InputMaybe<FormTemplateStatus>
}

/** Data about a form template and the dates it was processed */
export type FormTurnaroundFormData = {
  readonly __typename: 'FormTurnaroundFormData'
  readonly endDate: Scalars['DateTime']['output']
  readonly formTemplate: MinimalFormTurnaroundFormTemplate
  readonly startDate: Scalars['DateTime']['output']
}

export { FormWorkerRole }

export type G702Values = {
  readonly __typename: 'G702Values'
  readonly balanceToFinish: Maybe<Scalars['SafeInt']['output']>
  readonly balanceToFinishWithRetention: Maybe<Scalars['SafeInt']['output']>
  readonly contractSumToDate: Scalars['SafeInt']['output']
  readonly currentPaymentDue: Scalars['SafeInt']['output']
  readonly materialsRetentionAmount: Scalars['SafeInt']['output']
  readonly materialsRetentionPercent: Scalars['Float']['output']
  readonly netChangeByChangeOrders: Scalars['SafeInt']['output']
  readonly originalContractSum: Scalars['SafeInt']['output']
  readonly previousPayments: Scalars['SafeInt']['output']
  readonly progressRetentionAmount: Scalars['SafeInt']['output']
  readonly progressRetentionPercent: Scalars['Float']['output']
  readonly totalCompletedToDate: Scalars['SafeInt']['output']
  readonly totalLessRetainage: Scalars['SafeInt']['output']
  readonly totalRetention: Scalars['SafeInt']['output']
}

export type GeneralContractorForDedupe = {
  readonly __typename: 'GeneralContractorForDedupe'
  readonly company: Company
  readonly contractCount: Scalars['SafeInt']['output']
}

export type GetCacheKeysInput = {
  readonly pattern: Scalars['String']['input']
  readonly type: CacheType
}

export type GetPaginatedCompaniesInput = {
  readonly cursor?: InputMaybe<Scalars['ID']['input']>
  readonly limit?: InputMaybe<Scalars['Int']['input']>
  readonly search?: InputMaybe<Scalars['String']['input']>
  readonly sortCriteria?: InputMaybe<CompanyListSortCriteria>
  readonly sortOrder?: InputMaybe<PaginatedListSortOrder>
}

export type GetPaginatedContractsInput = {
  readonly billingType?: InputMaybe<BillingType>
  readonly complianceStatus?: InputMaybe<ComplianceStatus>
  readonly contractStatus?: InputMaybe<ContractListContractStatusFilter>
  readonly cursor?: InputMaybe<Scalars['ID']['input']>
  readonly generalContractorIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
  /** Filter returned contracts based on whether or not they have a billing forecast */
  readonly hasBillingForecast?: InputMaybe<Scalars['Boolean']['input']>
  /** Filter returned contracts based on whether or not they have any forms still being processed by the admin team */
  readonly isProcessingForms?: InputMaybe<Scalars['Boolean']['input']>
  readonly leadPMIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
  readonly limit?: InputMaybe<Scalars['Int']['input']>
  readonly month?: InputMaybe<Scalars['String']['input']>
  readonly officeIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
  readonly payAppStatus?: InputMaybe<ContractListPayAppStatusFilter>
  readonly projectIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
  readonly search?: InputMaybe<Scalars['String']['input']>
  readonly sortCriteria?: InputMaybe<ContractListSortCriteria>
  readonly sortOrder?: InputMaybe<PaginatedListSortOrder>
  readonly submitVia?: InputMaybe<ContractListSubmitViaFilter>
  readonly templateIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
}

export type GetPaginatedTemplatesInput = {
  readonly assigneeId?: InputMaybe<Scalars['ID']['input']>
  readonly associatedContractId?: InputMaybe<Scalars['ID']['input']>
  readonly cursor?: InputMaybe<Scalars['ID']['input']>
  readonly includeInactive?: InputMaybe<Scalars['Boolean']['input']>
  readonly limit?: InputMaybe<Scalars['Int']['input']>
  /** Associated company or requesting company */
  readonly relatedCompanyId?: InputMaybe<Scalars['ID']['input']>
  readonly search?: InputMaybe<Scalars['String']['input']>
  readonly sortCriteria?: InputMaybe<TemplateListSortCriteria>
  readonly sortOrder?: InputMaybe<PaginatedListSortOrder>
  readonly status?: InputMaybe<TemplateListStatusFilter>
  readonly tags?: InputMaybe<ReadonlyArray<FormTemplateTag>>
  readonly type?: InputMaybe<FormTemplateType>
  readonly waitingOnId?: InputMaybe<Scalars['ID']['input']>
}

export type GetPaginatedWriteSyncOperationsInput = {
  readonly cursor?: InputMaybe<Scalars['ID']['input']>
  readonly limit?: InputMaybe<Scalars['Int']['input']>
  readonly search?: InputMaybe<Scalars['String']['input']>
  readonly sort?: InputMaybe<WriteSyncOperationsSort>
  readonly status?: InputMaybe<WriteSyncOperationStatus>
}

export type IgnoreFormTemplateProvidedTypeInput = {
  readonly formTemplateAssociatedContractId: Scalars['ID']['input']
}

export { ImportIntegrationChangeOrdersMethod }

export { ImportIntegrationComboJobMethod }

export { ImportIntegrationProjectNameSource }

export { ImportOperationStatus }

/** All relevant metadata returned about a project that was imported from an integration. */
export type ImportProjectOnboardingMetadata = {
  readonly __typename: 'ImportProjectOnboardingMetadata'
  readonly architect: Maybe<IntegrationCompany>
  readonly companyIntegrationId: Scalars['ID']['output']
  readonly contract: IntegrationContract
  readonly generalContractor: Maybe<IntegrationCompany>
  readonly integrationAssociatedCompanyId: Maybe<Scalars['String']['output']>
  readonly owner: Maybe<IntegrationCompany>
  readonly project: IntegrationProjectDetails
  readonly taxCalculationType: TaxCalculationType
  readonly taxGroup: Maybe<IntegrationTaxGroup>
  readonly type: IntegrationType
}

export type ImportSovFromIntegrationInput = {
  readonly integrationId: Scalars['ID']['input']
}

/**
 * Note: because an AP invoice can belong to multiple contracts,
 * there can be multiple instances of the same ERP invoice in Siteline,
 * with the same `integrationInvoiceId` but a different `integrationContractId`.
 */
export type ImportedVendorInvoice = {
  readonly __typename: 'ImportedVendorInvoice'
  readonly amount: Scalars['Float']['output']
  /** May be a partial amount if the full invoice has not been paid */
  readonly amountPaid: Scalars['Float']['output']
  readonly code: Scalars['String']['output']
  readonly createdAt: Scalars['DateTime']['output']
  readonly description: Scalars['String']['output']
  readonly importOperation: VendorInvoiceImportOperation
  /** Provided when imported from ERP */
  readonly integrationContractId: Maybe<Scalars['String']['output']>
  /** Provided when imported from ERP. Not unique because an invoice may have line items across multiple jobs */
  readonly integrationInvoiceId: Maybe<Scalars['String']['output']>
  /** Provided when imported from ERP */
  readonly integrationProjectId: Maybe<Scalars['String']['output']>
  /** Provided when imported from ERP */
  readonly integrationVendorCode: Maybe<Scalars['String']['output']>
  /** Provided when imported from ERP */
  readonly integrationVendorId: Maybe<Scalars['String']['output']>
  /**
   * Internal project number that we'll use to link the invoice to a Siteline project.
   * This is used only if the integration fields are not provided, since they are more accurate.
   * Note that we can import invoices that don't have a matching Siteline contract, so there is no
   * guarantee that this project number exists in Siteline.
   */
  readonly internalProjectNumber: Maybe<Scalars['String']['output']>
  /** Format is YYYY-MM-DD */
  readonly invoiceDate: Scalars['String']['output']
  readonly isFullyPaid: Scalars['Boolean']['output']
  /** Payment date of when the invoice is fully paid. Format is YYYY-MM-DD */
  readonly paymentDate: Maybe<Scalars['String']['output']>
  /** All payment dates for the invoice. Note that the invoice may be partially paid. */
  readonly paymentDates: ReadonlyArray<Scalars['String']['output']>
  /** All payment numbers for the invoice. Note that the invoice may be partially paid. */
  readonly paymentNumbers: ReadonlyArray<Scalars['String']['output']>
  readonly retentionAmount: Scalars['Float']['output']
  /**
   * If a vendor in Siteline (belonging to the current user's company) is linked to this vendor in the
   * integration, this is the vendor's Siteline ID
   */
  readonly sitelineVendorId: Maybe<Scalars['ID']['output']>
  readonly vendorName: Scalars['String']['output']
}

/** A contract-level integration (textura, gcpay, etc) */
export type Integration = {
  readonly __typename: 'Integration'
  /**
   * If true, allow editing the SOV even if this is a GC portal integration and ignore all changes to
   * existing line items when importing the SOV from the integration
   */
  readonly allowGcPortalManualSovEditing: Scalars['Boolean']['output']
  readonly companyIntegration: CompanyIntegration
  readonly contract: Contract
  readonly createdAt: Scalars['DateTime']['output']
  readonly credentialsUpdatedAt: Maybe<Scalars['DateTime']['output']>
  readonly id: Scalars['ID']['output']
  readonly isActive: Scalars['Boolean']['output']
  readonly longName: Scalars['String']['output']
  /** Mappings of Siteline entities to Integration entities. Fields depend on the integration type. */
  readonly mappings: Scalars['JSONObject']['output']
  /**
   * Integration specific metadata, primarly for file-based integrations. Fields depend on the
   * integration type. Kept separate from mappings because this can be updated by the frontend.
   */
  readonly metadata: Scalars['JSONObject']['output']
  readonly shortName: Scalars['String']['output']
  readonly type: IntegrationType
  readonly writeSyncOperations: ReadonlyArray<WriteSyncOperation>
}

export type IntegrationCompany = {
  readonly __typename: 'IntegrationCompany'
  readonly companyAddress: Maybe<DraftLocation>
  readonly companyName: Maybe<Scalars['String']['output']>
  readonly sitelineCompanyId: Maybe<Scalars['String']['output']>
  readonly sitelineLocation: Maybe<Location>
}

export type IntegrationContract = {
  readonly __typename: 'IntegrationContract'
  readonly billingType: Maybe<BillingType>
  readonly contractDate: Maybe<Scalars['DateTime']['output']>
  readonly contractName: Maybe<Scalars['String']['output']>
  readonly contractNumber: Maybe<Scalars['String']['output']>
  /**
   * Some integrations (e.g. Foundation) will not have a contract for every project. We still
   * use this contract object, as some contract data may be available from the job setup, but do
   * not require a contract ID.
   */
  readonly integrationContractId: Maybe<Scalars['String']['output']>
  readonly latestPayAppNumber: Maybe<Scalars['SafeInt']['output']>
  readonly payAppDueDate: Maybe<Scalars['SafeInt']['output']>
  readonly paymentTerms: Maybe<Scalars['SafeInt']['output']>
  readonly paymentTermsType: Maybe<ContractPaymentTermsType>
  /**
   * Amount billed pre-siteline.
   * This is only for T&M contracts. Other contracts must use the SOV to indicate pre-siteline billed.
   */
  readonly preSitelineBilled: Maybe<Scalars['SafeInt']['output']>
  readonly retentionPercent: Maybe<Scalars['Float']['output']>
  readonly retentionTrackingLevel: Maybe<RetentionTrackingLevel>
  readonly roundRetention: Maybe<Scalars['Boolean']['output']>
  readonly storedMaterialsCarryoverType: Maybe<StoredMaterialsCarryoverType>
}

export type IntegrationCustomer = {
  readonly __typename: 'IntegrationCustomer'
  readonly code: Maybe<Scalars['String']['output']>
  readonly integrationCustomerId: Scalars['String']['output']
  readonly name: Scalars['String']['output']
}

export type IntegrationGeneralLedgerAccount = {
  readonly __typename: 'IntegrationGeneralLedgerAccount'
  readonly code: Scalars['String']['output']
  readonly integrationAccountId: Scalars['String']['output']
  readonly name: Scalars['String']['output']
  readonly parentCode: Maybe<Scalars['String']['output']>
  readonly parentId: Maybe<Scalars['String']['output']>
  readonly parentName: Maybe<Scalars['String']['output']>
}

export type IntegrationIncomeTypeNumber = {
  readonly __typename: 'IntegrationIncomeTypeNumber'
  readonly description: Scalars['String']['output']
  readonly incomeTypeNumber: Scalars['String']['output']
  readonly integrationIncomeTypeNumberId: Scalars['String']['output']
}

export type IntegrationInvoiceType = {
  readonly __typename: 'IntegrationInvoiceType'
  readonly hasAutoNumberingEnabled: Scalars['Boolean']['output']
  readonly invoiceType: Scalars['String']['output']
}

export type IntegrationInvoiceTypesInput = {
  readonly companyIntegrationId: Scalars['ID']['input']
}

export type IntegrationOnePasswordItem = {
  readonly __typename: 'IntegrationOnePasswordItem'
  readonly itemId: Scalars['ID']['output']
  readonly title: Scalars['String']['output']
  readonly vaultId: Scalars['ID']['output']
}

export type IntegrationProjectDetails = {
  readonly __typename: 'IntegrationProjectDetails'
  readonly integrationProjectId: Scalars['String']['output']
  readonly internalNotes: Maybe<Scalars['String']['output']>
  readonly internalProjectNumber: Maybe<Scalars['String']['output']>
  readonly projectAddress: Maybe<DraftLocation>
  readonly projectName: Scalars['String']['output']
  readonly projectNumber: Maybe<Scalars['String']['output']>
  /** The Siteline User IDs of users who should be added to the project as lead PM */
  readonly sitelineLeadPMIds: Maybe<ReadonlyArray<Scalars['String']['output']>>
}

export type IntegrationProjectMigrationSummary = {
  readonly __typename: 'IntegrationProjectMigrationSummary'
  /** Contracts with an integration of the right type where no clear match was found to migrate */
  readonly contractsIgnored: ReadonlyArray<Contract>
  /** Contracts where a match was found and a project import operation was queued */
  readonly contractsQueued: ReadonlyArray<Contract>
}

export type IntegrationProjectSummary = {
  readonly __typename: 'IntegrationProjectSummary'
  readonly contractName: Maybe<Scalars['String']['output']>
  readonly generalContractorName: Maybe<Scalars['String']['output']>
  readonly integrationAssociatedCompanyId: Maybe<Scalars['String']['output']>
  /**
   * "
   * If a contract ID is not provided, the integration may not require contracts for every job and
   * we will still allow onboarding the project with the information we have
   */
  readonly integrationContractId: Maybe<Scalars['String']['output']>
  readonly integrationProjectId: Maybe<Scalars['String']['output']>
  readonly projectName: Scalars['String']['output']
  readonly projectNumber: Maybe<Scalars['String']['output']>
  readonly sitelineContractId: Maybe<Scalars['String']['output']>
  /** Provided if already onboarded to Siteline */
  readonly sitelineProjectId: Maybe<Scalars['String']['output']>
}

export type IntegrationProjectsFilter = {
  readonly __typename: 'IntegrationProjectsFilter'
  /**
   * Should match one of the values in the `options` array. If not provided, will
   * default to the first option, or 'All' if `showAllOption` is true.
   */
  readonly defaultValue: Maybe<Scalars['String']['output']>
  readonly field: IntegrationProjectsFilterField
  /** If multiple options can be selected at once */
  readonly multiple: Scalars['Boolean']['output']
  /** These are the options to show for a specific integration */
  readonly options: ReadonlyArray<IntegrationProjectsFilterOption>
  /**
   * If true, an 'All' option is shown. This would be the default option if true.
   * Note that if this is false but `multiple` is true, it will still be possible
   * to select every option. But a single 'All' option would not be shown.
   */
  readonly showAllOption: Scalars['Boolean']['output']
}

export { IntegrationProjectsFilterField }

export type IntegrationProjectsFilterOption = {
  readonly __typename: 'IntegrationProjectsFilterOption'
  /** A label to show in the UI for this option. Can be the same as the value. */
  readonly label: Scalars['String']['output']
  /** The unique value that will be used for filtering on the corresponding field */
  readonly value: Scalars['String']['output']
}

export type IntegrationSov = {
  readonly __typename: 'IntegrationSov'
  readonly lineItems: ReadonlyArray<IntegrationSovLineItem>
}

export type IntegrationSovLineItem = {
  readonly __typename: 'IntegrationSovLineItem'
  /** Total value in cents billed to date */
  readonly billedToDate: Scalars['Float']['output']
  readonly code: Scalars['String']['output']
  readonly description: Scalars['String']['output']
  /** Could be null if the integration doesn't have IDs */
  readonly integrationLineItemId: Maybe<Scalars['String']['output']>
  readonly integrationTaxGroup: Maybe<IntegrationTaxGroup>
  /** Total value of retention in cents to date */
  readonly retentionToDate: Scalars['Float']['output']
  readonly scheduledValue: Scalars['Float']['output']
  /** Total unit price in cents, same as scheduledValue if not a unit price contract */
  readonly unitPrice: Scalars['Decimal']['output']
}

export { IntegrationSyncResultCode }

export type IntegrationTaxGroup = {
  readonly __typename: 'IntegrationTaxGroup'
  readonly integrationTaxGroupId: Scalars['String']['output']
  readonly name: Scalars['String']['output']
  /** Tax percentage for this group, between 0 and 1 */
  readonly taxPercent: Scalars['Float']['output']
}

export { IntegrationType }

export type IntegrationVendor = {
  readonly __typename: 'IntegrationVendor'
  readonly address: Maybe<Scalars['String']['output']>
  readonly contacts: ReadonlyArray<IntegrationVendorContact>
  readonly integrationVendorId: Scalars['String']['output']
  readonly name: Scalars['String']['output']
}

export type IntegrationVendorContact = {
  readonly __typename: 'IntegrationVendorContact'
  readonly email: Maybe<Scalars['EmailAddress']['output']>
  readonly integrationVendorContactId: Scalars['String']['output']
  readonly name: Maybe<Scalars['String']['output']>
  readonly phoneNumber: Maybe<Scalars['PhoneNumber']['output']>
  readonly title: Maybe<Scalars['String']['output']>
}

export type IntegrationVendorInvoice = {
  readonly __typename: 'IntegrationVendorInvoice'
  readonly amount: Scalars['Float']['output']
  /** May be a partial amount if the full invoice has not been paid */
  readonly amountPaid: Scalars['Float']['output']
  readonly code: Scalars['String']['output']
  readonly description: Scalars['String']['output']
  readonly integrationContractId: Maybe<Scalars['String']['output']>
  readonly integrationInvoiceId: Scalars['String']['output']
  readonly integrationProjectId: Scalars['String']['output']
  readonly integrationVendorCode: Scalars['String']['output']
  readonly integrationVendorId: Scalars['String']['output']
  /** Format is YYYY-MM-DD */
  readonly invoiceDate: Scalars['String']['output']
  readonly isFullyPaid: Scalars['Boolean']['output']
  /** Payment date of when the invoice is fully paid. Format is YYYY-MM-DD */
  readonly paymentDate: Maybe<Scalars['String']['output']>
  /** All payment dates for the invoice. Note that the invoice may be partially paid. */
  readonly paymentDates: ReadonlyArray<Scalars['String']['output']>
  /** All payment numbers for the invoice. Note that the invoice may be partially paid. */
  readonly paymentNumbers: ReadonlyArray<Scalars['String']['output']>
  readonly retentionAmount: Scalars['Float']['output']
  /**
   * If a vendor in Siteline (belonging to the current user's company) is linked to this vendor in the
   * integration, this is the vendor's Siteline ID
   */
  readonly sitelineVendorId: Maybe<Scalars['ID']['output']>
  readonly vendorName: Scalars['String']['output']
}

export type InviteCompanyUserInput = {
  readonly companyId: Scalars['String']['input']
  readonly companyUser: CompanyUserInput
}

/** Represents a document for a legal requirement that can be accessed by a URL */
export type LegalDocument = {
  readonly __typename: 'LegalDocument'
  readonly createdAt: Scalars['DateTime']['output']
  /** A list of each time the document has been sent. */
  readonly documentSentLog: Maybe<ReadonlyArray<DocumentSendLog>>
  /** Files can be uploaded by users or by lower-tier subs, who are not users */
  readonly file: Maybe<StoredFile>
  /**
   * Version of a template that this document must be rendered with. If null, the legal requirement
   * form template will be used instead. This cannot be provided if isUploaded is true.
   */
  readonly formTemplateVersion: Maybe<FormTemplateVersion>
  /** A list of all user-entered values for the form on the LegalRequirement */
  readonly formValues: ReadonlyArray<FormTemplateAnnotationValue>
  readonly id: Scalars['ID']['output']
  readonly isUploaded: Scalars['Boolean']['output']
  /** The time that this document was last sent in an email, if relevant */
  readonly lastSendTime: Maybe<Scalars['DateTime']['output']>
  /** The last write sync for this entity. Null if not successful */
  readonly lastSyncIfSuccessful: Maybe<WriteSyncOperation>
  /** All requests that exist for this legal document */
  readonly legalDocumentRequests: ReadonlyArray<LegalDocumentRequest>
  readonly legalRequirement: LegalRequirement
  readonly name: Scalars['String']['output']
  /**
   * This represents the end of the recurring period or the user-input expiration
   * date. This will be set unless the expiration frequency is set to NEVER.
   */
  readonly periodEnd: Maybe<Scalars['DateTime']['output']>
  /** For recurring documents, this is the start of the recurring period. */
  readonly periodStart: Maybe<Scalars['DateTime']['output']>
  /** Signature of the user who signed. This is also used for when the signature was added. */
  readonly signature: Maybe<Signature>
  readonly updatedAt: Scalars['DateTime']['output']
  /** Files can be uploaded by users or by lower-tier subs, who are not users */
  readonly uploadedBy: Maybe<User>
  /** If this legal document is for a vendor legal requirement, the vendor contract will be specified here. */
  readonly vendorContract: Maybe<VendorContract>
}

/** Request of a legal document from a vendor contact. */
export type LegalDocumentRequest = {
  readonly __typename: 'LegalDocumentRequest'
  readonly actionTakenAt: Maybe<Scalars['DateTime']['output']>
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  readonly legalDocument: LegalDocument
  readonly legalRequirement: LegalRequirement
  /** List of users who have created or modified this legal document request. */
  readonly modifiedBy: ReadonlyArray<User>
  readonly status: DocumentRequestStatus
  readonly updatedAt: Scalars['DateTime']['output']
  readonly vendorContact: VendorContact
  readonly vendorContract: VendorContract
}

/** Represents a single legal requirement for a project */
export type LegalRequirement = {
  readonly __typename: 'LegalRequirement'
  readonly contract: Contract
  readonly createdAt: Scalars['DateTime']['output']
  readonly documents: ReadonlyArray<LegalDocument>
  /** [Optional] The last date to get a recurring requirement. */
  readonly endDate: Maybe<Scalars['DateTime']['output']>
  readonly expirationFrequency: DocumentExpirationFrequency
  /** An optional template that can be filled out on Siteline */
  readonly formTemplate: Maybe<FormTemplate>
  readonly id: Scalars['ID']['output']
  /** Whether this is a requirement from the subcontractor to lower-tier vendors */
  readonly isVendorRequirement: Scalars['Boolean']['output']
  /** Fields missing that are required to generate this legal requirement's forms */
  readonly missingRequiredFields: ReadonlyArray<RequiredFieldForForms>
  readonly name: Scalars['String']['output']
  readonly skippedPeriods: ReadonlyArray<Scalars['String']['output']>
  /** The first date for a recurring requirement. */
  readonly startDate: Maybe<Scalars['DateTime']['output']>
  readonly timeZone: Scalars['String']['output']
  /** Type of legal document */
  readonly type: DocumentType
  readonly updatedAt: Scalars['DateTime']['output']
  /** For vendor legal requirements, a blank form template may be provided for the vendor to download */
  readonly vendorTemplate: Maybe<StoredFile>
}

/** Represents a single lien waiver on a pay app. */
export type LienWaiver = {
  readonly __typename: 'LienWaiver'
  /** Actual check amount entered on a vendor lien waiver form. */
  readonly amount: Maybe<Scalars['SafeInt']['output']>
  /**
   * This is intentionally a string as the claimant could be a sub-sub who is not a user of our system.
   * We will auto-populate with the subcontractor information but if this is a sub-sub, we will ask
   * them to type in their legal name.
   */
  readonly claimant: Scalars['String']['output']
  readonly contract: Contract
  readonly createdAt: Scalars['DateTime']['output']
  readonly customer: Scalars['String']['output']
  readonly date: Scalars['DateTime']['output']
  /**
   * Default check amount to fill lower-tier lien waivers with.
   * We use these values to populate the LIEN_WAIVER_AMOUNT form annotation values.
   * The value of this annotation depends on whehether the amount / text / both are provided by the sub.
   * This is irrelevant for primary lien waivers.
   */
  readonly defaultAmount: Maybe<Scalars['SafeInt']['output']>
  readonly defaultAmountText: Maybe<Scalars['String']['output']>
  /** Default exceptions to fill lower-tier lien waivers with. This is irrelevant for primary lien waivers. */
  readonly defaultExceptionAmounts: Maybe<Scalars['String']['output']>
  readonly defaultExceptionDates: Maybe<Scalars['String']['output']>
  /**
   * Default vendor contacts to send lien waiver requests to. We allow saving the default list so users
   * can edit and persist the recipients prior to sending the requests. If empty, the frontend will
   * defer to `hasSelectedDefaultVendorContacts`: if that field is true, the frontend will show no
   * recipients, and if that field is false, it will show a default list of recipients based on the
   * vendor contract and past lien waivers.
   */
  readonly defaultVendorContacts: ReadonlyArray<VendorContact>
  /** Form template variant that is used to generate the lien waiver. Can be null if lien waiver is uploaded manually. */
  readonly formTemplateVariant: Maybe<FormTemplateVariant>
  /** Form template version that is used to generate the lien waiver. Can be null if lien waiver is uploaded manually. */
  readonly formTemplateVersion: Maybe<FormTemplateVersion>
  /** A list of all user-entered values for this lien waiver. */
  readonly formValues: ReadonlyArray<FormTemplateAnnotationValue>
  /**
   * See above. This field is necessary because we can't distinguish between `defaultVendorContracts`
   * being unset vs empty (since null and an empty array are indistinguishable for @manyToMany fields).
   */
  readonly hasSelectedDefaultVendorContacts: Scalars['Boolean']['output']
  readonly id: Scalars['ID']['output']
  /** Refers to whether or not a lien waiver is for a joint check */
  readonly isJointCheck: Scalars['Boolean']['output']
  readonly jobLocation: Location
  /** The time that this document was last sent in an email. */
  readonly lastSendTime: Maybe<Scalars['DateTime']['output']>
  /** The last write sync for this entity. Null if not successful */
  readonly lastSyncIfSuccessful: Maybe<WriteSyncOperation>
  /** List of LienWaiverRequests from lower-tier companies */
  readonly lienWaiverRequests: ReadonlyArray<LienWaiverRequest>
  /**
   * Name of who makes the check. Generally this is the same as the customer, but for vendor joint
   * checks, this will be the general contractor instead of the subcontractor.
   */
  readonly makerOfCheck: Scalars['String']['output']
  /** A note the subcontractor writes to include the email request body */
  readonly message: Maybe<Scalars['String']['output']>
  readonly owner: Scalars['String']['output']
  readonly payApps: ReadonlyArray<PayApp>
  /**
   * Name of who the check is made to. Generally this is the same as the claimant, but for vendor
   * joint checks, this will be both the subcontractor and the vendor instead of just the vendor.
   */
  readonly payableTo: Scalars['String']['output']
  /** Includes information about the physical signatures required on this lien waiver. */
  readonly physicalSignatureRequired: PhysicalSignatureRequired
  readonly preSitelinePayApp: Maybe<PreSitelinePayApp>
  /** File(s) that will be attached to vendor requests for this lien waiver */
  readonly requestAttachments: ReadonlyArray<StoredFile>
  /** A list of each time the signed lien waiver has been sent. */
  readonly sentLog: ReadonlyArray<LienWaiverSendLog>
  readonly signature: Maybe<Signature>
  /** The email subject of the latest lien waiver request */
  readonly subject: Maybe<Scalars['String']['output']>
  readonly timeZone: Scalars['String']['output']
  readonly type: LienWaiverType
  readonly updatedAt: Scalars['DateTime']['output']
  /** If provided, we'll use this file instead of generating it on the fly */
  readonly uploadedFile: Maybe<StoredFile>
  /**
   * VendorContract is optional because a LW can be for a primary subcontractor or for a lower-tier. Only the
   * lower-tier is associated with a VendorContract.
   */
  readonly vendorContract: Maybe<VendorContract>
}

export { LienWaiverCategory }

/** Represents a request to have a Lien Waiver signed by a lower-tier company. */
export type LienWaiverRequest = {
  readonly __typename: 'LienWaiverRequest'
  /**
   * This date is displayed on the UI when a user checks their request status. We intentionally
   * don't use the updatedAt field because if the LienWaiverRequest document metadata isn't modified
   * by a user-driven action then we wouldn't want the date to change. Therefore we explicitly update
   * the actionTakenAt field.
   */
  readonly actionTakenAt: Maybe<Scalars['DateTime']['output']>
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  readonly lienWaiver: LienWaiver
  readonly modifiedBy: ReadonlyArray<User>
  /** The most recent user to send this request */
  readonly requestedBy: Maybe<User>
  readonly status: DocumentRequestStatus
  readonly statusLogs: ReadonlyArray<LienWaiverRequestStatusLog>
  readonly updatedAt: Scalars['DateTime']['output']
  readonly vendorContact: VendorContact
}

export type LienWaiverRequestData = {
  readonly __typename: 'LienWaiverRequestData'
  readonly companyCreatedAt: Scalars['DateTime']['output']
  readonly companyId: Scalars['ID']['output']
  readonly companyName: Scalars['String']['output']
  readonly lienWaiversRequested: Scalars['SafeInt']['output']
  readonly sitelinePointOfContactId: Maybe<Scalars['ID']['output']>
  readonly sitelinePointOfContactName: Maybe<Scalars['String']['output']>
}

/** Represents a log of all statuses for a lien waiver request  and when the transition occurred. */
export type LienWaiverRequestStatusLog = {
  readonly __typename: 'LienWaiverRequestStatusLog'
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  readonly ipAddress: Maybe<Scalars['String']['output']>
  readonly lienWaiverRequest: LienWaiverRequest
  readonly status: DocumentRequestStatus
  readonly statusUpdatedAt: Scalars['DateTime']['output']
  /** Person who made the change. If no user or contact is provided, change was made by the system. */
  readonly user: Maybe<User>
  /** Vendor contact who made the change. If no user or contact is provided, change was made by the system. */
  readonly vendorContact: Maybe<VendorContact>
}

export type LienWaiverRequestWithToken = {
  readonly __typename: 'LienWaiverRequestWithToken'
  readonly lienWaiverRequest: LienWaiverRequest
  readonly token: Scalars['String']['output']
}

/**
 * Represents a single send action of a lien waiver to a contact at a GC. This will be updated any time
 * a lien waiver is sent as part of a PayApp package or independently outside of it.
 */
export type LienWaiverSendLog = {
  readonly __typename: 'LienWaiverSendLog'
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  readonly lienWaiver: LienWaiver
  readonly recipients: ReadonlyArray<CompanyContact>
  readonly sender: User
}

export { LienWaiverType }

/** DEPRECATED */
export type LienWaiversByMonth = {
  readonly __typename: 'LienWaiversByMonth'
  readonly lienWaivers: ReadonlyArray<LienWaiver>
  /**
   * This is the month that the lien waiver through date corresponds to
   * in the timezone of the lien waiver's project
   */
  readonly month: Scalars['SafeInt']['output']
  readonly year: Scalars['SafeInt']['output']
}

/** DEPRECATED */
export type LienWaiversByProject = {
  readonly __typename: 'LienWaiversByProject'
  readonly internalProjectNumber: Maybe<Scalars['String']['output']>
  readonly lienWaivers: ReadonlyArray<LienWaiver>
  readonly lowerTierTo: Maybe<LowerTierToVendorContract>
  readonly projectId: Scalars['ID']['output']
  readonly projectName: Scalars['ID']['output']
  readonly projectNumber: Scalars['String']['output']
  /** Checks if this month was skipped for the given project */
  readonly skippedMonth: Scalars['Boolean']['output']
  readonly timeZone: Scalars['String']['output']
  readonly vendorContractId: Scalars['ID']['output']
}

/** DEPRECATED */
export type LienWaiversByVendorContract = {
  readonly __typename: 'LienWaiversByVendorContract'
  readonly lienWaivers: ReadonlyArray<LienWaiver>
  readonly lowerTierTo: Maybe<LowerTierToVendorContract>
  readonly vendorContractId: Scalars['ID']['output']
  readonly vendorId: Scalars['String']['output']
  readonly vendorName: Scalars['String']['output']
}

/**
 * The data for a single cell in the vendor lien waivers tracker, corresponding to a vendor contract
 * for a single month in a given category. There may be zero or one lien waiver for that month in the
 * category, and we also return whether the final lien waiver was previously collected.
 */
export type LienWaiversMonth = {
  readonly __typename: 'LienWaiversMonth'
  /** True if a final LW was requested in a prior month and no LWs have been created since */
  readonly hasCollectedFinalLienWaiver: Scalars['Boolean']['output']
  readonly lienWaiver: Maybe<LienWaiver>
}

/**
 * A summary of lien waivers for tracking in a given month. Depending on the query, this may correspond
 * to a contract or a vendor, and will encompass lien waivers in a certain category.
 */
export type LienWaiversMonthSummary = {
  readonly __typename: 'LienWaiversMonthSummary'
  readonly aggregateStatus: AggregateLienWaiverStatus
  /** True if the given month was skipped for billing and no lien waivers exist */
  readonly isMonthSkipped: Scalars['Boolean']['output']
  /** Total number of lien waivers */
  readonly numLienWaivers: Scalars['Int']['output']
  /** The number of lien waivers that have not yet been signed */
  readonly numPendingLienWaivers: Scalars['Int']['output']
}

export type LineItemAddition = {
  readonly __typename: 'LineItemAddition'
  readonly new: SovChangeSetCreateLineItem
}

export type LineItemDeletion = {
  readonly __typename: 'LineItemDeletion'
  readonly oldId: Scalars['ID']['output']
}

export type LineItemGroupAddition = {
  readonly __typename: 'LineItemGroupAddition'
  readonly new: SovChangeSetCreateLineItemGroup
  readonly newId: Scalars['ID']['output']
}

export type LineItemGroupDeletion = {
  readonly __typename: 'LineItemGroupDeletion'
  readonly oldId: Scalars['ID']['output']
}

export type LineItemGroupUpdate = {
  readonly __typename: 'LineItemGroupUpdate'
  readonly new: SovChangeSetUpdateLineItemGroup
  readonly oldId: Scalars['ID']['output']
}

/**
 * Similar to `ProjectMetrics`, but per SOV line item or amount line item on a contract. Every field
 * should be provided for lump sum and unit price projects but only amountBilledAndDraftThisMonth will
 * be provided for quick bills (which is why its the only required field), and only
 * amountBilledAndDraftThisMonth and completedThruMonth will be provided for T&M line items.
 */
export type LineItemMetrics = {
  readonly __typename: 'LineItemMetrics'
  readonly amountBilledAndDraftThisMonth: Scalars['SafeInt']['output']
  readonly balanceToFinish: Maybe<Scalars['SafeInt']['output']>
  readonly completedThruMonth: Maybe<Scalars['SafeInt']['output']>
  /** The cost code for the line item */
  readonly costCode: Maybe<Scalars['String']['output']>
  /** The name for SOV line items or the description for quick bill amount line items */
  readonly name: Scalars['String']['output']
  /** The code for SOV line items or the sort order for quick bill amount line items */
  readonly number: Scalars['String']['output']
  readonly percentCompletedThruMonth: Maybe<Scalars['Float']['output']>
  readonly totalValue: Maybe<Scalars['SafeInt']['output']>
}

export { LineItemType }

export type LineItemUpdate = {
  readonly __typename: 'LineItemUpdate'
  readonly new: SovChangeSetUpdateLineItem
  readonly oldId: Scalars['ID']['output']
}

/** Represents a location in the real world. Currently used by a company or a project. */
export type Location = {
  readonly __typename: 'Location'
  readonly city: Scalars['String']['output']
  readonly coordinates: Scalars['LatLng']['output']
  readonly country: Scalars['String']['output']
  readonly county: Maybe<Scalars['String']['output']>
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  /** Optional nickname for this location. */
  readonly nickname: Maybe<Scalars['String']['output']>
  readonly postalCode: Maybe<Scalars['String']['output']>
  readonly state: Scalars['String']['output']
  readonly street1: Maybe<Scalars['String']['output']>
  readonly street2: Maybe<Scalars['String']['output']>
  readonly timeZone: Scalars['String']['output']
  readonly updatedAt: Scalars['DateTime']['output']
}

export type LocationInput = {
  readonly city: Scalars['String']['input']
  readonly coordinates: Scalars['LatLng']['input']
  readonly country: Scalars['String']['input']
  readonly county?: InputMaybe<Scalars['String']['input']>
  readonly nickname?: InputMaybe<Scalars['String']['input']>
  readonly postalCode?: InputMaybe<Scalars['String']['input']>
  readonly state: Scalars['String']['input']
  readonly street1?: InputMaybe<Scalars['String']['input']>
  readonly street2?: InputMaybe<Scalars['String']['input']>
}

export type LowerTierToVendorContract = {
  readonly __typename: 'LowerTierToVendorContract'
  readonly vendorContractId: Scalars['ID']['output']
  readonly vendorId: Scalars['ID']['output']
  readonly vendorName: Scalars['ID']['output']
}

export type MarkFormTemplateAsDuplicateInput = {
  readonly duplicateTemplateId: Scalars['ID']['input']
  readonly id: Scalars['ID']['input']
}

export type MarkLienWaiverRequestsAsSubmittedForMonthInput = {
  readonly companyId: Scalars['ID']['input']
  readonly month: Scalars['SafeInt']['input']
  readonly year: Scalars['SafeInt']['input']
}

export type MarkOldSubmittedPayAppsAsPaidInput = {
  readonly companyId: Scalars['ID']['input']
}

export type MarkWriteSyncAsFailedInput = {
  readonly notifyUser: Scalars['Boolean']['input']
  readonly writeSyncOperationId: Scalars['ID']['input']
}

export type MergeGeneralContractorCompaniesInput = {
  readonly destinationId: Scalars['ID']['input']
  readonly sourceIds: ReadonlyArray<Scalars['ID']['input']>
}

export type MigrateIntegrationContractsInput = {
  readonly fromCompanyIntegrationId: Scalars['ID']['input']
  readonly toCompanyIntegrationId: Scalars['ID']['input']
}

/** A company linked to a form template as the requesting company */
export type MinimalFormTurnaroundCompany = {
  readonly __typename: 'MinimalFormTurnaroundCompany'
  readonly id: Scalars['ID']['output']
  readonly name: Scalars['String']['output']
}

export type MinimalFormTurnaroundFormTemplate = {
  readonly __typename: 'MinimalFormTurnaroundFormTemplate'
  readonly builderId: Maybe<Scalars['ID']['output']>
  readonly id: Scalars['ID']['output']
  readonly ownerId: Maybe<Scalars['ID']['output']>
  readonly requestingCompanyId: Maybe<Scalars['ID']['output']>
  readonly userVisibleName: Scalars['String']['output']
  readonly validatorId: Maybe<Scalars['ID']['output']>
}

/** A user attached to a form template, either as owner, validator, or builder */
export type MinimalFormTurnaroundUser = {
  readonly __typename: 'MinimalFormTurnaroundUser'
  readonly firstName: Scalars['String']['output']
  readonly id: Scalars['ID']['output']
  readonly lastName: Scalars['String']['output']
}

export type ModifyEmailPreferencesInput = {
  readonly block: Scalars['Boolean']['input']
  readonly emailTypes: ReadonlyArray<EmailType>
  readonly userId: Scalars['ID']['input']
}

export type ModifyNotificationPreferencesInput = {
  readonly block: Scalars['Boolean']['input']
  readonly notificationTypes: ReadonlyArray<NotificationType>
  readonly userId: Scalars['ID']['input']
}

/** Breakdown of amount billed and amount billed plus draft across all projects in a single month */
export type MonthlyBillingBreakdown = {
  readonly __typename: 'MonthlyBillingBreakdown'
  /**
   * Amount of progress billed across all projects that have pay apps submitted or in draft in a
   * single month. Does not include retention billed and does not subtract retention held.
   */
  readonly amountBilledAndDraftThisMonth: Scalars['SafeInt']['output']
  /**
   * Amount of progress billed across all projects in a single month, excluding projects in draft. Does
   * not include retention billed and does not subtract retention held.
   */
  readonly amountBilledThisMonth: Scalars['SafeInt']['output']
  readonly month: Scalars['DateTime']['output']
}

export type Mutation = {
  readonly __typename: 'Mutation'
  /** Adds an integration to a company. There can be multiple integrations of the same type per company. */
  readonly addCompanyIntegration: CompanyIntegration
  /** Adds a comment to a FormTemplate */
  readonly addFormTemplateComment: FormTemplateComment
  /** Adds an integration to a contract */
  readonly addIntegration: Integration
  /** Applies a set of overrides to an annotation and variant */
  readonly applyAnnotationOverride: AnnotationOverride
  /** Archives a company integration. Syncs will no longer be run for this integration. */
  readonly archiveCompanyIntegration: CompanyIntegration
  /** Deletes all of the contacts (GC contacts) from a company object. */
  readonly clearCompanyContacts: Company
  /** Clears all events for a pay app to wipe the activity feed */
  readonly clearPayAppEvents: PayApp
  /** Clones a form template. Mostly used to split out a template request into many different templates */
  readonly cloneFormTemplate: FormTemplate
  /** Clones a form template version and associated form annotations */
  readonly cloneFormTemplateVersion: FormTemplateVersion
  /** Changes a contract's retention tracking lever and adds/removes the relevant retention changelogs */
  readonly convertRetentionTrackingLevel: Contract
  /** Creates a dummy admin user object if one doesn't already exist */
  readonly createAdminUser: User
  /** Creates a company with no users */
  readonly createCompany: Company
  /** Creates a company contact. */
  readonly createCompanyContact: CompanyContact
  /** Creates a new company form template collection */
  readonly createCompanyFormTemplateCollection: Company
  /** Adds a form template */
  readonly createFormTemplate: FormTemplate
  /** Creates a form template annotation for an existing form template. */
  readonly createFormTemplateAnnotation: FormTemplateAnnotation
  /** Creates multiple template annotations for an existing form template. */
  readonly createFormTemplateAnnotations: ReadonlyArray<FormTemplateAnnotation>
  /** Adds a form template version and associated form annotations */
  readonly createFormTemplateVersion: FormTemplateVersion
  /** Creates a new lien waiver template set for a company */
  readonly createLienWaiverFormTemplateSet: Company
  /** Creates a new location in the database, specific to a company. */
  readonly createLocation: Location
  /** Creates a new pay app template set for a company */
  readonly createPayAppFormTemplateSet: Company
  /** Creates a new SOV line item group */
  readonly createSovLineItemGroup: SovLineItemGroup
  /** Creates a new template variant */
  readonly createTemplateVariant: FormTemplateVariant
  /** Quickly creates a template for test purposes */
  readonly createTestTemplate: FormTemplate
  /** Creates a new user education campaign, effective immediately until its expiration date */
  readonly createUserEducationCampaign: UserEducationCampaign
  /** Delete all overrides for an annotation */
  readonly deleteAnnotationOverride: DeletionResult
  /** Deletes keys from the generic or files cache */
  readonly deleteCacheKeys: Scalars['Boolean']['output']
  /** Deletes a company. Only allows if company does not belong to a project. */
  readonly deleteCompany: DeletionResult
  /** Deletes a company form template collection */
  readonly deleteCompanyFormTemplateCollection: Company
  /** Delete a form template. There can't be any usages of it to delete it. */
  readonly deleteFormTemplate: DeletionResult
  /** Deletes a form template annotation from an existing template. */
  readonly deleteFormTemplateAnnotation: DeletionResult
  /** Deletes multiple form template annotations. */
  readonly deleteFormTemplateAnnotations: ReadonlyArray<DeletionResult>
  /** Deletes a comment from a FormTemplate */
  readonly deleteFormTemplateComment: DeletionResult
  /** Delete a form template version. There can't be any usages of it to delete it. */
  readonly deleteFormTemplateVersion: DeletionResult
  /** Deletes an integration from a contract */
  readonly deleteIntegration: DeletionResult
  /** Deletes a lien waiver template set for a company */
  readonly deleteLienWaiverFormTemplateSet: Company
  /** Removes all lien waiver requests for a specific month, across an entire company. Used for demos. */
  readonly deleteLienWaiverRequestsForMonth: Scalars['Boolean']['output']
  /** Deletes a pay-app. Only allowed if pay-app is DRAFT. */
  readonly deletePayApp: DeletionResult
  /** Deletes a pay app template set for a company */
  readonly deletePayAppFormTemplateSet: Company
  /** Deletes a project */
  readonly deleteProject: DeletionResult
  /** Deletes a line item, assuming it hasn't been billed for. */
  readonly deleteSovLineItem: DeletionResult
  /** Deletes an SOV line item group */
  readonly deleteSovLineItemGroup: DeletionResult
  /** Deletes a template variant */
  readonly deleteTemplateVariant: DeletionResult
  /** Fully deletes a vendor, its contacts, and all lien waivers / documents related to it */
  readonly deleteVendor: DeletionResult
  /** Disables lower-tier lien-waivers for a contract */
  readonly disableLowerTierLienWaivers: Contract
  /** Disables primary lien-waivers for a contract */
  readonly disablePrimaryLienWaivers: Contract
  /** Suspends a user by removing their access and disabling their account */
  readonly disableUser: User
  /** Updates an integration credential */
  readonly enableIntegrationCredentialAutoRotation: CompanyIntegration
  /** Enables lower-tier lien-waivers for a contract, by passing a set of templates */
  readonly enableLowerTierLienWaivers: Contract
  /** Enables primary lien-waivers for a contract, by passing a set of templates */
  readonly enablePrimaryLienWaivers: Contract
  /** Re-enables a user in the database and on Firebase */
  readonly enableUser: User
  /** Exports credentials for a company integration to Postman environments. */
  readonly exportIntegrationCredentialsToPostman: Scalars['Boolean']['output']
  /** Sends an email to the users on the given contract notifying them that their forms are ready. */
  readonly finishedOnboardingForms: Contract
  /** Updates a form template <> contract association to ignore the uploaded form template type */
  readonly ignoreFormTemplateProvidedType: Contract
  /** Imports a SOV from an integration including adding, deleting, or updating existing line items. */
  readonly importSovFromIntegration: Sov
  /** Creates a user, associates it with the provided company, and send them an invite email */
  readonly inviteCompanyUser: CompanyUser
  /** Marks a template as a duplicate of another template */
  readonly markFormTemplateAsDuplicate: FormTemplate
  /** Marks all lien waiver requests as submitted for a specific month, across an entire company. Used for demos. */
  readonly markLienWaiverRequestsAsSubmittedForMonth: Scalars['Boolean']['output']
  /** Marks all old (>75 days) submitted pay apps as paid, across an entire company. Used for demos. */
  readonly markOldSubmittedPayAppsAsPaid: Scalars['Boolean']['output']
  /**
   * Marks a write sync as failed and notifies the person who initiated the sync.
   * This is useful when syncs are stuck in NOT_STARTED state and weren't processed by the integrations service.
   */
  readonly markWriteSyncAsFailed: WriteSyncOperation
  /** Merges multiple GC companies to a single destination company, updating all references to the old ones */
  readonly mergeGeneralContractorCompanies: Scalars['Boolean']['output']
  /**
   * For every active contract linked to a certain integration, attempts to create a new integration
   * linking the contract to a different system. If successful, marks the first integration inactive.
   */
  readonly migrateIntegrationContracts: IntegrationProjectMigrationSummary
  /** Blocks email notifications for a given type. */
  readonly modifyEmailPreferences: User
  /** Blocks push notifications for a given type. */
  readonly modifyNotificationPreferences: User
  /** Refreshes mappings for an integration by querying the third-party and running a matching algorithm */
  readonly refreshIntegrationMappings: Integration
  /** Replaces all annotations of a template version with the provided annotations. */
  readonly replaceFormTemplateAnnotations: ReadonlyArray<FormTemplateAnnotation>
  /**
   * Adds a write sync back to the Cloud Task queue.
   * This is useful when a sync is stuck and needs to be requeued.
   * You should not use this when a sync fails. Instead you should create a new sync from the main app.
   */
  readonly requeueWriteSync: WriteSyncOperation
  /** Resends an invite email to the specified user */
  readonly resendUserInvite: User
  /** Clear a pay-app entirely and set its status to DRAFT */
  readonly resetPayApp: PayApp
  /** Retract a pay-app and set it to DRAFT */
  readonly retractPayApp: PayApp
  /**
   * Rotates the external API token for a company. It returns the token that was generated.
   * The token cannot be read from the database after it is generated.
   */
  readonly rotateExternalApiToken: Scalars['String']['output']
  /** Rotates the credentials for a company integration automatically */
  readonly rotateIntegrationCredential: CompanyIntegration
  /** Sends a test monthly report email for a given company */
  readonly sendCompanyMonthlyReportTestEmail: ReadonlyArray<User>
  /** Set internal siteline notes for a company */
  readonly setCompanyInternalSitelineNotes: Scalars['Boolean']['output']
  /** Adds a list of FormTemplateTags to the given FormTemplate */
  readonly setFormTemplateTags: FormTemplate
  /** Sets a Legal Document as SYNCED manually. */
  readonly setLegalDocumentSynced: LegalRequirement
  /** Sets which SovLineItems are in an SovLineItemGroup. */
  readonly setLineItemsInGroup: SovLineItemGroup
  /** Sets a pay-app to PROPOSED manually */
  readonly setPayAppProposed: PayApp
  /** Sets a pay-app to SYNCED manually */
  readonly setPayAppSynced: PayApp
  /** Skips validation on a form template to allow it to be added to projects */
  readonly skipFormTemplateValidation: FormTemplate
  /** Un-archives a company integration. Syncs will be able to run again for this integration. */
  readonly unarchiveCompanyIntegration: CompanyIntegration
  /** Updates an Agave specific integration credential */
  readonly updateAgaveIntegrationCredential: CompanyIntegration
  /** Updates the change order log form on a project */
  readonly updateChangeOrderLogForm: Contract
  /** Updates the change order forms on a project */
  readonly updateChangeOrderRequestForm: Contract
  /** Updates a company */
  readonly updateCompany: Company
  /** Updates a company contact */
  readonly updateCompanyContact: CompanyContact
  /** Updates a company form template collection */
  readonly updateCompanyFormTemplateCollection: Company
  /** Updates a company integration */
  readonly updateCompanyIntegration: CompanyIntegration
  /** Updates the metadata for a contract on a project */
  readonly updateContract: Contract
  /** Updates the list of users on a project */
  readonly updateContractUsers: Contract
  /** Sets the default change order log templates for a company. */
  readonly updateDefaultChangeOrderLogForm: Company
  /** Sets the default change order request templates for a company. */
  readonly updateDefaultChangeOrderRequestForm: Company
  /** Updates the form template metadata */
  readonly updateFormTemplate: FormTemplate
  /** Updates form template annotations that on an existing form template. */
  readonly updateFormTemplateAnnotations: ReadonlyArray<FormTemplateAnnotation>
  /** Updates the associated company on a FormTemplate */
  readonly updateFormTemplateAssociatedCompany: FormTemplate
  /** Updates the associated contracts on a FormTemplate */
  readonly updateFormTemplateAssociatedContracts: FormTemplate
  /** Updates the builder of a template */
  readonly updateFormTemplateBuilder: FormTemplate
  /** Updates the due date of a template */
  readonly updateFormTemplateDueDate: FormTemplate
  /** Updates the original file of a template */
  readonly updateFormTemplateOriginalFile: FormTemplate
  /** Updates the owner of a template */
  readonly updateFormTemplateOwner: FormTemplate
  /** Updates the requesting company on a FormTemplate */
  readonly updateFormTemplateRequestingCompany: FormTemplate
  /** Updates the status of a Form Template. */
  readonly updateFormTemplateStatus: FormTemplate
  /** Updates the validator of a template */
  readonly updateFormTemplateValidator: FormTemplate
  /** Updates the form template version metadata, but not the annotations. */
  readonly updateFormTemplateVersion: FormTemplateVersion
  /** Updates an integration credential */
  readonly updateIntegrationCredential: CompanyIntegration
  /** Updates mappings for an integration */
  readonly updateIntegrationMappings: Integration
  /** Updates the legal requirements on a project */
  readonly updateLegalRequirements: Contract
  /** Updates a lien waiver template set for a company */
  readonly updateLienWaiverFormTemplateSet: Company
  /** Updates an existing location in the database. User must be a member of company to update. */
  readonly updateLocation: Location
  /** Update individual onboarding flags on the contract */
  readonly updateOnboardedProjectContractStatus: Contract
  /** Edit details about a pay app that was created. */
  readonly updatePayApp: PayApp
  /** Updates a pay app template set for a company */
  readonly updatePayAppFormTemplateSet: Company
  /** Updates the pay app requirements on a project */
  readonly updatePayAppRequirementGroups: Contract
  /** Updates a project */
  readonly updateProject: Project
  /** Updates whether retention is rounded on the contract. */
  readonly updateRoundRetention: Contract
  /** Updates an SOV line item group */
  readonly updateSovLineItemGroup: SovLineItemGroup
  /** Updates new template variant */
  readonly updateTemplateVariant: FormTemplateVariant
  /** Edits a new user education campaign */
  readonly updateUserEducationCampaign: UserEducationCampaign
}

export type MutationAddCompanyIntegrationArgs = {
  input: AddCompanyIntegrationInput
}

export type MutationAddFormTemplateCommentArgs = {
  input: AddFormTemplateCommentInput
}

export type MutationAddIntegrationArgs = {
  input: AddIntegrationInput
}

export type MutationApplyAnnotationOverrideArgs = {
  input: ApplyAnnotationOverrideInput
}

export type MutationArchiveCompanyIntegrationArgs = {
  id: Scalars['ID']['input']
}

export type MutationClearCompanyContactsArgs = {
  id: Scalars['ID']['input']
}

export type MutationClearPayAppEventsArgs = {
  payAppId: Scalars['ID']['input']
}

export type MutationCloneFormTemplateArgs = {
  input: CloneFormTemplateInput
}

export type MutationCloneFormTemplateVersionArgs = {
  formTemplateVersionId: Scalars['ID']['input']
}

export type MutationConvertRetentionTrackingLevelArgs = {
  input: ConvertRetentionTrackingLevelInput
}

export type MutationCreateAdminUserArgs = {
  id: Scalars['ID']['input']
}

export type MutationCreateCompanyArgs = {
  company: CompanyInput
}

export type MutationCreateCompanyContactArgs = {
  input: CreateCompanyContactInput
}

export type MutationCreateCompanyFormTemplateCollectionArgs = {
  input: CreateCompanyFormTemplateCollectionInput
}

export type MutationCreateFormTemplateArgs = {
  input: CreateFormTemplateInput
}

export type MutationCreateFormTemplateAnnotationArgs = {
  input: CreateFormAnnotationInput
}

export type MutationCreateFormTemplateAnnotationsArgs = {
  input: CreateFormAnnotationsInput
}

export type MutationCreateFormTemplateVersionArgs = {
  input: CreateFormTemplateVersionInput
}

export type MutationCreateLienWaiverFormTemplateSetArgs = {
  input: CreateLienWaiverFormTemplateSetInput
}

export type MutationCreateLocationArgs = {
  input: CreateLocationInput
}

export type MutationCreatePayAppFormTemplateSetArgs = {
  input: CreatePayAppFormTemplateSetInput
}

export type MutationCreateSovLineItemGroupArgs = {
  input: CreateSovLineItemGroupInput
}

export type MutationCreateTemplateVariantArgs = {
  input: CreateTemplateVariantInput
}

export type MutationCreateTestTemplateArgs = {
  input: CreateTestTemplateInput
}

export type MutationCreateUserEducationCampaignArgs = {
  input: CreateUserEducationCampaignInput
}

export type MutationDeleteAnnotationOverrideArgs = {
  input: DeleteAnnotationOverrideInput
}

export type MutationDeleteCacheKeysArgs = {
  input: DeleteCacheKeysInput
}

export type MutationDeleteCompanyArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteCompanyFormTemplateCollectionArgs = {
  input: DeleteCompanyFormTemplateCollectionInput
}

export type MutationDeleteFormTemplateArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteFormTemplateAnnotationArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteFormTemplateAnnotationsArgs = {
  ids: ReadonlyArray<Scalars['ID']['input']>
}

export type MutationDeleteFormTemplateCommentArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteFormTemplateVersionArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteIntegrationArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteLienWaiverFormTemplateSetArgs = {
  input: DeleteLienWaiverFormTemplateSetInput
}

export type MutationDeleteLienWaiverRequestsForMonthArgs = {
  input: DeleteLienWaiverRequestsForMonthInput
}

export type MutationDeletePayAppArgs = {
  payAppId: Scalars['ID']['input']
}

export type MutationDeletePayAppFormTemplateSetArgs = {
  input: DeletePayAppFormTemplateSetInput
}

export type MutationDeleteProjectArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteSovLineItemArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteSovLineItemGroupArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteTemplateVariantArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteVendorArgs = {
  id: Scalars['ID']['input']
}

export type MutationDisableLowerTierLienWaiversArgs = {
  contractId: Scalars['ID']['input']
}

export type MutationDisablePrimaryLienWaiversArgs = {
  contractId: Scalars['ID']['input']
}

export type MutationDisableUserArgs = {
  input: DisableUserInput
}

export type MutationEnableIntegrationCredentialAutoRotationArgs = {
  input: EnableIntegrationCredentialAutoRotationInput
}

export type MutationEnableLowerTierLienWaiversArgs = {
  input: EnableLienWaiverInput
}

export type MutationEnablePrimaryLienWaiversArgs = {
  input: EnableLienWaiverInput
}

export type MutationEnableUserArgs = {
  input: EnableUserInput
}

export type MutationExportIntegrationCredentialsToPostmanArgs = {
  input: ExportIntegrationCredentialsToPostmanInput
}

export type MutationFinishedOnboardingFormsArgs = {
  id: Scalars['ID']['input']
}

export type MutationIgnoreFormTemplateProvidedTypeArgs = {
  input: IgnoreFormTemplateProvidedTypeInput
}

export type MutationImportSovFromIntegrationArgs = {
  input: ImportSovFromIntegrationInput
}

export type MutationInviteCompanyUserArgs = {
  input: InviteCompanyUserInput
}

export type MutationMarkFormTemplateAsDuplicateArgs = {
  input: MarkFormTemplateAsDuplicateInput
}

export type MutationMarkLienWaiverRequestsAsSubmittedForMonthArgs = {
  input: MarkLienWaiverRequestsAsSubmittedForMonthInput
}

export type MutationMarkOldSubmittedPayAppsAsPaidArgs = {
  input: MarkOldSubmittedPayAppsAsPaidInput
}

export type MutationMarkWriteSyncAsFailedArgs = {
  input: MarkWriteSyncAsFailedInput
}

export type MutationMergeGeneralContractorCompaniesArgs = {
  input: MergeGeneralContractorCompaniesInput
}

export type MutationMigrateIntegrationContractsArgs = {
  input: MigrateIntegrationContractsInput
}

export type MutationModifyEmailPreferencesArgs = {
  input: ModifyEmailPreferencesInput
}

export type MutationModifyNotificationPreferencesArgs = {
  input: ModifyNotificationPreferencesInput
}

export type MutationRefreshIntegrationMappingsArgs = {
  input: RefreshIntegrationMappingsInput
}

export type MutationReplaceFormTemplateAnnotationsArgs = {
  input: ReplaceFormTemplateAnnotationsInput
}

export type MutationRequeueWriteSyncArgs = {
  input: RequeueWriteSyncInput
}

export type MutationResendUserInviteArgs = {
  id: Scalars['ID']['input']
}

export type MutationResetPayAppArgs = {
  payAppId: Scalars['ID']['input']
}

export type MutationRetractPayAppArgs = {
  payAppId: Scalars['ID']['input']
}

export type MutationRotateExternalApiTokenArgs = {
  companyId: Scalars['ID']['input']
}

export type MutationRotateIntegrationCredentialArgs = {
  input: RotateIntegrationCredentialInput
}

export type MutationSendCompanyMonthlyReportTestEmailArgs = {
  input: SendCompanyMonthlyReportTestEmailInput
}

export type MutationSetCompanyInternalSitelineNotesArgs = {
  input: SetCompanyInternalSitelineNotesInput
}

export type MutationSetFormTemplateTagsArgs = {
  input: SetFormTemplateTagsInput
}

export type MutationSetLegalDocumentSyncedArgs = {
  id: Scalars['ID']['input']
}

export type MutationSetLineItemsInGroupArgs = {
  input: SetLineItemsInGroupInput
}

export type MutationSetPayAppProposedArgs = {
  input: SetPayAppProposedInput
}

export type MutationSetPayAppSyncedArgs = {
  input: SetPayAppSyncedInput
}

export type MutationSkipFormTemplateValidationArgs = {
  input: SkipFormTemplateValidationInput
}

export type MutationUnarchiveCompanyIntegrationArgs = {
  id: Scalars['ID']['input']
}

export type MutationUpdateAgaveIntegrationCredentialArgs = {
  input: UpdateAgaveIntegrationCredentialInput
}

export type MutationUpdateChangeOrderLogFormArgs = {
  input: UpdateChangeOrderLogFormInput
}

export type MutationUpdateChangeOrderRequestFormArgs = {
  input: UpdateChangeOrderRequestFormInput
}

export type MutationUpdateCompanyArgs = {
  input: UpdateCompanyInput
}

export type MutationUpdateCompanyContactArgs = {
  input: UpdateCompanyContactInput
}

export type MutationUpdateCompanyFormTemplateCollectionArgs = {
  input: UpdateCompanyFormTemplateCollectionInput
}

export type MutationUpdateCompanyIntegrationArgs = {
  input: UpdateCompanyIntegrationInput
}

export type MutationUpdateContractArgs = {
  input: UpdateContractInput
}

export type MutationUpdateContractUsersArgs = {
  input: UpdateContractUsersInput
}

export type MutationUpdateDefaultChangeOrderLogFormArgs = {
  input: UpdateDefaultChangeOrderLogFormInput
}

export type MutationUpdateDefaultChangeOrderRequestFormArgs = {
  input: UpdateDefaultChangeOrderRequestFormInput
}

export type MutationUpdateDefaultPayAppFormsArgs = {
  input: UpdateDefaultPayAppFormsInput
}

export type MutationUpdateDefaultPrimaryLienWaiverFormsArgs = {
  input: UpdateDefaultLienWaiverFormsInput
}

export type MutationUpdateDefaultVendorLienWaiverFormsArgs = {
  input: UpdateDefaultLienWaiverFormsInput
}

export type MutationUpdateFormTemplateArgs = {
  input: UpdateFormTemplateInput
}

export type MutationUpdateFormTemplateAnnotationsArgs = {
  input: UpdateFormAnnotationsInput
}

export type MutationUpdateFormTemplateAssociatedCompanyArgs = {
  input: UpdateFormTemplateAssociatedCompanyInput
}

export type MutationUpdateFormTemplateAssociatedContractsArgs = {
  input: UpdateFormTemplateAssociatedContractsInput
}

export type MutationUpdateFormTemplateBuilderArgs = {
  input: UpdateFormTemplateUserInput
}

export type MutationUpdateFormTemplateDueDateArgs = {
  input: UpdateFormTemplateDueDateInput
}

export type MutationUpdateFormTemplateOriginalFileArgs = {
  input: UpdateFormTemplateOriginalFileInput
}

export type MutationUpdateFormTemplateOwnerArgs = {
  input: UpdateFormTemplateUserInput
}

export type MutationUpdateFormTemplateRequestingCompanyArgs = {
  input: UpdateFormTemplateRequestingCompanyInput
}

export type MutationUpdateFormTemplateStatusArgs = {
  input: UpdateFormTemplateStatusInput
}

export type MutationUpdateFormTemplateValidatorArgs = {
  input: UpdateFormTemplateUserInput
}

export type MutationUpdateFormTemplateVersionArgs = {
  input: UpdateFormTemplateVersionInput
}

export type MutationUpdateIntegrationCredentialArgs = {
  input: UpdateIntegrationCredentialInput
}

export type MutationUpdateIntegrationMappingsArgs = {
  input: UpdateIntegrationMappingsInput
}

export type MutationUpdateLegalRequirementsArgs = {
  input: UpdateLegalRequirementsInput
}

export type MutationUpdateLienWaiverFormTemplateSetArgs = {
  input: UpdateLienWaiverFormTemplateSetInput
}

export type MutationUpdateLocationArgs = {
  input: UpdateLocationInput
}

export type MutationUpdateOnboardedProjectContractStatusArgs = {
  input: UpdateOnboardedProjectContractStatusInput
}

export type MutationUpdatePayAppArgs = {
  input: UpdatePayAppInput
}

export type MutationUpdatePayAppFormTemplateSetArgs = {
  input: UpdatePayAppFormTemplateSetInput
}

export type MutationUpdatePayAppRequirementGroupsArgs = {
  input: UpdatePayAppRequirementGroupsInput
}

export type MutationUpdateProjectArgs = {
  input: UpdateProjectInput
}

export type MutationUpdateRoundRetentionArgs = {
  input: UpdateRoundRetentionInput
}

export type MutationUpdateSovLineItemGroupArgs = {
  input: UpdateSovLineItemGroupInput
}

export type MutationUpdateTemplateVariantArgs = {
  input: UpdateTemplateVariantInput
}

export type MutationUpdateUserEducationCampaignArgs = {
  input: UpdateUserEducationCampaignInput
}

export { NotaryTag }

export type Notification = {
  readonly __typename: 'Notification'
  /** Contract for which this notification is. */
  readonly contract: Maybe<Contract>
  /** Used to show time of notification. */
  readonly createdAt: Scalars['DateTime']['output']
  /**
   * Initials of the creator of the notification. Empty if created by the system. We only store
   * the initals of the creator, rather than a user ID, because of cases where an unauthenticated
   * user creates a notification (i.e. LTLW Signed). These initials are used to show an avatar
   * for that user on the notification.
   */
  readonly createdByUserInitials: Maybe<Scalars['String']['output']>
  readonly id: Scalars['ID']['output']
  /** Link to redirect to if the notification is clicked. */
  readonly link: Scalars['String']['output']
  /** Actual text of notification. */
  readonly message: Scalars['String']['output']
  /** Whether the notification has been read yet by the user. */
  readonly read: Scalars['Boolean']['output']
  /** Indicates which notification this is. */
  readonly type: NotificationType
  readonly updatedAt: Scalars['DateTime']['output']
  /** User for whom this notification is. */
  readonly user: User
}

/**
 * Feed of notifications returned in paginated lists. Cursor is the latest notification created time
 * and hasNext indicates if there are more notifications to be returned.
 */
export type NotificationFeed = {
  readonly __typename: 'NotificationFeed'
  readonly cursor: Scalars['DateTime']['output']
  readonly hasNext: Scalars['Boolean']['output']
  readonly notifications: ReadonlyArray<Notification>
}

export { NotificationType }

/** Tracks the status of onboarding legal requirements for a project from the Compliance module */
export type OnboardedProjectComplianceStatus = {
  readonly __typename: 'OnboardedProjectComplianceStatus'
  /** The user finished adding general contractor compliance recipients */
  readonly addedGcRecipients: Scalars['Boolean']['output']
  /** The user completed the step to set up legal requirements */
  readonly addedLegalRequirements: Scalars['Boolean']['output']
  /**
   * The user has completed onboarding. This step determines if on subsequent visits, we direct the
   * user to the onboarding page or we direct the user to the compliance overview page.
   */
  readonly completedOnboarding: Scalars['Boolean']['output']
}

/** Represents the status of each of the onboarding steps for a new project contract. */
export type OnboardedProjectContractStatus = {
  readonly __typename: 'OnboardedProjectContractStatus'
  /** Whether the subcontractor has already added GC contact emails. */
  readonly addedGcContacts: Scalars['Boolean']['output']
  /** Whether the subcontractor has already added their SOV. */
  readonly addedSov: Scalars['Boolean']['output']
  /** Whether the subcontractor has already selected their teammates. */
  readonly addedTeammates: Scalars['Boolean']['output']
  /** Computed fields to simplify frontend loads */
  readonly isComplete: Scalars['Boolean']['output']
  readonly isReadyForPayAppSubmit: Scalars['Boolean']['output']
  /**
   * Whether the admin has notified the subcontractor that the forms are onboarded, or no notification is
   * required because no forms were processed for the project
   */
  readonly notifiedOnboardedForms: Scalars['Boolean']['output']
  /** If false, an admin user is currently processing the change order log forms. */
  readonly onboardedChangeOrderLogForms: Scalars['Boolean']['output']
  /** If false, an admin user is currently processing the change order request forms. */
  readonly onboardedChangeOrderRequestForms: Scalars['Boolean']['output']
  /** If false, an admin user is currently processing the pay app forms. */
  readonly onboardedPayAppForms: Scalars['Boolean']['output']
  /** If false, an admin user is currently processing the primary lien waiver forms. */
  readonly onboardedPrimaryLienWaiverForms: Scalars['Boolean']['output']
  /** If false, an admin user is currently processing the vendor lien waiver forms. */
  readonly onboardedVendorLienWaiverForms: Scalars['Boolean']['output']
  /** Whether the subcontractor has uploaded change order log forms/sent them to admin */
  readonly selectedChangeOrderLogForms: Scalars['Boolean']['output']
  /** Whether the subcontractor has already selected their change order request forms/sent them to admin. */
  readonly selectedChangeOrderRequestForms: Scalars['Boolean']['output']
  /** Whether the subcontractor has already selected their pay app forms/sent them to admin. */
  readonly selectedPayAppForms: Scalars['Boolean']['output']
  /** Whether the subcontractor has already selected their primary lien waivers/sent them to admin. */
  readonly selectedPrimaryLienWaivers: Scalars['Boolean']['output']
  /** Whether the subcontractor has already selected a rate table. */
  readonly selectedRateTable: Scalars['Boolean']['output']
  /** Whether the subcontractor has already selected their vendor lien waivers/sent them to admin. */
  readonly selectedVendorLienWaivers: Scalars['Boolean']['output']
  /** Whether the subcontractor has created the project's first pay app. */
  readonly startedBilling: Scalars['Boolean']['output']
}

/** Tracks the status of onboarding vendors/lien waivers for a project from the Vendor module */
export type OnboardedProjectVendorsStatus = {
  readonly __typename: 'OnboardedProjectVendorsStatus'
  /** The user finished adding general contractor lien waiver recipients */
  readonly addedGcRecipients: Scalars['Boolean']['output']
  /** The user completed the vendor documents step of onboarding */
  readonly addedLegalRequirements: Scalars['Boolean']['output']
  /**
   * The first onboarding step is to select lien waiver templates. If this was done during project onboarding,
   * this key will be set to true. If during project onboarding, the user did not select vendor lien waivers,
   * this step will be outstanding (set to false), and only flipped to true when the user either selects
   * lien waivers and confirms their selections.
   */
  readonly addedVendorLienWaivers: Scalars['Boolean']['output']
  /** The user finished adding vendors */
  readonly addedVendors: Scalars['Boolean']['output']
  /**
   * The user has completed onboarding. This step determines if on subsequent visits, we direct the user
   * to the onboarding page or we direct the user to the project details section.
   */
  readonly completedOnboarding: Scalars['Boolean']['output']
}

/** Number of vendor lien waivers outstanding across all projects in a single month */
export type OutstandingLienWaiversByMonth = {
  readonly __typename: 'OutstandingLienWaiversByMonth'
  readonly month: Scalars['DateTime']['output']
  readonly numOutstandingLienWaiversThisMonth: Scalars['SafeInt']['output']
}

export type OverviewDashboard = {
  readonly __typename: 'OverviewDashboard'
  readonly billingSummary: BillingDashboardSummary
  readonly payAppAgingSummary: PayAppAgingDashboardSummary
  readonly vendorSummary: VendorLienWaiverDashboardSummary
}

/**
 * A list of contracts that can be paginated through, each including a list of predicted payment date periods.
 * The cursor is the ID of the last contract in the list.
 */
export type PaginatedCashForecastContractList = {
  readonly __typename: 'PaginatedCashForecastContractList'
  readonly contracts: ReadonlyArray<CashForecastContract>
  readonly cursor: Maybe<Scalars['ID']['output']>
  readonly hasNext: Scalars['Boolean']['output']
  readonly totalCount: Scalars['Int']['output']
}

/** A list of companies that can be paginated through. The cursor is the ID of the last company in the list. */
export type PaginatedCompanyList = {
  readonly __typename: 'PaginatedCompanyList'
  readonly companies: ReadonlyArray<Company>
  readonly cursor: Maybe<Scalars['ID']['output']>
  readonly hasNext: Scalars['Boolean']['output']
  readonly totalCount: Scalars['Int']['output']
}

/** A list of contracts that can be paginated through. The cursor is the ID of the last contract in the list. */
export type PaginatedContractList = {
  readonly __typename: 'PaginatedContractList'
  readonly contracts: ReadonlyArray<Contract>
  readonly cursor: Maybe<Scalars['ID']['output']>
  readonly hasNext: Scalars['Boolean']['output']
  readonly totalCount: Scalars['Int']['output']
}

export { PaginatedListSortOrder }

/** A list of pay apps that can be paginated through. The cursor is the ID of the last pay app in the list. */
export type PaginatedPayAppList = {
  readonly __typename: 'PaginatedPayAppList'
  readonly cursor: Maybe<Scalars['ID']['output']>
  readonly hasNext: Scalars['Boolean']['output']
  readonly payApps: ReadonlyArray<PayApp>
  readonly totalCount: Scalars['Int']['output']
}

/** A list of templates that can be paginated through. The cursor is the ID of the last template in the list. */
export type PaginatedTemplateList = {
  readonly __typename: 'PaginatedTemplateList'
  readonly cursor: Maybe<Scalars['ID']['output']>
  readonly hasNext: Scalars['Boolean']['output']
  readonly templates: ReadonlyArray<FormTemplate>
  readonly totalCount: Scalars['Int']['output']
}

/**
 * A list of vendor contracts that can be paginated through.
 * The cursor is the ID of the last vendor contract in the list.
 */
export type PaginatedVendorContractsList = {
  readonly __typename: 'PaginatedVendorContractsList'
  readonly cursor: Maybe<Scalars['ID']['output']>
  readonly hasNext: Scalars['Boolean']['output']
  readonly totalCount: Scalars['Int']['output']
  readonly vendorContracts: ReadonlyArray<VendorContract>
}

/**
 * A list of vendors that can be paginated through.
 * The cursor is the ID of the last vendor in the list.
 */
export type PaginatedVendorsList = {
  readonly __typename: 'PaginatedVendorsList'
  readonly cursor: Maybe<Scalars['ID']['output']>
  readonly hasNext: Scalars['Boolean']['output']
  readonly totalCount: Scalars['Int']['output']
  readonly vendors: ReadonlyArray<Vendor>
}

/** A list of write sync operations that can be paginated through. The cursor is the ID of the last operation in the list. */
export type PaginatedWriteSyncOperations = {
  readonly __typename: 'PaginatedWriteSyncOperations'
  readonly cursor: Maybe<Scalars['ID']['output']>
  readonly hasNext: Scalars['Boolean']['output']
  readonly operations: ReadonlyArray<WriteSyncOperation>
  readonly totalCount: Scalars['Int']['output']
}

/** Represents a single (monthly) pay app for a single company on a single project. */
export type PayApp = {
  readonly __typename: 'PayApp'
  /** Amount due, post tax. This is amountDuePreTax + amountDueTaxAmount */
  readonly amountDuePostTax: Scalars['SafeInt']['output']
  /** Current billed - retention held + retention released. This is pre-tax. */
  readonly amountDuePreTax: Scalars['SafeInt']['output']
  /** Tax amount on the amount due. This is the sum of all tax amounts on the progresses/amount items. */
  readonly amountDueTaxAmount: Scalars['SafeInt']['output']
  /**
   * A list of amount line items for the pay app.
   * Only used for QUICK pay apps.
   * This array is empty for all other types of pay apps.
   */
  readonly amountLineItems: ReadonlyArray<AmountLineItem>
  /**
   * The amount that was paid on this pay app. This may or may not be the payAppNet amount, depending
   * on what the end user recorded. If the pay app has not been paid, then this returns null/undefined.
   * This amount is post-tax.
   */
  readonly amountPaid: Maybe<Scalars['SafeInt']['output']>
  /** List of attachments to include as backup in the pay app */
  readonly attachments: ReadonlyArray<PayAppAttachment>
  /**
   * Indicates whether the latest updates to the progress values was done by an automatic read sync
   * from an integration. For example, if the GC modifies a sub's billing directly on Textura, this
   * bit will be set to true once we have pulled the billing data back into Siteline.
   */
  readonly automaticallyReadBilling: Scalars['Boolean']['output']
  /** Total balance left to finish after this pay app. */
  readonly balanceToFinish: Scalars['SafeInt']['output']
  /**
   * End date of the billing period for this pay app. Usually end of the month.
   * Note that it's a point in time and that you'll need the project timezone to determine the month.
   * We use this field to determine the "month" of the pay app.
   */
  readonly billingEnd: Scalars['DateTime']['output']
  /**
   * Start date of the billing period for this pay app. Usually start of the month.
   * Note that it's a point in time and that you'll need the project timezone to determine the month.
   */
  readonly billingStart: Scalars['DateTime']['output']
  /**
   * Billing type extracted from the contract billing type when pay app is created.
   * - For LUMP_SUM/UNIT_PRICE billing, only `progress` items are provided
   * - For QUICK billing, only `amountLineItems` are provided, and they all have `type = ADDITIONAL`
   */
  readonly billingType: BillingType
  /** Whether the forms for this pay app can be generated, i.e. they've been selected or provided and fully processed */
  readonly canGenerateForms: Scalars['Boolean']['output']
  /** List of scheduled collections notifications to be sent to the GC */
  readonly collectionsNotifications: ReadonlyArray<PayAppCollectionsNotification>
  /** Collections tasks on the pay app, as entered by the user */
  readonly collectionsTasks: ReadonlyArray<CollectionsTask>
  /** A list of comments on the pay app. */
  readonly comments: ReadonlyArray<PayAppComment>
  /** Contract that this pay app belongs to */
  readonly contract: Contract
  readonly createdAt: Scalars['DateTime']['output']
  /**
   * This is the current amount billed for this PayApp. This includes both retention and stored
   * materials.
   */
  readonly currentBilled: Scalars['SafeInt']['output']
  /** The total amount of progress and retention billed. Does not subtract retention held. */
  readonly currentProgressAndRetentionBilled: Scalars['SafeInt']['output']
  /** This is the current retention for this PayApp. */
  readonly currentRetention: Scalars['SafeInt']['output']
  /**
   * Description of the pay app.
   * Eg: "Pay App #1 (January)"
   */
  readonly description: Scalars['String']['output']
  /**
   * This is the time at which a pay app draft was submitted. It is cleared when a pay app is edited
   * and won't ever be set if the pay app was never submitted while it was a draft.
   */
  readonly draftSubmittedAt: Maybe<Scalars['DateTime']['output']>
  /**
   * Array of CompanyContact references that were emailed a PDF version of this PayApp. This is only
   * used when the general contractor is not using Siteline to administer PayApps.
   */
  readonly emailedContacts: ReadonlyArray<CompanyContact>
  /** A list of events related to this pay app, like `PAY_APP_SUBMITTED`. */
  readonly events: ReadonlyArray<PayAppEvent>
  /**
   * Generated when the pay app is signed so any future submissions and downloads include the exact
   * invoice that was signed. This file includes only the "invoice" forms, i.e. pay app requirements
   * and primary lien waiver. Other attachments will still be re-generated.
   */
  readonly finalInvoice: Maybe<StoredFile>
  readonly formTemplates: ReadonlyArray<PinnedPayAppTemplate>
  /** A list of all user-entered values for the forms in this pay app. */
  readonly formValues: ReadonlyArray<FormTemplateAnnotationValue>
  /** Gets a base set of G702 values to preview for a pay app */
  readonly g702Values: G702Values
  /** Whether the contract rate table was swapped or updated since the pay app was created */
  readonly hasContractRateTableChanged: Scalars['Boolean']['output']
  /**
   * Templates that are pinned to this pay-app. Since @oneToMany cannot be nullable, formTemplates can
   * only be an array with 0 or more elements. This is why we use a flag to determine whether to get
   * templates from the array, or from the contract that this pay-app belongs to.
   */
  readonly hasPinnedTemplates: Scalars['Boolean']['output']
  /** Whether fees have changed on the contract since the pay app was created */
  readonly haveContractFeesChanged: Scalars['Boolean']['output']
  /** Whether the taxes have changed on the contract since the pay app was created */
  readonly haveContractTaxesChanged: Scalars['Boolean']['output']
  readonly id: Scalars['ID']['output']
  /**
   * Iff a pay app has an event of type PAY_APP_PAID_IN_INTEGRATION, returns the date it was marked as
   * paid based on the date in the PayAppEvent logs. Otherwise returns null.
   */
  readonly integrationPaidAt: Maybe<Scalars['DateTime']['output']>
  /**
   * Date before which invoice must be submitted by the sub project manager to the sub accountant
   * (internal deadline).
   */
  readonly internalDueDate: Scalars['DateTime']['output']
  /** Optional invoice number for this pay app, to be used with template variables, if it exists. */
  readonly invoiceNumber: Maybe<Scalars['String']['output']>
  /** A single bit to describe if an invoice has been marked as completed by a sub project manager. */
  readonly invoiceReady: Scalars['Boolean']['output']
  /**
   * If the amount paid on the pay app doesn't match the amount due, we allow the customer to manually
   * mark the pay app balance as closed so the remaining amount isn't considered due and doesn't appear
   * in aging reports.
   */
  readonly isBalanceManuallyClosed: Scalars['Boolean']['output']
  /**
   * The last ERP sync for this entity, successful or not. It must be an actual field/column
   * because it needs to reset when editing the invoice.
   */
  readonly lastErpSync: Maybe<WriteSyncOperation>
  /** If a past pay app had a retention override at the pay app level, it's returned here */
  readonly lastPayAppWithRetentionHeldOverride: Maybe<PayApp>
  /**
   * Returns the most recent status log from a submission or sync, even if the pay app is in DRAFT.
   * We return the entire log, rather than just the date, so the client can tell
   * whether the latest submit event was a submission or a sync.
   */
  readonly lastSubmitted: Maybe<PayAppStatusLog>
  /** The last write sync for this entity, completed or not */
  readonly lastSync: Maybe<WriteSyncOperation>
  /** The last write sync for this entity. Null if not successful */
  readonly lastSyncIfSuccessful: Maybe<WriteSyncOperation>
  /** List of legal documents to include as backup in the pay app */
  readonly legalDocuments: ReadonlyArray<LegalDocument>
  /** List of LienWaivers for the top-tier sub and all lower-tier subs */
  readonly lienWaivers: ReadonlyArray<LienWaiver>
  /**
   * Iff a pay app has an event of type PAY_APP_PAID_IN_INTEGRATION, returns the type of integration
   * that was used to mark it paid. Otherwise returns null.
   */
  readonly markedAsPaidIntegration: Maybe<Integration>
  /** Retention held percent on the stored materials billing */
  readonly materialsRetentionHeldPercent: Maybe<Scalars['Float']['output']>
  /**
   * If there are fields missing on the contract or project that are required to generate this
   * pay app's forms, they're returned here
   */
  readonly missingRequiredFields: ReadonlyArray<RequiredFieldForForms>
  /**
   * If there are fields missing on the contract or project that are required to generate this pay
   * app's unconditional lien waiver, they're returned here
   */
  readonly missingRequiredUnconditionalLienWaiverFields: ReadonlyArray<RequiredFieldForForms>
  /** Payment date as indicated by the customer */
  readonly paidAt: Maybe<Scalars['DateTime']['output']>
  /** Date before which the entire PayApp must be submitted to the GC (external deadline). */
  readonly payAppDueDate: Scalars['DateTime']['output']
  /** Computes the pay app's number */
  readonly payAppNumber: Scalars['SafeInt']['output']
  /** Includes information about the physical signatures required on this pay app. */
  readonly physicalSignatureRequired: PhysicalSignatureRequired
  readonly pinnedChangeOrderLogFormTemplateVersion: Maybe<FormTemplateVersion>
  /**
   * The date payment is expected or predicted, to be used for cash forecasting. This date is entered
   * by the customer, but may be suggested to them based on past payment history.
   */
  readonly predictedPaymentDate: Maybe<Scalars['DateTime']['output']>
  /** The amount of previous retention billed for this period. */
  readonly previousRetentionBilled: Scalars['SafeInt']['output']
  /**
   * A list of progress values for their respective SOV line items.
   * Not used for QUICK pay apps.
   * This array is empty for all other types of pay apps.
   */
  readonly progress: ReadonlyArray<SovLineItemProgress>
  /** Retention held percent on the progress billing */
  readonly progressRetentionHeldPercent: Maybe<Scalars['Float']['output']>
  /**
   * Link back to rate table that was used to create a T&M pay app.
   * It will always exist for T&M pay apps, although it might be archived.
   * Always null for pay apps that are not T&M.
   */
  readonly rateTable: Maybe<RateTable>
  /**
   * Only for T&M.
   * Warning: this includes *all* fees, including group fees.
   */
  readonly rateTableFees: ReadonlyArray<PayAppRateTableFee>
  /** Only for T&M */
  readonly rateTableGroups: ReadonlyArray<PayAppRateTableGroup>
  /** Only for T&M */
  readonly rateTableItems: ReadonlyArray<PayAppRateTableItem>
  /**
   * Retention override amount on the pay app on both progress and material billing. Takes precedence
   * over the progress and materials percents if set on the pay app. If set, also takes precedence over
   * retention values tracked on the individual progresses.
   */
  readonly retentionHeldOverride: Maybe<Scalars['SafeInt']['output']>
  /** The overall retention held percent as of this payApp */
  readonly retentionHeldPercent: Scalars['Float']['output']
  /**
   * Optional invoice number for retention release invoices synced to ERPs,
   * when retention release is separate from the normal invoice.
   */
  readonly retentionInvoiceNumber: Maybe<Scalars['String']['output']>
  /** Used to determine whether the type of pay app is 'normal' or 'retention-only'. */
  readonly retentionOnly: Scalars['Boolean']['output']
  /** Total retention being released for this project on this pay app in cents */
  readonly retentionReleased: Maybe<Scalars['SafeInt']['output']>
  /** Event logs from each time the pay app was reverted to draft */
  readonly revertToDraftEvents: ReadonlyArray<PayAppEvent>
  readonly signature: Maybe<Signature>
  /**
   * Status of the pay app. Use this as the source of truth for statuses instead of status logs.
   * We denormalized statuses for performance reasons.
   */
  readonly status: PayAppStatus
  /** Last time the status was updated */
  readonly statusChangedAt: Scalars['DateTime']['output']
  /** List of all status state transitions for the pay app. */
  readonly statusLogs: ReadonlyArray<PayAppStatusLog>
  /** Vendors to include on sworn statement forms, with their corresponding metrics */
  readonly swornStatementVendors: Maybe<ReadonlyArray<PayAppSwornStatementVendor>>
  /** If an invoice was rejected by the GC in Textura, this is the date it was rejected */
  readonly texturaRejectedAt: Maybe<Scalars['DateTime']['output']>
  /**
   * If an invoice was rejected by the GC in Textura, this is the reason it was rejected for.
   * Note that this might not always be provided in the textura message that we parse, so it can be null
   * while texturaRejectedAt is set. Do not rely on this field to determine whether an invoice was rejected.
   */
  readonly texturaRejectionReason: Maybe<Scalars['String']['output']>
  readonly timeZone: Scalars['String']['output']
  /** This is the total retention held for this PayApp including the current period. */
  readonly totalRetention: Scalars['SafeInt']['output']
  /** The SOV total value as of this pay app */
  readonly totalValue: Scalars['SafeInt']['output']
  readonly updatedAt: Scalars['DateTime']['output']
  /** If provided, we'll use this file instead of generating it on the fly */
  readonly uploadedFile: Maybe<StoredFile>
  /**
   * Human-readable status of the pay app.
   * We already compute this on the frontend and this is exposed for our chatbot.
   * Warning: this is slow to compute and should be used sparingly.
   */
  readonly userFacingStatus: Scalars['String']['output']
  readonly worksheetProgress: ReadonlyArray<WorksheetLineItemProgress>
  /** Historical write sync operations corresponding to this pay app */
  readonly writeSyncOperations: ReadonlyArray<WriteSyncOperation>
}

export type PayAppAgingDashboard = {
  readonly __typename: 'PayAppAgingDashboard'
  readonly contracts: ReadonlyArray<PayAppAgingDashboardContract>
  readonly payAppAgingSummary: PayAppAgingDashboardSummary
}

export type PayAppAgingDashboardContract = {
  readonly __typename: 'PayAppAgingDashboardContract'
  readonly agingBreakdown: AgingBreakdown
  readonly billingStatus: DashboardBillingStatus
  readonly contract: Contract
  /**
   * If aging is calculated from submission date and there are unpaid pre-Siteline pay apps with no
   * submitted date, this will be true so the user knows that pay apps are missing from the data
   */
  readonly hasMissingPreSitelinePayApps: Scalars['Boolean']['output']
  readonly numComments: Scalars['SafeInt']['output']
}

export type PayAppAgingDashboardSummary = {
  readonly __typename: 'PayAppAgingDashboardSummary'
  /** Total amount across all unpaid pay apps over 30 days aged */
  readonly amountAged30Days: Scalars['SafeInt']['output']
  /** If undefined, the previous month had no amount aged over 30 days */
  readonly amountAged30DaysMonthOverMonthPercent: Maybe<Scalars['Float']['output']>
  /** Total amount across all unpaid pay apps over 60 days aged */
  readonly amountAged60Days: Scalars['SafeInt']['output']
  /** If undefined, the previous month had no amount aged over 60 days */
  readonly amountAged60DaysMonthOverMonthPercent: Maybe<Scalars['Float']['output']>
  /** Total amount across all unpaid pay apps over 90 days aged */
  readonly amountAged90Days: Scalars['SafeInt']['output']
  /** If undefined, the previous month had no amount aged over 90 days */
  readonly amountAged90DaysMonthOverMonthPercent: Maybe<Scalars['Float']['output']>
  /** Total amount across all unpaid pay apps over 120 days aged */
  readonly amountAged120Days: Scalars['SafeInt']['output']
  /** If undefined, the previous month had no amount aged over 120 days */
  readonly amountAged120DaysMonthOverMonthPercent: Maybe<Scalars['Float']['output']>
  /** If undefined, the previous month had no amount outstanding */
  readonly amountOutstandingMonthOverMonthPercent: Maybe<Scalars['Float']['output']>
  /** Amount due across all unpaid pay apps in a month */
  readonly amountOutstandingThisMonth: Scalars['SafeInt']['output']
  /** Average days to paid across all pay apps */
  readonly averageDaysToPaid: Scalars['Float']['output']
  /** If undefined, the previous month had no paid pay apps */
  readonly averageDaysToPaidMonthOverMonthPercent: Maybe<Scalars['Float']['output']>
  readonly payAppAgingBreakdown: AgingBreakdown
}

/** Represents an attachment, image, or binary file that can be accessed via a URL. */
export type PayAppAttachment = {
  readonly __typename: 'PayAppAttachment'
  /** If this attachment was created from a recurring contract attachment, link to that attachment */
  readonly contractPayAppAttachment: Maybe<ContractPayAppAttachment>
  readonly createdAt: Scalars['DateTime']['output']
  /** Optional description of the attachment */
  readonly description: Maybe<Scalars['String']['output']>
  readonly file: StoredFile
  readonly id: Scalars['ID']['output']
  /** The attachment is not included in the pay app PDF package, but is attached to submission emails */
  readonly isEmailAttachment: Scalars['Boolean']['output']
  /** Internal-only attachments will never get included in the generated PDF package for the pay app */
  readonly isInternalOnly: Scalars['Boolean']['output']
  readonly name: Scalars['String']['output']
  readonly payApp: PayApp
  /** Rate table items that are associated with this attachment */
  readonly rateTableItems: ReadonlyArray<PayAppRateTableItem>
  /** Optional reference to a SOV Line Item Progress that the attachment is associated with */
  readonly sovLineItemProgress: Maybe<SovLineItemProgress>
  readonly updatedAt: Scalars['DateTime']['output']
  /**
   * Unique upload identifier provided by clients when adding an attachment.
   * This allows clients to optimistically render attachments while uploading, then re-render them
   * when uploads complete.
   */
  readonly uploadId: Scalars['ID']['output']
}

/**
 * A notification scheduled to be sent to a general contractor for collection of payments.
 * This is the actual scheduled notification at the pay app level.
 */
export type PayAppCollectionsNotification = {
  readonly __typename: 'PayAppCollectionsNotification'
  /** If the notification was canceled by the user, this is the time it was canceled */
  readonly canceledAt: Maybe<Scalars['DateTime']['output']>
  /** The contract settings that this notification is based on */
  readonly contractNotification: ContractCollectionsNotification
  readonly id: Scalars['ID']['output']
  /** The last time the notification was sent */
  readonly lastSentOn: Maybe<Scalars['DateTime']['output']>
  /** If provided, ignore the contract settings and use this send time instead */
  readonly overrideSendTime: Maybe<Scalars['DateTime']['output']>
  readonly payApp: PayApp
  /** List of send logs for this notification */
  readonly sendLogs: ReadonlyArray<CollectionsNotificationSendLog>
  /** Scheduled send time. Inferred from contract settings, or overrideSendTime if provided. */
  readonly sendTime: Scalars['DateTime']['output']
  /** Current status of the notification (not sent, sent, canceled, or not needed) */
  readonly status: PayAppCollectionsNotificationStatus
}

export { PayAppCollectionsNotificationStatus }

/** Represents a comment from a user on a pay app. */
export type PayAppComment = {
  readonly __typename: 'PayAppComment'
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  readonly message: Scalars['String']['output']
  readonly payApp: PayApp
  readonly type: PayAppCommentType
  readonly user: User
}

export { PayAppCommentType }

/** Represents a single event that occurred on a pay app object. */
export type PayAppEvent = {
  readonly __typename: 'PayAppEvent'
  readonly createdAt: Scalars['DateTime']['output']
  readonly createdBy: User
  readonly id: Scalars['ID']['output']
  /** Whether the event was inserted via the admin API */
  readonly isAdmin: Scalars['Boolean']['output']
  /** Values in this dictionary depend on the event type */
  readonly metadata: Scalars['JSONObject']['output']
  readonly payApp: PayApp
  readonly type: PayAppEventType
}

export { PayAppEventType }

export { PayAppListSortCriteria }

/** A pay-app level or group-level fee on a T&M pay app. */
export type PayAppRateTableFee = {
  readonly __typename: 'PayAppRateTableFee'
  readonly createdAt: Scalars['DateTime']['output']
  readonly description: Scalars['String']['output']
  /** If provided, this fee applies to a specific group */
  readonly group: Maybe<PayAppRateTableGroup>
  readonly id: Scalars['ID']['output']
  /** If provided, this fee overrides another fee at the pay app (global) level. It must be a group fee in this case. */
  readonly overridesFee: Maybe<PayAppRateTableFee>
  readonly payApp: PayApp
  readonly percent: Scalars['Float']['output']
  /** Link back to the contract rate table fee that was used to create this fee, if it still exists. */
  readonly rateTableFee: Maybe<ContractRateTableFee>
  readonly updatedAt: Scalars['DateTime']['output']
}

/** A line item group on a T&M pay app. */
export type PayAppRateTableGroup = {
  readonly __typename: 'PayAppRateTableGroup'
  /** Total amount after all fees and taxes */
  readonly amountPostFeesPostTax: Scalars['SafeInt']['output']
  /** Amount after fees, but before tax applies */
  readonly amountPostFeesPreTax: Scalars['SafeInt']['output']
  /** Amount before any fee or tax applies */
  readonly amountPreFeesPreTax: Scalars['SafeInt']['output']
  readonly createdAt: Scalars['DateTime']['output']
  readonly fees: ReadonlyArray<PayAppRateTableFee>
  readonly id: Scalars['ID']['output']
  readonly items: ReadonlyArray<PayAppRateTableItem>
  readonly name: Scalars['String']['output']
  readonly payApp: PayApp
  /** Link back to the rate table group that was used to create the pay app item, if it still exists. */
  readonly rateTableGroup: Maybe<RateTableGroup>
  /** This is the pinned tax for this group, which is either the default tax group or the category override. */
  readonly taxGroup: Maybe<TaxGroup>
  readonly type: LineItemType
  readonly updatedAt: Scalars['DateTime']['output']
}

/** A line item on a T&M pay app. */
export type PayAppRateTableItem = {
  readonly __typename: 'PayAppRateTableItem'
  /** Attachments that are associated with this item */
  readonly attachments: ReadonlyArray<PayAppAttachment>
  readonly code: Maybe<Scalars['String']['output']>
  readonly createdAt: Scalars['DateTime']['output']
  readonly currentBilled: Scalars['SafeInt']['output']
  readonly currentUnitsBilled: Scalars['Float']['output']
  readonly description: Scalars['String']['output']
  /** Group that the line item belongs to */
  readonly group: PayAppRateTableGroup
  readonly id: Scalars['ID']['output']
  /** Whether this item was manually added to the pay app and doesn't have a matching rate table item */
  readonly isOneOffItem: Scalars['Boolean']['output']
  readonly payApp: PayApp
  /** Link back to the rate table item that was used to create the pay app item, if it still exists. */
  readonly rateTableItem: Maybe<RateTableItem>
  readonly sortOrder: Scalars['SafeInt']['output']
  readonly unitName: Maybe<Scalars['String']['output']>
  readonly unitRate: Scalars['SafeInt']['output']
  readonly updatedAt: Scalars['DateTime']['output']
}

/** Represents a single requirement for a document that goes into a PayApp. */
export type PayAppRequirement = {
  readonly __typename: 'PayAppRequirement'
  /**
   * Conditions that must be fulfilled for this requirement to appear in a PDF package.
   * If no condition is provided (empty array), the requirement always appears.
   * If there are one or more conditions, the requirement only appears if all conditions are fulfilled.
   */
  readonly conditions: ReadonlyArray<PayAppRequirementCondition>
  readonly createdAt: Scalars['DateTime']['output']
  /** Order of the requirement in its own group. */
  readonly groupOrder: Scalars['Int']['output']
  readonly id: Scalars['ID']['output']
  readonly payAppRequirementGroup: PayAppRequirementGroup
  /**
   * When null, this pay app requirement shows no template.
   * This is used to skip a template altogether for a specific condition (eg: for retention pay apps)
   */
  readonly templateVariant: Maybe<FormTemplateVariant>
  readonly updatedAt: Scalars['DateTime']['output']
}

export { PayAppRequirementCondition }

/**
 * Pay-apps determine which requirements to show by iterating over each requirement group and picking
 * one requirement from the group. Requirements are selected by picking the first one in their group
 * which meets all its conditions. It is possible that a requirement group has no eligible requirement
 * to show, in which case the group is ignored altogether.
 * Group order is determined by `PayAppRequirementGroup.order`.
 * Example:
 * - Group (order = 1)
 *   - Requirement (groupOrder = 1)
 *   - Requirement (groupOrder = 2)
 * - Group (order = 2)
 *   - Requirement (groupOrder = 1)
 *   - Requirement (groupOrder = 2)
 *   - Requirement (groupOrder = 3)
 */
export type PayAppRequirementGroup = {
  readonly __typename: 'PayAppRequirementGroup'
  readonly contract: Maybe<Contract>
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  /** The order of the group as displayed in the pay-app */
  readonly order: Scalars['Int']['output']
  readonly payAppRequirements: ReadonlyArray<PayAppRequirement>
  readonly updatedAt: Scalars['DateTime']['output']
}

export { PayAppStatus }

/** Breakdown of statuses for all pay apps in a month */
export type PayAppStatusBreakdown = {
  readonly __typename: 'PayAppStatusBreakdown'
  readonly draftOnTime: Scalars['SafeInt']['output']
  readonly notBillingThisMonth: Scalars['SafeInt']['output']
  readonly pastDue: Scalars['SafeInt']['output']
  readonly submitted: Scalars['SafeInt']['output']
}

export { PayAppStatusFilter }

/** Represents a log of all statuses for a pay app and when the transition occurred. */
export type PayAppStatusLog = {
  readonly __typename: 'PayAppStatusLog'
  /** Time when the log was created. This should be used to determine the latest status on a pay app. */
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  readonly ipAddress: Maybe<Scalars['String']['output']>
  readonly payApp: PayApp
  readonly status: PayAppStatus
  /**
   * This is a user-visible time for when the status was changed. For example, the pay app may be
   * marked as paid on the UI at one point (which would be the createdAt) but the user may enter a
   * different time for when the money actually came in (which would be the statusUpdatedAt).
   */
  readonly statusUpdatedAt: Scalars['DateTime']['output']
  /** This is a required field for all new log entries, but its optional for backwards compatibility. */
  readonly user: Maybe<User>
}

/**
 * The vendor metrics that may be shown on a pay app's sworn statement, based on current commitments on
 * the contract. If this object exists for a VendorContract and PayApp, then that vendor will appear on
 * the pay app's sworn statement form.
 */
export type PayAppSwornStatementVendor = {
  readonly __typename: 'PayAppSwornStatementVendor'
  /**
   * The total amount outstanding in open invoices on this vendor's commitments, to date. This may be
   * calculated from ERP invoices, or manually entered or edited by the user. Note that this is not the
   * same as the total commitment amount minus the amount paid, since this includes only what has been
   * billed.
   */
  readonly amountOutstandingToDate: Maybe<Scalars['SafeInt']['output']>
  /**
   * The total amount paid on this vendor's commitments, to date. This may be calculated from ERP
   * invoices, or manually entered or edited by the user.
   */
  readonly amountPaidToDate: Maybe<Scalars['SafeInt']['output']>
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  /**
   * When the amounts are updated, either manually or via integration, this field is updated to reflect
   * the details of that update. When this entity is first created, this field should be null.
   */
  readonly lastUpdate: Maybe<PayAppSwornStatementVendorUpdate>
  readonly payApp: PayApp
  /** The sum total of all open commitments whose date is on or before the pay app's billing end date */
  readonly totalCommitmentAmount: Scalars['SafeInt']['output']
  /** The last time the sworn statement metrics were updated */
  readonly updatedAt: Scalars['DateTime']['output']
  readonly vendorContract: VendorContract
}

export type PayAppSwornStatementVendorUpdate = {
  readonly __typename: 'PayAppSwornStatementVendorUpdate'
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  /** If updated via integration, this is the through date used for fetching vendor invoices */
  readonly integrationInvoicesThroughDate: Maybe<Scalars['DateTime']['output']>
  /**
   * The user who last manually edited the amounts. If the last edit was from an update via
   * integration, or the amounts have not yet been edited, this should be null.
   */
  readonly manuallyUpdatedBy: Maybe<User>
  readonly swornStatementVendor: PayAppSwornStatementVendor
  readonly updatedAt: Scalars['DateTime']['output']
  /**
   * The integration from which the amounts were last updated. If the last edit was manual, or the
   * amounts have not yet been edited, this should be null.
   */
  readonly updatedFromIntegrationType: Maybe<IntegrationType>
}

export type PayAppsSubmittedData = {
  readonly __typename: 'PayAppsSubmittedData'
  readonly companyCreatedAt: Scalars['DateTime']['output']
  readonly companyId: Scalars['ID']['output']
  readonly companyName: Scalars['String']['output']
  readonly payAppsSubmittedThisMonth: Scalars['SafeInt']['output']
  readonly sitelinePointOfContactId: Maybe<Scalars['ID']['output']>
  readonly sitelinePointOfContactName: Maybe<Scalars['String']['output']>
}

export type PayAppsSubmittedMonthInput = {
  /** This is in YYYY-MM format */
  readonly month: Scalars['String']['input']
}

export { PaymentDateEstimationType }

export type PaymentPeriodDateRange = {
  readonly __typename: 'PaymentPeriodDateRange'
  readonly endDate: Scalars['String']['output']
  readonly startDate: Scalars['String']['output']
}

export type PendingChangeOrderReport = {
  readonly __typename: 'PendingChangeOrderReport'
  readonly changeOrderRequests: ReadonlyArray<ChangeOrderRequest>
  readonly totalAmountApproved: Scalars['SafeInt']['output']
  readonly totalAmountAuthorizedToProceed: Scalars['SafeInt']['output']
  readonly totalAmountInDraft: Scalars['SafeInt']['output']
  readonly totalAmountProceedingWithWork: Scalars['SafeInt']['output']
  readonly totalAmountRequested: Scalars['SafeInt']['output']
  readonly totalOriginalContractAmount: Scalars['SafeInt']['output']
  readonly totalRevisedContractAmount: Scalars['SafeInt']['output']
}

export { Permission }

export { PhysicalSignatureRequired }

/** A template version pinned on a pay-app. Templates are pinned at signing time. */
export type PinnedPayAppTemplate = {
  readonly __typename: 'PinnedPayAppTemplate'
  readonly formTemplateVariant: FormTemplateVariant
  readonly formTemplateVersion: FormTemplateVersion
  readonly order: Scalars['Int']['output']
  readonly payApp: PayApp
}

/**
 * A pay app that was created before the contract was onboarded onto Siteline.
 * It is used purely for aging reports & unconditional lien waivers.
 * It does not act as the source of truth for pre-siteline billing (that's on the contract itself).
 */
export type PreSitelinePayApp = {
  readonly __typename: 'PreSitelinePayApp'
  readonly amountPaid: Maybe<Scalars['SafeInt']['output']>
  readonly billingEnd: Maybe<Scalars['DateTime']['output']>
  readonly billingStart: Maybe<Scalars['DateTime']['output']>
  readonly contract: Contract
  readonly createdAt: Scalars['DateTime']['output']
  readonly currentBilled: Maybe<Scalars['SafeInt']['output']>
  readonly id: Scalars['ID']['output']
  /**
   * If the amount paid on the pay app doesn't match the amount due, we allow the customer to manually
   * mark the pay app balance as closed so the remaining amount isn't considered due and doesn't appear
   * in aging reports.
   */
  readonly isBalanceManuallyClosed: Scalars['Boolean']['output']
  readonly isFinal: Scalars['Boolean']['output']
  readonly isNotarizingUnconditional: Scalars['Boolean']['output']
  readonly paidAt: Maybe<Scalars['DateTime']['output']>
  readonly payAppFile: Maybe<StoredFile>
  readonly payAppNumber: Scalars['Int']['output']
  readonly paymentDue: Maybe<Scalars['SafeInt']['output']>
  readonly percentComplete: Scalars['Float']['output']
  readonly retentionOnly: Scalars['Boolean']['output']
  readonly status: Maybe<PreSitelinePayAppStatus>
  readonly submittedAt: Maybe<Scalars['DateTime']['output']>
  readonly submittedUnconditionalOutsideOfSiteline: Scalars['Boolean']['output']
  readonly timeZone: Scalars['String']['output']
  readonly unconditionalLienWaiver: Maybe<LienWaiver>
}

export { PreSitelinePayAppStatus }

export type ProcessSalesLeadsInput = {
  readonly companyNames: ReadonlyArray<Scalars['String']['input']>
  readonly leadSourceDetail: Scalars['String']['input']
}

/** Represents a single project that produces payment applications on a regular schedule. */
export type Project = {
  readonly __typename: 'Project'
  readonly architect: Maybe<CompanyProjectMetadata>
  readonly bondNumber: Maybe<Scalars['String']['output']>
  readonly bondProvider: Maybe<CompanyProjectMetadata>
  readonly contracts: ReadonlyArray<Contract>
  readonly createdAt: Scalars['DateTime']['output']
  readonly generalContractor: Maybe<CompanyProjectMetadata>
  readonly id: Scalars['ID']['output']
  readonly location: Location
  readonly metadata: ProjectMetadata
  readonly name: Scalars['String']['output']
  readonly owner: Maybe<CompanyProjectMetadata>
  /** Project number as defined by the General Contractor. Subcontractors use Contract.internalProjectNumber instead. */
  readonly projectNumber: Scalars['String']['output']
  readonly timeZone: Scalars['String']['output']
  readonly updatedAt: Scalars['DateTime']['output']
}

export type ProjectCompanies = {
  readonly __typename: 'ProjectCompanies'
  readonly architects: ReadonlyArray<Company>
  readonly bondProviders: ReadonlyArray<Company>
  readonly generalContractors: ReadonlyArray<Company>
  readonly owners: ReadonlyArray<Company>
}

export type ProjectDocumentsByVendor = {
  readonly __typename: 'ProjectDocumentsByVendor'
  readonly periodStart: Maybe<Scalars['DateTime']['output']>
  readonly vendor: Vendor
  /** List of documents for different projects of a vendor for a specific period. */
  readonly vendorDocuments: ReadonlyArray<VendorLegalDocument>
}

/** A single project to be asynchronously imported from an integration */
export type ProjectImportOperation = {
  readonly __typename: 'ProjectImportOperation'
  readonly company: Company
  /** If null, the project import was triggered from a manual import (i.e. from Excel or CSV) */
  readonly companyIntegration: Maybe<CompanyIntegration>
  /** Set if the import was successful */
  readonly contract: Maybe<Contract>
  readonly createdAt: Scalars['DateTime']['output']
  readonly createdBy: User
  readonly error: Maybe<Scalars['String']['output']>
  readonly group: ProjectImportOperationGroup
  readonly groupId: Scalars['ID']['output']
  readonly id: Scalars['ID']['output']
  readonly integrationProjectName: Maybe<Scalars['String']['output']>
  readonly integrationProjectNumber: Maybe<Scalars['String']['output']>
  readonly metadata: Scalars['JSONObject']['output']
  /** If provided, this integration will be removed if the import is successful */
  readonly migratingFromIntegration: Maybe<Integration>
  readonly status: ImportOperationStatus
  readonly updatedAt: Scalars['DateTime']['output']
}

/** Contains some stats about a particular import group. Computed for each ProjectImportOperation. */
export type ProjectImportOperationGroup = {
  readonly __typename: 'ProjectImportOperationGroup'
  readonly numFailed: Scalars['SafeInt']['output']
  readonly numSuccessful: Scalars['SafeInt']['output']
  readonly status: ImportOperationStatus
  readonly totalOperations: Scalars['SafeInt']['output']
}

/** DEPRECATED */
export type ProjectLienWaiversByMonth = {
  readonly __typename: 'ProjectLienWaiversByMonth'
  readonly lienWaiversByMonth: ReadonlyArray<LienWaiversByMonth>
  readonly project: Project
  readonly requiresLowerTierLienWaivers: Scalars['Boolean']['output']
}

/** Represents metadata around a project. */
export type ProjectMetadata = {
  readonly __typename: 'ProjectMetadata'
  readonly fieldProgressDueOnDayOfMonth: Scalars['Int']['output']
  readonly payAppDueOnDayOfMonth: Scalars['Int']['output']
}

export type ProjectMetadataInput = {
  readonly payAppDueOnDayOfMonth: Scalars['Int']['input']
}

/**
 * Every field should be provided for lump sum and unit price projects but only amountBilledAndDraftThisMonth
 * will be provided for quick bills (which is why its the only required field)
 */
export type ProjectMetrics = {
  readonly __typename: 'ProjectMetrics'
  /** Gross billed, including drafts */
  readonly amountBilledAndDraftThisMonth: Scalars['SafeInt']['output']
  /**
   * Gross billed, drafts only.
   * WARNING: This ignores the payAppStatus filter.
   */
  readonly amountInDraftThisMonth: Maybe<Scalars['SafeInt']['output']>
  /**
   * Gross billed, drafts only.
   * This complies with the payAppStatus filter, which means it is 0 if the filter excludes drafts.
   */
  readonly amountInDraftThisMonthFiltered: Maybe<Scalars['SafeInt']['output']>
  readonly balanceToFinish: Maybe<Scalars['SafeInt']['output']>
  readonly billedAndDraftRetentionHeldThisMonth: Maybe<Scalars['SafeInt']['output']>
  /** Gross amount billed to date */
  readonly completedThruMonth: Maybe<Scalars['SafeInt']['output']>
  /** Net billed (deducting retention held), including drafts */
  readonly netAmountBilledAndDraftThisMonth: Scalars['SafeInt']['output']
  /**
   * Net billed (deducting retention held), drafts only.
   * WARNING: This ignores the payAppStatus filter.
   */
  readonly netAmountInDraftThisMonth: Maybe<Scalars['SafeInt']['output']>
  /** Net amount billed to date (deducting retention held) */
  readonly netCompletedThruMonth: Maybe<Scalars['SafeInt']['output']>
  readonly percentCompletedThruMonth: Maybe<Scalars['Float']['output']>
  /** Progress billed this month minus retention held. Retention billed is not included. */
  readonly progressBilledAndDraftMinusRetentionHeldThisMonth: Maybe<Scalars['SafeInt']['output']>
  /**
   * Progress billed this month, not deducting retention held.
   * Retention billed is not included.
   * This is the same as amountBilledAndDraftThisMonth.
   */
  readonly progressBilledAndDraftThisMonth: Maybe<Scalars['SafeInt']['output']>
  /**
   * Progress in draft this month minus retention held
   * Retention billed is not included.
   * This complies with the payAppStatus filter, which means it is 0 if the filter excludes drafts.
   */
  readonly progressInDraftMinusRetentionHeldThisMonth: Maybe<Scalars['SafeInt']['output']>
  /** Retention billed this month */
  readonly retentionBilledAndDraftThisMonth: Maybe<Scalars['SafeInt']['output']>
  /**
   * Retention in draft this month.
   * This complies with the payAppStatus filter, which means it is 0 if the filter excludes drafts.
   */
  readonly retentionBilledInDraftThisMonth: Maybe<Scalars['SafeInt']['output']>
  /** Retention held on only submitted pay apps */
  readonly retentionHeldThisMonth: Maybe<Scalars['SafeInt']['output']>
  /** All retention held to date, including draft and submitted pay apps */
  readonly retentionHeldThruMonth: Maybe<Scalars['SafeInt']['output']>
  readonly totalValue: Maybe<Scalars['SafeInt']['output']>
}

export { ProjectOnboardingFormType }

/** DEPRECATED */
export type ProjectsByVendor = {
  readonly __typename: 'ProjectsByVendor'
  readonly month: Scalars['SafeInt']['output']
  readonly projectLienWaivers: ReadonlyArray<LienWaiversByProject>
  readonly vendorId: Scalars['ID']['output']
  readonly vendorName: Scalars['String']['output']
  readonly year: Scalars['SafeInt']['output']
}

export type Query = {
  readonly __typename: 'Query'
  /** Finds count and ID of abandoned pay apps for a given company */
  readonly abandonedPayApps: ReadonlyArray<AbandonedPayAppsData>
  /** Get Agave debugger session url */
  readonly agaveDebuggerSessionUrl: Maybe<Scalars['String']['output']>
  /** Gets an Agave link token. */
  readonly agaveLinkToken: Scalars['String']['output']
  /** Gets a list of users at a company who have access to every company project */
  readonly allProjectUsersByCompany: ReadonlyArray<CompanyUser>
  /** Finds the number of pay apps submitted by each company in each month */
  readonly billingTotals: ReadonlyArray<BillingTotalsData>
  /** Returns a list of all keys on the generic or files cache, by key pattern. */
  readonly cacheKeys: ReadonlyArray<Scalars['String']['output']>
  /** Finds percent of projects per company with change order requests */
  readonly changeOrderLogUse: ReadonlyArray<ChangeOrderLogUseData>
  /** Returns logs from Cloud Logging */
  readonly cloudLoggingLogs: CloudLoggingLogsResponse
  /** Returns all companies */
  readonly companies: ReadonlyArray<Company>
  /** Returns a specific company */
  readonly company: Company
  /** Get internal siteline notes for a company */
  readonly companyInternalSitelineNotes: Maybe<Scalars['String']['output']>
  /** Finds percent of projects per company with legal requirements */
  readonly complianceUse: ReadonlyArray<ComplianceUseData>
  /** Returns a contract by ID */
  readonly contract: Contract
  /** Gets a list of form templates associated with a contract. */
  readonly contractFormTemplates: ReadonlyArray<FormTemplate>
  /** Returns a list of contracts by ID */
  readonly contractsById: ReadonlyArray<Contract>
  /** Returns the companies used for customer demos */
  readonly demoCompanies: ReadonlyArray<Company>
  /** Gets a list of PayApps, LienWaivers, and LegalDocuments that use a form template version */
  readonly entitiesUsingFormTemplateVersion: FormTemplateVersionEntities
  /** Gets a form template by ID */
  readonly formTemplate: FormTemplate
  /** Gets a form template variant by ID */
  readonly formTemplateVariant: FormTemplateVariant
  /** Gets a form template version by ID */
  readonly formTemplateVersion: FormTemplateVersion
  /** Finds the number of forms a company has requeseted, how many have been completed, and the ratio */
  readonly formTracker: ReadonlyArray<FormTrackerData>
  /** Finds how long it takes to complete form templates given a variety of filters */
  readonly formTurnaroundData: FormTurnaroundData
  /** Contracts that have been fully billed in Siteline */
  readonly fullyBilledContracts: ReadonlyArray<Contract>
  /** Gets a list of all general contractors for a specific company */
  readonly generalContractors: ReadonlyArray<Company>
  /** Finds all general contractor companies for deduping */
  readonly generalContractorsForDedupe: ReadonlyArray<GeneralContractorForDedupe>
  /** Returns invoice type info for Sage Intacct from the integration service */
  readonly integrationInvoiceTypes: ReadonlyArray<IntegrationInvoiceType>
  /** Returns a list of 1Password items in the Siteline Customer Login vault, with their title only */
  readonly integrationOnePasswordItems: ReadonlyArray<IntegrationOnePasswordItem>
  /** Gets a legal requirement by ID */
  readonly legalRequirement: LegalRequirement
  /** Number of lien waivers requested per company in the last 30 days */
  readonly lienWaiversRequested: ReadonlyArray<LienWaiverRequestData>
  /** List of paginated companies according to specific filters */
  readonly paginatedCompanies: PaginatedCompanyList
  /** List of paginated contracts according to specific filters */
  readonly paginatedContracts: PaginatedContractList
  /** List of paginated contracts according to specific filters */
  readonly paginatedTemplates: PaginatedTemplateList
  /** Returns a list of paginated write sync operations */
  readonly paginatedWriteSyncOperations: PaginatedWriteSyncOperations
  /** Returns a specific pay-app */
  readonly payApp: PayApp
  /** Finds number of pay apps submitted in a given month per company */
  readonly payAppsSubmittedMonth: ReadonlyArray<PayAppsSubmittedData>
  /** All GraphQL servers need a root query type. This one is empty. */
  readonly placeholder: Scalars['Boolean']['output']
  /** Returns a project by ID */
  readonly project: Project
  /** Returns all projects */
  readonly projects: ReadonlyArray<Project>
  /** Gets a list of projects that a company is part of as an owner, GC or sub. */
  readonly projectsByCompany: ReadonlyArray<Project>
  /** Gets a list of projects that a user is part of. */
  readonly projectsByUser: ReadonlyArray<Project>
  /** Search projects, companies, etc */
  readonly search: ReadonlyArray<SearchResult>
  /** Find templates similar to a given file */
  readonly similarTemplates: ReadonlyArray<SimilarTemplate>
  /** Returns all the Siteline team members */
  readonly sitelineTeamMembers: ReadonlyArray<User>
  /** Gets a list of all SOV Line Item Groups for a provided company and project. */
  readonly sovLineItemGroups: ReadonlyArray<SovLineItemGroup>
  /** Template build statistics */
  readonly templateBuildStats: TemplateBuildStats
  /** Finds time to value for newly onboarded companies */
  readonly timeToValue: ReadonlyArray<TimeToValueData>
  /** Returns a user by ID */
  readonly user: User
  /** Returns details for a single user education campaign */
  readonly userEducationCampaign: UserEducationCampaign
  /** Returns all user education campaigns */
  readonly userEducationCampaigns: ReadonlyArray<UserEducationCampaign>
}

export type QueryAgaveDebuggerSessionUrlArgs = {
  input: AgaveDebuggerSessionUrlInput
}

export type QueryAgaveLinkTokenArgs = {
  companyIntegrationId: Scalars['ID']['input']
}

export type QueryAllProjectUsersByCompanyArgs = {
  id: Scalars['ID']['input']
}

export type QueryBillingTotalsArgs = {
  input: BillingTotalsInput
}

export type QueryCacheKeysArgs = {
  input: GetCacheKeysInput
}

export type QueryCloudLoggingLogsArgs = {
  input: CloudLoggingLogsInput
}

export type QueryCompanyArgs = {
  id: Scalars['ID']['input']
}

export type QueryCompanyInternalSitelineNotesArgs = {
  companyId: Scalars['ID']['input']
}

export type QueryContractArgs = {
  id: Scalars['ID']['input']
}

export type QueryContractFormTemplatesArgs = {
  contractId: Scalars['ID']['input']
}

export type QueryContractsByIdArgs = {
  input: ContractsByIdInput
}

export type QueryEntitiesUsingFormTemplateVersionArgs = {
  id: Scalars['ID']['input']
}

export type QueryFormTemplateArgs = {
  id: Scalars['ID']['input']
}

export type QueryFormTemplateVariantArgs = {
  id: Scalars['ID']['input']
}

export type QueryFormTemplateVersionArgs = {
  id: Scalars['ID']['input']
}

export type QueryFormTrackerArgs = {
  input: FormTrackerInput
}

export type QueryFormTurnaroundDataArgs = {
  input: FormTurnaroundDataInput
}

export type QueryGeneralContractorsArgs = {
  companyId: Scalars['ID']['input']
}

export type QueryGeneralContractorsForDedupeArgs = {
  companyId: Scalars['ID']['input']
}

export type QueryIntegrationInvoiceTypesArgs = {
  input: IntegrationInvoiceTypesInput
}

export type QueryLegalRequirementArgs = {
  id: Scalars['ID']['input']
}

export type QueryPaginatedCompaniesArgs = {
  input: GetPaginatedCompaniesInput
}

export type QueryPaginatedContractsArgs = {
  input: GetPaginatedContractsInput
}

export type QueryPaginatedTemplatesArgs = {
  input: GetPaginatedTemplatesInput
}

export type QueryPaginatedWriteSyncOperationsArgs = {
  input: GetPaginatedWriteSyncOperationsInput
}

export type QueryPayAppArgs = {
  id: Scalars['ID']['input']
}

export type QueryPayAppsSubmittedMonthArgs = {
  input: PayAppsSubmittedMonthInput
}

export type QueryProjectArgs = {
  id: Scalars['ID']['input']
}

export type QueryProjectsByCompanyArgs = {
  id: Scalars['ID']['input']
}

export type QueryProjectsByUserArgs = {
  id: Scalars['ID']['input']
}

export type QuerySearchArgs = {
  query: Scalars['String']['input']
  type?: InputMaybe<SearchResultType>
}

export type QuerySimilarTemplatesArgs = {
  upload: Scalars['Upload']['input']
}

export type QuerySovLineItemGroupsArgs = {
  contractId: Scalars['ID']['input']
}

export type QueryUserArgs = {
  id: Scalars['ID']['input']
}

export type QueryUserEducationCampaignArgs = {
  id: Scalars['ID']['input']
}

export type RateTable = {
  readonly __typename: 'RateTable'
  readonly archivedAt: Maybe<Scalars['DateTime']['output']>
  /**
   * Returns all contracts associated with the rate table. This includes contracts that are currently
   * linked to the rate table, as well as contracts with a pay app linked to the rate table or a
   * change order request linked to the rate table. This may not be the current rate table associated
   * with all associated contracts, as a newer rate table may have been linked since the pay app or
   * COR was billed or priced.
   */
  readonly associatedContracts: ReadonlyArray<Contract>
  readonly company: Company
  /** List of contracts that are currently linked to this rate table */
  readonly contracts: ReadonlyArray<Contract>
  readonly createdAt: Scalars['DateTime']['output']
  /** If the rate table becomes effective as of a certain date, that date may be set here */
  readonly effectiveAt: Maybe<Scalars['DateTime']['output']>
  /** If the rate table expires as of a certain date, that date may be set here */
  readonly expiresAt: Maybe<Scalars['DateTime']['output']>
  readonly groups: ReadonlyArray<RateTableGroup>
  readonly id: Scalars['ID']['output']
  /** Mappings of Siteline entity to Integration entity. Fields depend on the integration type. */
  readonly integrationMappings: Scalars['JSONObject']['output']
  readonly items: ReadonlyArray<RateTableItem>
  readonly lastEditedAt: Scalars['DateTime']['output']
  readonly lastEditedBy: User
  readonly name: Scalars['String']['output']
  readonly status: RateTableStatus
  readonly updatedAt: Scalars['DateTime']['output']
}

export type RateTableGroup = {
  readonly __typename: 'RateTableGroup'
  readonly contractRateTableFees: ReadonlyArray<ContractRateTableFee>
  readonly contractRateTableTaxGroups: ReadonlyArray<ContractRateTableTaxGroup>
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  readonly items: ReadonlyArray<RateTableItem>
  readonly name: Scalars['String']['output']
  readonly rateTable: RateTable
  readonly type: LineItemType
  readonly updatedAt: Scalars['DateTime']['output']
}

export type RateTableItem = {
  readonly __typename: 'RateTableItem'
  readonly code: Maybe<Scalars['String']['output']>
  readonly createdAt: Scalars['DateTime']['output']
  readonly description: Scalars['String']['output']
  readonly group: RateTableGroup
  readonly id: Scalars['ID']['output']
  readonly rateTable: RateTable
  /** Order in which to display the rate table line items. First line item starts at 1. */
  readonly sortOrder: Scalars['SafeInt']['output']
  readonly unitName: Maybe<Scalars['String']['output']>
  readonly unitRate: Scalars['SafeInt']['output']
  readonly updatedAt: Scalars['DateTime']['output']
}

export { RateTableStatus }

export type RefreshIntegrationMappingsInput = {
  readonly integrationId: Scalars['ID']['input']
  readonly refreshLegalRequirements: Scalars['Boolean']['input']
  readonly refreshProjectAndContract: Scalars['Boolean']['input']
  readonly refreshVendors: Scalars['Boolean']['input']
}

export type ReplaceFormTemplateAnnotationsInput = {
  readonly annotations: ReadonlyArray<FormAnnotationInput>
  readonly formTemplateVersionId: Scalars['ID']['input']
}

export type RequeueWriteSyncInput = {
  readonly writeSyncOperationId: Scalars['ID']['input']
}

export { RequiredFieldForForms }

export type RetentionBillingResult = {
  readonly __typename: 'RetentionBillingResult'
  readonly retentionHeldCurrent: Scalars['Float']['output']
  readonly retentionHeldToDate: Scalars['Float']['output']
  readonly retentionReleased: Scalars['Float']['output']
}

export type RetentionChangelog = {
  readonly __typename: 'RetentionChangelog'
  /** Whether payAppChangedIn is retentionOnly, used for sorting the changelog. */
  readonly changedInRetentionOnlyPayApp: Scalars['Boolean']['output']
  /** Contract this retention changelog is for. */
  readonly contract: Contract
  readonly id: Scalars['ID']['output']
  /**
   * The line item for which this retention percent applies. If not specified, it impacts the entire
   * pay app.
   */
  readonly lineItemRetentionAdjustedOn: Maybe<SovLineItem>
  /** The billingEnd of the payAppChangedIn, used for sorting the changelog. */
  readonly monthChangedIn: Maybe<Scalars['DateTime']['output']>
  /** The pay app in which the user set the percent for the past pay app. */
  readonly payAppChangedIn: Maybe<PayApp>
  /**
   * The past pay app for which this retention percent applies. If not specified, it represents
   * retention from before onboarding to Siteline.
   */
  readonly payAppRetentionAdjustedOn: Maybe<PayApp>
  /** Whether this retention is from before the project was added to Siteline. */
  readonly preSiteline: Scalars['Boolean']['output']
  /** The progress retention on this progress or pay app not including the delta. */
  readonly progressRetentionAmount: Scalars['SafeInt']['output']
  /** The progress retention percent as a decimal (0-1). */
  readonly progressRetentionPercent: Scalars['Float']['output']
  /**
   * Amount of retention for this line item or pay app being billed for in the payAppChangedIn. Note:
   * this value is always 0 if the payAppChangedIn is the current pay app because you can't bill for
   * retention in the current month since it hasn't been held yet.
   */
  readonly retentionBilled: Scalars['SafeInt']['output']
  /** Fudge amount of retention on this progress or pay app. */
  readonly retentionDelta: Scalars['SafeInt']['output']
  /** The stored materials retention on this progress or pay app not including the delta. */
  readonly storedMaterialsRetentionAmount: Scalars['SafeInt']['output']
  /** The stored materials retention percent as a decimal (0-1). */
  readonly storedMaterialsRetentionPercent: Scalars['Float']['output']
}

export { RetentionTrackingLevel }

export type RotateIntegrationCredentialInput = {
  readonly companyIntegrationId: Scalars['ID']['input']
}

export { Sage100InvoiceTaxType }

export type SearchResult = {
  readonly __typename: 'SearchResult'
  readonly description: Scalars['String']['output']
  readonly id: Scalars['String']['output']
  readonly score: Scalars['Float']['output']
  readonly type: SearchResultType
}

export { SearchResultType }

export type SendCompanyMonthlyReportTestEmailInput = {
  readonly companyId: Scalars['ID']['input']
  readonly month: Scalars['Int']['input']
  readonly userId?: InputMaybe<Scalars['ID']['input']>
  readonly year: Scalars['Int']['input']
}

export type SetCompanyInternalSitelineNotesInput = {
  readonly companyId: Scalars['ID']['input']
  readonly internalSitelineNotes?: InputMaybe<Scalars['String']['input']>
}

export type SetFormTemplateTagsInput = {
  readonly id: Scalars['ID']['input']
  readonly tags: ReadonlyArray<FormTemplateTag>
}

export type SetLineItemsInGroupInput = {
  readonly groupId: Scalars['ID']['input']
  readonly sovLineItemIds: ReadonlyArray<Scalars['ID']['input']>
}

export type SetPayAppProposedInput = {
  readonly id: Scalars['ID']['input']
  readonly proposedAt: Scalars['DateTime']['input']
}

export type SetPayAppSyncedInput = {
  readonly id: Scalars['ID']['input']
  readonly syncedAt: Scalars['DateTime']['input']
}

/**
 * When a user signs a document, they either use their name + font or they upload a file. Depending on
 * which is chosen, either the name + font are populated or the file name + url + type are populated.
 * There are 2 checks:
 * 1. Confirms that either the user or vendor is specified but not both.
 * 2. Confirms that either a manual signature is specified or a uploaded signature but not both.
 */
export type Signature = {
  readonly __typename: 'Signature'
  readonly createdAt: Scalars['DateTime']['output']
  readonly file: Maybe<StoredFile>
  readonly id: Scalars['ID']['output']
  readonly ipAddress: Maybe<Scalars['String']['output']>
  readonly signatureFont: Maybe<SignatureFont>
  readonly signatureName: Maybe<Scalars['String']['output']>
  readonly updatedAt: Scalars['DateTime']['output']
  readonly user: Maybe<User>
  readonly vendorContact: Maybe<VendorContact>
}

export { SignatureAnnotationType }

export { SignatureFont }

export type SimilarTemplate = {
  readonly __typename: 'SimilarTemplate'
  /** Similarity score between the input file and returned template. Range: 0-1, higher is better. Score >= 0.8 is a strong match. */
  readonly score: Scalars['Float']['output']
  readonly template: FormTemplate
  readonly version: FormTemplateVersion
}

export type SkipFormTemplateValidationInput = {
  readonly formTemplateId: Scalars['ID']['input']
  readonly message: Scalars['String']['input']
}

/** Represents a full schedule of values for a given project and company. */
export type Sov = {
  readonly __typename: 'Sov'
  readonly contract: Contract
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  readonly lineItemGroups: ReadonlyArray<SovLineItemGroup>
  readonly lineItems: ReadonlyArray<SovLineItem>
  /**
   * A simple helper to share this logic. Runs the formula: (totalBilled / totalValue) to at most 2
   * decimal places.
   */
  readonly progressComplete: Scalars['Float']['output']
  /**
   * This includes both retention and stored materials. Stored materials are only ever billed for once,
   * and then they're considered a part of totalBilled. It makes sense to split it out in
   * SovLineItemProgress as we need to capture that in the specific month.
   */
  readonly totalBilled: Scalars['SafeInt']['output']
  /** This is the total amount of retention that has been accumulated on the entire SOV to date. */
  readonly totalRetention: Scalars['SafeInt']['output']
  /** Sum of totalValue from every SovLineItem. Updated any time the SOV is modified. */
  readonly totalValue: Scalars['SafeInt']['output']
  readonly updatedAt: Scalars['DateTime']['output']
}

export type SovChangeSet = {
  readonly __typename: 'SovChangeSet'
  readonly additions: ReadonlyArray<LineItemAddition>
  readonly deletions: ReadonlyArray<LineItemDeletion>
  readonly groupAdditions: ReadonlyArray<LineItemGroupAddition>
  readonly groupDeletions: ReadonlyArray<LineItemGroupDeletion>
  readonly groupUpdates: ReadonlyArray<LineItemGroupUpdate>
  readonly updates: ReadonlyArray<LineItemUpdate>
}

export type SovChangeSetCreateLineItem = {
  readonly __typename: 'SovChangeSetCreateLineItem'
  readonly changeOrderApprovedDate: Maybe<Scalars['DateTime']['output']>
  readonly changeOrderEffectiveDate: Maybe<Scalars['DateTime']['output']>
  readonly code: Scalars['String']['output']
  readonly costCode: Maybe<Scalars['String']['output']>
  readonly defaultRetentionPercent: Maybe<Scalars['Float']['output']>
  readonly isChangeOrder: Scalars['Boolean']['output']
  readonly latestTotalValue: Scalars['SafeInt']['output']
  readonly name: Scalars['String']['output']
  readonly originalTotalValue: Scalars['SafeInt']['output']
  readonly preSitelineRetentionHeldOverride: Maybe<Scalars['SafeInt']['output']>
  readonly previousBilled: Scalars['SafeInt']['output']
  readonly previousMaterialsInStorage: Maybe<Scalars['SafeInt']['output']>
  readonly previousStoredMaterials: Maybe<Scalars['SafeInt']['output']>
  readonly sortOrder: Maybe<Scalars['SafeInt']['output']>
  readonly sovLineItemGroupId: Maybe<Scalars['String']['output']>
  readonly taxGroupId: Maybe<Scalars['ID']['output']>
  readonly unitName: Maybe<Scalars['String']['output']>
  readonly unitPrice: Maybe<Scalars['Decimal']['output']>
}

export type SovChangeSetCreateLineItemGroup = {
  readonly __typename: 'SovChangeSetCreateLineItemGroup'
  readonly code: Maybe<Scalars['String']['output']>
  readonly name: Scalars['String']['output']
}

export type SovChangeSetUpdateLineItem = {
  readonly __typename: 'SovChangeSetUpdateLineItem'
  /** Undefined to ignore, null to remove */
  readonly changeOrderApprovedDate: Maybe<Scalars['DateTime']['output']>
  readonly changeOrderEffectiveDate: Maybe<Scalars['DateTime']['output']>
  readonly code: Maybe<Scalars['String']['output']>
  readonly costCode: Maybe<Scalars['String']['output']>
  readonly defaultRetentionPercent: Maybe<Scalars['Float']['output']>
  readonly isChangeOrder: Maybe<Scalars['Boolean']['output']>
  readonly latestTotalValue: Maybe<Scalars['SafeInt']['output']>
  readonly name: Maybe<Scalars['String']['output']>
  readonly originalTotalValue: Maybe<Scalars['SafeInt']['output']>
  readonly preSitelineRetentionHeldOverride: Maybe<Scalars['SafeInt']['output']>
  readonly previousBilled: Maybe<Scalars['SafeInt']['output']>
  readonly previousMaterialsInStorage: Maybe<Scalars['SafeInt']['output']>
  readonly previousStoredMaterials: Maybe<Scalars['SafeInt']['output']>
  readonly sortOrder: Maybe<Scalars['SafeInt']['output']>
  readonly sovLineItemGroupId: Maybe<Scalars['String']['output']>
  readonly taxGroupId: Maybe<Scalars['ID']['output']>
  readonly unitName: Maybe<Scalars['String']['output']>
  readonly unitPrice: Maybe<Scalars['Decimal']['output']>
}

export type SovChangeSetUpdateLineItemGroup = {
  readonly __typename: 'SovChangeSetUpdateLineItemGroup'
  readonly code: Maybe<Scalars['String']['output']>
  readonly name: Maybe<Scalars['String']['output']>
}

/**
 * Represents a single line item from a single SOV.
 * Constraints:
 * - Ensure that a change order always has an approval date, and that a regular line item does not
 *   have an change-order approval date
 */
export type SovLineItem = {
  readonly __typename: 'SovLineItem'
  /**
   * Equivalent to `totalBilled`, except includes progress billed on draft and signed pay apps as well as
   * proposed and paid.
   */
  readonly billedToDate: Scalars['SafeInt']['output']
  /** If this is a change order, this is the date that this SovLineItem was approved. */
  readonly changeOrderApprovedDate: Maybe<Scalars['DateTime']['output']>
  /**
   * If this is a change order and it should start appearing on pay apps in a month other than the
   * month it was approved, this month will be used instead of the approval date. By default, the
   * change order is considered effective in the month it was approved.
   */
  readonly changeOrderEffectiveDate: Maybe<Scalars['DateTime']['output']>
  /**
   * Change order requests that are associated with this line item. All change order requests in
   * this list should be linked to each other as well.
   */
  readonly changeOrderRequests: ReadonlyArray<ChangeOrderRequest>
  readonly code: Scalars['String']['output']
  readonly costCode: Maybe<Scalars['String']['output']>
  readonly createdAt: Scalars['DateTime']['output']
  /** Tracks what initial retention % to initialize progress at for the first pay app. Range 0-1. */
  readonly defaultRetentionPercent: Maybe<Scalars['Float']['output']>
  readonly id: Scalars['ID']['output']
  /** This is set to true if this line item is a part of a change order, false otherwise. */
  readonly isChangeOrder: Scalars['Boolean']['output']
  /** The latest total value for the line item */
  readonly latestTotalValue: Scalars['SafeInt']['output']
  readonly name: Scalars['String']['output']
  /** The original total value of the line item when it was added */
  readonly originalTotalValue: Scalars['SafeInt']['output']
  /** The computed total amount of retention held pre-siteline in cents. */
  readonly preSitelineRetention: Scalars['SafeInt']['output']
  /**
   * Pre-siteline retention override amount in cents.
   * This is required for pay-app/project tracking.
   */
  readonly preSitelineRetentionHeldOverride: Maybe<Scalars['SafeInt']['output']>
  /**
   * Tax rate to apply to pre-siteline billing.
   * This is automatically set when enabling taxes at the beginning of a contract, or directly
   * when importing/creating a contract.
   */
  readonly preSitelineTaxPercent: Scalars['Float']['output']
  /**
   * This is the total amount previously billed for this line item if the project is being added to
   * Siteline part of the way through. For new projects this number is set to 0.
   */
  readonly previousBilled: Scalars['SafeInt']['output']
  /**
   * This is the total amount of materials that were held in storage at the time that the project was
   * added to Siteline. This number is only relevant to manual stored materials carryover type, where
   * materials in storage is tracked separately from materials billed.
   */
  readonly previousMaterialsInStorage: Scalars['SafeInt']['output']
  /**
   * This is the total amount of previously billed stored materials for this line item if the project
   * is being added to Siteline part of the way through. This number is independent from previousBilled
   * and previousBilled includes both progress and stored materials. For new projects this number is
   * set to 0.
   */
  readonly previousStoredMaterials: Scalars['SafeInt']['output']
  readonly progress: ReadonlyArray<SovLineItemProgress>
  /**
   * A simple helper to share this logic. Runs the formula: (totalBilled / totalValue) to at most 2
   * decimal places.
   */
  readonly progressComplete: Scalars['Float']['output']
  /**
   * If defined, it will be assumed that all SovLineItem objects have the sortOrder defined and this
   * will be used to sort in order instead of the `code` field, which is the default sorting method.
   */
  readonly sortOrder: Scalars['SafeInt']['output']
  readonly sov: Sov
  /** Optional group for this line item */
  readonly sovLineItemGroup: Maybe<SovLineItemGroup>
  /** Optional tax rate to apply to the line item. It must be a tax group from the subcontractor company. */
  readonly taxGroup: Maybe<TaxGroup>
  /**
   * This includes both retention and stored materials. Stored materials are only ever billed for once,
   * and then they're considered a part of totalBilled. It makes sense to split it out in
   * SovLineItemProgress as we need to capture that in the specific month. Includes only proposed/paid pay apps.
   */
  readonly totalBilled: Scalars['SafeInt']['output']
  /** This is the total amount of retention that has been accumulated on this line item to date. */
  readonly totalRetention: Scalars['SafeInt']['output']
  /** List of all total values for the line item */
  readonly totalValues: ReadonlyArray<SovLineItemTotalValue>
  /** The human-readable unit of measurement for each unit, e.g. LF, EA, HR for UNIT_PRICE contract. */
  readonly unitName: Maybe<Scalars['String']['output']>
  /** A price per unit, in cents. This is used for UNIT_PRICE contracts. */
  readonly unitPrice: Maybe<Scalars['Decimal']['output']>
  readonly updatedAt: Scalars['DateTime']['output']
  readonly worksheetLineItems: ReadonlyArray<WorksheetLineItem>
}

/** Represents a group of SovLineItems, denoted primarily by a name. */
export type SovLineItemGroup = {
  readonly __typename: 'SovLineItemGroup'
  readonly code: Maybe<Scalars['String']['output']>
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  readonly lineItems: ReadonlyArray<SovLineItem>
  readonly name: Scalars['String']['output']
  readonly sov: Sov
  readonly updatedAt: Scalars['DateTime']['output']
}

export type SovLineItemInput = {
  readonly changeOrderApprovedDate?: InputMaybe<Scalars['DateTime']['input']>
  readonly code: Scalars['String']['input']
  readonly id?: InputMaybe<Scalars['String']['input']>
  readonly isChangeOrder: Scalars['Boolean']['input']
  readonly name: Scalars['String']['input']
  readonly previousBilled: Scalars['SafeInt']['input']
  readonly previousStoredMaterials?: InputMaybe<Scalars['SafeInt']['input']>
  readonly progressRetentionPercent?: InputMaybe<Scalars['Float']['input']>
  readonly sortOrder: Scalars['SafeInt']['input']
  readonly sovLineItemGroupId?: InputMaybe<Scalars['ID']['input']>
  readonly totalValue: Scalars['SafeInt']['input']
}

/**
 * Represents the progress for a single SOV line item, to be used in monthly pay apps.
 * Constraints:
 * - Ensure that there is only one progress per line-item/pay-app tuple.
 */
export type SovLineItemProgress = {
  readonly __typename: 'SovLineItemProgress'
  /** Amount due, post tax. This is amountDuePreTax + amountDueTaxAmount */
  readonly amountDuePostTax: Scalars['SafeInt']['output']
  /** Current billed - retention held + retention released. This is pre-tax. */
  readonly amountDuePreTax: Scalars['SafeInt']['output']
  /** Tax amount on the amount due. Tax rate is available on sovLineItem.taxGroup */
  readonly amountDueTaxAmount: Scalars['SafeInt']['output']
  /** Optional description of the PayAppAttachments associated with the SovLineItemProgress */
  readonly attachmentsDescription: Maybe<Scalars['String']['output']>
  readonly createdAt: Scalars['DateTime']['output']
  /** Sum of progressBilled + storedMaterialBilled. This does not deduct retention. */
  readonly currentBilled: Scalars['SafeInt']['output']
  /** Amount of retention held just for this period, on currentBilled. This is pre-tax. */
  readonly currentRetention: Scalars['SafeInt']['output']
  /**
   * A list of events related to this progress, like `SET_PROGRESS_BILLED`.
   * Pay-app events, like submissions, are not reflected here. They are reflected on the pay app itself
   */
  readonly events: ReadonlyArray<SovLineItemProgressEvent>
  /** This is the total amount billed in all progress for this line item after this pay app. */
  readonly futureBilled: Scalars['SafeInt']['output']
  readonly id: Scalars['ID']['output']
  /** If a past progress had a retention override on this line item, it's returned here */
  readonly lastProgressWithRetentionHeldOverride: Maybe<SovLineItemProgress>
  /** Alias for storedMaterialBilled */
  readonly materialsAddedToStorageInCurrentPayApp: Scalars['SafeInt']['output']
  /**
   * Materials left in storage after applying changes from this pay app
   * materialsInStorageThroughPreviousPayApp + materialsAddedToStorageInCurrentPayApp - materialsInstalledInCurrentPayApp
   */
  readonly materialsInStorageThroughCurrentPayApp: Scalars['SafeInt']['output']
  /** Sum of all previous storedMaterialBilled - previous materialsInstalledInCurrentPayApp */
  readonly materialsInStorageThroughPreviousPayApp: Scalars['SafeInt']['output']
  /**
   * Materials installed in the current pay app
   * Bounds: 0 to materialsInStorageThroughPreviousPayApp + materialsAddedToStorageInCurrentPayApp
   * In automatic mode, this is always equal to materialsAddedToStorageInCurrentPayApp (or storedMaterialBilled)
   */
  readonly materialsInstalledInCurrentPayApp: Scalars['SafeInt']['output']
  /** Retention held percent on the stored materials billing */
  readonly materialsRetentionHeldPercent: Maybe<Scalars['Float']['output']>
  readonly payApp: PayApp
  /** This is the total amount billed in all progress for this line item prior to this pay app. */
  readonly previousBilled: Scalars['SafeInt']['output']
  /** This is the total amount of retention in all progress for this line item prior to this pay app. */
  readonly previousRetention: Scalars['SafeInt']['output']
  /** The amount of previous retention billed for this period. */
  readonly previousRetentionBilled: Scalars['SafeInt']['output']
  /** Sum of all previous progress billed and materials installed. */
  readonly previousWorkCompleted: Scalars['SafeInt']['output']
  /** Progress billed this period, pre-tax. */
  readonly progressBilled: Scalars['SafeInt']['output']
  /** Retention held percent on the progress billing */
  readonly progressRetentionHeldPercent: Maybe<Scalars['Float']['output']>
  /**
   * Retention override amount on the sov line item progress on both progress and material billing. Takes
   * precedence over the progress and materials percents if set.
   */
  readonly retentionHeldOverride: Maybe<Scalars['SafeInt']['output']>
  /** The overall retention held percent as of this progress */
  readonly retentionHeldPercent: Scalars['Float']['output']
  /** Total retention being released for this line item on this progress in cents */
  readonly retentionReleased: Maybe<Scalars['SafeInt']['output']>
  readonly sovLineItem: SovLineItem
  /**
   * Pre-tax amount of materials bought (but not necessary installed) in this pay app,
   * to be immediately billed to the GC.
   */
  readonly storedMaterialBilled: Scalars['SafeInt']['output']
  /**
   * Tax rate applied to this progress, determined at the time of the pay app creation.
   * Value is between 0 and 1.
   */
  readonly taxPercent: Scalars['Float']['output']
  /** The line item's total value as of this progress */
  readonly totalValue: Scalars['SafeInt']['output']
  readonly updatedAt: Scalars['DateTime']['output']
  /**
   * This is the subcontractor user that last modified this progress. Could be empty if this was last
   * modified by a backend system.
   */
  readonly updatedBy: Maybe<User>
  /** Progress for all worksheet line items associated with this progress's SOV line item */
  readonly worksheetLineItemProgress: ReadonlyArray<WorksheetLineItemProgress>
}

/** An event tied to a line-item progress. */
export type SovLineItemProgressEvent = {
  readonly __typename: 'SovLineItemProgressEvent'
  readonly createdAt: Scalars['DateTime']['output']
  readonly createdBy: User
  readonly id: Scalars['ID']['output']
  readonly isAdmin: Scalars['Boolean']['output']
  /** Values in this dictionary depend on the event type */
  readonly metadata: Scalars['JSONObject']['output']
  readonly sovLineItemProgress: SovLineItemProgress
  readonly type: SovLineItemProgressEventType
}

export { SovLineItemProgressEventType }

/** A log of the total value of an SOV Line Item at different dates in time */
export type SovLineItemTotalValue = {
  readonly __typename: 'SovLineItemTotalValue'
  /**
   * If set, the total value only applies starting the given approved date. If the
   *  total value changes in a pay app, use the pay app start as the approved date.
   * If it is not set, it is the total value from pre-siteline onboarding.
   */
  readonly approvedDate: Maybe<Scalars['DateTime']['output']>
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  readonly lineItem: SovLineItem
  readonly totalValue: Scalars['SafeInt']['output']
  readonly updatedAt: Scalars['DateTime']['output']
}

/** Represents a file stored in Cloud Storage */
export type StoredFile = {
  readonly __typename: 'StoredFile'
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  readonly ipAddress: Maybe<Scalars['String']['output']>
  readonly name: Scalars['String']['output']
  readonly size: Scalars['SafeInt']['output']
  readonly type: StoredFileType
  readonly updatedAt: Scalars['DateTime']['output']
  readonly uploadedBy: Maybe<User>
  readonly url: Scalars['URL']['output']
}

export type StoredFileInput = {
  readonly file: Scalars['Upload']['input']
  readonly name: Scalars['String']['input']
}

export { StoredFileType }

export { StoredMaterialsCarryoverType }

/** A set of lien-waiver templates to use for a subcontractor */
export type SubcontractorLienWaiverTemplateSet = {
  readonly __typename: 'SubcontractorLienWaiverTemplateSet'
  readonly conditionalFinalVariant: Maybe<FormTemplateVariant>
  readonly conditionalProgressVariant: Maybe<FormTemplateVariant>
  readonly id: Scalars['ID']['output']
  readonly unconditionalFinalVariant: Maybe<FormTemplateVariant>
  readonly unconditionalProgressVariant: Maybe<FormTemplateVariant>
}

export type SubcontractorLienWaiverTemplateSetInput = {
  readonly conditionalFinalVariantId?: InputMaybe<Scalars['ID']['input']>
  readonly conditionalProgressVariantId?: InputMaybe<Scalars['ID']['input']>
  readonly unconditionalFinalVariantId?: InputMaybe<Scalars['ID']['input']>
  readonly unconditionalProgressVariantId?: InputMaybe<Scalars['ID']['input']>
}

export type Subscription = {
  readonly __typename: 'Subscription'
  /** Any time the given form template is updated, the data is pushed to the client. */
  readonly updatedFormTemplate: FormTemplate
}

export type SubscriptionUpdatedFormTemplateArgs = {
  id: Scalars['ID']['input']
}

export { TaxCalculationType }

/** A single tax rate belonging to a company. */
export type TaxGroup = {
  readonly __typename: 'TaxGroup'
  /** We archive tax groups instead of deleting them so we preserve any existing references */
  readonly archivedAt: Maybe<Scalars['DateTime']['output']>
  readonly company: Company
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  /** List of mappings to integration tax groups */
  readonly integrationMappings: Scalars['JSONObject']['output']
  readonly name: Scalars['String']['output']
  /** Tax percentage for this group, between 0 and 1 */
  readonly taxPercent: Scalars['Float']['output']
  readonly updatedAt: Scalars['DateTime']['output']
}

export type TaxGroupReport = {
  readonly __typename: 'TaxGroupReport'
  readonly billedPerTaxGroup: ReadonlyArray<BilledPerTaxGroup>
  readonly contractBilledPerTaxGroup: ReadonlyArray<ContractBilledPerTaxGroup>
  readonly summary: TaxGroupReportSummary
  readonly taxesByState: ReadonlyArray<TaxesByState>
}

export type TaxGroupReportSummary = {
  readonly __typename: 'TaxGroupReportSummary'
  readonly numProjects: Scalars['SafeInt']['output']
  readonly totalTaxAmountThisPeriod: Scalars['SafeInt']['output']
}

export type TaxesByState = {
  readonly __typename: 'TaxesByState'
  readonly state: Scalars['String']['output']
  readonly totalScheduledValue: Scalars['SafeInt']['output']
  readonly totalTaxAmountThisPeriod: Scalars['SafeInt']['output']
}

export type TemplateBuildStats = {
  readonly __typename: 'TemplateBuildStats'
  readonly builders: ReadonlyArray<TemplateBuildStatsBuilder>
}

export type TemplateBuildStatsBuilder = {
  readonly __typename: 'TemplateBuildStatsBuilder'
  readonly user: User
  /** Number of templates that are waiting for this user */
  readonly waitingOnCount: Scalars['Int']['output']
}

export { TemplateListSortCriteria }

export { TemplateListStatusFilter }

export { TextAlignment }

export type TimeToValueData = {
  readonly __typename: 'TimeToValueData'
  readonly billingByMonth: ReadonlyArray<TimeToValueMonth>
  readonly company: Company
}

export type TimeToValueMonth = {
  readonly __typename: 'TimeToValueMonth'
  readonly cumulativeContractsBilled: Scalars['SafeInt']['output']
  /** Format is YYYY-MM */
  readonly month: Scalars['String']['output']
}

export { Tutorial }

export type UpdateAgaveIntegrationCredentialInput = {
  readonly companyIntegrationId: Scalars['ID']['input']
  readonly publicToken: Scalars['String']['input']
}

export type UpdateChangeOrderLogFormInput = {
  readonly changeOrderLogFormTemplateId?: InputMaybe<Scalars['ID']['input']>
  readonly contractId: Scalars['ID']['input']
}

export type UpdateChangeOrderRequestFormInput = {
  readonly changeOrderRequestFormTemplateId?: InputMaybe<Scalars['ID']['input']>
  readonly contractId: Scalars['ID']['input']
}

export type UpdateCompanyContactInput = {
  readonly companyName?: InputMaybe<Scalars['String']['input']>
  readonly email?: InputMaybe<Scalars['EmailAddress']['input']>
  readonly fullName?: InputMaybe<Scalars['String']['input']>
  readonly id: Scalars['ID']['input']
  readonly isActive?: InputMaybe<Scalars['Boolean']['input']>
  readonly jobTitle?: InputMaybe<Scalars['String']['input']>
  readonly phoneNumber?: InputMaybe<Scalars['PhoneNumber']['input']>
}

export type UpdateCompanyFormTemplateCollectionInput = {
  readonly billingType?: InputMaybe<BillingType>
  readonly changeOrderLogVariantId?: InputMaybe<Scalars['ID']['input']>
  readonly changeOrderRequestVariantId?: InputMaybe<Scalars['ID']['input']>
  readonly collectionId: Scalars['ID']['input']
  readonly generalContractorCompanyId?: InputMaybe<Scalars['ID']['input']>
  readonly name?: InputMaybe<Scalars['String']['input']>
  readonly payAppTemplateSetId?: InputMaybe<Scalars['ID']['input']>
  readonly primaryLienWaiverTemplateSetId?: InputMaybe<Scalars['ID']['input']>
  readonly state?: InputMaybe<Scalars['String']['input']>
  readonly vendorLienWaiverTemplateSetId?: InputMaybe<Scalars['ID']['input']>
}

export type UpdateCompanyInput = {
  readonly allowLumpSumOverbilling?: InputMaybe<Scalars['Boolean']['input']>
  readonly defaultQuickBillingTemplateId?: InputMaybe<Scalars['ID']['input']>
  readonly defaultShowAuditItemOnSignedPackages?: InputMaybe<Scalars['Boolean']['input']>
  readonly expectedNumberOfProjects?: InputMaybe<Scalars['SafeInt']['input']>
  readonly id: Scalars['ID']['input']
  readonly isDemoCompany?: InputMaybe<Scalars['Boolean']['input']>
  readonly metadata?: InputMaybe<CompanyMetadataInput>
  readonly mfaRequirement?: InputMaybe<CompanyMfaRequirement>
  readonly name?: InputMaybe<Scalars['String']['input']>
  readonly nickname?: InputMaybe<Scalars['String']['input']>
  readonly notarySignature?: InputMaybe<StoredFileInput>
  readonly notaryStamp?: InputMaybe<StoredFileInput>
  readonly notaryTags?: InputMaybe<ReadonlyArray<NotaryTag>>
  readonly phoneNumber?: InputMaybe<Scalars['PhoneNumber']['input']>
  readonly picture?: InputMaybe<StoredFileInput>
  readonly secondarySignature?: InputMaybe<StoredFileInput>
  readonly sitelinePointOfContactId?: InputMaybe<Scalars['ID']['input']>
  readonly type?: InputMaybe<CompanyType>
}

export type UpdateCompanyIntegrationInput = {
  readonly id: Scalars['ID']['input']
  readonly importChangeOrdersMethod: ImportIntegrationChangeOrdersMethod
  readonly importComboJobMethod: ImportIntegrationComboJobMethod
  readonly importProjectNameSource: ImportIntegrationProjectNameSource
  /** Pass null to remove label */
  readonly label?: InputMaybe<Scalars['String']['input']>
  /** Pass null to remove location */
  readonly locationId?: InputMaybe<Scalars['ID']['input']>
  readonly metadata?: InputMaybe<Scalars['JSONObject']['input']>
  readonly revertPayAppsToDraftWhenTexturaInvoiceRejected: Scalars['Boolean']['input']
  readonly shouldImportNewProjectSov: Scalars['Boolean']['input']
}

export type UpdateContractInput = {
  readonly contractDate?: InputMaybe<Scalars['DateTime']['input']>
  readonly contractNumber?: InputMaybe<Scalars['String']['input']>
  readonly contractTemplateVariables?: InputMaybe<Scalars['JSONObject']['input']>
  readonly daysBeforePayAppDue?: InputMaybe<Scalars['Int']['input']>
  readonly id: Scalars['ID']['input']
  readonly internalProjectNumber?: InputMaybe<Scalars['String']['input']>
  readonly pastPayAppCount?: InputMaybe<Scalars['Int']['input']>
  readonly retentionTrackingLevel?: InputMaybe<RetentionTrackingLevel>
  readonly selectedAddress?: InputMaybe<Scalars['ID']['input']>
  readonly status?: InputMaybe<ContractStatus>
  readonly vendorNumber?: InputMaybe<Scalars['String']['input']>
}

export type UpdateContractUsersInput = {
  readonly addUserIds: ReadonlyArray<Scalars['ID']['input']>
  readonly id: Scalars['ID']['input']
  readonly removeUserIds: ReadonlyArray<Scalars['ID']['input']>
}

export type UpdateDefaultChangeOrderLogFormInput = {
  readonly changeOrderLogFormTemplateId?: InputMaybe<Scalars['ID']['input']>
  readonly companyId: Scalars['ID']['input']
  readonly updateContractTemplates: Scalars['Boolean']['input']
}

export type UpdateDefaultChangeOrderRequestFormInput = {
  readonly changeOrderRequestFormTemplateId?: InputMaybe<Scalars['ID']['input']>
  readonly companyId: Scalars['ID']['input']
  readonly updateContractTemplates: Scalars['Boolean']['input']
}

export type UpdateDefaultLienWaiverFormsInput = {
  readonly companyId: Scalars['ID']['input']
  readonly lienWaivers: SubcontractorLienWaiverTemplateSetInput
}

export type UpdateDefaultPayAppFormsInput = {
  readonly billingType: BillingType
  readonly companyId: Scalars['ID']['input']
  readonly payAppRequirementGroups: ReadonlyArray<UpdatePayAppRequirementGroupInput>
}

export type UpdateFormAnnotationInput = {
  readonly copyDefaultValueFromPreviousAnnotationValue?: InputMaybe<Scalars['Boolean']['input']>
  readonly defaultValueKey?: InputMaybe<Scalars['String']['input']>
  readonly doNotRetainOnReset?: InputMaybe<Scalars['Boolean']['input']>
  readonly dynamicFieldTag?: InputMaybe<Scalars['String']['input']>
  readonly fieldType?: InputMaybe<FormTemplateAnnotationMetadataFieldType>
  readonly fontColor?: InputMaybe<Scalars['String']['input']>
  readonly fontFamily?: InputMaybe<FormTemplateFont>
  readonly height?: InputMaybe<Scalars['Float']['input']>
  readonly id: Scalars['ID']['input']
  readonly imageType?: InputMaybe<FormTemplateAnnotationImageType>
  readonly isOptional?: InputMaybe<Scalars['Boolean']['input']>
  /** If the form has more than one page, this is the 1-indexed page the annotation is on */
  readonly pageNumber?: InputMaybe<Scalars['Int']['input']>
  readonly permanentId?: InputMaybe<Scalars['ID']['input']>
  readonly prefix?: InputMaybe<Scalars['String']['input']>
  readonly selectedKey?: InputMaybe<Scalars['String']['input']>
  readonly signatureType?: InputMaybe<SignatureAnnotationType>
  readonly suffix?: InputMaybe<Scalars['String']['input']>
  readonly syncTag?: InputMaybe<Scalars['String']['input']>
  readonly textAlignment?: InputMaybe<TextAlignment>
  readonly type?: InputMaybe<FormTemplateAnnotationType>
  readonly userVisibleName?: InputMaybe<Scalars['String']['input']>
  readonly width?: InputMaybe<Scalars['Float']['input']>
  readonly wrapText?: InputMaybe<Scalars['Boolean']['input']>
  readonly xStart?: InputMaybe<Scalars['Float']['input']>
  readonly yStart?: InputMaybe<Scalars['Float']['input']>
}

export type UpdateFormAnnotationsInput = {
  readonly inputs: ReadonlyArray<UpdateFormAnnotationInput>
}

export type UpdateFormTemplateAssociatedCompanyInput = {
  readonly associatedCompanyId: Scalars['ID']['input']
  readonly id: Scalars['ID']['input']
}

export type UpdateFormTemplateAssociatedContractsInput = {
  readonly contractIds: ReadonlyArray<Scalars['ID']['input']>
  readonly id: Scalars['ID']['input']
}

export type UpdateFormTemplateDueDateInput = {
  /** If null, will clear the due date for this form template */
  readonly dueDate?: InputMaybe<Scalars['DateTime']['input']>
  readonly id: Scalars['ID']['input']
}

export type UpdateFormTemplateInput = {
  readonly id: Scalars['ID']['input']
  readonly type?: InputMaybe<FormTemplateType>
  readonly userVisibleName?: InputMaybe<Scalars['String']['input']>
}

export type UpdateFormTemplateOriginalFileInput = {
  readonly file: Scalars['Upload']['input']
  readonly id: Scalars['ID']['input']
}

export type UpdateFormTemplateRequestingCompanyInput = {
  readonly id: Scalars['ID']['input']
  readonly requestingCompanyId: Scalars['ID']['input']
}

export type UpdateFormTemplateStatusInput = {
  readonly id: Scalars['ID']['input']
  readonly status: FormTemplateStatus
}

export type UpdateFormTemplateUserInput = {
  readonly id: Scalars['ID']['input']
  readonly userId: Scalars['ID']['input']
}

export type UpdateFormTemplateVersionInput = {
  readonly file?: InputMaybe<Scalars['Upload']['input']>
  readonly id: Scalars['ID']['input']
  readonly isReadyForManualStoredMaterials?: InputMaybe<Scalars['Boolean']['input']>
}

export type UpdateIntegrationCredentialInput = {
  readonly companyIntegrationId: Scalars['ID']['input']
  readonly hh2Username?: InputMaybe<Scalars['String']['input']>
  readonly password: Scalars['String']['input']
  readonly username: Scalars['String']['input']
}

export type UpdateIntegrationMappingsInput = {
  readonly integrationId: Scalars['ID']['input']
  readonly mappings: Scalars['JSONObject']['input']
}

export type UpdateLegalRequirementInput = {
  readonly endDate?: InputMaybe<Scalars['DateTime']['input']>
  readonly expirationFrequency: DocumentExpirationFrequency
  readonly formTemplateId?: InputMaybe<Scalars['ID']['input']>
  readonly id?: InputMaybe<Scalars['ID']['input']>
  readonly name: Scalars['String']['input']
  readonly startDate?: InputMaybe<Scalars['DateTime']['input']>
}

export type UpdateLegalRequirementsInput = {
  readonly contractId: Scalars['ID']['input']
  readonly requirements: ReadonlyArray<UpdateLegalRequirementInput>
}

export type UpdateLienWaiverFormTemplateSetInput = {
  readonly generalContractorCompanyId?: InputMaybe<Scalars['ID']['input']>
  readonly lienWaivers: SubcontractorLienWaiverTemplateSetInput
  readonly name?: InputMaybe<Scalars['String']['input']>
  readonly state?: InputMaybe<Scalars['String']['input']>
  readonly templateSetId: Scalars['ID']['input']
}

export type UpdateLocationInput = {
  readonly id: Scalars['ID']['input']
  readonly location: LocationInput
}

export type UpdateOnboardedProjectContractStatusInput = {
  readonly addedGcContacts?: InputMaybe<Scalars['Boolean']['input']>
  readonly addedSov?: InputMaybe<Scalars['Boolean']['input']>
  readonly addedTeammates?: InputMaybe<Scalars['Boolean']['input']>
  readonly contractId: Scalars['ID']['input']
  readonly notifiedOnboardedForms?: InputMaybe<Scalars['Boolean']['input']>
  readonly onboardedChangeOrderRequestForms?: InputMaybe<Scalars['Boolean']['input']>
  readonly onboardedPayAppForms?: InputMaybe<Scalars['Boolean']['input']>
  readonly onboardedPrimaryLienWaiverForms?: InputMaybe<Scalars['Boolean']['input']>
  readonly onboardedVendorLienWaiverForms?: InputMaybe<Scalars['Boolean']['input']>
  readonly selectedChangeOrderRequestForms?: InputMaybe<Scalars['Boolean']['input']>
  readonly selectedPayAppForms?: InputMaybe<Scalars['Boolean']['input']>
  readonly selectedPrimaryLienWaivers?: InputMaybe<Scalars['Boolean']['input']>
  readonly selectedRateTable?: InputMaybe<Scalars['Boolean']['input']>
  readonly selectedVendorLienWaivers?: InputMaybe<Scalars['Boolean']['input']>
  readonly startedBilling?: InputMaybe<Scalars['Boolean']['input']>
}

export type UpdatePayAppFormTemplateSetInput = {
  readonly billingType?: InputMaybe<BillingType>
  readonly generalContractorCompanyId?: InputMaybe<Scalars['ID']['input']>
  readonly name?: InputMaybe<Scalars['String']['input']>
  readonly requirementGroups: ReadonlyArray<UpdatePayAppRequirementGroupInput>
  readonly state?: InputMaybe<Scalars['String']['input']>
  readonly templateSetId: Scalars['ID']['input']
}

export type UpdatePayAppInput = {
  readonly id: Scalars['ID']['input']
  readonly retentionOnly?: InputMaybe<Scalars['Boolean']['input']>
}

export type UpdatePayAppRequirementGroupInput = {
  readonly id?: InputMaybe<Scalars['ID']['input']>
  readonly order: Scalars['Int']['input']
  readonly requirements: ReadonlyArray<UpdatePayAppRequirementInput>
}

export type UpdatePayAppRequirementGroupsInput = {
  readonly contractId: Scalars['ID']['input']
  readonly requirementGroups: ReadonlyArray<UpdatePayAppRequirementGroupInput>
}

export type UpdatePayAppRequirementInput = {
  readonly conditions: ReadonlyArray<PayAppRequirementCondition>
  readonly groupOrder: Scalars['Int']['input']
  readonly id?: InputMaybe<Scalars['ID']['input']>
  readonly templateVariantId?: InputMaybe<Scalars['ID']['input']>
}

export type UpdateProjectInput = {
  readonly architect?: InputMaybe<CompanyProjectMetadataInput>
  readonly generalContractor?: InputMaybe<CompanyProjectMetadataInput>
  readonly id: Scalars['ID']['input']
  readonly location?: InputMaybe<LocationInput>
  readonly metadata?: InputMaybe<ProjectMetadataInput>
  readonly name?: InputMaybe<Scalars['String']['input']>
  readonly owner?: InputMaybe<CompanyProjectMetadataInput>
  readonly projectNumber?: InputMaybe<Scalars['String']['input']>
  readonly removeArchitect?: InputMaybe<Scalars['Boolean']['input']>
}

export type UpdateRoundRetentionInput = {
  readonly contractId: Scalars['ID']['input']
  readonly roundRetention: Scalars['Boolean']['input']
}

export type UpdateSovLineItemGroupInput = {
  readonly groupCode?: InputMaybe<Scalars['String']['input']>
  readonly groupName?: InputMaybe<Scalars['String']['input']>
  readonly id: Scalars['String']['input']
}

export type UpdateTemplateVariantInput = {
  readonly hidesZeroDollarAmounts?: InputMaybe<Scalars['Boolean']['input']>
  readonly id: Scalars['ID']['input']
  readonly internalName?: InputMaybe<Scalars['String']['input']>
  readonly roundPercentages?: InputMaybe<Scalars['Boolean']['input']>
  readonly useCompanyNotarySignatureIfAvailable?: InputMaybe<Scalars['Boolean']['input']>
  readonly userVisibleName?: InputMaybe<Scalars['String']['input']>
}

export type UpdateUserEducationCampaignInput = {
  readonly id: Scalars['ID']['input']
  readonly info: CreateUserEducationCampaignInput
}

/** Represents a single person that works at a single company. */
export type User = {
  readonly __typename: 'User'
  /** A list of email notifications the user has unsubscribed from. */
  readonly blockedEmails: ReadonlyArray<EmailType>
  /** A list of notifications the user has unsubscribed from. */
  readonly blockedNotifications: ReadonlyArray<NotificationType>
  /**
   * User education campaigns are shown once per user and should be added to this array once shown so
   * they aren't triggered again. Unlike `tutorialsShown`, campaigns are dynamically created in admin and
   * are referenced by ID.
   */
  readonly campaignsShown: ReadonlyArray<UserEducationCampaign>
  readonly companies: ReadonlyArray<Company>
  readonly companyUsers: ReadonlyArray<CompanyUser>
  readonly createdAt: Scalars['DateTime']['output']
  /**
   * When the first signature is used/uploaded, the default signature will be set to that, and then
   * every time a new signature is used or uploaded, it'll override the default.
   * Therefore, this field is essentially just the last used signature.
   */
  readonly defaultSignature: Maybe<Signature>
  readonly email: Scalars['EmailAddress']['output']
  readonly firstName: Scalars['String']['output']
  readonly fullName: Scalars['String']['output']
  readonly id: Scalars['ID']['output']
  /** Whether this user is a Siteline team member with admin dashboard access */
  readonly isSitelineTeamMember: Scalars['Boolean']['output']
  readonly jobTitle: Maybe<Scalars['String']['output']>
  readonly lastName: Scalars['String']['output']
  readonly phoneNumber: Maybe<Scalars['PhoneNumber']['output']>
  readonly policiesAcceptedAt: Maybe<Scalars['DateTime']['output']>
  readonly starredProjects: ReadonlyArray<Project>
  /**
   * Educational tooltips and tutorials that are shown only once per user should be added
   * to this array once seen or dismissed to track that they shouldn't be shown again
   */
  readonly tutorialsShown: ReadonlyArray<Tutorial>
  readonly updatedAt: Scalars['DateTime']['output']
}

/** A user education campaign represents a one-time popup in the app announcing a change to the user */
export type UserEducationCampaign = {
  readonly __typename: 'UserEducationCampaign'
  /** If undefined, will not show any button on the popup */
  readonly buttonLabel: Maybe<Scalars['String']['output']>
  /** If `buttonLabel` is defined but not `buttonLink`, the button will dismiss the popup */
  readonly buttonLink: Maybe<Scalars['String']['output']>
  /** The Siteline user that created this campaign */
  readonly createdBy: User
  /** The campaign will run only up to the end date */
  readonly endDate: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  readonly message: Scalars['String']['output']
  /** An internal name for the campaign for tracking, not to be exposed to the user */
  readonly name: Scalars['String']['output']
  /** If true, a 'NEW' tag is shown at the top of the popup */
  readonly showNewTag: Scalars['Boolean']['output']
  /** The campaign will only be shown to users after the start date */
  readonly startDate: Scalars['DateTime']['output']
  /** If defined, the campaign will only be shown to a user with all given permissions */
  readonly targetPermissions: ReadonlyArray<Permission>
  readonly title: Scalars['String']['output']
}

export type UserInput = {
  readonly email: Scalars['String']['input']
  readonly firstName: Scalars['String']['input']
  readonly lastName: Scalars['String']['input']
}

export { UserStatus }

export type Vendor = {
  readonly __typename: 'Vendor'
  readonly address: Maybe<Location>
  readonly company: Company
  /** The company integrations this vendor is linked to */
  readonly companyIntegrations: ReadonlyArray<CompanyIntegration>
  readonly complianceStatus: VendorComplianceStatus
  /** The sum of amounts on all conditional lien waivers requested for this vendor */
  readonly conditionalAmountWaived: Scalars['SafeInt']['output']
  readonly contacts: ReadonlyArray<VendorContact>
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  /** Mappings of Siteline entities to Integration entities. Fields depend on the integration type. */
  readonly integrationMappings: Scalars['JSONObject']['output']
  /** Internal notes and reminders about the vendor, added by users internally */
  readonly internalNotes: Maybe<Scalars['String']['output']>
  readonly name: Scalars['String']['output']
  readonly numContracts: Scalars['Int']['output']
  readonly phoneNumber: Maybe<Scalars['PhoneNumber']['output']>
  /** The sum of amounts on all unconditional lien waivers requested for this vendor */
  readonly unconditionalAmountWaived: Scalars['SafeInt']['output']
  readonly updatedAt: Scalars['DateTime']['output']
  readonly vendorContracts: ReadonlyArray<VendorContract>
}

export { VendorComplianceStatus }

/** Represents a contact at a vendor or lower-tier contractor who receives lien waiver or document requests */
export type VendorContact = {
  readonly __typename: 'VendorContact'
  /** An archived vendor contact should not be surfaced to the user, except relating to past document requests */
  readonly archivedAt: Maybe<Scalars['DateTime']['output']>
  readonly createdAt: Scalars['DateTime']['output']
  /**
   * When the first signature is used/uploaded, the default signature will be set to that, and then
   * every time a new signature is used or uploaded, it'll override the default.
   * Therefore, this field is essentially just the last used signature.
   */
  readonly defaultSignature: Maybe<Signature>
  readonly email: Scalars['EmailAddress']['output']
  readonly fullName: Scalars['String']['output']
  readonly id: Scalars['ID']['output']
  readonly jobTitle: Maybe<Scalars['String']['output']>
  /** When a contact is used on a sub-tier lien waiver, it shouldn't overwrite primary vendor contacts */
  readonly lowerTierTo: Maybe<Vendor>
  /**
   * Whether this vendor contact has opted out of digital signatures.
   * If yes, lien waiver requests must be sent manually, and subcontractors will upload lien waivers directly.
   */
  readonly optedOutOfDigitalSignaturesAt: Maybe<Scalars['DateTime']['output']>
  readonly phoneNumber: Maybe<Scalars['PhoneNumber']['output']>
  readonly updatedAt: Scalars['DateTime']['output']
  readonly vendor: Vendor
}

/** Contract that tracks all the legal requirements that a vendor needs to provide. */
export type VendorContract = {
  readonly __typename: 'VendorContract'
  readonly commitments: ReadonlyArray<VendorContractCommitment>
  /** The sum of amounts on all conditional lien waivers requested for this vendor and contract */
  readonly conditionalAmountWaived: Scalars['SafeInt']['output']
  readonly contract: Contract
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  /**
   * If true, we will automatically create a `PayAppSwornStatementVendor` for every new pay app on this
   * contract. Flipping to true will also create a `PayAppSwornStatementVendor` for every draft pay app
   * on this contract. Flipping to false will remove the corresponding `PayAppSwornStatementVendor`
   * from every draft pay app on this contract. Defaults to false.
   */
  readonly includeInSwornStatements: Scalars['Boolean']['output']
  readonly legalDocumentRequests: ReadonlyArray<LegalDocumentRequest>
  readonly lienWaivers: ReadonlyArray<LienWaiver>
  /**
   * If this is a lower-lower-tier vendor, set to the `VendorContract` for the vendor who contracted them.
   * This `VendorContract` must be for another vendor on this project.
   */
  readonly lowerTierTo: Maybe<VendorContract>
  readonly payAppSwornStatements: ReadonlyArray<PayAppSwornStatementVendor>
  readonly preliminaryNotices: ReadonlyArray<VendorPreliminaryNotice>
  /** The sum of amounts on all unconditional lien waivers requested for this vendor and contract */
  readonly unconditionalAmountWaived: Scalars['SafeInt']['output']
  readonly updatedAt: Scalars['DateTime']['output']
  readonly vendor: Vendor
  readonly vendorLegalRequirements: ReadonlyArray<VendorLegalRequirement>
}

export type VendorContractCommitment = {
  readonly __typename: 'VendorContractCommitment'
  readonly amount: Scalars['SafeInt']['output']
  readonly closedAt: Maybe<Scalars['DateTime']['output']>
  readonly commitmentType: VendorContractCommitmentType
  readonly createdAt: Scalars['DateTime']['output']
  readonly date: Scalars['DateTime']['output']
  readonly file: Maybe<StoredFile>
  readonly id: Scalars['ID']['output']
  readonly number: Scalars['String']['output']
  readonly payWhenPaid: Scalars['Boolean']['output']
  readonly paymentTerms: Maybe<Scalars['SafeInt']['output']>
  readonly updatedAt: Scalars['DateTime']['output']
  readonly vendorContract: VendorContract
}

export { VendorContractCommitmentType }

/** DEPRECATED */
export type VendorContractsByProject = {
  readonly __typename: 'VendorContractsByProject'
  readonly internalProjectNumber: Maybe<Scalars['String']['output']>
  readonly month: Scalars['SafeInt']['output']
  readonly projectId: Scalars['ID']['output']
  readonly projectName: Scalars['String']['output']
  readonly projectNumber: Scalars['String']['output']
  /** Checks if this month was skipped for the given project */
  readonly skippedMonth: Scalars['Boolean']['output']
  readonly timeZone: Scalars['String']['output']
  readonly vendorContractLienWaivers: ReadonlyArray<LienWaiversByVendorContract>
  readonly year: Scalars['SafeInt']['output']
}

/** DEPRECATED */
export type VendorContractsByProjectSummary = {
  readonly __typename: 'VendorContractsByProjectSummary'
  readonly aggregateConditionalStatus: AggregateLienWaiverStatus
  readonly aggregateUnconditionalStatus: AggregateLienWaiverStatus
  readonly internalProjectNumber: Maybe<Scalars['String']['output']>
  readonly month: Scalars['SafeInt']['output']
  /** Total number of condtional lien waivers */
  readonly numConditionals: Scalars['Int']['output']
  /** The number of conditional lien waivers that have not yet been signed */
  readonly numPendingConditionals: Scalars['Int']['output']
  /** The number of unconditional lien waivers that have not yet been signed */
  readonly numPendingUnconditionals: Scalars['Int']['output']
  /** Total number of uncondtional lien waivers */
  readonly numUnconditionals: Scalars['Int']['output']
  readonly projectId: Scalars['ID']['output']
  readonly projectName: Scalars['String']['output']
  readonly projectNumber: Scalars['String']['output']
  /** Checks if this month was skipped for the given project */
  readonly skippedMonth: Scalars['Boolean']['output']
  readonly timeZone: Scalars['String']['output']
  readonly year: Scalars['SafeInt']['output']
}

export type VendorDocumentsByProject = {
  readonly __typename: 'VendorDocumentsByProject'
  readonly periodStart: Maybe<Scalars['DateTime']['output']>
  readonly project: Project
  /** List of documents for different vendors of a project for a specific period. */
  readonly vendorDocuments: ReadonlyArray<VendorLegalDocument>
}

/** A single vendor to be asynchronously imported from an integration */
export type VendorImportOperation = {
  readonly __typename: 'VendorImportOperation'
  readonly company: Company
  readonly createdAt: Scalars['DateTime']['output']
  readonly createdBy: User
  readonly error: Maybe<Scalars['String']['output']>
  readonly group: VendorImportOperationGroup
  readonly groupId: Scalars['ID']['output']
  readonly id: Scalars['ID']['output']
  readonly payload: Scalars['JSONObject']['output']
  readonly status: ImportOperationStatus
  readonly updatedAt: Scalars['DateTime']['output']
  /** Set if the import was successful */
  readonly vendor: Maybe<Vendor>
}

/** Contains some stats about a particular import group. Computed for each VendorImportOperation. */
export type VendorImportOperationGroup = {
  readonly __typename: 'VendorImportOperationGroup'
  readonly numFailed: Scalars['SafeInt']['output']
  readonly numSuccessful: Scalars['SafeInt']['output']
  readonly status: ImportOperationStatus
  readonly totalOperations: Scalars['SafeInt']['output']
}

/**
 * A single import operation for multiple vendor (AP) invoices.
 * Invoices can be imported from an ERP, or directly from Excel (see CreateVendorInvoiceImportOperationInput).
 * When importing from an ERP, the operation is processed by Cloud Tasks to account for long import times.
 * Imported invoices are available in `operation.invoices`.
 */
export type VendorInvoiceImportOperation = {
  readonly __typename: 'VendorInvoiceImportOperation'
  readonly company: Company
  /** Might not be provided if importing from Excel */
  readonly companyIntegration: Maybe<CompanyIntegration>
  /** Provide to narrow down by contract. Previously-cached contract invoices will be deleted */
  readonly contract: Maybe<Contract>
  readonly createdAt: Scalars['DateTime']['output']
  readonly createdBy: User
  /** Format is YYYY-MM-DD */
  readonly endDate: Scalars['String']['output']
  readonly errors: ReadonlyArray<Scalars['String']['output']>
  readonly id: Scalars['ID']['output']
  readonly invoiceCount: Scalars['Int']['output']
  readonly invoices: ReadonlyArray<ImportedVendorInvoice>
  /** Format is YYYY-MM-DD */
  readonly startDate: Scalars['String']['output']
  readonly status: ImportOperationStatus
  readonly updatedAt: Scalars['DateTime']['output']
}

export type VendorInvoiceImportStatus = {
  readonly __typename: 'VendorInvoiceImportStatus'
  /** In case there's an operation in progress */
  readonly activeOperation: Maybe<VendorInvoiceImportOperation>
  /** If a previous operation has completed, it is available here */
  readonly lastOperation: Maybe<VendorInvoiceImportOperation>
}

export type VendorLegalDocument = {
  readonly __typename: 'VendorLegalDocument'
  readonly document: Maybe<LegalDocument>
  readonly isSkippedPeriod: Scalars['Boolean']['output']
  readonly vendorRequirement: VendorLegalRequirement
}

/** Metadata about a legal requirement that is for a vendor. */
export type VendorLegalRequirement = {
  readonly __typename: 'VendorLegalRequirement'
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  readonly legalRequirement: LegalRequirement
  readonly skippedPeriods: ReadonlyArray<Scalars['String']['output']>
  readonly updatedAt: Scalars['DateTime']['output']
  readonly vendorContract: VendorContract
}

export type VendorLienWaiverDashboardSummary = {
  readonly __typename: 'VendorLienWaiverDashboardSummary'
  /** If empty, the company has never requested a lien waiver */
  readonly numOutstandingLienWaiversThisMonth: Maybe<Scalars['SafeInt']['output']>
  readonly outstandingLienWaiversByMonth: ReadonlyArray<OutstandingLienWaiversByMonth>
  readonly outstandingVendors: Scalars['SafeInt']['output']
}

export { VendorLienWaiverReminderFrequency }

/** DEPRECATED */
export type VendorLienWaiversByMonth = {
  readonly __typename: 'VendorLienWaiversByMonth'
  readonly lienWaiversByMonth: ReadonlyArray<LienWaiversByMonth>
  readonly vendor: Vendor
}

export { VendorListSortCriteria }

/** A preliminary notice for a vendor, filed on a certain project */
export type VendorPreliminaryNotice = {
  readonly __typename: 'VendorPreliminaryNotice'
  readonly createdAt: Scalars['DateTime']['output']
  /** The notice date or date filed for the preliminary notice */
  readonly date: Scalars['DateTime']['output']
  readonly file: StoredFile
  readonly id: Scalars['ID']['output']
  readonly updatedAt: Scalars['DateTime']['output']
  readonly vendorContract: VendorContract
}

export type WorksheetLineItem = {
  readonly __typename: 'WorksheetLineItem'
  readonly billedToDate: Scalars['SafeInt']['output']
  readonly code: Scalars['String']['output']
  readonly costCode: Maybe<Scalars['String']['output']>
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  readonly name: Scalars['String']['output']
  readonly previousBilled: Scalars['SafeInt']['output']
  readonly progress: ReadonlyArray<WorksheetLineItemProgress>
  /**
   * This is the order of the worksheet line item relative to other worksheet items on the same SOV
   * line item. Within a single SOV, there may be multiple worksheet line items with the same sort
   * order (e.g. there will likely be many worksheet line items with sort order 1), but every worksheet
   * item corresponding to the same SOV line item should have a unique sort order.
   */
  readonly sortOrder: Scalars['SafeInt']['output']
  readonly sovLineItem: SovLineItem
  readonly totalValue: Scalars['SafeInt']['output']
  readonly unitName: Maybe<Scalars['String']['output']>
  /** Unit price and name are inferred from the SOV line item, and cannot diverge */
  readonly unitPrice: Maybe<Scalars['Decimal']['output']>
  readonly updatedAt: Scalars['DateTime']['output']
}

export type WorksheetLineItemProgress = {
  readonly __typename: 'WorksheetLineItemProgress'
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  readonly payApp: PayApp
  readonly previousBilled: Scalars['SafeInt']['output']
  readonly progressBilled: Scalars['SafeInt']['output']
  readonly totalValue: Scalars['SafeInt']['output']
  readonly updatedAt: Scalars['DateTime']['output']
  readonly updatedBy: Maybe<User>
  readonly worksheetLineItem: WorksheetLineItem
}

export type WriteSyncOperation = {
  readonly __typename: 'WriteSyncOperation'
  readonly createdAt: Scalars['DateTime']['output']
  readonly createdBy: User
  readonly id: Scalars['ID']['output']
  readonly integration: Integration
  readonly legalDocuments: ReadonlyArray<LegalDocument>
  readonly legalRequirement: Maybe<LegalRequirement>
  readonly lienWaivers: ReadonlyArray<LienWaiver>
  readonly payApp: Maybe<PayApp>
  readonly payload: Scalars['JSONObject']['output']
  readonly result: Maybe<Scalars['JSONObject']['output']>
  readonly status: WriteSyncOperationStatus
  readonly updatedAt: Scalars['DateTime']['output']
}

export { WriteSyncOperationStatus }

export type WriteSyncOperationsSort = {
  readonly criteria: WriteSyncOperationsSortCriteria
  readonly order: PaginatedListSortOrder
}

export { WriteSyncOperationsSortCriteria }

export type CompaniesForAutocompleteQueryVariables = Exact<{
  input: GetPaginatedCompaniesInput
}>

export type CompaniesForAutocompleteQuery = {
  readonly __typename: 'Query'
  readonly paginatedCompanies: {
    readonly __typename: 'PaginatedCompanyList'
    readonly companies: ReadonlyArray<{
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
    }>
  }
}

export type CompanyForAutocompleteQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type CompanyForAutocompleteQuery = {
  readonly __typename: 'Query'
  readonly company: {
    readonly __typename: 'Company'
    readonly id: ID['output']
    readonly name: String['output']
  }
}

export type UpdateCompanyMutationVariables = Exact<{
  input: UpdateCompanyInput
}>

export type UpdateCompanyMutation = {
  readonly __typename: 'Mutation'
  readonly updateCompany: {
    readonly __typename: 'Company'
    readonly id: ID['output']
    readonly name: String['output']
    readonly nickname: String['output'] | null
    readonly type: CompanyType
    readonly phoneNumber: PhoneNumber['output'] | null
    readonly expectedNumberOfProjects: SafeInt['output'] | null
    readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
    readonly allowLumpSumOverbilling: Boolean['output']
    readonly notaryTags: ReadonlyArray<NotaryTag>
    readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
    readonly isDemoCompany: Boolean['output']
    readonly picture: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
    } | null
    readonly notaryStamp: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
    } | null
    readonly notarySignature: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
    } | null
    readonly secondarySignature: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
    } | null
    readonly locations: ReadonlyArray<{
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    }>
    readonly metadata: {
      readonly __typename: 'CompanyMetadata'
      readonly subtrade: String['output'] | null
      readonly domains: ReadonlyArray<String['output']>
      readonly domainSignup: Boolean['output']
      readonly monthlyCreatePayAppReminderDate: Int['output'] | null
    }
    readonly defaultQuickBillingTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
    } | null
    readonly sitelinePointOfContact: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
    } | null
  }
}

export type DeleteCompanyMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteCompanyMutation = {
  readonly __typename: 'Mutation'
  readonly deleteCompany: { readonly __typename: 'DeletionResult'; readonly id: ID['output'] }
}

export type GetCompanyQueryVariables = Exact<{
  companyId: Scalars['ID']['input']
}>

export type GetCompanyQuery = {
  readonly __typename: 'Query'
  readonly company: {
    readonly __typename: 'Company'
    readonly id: ID['output']
    readonly name: String['output']
    readonly nickname: String['output'] | null
    readonly type: CompanyType
    readonly phoneNumber: PhoneNumber['output'] | null
    readonly expectedNumberOfProjects: SafeInt['output'] | null
    readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
    readonly allowLumpSumOverbilling: Boolean['output']
    readonly notaryTags: ReadonlyArray<NotaryTag>
    readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
    readonly isDemoCompany: Boolean['output']
    readonly picture: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
    } | null
    readonly notaryStamp: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
    } | null
    readonly notarySignature: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
    } | null
    readonly secondarySignature: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
    } | null
    readonly locations: ReadonlyArray<{
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    }>
    readonly metadata: {
      readonly __typename: 'CompanyMetadata'
      readonly subtrade: String['output'] | null
      readonly domains: ReadonlyArray<String['output']>
      readonly domainSignup: Boolean['output']
      readonly monthlyCreatePayAppReminderDate: Int['output'] | null
    }
    readonly defaultQuickBillingTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
    } | null
    readonly sitelinePointOfContact: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
    } | null
  }
}

export type PayAppForAutocompleteQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type PayAppForAutocompleteQuery = {
  readonly __typename: 'Query'
  readonly payApp: {
    readonly __typename: 'PayApp'
    readonly id: ID['output']
    readonly billingEnd: DateTime['output']
    readonly timeZone: String['output']
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly name: String['output']
      }
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      }
    }
  }
}

export type TemplatesForAutocompleteQueryVariables = Exact<{
  input: GetPaginatedTemplatesInput
}>

export type TemplatesForAutocompleteQuery = {
  readonly __typename: 'Query'
  readonly paginatedTemplates: {
    readonly __typename: 'PaginatedTemplateList'
    readonly templates: ReadonlyArray<{
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly createdAt: DateTime['output']
      readonly type: FormTemplateType
      readonly userVisibleName: String['output']
      readonly status: FormTemplateStatus
      readonly skippedValidation: Boolean['output']
      readonly variants: ReadonlyArray<{
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly internalName: String['output']
        readonly isDefaultVariant: Boolean['output']
      }>
    }>
  }
}

export type TemplateForAutocompleteQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type TemplateForAutocompleteQuery = {
  readonly __typename: 'Query'
  readonly formTemplate: {
    readonly __typename: 'FormTemplate'
    readonly id: String['output']
    readonly createdAt: DateTime['output']
    readonly userVisibleName: String['output']
    readonly type: FormTemplateType
    readonly status: FormTemplateStatus
    readonly skippedValidation: Boolean['output']
    readonly variants: ReadonlyArray<{
      readonly __typename: 'FormTemplateVariant'
      readonly id: ID['output']
      readonly internalName: String['output']
      readonly isDefaultVariant: Boolean['output']
    }>
  }
}

export type SearchQueryVariables = Exact<{
  query: Scalars['String']['input']
  type?: InputMaybe<SearchResultType>
}>

export type SearchQuery = {
  readonly __typename: 'Query'
  readonly search: ReadonlyArray<{
    readonly __typename: 'SearchResult'
    readonly id: String['output']
    readonly type: SearchResultType
    readonly description: String['output']
    readonly score: Float['output']
  }>
}

export type CreateTestTemplateMutationVariables = Exact<{
  input: CreateTestTemplateInput
}>

export type CreateTestTemplateMutation = {
  readonly __typename: 'Mutation'
  readonly createTestTemplate: {
    readonly __typename: 'FormTemplate'
    readonly id: String['output']
    readonly versions: ReadonlyArray<{
      readonly __typename: 'FormTemplateVersion'
      readonly id: ID['output']
    }>
  }
}

export type TemplateVariantForAutocompleteQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type TemplateVariantForAutocompleteQuery = {
  readonly __typename: 'Query'
  readonly formTemplateVariant: {
    readonly __typename: 'FormTemplateVariant'
    readonly id: ID['output']
    readonly userVisibleName: String['output'] | null
    readonly internalName: String['output']
    readonly isDefaultVariant: Boolean['output']
    readonly hidesZeroDollarAmounts: Boolean['output'] | null
    readonly roundPercentages: Boolean['output'] | null
    readonly useCompanyNotarySignatureIfAvailable: Boolean['output'] | null
    readonly template: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly createdAt: DateTime['output']
      readonly type: FormTemplateType
      readonly userVisibleName: String['output']
      readonly status: FormTemplateStatus
      readonly skippedValidation: Boolean['output']
      readonly versions: ReadonlyArray<{
        readonly __typename: 'FormTemplateVersion'
        readonly id: ID['output']
        readonly versionNumber: Int['output']
        readonly isReadyForManualStoredMaterials: Boolean['output']
      }>
    }
  }
}

export type LocationProperties = {
  readonly __typename: 'Location'
  readonly id: ID['output']
  readonly createdAt: DateTime['output']
  readonly nickname: String['output'] | null
  readonly street1: String['output'] | null
  readonly street2: String['output'] | null
  readonly city: String['output']
  readonly county: String['output'] | null
  readonly state: String['output']
  readonly country: String['output']
  readonly postalCode: String['output'] | null
  readonly timeZone: String['output']
  readonly coordinates: LatLng['output']
}

export type CompanyProperties = {
  readonly __typename: 'Company'
  readonly id: ID['output']
  readonly name: String['output']
  readonly nickname: String['output'] | null
  readonly type: CompanyType
  readonly phoneNumber: PhoneNumber['output'] | null
  readonly expectedNumberOfProjects: SafeInt['output'] | null
  readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
  readonly allowLumpSumOverbilling: Boolean['output']
  readonly notaryTags: ReadonlyArray<NotaryTag>
  readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
  readonly isDemoCompany: Boolean['output']
  readonly picture: {
    readonly __typename: 'StoredFile'
    readonly id: ID['output']
    readonly url: URL['output']
  } | null
  readonly notaryStamp: {
    readonly __typename: 'StoredFile'
    readonly id: ID['output']
    readonly url: URL['output']
  } | null
  readonly notarySignature: {
    readonly __typename: 'StoredFile'
    readonly id: ID['output']
    readonly url: URL['output']
  } | null
  readonly secondarySignature: {
    readonly __typename: 'StoredFile'
    readonly id: ID['output']
    readonly url: URL['output']
  } | null
  readonly locations: ReadonlyArray<{
    readonly __typename: 'Location'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly nickname: String['output'] | null
    readonly street1: String['output'] | null
    readonly street2: String['output'] | null
    readonly city: String['output']
    readonly county: String['output'] | null
    readonly state: String['output']
    readonly country: String['output']
    readonly postalCode: String['output'] | null
    readonly timeZone: String['output']
    readonly coordinates: LatLng['output']
  }>
  readonly metadata: {
    readonly __typename: 'CompanyMetadata'
    readonly subtrade: String['output'] | null
    readonly domains: ReadonlyArray<String['output']>
    readonly domainSignup: Boolean['output']
    readonly monthlyCreatePayAppReminderDate: Int['output'] | null
  }
  readonly defaultQuickBillingTemplate: {
    readonly __typename: 'FormTemplate'
    readonly id: String['output']
  } | null
  readonly sitelinePointOfContact: {
    readonly __typename: 'User'
    readonly id: ID['output']
    readonly email: EmailAddress['output']
  } | null
}

export type CompanyIntegrationProperties = {
  readonly __typename: 'CompanyIntegration'
  readonly id: ID['output']
  readonly createdAt: DateTime['output']
  readonly type: IntegrationType
  readonly label: String['output'] | null
  readonly shortName: String['output']
  readonly longName: String['output']
  readonly credentialsUpdatedAt: DateTime['output'] | null
  readonly credentialsReadyForAutoRotation: Boolean['output']
  readonly archivedAt: DateTime['output'] | null
  readonly shouldImportNewProjectSov: Boolean['output']
  readonly metadata: JSONObject['output']
  readonly importChangeOrdersMethod: ImportIntegrationChangeOrdersMethod
  readonly importComboJobMethod: ImportIntegrationComboJobMethod
  readonly importProjectNameSource: ImportIntegrationProjectNameSource
  readonly revertPayAppsToDraftWhenTexturaInvoiceRejected: Boolean['output']
  readonly location: {
    readonly __typename: 'Location'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly nickname: String['output'] | null
    readonly street1: String['output'] | null
    readonly street2: String['output'] | null
    readonly city: String['output']
    readonly county: String['output'] | null
    readonly state: String['output']
    readonly country: String['output']
    readonly postalCode: String['output'] | null
    readonly timeZone: String['output']
    readonly coordinates: LatLng['output']
  } | null
}

export type MinimalUserProperties = {
  readonly __typename: 'User'
  readonly id: ID['output']
  readonly firstName: String['output']
  readonly lastName: String['output']
  readonly email: EmailAddress['output']
  readonly jobTitle: String['output'] | null
  readonly phoneNumber: PhoneNumber['output'] | null
  readonly companies: ReadonlyArray<{
    readonly __typename: 'Company'
    readonly id: ID['output']
    readonly name: String['output']
  }>
}

export type UserProperties = {
  readonly __typename: 'User'
  readonly blockedEmails: ReadonlyArray<EmailType>
  readonly blockedNotifications: ReadonlyArray<NotificationType>
  readonly policiesAcceptedAt: DateTime['output'] | null
  readonly id: ID['output']
  readonly firstName: String['output']
  readonly lastName: String['output']
  readonly email: EmailAddress['output']
  readonly jobTitle: String['output'] | null
  readonly phoneNumber: PhoneNumber['output'] | null
  readonly companies: ReadonlyArray<{
    readonly __typename: 'Company'
    readonly id: ID['output']
    readonly name: String['output']
    readonly nickname: String['output'] | null
    readonly type: CompanyType
    readonly phoneNumber: PhoneNumber['output'] | null
    readonly expectedNumberOfProjects: SafeInt['output'] | null
    readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
    readonly allowLumpSumOverbilling: Boolean['output']
    readonly notaryTags: ReadonlyArray<NotaryTag>
    readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
    readonly isDemoCompany: Boolean['output']
    readonly picture: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
    } | null
    readonly notaryStamp: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
    } | null
    readonly notarySignature: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
    } | null
    readonly secondarySignature: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
    } | null
    readonly locations: ReadonlyArray<{
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    }>
    readonly metadata: {
      readonly __typename: 'CompanyMetadata'
      readonly subtrade: String['output'] | null
      readonly domains: ReadonlyArray<String['output']>
      readonly domainSignup: Boolean['output']
      readonly monthlyCreatePayAppReminderDate: Int['output'] | null
    }
    readonly defaultQuickBillingTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
    } | null
    readonly sitelinePointOfContact: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
    } | null
  }>
  readonly companyUsers: ReadonlyArray<{
    readonly __typename: 'CompanyUser'
    readonly id: ID['output']
    readonly status: UserStatus
    readonly role: CompanyUserRole
    readonly company: {
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
      readonly nickname: String['output'] | null
      readonly type: CompanyType
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly expectedNumberOfProjects: SafeInt['output'] | null
      readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
      readonly allowLumpSumOverbilling: Boolean['output']
      readonly notaryTags: ReadonlyArray<NotaryTag>
      readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
      readonly isDemoCompany: Boolean['output']
      readonly picture: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly notaryStamp: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly notarySignature: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly secondarySignature: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly locations: ReadonlyArray<{
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }>
      readonly metadata: {
        readonly __typename: 'CompanyMetadata'
        readonly subtrade: String['output'] | null
        readonly domains: ReadonlyArray<String['output']>
        readonly domainSignup: Boolean['output']
        readonly monthlyCreatePayAppReminderDate: Int['output'] | null
      }
      readonly defaultQuickBillingTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
      } | null
      readonly sitelinePointOfContact: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
      } | null
    }
  }>
}

export type MinimalCompanyUserProperties = {
  readonly __typename: 'CompanyUser'
  readonly id: ID['output']
  readonly role: CompanyUserRole
  readonly user: {
    readonly __typename: 'User'
    readonly id: ID['output']
    readonly firstName: String['output']
    readonly lastName: String['output']
    readonly email: EmailAddress['output']
    readonly jobTitle: String['output'] | null
    readonly phoneNumber: PhoneNumber['output'] | null
    readonly companies: ReadonlyArray<{
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
    }>
  }
}

export type CompanyUserProperties = {
  readonly __typename: 'CompanyUser'
  readonly id: ID['output']
  readonly role: CompanyUserRole
  readonly isSitelineAdmin: Boolean['output']
  readonly status: UserStatus
  readonly permissions: ReadonlyArray<Permission>
  readonly user: {
    readonly __typename: 'User'
    readonly blockedEmails: ReadonlyArray<EmailType>
    readonly blockedNotifications: ReadonlyArray<NotificationType>
    readonly policiesAcceptedAt: DateTime['output'] | null
    readonly id: ID['output']
    readonly firstName: String['output']
    readonly lastName: String['output']
    readonly email: EmailAddress['output']
    readonly jobTitle: String['output'] | null
    readonly phoneNumber: PhoneNumber['output'] | null
    readonly companies: ReadonlyArray<{
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
      readonly nickname: String['output'] | null
      readonly type: CompanyType
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly expectedNumberOfProjects: SafeInt['output'] | null
      readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
      readonly allowLumpSumOverbilling: Boolean['output']
      readonly notaryTags: ReadonlyArray<NotaryTag>
      readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
      readonly isDemoCompany: Boolean['output']
      readonly picture: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly notaryStamp: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly notarySignature: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly secondarySignature: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly locations: ReadonlyArray<{
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }>
      readonly metadata: {
        readonly __typename: 'CompanyMetadata'
        readonly subtrade: String['output'] | null
        readonly domains: ReadonlyArray<String['output']>
        readonly domainSignup: Boolean['output']
        readonly monthlyCreatePayAppReminderDate: Int['output'] | null
      }
      readonly defaultQuickBillingTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
      } | null
      readonly sitelinePointOfContact: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
      } | null
    }>
    readonly companyUsers: ReadonlyArray<{
      readonly __typename: 'CompanyUser'
      readonly id: ID['output']
      readonly status: UserStatus
      readonly role: CompanyUserRole
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
        readonly nickname: String['output'] | null
        readonly type: CompanyType
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly expectedNumberOfProjects: SafeInt['output'] | null
        readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
        readonly allowLumpSumOverbilling: Boolean['output']
        readonly notaryTags: ReadonlyArray<NotaryTag>
        readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
        readonly isDemoCompany: Boolean['output']
        readonly picture: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notaryStamp: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly secondarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly locations: ReadonlyArray<{
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        }>
        readonly metadata: {
          readonly __typename: 'CompanyMetadata'
          readonly subtrade: String['output'] | null
          readonly domains: ReadonlyArray<String['output']>
          readonly domainSignup: Boolean['output']
          readonly monthlyCreatePayAppReminderDate: Int['output'] | null
        }
        readonly defaultQuickBillingTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
        } | null
        readonly sitelinePointOfContact: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
        } | null
      }
    }>
  }
}

export type StoredFileProperties = {
  readonly __typename: 'StoredFile'
  readonly id: ID['output']
  readonly type: StoredFileType
  readonly url: URL['output']
  readonly name: String['output']
}

export type SovProperties = {
  readonly __typename: 'Sov'
  readonly id: ID['output']
  readonly totalValue: SafeInt['output']
  readonly totalBilled: SafeInt['output']
  readonly totalRetention: SafeInt['output']
  readonly progressComplete: Float['output']
}

export type SovLineItemGroupProperties = {
  readonly __typename: 'SovLineItemGroup'
  readonly id: ID['output']
  readonly name: String['output']
  readonly code: String['output'] | null
}

export type SovLineItemProperties = {
  readonly __typename: 'SovLineItem'
  readonly id: ID['output']
  readonly code: String['output']
  readonly sortOrder: SafeInt['output']
  readonly name: String['output']
  readonly isChangeOrder: Boolean['output']
  readonly changeOrderApprovedDate: DateTime['output'] | null
  readonly latestTotalValue: SafeInt['output']
  readonly totalBilled: SafeInt['output']
  readonly previousBilled: SafeInt['output']
  readonly previousStoredMaterials: SafeInt['output']
  readonly totalRetention: SafeInt['output']
  readonly progressComplete: Float['output']
  readonly defaultRetentionPercent: Float['output'] | null
  readonly sovLineItemGroup: {
    readonly __typename: 'SovLineItemGroup'
    readonly id: ID['output']
    readonly name: String['output']
    readonly code: String['output'] | null
  } | null
}

export type CompanySovProperties = {
  readonly __typename: 'Sov'
  readonly id: ID['output']
  readonly totalValue: SafeInt['output']
  readonly totalBilled: SafeInt['output']
  readonly totalRetention: SafeInt['output']
  readonly progressComplete: Float['output']
  readonly contract: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly timeZone: String['output']
  }
  readonly lineItems: ReadonlyArray<{
    readonly __typename: 'SovLineItem'
    readonly id: ID['output']
    readonly code: String['output']
    readonly sortOrder: SafeInt['output']
    readonly name: String['output']
    readonly isChangeOrder: Boolean['output']
    readonly changeOrderApprovedDate: DateTime['output'] | null
    readonly latestTotalValue: SafeInt['output']
    readonly totalBilled: SafeInt['output']
    readonly previousBilled: SafeInt['output']
    readonly previousStoredMaterials: SafeInt['output']
    readonly totalRetention: SafeInt['output']
    readonly progressComplete: Float['output']
    readonly defaultRetentionPercent: Float['output'] | null
    readonly sovLineItemGroup: {
      readonly __typename: 'SovLineItemGroup'
      readonly id: ID['output']
      readonly name: String['output']
      readonly code: String['output'] | null
    } | null
  }>
}

export type SovLineItemProgressProperties = {
  readonly __typename: 'SovLineItemProgress'
  readonly id: ID['output']
  readonly currentBilled: SafeInt['output']
  readonly progressBilled: SafeInt['output']
  readonly currentRetention: SafeInt['output']
  readonly previousBilled: SafeInt['output']
  readonly storedMaterialBilled: SafeInt['output']
  readonly previousRetention: SafeInt['output']
  readonly previousRetentionBilled: SafeInt['output']
  readonly totalValue: SafeInt['output']
  readonly sovLineItem: {
    readonly __typename: 'SovLineItem'
    readonly id: ID['output']
    readonly code: String['output']
    readonly sortOrder: SafeInt['output']
    readonly name: String['output']
    readonly isChangeOrder: Boolean['output']
    readonly changeOrderApprovedDate: DateTime['output'] | null
    readonly latestTotalValue: SafeInt['output']
    readonly totalBilled: SafeInt['output']
    readonly previousBilled: SafeInt['output']
    readonly previousStoredMaterials: SafeInt['output']
    readonly totalRetention: SafeInt['output']
    readonly progressComplete: Float['output']
    readonly defaultRetentionPercent: Float['output'] | null
    readonly sovLineItemGroup: {
      readonly __typename: 'SovLineItemGroup'
      readonly id: ID['output']
      readonly name: String['output']
      readonly code: String['output'] | null
    } | null
  }
  readonly updatedBy: {
    readonly __typename: 'User'
    readonly blockedEmails: ReadonlyArray<EmailType>
    readonly blockedNotifications: ReadonlyArray<NotificationType>
    readonly policiesAcceptedAt: DateTime['output'] | null
    readonly id: ID['output']
    readonly firstName: String['output']
    readonly lastName: String['output']
    readonly email: EmailAddress['output']
    readonly jobTitle: String['output'] | null
    readonly phoneNumber: PhoneNumber['output'] | null
    readonly companies: ReadonlyArray<{
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
      readonly nickname: String['output'] | null
      readonly type: CompanyType
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly expectedNumberOfProjects: SafeInt['output'] | null
      readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
      readonly allowLumpSumOverbilling: Boolean['output']
      readonly notaryTags: ReadonlyArray<NotaryTag>
      readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
      readonly isDemoCompany: Boolean['output']
      readonly picture: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly notaryStamp: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly notarySignature: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly secondarySignature: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly locations: ReadonlyArray<{
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }>
      readonly metadata: {
        readonly __typename: 'CompanyMetadata'
        readonly subtrade: String['output'] | null
        readonly domains: ReadonlyArray<String['output']>
        readonly domainSignup: Boolean['output']
        readonly monthlyCreatePayAppReminderDate: Int['output'] | null
      }
      readonly defaultQuickBillingTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
      } | null
      readonly sitelinePointOfContact: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
      } | null
    }>
    readonly companyUsers: ReadonlyArray<{
      readonly __typename: 'CompanyUser'
      readonly id: ID['output']
      readonly status: UserStatus
      readonly role: CompanyUserRole
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
        readonly nickname: String['output'] | null
        readonly type: CompanyType
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly expectedNumberOfProjects: SafeInt['output'] | null
        readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
        readonly allowLumpSumOverbilling: Boolean['output']
        readonly notaryTags: ReadonlyArray<NotaryTag>
        readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
        readonly isDemoCompany: Boolean['output']
        readonly picture: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notaryStamp: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly secondarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly locations: ReadonlyArray<{
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        }>
        readonly metadata: {
          readonly __typename: 'CompanyMetadata'
          readonly subtrade: String['output'] | null
          readonly domains: ReadonlyArray<String['output']>
          readonly domainSignup: Boolean['output']
          readonly monthlyCreatePayAppReminderDate: Int['output'] | null
        }
        readonly defaultQuickBillingTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
        } | null
        readonly sitelinePointOfContact: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
        } | null
      }
    }>
  } | null
}

export type FormTemplateAnnotationProperties = {
  readonly __typename: 'FormTemplateAnnotation'
  readonly id: ID['output']
  readonly permanentId: ID['output']
  readonly syncTag: String['output'] | null
  readonly userVisibleName: String['output']
  readonly type: FormTemplateAnnotationType
  readonly xStart: Float['output']
  readonly yStart: Float['output']
  readonly width: Float['output']
  readonly height: Float['output']
  readonly pageNumber: Int['output']
  readonly fontFamily: FormTemplateFont
  readonly fontColor: String['output']
  readonly textAlignment: TextAlignment
  readonly wrapText: Boolean['output']
  readonly isOptional: Boolean['output']
  readonly selectedKey: String['output'] | null
  readonly defaultValueKey: String['output'] | null
  readonly prefix: String['output'] | null
  readonly suffix: String['output'] | null
  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
  readonly doNotRetainOnReset: Boolean['output']
  readonly dynamicFieldTag: String['output'] | null
  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
  readonly imageType: FormTemplateAnnotationImageType | null
  readonly signatureType: SignatureAnnotationType | null
}

export type FormTemplateStatusLogProperties = {
  readonly __typename: 'FormTemplateStatusLog'
  readonly id: ID['output']
  readonly statusUpdatedAt: DateTime['output']
  readonly status: FormTemplateStatus
  readonly user: {
    readonly __typename: 'User'
    readonly id: ID['output']
    readonly email: EmailAddress['output']
  }
}

export type FormTemplateVersionProperties = {
  readonly __typename: 'FormTemplateVersion'
  readonly id: ID['output']
  readonly createdAt: DateTime['output']
  readonly updatedAt: DateTime['output']
  readonly versionNumber: Int['output']
  readonly isReadyForManualStoredMaterials: Boolean['output']
  readonly file: {
    readonly __typename: 'StoredFile'
    readonly id: ID['output']
    readonly type: StoredFileType
    readonly url: URL['output']
    readonly name: String['output']
  }
  readonly annotations: ReadonlyArray<{
    readonly __typename: 'FormTemplateAnnotation'
    readonly id: ID['output']
    readonly permanentId: ID['output']
    readonly syncTag: String['output'] | null
    readonly userVisibleName: String['output']
    readonly type: FormTemplateAnnotationType
    readonly xStart: Float['output']
    readonly yStart: Float['output']
    readonly width: Float['output']
    readonly height: Float['output']
    readonly pageNumber: Int['output']
    readonly fontFamily: FormTemplateFont
    readonly fontColor: String['output']
    readonly textAlignment: TextAlignment
    readonly wrapText: Boolean['output']
    readonly isOptional: Boolean['output']
    readonly selectedKey: String['output'] | null
    readonly defaultValueKey: String['output'] | null
    readonly prefix: String['output'] | null
    readonly suffix: String['output'] | null
    readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
    readonly doNotRetainOnReset: Boolean['output']
    readonly dynamicFieldTag: String['output'] | null
    readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
    readonly imageType: FormTemplateAnnotationImageType | null
    readonly signatureType: SignatureAnnotationType | null
  }>
}

export type FormTemplateCommentProperties = {
  readonly __typename: 'FormTemplateComment'
  readonly id: ID['output']
  readonly createdAt: DateTime['output']
  readonly message: String['output']
  readonly isSystemGenerated: Boolean['output']
  readonly user: {
    readonly __typename: 'User'
    readonly id: ID['output']
    readonly firstName: String['output']
    readonly lastName: String['output']
    readonly email: EmailAddress['output']
  }
}

export type FormTemplateProperties = {
  readonly __typename: 'FormTemplate'
  readonly id: String['output']
  readonly createdAt: DateTime['output']
  readonly type: FormTemplateType
  readonly status: FormTemplateStatus
  readonly skippedValidation: Boolean['output']
  readonly userVisibleName: String['output']
  readonly isCustomerReady: Boolean['output']
  readonly tags: ReadonlyArray<FormTemplateTag>
  readonly dueDate: DateTime['output'] | null
  readonly statusLogs: ReadonlyArray<{
    readonly __typename: 'FormTemplateStatusLog'
    readonly id: ID['output']
    readonly statusUpdatedAt: DateTime['output']
    readonly status: FormTemplateStatus
    readonly user: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
    }
  }>
  readonly associatedCompany: { readonly __typename: 'Company'; readonly id: ID['output'] } | null
  readonly requestingCompany: { readonly __typename: 'Company'; readonly id: ID['output'] } | null
  readonly associatedContracts: ReadonlyArray<{
    readonly __typename: 'FormTemplateAssociatedContract'
    readonly id: String['output']
    readonly providedAsFormType: ProjectOnboardingFormType | null
    readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
  }>
  readonly owner: {
    readonly __typename: 'User'
    readonly id: ID['output']
    readonly email: EmailAddress['output']
    readonly firstName: String['output']
    readonly lastName: String['output']
  }
  readonly builder: {
    readonly __typename: 'User'
    readonly id: ID['output']
    readonly email: EmailAddress['output']
    readonly firstName: String['output']
    readonly lastName: String['output']
  }
  readonly validator: {
    readonly __typename: 'User'
    readonly id: ID['output']
    readonly email: EmailAddress['output']
    readonly firstName: String['output']
    readonly lastName: String['output']
  } | null
  readonly versions: ReadonlyArray<{
    readonly __typename: 'FormTemplateVersion'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly updatedAt: DateTime['output']
    readonly versionNumber: Int['output']
    readonly isReadyForManualStoredMaterials: Boolean['output']
    readonly file: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
    }
    readonly annotations: ReadonlyArray<{
      readonly __typename: 'FormTemplateAnnotation'
      readonly id: ID['output']
      readonly permanentId: ID['output']
      readonly syncTag: String['output'] | null
      readonly userVisibleName: String['output']
      readonly type: FormTemplateAnnotationType
      readonly xStart: Float['output']
      readonly yStart: Float['output']
      readonly width: Float['output']
      readonly height: Float['output']
      readonly pageNumber: Int['output']
      readonly fontFamily: FormTemplateFont
      readonly fontColor: String['output']
      readonly textAlignment: TextAlignment
      readonly wrapText: Boolean['output']
      readonly isOptional: Boolean['output']
      readonly selectedKey: String['output'] | null
      readonly defaultValueKey: String['output'] | null
      readonly prefix: String['output'] | null
      readonly suffix: String['output'] | null
      readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
      readonly doNotRetainOnReset: Boolean['output']
      readonly dynamicFieldTag: String['output'] | null
      readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
      readonly imageType: FormTemplateAnnotationImageType | null
      readonly signatureType: SignatureAnnotationType | null
    }>
  }>
  readonly variants: ReadonlyArray<{
    readonly __typename: 'FormTemplateVariant'
    readonly id: ID['output']
    readonly internalName: String['output']
    readonly isDefaultVariant: Boolean['output']
  }>
  readonly comments: ReadonlyArray<{
    readonly __typename: 'FormTemplateComment'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly message: String['output']
    readonly isSystemGenerated: Boolean['output']
    readonly user: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
      readonly email: EmailAddress['output']
    }
  }>
  readonly originalFile: {
    readonly __typename: 'StoredFile'
    readonly id: ID['output']
    readonly type: StoredFileType
    readonly url: URL['output']
    readonly name: String['output']
  } | null
  readonly duplicateTemplate: {
    readonly __typename: 'FormTemplate'
    readonly id: String['output']
    readonly userVisibleName: String['output']
  } | null
}

export type MinimalFormTemplateProperties = {
  readonly __typename: 'FormTemplate'
  readonly id: String['output']
  readonly createdAt: DateTime['output']
  readonly isCustomerReady: Boolean['output']
  readonly userVisibleName: String['output']
  readonly skippedValidation: Boolean['output']
  readonly type: FormTemplateType
  readonly status: FormTemplateStatus
  readonly versions: ReadonlyArray<{
    readonly __typename: 'FormTemplateVersion'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly updatedAt: DateTime['output']
    readonly versionNumber: Int['output']
    readonly isReadyForManualStoredMaterials: Boolean['output']
    readonly file: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
    }
    readonly annotations: ReadonlyArray<{
      readonly __typename: 'FormTemplateAnnotation'
      readonly id: ID['output']
      readonly permanentId: ID['output']
      readonly syncTag: String['output'] | null
      readonly userVisibleName: String['output']
      readonly type: FormTemplateAnnotationType
      readonly xStart: Float['output']
      readonly yStart: Float['output']
      readonly width: Float['output']
      readonly height: Float['output']
      readonly pageNumber: Int['output']
      readonly fontFamily: FormTemplateFont
      readonly fontColor: String['output']
      readonly textAlignment: TextAlignment
      readonly wrapText: Boolean['output']
      readonly isOptional: Boolean['output']
      readonly selectedKey: String['output'] | null
      readonly defaultValueKey: String['output'] | null
      readonly prefix: String['output'] | null
      readonly suffix: String['output'] | null
      readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
      readonly doNotRetainOnReset: Boolean['output']
      readonly dynamicFieldTag: String['output'] | null
      readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
      readonly imageType: FormTemplateAnnotationImageType | null
      readonly signatureType: SignatureAnnotationType | null
    }>
  }>
  readonly variants: ReadonlyArray<{
    readonly __typename: 'FormTemplateVariant'
    readonly id: ID['output']
    readonly internalName: String['output']
    readonly isDefaultVariant: Boolean['output']
  }>
}

export type AnnotationOverrideProperties = {
  readonly __typename: 'AnnotationOverride'
  readonly id: ID['output']
  readonly annotationPermanentId: ID['output']
  readonly userVisibleName: String['output'] | null
  readonly fontFamily: FormTemplateFont | null
  readonly fontColor: String['output'] | null
  readonly textAlignment: TextAlignment | null
  readonly wrapText: Boolean['output'] | null
  readonly isOptional: Boolean['output'] | null
  readonly selectedKey: String['output'] | null
  readonly defaultValueKey: String['output'] | null
  readonly prefix: String['output'] | null
  readonly suffix: String['output'] | null
  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
  readonly doNotRetainOnReset: Boolean['output'] | null
  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output'] | null
  readonly imageType: FormTemplateAnnotationImageType | null
  readonly signatureType: SignatureAnnotationType | null
  readonly syncTag: String['output'] | null
}

export type FormTemplateVariantProperties = {
  readonly __typename: 'FormTemplateVariant'
  readonly id: ID['output']
  readonly internalName: String['output']
  readonly userVisibleName: String['output'] | null
  readonly isDefaultVariant: Boolean['output']
  readonly hidesZeroDollarAmounts: Boolean['output'] | null
  readonly roundPercentages: Boolean['output'] | null
  readonly useCompanyNotarySignatureIfAvailable: Boolean['output'] | null
  readonly annotationOverrides: ReadonlyArray<{
    readonly __typename: 'AnnotationOverride'
    readonly id: ID['output']
    readonly annotationPermanentId: ID['output']
    readonly userVisibleName: String['output'] | null
    readonly fontFamily: FormTemplateFont | null
    readonly fontColor: String['output'] | null
    readonly textAlignment: TextAlignment | null
    readonly wrapText: Boolean['output'] | null
    readonly isOptional: Boolean['output'] | null
    readonly selectedKey: String['output'] | null
    readonly defaultValueKey: String['output'] | null
    readonly prefix: String['output'] | null
    readonly suffix: String['output'] | null
    readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
    readonly doNotRetainOnReset: Boolean['output'] | null
    readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output'] | null
    readonly imageType: FormTemplateAnnotationImageType | null
    readonly signatureType: SignatureAnnotationType | null
    readonly syncTag: String['output'] | null
  }>
}

export type SimilarTemplateProperties = {
  readonly __typename: 'SimilarTemplate'
  readonly score: Float['output']
  readonly template: {
    readonly __typename: 'FormTemplate'
    readonly id: String['output']
    readonly createdAt: DateTime['output']
    readonly type: FormTemplateType
    readonly status: FormTemplateStatus
    readonly skippedValidation: Boolean['output']
    readonly userVisibleName: String['output']
    readonly isCustomerReady: Boolean['output']
    readonly tags: ReadonlyArray<FormTemplateTag>
    readonly dueDate: DateTime['output'] | null
    readonly statusLogs: ReadonlyArray<{
      readonly __typename: 'FormTemplateStatusLog'
      readonly id: ID['output']
      readonly statusUpdatedAt: DateTime['output']
      readonly status: FormTemplateStatus
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
      }
    }>
    readonly associatedCompany: { readonly __typename: 'Company'; readonly id: ID['output'] } | null
    readonly requestingCompany: { readonly __typename: 'Company'; readonly id: ID['output'] } | null
    readonly associatedContracts: ReadonlyArray<{
      readonly __typename: 'FormTemplateAssociatedContract'
      readonly id: String['output']
      readonly providedAsFormType: ProjectOnboardingFormType | null
      readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
    }>
    readonly owner: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
    readonly builder: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
    readonly validator: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    } | null
    readonly versions: ReadonlyArray<{
      readonly __typename: 'FormTemplateVersion'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly updatedAt: DateTime['output']
      readonly versionNumber: Int['output']
      readonly isReadyForManualStoredMaterials: Boolean['output']
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
      }
      readonly annotations: ReadonlyArray<{
        readonly __typename: 'FormTemplateAnnotation'
        readonly id: ID['output']
        readonly permanentId: ID['output']
        readonly syncTag: String['output'] | null
        readonly userVisibleName: String['output']
        readonly type: FormTemplateAnnotationType
        readonly xStart: Float['output']
        readonly yStart: Float['output']
        readonly width: Float['output']
        readonly height: Float['output']
        readonly pageNumber: Int['output']
        readonly fontFamily: FormTemplateFont
        readonly fontColor: String['output']
        readonly textAlignment: TextAlignment
        readonly wrapText: Boolean['output']
        readonly isOptional: Boolean['output']
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output']
        readonly dynamicFieldTag: String['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
        readonly imageType: FormTemplateAnnotationImageType | null
        readonly signatureType: SignatureAnnotationType | null
      }>
    }>
    readonly variants: ReadonlyArray<{
      readonly __typename: 'FormTemplateVariant'
      readonly id: ID['output']
      readonly internalName: String['output']
      readonly isDefaultVariant: Boolean['output']
    }>
    readonly comments: ReadonlyArray<{
      readonly __typename: 'FormTemplateComment'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly message: String['output']
      readonly isSystemGenerated: Boolean['output']
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
      }
    }>
    readonly originalFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
    } | null
    readonly duplicateTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly userVisibleName: String['output']
    } | null
  }
  readonly version: {
    readonly __typename: 'FormTemplateVersion'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly updatedAt: DateTime['output']
    readonly versionNumber: Int['output']
    readonly isReadyForManualStoredMaterials: Boolean['output']
    readonly file: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
    }
    readonly annotations: ReadonlyArray<{
      readonly __typename: 'FormTemplateAnnotation'
      readonly id: ID['output']
      readonly permanentId: ID['output']
      readonly syncTag: String['output'] | null
      readonly userVisibleName: String['output']
      readonly type: FormTemplateAnnotationType
      readonly xStart: Float['output']
      readonly yStart: Float['output']
      readonly width: Float['output']
      readonly height: Float['output']
      readonly pageNumber: Int['output']
      readonly fontFamily: FormTemplateFont
      readonly fontColor: String['output']
      readonly textAlignment: TextAlignment
      readonly wrapText: Boolean['output']
      readonly isOptional: Boolean['output']
      readonly selectedKey: String['output'] | null
      readonly defaultValueKey: String['output'] | null
      readonly prefix: String['output'] | null
      readonly suffix: String['output'] | null
      readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
      readonly doNotRetainOnReset: Boolean['output']
      readonly dynamicFieldTag: String['output'] | null
      readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
      readonly imageType: FormTemplateAnnotationImageType | null
      readonly signatureType: SignatureAnnotationType | null
    }>
  }
}

export type PayAppNoProgressProperties = {
  readonly __typename: 'PayApp'
  readonly id: ID['output']
  readonly createdAt: DateTime['output']
  readonly timeZone: String['output']
  readonly status: PayAppStatus
  readonly retentionOnly: Boolean['output']
  readonly billingStart: DateTime['output']
  readonly billingEnd: DateTime['output']
  readonly payAppDueDate: DateTime['output']
  readonly currentBilled: SafeInt['output']
  readonly currentRetention: SafeInt['output']
  readonly totalRetention: SafeInt['output']
  readonly previousRetentionBilled: SafeInt['output']
  readonly contract: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly company: {
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
      readonly nickname: String['output'] | null
      readonly type: CompanyType
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly expectedNumberOfProjects: SafeInt['output'] | null
      readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
      readonly allowLumpSumOverbilling: Boolean['output']
      readonly notaryTags: ReadonlyArray<NotaryTag>
      readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
      readonly isDemoCompany: Boolean['output']
      readonly picture: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly notaryStamp: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly notarySignature: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly secondarySignature: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly locations: ReadonlyArray<{
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }>
      readonly metadata: {
        readonly __typename: 'CompanyMetadata'
        readonly subtrade: String['output'] | null
        readonly domains: ReadonlyArray<String['output']>
        readonly domainSignup: Boolean['output']
        readonly monthlyCreatePayAppReminderDate: Int['output'] | null
      }
      readonly defaultQuickBillingTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
      } | null
      readonly sitelinePointOfContact: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
      } | null
    }
  }
}

export type AmountLineItemProperties = {
  readonly __typename: 'AmountLineItem'
  readonly id: ID['output']
  readonly description: String['output']
  readonly amount: SafeInt['output']
  readonly sortOrder: SafeInt['output']
  readonly feePercent: Float['output']
}

export type PayAppProperties = {
  readonly __typename: 'PayApp'
  readonly id: ID['output']
  readonly createdAt: DateTime['output']
  readonly timeZone: String['output']
  readonly status: PayAppStatus
  readonly retentionOnly: Boolean['output']
  readonly billingStart: DateTime['output']
  readonly billingEnd: DateTime['output']
  readonly payAppDueDate: DateTime['output']
  readonly currentBilled: SafeInt['output']
  readonly currentRetention: SafeInt['output']
  readonly totalRetention: SafeInt['output']
  readonly previousRetentionBilled: SafeInt['output']
  readonly progress: ReadonlyArray<{
    readonly __typename: 'SovLineItemProgress'
    readonly id: ID['output']
    readonly currentBilled: SafeInt['output']
    readonly progressBilled: SafeInt['output']
    readonly currentRetention: SafeInt['output']
    readonly previousBilled: SafeInt['output']
    readonly storedMaterialBilled: SafeInt['output']
    readonly previousRetention: SafeInt['output']
    readonly previousRetentionBilled: SafeInt['output']
    readonly totalValue: SafeInt['output']
    readonly sovLineItem: {
      readonly __typename: 'SovLineItem'
      readonly id: ID['output']
      readonly code: String['output']
      readonly sortOrder: SafeInt['output']
      readonly name: String['output']
      readonly isChangeOrder: Boolean['output']
      readonly changeOrderApprovedDate: DateTime['output'] | null
      readonly latestTotalValue: SafeInt['output']
      readonly totalBilled: SafeInt['output']
      readonly previousBilled: SafeInt['output']
      readonly previousStoredMaterials: SafeInt['output']
      readonly totalRetention: SafeInt['output']
      readonly progressComplete: Float['output']
      readonly defaultRetentionPercent: Float['output'] | null
      readonly sovLineItemGroup: {
        readonly __typename: 'SovLineItemGroup'
        readonly id: ID['output']
        readonly name: String['output']
        readonly code: String['output'] | null
      } | null
    }
    readonly updatedBy: {
      readonly __typename: 'User'
      readonly blockedEmails: ReadonlyArray<EmailType>
      readonly blockedNotifications: ReadonlyArray<NotificationType>
      readonly policiesAcceptedAt: DateTime['output'] | null
      readonly id: ID['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
      readonly email: EmailAddress['output']
      readonly jobTitle: String['output'] | null
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly companies: ReadonlyArray<{
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
        readonly nickname: String['output'] | null
        readonly type: CompanyType
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly expectedNumberOfProjects: SafeInt['output'] | null
        readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
        readonly allowLumpSumOverbilling: Boolean['output']
        readonly notaryTags: ReadonlyArray<NotaryTag>
        readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
        readonly isDemoCompany: Boolean['output']
        readonly picture: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notaryStamp: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly secondarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly locations: ReadonlyArray<{
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        }>
        readonly metadata: {
          readonly __typename: 'CompanyMetadata'
          readonly subtrade: String['output'] | null
          readonly domains: ReadonlyArray<String['output']>
          readonly domainSignup: Boolean['output']
          readonly monthlyCreatePayAppReminderDate: Int['output'] | null
        }
        readonly defaultQuickBillingTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
        } | null
        readonly sitelinePointOfContact: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
        } | null
      }>
      readonly companyUsers: ReadonlyArray<{
        readonly __typename: 'CompanyUser'
        readonly id: ID['output']
        readonly status: UserStatus
        readonly role: CompanyUserRole
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
          readonly nickname: String['output'] | null
          readonly type: CompanyType
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly expectedNumberOfProjects: SafeInt['output'] | null
          readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
          readonly allowLumpSumOverbilling: Boolean['output']
          readonly notaryTags: ReadonlyArray<NotaryTag>
          readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
          readonly isDemoCompany: Boolean['output']
          readonly picture: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly notaryStamp: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly notarySignature: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly secondarySignature: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly locations: ReadonlyArray<{
            readonly __typename: 'Location'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly nickname: String['output'] | null
            readonly street1: String['output'] | null
            readonly street2: String['output'] | null
            readonly city: String['output']
            readonly county: String['output'] | null
            readonly state: String['output']
            readonly country: String['output']
            readonly postalCode: String['output'] | null
            readonly timeZone: String['output']
            readonly coordinates: LatLng['output']
          }>
          readonly metadata: {
            readonly __typename: 'CompanyMetadata'
            readonly subtrade: String['output'] | null
            readonly domains: ReadonlyArray<String['output']>
            readonly domainSignup: Boolean['output']
            readonly monthlyCreatePayAppReminderDate: Int['output'] | null
          }
          readonly defaultQuickBillingTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
          } | null
          readonly sitelinePointOfContact: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
          } | null
        }
      }>
    } | null
  }>
  readonly amountLineItems: ReadonlyArray<{
    readonly __typename: 'AmountLineItem'
    readonly id: ID['output']
    readonly description: String['output']
    readonly amount: SafeInt['output']
    readonly sortOrder: SafeInt['output']
    readonly feePercent: Float['output']
  }>
  readonly contract: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly company: {
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
      readonly nickname: String['output'] | null
      readonly type: CompanyType
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly expectedNumberOfProjects: SafeInt['output'] | null
      readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
      readonly allowLumpSumOverbilling: Boolean['output']
      readonly notaryTags: ReadonlyArray<NotaryTag>
      readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
      readonly isDemoCompany: Boolean['output']
      readonly picture: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly notaryStamp: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly notarySignature: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly secondarySignature: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly locations: ReadonlyArray<{
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }>
      readonly metadata: {
        readonly __typename: 'CompanyMetadata'
        readonly subtrade: String['output'] | null
        readonly domains: ReadonlyArray<String['output']>
        readonly domainSignup: Boolean['output']
        readonly monthlyCreatePayAppReminderDate: Int['output'] | null
      }
      readonly defaultQuickBillingTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
      } | null
      readonly sitelinePointOfContact: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
      } | null
    }
  }
}

export type CompanyProjectMetadataProperties = {
  readonly __typename: 'CompanyProjectMetadata'
  readonly role: CompanyProjectRole
  readonly companyName: String['output'] | null
  readonly company: {
    readonly __typename: 'Company'
    readonly id: ID['output']
    readonly name: String['output']
    readonly nickname: String['output'] | null
    readonly type: CompanyType
    readonly phoneNumber: PhoneNumber['output'] | null
    readonly expectedNumberOfProjects: SafeInt['output'] | null
    readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
    readonly allowLumpSumOverbilling: Boolean['output']
    readonly notaryTags: ReadonlyArray<NotaryTag>
    readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
    readonly isDemoCompany: Boolean['output']
    readonly picture: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
    } | null
    readonly notaryStamp: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
    } | null
    readonly notarySignature: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
    } | null
    readonly secondarySignature: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
    } | null
    readonly locations: ReadonlyArray<{
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    }>
    readonly metadata: {
      readonly __typename: 'CompanyMetadata'
      readonly subtrade: String['output'] | null
      readonly domains: ReadonlyArray<String['output']>
      readonly domainSignup: Boolean['output']
      readonly monthlyCreatePayAppReminderDate: Int['output'] | null
    }
    readonly defaultQuickBillingTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
    } | null
    readonly sitelinePointOfContact: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
    } | null
  }
  readonly selectedAddress: {
    readonly __typename: 'Location'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly nickname: String['output'] | null
    readonly street1: String['output'] | null
    readonly street2: String['output'] | null
    readonly city: String['output']
    readonly county: String['output'] | null
    readonly state: String['output']
    readonly country: String['output']
    readonly postalCode: String['output'] | null
    readonly timeZone: String['output']
    readonly coordinates: LatLng['output']
  } | null
}

export type ProjectProperties = {
  readonly __typename: 'Project'
  readonly id: ID['output']
  readonly name: String['output']
  readonly projectNumber: String['output']
  readonly location: {
    readonly __typename: 'Location'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly nickname: String['output'] | null
    readonly street1: String['output'] | null
    readonly street2: String['output'] | null
    readonly city: String['output']
    readonly county: String['output'] | null
    readonly state: String['output']
    readonly country: String['output']
    readonly postalCode: String['output'] | null
    readonly timeZone: String['output']
    readonly coordinates: LatLng['output']
  }
  readonly generalContractor: {
    readonly __typename: 'CompanyProjectMetadata'
    readonly role: CompanyProjectRole
    readonly companyName: String['output'] | null
    readonly company: {
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
      readonly nickname: String['output'] | null
      readonly type: CompanyType
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly expectedNumberOfProjects: SafeInt['output'] | null
      readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
      readonly allowLumpSumOverbilling: Boolean['output']
      readonly notaryTags: ReadonlyArray<NotaryTag>
      readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
      readonly isDemoCompany: Boolean['output']
      readonly picture: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly notaryStamp: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly notarySignature: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly secondarySignature: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly locations: ReadonlyArray<{
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }>
      readonly metadata: {
        readonly __typename: 'CompanyMetadata'
        readonly subtrade: String['output'] | null
        readonly domains: ReadonlyArray<String['output']>
        readonly domainSignup: Boolean['output']
        readonly monthlyCreatePayAppReminderDate: Int['output'] | null
      }
      readonly defaultQuickBillingTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
      } | null
      readonly sitelinePointOfContact: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
      } | null
    }
    readonly selectedAddress: {
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    } | null
  } | null
}

export type IntegrationProperties = {
  readonly __typename: 'Integration'
  readonly id: ID['output']
  readonly createdAt: DateTime['output']
  readonly type: IntegrationType
  readonly credentialsUpdatedAt: DateTime['output'] | null
  readonly mappings: JSONObject['output']
  readonly isActive: Boolean['output']
  readonly shortName: String['output']
  readonly longName: String['output']
  readonly companyIntegration: {
    readonly __typename: 'CompanyIntegration'
    readonly id: ID['output']
  }
}

export type LegalDocumentProperties = {
  readonly __typename: 'LegalDocument'
  readonly id: ID['output']
  readonly name: String['output']
  readonly periodStart: DateTime['output'] | null
  readonly periodEnd: DateTime['output'] | null
  readonly lastSyncIfSuccessful: {
    readonly __typename: 'WriteSyncOperation'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
  } | null
}

export type LegalRequirementProperties = {
  readonly __typename: 'LegalRequirement'
  readonly id: ID['output']
  readonly createdAt: DateTime['output']
  readonly name: String['output']
  readonly timeZone: String['output']
  readonly expirationFrequency: DocumentExpirationFrequency
  readonly startDate: DateTime['output'] | null
  readonly endDate: DateTime['output'] | null
  readonly isVendorRequirement: Boolean['output']
  readonly documents: ReadonlyArray<{
    readonly __typename: 'LegalDocument'
    readonly id: ID['output']
  }>
  readonly formTemplate: {
    readonly __typename: 'FormTemplate'
    readonly id: String['output']
  } | null
}

export type PayAppRequirementProperties = {
  readonly __typename: 'PayAppRequirement'
  readonly id: ID['output']
  readonly groupOrder: Int['output']
  readonly conditions: ReadonlyArray<PayAppRequirementCondition>
  readonly templateVariant: {
    readonly __typename: 'FormTemplateVariant'
    readonly id: ID['output']
    readonly internalName: String['output']
    readonly userVisibleName: String['output'] | null
    readonly template: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly isCustomerReady: Boolean['output']
    }
  } | null
}

export type PayAppRequirementGroupProperties = {
  readonly __typename: 'PayAppRequirementGroup'
  readonly id: ID['output']
  readonly order: Int['output']
  readonly payAppRequirements: ReadonlyArray<{
    readonly __typename: 'PayAppRequirement'
    readonly id: ID['output']
    readonly groupOrder: Int['output']
    readonly conditions: ReadonlyArray<PayAppRequirementCondition>
    readonly templateVariant: {
      readonly __typename: 'FormTemplateVariant'
      readonly id: ID['output']
      readonly internalName: String['output']
      readonly userVisibleName: String['output'] | null
      readonly template: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly isCustomerReady: Boolean['output']
      }
    } | null
  }>
}

export type LienWaiverFormTemplateVariantProperties = {
  readonly __typename: 'FormTemplateVariant'
  readonly id: ID['output']
  readonly template: {
    readonly __typename: 'FormTemplate'
    readonly id: String['output']
    readonly createdAt: DateTime['output']
    readonly type: FormTemplateType
    readonly status: FormTemplateStatus
    readonly skippedValidation: Boolean['output']
    readonly userVisibleName: String['output']
    readonly isCustomerReady: Boolean['output']
    readonly tags: ReadonlyArray<FormTemplateTag>
    readonly dueDate: DateTime['output'] | null
    readonly statusLogs: ReadonlyArray<{
      readonly __typename: 'FormTemplateStatusLog'
      readonly id: ID['output']
      readonly statusUpdatedAt: DateTime['output']
      readonly status: FormTemplateStatus
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
      }
    }>
    readonly associatedCompany: { readonly __typename: 'Company'; readonly id: ID['output'] } | null
    readonly requestingCompany: { readonly __typename: 'Company'; readonly id: ID['output'] } | null
    readonly associatedContracts: ReadonlyArray<{
      readonly __typename: 'FormTemplateAssociatedContract'
      readonly id: String['output']
      readonly providedAsFormType: ProjectOnboardingFormType | null
      readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
    }>
    readonly owner: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
    readonly builder: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
    readonly validator: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    } | null
    readonly versions: ReadonlyArray<{
      readonly __typename: 'FormTemplateVersion'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly updatedAt: DateTime['output']
      readonly versionNumber: Int['output']
      readonly isReadyForManualStoredMaterials: Boolean['output']
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
      }
      readonly annotations: ReadonlyArray<{
        readonly __typename: 'FormTemplateAnnotation'
        readonly id: ID['output']
        readonly permanentId: ID['output']
        readonly syncTag: String['output'] | null
        readonly userVisibleName: String['output']
        readonly type: FormTemplateAnnotationType
        readonly xStart: Float['output']
        readonly yStart: Float['output']
        readonly width: Float['output']
        readonly height: Float['output']
        readonly pageNumber: Int['output']
        readonly fontFamily: FormTemplateFont
        readonly fontColor: String['output']
        readonly textAlignment: TextAlignment
        readonly wrapText: Boolean['output']
        readonly isOptional: Boolean['output']
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output']
        readonly dynamicFieldTag: String['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
        readonly imageType: FormTemplateAnnotationImageType | null
        readonly signatureType: SignatureAnnotationType | null
      }>
    }>
    readonly variants: ReadonlyArray<{
      readonly __typename: 'FormTemplateVariant'
      readonly id: ID['output']
      readonly internalName: String['output']
      readonly isDefaultVariant: Boolean['output']
    }>
    readonly comments: ReadonlyArray<{
      readonly __typename: 'FormTemplateComment'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly message: String['output']
      readonly isSystemGenerated: Boolean['output']
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
      }
    }>
    readonly originalFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
    } | null
    readonly duplicateTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly userVisibleName: String['output']
    } | null
  }
}

export type SubcontractorLienWaiverTemplateSetProperties = {
  readonly __typename: 'SubcontractorLienWaiverTemplateSet'
  readonly id: ID['output']
  readonly conditionalFinalVariant: {
    readonly __typename: 'FormTemplateVariant'
    readonly id: ID['output']
    readonly template: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly createdAt: DateTime['output']
      readonly type: FormTemplateType
      readonly status: FormTemplateStatus
      readonly skippedValidation: Boolean['output']
      readonly userVisibleName: String['output']
      readonly isCustomerReady: Boolean['output']
      readonly tags: ReadonlyArray<FormTemplateTag>
      readonly dueDate: DateTime['output'] | null
      readonly statusLogs: ReadonlyArray<{
        readonly __typename: 'FormTemplateStatusLog'
        readonly id: ID['output']
        readonly statusUpdatedAt: DateTime['output']
        readonly status: FormTemplateStatus
        readonly user: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
        }
      }>
      readonly associatedCompany: {
        readonly __typename: 'Company'
        readonly id: ID['output']
      } | null
      readonly requestingCompany: {
        readonly __typename: 'Company'
        readonly id: ID['output']
      } | null
      readonly associatedContracts: ReadonlyArray<{
        readonly __typename: 'FormTemplateAssociatedContract'
        readonly id: String['output']
        readonly providedAsFormType: ProjectOnboardingFormType | null
        readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
      }>
      readonly owner: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
      readonly builder: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
      readonly validator: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      } | null
      readonly versions: ReadonlyArray<{
        readonly __typename: 'FormTemplateVersion'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly updatedAt: DateTime['output']
        readonly versionNumber: Int['output']
        readonly isReadyForManualStoredMaterials: Boolean['output']
        readonly file: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
        }
        readonly annotations: ReadonlyArray<{
          readonly __typename: 'FormTemplateAnnotation'
          readonly id: ID['output']
          readonly permanentId: ID['output']
          readonly syncTag: String['output'] | null
          readonly userVisibleName: String['output']
          readonly type: FormTemplateAnnotationType
          readonly xStart: Float['output']
          readonly yStart: Float['output']
          readonly width: Float['output']
          readonly height: Float['output']
          readonly pageNumber: Int['output']
          readonly fontFamily: FormTemplateFont
          readonly fontColor: String['output']
          readonly textAlignment: TextAlignment
          readonly wrapText: Boolean['output']
          readonly isOptional: Boolean['output']
          readonly selectedKey: String['output'] | null
          readonly defaultValueKey: String['output'] | null
          readonly prefix: String['output'] | null
          readonly suffix: String['output'] | null
          readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
          readonly doNotRetainOnReset: Boolean['output']
          readonly dynamicFieldTag: String['output'] | null
          readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
          readonly imageType: FormTemplateAnnotationImageType | null
          readonly signatureType: SignatureAnnotationType | null
        }>
      }>
      readonly variants: ReadonlyArray<{
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly internalName: String['output']
        readonly isDefaultVariant: Boolean['output']
      }>
      readonly comments: ReadonlyArray<{
        readonly __typename: 'FormTemplateComment'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly message: String['output']
        readonly isSystemGenerated: Boolean['output']
        readonly user: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
          readonly email: EmailAddress['output']
        }
      }>
      readonly originalFile: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
      } | null
      readonly duplicateTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly userVisibleName: String['output']
      } | null
    }
  } | null
  readonly conditionalProgressVariant: {
    readonly __typename: 'FormTemplateVariant'
    readonly id: ID['output']
    readonly template: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly createdAt: DateTime['output']
      readonly type: FormTemplateType
      readonly status: FormTemplateStatus
      readonly skippedValidation: Boolean['output']
      readonly userVisibleName: String['output']
      readonly isCustomerReady: Boolean['output']
      readonly tags: ReadonlyArray<FormTemplateTag>
      readonly dueDate: DateTime['output'] | null
      readonly statusLogs: ReadonlyArray<{
        readonly __typename: 'FormTemplateStatusLog'
        readonly id: ID['output']
        readonly statusUpdatedAt: DateTime['output']
        readonly status: FormTemplateStatus
        readonly user: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
        }
      }>
      readonly associatedCompany: {
        readonly __typename: 'Company'
        readonly id: ID['output']
      } | null
      readonly requestingCompany: {
        readonly __typename: 'Company'
        readonly id: ID['output']
      } | null
      readonly associatedContracts: ReadonlyArray<{
        readonly __typename: 'FormTemplateAssociatedContract'
        readonly id: String['output']
        readonly providedAsFormType: ProjectOnboardingFormType | null
        readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
      }>
      readonly owner: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
      readonly builder: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
      readonly validator: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      } | null
      readonly versions: ReadonlyArray<{
        readonly __typename: 'FormTemplateVersion'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly updatedAt: DateTime['output']
        readonly versionNumber: Int['output']
        readonly isReadyForManualStoredMaterials: Boolean['output']
        readonly file: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
        }
        readonly annotations: ReadonlyArray<{
          readonly __typename: 'FormTemplateAnnotation'
          readonly id: ID['output']
          readonly permanentId: ID['output']
          readonly syncTag: String['output'] | null
          readonly userVisibleName: String['output']
          readonly type: FormTemplateAnnotationType
          readonly xStart: Float['output']
          readonly yStart: Float['output']
          readonly width: Float['output']
          readonly height: Float['output']
          readonly pageNumber: Int['output']
          readonly fontFamily: FormTemplateFont
          readonly fontColor: String['output']
          readonly textAlignment: TextAlignment
          readonly wrapText: Boolean['output']
          readonly isOptional: Boolean['output']
          readonly selectedKey: String['output'] | null
          readonly defaultValueKey: String['output'] | null
          readonly prefix: String['output'] | null
          readonly suffix: String['output'] | null
          readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
          readonly doNotRetainOnReset: Boolean['output']
          readonly dynamicFieldTag: String['output'] | null
          readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
          readonly imageType: FormTemplateAnnotationImageType | null
          readonly signatureType: SignatureAnnotationType | null
        }>
      }>
      readonly variants: ReadonlyArray<{
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly internalName: String['output']
        readonly isDefaultVariant: Boolean['output']
      }>
      readonly comments: ReadonlyArray<{
        readonly __typename: 'FormTemplateComment'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly message: String['output']
        readonly isSystemGenerated: Boolean['output']
        readonly user: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
          readonly email: EmailAddress['output']
        }
      }>
      readonly originalFile: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
      } | null
      readonly duplicateTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly userVisibleName: String['output']
      } | null
    }
  } | null
  readonly unconditionalFinalVariant: {
    readonly __typename: 'FormTemplateVariant'
    readonly id: ID['output']
    readonly template: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly createdAt: DateTime['output']
      readonly type: FormTemplateType
      readonly status: FormTemplateStatus
      readonly skippedValidation: Boolean['output']
      readonly userVisibleName: String['output']
      readonly isCustomerReady: Boolean['output']
      readonly tags: ReadonlyArray<FormTemplateTag>
      readonly dueDate: DateTime['output'] | null
      readonly statusLogs: ReadonlyArray<{
        readonly __typename: 'FormTemplateStatusLog'
        readonly id: ID['output']
        readonly statusUpdatedAt: DateTime['output']
        readonly status: FormTemplateStatus
        readonly user: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
        }
      }>
      readonly associatedCompany: {
        readonly __typename: 'Company'
        readonly id: ID['output']
      } | null
      readonly requestingCompany: {
        readonly __typename: 'Company'
        readonly id: ID['output']
      } | null
      readonly associatedContracts: ReadonlyArray<{
        readonly __typename: 'FormTemplateAssociatedContract'
        readonly id: String['output']
        readonly providedAsFormType: ProjectOnboardingFormType | null
        readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
      }>
      readonly owner: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
      readonly builder: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
      readonly validator: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      } | null
      readonly versions: ReadonlyArray<{
        readonly __typename: 'FormTemplateVersion'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly updatedAt: DateTime['output']
        readonly versionNumber: Int['output']
        readonly isReadyForManualStoredMaterials: Boolean['output']
        readonly file: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
        }
        readonly annotations: ReadonlyArray<{
          readonly __typename: 'FormTemplateAnnotation'
          readonly id: ID['output']
          readonly permanentId: ID['output']
          readonly syncTag: String['output'] | null
          readonly userVisibleName: String['output']
          readonly type: FormTemplateAnnotationType
          readonly xStart: Float['output']
          readonly yStart: Float['output']
          readonly width: Float['output']
          readonly height: Float['output']
          readonly pageNumber: Int['output']
          readonly fontFamily: FormTemplateFont
          readonly fontColor: String['output']
          readonly textAlignment: TextAlignment
          readonly wrapText: Boolean['output']
          readonly isOptional: Boolean['output']
          readonly selectedKey: String['output'] | null
          readonly defaultValueKey: String['output'] | null
          readonly prefix: String['output'] | null
          readonly suffix: String['output'] | null
          readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
          readonly doNotRetainOnReset: Boolean['output']
          readonly dynamicFieldTag: String['output'] | null
          readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
          readonly imageType: FormTemplateAnnotationImageType | null
          readonly signatureType: SignatureAnnotationType | null
        }>
      }>
      readonly variants: ReadonlyArray<{
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly internalName: String['output']
        readonly isDefaultVariant: Boolean['output']
      }>
      readonly comments: ReadonlyArray<{
        readonly __typename: 'FormTemplateComment'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly message: String['output']
        readonly isSystemGenerated: Boolean['output']
        readonly user: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
          readonly email: EmailAddress['output']
        }
      }>
      readonly originalFile: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
      } | null
      readonly duplicateTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly userVisibleName: String['output']
      } | null
    }
  } | null
  readonly unconditionalProgressVariant: {
    readonly __typename: 'FormTemplateVariant'
    readonly id: ID['output']
    readonly template: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly createdAt: DateTime['output']
      readonly type: FormTemplateType
      readonly status: FormTemplateStatus
      readonly skippedValidation: Boolean['output']
      readonly userVisibleName: String['output']
      readonly isCustomerReady: Boolean['output']
      readonly tags: ReadonlyArray<FormTemplateTag>
      readonly dueDate: DateTime['output'] | null
      readonly statusLogs: ReadonlyArray<{
        readonly __typename: 'FormTemplateStatusLog'
        readonly id: ID['output']
        readonly statusUpdatedAt: DateTime['output']
        readonly status: FormTemplateStatus
        readonly user: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
        }
      }>
      readonly associatedCompany: {
        readonly __typename: 'Company'
        readonly id: ID['output']
      } | null
      readonly requestingCompany: {
        readonly __typename: 'Company'
        readonly id: ID['output']
      } | null
      readonly associatedContracts: ReadonlyArray<{
        readonly __typename: 'FormTemplateAssociatedContract'
        readonly id: String['output']
        readonly providedAsFormType: ProjectOnboardingFormType | null
        readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
      }>
      readonly owner: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
      readonly builder: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
      readonly validator: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      } | null
      readonly versions: ReadonlyArray<{
        readonly __typename: 'FormTemplateVersion'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly updatedAt: DateTime['output']
        readonly versionNumber: Int['output']
        readonly isReadyForManualStoredMaterials: Boolean['output']
        readonly file: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
        }
        readonly annotations: ReadonlyArray<{
          readonly __typename: 'FormTemplateAnnotation'
          readonly id: ID['output']
          readonly permanentId: ID['output']
          readonly syncTag: String['output'] | null
          readonly userVisibleName: String['output']
          readonly type: FormTemplateAnnotationType
          readonly xStart: Float['output']
          readonly yStart: Float['output']
          readonly width: Float['output']
          readonly height: Float['output']
          readonly pageNumber: Int['output']
          readonly fontFamily: FormTemplateFont
          readonly fontColor: String['output']
          readonly textAlignment: TextAlignment
          readonly wrapText: Boolean['output']
          readonly isOptional: Boolean['output']
          readonly selectedKey: String['output'] | null
          readonly defaultValueKey: String['output'] | null
          readonly prefix: String['output'] | null
          readonly suffix: String['output'] | null
          readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
          readonly doNotRetainOnReset: Boolean['output']
          readonly dynamicFieldTag: String['output'] | null
          readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
          readonly imageType: FormTemplateAnnotationImageType | null
          readonly signatureType: SignatureAnnotationType | null
        }>
      }>
      readonly variants: ReadonlyArray<{
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly internalName: String['output']
        readonly isDefaultVariant: Boolean['output']
      }>
      readonly comments: ReadonlyArray<{
        readonly __typename: 'FormTemplateComment'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly message: String['output']
        readonly isSystemGenerated: Boolean['output']
        readonly user: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
          readonly email: EmailAddress['output']
        }
      }>
      readonly originalFile: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
      } | null
      readonly duplicateTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly userVisibleName: String['output']
      } | null
    }
  } | null
}

export type ContractProperties = {
  readonly __typename: 'Contract'
  readonly id: ID['output']
  readonly role: CompanyProjectRole
  readonly status: ContractStatus
  readonly timeZone: String['output']
  readonly contractDate: DateTime['output'] | null
  readonly contractNumber: String['output'] | null
  readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
  readonly defaultRetentionPercent: Float['output'] | null
  readonly retentionTrackingLevel: RetentionTrackingLevel
  readonly daysBeforePayAppDue: Int['output']
  readonly billingType: BillingType
  readonly contractTemplateVariables: JSONObject['output']
  readonly internalProjectNumber: String['output'] | null
  readonly vendorNumber: String['output'] | null
  readonly pastPayAppCount: Int['output']
  readonly roundRetention: Boolean['output']
  readonly project: {
    readonly __typename: 'Project'
    readonly id: ID['output']
    readonly name: String['output']
    readonly projectNumber: String['output']
    readonly location: {
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    }
    readonly generalContractor: {
      readonly __typename: 'CompanyProjectMetadata'
      readonly role: CompanyProjectRole
      readonly companyName: String['output'] | null
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
        readonly nickname: String['output'] | null
        readonly type: CompanyType
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly expectedNumberOfProjects: SafeInt['output'] | null
        readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
        readonly allowLumpSumOverbilling: Boolean['output']
        readonly notaryTags: ReadonlyArray<NotaryTag>
        readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
        readonly isDemoCompany: Boolean['output']
        readonly picture: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notaryStamp: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly secondarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly locations: ReadonlyArray<{
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        }>
        readonly metadata: {
          readonly __typename: 'CompanyMetadata'
          readonly subtrade: String['output'] | null
          readonly domains: ReadonlyArray<String['output']>
          readonly domainSignup: Boolean['output']
          readonly monthlyCreatePayAppReminderDate: Int['output'] | null
        }
        readonly defaultQuickBillingTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
        } | null
        readonly sitelinePointOfContact: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
        } | null
      }
      readonly selectedAddress: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      } | null
    } | null
  }
  readonly company: {
    readonly __typename: 'Company'
    readonly id: ID['output']
    readonly name: String['output']
    readonly nickname: String['output'] | null
    readonly type: CompanyType
    readonly phoneNumber: PhoneNumber['output'] | null
    readonly expectedNumberOfProjects: SafeInt['output'] | null
    readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
    readonly allowLumpSumOverbilling: Boolean['output']
    readonly notaryTags: ReadonlyArray<NotaryTag>
    readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
    readonly isDemoCompany: Boolean['output']
    readonly picture: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
    } | null
    readonly notaryStamp: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
    } | null
    readonly notarySignature: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
    } | null
    readonly secondarySignature: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
    } | null
    readonly locations: ReadonlyArray<{
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    }>
    readonly metadata: {
      readonly __typename: 'CompanyMetadata'
      readonly subtrade: String['output'] | null
      readonly domains: ReadonlyArray<String['output']>
      readonly domainSignup: Boolean['output']
      readonly monthlyCreatePayAppReminderDate: Int['output'] | null
    }
    readonly defaultQuickBillingTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
    } | null
    readonly sitelinePointOfContact: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
    } | null
  }
  readonly sov: {
    readonly __typename: 'Sov'
    readonly id: ID['output']
    readonly totalValue: SafeInt['output']
    readonly totalBilled: SafeInt['output']
    readonly totalRetention: SafeInt['output']
    readonly progressComplete: Float['output']
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly timeZone: String['output']
    }
    readonly lineItems: ReadonlyArray<{
      readonly __typename: 'SovLineItem'
      readonly id: ID['output']
      readonly code: String['output']
      readonly sortOrder: SafeInt['output']
      readonly name: String['output']
      readonly isChangeOrder: Boolean['output']
      readonly changeOrderApprovedDate: DateTime['output'] | null
      readonly latestTotalValue: SafeInt['output']
      readonly totalBilled: SafeInt['output']
      readonly previousBilled: SafeInt['output']
      readonly previousStoredMaterials: SafeInt['output']
      readonly totalRetention: SafeInt['output']
      readonly progressComplete: Float['output']
      readonly defaultRetentionPercent: Float['output'] | null
      readonly sovLineItemGroup: {
        readonly __typename: 'SovLineItemGroup'
        readonly id: ID['output']
        readonly name: String['output']
        readonly code: String['output'] | null
      } | null
    }>
  } | null
  readonly lienWaiverTemplates: {
    readonly __typename: 'SubcontractorLienWaiverTemplateSet'
    readonly id: ID['output']
    readonly conditionalFinalVariant: {
      readonly __typename: 'FormTemplateVariant'
      readonly id: ID['output']
      readonly template: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly createdAt: DateTime['output']
        readonly type: FormTemplateType
        readonly status: FormTemplateStatus
        readonly skippedValidation: Boolean['output']
        readonly userVisibleName: String['output']
        readonly isCustomerReady: Boolean['output']
        readonly tags: ReadonlyArray<FormTemplateTag>
        readonly dueDate: DateTime['output'] | null
        readonly statusLogs: ReadonlyArray<{
          readonly __typename: 'FormTemplateStatusLog'
          readonly id: ID['output']
          readonly statusUpdatedAt: DateTime['output']
          readonly status: FormTemplateStatus
          readonly user: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
          }
        }>
        readonly associatedCompany: {
          readonly __typename: 'Company'
          readonly id: ID['output']
        } | null
        readonly requestingCompany: {
          readonly __typename: 'Company'
          readonly id: ID['output']
        } | null
        readonly associatedContracts: ReadonlyArray<{
          readonly __typename: 'FormTemplateAssociatedContract'
          readonly id: String['output']
          readonly providedAsFormType: ProjectOnboardingFormType | null
          readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
        }>
        readonly owner: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        }
        readonly builder: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        }
        readonly validator: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        } | null
        readonly versions: ReadonlyArray<{
          readonly __typename: 'FormTemplateVersion'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly updatedAt: DateTime['output']
          readonly versionNumber: Int['output']
          readonly isReadyForManualStoredMaterials: Boolean['output']
          readonly file: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          }
          readonly annotations: ReadonlyArray<{
            readonly __typename: 'FormTemplateAnnotation'
            readonly id: ID['output']
            readonly permanentId: ID['output']
            readonly syncTag: String['output'] | null
            readonly userVisibleName: String['output']
            readonly type: FormTemplateAnnotationType
            readonly xStart: Float['output']
            readonly yStart: Float['output']
            readonly width: Float['output']
            readonly height: Float['output']
            readonly pageNumber: Int['output']
            readonly fontFamily: FormTemplateFont
            readonly fontColor: String['output']
            readonly textAlignment: TextAlignment
            readonly wrapText: Boolean['output']
            readonly isOptional: Boolean['output']
            readonly selectedKey: String['output'] | null
            readonly defaultValueKey: String['output'] | null
            readonly prefix: String['output'] | null
            readonly suffix: String['output'] | null
            readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
            readonly doNotRetainOnReset: Boolean['output']
            readonly dynamicFieldTag: String['output'] | null
            readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
            readonly imageType: FormTemplateAnnotationImageType | null
            readonly signatureType: SignatureAnnotationType | null
          }>
        }>
        readonly variants: ReadonlyArray<{
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly internalName: String['output']
          readonly isDefaultVariant: Boolean['output']
        }>
        readonly comments: ReadonlyArray<{
          readonly __typename: 'FormTemplateComment'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly message: String['output']
          readonly isSystemGenerated: Boolean['output']
          readonly user: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
            readonly email: EmailAddress['output']
          }
        }>
        readonly originalFile: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
        } | null
        readonly duplicateTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly userVisibleName: String['output']
        } | null
      }
    } | null
    readonly conditionalProgressVariant: {
      readonly __typename: 'FormTemplateVariant'
      readonly id: ID['output']
      readonly template: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly createdAt: DateTime['output']
        readonly type: FormTemplateType
        readonly status: FormTemplateStatus
        readonly skippedValidation: Boolean['output']
        readonly userVisibleName: String['output']
        readonly isCustomerReady: Boolean['output']
        readonly tags: ReadonlyArray<FormTemplateTag>
        readonly dueDate: DateTime['output'] | null
        readonly statusLogs: ReadonlyArray<{
          readonly __typename: 'FormTemplateStatusLog'
          readonly id: ID['output']
          readonly statusUpdatedAt: DateTime['output']
          readonly status: FormTemplateStatus
          readonly user: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
          }
        }>
        readonly associatedCompany: {
          readonly __typename: 'Company'
          readonly id: ID['output']
        } | null
        readonly requestingCompany: {
          readonly __typename: 'Company'
          readonly id: ID['output']
        } | null
        readonly associatedContracts: ReadonlyArray<{
          readonly __typename: 'FormTemplateAssociatedContract'
          readonly id: String['output']
          readonly providedAsFormType: ProjectOnboardingFormType | null
          readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
        }>
        readonly owner: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        }
        readonly builder: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        }
        readonly validator: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        } | null
        readonly versions: ReadonlyArray<{
          readonly __typename: 'FormTemplateVersion'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly updatedAt: DateTime['output']
          readonly versionNumber: Int['output']
          readonly isReadyForManualStoredMaterials: Boolean['output']
          readonly file: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          }
          readonly annotations: ReadonlyArray<{
            readonly __typename: 'FormTemplateAnnotation'
            readonly id: ID['output']
            readonly permanentId: ID['output']
            readonly syncTag: String['output'] | null
            readonly userVisibleName: String['output']
            readonly type: FormTemplateAnnotationType
            readonly xStart: Float['output']
            readonly yStart: Float['output']
            readonly width: Float['output']
            readonly height: Float['output']
            readonly pageNumber: Int['output']
            readonly fontFamily: FormTemplateFont
            readonly fontColor: String['output']
            readonly textAlignment: TextAlignment
            readonly wrapText: Boolean['output']
            readonly isOptional: Boolean['output']
            readonly selectedKey: String['output'] | null
            readonly defaultValueKey: String['output'] | null
            readonly prefix: String['output'] | null
            readonly suffix: String['output'] | null
            readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
            readonly doNotRetainOnReset: Boolean['output']
            readonly dynamicFieldTag: String['output'] | null
            readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
            readonly imageType: FormTemplateAnnotationImageType | null
            readonly signatureType: SignatureAnnotationType | null
          }>
        }>
        readonly variants: ReadonlyArray<{
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly internalName: String['output']
          readonly isDefaultVariant: Boolean['output']
        }>
        readonly comments: ReadonlyArray<{
          readonly __typename: 'FormTemplateComment'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly message: String['output']
          readonly isSystemGenerated: Boolean['output']
          readonly user: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
            readonly email: EmailAddress['output']
          }
        }>
        readonly originalFile: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
        } | null
        readonly duplicateTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly userVisibleName: String['output']
        } | null
      }
    } | null
    readonly unconditionalFinalVariant: {
      readonly __typename: 'FormTemplateVariant'
      readonly id: ID['output']
      readonly template: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly createdAt: DateTime['output']
        readonly type: FormTemplateType
        readonly status: FormTemplateStatus
        readonly skippedValidation: Boolean['output']
        readonly userVisibleName: String['output']
        readonly isCustomerReady: Boolean['output']
        readonly tags: ReadonlyArray<FormTemplateTag>
        readonly dueDate: DateTime['output'] | null
        readonly statusLogs: ReadonlyArray<{
          readonly __typename: 'FormTemplateStatusLog'
          readonly id: ID['output']
          readonly statusUpdatedAt: DateTime['output']
          readonly status: FormTemplateStatus
          readonly user: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
          }
        }>
        readonly associatedCompany: {
          readonly __typename: 'Company'
          readonly id: ID['output']
        } | null
        readonly requestingCompany: {
          readonly __typename: 'Company'
          readonly id: ID['output']
        } | null
        readonly associatedContracts: ReadonlyArray<{
          readonly __typename: 'FormTemplateAssociatedContract'
          readonly id: String['output']
          readonly providedAsFormType: ProjectOnboardingFormType | null
          readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
        }>
        readonly owner: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        }
        readonly builder: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        }
        readonly validator: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        } | null
        readonly versions: ReadonlyArray<{
          readonly __typename: 'FormTemplateVersion'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly updatedAt: DateTime['output']
          readonly versionNumber: Int['output']
          readonly isReadyForManualStoredMaterials: Boolean['output']
          readonly file: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          }
          readonly annotations: ReadonlyArray<{
            readonly __typename: 'FormTemplateAnnotation'
            readonly id: ID['output']
            readonly permanentId: ID['output']
            readonly syncTag: String['output'] | null
            readonly userVisibleName: String['output']
            readonly type: FormTemplateAnnotationType
            readonly xStart: Float['output']
            readonly yStart: Float['output']
            readonly width: Float['output']
            readonly height: Float['output']
            readonly pageNumber: Int['output']
            readonly fontFamily: FormTemplateFont
            readonly fontColor: String['output']
            readonly textAlignment: TextAlignment
            readonly wrapText: Boolean['output']
            readonly isOptional: Boolean['output']
            readonly selectedKey: String['output'] | null
            readonly defaultValueKey: String['output'] | null
            readonly prefix: String['output'] | null
            readonly suffix: String['output'] | null
            readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
            readonly doNotRetainOnReset: Boolean['output']
            readonly dynamicFieldTag: String['output'] | null
            readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
            readonly imageType: FormTemplateAnnotationImageType | null
            readonly signatureType: SignatureAnnotationType | null
          }>
        }>
        readonly variants: ReadonlyArray<{
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly internalName: String['output']
          readonly isDefaultVariant: Boolean['output']
        }>
        readonly comments: ReadonlyArray<{
          readonly __typename: 'FormTemplateComment'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly message: String['output']
          readonly isSystemGenerated: Boolean['output']
          readonly user: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
            readonly email: EmailAddress['output']
          }
        }>
        readonly originalFile: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
        } | null
        readonly duplicateTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly userVisibleName: String['output']
        } | null
      }
    } | null
    readonly unconditionalProgressVariant: {
      readonly __typename: 'FormTemplateVariant'
      readonly id: ID['output']
      readonly template: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly createdAt: DateTime['output']
        readonly type: FormTemplateType
        readonly status: FormTemplateStatus
        readonly skippedValidation: Boolean['output']
        readonly userVisibleName: String['output']
        readonly isCustomerReady: Boolean['output']
        readonly tags: ReadonlyArray<FormTemplateTag>
        readonly dueDate: DateTime['output'] | null
        readonly statusLogs: ReadonlyArray<{
          readonly __typename: 'FormTemplateStatusLog'
          readonly id: ID['output']
          readonly statusUpdatedAt: DateTime['output']
          readonly status: FormTemplateStatus
          readonly user: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
          }
        }>
        readonly associatedCompany: {
          readonly __typename: 'Company'
          readonly id: ID['output']
        } | null
        readonly requestingCompany: {
          readonly __typename: 'Company'
          readonly id: ID['output']
        } | null
        readonly associatedContracts: ReadonlyArray<{
          readonly __typename: 'FormTemplateAssociatedContract'
          readonly id: String['output']
          readonly providedAsFormType: ProjectOnboardingFormType | null
          readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
        }>
        readonly owner: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        }
        readonly builder: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        }
        readonly validator: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        } | null
        readonly versions: ReadonlyArray<{
          readonly __typename: 'FormTemplateVersion'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly updatedAt: DateTime['output']
          readonly versionNumber: Int['output']
          readonly isReadyForManualStoredMaterials: Boolean['output']
          readonly file: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          }
          readonly annotations: ReadonlyArray<{
            readonly __typename: 'FormTemplateAnnotation'
            readonly id: ID['output']
            readonly permanentId: ID['output']
            readonly syncTag: String['output'] | null
            readonly userVisibleName: String['output']
            readonly type: FormTemplateAnnotationType
            readonly xStart: Float['output']
            readonly yStart: Float['output']
            readonly width: Float['output']
            readonly height: Float['output']
            readonly pageNumber: Int['output']
            readonly fontFamily: FormTemplateFont
            readonly fontColor: String['output']
            readonly textAlignment: TextAlignment
            readonly wrapText: Boolean['output']
            readonly isOptional: Boolean['output']
            readonly selectedKey: String['output'] | null
            readonly defaultValueKey: String['output'] | null
            readonly prefix: String['output'] | null
            readonly suffix: String['output'] | null
            readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
            readonly doNotRetainOnReset: Boolean['output']
            readonly dynamicFieldTag: String['output'] | null
            readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
            readonly imageType: FormTemplateAnnotationImageType | null
            readonly signatureType: SignatureAnnotationType | null
          }>
        }>
        readonly variants: ReadonlyArray<{
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly internalName: String['output']
          readonly isDefaultVariant: Boolean['output']
        }>
        readonly comments: ReadonlyArray<{
          readonly __typename: 'FormTemplateComment'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly message: String['output']
          readonly isSystemGenerated: Boolean['output']
          readonly user: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
            readonly email: EmailAddress['output']
          }
        }>
        readonly originalFile: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
        } | null
        readonly duplicateTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly userVisibleName: String['output']
        } | null
      }
    } | null
  } | null
  readonly lowerTierLienWaiverTemplates: {
    readonly __typename: 'SubcontractorLienWaiverTemplateSet'
    readonly id: ID['output']
    readonly conditionalFinalVariant: {
      readonly __typename: 'FormTemplateVariant'
      readonly id: ID['output']
      readonly template: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly createdAt: DateTime['output']
        readonly type: FormTemplateType
        readonly status: FormTemplateStatus
        readonly skippedValidation: Boolean['output']
        readonly userVisibleName: String['output']
        readonly isCustomerReady: Boolean['output']
        readonly tags: ReadonlyArray<FormTemplateTag>
        readonly dueDate: DateTime['output'] | null
        readonly statusLogs: ReadonlyArray<{
          readonly __typename: 'FormTemplateStatusLog'
          readonly id: ID['output']
          readonly statusUpdatedAt: DateTime['output']
          readonly status: FormTemplateStatus
          readonly user: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
          }
        }>
        readonly associatedCompany: {
          readonly __typename: 'Company'
          readonly id: ID['output']
        } | null
        readonly requestingCompany: {
          readonly __typename: 'Company'
          readonly id: ID['output']
        } | null
        readonly associatedContracts: ReadonlyArray<{
          readonly __typename: 'FormTemplateAssociatedContract'
          readonly id: String['output']
          readonly providedAsFormType: ProjectOnboardingFormType | null
          readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
        }>
        readonly owner: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        }
        readonly builder: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        }
        readonly validator: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        } | null
        readonly versions: ReadonlyArray<{
          readonly __typename: 'FormTemplateVersion'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly updatedAt: DateTime['output']
          readonly versionNumber: Int['output']
          readonly isReadyForManualStoredMaterials: Boolean['output']
          readonly file: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          }
          readonly annotations: ReadonlyArray<{
            readonly __typename: 'FormTemplateAnnotation'
            readonly id: ID['output']
            readonly permanentId: ID['output']
            readonly syncTag: String['output'] | null
            readonly userVisibleName: String['output']
            readonly type: FormTemplateAnnotationType
            readonly xStart: Float['output']
            readonly yStart: Float['output']
            readonly width: Float['output']
            readonly height: Float['output']
            readonly pageNumber: Int['output']
            readonly fontFamily: FormTemplateFont
            readonly fontColor: String['output']
            readonly textAlignment: TextAlignment
            readonly wrapText: Boolean['output']
            readonly isOptional: Boolean['output']
            readonly selectedKey: String['output'] | null
            readonly defaultValueKey: String['output'] | null
            readonly prefix: String['output'] | null
            readonly suffix: String['output'] | null
            readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
            readonly doNotRetainOnReset: Boolean['output']
            readonly dynamicFieldTag: String['output'] | null
            readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
            readonly imageType: FormTemplateAnnotationImageType | null
            readonly signatureType: SignatureAnnotationType | null
          }>
        }>
        readonly variants: ReadonlyArray<{
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly internalName: String['output']
          readonly isDefaultVariant: Boolean['output']
        }>
        readonly comments: ReadonlyArray<{
          readonly __typename: 'FormTemplateComment'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly message: String['output']
          readonly isSystemGenerated: Boolean['output']
          readonly user: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
            readonly email: EmailAddress['output']
          }
        }>
        readonly originalFile: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
        } | null
        readonly duplicateTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly userVisibleName: String['output']
        } | null
      }
    } | null
    readonly conditionalProgressVariant: {
      readonly __typename: 'FormTemplateVariant'
      readonly id: ID['output']
      readonly template: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly createdAt: DateTime['output']
        readonly type: FormTemplateType
        readonly status: FormTemplateStatus
        readonly skippedValidation: Boolean['output']
        readonly userVisibleName: String['output']
        readonly isCustomerReady: Boolean['output']
        readonly tags: ReadonlyArray<FormTemplateTag>
        readonly dueDate: DateTime['output'] | null
        readonly statusLogs: ReadonlyArray<{
          readonly __typename: 'FormTemplateStatusLog'
          readonly id: ID['output']
          readonly statusUpdatedAt: DateTime['output']
          readonly status: FormTemplateStatus
          readonly user: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
          }
        }>
        readonly associatedCompany: {
          readonly __typename: 'Company'
          readonly id: ID['output']
        } | null
        readonly requestingCompany: {
          readonly __typename: 'Company'
          readonly id: ID['output']
        } | null
        readonly associatedContracts: ReadonlyArray<{
          readonly __typename: 'FormTemplateAssociatedContract'
          readonly id: String['output']
          readonly providedAsFormType: ProjectOnboardingFormType | null
          readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
        }>
        readonly owner: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        }
        readonly builder: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        }
        readonly validator: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        } | null
        readonly versions: ReadonlyArray<{
          readonly __typename: 'FormTemplateVersion'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly updatedAt: DateTime['output']
          readonly versionNumber: Int['output']
          readonly isReadyForManualStoredMaterials: Boolean['output']
          readonly file: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          }
          readonly annotations: ReadonlyArray<{
            readonly __typename: 'FormTemplateAnnotation'
            readonly id: ID['output']
            readonly permanentId: ID['output']
            readonly syncTag: String['output'] | null
            readonly userVisibleName: String['output']
            readonly type: FormTemplateAnnotationType
            readonly xStart: Float['output']
            readonly yStart: Float['output']
            readonly width: Float['output']
            readonly height: Float['output']
            readonly pageNumber: Int['output']
            readonly fontFamily: FormTemplateFont
            readonly fontColor: String['output']
            readonly textAlignment: TextAlignment
            readonly wrapText: Boolean['output']
            readonly isOptional: Boolean['output']
            readonly selectedKey: String['output'] | null
            readonly defaultValueKey: String['output'] | null
            readonly prefix: String['output'] | null
            readonly suffix: String['output'] | null
            readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
            readonly doNotRetainOnReset: Boolean['output']
            readonly dynamicFieldTag: String['output'] | null
            readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
            readonly imageType: FormTemplateAnnotationImageType | null
            readonly signatureType: SignatureAnnotationType | null
          }>
        }>
        readonly variants: ReadonlyArray<{
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly internalName: String['output']
          readonly isDefaultVariant: Boolean['output']
        }>
        readonly comments: ReadonlyArray<{
          readonly __typename: 'FormTemplateComment'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly message: String['output']
          readonly isSystemGenerated: Boolean['output']
          readonly user: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
            readonly email: EmailAddress['output']
          }
        }>
        readonly originalFile: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
        } | null
        readonly duplicateTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly userVisibleName: String['output']
        } | null
      }
    } | null
    readonly unconditionalFinalVariant: {
      readonly __typename: 'FormTemplateVariant'
      readonly id: ID['output']
      readonly template: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly createdAt: DateTime['output']
        readonly type: FormTemplateType
        readonly status: FormTemplateStatus
        readonly skippedValidation: Boolean['output']
        readonly userVisibleName: String['output']
        readonly isCustomerReady: Boolean['output']
        readonly tags: ReadonlyArray<FormTemplateTag>
        readonly dueDate: DateTime['output'] | null
        readonly statusLogs: ReadonlyArray<{
          readonly __typename: 'FormTemplateStatusLog'
          readonly id: ID['output']
          readonly statusUpdatedAt: DateTime['output']
          readonly status: FormTemplateStatus
          readonly user: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
          }
        }>
        readonly associatedCompany: {
          readonly __typename: 'Company'
          readonly id: ID['output']
        } | null
        readonly requestingCompany: {
          readonly __typename: 'Company'
          readonly id: ID['output']
        } | null
        readonly associatedContracts: ReadonlyArray<{
          readonly __typename: 'FormTemplateAssociatedContract'
          readonly id: String['output']
          readonly providedAsFormType: ProjectOnboardingFormType | null
          readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
        }>
        readonly owner: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        }
        readonly builder: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        }
        readonly validator: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        } | null
        readonly versions: ReadonlyArray<{
          readonly __typename: 'FormTemplateVersion'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly updatedAt: DateTime['output']
          readonly versionNumber: Int['output']
          readonly isReadyForManualStoredMaterials: Boolean['output']
          readonly file: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          }
          readonly annotations: ReadonlyArray<{
            readonly __typename: 'FormTemplateAnnotation'
            readonly id: ID['output']
            readonly permanentId: ID['output']
            readonly syncTag: String['output'] | null
            readonly userVisibleName: String['output']
            readonly type: FormTemplateAnnotationType
            readonly xStart: Float['output']
            readonly yStart: Float['output']
            readonly width: Float['output']
            readonly height: Float['output']
            readonly pageNumber: Int['output']
            readonly fontFamily: FormTemplateFont
            readonly fontColor: String['output']
            readonly textAlignment: TextAlignment
            readonly wrapText: Boolean['output']
            readonly isOptional: Boolean['output']
            readonly selectedKey: String['output'] | null
            readonly defaultValueKey: String['output'] | null
            readonly prefix: String['output'] | null
            readonly suffix: String['output'] | null
            readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
            readonly doNotRetainOnReset: Boolean['output']
            readonly dynamicFieldTag: String['output'] | null
            readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
            readonly imageType: FormTemplateAnnotationImageType | null
            readonly signatureType: SignatureAnnotationType | null
          }>
        }>
        readonly variants: ReadonlyArray<{
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly internalName: String['output']
          readonly isDefaultVariant: Boolean['output']
        }>
        readonly comments: ReadonlyArray<{
          readonly __typename: 'FormTemplateComment'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly message: String['output']
          readonly isSystemGenerated: Boolean['output']
          readonly user: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
            readonly email: EmailAddress['output']
          }
        }>
        readonly originalFile: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
        } | null
        readonly duplicateTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly userVisibleName: String['output']
        } | null
      }
    } | null
    readonly unconditionalProgressVariant: {
      readonly __typename: 'FormTemplateVariant'
      readonly id: ID['output']
      readonly template: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly createdAt: DateTime['output']
        readonly type: FormTemplateType
        readonly status: FormTemplateStatus
        readonly skippedValidation: Boolean['output']
        readonly userVisibleName: String['output']
        readonly isCustomerReady: Boolean['output']
        readonly tags: ReadonlyArray<FormTemplateTag>
        readonly dueDate: DateTime['output'] | null
        readonly statusLogs: ReadonlyArray<{
          readonly __typename: 'FormTemplateStatusLog'
          readonly id: ID['output']
          readonly statusUpdatedAt: DateTime['output']
          readonly status: FormTemplateStatus
          readonly user: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
          }
        }>
        readonly associatedCompany: {
          readonly __typename: 'Company'
          readonly id: ID['output']
        } | null
        readonly requestingCompany: {
          readonly __typename: 'Company'
          readonly id: ID['output']
        } | null
        readonly associatedContracts: ReadonlyArray<{
          readonly __typename: 'FormTemplateAssociatedContract'
          readonly id: String['output']
          readonly providedAsFormType: ProjectOnboardingFormType | null
          readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
        }>
        readonly owner: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        }
        readonly builder: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        }
        readonly validator: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        } | null
        readonly versions: ReadonlyArray<{
          readonly __typename: 'FormTemplateVersion'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly updatedAt: DateTime['output']
          readonly versionNumber: Int['output']
          readonly isReadyForManualStoredMaterials: Boolean['output']
          readonly file: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          }
          readonly annotations: ReadonlyArray<{
            readonly __typename: 'FormTemplateAnnotation'
            readonly id: ID['output']
            readonly permanentId: ID['output']
            readonly syncTag: String['output'] | null
            readonly userVisibleName: String['output']
            readonly type: FormTemplateAnnotationType
            readonly xStart: Float['output']
            readonly yStart: Float['output']
            readonly width: Float['output']
            readonly height: Float['output']
            readonly pageNumber: Int['output']
            readonly fontFamily: FormTemplateFont
            readonly fontColor: String['output']
            readonly textAlignment: TextAlignment
            readonly wrapText: Boolean['output']
            readonly isOptional: Boolean['output']
            readonly selectedKey: String['output'] | null
            readonly defaultValueKey: String['output'] | null
            readonly prefix: String['output'] | null
            readonly suffix: String['output'] | null
            readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
            readonly doNotRetainOnReset: Boolean['output']
            readonly dynamicFieldTag: String['output'] | null
            readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
            readonly imageType: FormTemplateAnnotationImageType | null
            readonly signatureType: SignatureAnnotationType | null
          }>
        }>
        readonly variants: ReadonlyArray<{
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly internalName: String['output']
          readonly isDefaultVariant: Boolean['output']
        }>
        readonly comments: ReadonlyArray<{
          readonly __typename: 'FormTemplateComment'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly message: String['output']
          readonly isSystemGenerated: Boolean['output']
          readonly user: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
            readonly email: EmailAddress['output']
          }
        }>
        readonly originalFile: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
        } | null
        readonly duplicateTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly userVisibleName: String['output']
        } | null
      }
    } | null
  } | null
  readonly payAppRequirementGroups: ReadonlyArray<{
    readonly __typename: 'PayAppRequirementGroup'
    readonly id: ID['output']
    readonly order: Int['output']
    readonly payAppRequirements: ReadonlyArray<{
      readonly __typename: 'PayAppRequirement'
      readonly id: ID['output']
      readonly groupOrder: Int['output']
      readonly conditions: ReadonlyArray<PayAppRequirementCondition>
      readonly templateVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly internalName: String['output']
        readonly userVisibleName: String['output'] | null
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
        }
      } | null
    }>
  }>
  readonly legalRequirements: ReadonlyArray<{
    readonly __typename: 'LegalRequirement'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly name: String['output']
    readonly timeZone: String['output']
    readonly expirationFrequency: DocumentExpirationFrequency
    readonly startDate: DateTime['output'] | null
    readonly endDate: DateTime['output'] | null
    readonly isVendorRequirement: Boolean['output']
    readonly documents: ReadonlyArray<{
      readonly __typename: 'LegalDocument'
      readonly id: ID['output']
    }>
    readonly formTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
    } | null
  }>
  readonly selectedAddress: {
    readonly __typename: 'Location'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly nickname: String['output'] | null
    readonly street1: String['output'] | null
    readonly street2: String['output'] | null
    readonly city: String['output']
    readonly county: String['output'] | null
    readonly state: String['output']
    readonly country: String['output']
    readonly postalCode: String['output'] | null
    readonly timeZone: String['output']
    readonly coordinates: LatLng['output']
  }
  readonly changeOrderRequestTemplate: {
    readonly __typename: 'FormTemplate'
    readonly id: String['output']
  } | null
  readonly changeOrderLogTemplate: {
    readonly __typename: 'FormTemplate'
    readonly id: String['output']
  } | null
  readonly integrations: ReadonlyArray<{
    readonly __typename: 'Integration'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly type: IntegrationType
    readonly credentialsUpdatedAt: DateTime['output'] | null
    readonly mappings: JSONObject['output']
    readonly isActive: Boolean['output']
    readonly shortName: String['output']
    readonly longName: String['output']
    readonly companyIntegration: {
      readonly __typename: 'CompanyIntegration'
      readonly id: ID['output']
    }
  }>
  readonly onboardedStatus: {
    readonly __typename: 'OnboardedProjectContractStatus'
    readonly selectedPayAppForms: Boolean['output']
    readonly onboardedPayAppForms: Boolean['output']
    readonly selectedPrimaryLienWaivers: Boolean['output']
    readonly onboardedPrimaryLienWaiverForms: Boolean['output']
    readonly selectedVendorLienWaivers: Boolean['output']
    readonly onboardedVendorLienWaiverForms: Boolean['output']
    readonly selectedChangeOrderRequestForms: Boolean['output']
    readonly onboardedChangeOrderRequestForms: Boolean['output']
    readonly addedSov: Boolean['output']
    readonly selectedRateTable: Boolean['output']
    readonly addedTeammates: Boolean['output']
    readonly addedGcContacts: Boolean['output']
    readonly startedBilling: Boolean['output']
    readonly notifiedOnboardedForms: Boolean['output']
  }
}

export type ProjectForDetailsProperties = {
  readonly __typename: 'Project'
  readonly id: ID['output']
  readonly name: String['output']
  readonly projectNumber: String['output']
  readonly location: {
    readonly __typename: 'Location'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly nickname: String['output'] | null
    readonly street1: String['output'] | null
    readonly street2: String['output'] | null
    readonly city: String['output']
    readonly county: String['output'] | null
    readonly state: String['output']
    readonly country: String['output']
    readonly postalCode: String['output'] | null
    readonly timeZone: String['output']
    readonly coordinates: LatLng['output']
  }
  readonly owner: {
    readonly __typename: 'CompanyProjectMetadata'
    readonly role: CompanyProjectRole
    readonly companyName: String['output'] | null
    readonly company: {
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
      readonly nickname: String['output'] | null
      readonly type: CompanyType
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly expectedNumberOfProjects: SafeInt['output'] | null
      readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
      readonly allowLumpSumOverbilling: Boolean['output']
      readonly notaryTags: ReadonlyArray<NotaryTag>
      readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
      readonly isDemoCompany: Boolean['output']
      readonly users: ReadonlyArray<{
        readonly __typename: 'CompanyUser'
        readonly id: ID['output']
        readonly role: CompanyUserRole
        readonly user: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
          readonly email: EmailAddress['output']
          readonly jobTitle: String['output'] | null
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly companies: ReadonlyArray<{
            readonly __typename: 'Company'
            readonly id: ID['output']
            readonly name: String['output']
          }>
        }
      }>
      readonly picture: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly notaryStamp: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly notarySignature: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly secondarySignature: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly locations: ReadonlyArray<{
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }>
      readonly metadata: {
        readonly __typename: 'CompanyMetadata'
        readonly subtrade: String['output'] | null
        readonly domains: ReadonlyArray<String['output']>
        readonly domainSignup: Boolean['output']
        readonly monthlyCreatePayAppReminderDate: Int['output'] | null
      }
      readonly defaultQuickBillingTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
      } | null
      readonly sitelinePointOfContact: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
      } | null
    }
    readonly selectedAddress: {
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    } | null
  } | null
  readonly generalContractor: {
    readonly __typename: 'CompanyProjectMetadata'
    readonly role: CompanyProjectRole
    readonly companyName: String['output'] | null
    readonly company: {
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
      readonly nickname: String['output'] | null
      readonly type: CompanyType
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly expectedNumberOfProjects: SafeInt['output'] | null
      readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
      readonly allowLumpSumOverbilling: Boolean['output']
      readonly notaryTags: ReadonlyArray<NotaryTag>
      readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
      readonly isDemoCompany: Boolean['output']
      readonly users: ReadonlyArray<{
        readonly __typename: 'CompanyUser'
        readonly id: ID['output']
        readonly role: CompanyUserRole
        readonly user: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
          readonly email: EmailAddress['output']
          readonly jobTitle: String['output'] | null
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly companies: ReadonlyArray<{
            readonly __typename: 'Company'
            readonly id: ID['output']
            readonly name: String['output']
          }>
        }
      }>
      readonly picture: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly notaryStamp: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly notarySignature: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly secondarySignature: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly locations: ReadonlyArray<{
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }>
      readonly metadata: {
        readonly __typename: 'CompanyMetadata'
        readonly subtrade: String['output'] | null
        readonly domains: ReadonlyArray<String['output']>
        readonly domainSignup: Boolean['output']
        readonly monthlyCreatePayAppReminderDate: Int['output'] | null
      }
      readonly defaultQuickBillingTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
      } | null
      readonly sitelinePointOfContact: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
      } | null
    }
    readonly selectedAddress: {
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    } | null
  } | null
  readonly architect: {
    readonly __typename: 'CompanyProjectMetadata'
    readonly role: CompanyProjectRole
    readonly companyName: String['output'] | null
    readonly company: {
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
      readonly nickname: String['output'] | null
      readonly type: CompanyType
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly expectedNumberOfProjects: SafeInt['output'] | null
      readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
      readonly allowLumpSumOverbilling: Boolean['output']
      readonly notaryTags: ReadonlyArray<NotaryTag>
      readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
      readonly isDemoCompany: Boolean['output']
      readonly picture: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly notaryStamp: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly notarySignature: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly secondarySignature: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly locations: ReadonlyArray<{
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }>
      readonly metadata: {
        readonly __typename: 'CompanyMetadata'
        readonly subtrade: String['output'] | null
        readonly domains: ReadonlyArray<String['output']>
        readonly domainSignup: Boolean['output']
        readonly monthlyCreatePayAppReminderDate: Int['output'] | null
      }
      readonly defaultQuickBillingTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
      } | null
      readonly sitelinePointOfContact: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
      } | null
    }
    readonly selectedAddress: {
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    } | null
  } | null
  readonly contracts: ReadonlyArray<{
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly role: CompanyProjectRole
    readonly status: ContractStatus
    readonly timeZone: String['output']
    readonly contractDate: DateTime['output'] | null
    readonly contractNumber: String['output'] | null
    readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
    readonly defaultRetentionPercent: Float['output'] | null
    readonly retentionTrackingLevel: RetentionTrackingLevel
    readonly daysBeforePayAppDue: Int['output']
    readonly billingType: BillingType
    readonly contractTemplateVariables: JSONObject['output']
    readonly internalProjectNumber: String['output'] | null
    readonly vendorNumber: String['output'] | null
    readonly pastPayAppCount: Int['output']
    readonly roundRetention: Boolean['output']
    readonly company: {
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
      readonly nickname: String['output'] | null
      readonly type: CompanyType
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly expectedNumberOfProjects: SafeInt['output'] | null
      readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
      readonly allowLumpSumOverbilling: Boolean['output']
      readonly notaryTags: ReadonlyArray<NotaryTag>
      readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
      readonly isDemoCompany: Boolean['output']
      readonly users: ReadonlyArray<{
        readonly __typename: 'CompanyUser'
        readonly id: ID['output']
        readonly role: CompanyUserRole
        readonly user: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
          readonly email: EmailAddress['output']
          readonly jobTitle: String['output'] | null
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly companies: ReadonlyArray<{
            readonly __typename: 'Company'
            readonly id: ID['output']
            readonly name: String['output']
          }>
        }
      }>
      readonly picture: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly notaryStamp: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly notarySignature: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly secondarySignature: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly locations: ReadonlyArray<{
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }>
      readonly metadata: {
        readonly __typename: 'CompanyMetadata'
        readonly subtrade: String['output'] | null
        readonly domains: ReadonlyArray<String['output']>
        readonly domainSignup: Boolean['output']
        readonly monthlyCreatePayAppReminderDate: Int['output'] | null
      }
      readonly defaultQuickBillingTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
      } | null
      readonly sitelinePointOfContact: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
      } | null
    }
    readonly users: ReadonlyArray<{
      readonly __typename: 'ContractUser'
      readonly id: ID['output']
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
        readonly jobTitle: String['output'] | null
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly companies: ReadonlyArray<{
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        }>
      }
    }>
    readonly project: {
      readonly __typename: 'Project'
      readonly id: ID['output']
      readonly name: String['output']
      readonly projectNumber: String['output']
      readonly location: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }
      readonly generalContractor: {
        readonly __typename: 'CompanyProjectMetadata'
        readonly role: CompanyProjectRole
        readonly companyName: String['output'] | null
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
          readonly nickname: String['output'] | null
          readonly type: CompanyType
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly expectedNumberOfProjects: SafeInt['output'] | null
          readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
          readonly allowLumpSumOverbilling: Boolean['output']
          readonly notaryTags: ReadonlyArray<NotaryTag>
          readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
          readonly isDemoCompany: Boolean['output']
          readonly picture: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly notaryStamp: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly notarySignature: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly secondarySignature: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly locations: ReadonlyArray<{
            readonly __typename: 'Location'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly nickname: String['output'] | null
            readonly street1: String['output'] | null
            readonly street2: String['output'] | null
            readonly city: String['output']
            readonly county: String['output'] | null
            readonly state: String['output']
            readonly country: String['output']
            readonly postalCode: String['output'] | null
            readonly timeZone: String['output']
            readonly coordinates: LatLng['output']
          }>
          readonly metadata: {
            readonly __typename: 'CompanyMetadata'
            readonly subtrade: String['output'] | null
            readonly domains: ReadonlyArray<String['output']>
            readonly domainSignup: Boolean['output']
            readonly monthlyCreatePayAppReminderDate: Int['output'] | null
          }
          readonly defaultQuickBillingTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
          } | null
          readonly sitelinePointOfContact: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
          } | null
        }
        readonly selectedAddress: {
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        } | null
      } | null
    }
    readonly sov: {
      readonly __typename: 'Sov'
      readonly id: ID['output']
      readonly totalValue: SafeInt['output']
      readonly totalBilled: SafeInt['output']
      readonly totalRetention: SafeInt['output']
      readonly progressComplete: Float['output']
      readonly contract: {
        readonly __typename: 'Contract'
        readonly id: ID['output']
        readonly timeZone: String['output']
      }
      readonly lineItems: ReadonlyArray<{
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
        readonly code: String['output']
        readonly sortOrder: SafeInt['output']
        readonly name: String['output']
        readonly isChangeOrder: Boolean['output']
        readonly changeOrderApprovedDate: DateTime['output'] | null
        readonly latestTotalValue: SafeInt['output']
        readonly totalBilled: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly previousStoredMaterials: SafeInt['output']
        readonly totalRetention: SafeInt['output']
        readonly progressComplete: Float['output']
        readonly defaultRetentionPercent: Float['output'] | null
        readonly sovLineItemGroup: {
          readonly __typename: 'SovLineItemGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly code: String['output'] | null
        } | null
      }>
    } | null
    readonly lienWaiverTemplates: {
      readonly __typename: 'SubcontractorLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly conditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly conditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
    } | null
    readonly lowerTierLienWaiverTemplates: {
      readonly __typename: 'SubcontractorLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly conditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly conditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
    } | null
    readonly payAppRequirementGroups: ReadonlyArray<{
      readonly __typename: 'PayAppRequirementGroup'
      readonly id: ID['output']
      readonly order: Int['output']
      readonly payAppRequirements: ReadonlyArray<{
        readonly __typename: 'PayAppRequirement'
        readonly id: ID['output']
        readonly groupOrder: Int['output']
        readonly conditions: ReadonlyArray<PayAppRequirementCondition>
        readonly templateVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly internalName: String['output']
          readonly userVisibleName: String['output'] | null
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly isCustomerReady: Boolean['output']
          }
        } | null
      }>
    }>
    readonly legalRequirements: ReadonlyArray<{
      readonly __typename: 'LegalRequirement'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly name: String['output']
      readonly timeZone: String['output']
      readonly expirationFrequency: DocumentExpirationFrequency
      readonly startDate: DateTime['output'] | null
      readonly endDate: DateTime['output'] | null
      readonly isVendorRequirement: Boolean['output']
      readonly documents: ReadonlyArray<{
        readonly __typename: 'LegalDocument'
        readonly id: ID['output']
      }>
      readonly formTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
      } | null
    }>
    readonly selectedAddress: {
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    }
    readonly changeOrderRequestTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
    } | null
    readonly changeOrderLogTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
    } | null
    readonly integrations: ReadonlyArray<{
      readonly __typename: 'Integration'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly type: IntegrationType
      readonly credentialsUpdatedAt: DateTime['output'] | null
      readonly mappings: JSONObject['output']
      readonly isActive: Boolean['output']
      readonly shortName: String['output']
      readonly longName: String['output']
      readonly companyIntegration: {
        readonly __typename: 'CompanyIntegration'
        readonly id: ID['output']
      }
    }>
    readonly onboardedStatus: {
      readonly __typename: 'OnboardedProjectContractStatus'
      readonly selectedPayAppForms: Boolean['output']
      readonly onboardedPayAppForms: Boolean['output']
      readonly selectedPrimaryLienWaivers: Boolean['output']
      readonly onboardedPrimaryLienWaiverForms: Boolean['output']
      readonly selectedVendorLienWaivers: Boolean['output']
      readonly onboardedVendorLienWaiverForms: Boolean['output']
      readonly selectedChangeOrderRequestForms: Boolean['output']
      readonly onboardedChangeOrderRequestForms: Boolean['output']
      readonly addedSov: Boolean['output']
      readonly selectedRateTable: Boolean['output']
      readonly addedTeammates: Boolean['output']
      readonly addedGcContacts: Boolean['output']
      readonly startedBilling: Boolean['output']
      readonly notifiedOnboardedForms: Boolean['output']
    }
  }>
  readonly metadata: {
    readonly __typename: 'ProjectMetadata'
    readonly fieldProgressDueOnDayOfMonth: Int['output']
    readonly payAppDueOnDayOfMonth: Int['output']
  }
}

export type CompanyContactProperties = {
  readonly __typename: 'CompanyContact'
  readonly id: ID['output']
  readonly fullName: String['output']
  readonly email: EmailAddress['output']
  readonly companyName: String['output']
  readonly archivedAt: DateTime['output'] | null
}

export type VendorProperties = {
  readonly __typename: 'Vendor'
  readonly id: ID['output']
  readonly name: String['output']
  readonly contacts: ReadonlyArray<{
    readonly __typename: 'VendorContact'
    readonly id: ID['output']
    readonly fullName: String['output']
    readonly email: EmailAddress['output']
    readonly archivedAt: DateTime['output'] | null
  }>
}

export type CompanyPayAppFormTemplateSetProperties = {
  readonly __typename: 'CompanyPayAppFormTemplateSet'
  readonly id: ID['output']
  readonly name: String['output']
  readonly state: String['output'] | null
  readonly billingType: BillingType
  readonly generalContractor: {
    readonly __typename: 'Company'
    readonly id: ID['output']
    readonly name: String['output']
  } | null
  readonly payAppRequirementGroups: ReadonlyArray<{
    readonly __typename: 'PayAppRequirementGroup'
    readonly id: ID['output']
    readonly order: Int['output']
    readonly payAppRequirements: ReadonlyArray<{
      readonly __typename: 'PayAppRequirement'
      readonly id: ID['output']
      readonly groupOrder: Int['output']
      readonly conditions: ReadonlyArray<PayAppRequirementCondition>
      readonly templateVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly internalName: String['output']
        readonly userVisibleName: String['output'] | null
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
        }
      } | null
    }>
  }>
}

export type CompanyLienWaiverTemplateSetProperties = {
  readonly __typename: 'CompanyLienWaiverTemplateSet'
  readonly id: ID['output']
  readonly name: String['output']
  readonly state: String['output'] | null
  readonly generalContractor: {
    readonly __typename: 'Company'
    readonly id: ID['output']
    readonly name: String['output']
  } | null
  readonly lienWaivers: {
    readonly __typename: 'SubcontractorLienWaiverTemplateSet'
    readonly id: ID['output']
    readonly conditionalFinalVariant: {
      readonly __typename: 'FormTemplateVariant'
      readonly id: ID['output']
      readonly template: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly createdAt: DateTime['output']
        readonly type: FormTemplateType
        readonly status: FormTemplateStatus
        readonly skippedValidation: Boolean['output']
        readonly userVisibleName: String['output']
        readonly isCustomerReady: Boolean['output']
        readonly tags: ReadonlyArray<FormTemplateTag>
        readonly dueDate: DateTime['output'] | null
        readonly statusLogs: ReadonlyArray<{
          readonly __typename: 'FormTemplateStatusLog'
          readonly id: ID['output']
          readonly statusUpdatedAt: DateTime['output']
          readonly status: FormTemplateStatus
          readonly user: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
          }
        }>
        readonly associatedCompany: {
          readonly __typename: 'Company'
          readonly id: ID['output']
        } | null
        readonly requestingCompany: {
          readonly __typename: 'Company'
          readonly id: ID['output']
        } | null
        readonly associatedContracts: ReadonlyArray<{
          readonly __typename: 'FormTemplateAssociatedContract'
          readonly id: String['output']
          readonly providedAsFormType: ProjectOnboardingFormType | null
          readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
        }>
        readonly owner: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        }
        readonly builder: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        }
        readonly validator: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        } | null
        readonly versions: ReadonlyArray<{
          readonly __typename: 'FormTemplateVersion'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly updatedAt: DateTime['output']
          readonly versionNumber: Int['output']
          readonly isReadyForManualStoredMaterials: Boolean['output']
          readonly file: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          }
          readonly annotations: ReadonlyArray<{
            readonly __typename: 'FormTemplateAnnotation'
            readonly id: ID['output']
            readonly permanentId: ID['output']
            readonly syncTag: String['output'] | null
            readonly userVisibleName: String['output']
            readonly type: FormTemplateAnnotationType
            readonly xStart: Float['output']
            readonly yStart: Float['output']
            readonly width: Float['output']
            readonly height: Float['output']
            readonly pageNumber: Int['output']
            readonly fontFamily: FormTemplateFont
            readonly fontColor: String['output']
            readonly textAlignment: TextAlignment
            readonly wrapText: Boolean['output']
            readonly isOptional: Boolean['output']
            readonly selectedKey: String['output'] | null
            readonly defaultValueKey: String['output'] | null
            readonly prefix: String['output'] | null
            readonly suffix: String['output'] | null
            readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
            readonly doNotRetainOnReset: Boolean['output']
            readonly dynamicFieldTag: String['output'] | null
            readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
            readonly imageType: FormTemplateAnnotationImageType | null
            readonly signatureType: SignatureAnnotationType | null
          }>
        }>
        readonly variants: ReadonlyArray<{
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly internalName: String['output']
          readonly isDefaultVariant: Boolean['output']
        }>
        readonly comments: ReadonlyArray<{
          readonly __typename: 'FormTemplateComment'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly message: String['output']
          readonly isSystemGenerated: Boolean['output']
          readonly user: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
            readonly email: EmailAddress['output']
          }
        }>
        readonly originalFile: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
        } | null
        readonly duplicateTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly userVisibleName: String['output']
        } | null
      }
    } | null
    readonly conditionalProgressVariant: {
      readonly __typename: 'FormTemplateVariant'
      readonly id: ID['output']
      readonly template: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly createdAt: DateTime['output']
        readonly type: FormTemplateType
        readonly status: FormTemplateStatus
        readonly skippedValidation: Boolean['output']
        readonly userVisibleName: String['output']
        readonly isCustomerReady: Boolean['output']
        readonly tags: ReadonlyArray<FormTemplateTag>
        readonly dueDate: DateTime['output'] | null
        readonly statusLogs: ReadonlyArray<{
          readonly __typename: 'FormTemplateStatusLog'
          readonly id: ID['output']
          readonly statusUpdatedAt: DateTime['output']
          readonly status: FormTemplateStatus
          readonly user: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
          }
        }>
        readonly associatedCompany: {
          readonly __typename: 'Company'
          readonly id: ID['output']
        } | null
        readonly requestingCompany: {
          readonly __typename: 'Company'
          readonly id: ID['output']
        } | null
        readonly associatedContracts: ReadonlyArray<{
          readonly __typename: 'FormTemplateAssociatedContract'
          readonly id: String['output']
          readonly providedAsFormType: ProjectOnboardingFormType | null
          readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
        }>
        readonly owner: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        }
        readonly builder: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        }
        readonly validator: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        } | null
        readonly versions: ReadonlyArray<{
          readonly __typename: 'FormTemplateVersion'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly updatedAt: DateTime['output']
          readonly versionNumber: Int['output']
          readonly isReadyForManualStoredMaterials: Boolean['output']
          readonly file: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          }
          readonly annotations: ReadonlyArray<{
            readonly __typename: 'FormTemplateAnnotation'
            readonly id: ID['output']
            readonly permanentId: ID['output']
            readonly syncTag: String['output'] | null
            readonly userVisibleName: String['output']
            readonly type: FormTemplateAnnotationType
            readonly xStart: Float['output']
            readonly yStart: Float['output']
            readonly width: Float['output']
            readonly height: Float['output']
            readonly pageNumber: Int['output']
            readonly fontFamily: FormTemplateFont
            readonly fontColor: String['output']
            readonly textAlignment: TextAlignment
            readonly wrapText: Boolean['output']
            readonly isOptional: Boolean['output']
            readonly selectedKey: String['output'] | null
            readonly defaultValueKey: String['output'] | null
            readonly prefix: String['output'] | null
            readonly suffix: String['output'] | null
            readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
            readonly doNotRetainOnReset: Boolean['output']
            readonly dynamicFieldTag: String['output'] | null
            readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
            readonly imageType: FormTemplateAnnotationImageType | null
            readonly signatureType: SignatureAnnotationType | null
          }>
        }>
        readonly variants: ReadonlyArray<{
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly internalName: String['output']
          readonly isDefaultVariant: Boolean['output']
        }>
        readonly comments: ReadonlyArray<{
          readonly __typename: 'FormTemplateComment'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly message: String['output']
          readonly isSystemGenerated: Boolean['output']
          readonly user: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
            readonly email: EmailAddress['output']
          }
        }>
        readonly originalFile: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
        } | null
        readonly duplicateTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly userVisibleName: String['output']
        } | null
      }
    } | null
    readonly unconditionalFinalVariant: {
      readonly __typename: 'FormTemplateVariant'
      readonly id: ID['output']
      readonly template: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly createdAt: DateTime['output']
        readonly type: FormTemplateType
        readonly status: FormTemplateStatus
        readonly skippedValidation: Boolean['output']
        readonly userVisibleName: String['output']
        readonly isCustomerReady: Boolean['output']
        readonly tags: ReadonlyArray<FormTemplateTag>
        readonly dueDate: DateTime['output'] | null
        readonly statusLogs: ReadonlyArray<{
          readonly __typename: 'FormTemplateStatusLog'
          readonly id: ID['output']
          readonly statusUpdatedAt: DateTime['output']
          readonly status: FormTemplateStatus
          readonly user: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
          }
        }>
        readonly associatedCompany: {
          readonly __typename: 'Company'
          readonly id: ID['output']
        } | null
        readonly requestingCompany: {
          readonly __typename: 'Company'
          readonly id: ID['output']
        } | null
        readonly associatedContracts: ReadonlyArray<{
          readonly __typename: 'FormTemplateAssociatedContract'
          readonly id: String['output']
          readonly providedAsFormType: ProjectOnboardingFormType | null
          readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
        }>
        readonly owner: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        }
        readonly builder: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        }
        readonly validator: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        } | null
        readonly versions: ReadonlyArray<{
          readonly __typename: 'FormTemplateVersion'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly updatedAt: DateTime['output']
          readonly versionNumber: Int['output']
          readonly isReadyForManualStoredMaterials: Boolean['output']
          readonly file: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          }
          readonly annotations: ReadonlyArray<{
            readonly __typename: 'FormTemplateAnnotation'
            readonly id: ID['output']
            readonly permanentId: ID['output']
            readonly syncTag: String['output'] | null
            readonly userVisibleName: String['output']
            readonly type: FormTemplateAnnotationType
            readonly xStart: Float['output']
            readonly yStart: Float['output']
            readonly width: Float['output']
            readonly height: Float['output']
            readonly pageNumber: Int['output']
            readonly fontFamily: FormTemplateFont
            readonly fontColor: String['output']
            readonly textAlignment: TextAlignment
            readonly wrapText: Boolean['output']
            readonly isOptional: Boolean['output']
            readonly selectedKey: String['output'] | null
            readonly defaultValueKey: String['output'] | null
            readonly prefix: String['output'] | null
            readonly suffix: String['output'] | null
            readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
            readonly doNotRetainOnReset: Boolean['output']
            readonly dynamicFieldTag: String['output'] | null
            readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
            readonly imageType: FormTemplateAnnotationImageType | null
            readonly signatureType: SignatureAnnotationType | null
          }>
        }>
        readonly variants: ReadonlyArray<{
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly internalName: String['output']
          readonly isDefaultVariant: Boolean['output']
        }>
        readonly comments: ReadonlyArray<{
          readonly __typename: 'FormTemplateComment'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly message: String['output']
          readonly isSystemGenerated: Boolean['output']
          readonly user: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
            readonly email: EmailAddress['output']
          }
        }>
        readonly originalFile: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
        } | null
        readonly duplicateTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly userVisibleName: String['output']
        } | null
      }
    } | null
    readonly unconditionalProgressVariant: {
      readonly __typename: 'FormTemplateVariant'
      readonly id: ID['output']
      readonly template: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly createdAt: DateTime['output']
        readonly type: FormTemplateType
        readonly status: FormTemplateStatus
        readonly skippedValidation: Boolean['output']
        readonly userVisibleName: String['output']
        readonly isCustomerReady: Boolean['output']
        readonly tags: ReadonlyArray<FormTemplateTag>
        readonly dueDate: DateTime['output'] | null
        readonly statusLogs: ReadonlyArray<{
          readonly __typename: 'FormTemplateStatusLog'
          readonly id: ID['output']
          readonly statusUpdatedAt: DateTime['output']
          readonly status: FormTemplateStatus
          readonly user: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
          }
        }>
        readonly associatedCompany: {
          readonly __typename: 'Company'
          readonly id: ID['output']
        } | null
        readonly requestingCompany: {
          readonly __typename: 'Company'
          readonly id: ID['output']
        } | null
        readonly associatedContracts: ReadonlyArray<{
          readonly __typename: 'FormTemplateAssociatedContract'
          readonly id: String['output']
          readonly providedAsFormType: ProjectOnboardingFormType | null
          readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
        }>
        readonly owner: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        }
        readonly builder: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        }
        readonly validator: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        } | null
        readonly versions: ReadonlyArray<{
          readonly __typename: 'FormTemplateVersion'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly updatedAt: DateTime['output']
          readonly versionNumber: Int['output']
          readonly isReadyForManualStoredMaterials: Boolean['output']
          readonly file: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          }
          readonly annotations: ReadonlyArray<{
            readonly __typename: 'FormTemplateAnnotation'
            readonly id: ID['output']
            readonly permanentId: ID['output']
            readonly syncTag: String['output'] | null
            readonly userVisibleName: String['output']
            readonly type: FormTemplateAnnotationType
            readonly xStart: Float['output']
            readonly yStart: Float['output']
            readonly width: Float['output']
            readonly height: Float['output']
            readonly pageNumber: Int['output']
            readonly fontFamily: FormTemplateFont
            readonly fontColor: String['output']
            readonly textAlignment: TextAlignment
            readonly wrapText: Boolean['output']
            readonly isOptional: Boolean['output']
            readonly selectedKey: String['output'] | null
            readonly defaultValueKey: String['output'] | null
            readonly prefix: String['output'] | null
            readonly suffix: String['output'] | null
            readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
            readonly doNotRetainOnReset: Boolean['output']
            readonly dynamicFieldTag: String['output'] | null
            readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
            readonly imageType: FormTemplateAnnotationImageType | null
            readonly signatureType: SignatureAnnotationType | null
          }>
        }>
        readonly variants: ReadonlyArray<{
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly internalName: String['output']
          readonly isDefaultVariant: Boolean['output']
        }>
        readonly comments: ReadonlyArray<{
          readonly __typename: 'FormTemplateComment'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly message: String['output']
          readonly isSystemGenerated: Boolean['output']
          readonly user: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
            readonly email: EmailAddress['output']
          }
        }>
        readonly originalFile: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
        } | null
        readonly duplicateTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly userVisibleName: String['output']
        } | null
      }
    } | null
  } | null
}

export type CompanyFormTemplateCollectionProperties = {
  readonly __typename: 'CompanyFormTemplateCollection'
  readonly id: ID['output']
  readonly name: String['output']
  readonly state: String['output'] | null
  readonly billingType: BillingType
  readonly generalContractor: {
    readonly __typename: 'Company'
    readonly id: ID['output']
    readonly name: String['output']
  } | null
  readonly payAppForms: {
    readonly __typename: 'CompanyPayAppFormTemplateSet'
    readonly id: ID['output']
    readonly name: String['output']
    readonly state: String['output'] | null
    readonly billingType: BillingType
    readonly generalContractor: {
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
    } | null
    readonly payAppRequirementGroups: ReadonlyArray<{
      readonly __typename: 'PayAppRequirementGroup'
      readonly id: ID['output']
      readonly order: Int['output']
      readonly payAppRequirements: ReadonlyArray<{
        readonly __typename: 'PayAppRequirement'
        readonly id: ID['output']
        readonly groupOrder: Int['output']
        readonly conditions: ReadonlyArray<PayAppRequirementCondition>
        readonly templateVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly internalName: String['output']
          readonly userVisibleName: String['output'] | null
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly isCustomerReady: Boolean['output']
          }
        } | null
      }>
    }>
  } | null
  readonly primaryLienWaivers: {
    readonly __typename: 'CompanyLienWaiverTemplateSet'
    readonly id: ID['output']
    readonly name: String['output']
    readonly state: String['output'] | null
    readonly generalContractor: {
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
    } | null
    readonly lienWaivers: {
      readonly __typename: 'SubcontractorLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly conditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly conditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
    } | null
  } | null
  readonly vendorLienWaivers: {
    readonly __typename: 'CompanyLienWaiverTemplateSet'
    readonly id: ID['output']
    readonly name: String['output']
    readonly state: String['output'] | null
    readonly generalContractor: {
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
    } | null
    readonly lienWaivers: {
      readonly __typename: 'SubcontractorLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly conditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly conditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
    } | null
  } | null
  readonly changeOrderRequest: {
    readonly __typename: 'FormTemplateVariant'
    readonly id: ID['output']
    readonly internalName: String['output']
    readonly isDefaultVariant: Boolean['output']
    readonly template: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly createdAt: DateTime['output']
      readonly isCustomerReady: Boolean['output']
      readonly userVisibleName: String['output']
      readonly skippedValidation: Boolean['output']
      readonly type: FormTemplateType
      readonly status: FormTemplateStatus
      readonly versions: ReadonlyArray<{
        readonly __typename: 'FormTemplateVersion'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly updatedAt: DateTime['output']
        readonly versionNumber: Int['output']
        readonly isReadyForManualStoredMaterials: Boolean['output']
        readonly file: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
        }
        readonly annotations: ReadonlyArray<{
          readonly __typename: 'FormTemplateAnnotation'
          readonly id: ID['output']
          readonly permanentId: ID['output']
          readonly syncTag: String['output'] | null
          readonly userVisibleName: String['output']
          readonly type: FormTemplateAnnotationType
          readonly xStart: Float['output']
          readonly yStart: Float['output']
          readonly width: Float['output']
          readonly height: Float['output']
          readonly pageNumber: Int['output']
          readonly fontFamily: FormTemplateFont
          readonly fontColor: String['output']
          readonly textAlignment: TextAlignment
          readonly wrapText: Boolean['output']
          readonly isOptional: Boolean['output']
          readonly selectedKey: String['output'] | null
          readonly defaultValueKey: String['output'] | null
          readonly prefix: String['output'] | null
          readonly suffix: String['output'] | null
          readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
          readonly doNotRetainOnReset: Boolean['output']
          readonly dynamicFieldTag: String['output'] | null
          readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
          readonly imageType: FormTemplateAnnotationImageType | null
          readonly signatureType: SignatureAnnotationType | null
        }>
      }>
      readonly variants: ReadonlyArray<{
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly internalName: String['output']
        readonly isDefaultVariant: Boolean['output']
      }>
    }
  } | null
  readonly changeOrderLog: {
    readonly __typename: 'FormTemplateVariant'
    readonly id: ID['output']
    readonly internalName: String['output']
    readonly isDefaultVariant: Boolean['output']
    readonly template: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly createdAt: DateTime['output']
      readonly isCustomerReady: Boolean['output']
      readonly userVisibleName: String['output']
      readonly skippedValidation: Boolean['output']
      readonly type: FormTemplateType
      readonly status: FormTemplateStatus
      readonly versions: ReadonlyArray<{
        readonly __typename: 'FormTemplateVersion'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly updatedAt: DateTime['output']
        readonly versionNumber: Int['output']
        readonly isReadyForManualStoredMaterials: Boolean['output']
        readonly file: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
        }
        readonly annotations: ReadonlyArray<{
          readonly __typename: 'FormTemplateAnnotation'
          readonly id: ID['output']
          readonly permanentId: ID['output']
          readonly syncTag: String['output'] | null
          readonly userVisibleName: String['output']
          readonly type: FormTemplateAnnotationType
          readonly xStart: Float['output']
          readonly yStart: Float['output']
          readonly width: Float['output']
          readonly height: Float['output']
          readonly pageNumber: Int['output']
          readonly fontFamily: FormTemplateFont
          readonly fontColor: String['output']
          readonly textAlignment: TextAlignment
          readonly wrapText: Boolean['output']
          readonly isOptional: Boolean['output']
          readonly selectedKey: String['output'] | null
          readonly defaultValueKey: String['output'] | null
          readonly prefix: String['output'] | null
          readonly suffix: String['output'] | null
          readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
          readonly doNotRetainOnReset: Boolean['output']
          readonly dynamicFieldTag: String['output'] | null
          readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
          readonly imageType: FormTemplateAnnotationImageType | null
          readonly signatureType: SignatureAnnotationType | null
        }>
      }>
      readonly variants: ReadonlyArray<{
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly internalName: String['output']
        readonly isDefaultVariant: Boolean['output']
      }>
    }
  } | null
}

export type CompanyForDetailsProperties = {
  readonly __typename: 'Company'
  readonly id: ID['output']
  readonly name: String['output']
  readonly nickname: String['output'] | null
  readonly type: CompanyType
  readonly phoneNumber: PhoneNumber['output'] | null
  readonly expectedNumberOfProjects: SafeInt['output'] | null
  readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
  readonly allowLumpSumOverbilling: Boolean['output']
  readonly notaryTags: ReadonlyArray<NotaryTag>
  readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
  readonly isDemoCompany: Boolean['output']
  readonly companyIntegrations: ReadonlyArray<{
    readonly __typename: 'CompanyIntegration'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly type: IntegrationType
    readonly label: String['output'] | null
    readonly shortName: String['output']
    readonly longName: String['output']
    readonly credentialsUpdatedAt: DateTime['output'] | null
    readonly credentialsReadyForAutoRotation: Boolean['output']
    readonly archivedAt: DateTime['output'] | null
    readonly shouldImportNewProjectSov: Boolean['output']
    readonly metadata: JSONObject['output']
    readonly importChangeOrdersMethod: ImportIntegrationChangeOrdersMethod
    readonly importComboJobMethod: ImportIntegrationComboJobMethod
    readonly importProjectNameSource: ImportIntegrationProjectNameSource
    readonly revertPayAppsToDraftWhenTexturaInvoiceRejected: Boolean['output']
    readonly location: {
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    } | null
  }>
  readonly users: ReadonlyArray<{
    readonly __typename: 'CompanyUser'
    readonly id: ID['output']
    readonly role: CompanyUserRole
    readonly isSitelineAdmin: Boolean['output']
    readonly status: UserStatus
    readonly permissions: ReadonlyArray<Permission>
    readonly user: {
      readonly __typename: 'User'
      readonly blockedEmails: ReadonlyArray<EmailType>
      readonly blockedNotifications: ReadonlyArray<NotificationType>
      readonly policiesAcceptedAt: DateTime['output'] | null
      readonly id: ID['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
      readonly email: EmailAddress['output']
      readonly jobTitle: String['output'] | null
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly companies: ReadonlyArray<{
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
        readonly nickname: String['output'] | null
        readonly type: CompanyType
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly expectedNumberOfProjects: SafeInt['output'] | null
        readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
        readonly allowLumpSumOverbilling: Boolean['output']
        readonly notaryTags: ReadonlyArray<NotaryTag>
        readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
        readonly isDemoCompany: Boolean['output']
        readonly picture: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notaryStamp: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly secondarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly locations: ReadonlyArray<{
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        }>
        readonly metadata: {
          readonly __typename: 'CompanyMetadata'
          readonly subtrade: String['output'] | null
          readonly domains: ReadonlyArray<String['output']>
          readonly domainSignup: Boolean['output']
          readonly monthlyCreatePayAppReminderDate: Int['output'] | null
        }
        readonly defaultQuickBillingTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
        } | null
        readonly sitelinePointOfContact: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
        } | null
      }>
      readonly companyUsers: ReadonlyArray<{
        readonly __typename: 'CompanyUser'
        readonly id: ID['output']
        readonly status: UserStatus
        readonly role: CompanyUserRole
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
          readonly nickname: String['output'] | null
          readonly type: CompanyType
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly expectedNumberOfProjects: SafeInt['output'] | null
          readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
          readonly allowLumpSumOverbilling: Boolean['output']
          readonly notaryTags: ReadonlyArray<NotaryTag>
          readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
          readonly isDemoCompany: Boolean['output']
          readonly picture: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly notaryStamp: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly notarySignature: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly secondarySignature: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly locations: ReadonlyArray<{
            readonly __typename: 'Location'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly nickname: String['output'] | null
            readonly street1: String['output'] | null
            readonly street2: String['output'] | null
            readonly city: String['output']
            readonly county: String['output'] | null
            readonly state: String['output']
            readonly country: String['output']
            readonly postalCode: String['output'] | null
            readonly timeZone: String['output']
            readonly coordinates: LatLng['output']
          }>
          readonly metadata: {
            readonly __typename: 'CompanyMetadata'
            readonly subtrade: String['output'] | null
            readonly domains: ReadonlyArray<String['output']>
            readonly domainSignup: Boolean['output']
            readonly monthlyCreatePayAppReminderDate: Int['output'] | null
          }
          readonly defaultQuickBillingTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
          } | null
          readonly sitelinePointOfContact: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
          } | null
        }
      }>
    }
  }>
  readonly vendors: ReadonlyArray<{
    readonly __typename: 'Vendor'
    readonly id: ID['output']
    readonly name: String['output']
    readonly contacts: ReadonlyArray<{
      readonly __typename: 'VendorContact'
      readonly id: ID['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly archivedAt: DateTime['output'] | null
    }>
  }>
  readonly contacts: ReadonlyArray<{
    readonly __typename: 'CompanyContact'
    readonly id: ID['output']
    readonly fullName: String['output']
    readonly email: EmailAddress['output']
    readonly companyName: String['output']
    readonly archivedAt: DateTime['output'] | null
  }>
  readonly formTemplateCollections: ReadonlyArray<{
    readonly __typename: 'CompanyFormTemplateCollection'
    readonly id: ID['output']
    readonly name: String['output']
    readonly state: String['output'] | null
    readonly billingType: BillingType
    readonly generalContractor: {
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
    } | null
    readonly payAppForms: {
      readonly __typename: 'CompanyPayAppFormTemplateSet'
      readonly id: ID['output']
      readonly name: String['output']
      readonly state: String['output'] | null
      readonly billingType: BillingType
      readonly generalContractor: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      } | null
      readonly payAppRequirementGroups: ReadonlyArray<{
        readonly __typename: 'PayAppRequirementGroup'
        readonly id: ID['output']
        readonly order: Int['output']
        readonly payAppRequirements: ReadonlyArray<{
          readonly __typename: 'PayAppRequirement'
          readonly id: ID['output']
          readonly groupOrder: Int['output']
          readonly conditions: ReadonlyArray<PayAppRequirementCondition>
          readonly templateVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly userVisibleName: String['output'] | null
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly isCustomerReady: Boolean['output']
            }
          } | null
        }>
      }>
    } | null
    readonly primaryLienWaivers: {
      readonly __typename: 'CompanyLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly name: String['output']
      readonly state: String['output'] | null
      readonly generalContractor: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      } | null
      readonly lienWaivers: {
        readonly __typename: 'SubcontractorLienWaiverTemplateSet'
        readonly id: ID['output']
        readonly conditionalFinalVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly conditionalProgressVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly unconditionalFinalVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly unconditionalProgressVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
      } | null
    } | null
    readonly vendorLienWaivers: {
      readonly __typename: 'CompanyLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly name: String['output']
      readonly state: String['output'] | null
      readonly generalContractor: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      } | null
      readonly lienWaivers: {
        readonly __typename: 'SubcontractorLienWaiverTemplateSet'
        readonly id: ID['output']
        readonly conditionalFinalVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly conditionalProgressVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly unconditionalFinalVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly unconditionalProgressVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
      } | null
    } | null
    readonly changeOrderRequest: {
      readonly __typename: 'FormTemplateVariant'
      readonly id: ID['output']
      readonly internalName: String['output']
      readonly isDefaultVariant: Boolean['output']
      readonly template: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly createdAt: DateTime['output']
        readonly isCustomerReady: Boolean['output']
        readonly userVisibleName: String['output']
        readonly skippedValidation: Boolean['output']
        readonly type: FormTemplateType
        readonly status: FormTemplateStatus
        readonly versions: ReadonlyArray<{
          readonly __typename: 'FormTemplateVersion'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly updatedAt: DateTime['output']
          readonly versionNumber: Int['output']
          readonly isReadyForManualStoredMaterials: Boolean['output']
          readonly file: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          }
          readonly annotations: ReadonlyArray<{
            readonly __typename: 'FormTemplateAnnotation'
            readonly id: ID['output']
            readonly permanentId: ID['output']
            readonly syncTag: String['output'] | null
            readonly userVisibleName: String['output']
            readonly type: FormTemplateAnnotationType
            readonly xStart: Float['output']
            readonly yStart: Float['output']
            readonly width: Float['output']
            readonly height: Float['output']
            readonly pageNumber: Int['output']
            readonly fontFamily: FormTemplateFont
            readonly fontColor: String['output']
            readonly textAlignment: TextAlignment
            readonly wrapText: Boolean['output']
            readonly isOptional: Boolean['output']
            readonly selectedKey: String['output'] | null
            readonly defaultValueKey: String['output'] | null
            readonly prefix: String['output'] | null
            readonly suffix: String['output'] | null
            readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
            readonly doNotRetainOnReset: Boolean['output']
            readonly dynamicFieldTag: String['output'] | null
            readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
            readonly imageType: FormTemplateAnnotationImageType | null
            readonly signatureType: SignatureAnnotationType | null
          }>
        }>
        readonly variants: ReadonlyArray<{
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly internalName: String['output']
          readonly isDefaultVariant: Boolean['output']
        }>
      }
    } | null
    readonly changeOrderLog: {
      readonly __typename: 'FormTemplateVariant'
      readonly id: ID['output']
      readonly internalName: String['output']
      readonly isDefaultVariant: Boolean['output']
      readonly template: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly createdAt: DateTime['output']
        readonly isCustomerReady: Boolean['output']
        readonly userVisibleName: String['output']
        readonly skippedValidation: Boolean['output']
        readonly type: FormTemplateType
        readonly status: FormTemplateStatus
        readonly versions: ReadonlyArray<{
          readonly __typename: 'FormTemplateVersion'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly updatedAt: DateTime['output']
          readonly versionNumber: Int['output']
          readonly isReadyForManualStoredMaterials: Boolean['output']
          readonly file: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          }
          readonly annotations: ReadonlyArray<{
            readonly __typename: 'FormTemplateAnnotation'
            readonly id: ID['output']
            readonly permanentId: ID['output']
            readonly syncTag: String['output'] | null
            readonly userVisibleName: String['output']
            readonly type: FormTemplateAnnotationType
            readonly xStart: Float['output']
            readonly yStart: Float['output']
            readonly width: Float['output']
            readonly height: Float['output']
            readonly pageNumber: Int['output']
            readonly fontFamily: FormTemplateFont
            readonly fontColor: String['output']
            readonly textAlignment: TextAlignment
            readonly wrapText: Boolean['output']
            readonly isOptional: Boolean['output']
            readonly selectedKey: String['output'] | null
            readonly defaultValueKey: String['output'] | null
            readonly prefix: String['output'] | null
            readonly suffix: String['output'] | null
            readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
            readonly doNotRetainOnReset: Boolean['output']
            readonly dynamicFieldTag: String['output'] | null
            readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
            readonly imageType: FormTemplateAnnotationImageType | null
            readonly signatureType: SignatureAnnotationType | null
          }>
        }>
        readonly variants: ReadonlyArray<{
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly internalName: String['output']
          readonly isDefaultVariant: Boolean['output']
        }>
      }
    } | null
  }>
  readonly payAppFormTemplateSets: ReadonlyArray<{
    readonly __typename: 'CompanyPayAppFormTemplateSet'
    readonly id: ID['output']
    readonly name: String['output']
    readonly state: String['output'] | null
    readonly billingType: BillingType
    readonly generalContractor: {
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
    } | null
    readonly payAppRequirementGroups: ReadonlyArray<{
      readonly __typename: 'PayAppRequirementGroup'
      readonly id: ID['output']
      readonly order: Int['output']
      readonly payAppRequirements: ReadonlyArray<{
        readonly __typename: 'PayAppRequirement'
        readonly id: ID['output']
        readonly groupOrder: Int['output']
        readonly conditions: ReadonlyArray<PayAppRequirementCondition>
        readonly templateVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly internalName: String['output']
          readonly userVisibleName: String['output'] | null
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly isCustomerReady: Boolean['output']
          }
        } | null
      }>
    }>
  }>
  readonly primaryLienWaiverTemplateSets: ReadonlyArray<{
    readonly __typename: 'CompanyLienWaiverTemplateSet'
    readonly id: ID['output']
    readonly name: String['output']
    readonly state: String['output'] | null
    readonly generalContractor: {
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
    } | null
    readonly lienWaivers: {
      readonly __typename: 'SubcontractorLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly conditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly conditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
    } | null
  }>
  readonly vendorLienWaiverTemplateSets: ReadonlyArray<{
    readonly __typename: 'CompanyLienWaiverTemplateSet'
    readonly id: ID['output']
    readonly name: String['output']
    readonly state: String['output'] | null
    readonly generalContractor: {
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
    } | null
    readonly lienWaivers: {
      readonly __typename: 'SubcontractorLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly conditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly conditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
    } | null
  }>
  readonly defaultChangeOrderRequestTemplate: {
    readonly __typename: 'FormTemplate'
    readonly id: String['output']
  } | null
  readonly defaultChangeOrderLogTemplate: {
    readonly __typename: 'FormTemplate'
    readonly id: String['output']
  } | null
  readonly picture: {
    readonly __typename: 'StoredFile'
    readonly id: ID['output']
    readonly url: URL['output']
  } | null
  readonly notaryStamp: {
    readonly __typename: 'StoredFile'
    readonly id: ID['output']
    readonly url: URL['output']
  } | null
  readonly notarySignature: {
    readonly __typename: 'StoredFile'
    readonly id: ID['output']
    readonly url: URL['output']
  } | null
  readonly secondarySignature: {
    readonly __typename: 'StoredFile'
    readonly id: ID['output']
    readonly url: URL['output']
  } | null
  readonly locations: ReadonlyArray<{
    readonly __typename: 'Location'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly nickname: String['output'] | null
    readonly street1: String['output'] | null
    readonly street2: String['output'] | null
    readonly city: String['output']
    readonly county: String['output'] | null
    readonly state: String['output']
    readonly country: String['output']
    readonly postalCode: String['output'] | null
    readonly timeZone: String['output']
    readonly coordinates: LatLng['output']
  }>
  readonly metadata: {
    readonly __typename: 'CompanyMetadata'
    readonly subtrade: String['output'] | null
    readonly domains: ReadonlyArray<String['output']>
    readonly domainSignup: Boolean['output']
    readonly monthlyCreatePayAppReminderDate: Int['output'] | null
  }
  readonly defaultQuickBillingTemplate: {
    readonly __typename: 'FormTemplate'
    readonly id: String['output']
  } | null
  readonly sitelinePointOfContact: {
    readonly __typename: 'User'
    readonly id: ID['output']
    readonly email: EmailAddress['output']
  } | null
}

export type UserEducationCampaignProperties = {
  readonly __typename: 'UserEducationCampaign'
  readonly id: ID['output']
  readonly name: String['output']
  readonly startDate: DateTime['output']
  readonly endDate: DateTime['output']
  readonly targetPermissions: ReadonlyArray<Permission>
  readonly showNewTag: Boolean['output']
  readonly title: String['output']
  readonly message: String['output']
  readonly buttonLabel: String['output'] | null
  readonly buttonLink: String['output'] | null
}

export type CreateAdminUserMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type CreateAdminUserMutation = {
  readonly __typename: 'Mutation'
  readonly createAdminUser: { readonly __typename: 'User'; readonly id: ID['output'] }
}

export type FullyBilledContractsQueryVariables = Exact<{ [key: string]: never }>

export type FullyBilledContractsQuery = {
  readonly __typename: 'Query'
  readonly fullyBilledContracts: ReadonlyArray<{
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly timeZone: String['output']
    readonly pastPayAppCount: Int['output']
    readonly percentComplete: Float['output'] | null
    readonly project: {
      readonly __typename: 'Project'
      readonly id: ID['output']
      readonly name: String['output']
    }
    readonly company: {
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
    }
    readonly payApps: ReadonlyArray<{
      readonly __typename: 'PayApp'
      readonly id: ID['output']
      readonly billingEnd: DateTime['output']
      readonly balanceToFinish: SafeInt['output']
      readonly currentBilled: SafeInt['output']
      readonly payAppNumber: SafeInt['output']
      readonly retentionOnly: Boolean['output']
    }>
    readonly sov: {
      readonly __typename: 'Sov'
      readonly id: ID['output']
      readonly totalValue: SafeInt['output']
      readonly totalRetention: SafeInt['output']
    } | null
  }>
}

export type CacheKeysQueryVariables = Exact<{
  input: GetCacheKeysInput
}>

export type CacheKeysQuery = {
  readonly __typename: 'Query'
  readonly cacheKeys: ReadonlyArray<String['output']>
}

export type DeleteCacheKeysMutationVariables = Exact<{
  input: DeleteCacheKeysInput
}>

export type DeleteCacheKeysMutation = {
  readonly __typename: 'Mutation'
  readonly deleteCacheKeys: Boolean['output']
}

export type CreateCompanyMutationVariables = Exact<{
  input: CompanyInput
}>

export type CreateCompanyMutation = {
  readonly __typename: 'Mutation'
  readonly createCompany: {
    readonly __typename: 'Company'
    readonly id: ID['output']
    readonly name: String['output']
    readonly nickname: String['output'] | null
    readonly type: CompanyType
    readonly phoneNumber: PhoneNumber['output'] | null
    readonly expectedNumberOfProjects: SafeInt['output'] | null
    readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
    readonly allowLumpSumOverbilling: Boolean['output']
    readonly notaryTags: ReadonlyArray<NotaryTag>
    readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
    readonly isDemoCompany: Boolean['output']
    readonly picture: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
    } | null
    readonly notaryStamp: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
    } | null
    readonly notarySignature: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
    } | null
    readonly secondarySignature: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
    } | null
    readonly locations: ReadonlyArray<{
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    }>
    readonly metadata: {
      readonly __typename: 'CompanyMetadata'
      readonly subtrade: String['output'] | null
      readonly domains: ReadonlyArray<String['output']>
      readonly domainSignup: Boolean['output']
      readonly monthlyCreatePayAppReminderDate: Int['output'] | null
    }
    readonly defaultQuickBillingTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
    } | null
    readonly sitelinePointOfContact: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
    } | null
  }
}

export type AddCompanyIntegrationMutationVariables = Exact<{
  input: AddCompanyIntegrationInput
}>

export type AddCompanyIntegrationMutation = {
  readonly __typename: 'Mutation'
  readonly addCompanyIntegration: {
    readonly __typename: 'CompanyIntegration'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly type: IntegrationType
    readonly label: String['output'] | null
    readonly shortName: String['output']
    readonly longName: String['output']
    readonly credentialsUpdatedAt: DateTime['output'] | null
    readonly credentialsReadyForAutoRotation: Boolean['output']
    readonly archivedAt: DateTime['output'] | null
    readonly shouldImportNewProjectSov: Boolean['output']
    readonly metadata: JSONObject['output']
    readonly importChangeOrdersMethod: ImportIntegrationChangeOrdersMethod
    readonly importComboJobMethod: ImportIntegrationComboJobMethod
    readonly importProjectNameSource: ImportIntegrationProjectNameSource
    readonly revertPayAppsToDraftWhenTexturaInvoiceRejected: Boolean['output']
    readonly location: {
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    } | null
  }
}

export type UpdateCompanyIntegrationMutationVariables = Exact<{
  input: UpdateCompanyIntegrationInput
}>

export type UpdateCompanyIntegrationMutation = {
  readonly __typename: 'Mutation'
  readonly updateCompanyIntegration: {
    readonly __typename: 'CompanyIntegration'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly type: IntegrationType
    readonly label: String['output'] | null
    readonly shortName: String['output']
    readonly longName: String['output']
    readonly credentialsUpdatedAt: DateTime['output'] | null
    readonly credentialsReadyForAutoRotation: Boolean['output']
    readonly archivedAt: DateTime['output'] | null
    readonly shouldImportNewProjectSov: Boolean['output']
    readonly metadata: JSONObject['output']
    readonly importChangeOrdersMethod: ImportIntegrationChangeOrdersMethod
    readonly importComboJobMethod: ImportIntegrationComboJobMethod
    readonly importProjectNameSource: ImportIntegrationProjectNameSource
    readonly revertPayAppsToDraftWhenTexturaInvoiceRejected: Boolean['output']
    readonly location: {
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    } | null
  }
}

export type GetIntegrationInvoiceTypesQueryVariables = Exact<{
  input: IntegrationInvoiceTypesInput
}>

export type GetIntegrationInvoiceTypesQuery = {
  readonly __typename: 'Query'
  readonly integrationInvoiceTypes: ReadonlyArray<{
    readonly __typename: 'IntegrationInvoiceType'
    readonly invoiceType: String['output']
    readonly hasAutoNumberingEnabled: Boolean['output']
  }>
}

export type InviteCompanyUserMutationVariables = Exact<{
  input: InviteCompanyUserInput
}>

export type InviteCompanyUserMutation = {
  readonly __typename: 'Mutation'
  readonly inviteCompanyUser: {
    readonly __typename: 'CompanyUser'
    readonly id: ID['output']
    readonly role: CompanyUserRole
    readonly isSitelineAdmin: Boolean['output']
    readonly status: UserStatus
    readonly permissions: ReadonlyArray<Permission>
    readonly user: {
      readonly __typename: 'User'
      readonly blockedEmails: ReadonlyArray<EmailType>
      readonly blockedNotifications: ReadonlyArray<NotificationType>
      readonly policiesAcceptedAt: DateTime['output'] | null
      readonly id: ID['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
      readonly email: EmailAddress['output']
      readonly jobTitle: String['output'] | null
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly companies: ReadonlyArray<{
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
        readonly nickname: String['output'] | null
        readonly type: CompanyType
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly expectedNumberOfProjects: SafeInt['output'] | null
        readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
        readonly allowLumpSumOverbilling: Boolean['output']
        readonly notaryTags: ReadonlyArray<NotaryTag>
        readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
        readonly isDemoCompany: Boolean['output']
        readonly picture: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notaryStamp: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly secondarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly locations: ReadonlyArray<{
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        }>
        readonly metadata: {
          readonly __typename: 'CompanyMetadata'
          readonly subtrade: String['output'] | null
          readonly domains: ReadonlyArray<String['output']>
          readonly domainSignup: Boolean['output']
          readonly monthlyCreatePayAppReminderDate: Int['output'] | null
        }
        readonly defaultQuickBillingTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
        } | null
        readonly sitelinePointOfContact: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
        } | null
      }>
      readonly companyUsers: ReadonlyArray<{
        readonly __typename: 'CompanyUser'
        readonly id: ID['output']
        readonly status: UserStatus
        readonly role: CompanyUserRole
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
          readonly nickname: String['output'] | null
          readonly type: CompanyType
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly expectedNumberOfProjects: SafeInt['output'] | null
          readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
          readonly allowLumpSumOverbilling: Boolean['output']
          readonly notaryTags: ReadonlyArray<NotaryTag>
          readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
          readonly isDemoCompany: Boolean['output']
          readonly picture: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly notaryStamp: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly notarySignature: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly secondarySignature: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly locations: ReadonlyArray<{
            readonly __typename: 'Location'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly nickname: String['output'] | null
            readonly street1: String['output'] | null
            readonly street2: String['output'] | null
            readonly city: String['output']
            readonly county: String['output'] | null
            readonly state: String['output']
            readonly country: String['output']
            readonly postalCode: String['output'] | null
            readonly timeZone: String['output']
            readonly coordinates: LatLng['output']
          }>
          readonly metadata: {
            readonly __typename: 'CompanyMetadata'
            readonly subtrade: String['output'] | null
            readonly domains: ReadonlyArray<String['output']>
            readonly domainSignup: Boolean['output']
            readonly monthlyCreatePayAppReminderDate: Int['output'] | null
          }
          readonly defaultQuickBillingTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
          } | null
          readonly sitelinePointOfContact: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
          } | null
        }
      }>
    }
  }
}

export type CreateCompanyContactMutationVariables = Exact<{
  input: CreateCompanyContactInput
}>

export type CreateCompanyContactMutation = {
  readonly __typename: 'Mutation'
  readonly createCompanyContact: {
    readonly __typename: 'CompanyContact'
    readonly id: ID['output']
    readonly fullName: String['output']
    readonly email: EmailAddress['output']
    readonly companyName: String['output']
    readonly archivedAt: DateTime['output'] | null
  }
}

export type UpdateCompanyContactMutationVariables = Exact<{
  input: UpdateCompanyContactInput
}>

export type UpdateCompanyContactMutation = {
  readonly __typename: 'Mutation'
  readonly updateCompanyContact: {
    readonly __typename: 'CompanyContact'
    readonly id: ID['output']
    readonly fullName: String['output']
    readonly email: EmailAddress['output']
    readonly companyName: String['output']
    readonly archivedAt: DateTime['output'] | null
  }
}

export type ClearCompanyContactsMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ClearCompanyContactsMutation = {
  readonly __typename: 'Mutation'
  readonly clearCompanyContacts: {
    readonly __typename: 'Company'
    readonly id: ID['output']
    readonly name: String['output']
    readonly nickname: String['output'] | null
    readonly type: CompanyType
    readonly phoneNumber: PhoneNumber['output'] | null
    readonly expectedNumberOfProjects: SafeInt['output'] | null
    readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
    readonly allowLumpSumOverbilling: Boolean['output']
    readonly notaryTags: ReadonlyArray<NotaryTag>
    readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
    readonly isDemoCompany: Boolean['output']
    readonly companyIntegrations: ReadonlyArray<{
      readonly __typename: 'CompanyIntegration'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly type: IntegrationType
      readonly label: String['output'] | null
      readonly shortName: String['output']
      readonly longName: String['output']
      readonly credentialsUpdatedAt: DateTime['output'] | null
      readonly credentialsReadyForAutoRotation: Boolean['output']
      readonly archivedAt: DateTime['output'] | null
      readonly shouldImportNewProjectSov: Boolean['output']
      readonly metadata: JSONObject['output']
      readonly importChangeOrdersMethod: ImportIntegrationChangeOrdersMethod
      readonly importComboJobMethod: ImportIntegrationComboJobMethod
      readonly importProjectNameSource: ImportIntegrationProjectNameSource
      readonly revertPayAppsToDraftWhenTexturaInvoiceRejected: Boolean['output']
      readonly location: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      } | null
    }>
    readonly users: ReadonlyArray<{
      readonly __typename: 'CompanyUser'
      readonly id: ID['output']
      readonly role: CompanyUserRole
      readonly isSitelineAdmin: Boolean['output']
      readonly status: UserStatus
      readonly permissions: ReadonlyArray<Permission>
      readonly user: {
        readonly __typename: 'User'
        readonly blockedEmails: ReadonlyArray<EmailType>
        readonly blockedNotifications: ReadonlyArray<NotificationType>
        readonly policiesAcceptedAt: DateTime['output'] | null
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
        readonly jobTitle: String['output'] | null
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly companies: ReadonlyArray<{
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
          readonly nickname: String['output'] | null
          readonly type: CompanyType
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly expectedNumberOfProjects: SafeInt['output'] | null
          readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
          readonly allowLumpSumOverbilling: Boolean['output']
          readonly notaryTags: ReadonlyArray<NotaryTag>
          readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
          readonly isDemoCompany: Boolean['output']
          readonly picture: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly notaryStamp: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly notarySignature: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly secondarySignature: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly locations: ReadonlyArray<{
            readonly __typename: 'Location'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly nickname: String['output'] | null
            readonly street1: String['output'] | null
            readonly street2: String['output'] | null
            readonly city: String['output']
            readonly county: String['output'] | null
            readonly state: String['output']
            readonly country: String['output']
            readonly postalCode: String['output'] | null
            readonly timeZone: String['output']
            readonly coordinates: LatLng['output']
          }>
          readonly metadata: {
            readonly __typename: 'CompanyMetadata'
            readonly subtrade: String['output'] | null
            readonly domains: ReadonlyArray<String['output']>
            readonly domainSignup: Boolean['output']
            readonly monthlyCreatePayAppReminderDate: Int['output'] | null
          }
          readonly defaultQuickBillingTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
          } | null
          readonly sitelinePointOfContact: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
          } | null
        }>
        readonly companyUsers: ReadonlyArray<{
          readonly __typename: 'CompanyUser'
          readonly id: ID['output']
          readonly status: UserStatus
          readonly role: CompanyUserRole
          readonly company: {
            readonly __typename: 'Company'
            readonly id: ID['output']
            readonly name: String['output']
            readonly nickname: String['output'] | null
            readonly type: CompanyType
            readonly phoneNumber: PhoneNumber['output'] | null
            readonly expectedNumberOfProjects: SafeInt['output'] | null
            readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
            readonly allowLumpSumOverbilling: Boolean['output']
            readonly notaryTags: ReadonlyArray<NotaryTag>
            readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
            readonly isDemoCompany: Boolean['output']
            readonly picture: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly notaryStamp: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly notarySignature: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly secondarySignature: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly locations: ReadonlyArray<{
              readonly __typename: 'Location'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly nickname: String['output'] | null
              readonly street1: String['output'] | null
              readonly street2: String['output'] | null
              readonly city: String['output']
              readonly county: String['output'] | null
              readonly state: String['output']
              readonly country: String['output']
              readonly postalCode: String['output'] | null
              readonly timeZone: String['output']
              readonly coordinates: LatLng['output']
            }>
            readonly metadata: {
              readonly __typename: 'CompanyMetadata'
              readonly subtrade: String['output'] | null
              readonly domains: ReadonlyArray<String['output']>
              readonly domainSignup: Boolean['output']
              readonly monthlyCreatePayAppReminderDate: Int['output'] | null
            }
            readonly defaultQuickBillingTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
            } | null
            readonly sitelinePointOfContact: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            } | null
          }
        }>
      }
    }>
    readonly vendors: ReadonlyArray<{
      readonly __typename: 'Vendor'
      readonly id: ID['output']
      readonly name: String['output']
      readonly contacts: ReadonlyArray<{
        readonly __typename: 'VendorContact'
        readonly id: ID['output']
        readonly fullName: String['output']
        readonly email: EmailAddress['output']
        readonly archivedAt: DateTime['output'] | null
      }>
    }>
    readonly contacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly archivedAt: DateTime['output'] | null
    }>
    readonly formTemplateCollections: ReadonlyArray<{
      readonly __typename: 'CompanyFormTemplateCollection'
      readonly id: ID['output']
      readonly name: String['output']
      readonly state: String['output'] | null
      readonly billingType: BillingType
      readonly generalContractor: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      } | null
      readonly payAppForms: {
        readonly __typename: 'CompanyPayAppFormTemplateSet'
        readonly id: ID['output']
        readonly name: String['output']
        readonly state: String['output'] | null
        readonly billingType: BillingType
        readonly generalContractor: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        } | null
        readonly payAppRequirementGroups: ReadonlyArray<{
          readonly __typename: 'PayAppRequirementGroup'
          readonly id: ID['output']
          readonly order: Int['output']
          readonly payAppRequirements: ReadonlyArray<{
            readonly __typename: 'PayAppRequirement'
            readonly id: ID['output']
            readonly groupOrder: Int['output']
            readonly conditions: ReadonlyArray<PayAppRequirementCondition>
            readonly templateVariant: {
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly userVisibleName: String['output'] | null
              readonly template: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly isCustomerReady: Boolean['output']
              }
            } | null
          }>
        }>
      } | null
      readonly primaryLienWaivers: {
        readonly __typename: 'CompanyLienWaiverTemplateSet'
        readonly id: ID['output']
        readonly name: String['output']
        readonly state: String['output'] | null
        readonly generalContractor: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        } | null
        readonly lienWaivers: {
          readonly __typename: 'SubcontractorLienWaiverTemplateSet'
          readonly id: ID['output']
          readonly conditionalFinalVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
          readonly conditionalProgressVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
          readonly unconditionalFinalVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
          readonly unconditionalProgressVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
        } | null
      } | null
      readonly vendorLienWaivers: {
        readonly __typename: 'CompanyLienWaiverTemplateSet'
        readonly id: ID['output']
        readonly name: String['output']
        readonly state: String['output'] | null
        readonly generalContractor: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        } | null
        readonly lienWaivers: {
          readonly __typename: 'SubcontractorLienWaiverTemplateSet'
          readonly id: ID['output']
          readonly conditionalFinalVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
          readonly conditionalProgressVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
          readonly unconditionalFinalVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
          readonly unconditionalProgressVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
        } | null
      } | null
      readonly changeOrderRequest: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly internalName: String['output']
        readonly isDefaultVariant: Boolean['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly isCustomerReady: Boolean['output']
          readonly userVisibleName: String['output']
          readonly skippedValidation: Boolean['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
        }
      } | null
      readonly changeOrderLog: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly internalName: String['output']
        readonly isDefaultVariant: Boolean['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly isCustomerReady: Boolean['output']
          readonly userVisibleName: String['output']
          readonly skippedValidation: Boolean['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
        }
      } | null
    }>
    readonly payAppFormTemplateSets: ReadonlyArray<{
      readonly __typename: 'CompanyPayAppFormTemplateSet'
      readonly id: ID['output']
      readonly name: String['output']
      readonly state: String['output'] | null
      readonly billingType: BillingType
      readonly generalContractor: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      } | null
      readonly payAppRequirementGroups: ReadonlyArray<{
        readonly __typename: 'PayAppRequirementGroup'
        readonly id: ID['output']
        readonly order: Int['output']
        readonly payAppRequirements: ReadonlyArray<{
          readonly __typename: 'PayAppRequirement'
          readonly id: ID['output']
          readonly groupOrder: Int['output']
          readonly conditions: ReadonlyArray<PayAppRequirementCondition>
          readonly templateVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly userVisibleName: String['output'] | null
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly isCustomerReady: Boolean['output']
            }
          } | null
        }>
      }>
    }>
    readonly primaryLienWaiverTemplateSets: ReadonlyArray<{
      readonly __typename: 'CompanyLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly name: String['output']
      readonly state: String['output'] | null
      readonly generalContractor: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      } | null
      readonly lienWaivers: {
        readonly __typename: 'SubcontractorLienWaiverTemplateSet'
        readonly id: ID['output']
        readonly conditionalFinalVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly conditionalProgressVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly unconditionalFinalVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly unconditionalProgressVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
      } | null
    }>
    readonly vendorLienWaiverTemplateSets: ReadonlyArray<{
      readonly __typename: 'CompanyLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly name: String['output']
      readonly state: String['output'] | null
      readonly generalContractor: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      } | null
      readonly lienWaivers: {
        readonly __typename: 'SubcontractorLienWaiverTemplateSet'
        readonly id: ID['output']
        readonly conditionalFinalVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly conditionalProgressVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly unconditionalFinalVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly unconditionalProgressVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
      } | null
    }>
    readonly defaultChangeOrderRequestTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
    } | null
    readonly defaultChangeOrderLogTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
    } | null
    readonly picture: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
    } | null
    readonly notaryStamp: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
    } | null
    readonly notarySignature: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
    } | null
    readonly secondarySignature: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
    } | null
    readonly locations: ReadonlyArray<{
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    }>
    readonly metadata: {
      readonly __typename: 'CompanyMetadata'
      readonly subtrade: String['output'] | null
      readonly domains: ReadonlyArray<String['output']>
      readonly domainSignup: Boolean['output']
      readonly monthlyCreatePayAppReminderDate: Int['output'] | null
    }
    readonly defaultQuickBillingTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
    } | null
    readonly sitelinePointOfContact: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
    } | null
  }
}

export type GetCompanyForDetailsQueryVariables = Exact<{
  companyId: Scalars['ID']['input']
}>

export type GetCompanyForDetailsQuery = {
  readonly __typename: 'Query'
  readonly company: {
    readonly __typename: 'Company'
    readonly id: ID['output']
    readonly name: String['output']
    readonly nickname: String['output'] | null
    readonly type: CompanyType
    readonly phoneNumber: PhoneNumber['output'] | null
    readonly expectedNumberOfProjects: SafeInt['output'] | null
    readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
    readonly allowLumpSumOverbilling: Boolean['output']
    readonly notaryTags: ReadonlyArray<NotaryTag>
    readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
    readonly isDemoCompany: Boolean['output']
    readonly companyIntegrations: ReadonlyArray<{
      readonly __typename: 'CompanyIntegration'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly type: IntegrationType
      readonly label: String['output'] | null
      readonly shortName: String['output']
      readonly longName: String['output']
      readonly credentialsUpdatedAt: DateTime['output'] | null
      readonly credentialsReadyForAutoRotation: Boolean['output']
      readonly archivedAt: DateTime['output'] | null
      readonly shouldImportNewProjectSov: Boolean['output']
      readonly metadata: JSONObject['output']
      readonly importChangeOrdersMethod: ImportIntegrationChangeOrdersMethod
      readonly importComboJobMethod: ImportIntegrationComboJobMethod
      readonly importProjectNameSource: ImportIntegrationProjectNameSource
      readonly revertPayAppsToDraftWhenTexturaInvoiceRejected: Boolean['output']
      readonly location: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      } | null
    }>
    readonly users: ReadonlyArray<{
      readonly __typename: 'CompanyUser'
      readonly id: ID['output']
      readonly role: CompanyUserRole
      readonly isSitelineAdmin: Boolean['output']
      readonly status: UserStatus
      readonly permissions: ReadonlyArray<Permission>
      readonly user: {
        readonly __typename: 'User'
        readonly blockedEmails: ReadonlyArray<EmailType>
        readonly blockedNotifications: ReadonlyArray<NotificationType>
        readonly policiesAcceptedAt: DateTime['output'] | null
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
        readonly jobTitle: String['output'] | null
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly companies: ReadonlyArray<{
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
          readonly nickname: String['output'] | null
          readonly type: CompanyType
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly expectedNumberOfProjects: SafeInt['output'] | null
          readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
          readonly allowLumpSumOverbilling: Boolean['output']
          readonly notaryTags: ReadonlyArray<NotaryTag>
          readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
          readonly isDemoCompany: Boolean['output']
          readonly picture: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly notaryStamp: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly notarySignature: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly secondarySignature: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly locations: ReadonlyArray<{
            readonly __typename: 'Location'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly nickname: String['output'] | null
            readonly street1: String['output'] | null
            readonly street2: String['output'] | null
            readonly city: String['output']
            readonly county: String['output'] | null
            readonly state: String['output']
            readonly country: String['output']
            readonly postalCode: String['output'] | null
            readonly timeZone: String['output']
            readonly coordinates: LatLng['output']
          }>
          readonly metadata: {
            readonly __typename: 'CompanyMetadata'
            readonly subtrade: String['output'] | null
            readonly domains: ReadonlyArray<String['output']>
            readonly domainSignup: Boolean['output']
            readonly monthlyCreatePayAppReminderDate: Int['output'] | null
          }
          readonly defaultQuickBillingTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
          } | null
          readonly sitelinePointOfContact: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
          } | null
        }>
        readonly companyUsers: ReadonlyArray<{
          readonly __typename: 'CompanyUser'
          readonly id: ID['output']
          readonly status: UserStatus
          readonly role: CompanyUserRole
          readonly company: {
            readonly __typename: 'Company'
            readonly id: ID['output']
            readonly name: String['output']
            readonly nickname: String['output'] | null
            readonly type: CompanyType
            readonly phoneNumber: PhoneNumber['output'] | null
            readonly expectedNumberOfProjects: SafeInt['output'] | null
            readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
            readonly allowLumpSumOverbilling: Boolean['output']
            readonly notaryTags: ReadonlyArray<NotaryTag>
            readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
            readonly isDemoCompany: Boolean['output']
            readonly picture: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly notaryStamp: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly notarySignature: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly secondarySignature: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly locations: ReadonlyArray<{
              readonly __typename: 'Location'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly nickname: String['output'] | null
              readonly street1: String['output'] | null
              readonly street2: String['output'] | null
              readonly city: String['output']
              readonly county: String['output'] | null
              readonly state: String['output']
              readonly country: String['output']
              readonly postalCode: String['output'] | null
              readonly timeZone: String['output']
              readonly coordinates: LatLng['output']
            }>
            readonly metadata: {
              readonly __typename: 'CompanyMetadata'
              readonly subtrade: String['output'] | null
              readonly domains: ReadonlyArray<String['output']>
              readonly domainSignup: Boolean['output']
              readonly monthlyCreatePayAppReminderDate: Int['output'] | null
            }
            readonly defaultQuickBillingTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
            } | null
            readonly sitelinePointOfContact: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            } | null
          }
        }>
      }
    }>
    readonly vendors: ReadonlyArray<{
      readonly __typename: 'Vendor'
      readonly id: ID['output']
      readonly name: String['output']
      readonly contacts: ReadonlyArray<{
        readonly __typename: 'VendorContact'
        readonly id: ID['output']
        readonly fullName: String['output']
        readonly email: EmailAddress['output']
        readonly archivedAt: DateTime['output'] | null
      }>
    }>
    readonly contacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly archivedAt: DateTime['output'] | null
    }>
    readonly formTemplateCollections: ReadonlyArray<{
      readonly __typename: 'CompanyFormTemplateCollection'
      readonly id: ID['output']
      readonly name: String['output']
      readonly state: String['output'] | null
      readonly billingType: BillingType
      readonly generalContractor: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      } | null
      readonly payAppForms: {
        readonly __typename: 'CompanyPayAppFormTemplateSet'
        readonly id: ID['output']
        readonly name: String['output']
        readonly state: String['output'] | null
        readonly billingType: BillingType
        readonly generalContractor: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        } | null
        readonly payAppRequirementGroups: ReadonlyArray<{
          readonly __typename: 'PayAppRequirementGroup'
          readonly id: ID['output']
          readonly order: Int['output']
          readonly payAppRequirements: ReadonlyArray<{
            readonly __typename: 'PayAppRequirement'
            readonly id: ID['output']
            readonly groupOrder: Int['output']
            readonly conditions: ReadonlyArray<PayAppRequirementCondition>
            readonly templateVariant: {
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly userVisibleName: String['output'] | null
              readonly template: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly isCustomerReady: Boolean['output']
              }
            } | null
          }>
        }>
      } | null
      readonly primaryLienWaivers: {
        readonly __typename: 'CompanyLienWaiverTemplateSet'
        readonly id: ID['output']
        readonly name: String['output']
        readonly state: String['output'] | null
        readonly generalContractor: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        } | null
        readonly lienWaivers: {
          readonly __typename: 'SubcontractorLienWaiverTemplateSet'
          readonly id: ID['output']
          readonly conditionalFinalVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
          readonly conditionalProgressVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
          readonly unconditionalFinalVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
          readonly unconditionalProgressVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
        } | null
      } | null
      readonly vendorLienWaivers: {
        readonly __typename: 'CompanyLienWaiverTemplateSet'
        readonly id: ID['output']
        readonly name: String['output']
        readonly state: String['output'] | null
        readonly generalContractor: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        } | null
        readonly lienWaivers: {
          readonly __typename: 'SubcontractorLienWaiverTemplateSet'
          readonly id: ID['output']
          readonly conditionalFinalVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
          readonly conditionalProgressVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
          readonly unconditionalFinalVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
          readonly unconditionalProgressVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
        } | null
      } | null
      readonly changeOrderRequest: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly internalName: String['output']
        readonly isDefaultVariant: Boolean['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly isCustomerReady: Boolean['output']
          readonly userVisibleName: String['output']
          readonly skippedValidation: Boolean['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
        }
      } | null
      readonly changeOrderLog: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly internalName: String['output']
        readonly isDefaultVariant: Boolean['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly isCustomerReady: Boolean['output']
          readonly userVisibleName: String['output']
          readonly skippedValidation: Boolean['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
        }
      } | null
    }>
    readonly payAppFormTemplateSets: ReadonlyArray<{
      readonly __typename: 'CompanyPayAppFormTemplateSet'
      readonly id: ID['output']
      readonly name: String['output']
      readonly state: String['output'] | null
      readonly billingType: BillingType
      readonly generalContractor: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      } | null
      readonly payAppRequirementGroups: ReadonlyArray<{
        readonly __typename: 'PayAppRequirementGroup'
        readonly id: ID['output']
        readonly order: Int['output']
        readonly payAppRequirements: ReadonlyArray<{
          readonly __typename: 'PayAppRequirement'
          readonly id: ID['output']
          readonly groupOrder: Int['output']
          readonly conditions: ReadonlyArray<PayAppRequirementCondition>
          readonly templateVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly userVisibleName: String['output'] | null
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly isCustomerReady: Boolean['output']
            }
          } | null
        }>
      }>
    }>
    readonly primaryLienWaiverTemplateSets: ReadonlyArray<{
      readonly __typename: 'CompanyLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly name: String['output']
      readonly state: String['output'] | null
      readonly generalContractor: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      } | null
      readonly lienWaivers: {
        readonly __typename: 'SubcontractorLienWaiverTemplateSet'
        readonly id: ID['output']
        readonly conditionalFinalVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly conditionalProgressVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly unconditionalFinalVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly unconditionalProgressVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
      } | null
    }>
    readonly vendorLienWaiverTemplateSets: ReadonlyArray<{
      readonly __typename: 'CompanyLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly name: String['output']
      readonly state: String['output'] | null
      readonly generalContractor: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      } | null
      readonly lienWaivers: {
        readonly __typename: 'SubcontractorLienWaiverTemplateSet'
        readonly id: ID['output']
        readonly conditionalFinalVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly conditionalProgressVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly unconditionalFinalVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly unconditionalProgressVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
      } | null
    }>
    readonly defaultChangeOrderRequestTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
    } | null
    readonly defaultChangeOrderLogTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
    } | null
    readonly picture: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
    } | null
    readonly notaryStamp: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
    } | null
    readonly notarySignature: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
    } | null
    readonly secondarySignature: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
    } | null
    readonly locations: ReadonlyArray<{
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    }>
    readonly metadata: {
      readonly __typename: 'CompanyMetadata'
      readonly subtrade: String['output'] | null
      readonly domains: ReadonlyArray<String['output']>
      readonly domainSignup: Boolean['output']
      readonly monthlyCreatePayAppReminderDate: Int['output'] | null
    }
    readonly defaultQuickBillingTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
    } | null
    readonly sitelinePointOfContact: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
    } | null
  }
}

export type UpdateDefaultChangeOrderLogFormMutationVariables = Exact<{
  input: UpdateDefaultChangeOrderLogFormInput
}>

export type UpdateDefaultChangeOrderLogFormMutation = {
  readonly __typename: 'Mutation'
  readonly updateDefaultChangeOrderLogForm: {
    readonly __typename: 'Company'
    readonly id: ID['output']
    readonly name: String['output']
    readonly nickname: String['output'] | null
    readonly type: CompanyType
    readonly phoneNumber: PhoneNumber['output'] | null
    readonly expectedNumberOfProjects: SafeInt['output'] | null
    readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
    readonly allowLumpSumOverbilling: Boolean['output']
    readonly notaryTags: ReadonlyArray<NotaryTag>
    readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
    readonly isDemoCompany: Boolean['output']
    readonly companyIntegrations: ReadonlyArray<{
      readonly __typename: 'CompanyIntegration'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly type: IntegrationType
      readonly label: String['output'] | null
      readonly shortName: String['output']
      readonly longName: String['output']
      readonly credentialsUpdatedAt: DateTime['output'] | null
      readonly credentialsReadyForAutoRotation: Boolean['output']
      readonly archivedAt: DateTime['output'] | null
      readonly shouldImportNewProjectSov: Boolean['output']
      readonly metadata: JSONObject['output']
      readonly importChangeOrdersMethod: ImportIntegrationChangeOrdersMethod
      readonly importComboJobMethod: ImportIntegrationComboJobMethod
      readonly importProjectNameSource: ImportIntegrationProjectNameSource
      readonly revertPayAppsToDraftWhenTexturaInvoiceRejected: Boolean['output']
      readonly location: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      } | null
    }>
    readonly users: ReadonlyArray<{
      readonly __typename: 'CompanyUser'
      readonly id: ID['output']
      readonly role: CompanyUserRole
      readonly isSitelineAdmin: Boolean['output']
      readonly status: UserStatus
      readonly permissions: ReadonlyArray<Permission>
      readonly user: {
        readonly __typename: 'User'
        readonly blockedEmails: ReadonlyArray<EmailType>
        readonly blockedNotifications: ReadonlyArray<NotificationType>
        readonly policiesAcceptedAt: DateTime['output'] | null
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
        readonly jobTitle: String['output'] | null
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly companies: ReadonlyArray<{
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
          readonly nickname: String['output'] | null
          readonly type: CompanyType
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly expectedNumberOfProjects: SafeInt['output'] | null
          readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
          readonly allowLumpSumOverbilling: Boolean['output']
          readonly notaryTags: ReadonlyArray<NotaryTag>
          readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
          readonly isDemoCompany: Boolean['output']
          readonly picture: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly notaryStamp: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly notarySignature: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly secondarySignature: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly locations: ReadonlyArray<{
            readonly __typename: 'Location'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly nickname: String['output'] | null
            readonly street1: String['output'] | null
            readonly street2: String['output'] | null
            readonly city: String['output']
            readonly county: String['output'] | null
            readonly state: String['output']
            readonly country: String['output']
            readonly postalCode: String['output'] | null
            readonly timeZone: String['output']
            readonly coordinates: LatLng['output']
          }>
          readonly metadata: {
            readonly __typename: 'CompanyMetadata'
            readonly subtrade: String['output'] | null
            readonly domains: ReadonlyArray<String['output']>
            readonly domainSignup: Boolean['output']
            readonly monthlyCreatePayAppReminderDate: Int['output'] | null
          }
          readonly defaultQuickBillingTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
          } | null
          readonly sitelinePointOfContact: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
          } | null
        }>
        readonly companyUsers: ReadonlyArray<{
          readonly __typename: 'CompanyUser'
          readonly id: ID['output']
          readonly status: UserStatus
          readonly role: CompanyUserRole
          readonly company: {
            readonly __typename: 'Company'
            readonly id: ID['output']
            readonly name: String['output']
            readonly nickname: String['output'] | null
            readonly type: CompanyType
            readonly phoneNumber: PhoneNumber['output'] | null
            readonly expectedNumberOfProjects: SafeInt['output'] | null
            readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
            readonly allowLumpSumOverbilling: Boolean['output']
            readonly notaryTags: ReadonlyArray<NotaryTag>
            readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
            readonly isDemoCompany: Boolean['output']
            readonly picture: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly notaryStamp: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly notarySignature: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly secondarySignature: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly locations: ReadonlyArray<{
              readonly __typename: 'Location'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly nickname: String['output'] | null
              readonly street1: String['output'] | null
              readonly street2: String['output'] | null
              readonly city: String['output']
              readonly county: String['output'] | null
              readonly state: String['output']
              readonly country: String['output']
              readonly postalCode: String['output'] | null
              readonly timeZone: String['output']
              readonly coordinates: LatLng['output']
            }>
            readonly metadata: {
              readonly __typename: 'CompanyMetadata'
              readonly subtrade: String['output'] | null
              readonly domains: ReadonlyArray<String['output']>
              readonly domainSignup: Boolean['output']
              readonly monthlyCreatePayAppReminderDate: Int['output'] | null
            }
            readonly defaultQuickBillingTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
            } | null
            readonly sitelinePointOfContact: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            } | null
          }
        }>
      }
    }>
    readonly vendors: ReadonlyArray<{
      readonly __typename: 'Vendor'
      readonly id: ID['output']
      readonly name: String['output']
      readonly contacts: ReadonlyArray<{
        readonly __typename: 'VendorContact'
        readonly id: ID['output']
        readonly fullName: String['output']
        readonly email: EmailAddress['output']
        readonly archivedAt: DateTime['output'] | null
      }>
    }>
    readonly contacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly archivedAt: DateTime['output'] | null
    }>
    readonly formTemplateCollections: ReadonlyArray<{
      readonly __typename: 'CompanyFormTemplateCollection'
      readonly id: ID['output']
      readonly name: String['output']
      readonly state: String['output'] | null
      readonly billingType: BillingType
      readonly generalContractor: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      } | null
      readonly payAppForms: {
        readonly __typename: 'CompanyPayAppFormTemplateSet'
        readonly id: ID['output']
        readonly name: String['output']
        readonly state: String['output'] | null
        readonly billingType: BillingType
        readonly generalContractor: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        } | null
        readonly payAppRequirementGroups: ReadonlyArray<{
          readonly __typename: 'PayAppRequirementGroup'
          readonly id: ID['output']
          readonly order: Int['output']
          readonly payAppRequirements: ReadonlyArray<{
            readonly __typename: 'PayAppRequirement'
            readonly id: ID['output']
            readonly groupOrder: Int['output']
            readonly conditions: ReadonlyArray<PayAppRequirementCondition>
            readonly templateVariant: {
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly userVisibleName: String['output'] | null
              readonly template: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly isCustomerReady: Boolean['output']
              }
            } | null
          }>
        }>
      } | null
      readonly primaryLienWaivers: {
        readonly __typename: 'CompanyLienWaiverTemplateSet'
        readonly id: ID['output']
        readonly name: String['output']
        readonly state: String['output'] | null
        readonly generalContractor: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        } | null
        readonly lienWaivers: {
          readonly __typename: 'SubcontractorLienWaiverTemplateSet'
          readonly id: ID['output']
          readonly conditionalFinalVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
          readonly conditionalProgressVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
          readonly unconditionalFinalVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
          readonly unconditionalProgressVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
        } | null
      } | null
      readonly vendorLienWaivers: {
        readonly __typename: 'CompanyLienWaiverTemplateSet'
        readonly id: ID['output']
        readonly name: String['output']
        readonly state: String['output'] | null
        readonly generalContractor: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        } | null
        readonly lienWaivers: {
          readonly __typename: 'SubcontractorLienWaiverTemplateSet'
          readonly id: ID['output']
          readonly conditionalFinalVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
          readonly conditionalProgressVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
          readonly unconditionalFinalVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
          readonly unconditionalProgressVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
        } | null
      } | null
      readonly changeOrderRequest: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly internalName: String['output']
        readonly isDefaultVariant: Boolean['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly isCustomerReady: Boolean['output']
          readonly userVisibleName: String['output']
          readonly skippedValidation: Boolean['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
        }
      } | null
      readonly changeOrderLog: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly internalName: String['output']
        readonly isDefaultVariant: Boolean['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly isCustomerReady: Boolean['output']
          readonly userVisibleName: String['output']
          readonly skippedValidation: Boolean['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
        }
      } | null
    }>
    readonly payAppFormTemplateSets: ReadonlyArray<{
      readonly __typename: 'CompanyPayAppFormTemplateSet'
      readonly id: ID['output']
      readonly name: String['output']
      readonly state: String['output'] | null
      readonly billingType: BillingType
      readonly generalContractor: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      } | null
      readonly payAppRequirementGroups: ReadonlyArray<{
        readonly __typename: 'PayAppRequirementGroup'
        readonly id: ID['output']
        readonly order: Int['output']
        readonly payAppRequirements: ReadonlyArray<{
          readonly __typename: 'PayAppRequirement'
          readonly id: ID['output']
          readonly groupOrder: Int['output']
          readonly conditions: ReadonlyArray<PayAppRequirementCondition>
          readonly templateVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly userVisibleName: String['output'] | null
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly isCustomerReady: Boolean['output']
            }
          } | null
        }>
      }>
    }>
    readonly primaryLienWaiverTemplateSets: ReadonlyArray<{
      readonly __typename: 'CompanyLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly name: String['output']
      readonly state: String['output'] | null
      readonly generalContractor: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      } | null
      readonly lienWaivers: {
        readonly __typename: 'SubcontractorLienWaiverTemplateSet'
        readonly id: ID['output']
        readonly conditionalFinalVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly conditionalProgressVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly unconditionalFinalVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly unconditionalProgressVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
      } | null
    }>
    readonly vendorLienWaiverTemplateSets: ReadonlyArray<{
      readonly __typename: 'CompanyLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly name: String['output']
      readonly state: String['output'] | null
      readonly generalContractor: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      } | null
      readonly lienWaivers: {
        readonly __typename: 'SubcontractorLienWaiverTemplateSet'
        readonly id: ID['output']
        readonly conditionalFinalVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly conditionalProgressVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly unconditionalFinalVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly unconditionalProgressVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
      } | null
    }>
    readonly defaultChangeOrderRequestTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
    } | null
    readonly defaultChangeOrderLogTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
    } | null
    readonly picture: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
    } | null
    readonly notaryStamp: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
    } | null
    readonly notarySignature: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
    } | null
    readonly secondarySignature: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
    } | null
    readonly locations: ReadonlyArray<{
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    }>
    readonly metadata: {
      readonly __typename: 'CompanyMetadata'
      readonly subtrade: String['output'] | null
      readonly domains: ReadonlyArray<String['output']>
      readonly domainSignup: Boolean['output']
      readonly monthlyCreatePayAppReminderDate: Int['output'] | null
    }
    readonly defaultQuickBillingTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
    } | null
    readonly sitelinePointOfContact: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
    } | null
  }
}

export type UpdateDefaultChangeOrderRequestFormMutationVariables = Exact<{
  input: UpdateDefaultChangeOrderRequestFormInput
}>

export type UpdateDefaultChangeOrderRequestFormMutation = {
  readonly __typename: 'Mutation'
  readonly updateDefaultChangeOrderRequestForm: {
    readonly __typename: 'Company'
    readonly id: ID['output']
    readonly name: String['output']
    readonly nickname: String['output'] | null
    readonly type: CompanyType
    readonly phoneNumber: PhoneNumber['output'] | null
    readonly expectedNumberOfProjects: SafeInt['output'] | null
    readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
    readonly allowLumpSumOverbilling: Boolean['output']
    readonly notaryTags: ReadonlyArray<NotaryTag>
    readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
    readonly isDemoCompany: Boolean['output']
    readonly companyIntegrations: ReadonlyArray<{
      readonly __typename: 'CompanyIntegration'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly type: IntegrationType
      readonly label: String['output'] | null
      readonly shortName: String['output']
      readonly longName: String['output']
      readonly credentialsUpdatedAt: DateTime['output'] | null
      readonly credentialsReadyForAutoRotation: Boolean['output']
      readonly archivedAt: DateTime['output'] | null
      readonly shouldImportNewProjectSov: Boolean['output']
      readonly metadata: JSONObject['output']
      readonly importChangeOrdersMethod: ImportIntegrationChangeOrdersMethod
      readonly importComboJobMethod: ImportIntegrationComboJobMethod
      readonly importProjectNameSource: ImportIntegrationProjectNameSource
      readonly revertPayAppsToDraftWhenTexturaInvoiceRejected: Boolean['output']
      readonly location: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      } | null
    }>
    readonly users: ReadonlyArray<{
      readonly __typename: 'CompanyUser'
      readonly id: ID['output']
      readonly role: CompanyUserRole
      readonly isSitelineAdmin: Boolean['output']
      readonly status: UserStatus
      readonly permissions: ReadonlyArray<Permission>
      readonly user: {
        readonly __typename: 'User'
        readonly blockedEmails: ReadonlyArray<EmailType>
        readonly blockedNotifications: ReadonlyArray<NotificationType>
        readonly policiesAcceptedAt: DateTime['output'] | null
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
        readonly jobTitle: String['output'] | null
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly companies: ReadonlyArray<{
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
          readonly nickname: String['output'] | null
          readonly type: CompanyType
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly expectedNumberOfProjects: SafeInt['output'] | null
          readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
          readonly allowLumpSumOverbilling: Boolean['output']
          readonly notaryTags: ReadonlyArray<NotaryTag>
          readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
          readonly isDemoCompany: Boolean['output']
          readonly picture: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly notaryStamp: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly notarySignature: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly secondarySignature: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly locations: ReadonlyArray<{
            readonly __typename: 'Location'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly nickname: String['output'] | null
            readonly street1: String['output'] | null
            readonly street2: String['output'] | null
            readonly city: String['output']
            readonly county: String['output'] | null
            readonly state: String['output']
            readonly country: String['output']
            readonly postalCode: String['output'] | null
            readonly timeZone: String['output']
            readonly coordinates: LatLng['output']
          }>
          readonly metadata: {
            readonly __typename: 'CompanyMetadata'
            readonly subtrade: String['output'] | null
            readonly domains: ReadonlyArray<String['output']>
            readonly domainSignup: Boolean['output']
            readonly monthlyCreatePayAppReminderDate: Int['output'] | null
          }
          readonly defaultQuickBillingTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
          } | null
          readonly sitelinePointOfContact: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
          } | null
        }>
        readonly companyUsers: ReadonlyArray<{
          readonly __typename: 'CompanyUser'
          readonly id: ID['output']
          readonly status: UserStatus
          readonly role: CompanyUserRole
          readonly company: {
            readonly __typename: 'Company'
            readonly id: ID['output']
            readonly name: String['output']
            readonly nickname: String['output'] | null
            readonly type: CompanyType
            readonly phoneNumber: PhoneNumber['output'] | null
            readonly expectedNumberOfProjects: SafeInt['output'] | null
            readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
            readonly allowLumpSumOverbilling: Boolean['output']
            readonly notaryTags: ReadonlyArray<NotaryTag>
            readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
            readonly isDemoCompany: Boolean['output']
            readonly picture: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly notaryStamp: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly notarySignature: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly secondarySignature: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly locations: ReadonlyArray<{
              readonly __typename: 'Location'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly nickname: String['output'] | null
              readonly street1: String['output'] | null
              readonly street2: String['output'] | null
              readonly city: String['output']
              readonly county: String['output'] | null
              readonly state: String['output']
              readonly country: String['output']
              readonly postalCode: String['output'] | null
              readonly timeZone: String['output']
              readonly coordinates: LatLng['output']
            }>
            readonly metadata: {
              readonly __typename: 'CompanyMetadata'
              readonly subtrade: String['output'] | null
              readonly domains: ReadonlyArray<String['output']>
              readonly domainSignup: Boolean['output']
              readonly monthlyCreatePayAppReminderDate: Int['output'] | null
            }
            readonly defaultQuickBillingTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
            } | null
            readonly sitelinePointOfContact: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            } | null
          }
        }>
      }
    }>
    readonly vendors: ReadonlyArray<{
      readonly __typename: 'Vendor'
      readonly id: ID['output']
      readonly name: String['output']
      readonly contacts: ReadonlyArray<{
        readonly __typename: 'VendorContact'
        readonly id: ID['output']
        readonly fullName: String['output']
        readonly email: EmailAddress['output']
        readonly archivedAt: DateTime['output'] | null
      }>
    }>
    readonly contacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly archivedAt: DateTime['output'] | null
    }>
    readonly formTemplateCollections: ReadonlyArray<{
      readonly __typename: 'CompanyFormTemplateCollection'
      readonly id: ID['output']
      readonly name: String['output']
      readonly state: String['output'] | null
      readonly billingType: BillingType
      readonly generalContractor: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      } | null
      readonly payAppForms: {
        readonly __typename: 'CompanyPayAppFormTemplateSet'
        readonly id: ID['output']
        readonly name: String['output']
        readonly state: String['output'] | null
        readonly billingType: BillingType
        readonly generalContractor: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        } | null
        readonly payAppRequirementGroups: ReadonlyArray<{
          readonly __typename: 'PayAppRequirementGroup'
          readonly id: ID['output']
          readonly order: Int['output']
          readonly payAppRequirements: ReadonlyArray<{
            readonly __typename: 'PayAppRequirement'
            readonly id: ID['output']
            readonly groupOrder: Int['output']
            readonly conditions: ReadonlyArray<PayAppRequirementCondition>
            readonly templateVariant: {
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly userVisibleName: String['output'] | null
              readonly template: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly isCustomerReady: Boolean['output']
              }
            } | null
          }>
        }>
      } | null
      readonly primaryLienWaivers: {
        readonly __typename: 'CompanyLienWaiverTemplateSet'
        readonly id: ID['output']
        readonly name: String['output']
        readonly state: String['output'] | null
        readonly generalContractor: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        } | null
        readonly lienWaivers: {
          readonly __typename: 'SubcontractorLienWaiverTemplateSet'
          readonly id: ID['output']
          readonly conditionalFinalVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
          readonly conditionalProgressVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
          readonly unconditionalFinalVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
          readonly unconditionalProgressVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
        } | null
      } | null
      readonly vendorLienWaivers: {
        readonly __typename: 'CompanyLienWaiverTemplateSet'
        readonly id: ID['output']
        readonly name: String['output']
        readonly state: String['output'] | null
        readonly generalContractor: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        } | null
        readonly lienWaivers: {
          readonly __typename: 'SubcontractorLienWaiverTemplateSet'
          readonly id: ID['output']
          readonly conditionalFinalVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
          readonly conditionalProgressVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
          readonly unconditionalFinalVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
          readonly unconditionalProgressVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
        } | null
      } | null
      readonly changeOrderRequest: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly internalName: String['output']
        readonly isDefaultVariant: Boolean['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly isCustomerReady: Boolean['output']
          readonly userVisibleName: String['output']
          readonly skippedValidation: Boolean['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
        }
      } | null
      readonly changeOrderLog: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly internalName: String['output']
        readonly isDefaultVariant: Boolean['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly isCustomerReady: Boolean['output']
          readonly userVisibleName: String['output']
          readonly skippedValidation: Boolean['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
        }
      } | null
    }>
    readonly payAppFormTemplateSets: ReadonlyArray<{
      readonly __typename: 'CompanyPayAppFormTemplateSet'
      readonly id: ID['output']
      readonly name: String['output']
      readonly state: String['output'] | null
      readonly billingType: BillingType
      readonly generalContractor: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      } | null
      readonly payAppRequirementGroups: ReadonlyArray<{
        readonly __typename: 'PayAppRequirementGroup'
        readonly id: ID['output']
        readonly order: Int['output']
        readonly payAppRequirements: ReadonlyArray<{
          readonly __typename: 'PayAppRequirement'
          readonly id: ID['output']
          readonly groupOrder: Int['output']
          readonly conditions: ReadonlyArray<PayAppRequirementCondition>
          readonly templateVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly userVisibleName: String['output'] | null
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly isCustomerReady: Boolean['output']
            }
          } | null
        }>
      }>
    }>
    readonly primaryLienWaiverTemplateSets: ReadonlyArray<{
      readonly __typename: 'CompanyLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly name: String['output']
      readonly state: String['output'] | null
      readonly generalContractor: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      } | null
      readonly lienWaivers: {
        readonly __typename: 'SubcontractorLienWaiverTemplateSet'
        readonly id: ID['output']
        readonly conditionalFinalVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly conditionalProgressVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly unconditionalFinalVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly unconditionalProgressVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
      } | null
    }>
    readonly vendorLienWaiverTemplateSets: ReadonlyArray<{
      readonly __typename: 'CompanyLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly name: String['output']
      readonly state: String['output'] | null
      readonly generalContractor: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      } | null
      readonly lienWaivers: {
        readonly __typename: 'SubcontractorLienWaiverTemplateSet'
        readonly id: ID['output']
        readonly conditionalFinalVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly conditionalProgressVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly unconditionalFinalVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly unconditionalProgressVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
      } | null
    }>
    readonly defaultChangeOrderRequestTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
    } | null
    readonly defaultChangeOrderLogTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
    } | null
    readonly picture: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
    } | null
    readonly notaryStamp: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
    } | null
    readonly notarySignature: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
    } | null
    readonly secondarySignature: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
    } | null
    readonly locations: ReadonlyArray<{
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    }>
    readonly metadata: {
      readonly __typename: 'CompanyMetadata'
      readonly subtrade: String['output'] | null
      readonly domains: ReadonlyArray<String['output']>
      readonly domainSignup: Boolean['output']
      readonly monthlyCreatePayAppReminderDate: Int['output'] | null
    }
    readonly defaultQuickBillingTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
    } | null
    readonly sitelinePointOfContact: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
    } | null
  }
}

export type CreateCompanyFormTemplateCollectionMutationVariables = Exact<{
  input: CreateCompanyFormTemplateCollectionInput
}>

export type CreateCompanyFormTemplateCollectionMutation = {
  readonly __typename: 'Mutation'
  readonly createCompanyFormTemplateCollection: {
    readonly __typename: 'Company'
    readonly id: ID['output']
    readonly formTemplateCollections: ReadonlyArray<{
      readonly __typename: 'CompanyFormTemplateCollection'
      readonly id: ID['output']
      readonly name: String['output']
      readonly state: String['output'] | null
      readonly billingType: BillingType
      readonly generalContractor: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      } | null
      readonly payAppForms: {
        readonly __typename: 'CompanyPayAppFormTemplateSet'
        readonly id: ID['output']
        readonly name: String['output']
        readonly state: String['output'] | null
        readonly billingType: BillingType
        readonly generalContractor: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        } | null
        readonly payAppRequirementGroups: ReadonlyArray<{
          readonly __typename: 'PayAppRequirementGroup'
          readonly id: ID['output']
          readonly order: Int['output']
          readonly payAppRequirements: ReadonlyArray<{
            readonly __typename: 'PayAppRequirement'
            readonly id: ID['output']
            readonly groupOrder: Int['output']
            readonly conditions: ReadonlyArray<PayAppRequirementCondition>
            readonly templateVariant: {
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly userVisibleName: String['output'] | null
              readonly template: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly isCustomerReady: Boolean['output']
              }
            } | null
          }>
        }>
      } | null
      readonly primaryLienWaivers: {
        readonly __typename: 'CompanyLienWaiverTemplateSet'
        readonly id: ID['output']
        readonly name: String['output']
        readonly state: String['output'] | null
        readonly generalContractor: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        } | null
        readonly lienWaivers: {
          readonly __typename: 'SubcontractorLienWaiverTemplateSet'
          readonly id: ID['output']
          readonly conditionalFinalVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
          readonly conditionalProgressVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
          readonly unconditionalFinalVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
          readonly unconditionalProgressVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
        } | null
      } | null
      readonly vendorLienWaivers: {
        readonly __typename: 'CompanyLienWaiverTemplateSet'
        readonly id: ID['output']
        readonly name: String['output']
        readonly state: String['output'] | null
        readonly generalContractor: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        } | null
        readonly lienWaivers: {
          readonly __typename: 'SubcontractorLienWaiverTemplateSet'
          readonly id: ID['output']
          readonly conditionalFinalVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
          readonly conditionalProgressVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
          readonly unconditionalFinalVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
          readonly unconditionalProgressVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
        } | null
      } | null
      readonly changeOrderRequest: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly internalName: String['output']
        readonly isDefaultVariant: Boolean['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly isCustomerReady: Boolean['output']
          readonly userVisibleName: String['output']
          readonly skippedValidation: Boolean['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
        }
      } | null
      readonly changeOrderLog: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly internalName: String['output']
        readonly isDefaultVariant: Boolean['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly isCustomerReady: Boolean['output']
          readonly userVisibleName: String['output']
          readonly skippedValidation: Boolean['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
        }
      } | null
    }>
  }
}

export type UpdateCompanyFormTemplateCollectionMutationVariables = Exact<{
  input: UpdateCompanyFormTemplateCollectionInput
}>

export type UpdateCompanyFormTemplateCollectionMutation = {
  readonly __typename: 'Mutation'
  readonly updateCompanyFormTemplateCollection: {
    readonly __typename: 'Company'
    readonly id: ID['output']
    readonly formTemplateCollections: ReadonlyArray<{
      readonly __typename: 'CompanyFormTemplateCollection'
      readonly id: ID['output']
      readonly name: String['output']
      readonly state: String['output'] | null
      readonly billingType: BillingType
      readonly generalContractor: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      } | null
      readonly payAppForms: {
        readonly __typename: 'CompanyPayAppFormTemplateSet'
        readonly id: ID['output']
        readonly name: String['output']
        readonly state: String['output'] | null
        readonly billingType: BillingType
        readonly generalContractor: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        } | null
        readonly payAppRequirementGroups: ReadonlyArray<{
          readonly __typename: 'PayAppRequirementGroup'
          readonly id: ID['output']
          readonly order: Int['output']
          readonly payAppRequirements: ReadonlyArray<{
            readonly __typename: 'PayAppRequirement'
            readonly id: ID['output']
            readonly groupOrder: Int['output']
            readonly conditions: ReadonlyArray<PayAppRequirementCondition>
            readonly templateVariant: {
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly userVisibleName: String['output'] | null
              readonly template: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly isCustomerReady: Boolean['output']
              }
            } | null
          }>
        }>
      } | null
      readonly primaryLienWaivers: {
        readonly __typename: 'CompanyLienWaiverTemplateSet'
        readonly id: ID['output']
        readonly name: String['output']
        readonly state: String['output'] | null
        readonly generalContractor: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        } | null
        readonly lienWaivers: {
          readonly __typename: 'SubcontractorLienWaiverTemplateSet'
          readonly id: ID['output']
          readonly conditionalFinalVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
          readonly conditionalProgressVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
          readonly unconditionalFinalVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
          readonly unconditionalProgressVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
        } | null
      } | null
      readonly vendorLienWaivers: {
        readonly __typename: 'CompanyLienWaiverTemplateSet'
        readonly id: ID['output']
        readonly name: String['output']
        readonly state: String['output'] | null
        readonly generalContractor: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        } | null
        readonly lienWaivers: {
          readonly __typename: 'SubcontractorLienWaiverTemplateSet'
          readonly id: ID['output']
          readonly conditionalFinalVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
          readonly conditionalProgressVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
          readonly unconditionalFinalVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
          readonly unconditionalProgressVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
        } | null
      } | null
      readonly changeOrderRequest: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly internalName: String['output']
        readonly isDefaultVariant: Boolean['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly isCustomerReady: Boolean['output']
          readonly userVisibleName: String['output']
          readonly skippedValidation: Boolean['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
        }
      } | null
      readonly changeOrderLog: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly internalName: String['output']
        readonly isDefaultVariant: Boolean['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly isCustomerReady: Boolean['output']
          readonly userVisibleName: String['output']
          readonly skippedValidation: Boolean['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
        }
      } | null
    }>
  }
}

export type DeleteCompanyFormTemplateCollectionMutationVariables = Exact<{
  input: DeleteCompanyFormTemplateCollectionInput
}>

export type DeleteCompanyFormTemplateCollectionMutation = {
  readonly __typename: 'Mutation'
  readonly deleteCompanyFormTemplateCollection: {
    readonly __typename: 'Company'
    readonly id: ID['output']
    readonly formTemplateCollections: ReadonlyArray<{
      readonly __typename: 'CompanyFormTemplateCollection'
      readonly id: ID['output']
      readonly name: String['output']
      readonly state: String['output'] | null
      readonly billingType: BillingType
      readonly generalContractor: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      } | null
      readonly payAppForms: {
        readonly __typename: 'CompanyPayAppFormTemplateSet'
        readonly id: ID['output']
        readonly name: String['output']
        readonly state: String['output'] | null
        readonly billingType: BillingType
        readonly generalContractor: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        } | null
        readonly payAppRequirementGroups: ReadonlyArray<{
          readonly __typename: 'PayAppRequirementGroup'
          readonly id: ID['output']
          readonly order: Int['output']
          readonly payAppRequirements: ReadonlyArray<{
            readonly __typename: 'PayAppRequirement'
            readonly id: ID['output']
            readonly groupOrder: Int['output']
            readonly conditions: ReadonlyArray<PayAppRequirementCondition>
            readonly templateVariant: {
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly userVisibleName: String['output'] | null
              readonly template: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly isCustomerReady: Boolean['output']
              }
            } | null
          }>
        }>
      } | null
      readonly primaryLienWaivers: {
        readonly __typename: 'CompanyLienWaiverTemplateSet'
        readonly id: ID['output']
        readonly name: String['output']
        readonly state: String['output'] | null
        readonly generalContractor: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        } | null
        readonly lienWaivers: {
          readonly __typename: 'SubcontractorLienWaiverTemplateSet'
          readonly id: ID['output']
          readonly conditionalFinalVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
          readonly conditionalProgressVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
          readonly unconditionalFinalVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
          readonly unconditionalProgressVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
        } | null
      } | null
      readonly vendorLienWaivers: {
        readonly __typename: 'CompanyLienWaiverTemplateSet'
        readonly id: ID['output']
        readonly name: String['output']
        readonly state: String['output'] | null
        readonly generalContractor: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        } | null
        readonly lienWaivers: {
          readonly __typename: 'SubcontractorLienWaiverTemplateSet'
          readonly id: ID['output']
          readonly conditionalFinalVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
          readonly conditionalProgressVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
          readonly unconditionalFinalVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
          readonly unconditionalProgressVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly createdAt: DateTime['output']
              readonly type: FormTemplateType
              readonly status: FormTemplateStatus
              readonly skippedValidation: Boolean['output']
              readonly userVisibleName: String['output']
              readonly isCustomerReady: Boolean['output']
              readonly tags: ReadonlyArray<FormTemplateTag>
              readonly dueDate: DateTime['output'] | null
              readonly statusLogs: ReadonlyArray<{
                readonly __typename: 'FormTemplateStatusLog'
                readonly id: ID['output']
                readonly statusUpdatedAt: DateTime['output']
                readonly status: FormTemplateStatus
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly associatedCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly requestingCompany: {
                readonly __typename: 'Company'
                readonly id: ID['output']
              } | null
              readonly associatedContracts: ReadonlyArray<{
                readonly __typename: 'FormTemplateAssociatedContract'
                readonly id: String['output']
                readonly providedAsFormType: ProjectOnboardingFormType | null
                readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
              }>
              readonly owner: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly builder: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              }
              readonly validator: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
              } | null
              readonly versions: ReadonlyArray<{
                readonly __typename: 'FormTemplateVersion'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly updatedAt: DateTime['output']
                readonly versionNumber: Int['output']
                readonly isReadyForManualStoredMaterials: Boolean['output']
                readonly file: {
                  readonly __typename: 'StoredFile'
                  readonly id: ID['output']
                  readonly type: StoredFileType
                  readonly url: URL['output']
                  readonly name: String['output']
                }
                readonly annotations: ReadonlyArray<{
                  readonly __typename: 'FormTemplateAnnotation'
                  readonly id: ID['output']
                  readonly permanentId: ID['output']
                  readonly syncTag: String['output'] | null
                  readonly userVisibleName: String['output']
                  readonly type: FormTemplateAnnotationType
                  readonly xStart: Float['output']
                  readonly yStart: Float['output']
                  readonly width: Float['output']
                  readonly height: Float['output']
                  readonly pageNumber: Int['output']
                  readonly fontFamily: FormTemplateFont
                  readonly fontColor: String['output']
                  readonly textAlignment: TextAlignment
                  readonly wrapText: Boolean['output']
                  readonly isOptional: Boolean['output']
                  readonly selectedKey: String['output'] | null
                  readonly defaultValueKey: String['output'] | null
                  readonly prefix: String['output'] | null
                  readonly suffix: String['output'] | null
                  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                  readonly doNotRetainOnReset: Boolean['output']
                  readonly dynamicFieldTag: String['output'] | null
                  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                  readonly imageType: FormTemplateAnnotationImageType | null
                  readonly signatureType: SignatureAnnotationType | null
                }>
              }>
              readonly variants: ReadonlyArray<{
                readonly __typename: 'FormTemplateVariant'
                readonly id: ID['output']
                readonly internalName: String['output']
                readonly isDefaultVariant: Boolean['output']
              }>
              readonly comments: ReadonlyArray<{
                readonly __typename: 'FormTemplateComment'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly message: String['output']
                readonly isSystemGenerated: Boolean['output']
                readonly user: {
                  readonly __typename: 'User'
                  readonly id: ID['output']
                  readonly firstName: String['output']
                  readonly lastName: String['output']
                  readonly email: EmailAddress['output']
                }
              }>
              readonly originalFile: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              } | null
              readonly duplicateTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
                readonly userVisibleName: String['output']
              } | null
            }
          } | null
        } | null
      } | null
      readonly changeOrderRequest: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly internalName: String['output']
        readonly isDefaultVariant: Boolean['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly isCustomerReady: Boolean['output']
          readonly userVisibleName: String['output']
          readonly skippedValidation: Boolean['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
        }
      } | null
      readonly changeOrderLog: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly internalName: String['output']
        readonly isDefaultVariant: Boolean['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly isCustomerReady: Boolean['output']
          readonly userVisibleName: String['output']
          readonly skippedValidation: Boolean['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
        }
      } | null
    }>
  }
}

export type RotateExternalApiTokenMutationVariables = Exact<{
  companyId: Scalars['ID']['input']
}>

export type RotateExternalApiTokenMutation = {
  readonly __typename: 'Mutation'
  readonly rotateExternalApiToken: String['output']
}

export type ArchiveCompanyIntegrationMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ArchiveCompanyIntegrationMutation = {
  readonly __typename: 'Mutation'
  readonly archiveCompanyIntegration: {
    readonly __typename: 'CompanyIntegration'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly type: IntegrationType
    readonly label: String['output'] | null
    readonly shortName: String['output']
    readonly longName: String['output']
    readonly credentialsUpdatedAt: DateTime['output'] | null
    readonly credentialsReadyForAutoRotation: Boolean['output']
    readonly archivedAt: DateTime['output'] | null
    readonly shouldImportNewProjectSov: Boolean['output']
    readonly metadata: JSONObject['output']
    readonly importChangeOrdersMethod: ImportIntegrationChangeOrdersMethod
    readonly importComboJobMethod: ImportIntegrationComboJobMethod
    readonly importProjectNameSource: ImportIntegrationProjectNameSource
    readonly revertPayAppsToDraftWhenTexturaInvoiceRejected: Boolean['output']
    readonly location: {
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    } | null
  }
}

export type UnarchiveCompanyIntegrationMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type UnarchiveCompanyIntegrationMutation = {
  readonly __typename: 'Mutation'
  readonly unarchiveCompanyIntegration: {
    readonly __typename: 'CompanyIntegration'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly type: IntegrationType
    readonly label: String['output'] | null
    readonly shortName: String['output']
    readonly longName: String['output']
    readonly credentialsUpdatedAt: DateTime['output'] | null
    readonly credentialsReadyForAutoRotation: Boolean['output']
    readonly archivedAt: DateTime['output'] | null
    readonly shouldImportNewProjectSov: Boolean['output']
    readonly metadata: JSONObject['output']
    readonly importChangeOrdersMethod: ImportIntegrationChangeOrdersMethod
    readonly importComboJobMethod: ImportIntegrationComboJobMethod
    readonly importProjectNameSource: ImportIntegrationProjectNameSource
    readonly revertPayAppsToDraftWhenTexturaInvoiceRejected: Boolean['output']
    readonly location: {
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    } | null
  }
}

export type MigrateIntegrationContractsMutationVariables = Exact<{
  input: MigrateIntegrationContractsInput
}>

export type MigrateIntegrationContractsMutation = {
  readonly __typename: 'Mutation'
  readonly migrateIntegrationContracts: {
    readonly __typename: 'IntegrationProjectMigrationSummary'
    readonly contractsQueued: ReadonlyArray<{
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly internalProjectNumber: String['output'] | null
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly name: String['output']
        readonly projectNumber: String['output']
      }
    }>
    readonly contractsIgnored: ReadonlyArray<{
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly internalProjectNumber: String['output'] | null
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly name: String['output']
        readonly projectNumber: String['output']
      }
    }>
  }
}

export type UpdateIntegrationCredentialMutationVariables = Exact<{
  input: UpdateIntegrationCredentialInput
}>

export type UpdateIntegrationCredentialMutation = {
  readonly __typename: 'Mutation'
  readonly updateIntegrationCredential: {
    readonly __typename: 'CompanyIntegration'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly type: IntegrationType
    readonly label: String['output'] | null
    readonly shortName: String['output']
    readonly longName: String['output']
    readonly credentialsUpdatedAt: DateTime['output'] | null
    readonly credentialsReadyForAutoRotation: Boolean['output']
    readonly archivedAt: DateTime['output'] | null
    readonly shouldImportNewProjectSov: Boolean['output']
    readonly metadata: JSONObject['output']
    readonly importChangeOrdersMethod: ImportIntegrationChangeOrdersMethod
    readonly importComboJobMethod: ImportIntegrationComboJobMethod
    readonly importProjectNameSource: ImportIntegrationProjectNameSource
    readonly revertPayAppsToDraftWhenTexturaInvoiceRejected: Boolean['output']
    readonly location: {
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    } | null
  }
}

export type ExportIntegrationCredentialsToPostmanMutationVariables = Exact<{
  input: ExportIntegrationCredentialsToPostmanInput
}>

export type ExportIntegrationCredentialsToPostmanMutation = {
  readonly __typename: 'Mutation'
  readonly exportIntegrationCredentialsToPostman: Boolean['output']
}

export type RotateIntegrationCredentialMutationVariables = Exact<{
  input: RotateIntegrationCredentialInput
}>

export type RotateIntegrationCredentialMutation = {
  readonly __typename: 'Mutation'
  readonly rotateIntegrationCredential: {
    readonly __typename: 'CompanyIntegration'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly type: IntegrationType
    readonly label: String['output'] | null
    readonly shortName: String['output']
    readonly longName: String['output']
    readonly credentialsUpdatedAt: DateTime['output'] | null
    readonly credentialsReadyForAutoRotation: Boolean['output']
    readonly archivedAt: DateTime['output'] | null
    readonly shouldImportNewProjectSov: Boolean['output']
    readonly metadata: JSONObject['output']
    readonly importChangeOrdersMethod: ImportIntegrationChangeOrdersMethod
    readonly importComboJobMethod: ImportIntegrationComboJobMethod
    readonly importProjectNameSource: ImportIntegrationProjectNameSource
    readonly revertPayAppsToDraftWhenTexturaInvoiceRejected: Boolean['output']
    readonly location: {
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    } | null
  }
}

export type CompanyForCredentialsQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type CompanyForCredentialsQuery = {
  readonly __typename: 'Query'
  readonly company: {
    readonly __typename: 'Company'
    readonly id: ID['output']
    readonly companyIntegrations: ReadonlyArray<{
      readonly __typename: 'CompanyIntegration'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly type: IntegrationType
      readonly label: String['output'] | null
      readonly shortName: String['output']
      readonly longName: String['output']
      readonly credentialsUpdatedAt: DateTime['output'] | null
      readonly credentialsReadyForAutoRotation: Boolean['output']
      readonly archivedAt: DateTime['output'] | null
      readonly shouldImportNewProjectSov: Boolean['output']
      readonly metadata: JSONObject['output']
      readonly importChangeOrdersMethod: ImportIntegrationChangeOrdersMethod
      readonly importComboJobMethod: ImportIntegrationComboJobMethod
      readonly importProjectNameSource: ImportIntegrationProjectNameSource
      readonly revertPayAppsToDraftWhenTexturaInvoiceRejected: Boolean['output']
      readonly location: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      } | null
    }>
  }
}

export type AgaveDebuggerSessionUrlQueryVariables = Exact<{
  input: AgaveDebuggerSessionUrlInput
}>

export type AgaveDebuggerSessionUrlQuery = {
  readonly __typename: 'Query'
  readonly agaveDebuggerSessionUrl: String['output'] | null
}

export type UpdateLocationRequestMutationVariables = Exact<{
  input: UpdateLocationInput
}>

export type UpdateLocationRequestMutation = {
  readonly __typename: 'Mutation'
  readonly updateLocation: {
    readonly __typename: 'Location'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly nickname: String['output'] | null
    readonly street1: String['output'] | null
    readonly street2: String['output'] | null
    readonly city: String['output']
    readonly county: String['output'] | null
    readonly state: String['output']
    readonly country: String['output']
    readonly postalCode: String['output'] | null
    readonly timeZone: String['output']
    readonly coordinates: LatLng['output']
  }
}

export type CreateLocationRequestMutationVariables = Exact<{
  input: CreateLocationInput
}>

export type CreateLocationRequestMutation = {
  readonly __typename: 'Mutation'
  readonly createLocation: {
    readonly __typename: 'Location'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly nickname: String['output'] | null
    readonly street1: String['output'] | null
    readonly street2: String['output'] | null
    readonly city: String['output']
    readonly county: String['output'] | null
    readonly state: String['output']
    readonly country: String['output']
    readonly postalCode: String['output'] | null
    readonly timeZone: String['output']
    readonly coordinates: LatLng['output']
  }
}

export type SetCompanyInternalSitelineNotesMutationVariables = Exact<{
  input: SetCompanyInternalSitelineNotesInput
}>

export type SetCompanyInternalSitelineNotesMutation = {
  readonly __typename: 'Mutation'
  readonly setCompanyInternalSitelineNotes: Boolean['output']
}

export type CompanyInternalSitelineNotesQueryVariables = Exact<{
  companyId: Scalars['ID']['input']
}>

export type CompanyInternalSitelineNotesQuery = {
  readonly __typename: 'Query'
  readonly companyInternalSitelineNotes: String['output'] | null
}

export type ProjectsByCompanyQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ProjectsByCompanyQuery = {
  readonly __typename: 'Query'
  readonly projectsByCompany: ReadonlyArray<{
    readonly __typename: 'Project'
    readonly id: ID['output']
    readonly name: String['output']
    readonly projectNumber: String['output']
    readonly contracts: ReadonlyArray<{
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly contractNumber: String['output'] | null
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      }
    }>
    readonly location: {
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    }
    readonly generalContractor: {
      readonly __typename: 'CompanyProjectMetadata'
      readonly role: CompanyProjectRole
      readonly companyName: String['output'] | null
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
        readonly nickname: String['output'] | null
        readonly type: CompanyType
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly expectedNumberOfProjects: SafeInt['output'] | null
        readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
        readonly allowLumpSumOverbilling: Boolean['output']
        readonly notaryTags: ReadonlyArray<NotaryTag>
        readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
        readonly isDemoCompany: Boolean['output']
        readonly picture: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notaryStamp: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly secondarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly locations: ReadonlyArray<{
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        }>
        readonly metadata: {
          readonly __typename: 'CompanyMetadata'
          readonly subtrade: String['output'] | null
          readonly domains: ReadonlyArray<String['output']>
          readonly domainSignup: Boolean['output']
          readonly monthlyCreatePayAppReminderDate: Int['output'] | null
        }
        readonly defaultQuickBillingTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
        } | null
        readonly sitelinePointOfContact: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
        } | null
      }
      readonly selectedAddress: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      } | null
    } | null
  }>
}

export type DisableUserMutationVariables = Exact<{
  input: DisableUserInput
}>

export type DisableUserMutation = {
  readonly __typename: 'Mutation'
  readonly disableUser: {
    readonly __typename: 'User'
    readonly blockedEmails: ReadonlyArray<EmailType>
    readonly blockedNotifications: ReadonlyArray<NotificationType>
    readonly policiesAcceptedAt: DateTime['output'] | null
    readonly id: ID['output']
    readonly firstName: String['output']
    readonly lastName: String['output']
    readonly email: EmailAddress['output']
    readonly jobTitle: String['output'] | null
    readonly phoneNumber: PhoneNumber['output'] | null
    readonly companies: ReadonlyArray<{
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
      readonly nickname: String['output'] | null
      readonly type: CompanyType
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly expectedNumberOfProjects: SafeInt['output'] | null
      readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
      readonly allowLumpSumOverbilling: Boolean['output']
      readonly notaryTags: ReadonlyArray<NotaryTag>
      readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
      readonly isDemoCompany: Boolean['output']
      readonly picture: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly notaryStamp: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly notarySignature: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly secondarySignature: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly locations: ReadonlyArray<{
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }>
      readonly metadata: {
        readonly __typename: 'CompanyMetadata'
        readonly subtrade: String['output'] | null
        readonly domains: ReadonlyArray<String['output']>
        readonly domainSignup: Boolean['output']
        readonly monthlyCreatePayAppReminderDate: Int['output'] | null
      }
      readonly defaultQuickBillingTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
      } | null
      readonly sitelinePointOfContact: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
      } | null
    }>
    readonly companyUsers: ReadonlyArray<{
      readonly __typename: 'CompanyUser'
      readonly id: ID['output']
      readonly status: UserStatus
      readonly role: CompanyUserRole
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
        readonly nickname: String['output'] | null
        readonly type: CompanyType
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly expectedNumberOfProjects: SafeInt['output'] | null
        readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
        readonly allowLumpSumOverbilling: Boolean['output']
        readonly notaryTags: ReadonlyArray<NotaryTag>
        readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
        readonly isDemoCompany: Boolean['output']
        readonly picture: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notaryStamp: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly secondarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly locations: ReadonlyArray<{
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        }>
        readonly metadata: {
          readonly __typename: 'CompanyMetadata'
          readonly subtrade: String['output'] | null
          readonly domains: ReadonlyArray<String['output']>
          readonly domainSignup: Boolean['output']
          readonly monthlyCreatePayAppReminderDate: Int['output'] | null
        }
        readonly defaultQuickBillingTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
        } | null
        readonly sitelinePointOfContact: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
        } | null
      }
    }>
  }
}

export type EnableUserMutationVariables = Exact<{
  input: EnableUserInput
}>

export type EnableUserMutation = {
  readonly __typename: 'Mutation'
  readonly enableUser: {
    readonly __typename: 'User'
    readonly blockedEmails: ReadonlyArray<EmailType>
    readonly blockedNotifications: ReadonlyArray<NotificationType>
    readonly policiesAcceptedAt: DateTime['output'] | null
    readonly id: ID['output']
    readonly firstName: String['output']
    readonly lastName: String['output']
    readonly email: EmailAddress['output']
    readonly jobTitle: String['output'] | null
    readonly phoneNumber: PhoneNumber['output'] | null
    readonly companies: ReadonlyArray<{
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
      readonly nickname: String['output'] | null
      readonly type: CompanyType
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly expectedNumberOfProjects: SafeInt['output'] | null
      readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
      readonly allowLumpSumOverbilling: Boolean['output']
      readonly notaryTags: ReadonlyArray<NotaryTag>
      readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
      readonly isDemoCompany: Boolean['output']
      readonly picture: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly notaryStamp: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly notarySignature: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly secondarySignature: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly locations: ReadonlyArray<{
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }>
      readonly metadata: {
        readonly __typename: 'CompanyMetadata'
        readonly subtrade: String['output'] | null
        readonly domains: ReadonlyArray<String['output']>
        readonly domainSignup: Boolean['output']
        readonly monthlyCreatePayAppReminderDate: Int['output'] | null
      }
      readonly defaultQuickBillingTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
      } | null
      readonly sitelinePointOfContact: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
      } | null
    }>
    readonly companyUsers: ReadonlyArray<{
      readonly __typename: 'CompanyUser'
      readonly id: ID['output']
      readonly status: UserStatus
      readonly role: CompanyUserRole
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
        readonly nickname: String['output'] | null
        readonly type: CompanyType
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly expectedNumberOfProjects: SafeInt['output'] | null
        readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
        readonly allowLumpSumOverbilling: Boolean['output']
        readonly notaryTags: ReadonlyArray<NotaryTag>
        readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
        readonly isDemoCompany: Boolean['output']
        readonly picture: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notaryStamp: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly secondarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly locations: ReadonlyArray<{
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        }>
        readonly metadata: {
          readonly __typename: 'CompanyMetadata'
          readonly subtrade: String['output'] | null
          readonly domains: ReadonlyArray<String['output']>
          readonly domainSignup: Boolean['output']
          readonly monthlyCreatePayAppReminderDate: Int['output'] | null
        }
        readonly defaultQuickBillingTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
        } | null
        readonly sitelinePointOfContact: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
        } | null
      }
    }>
  }
}

export type DeleteVendorMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteVendorMutation = {
  readonly __typename: 'Mutation'
  readonly deleteVendor: { readonly __typename: 'DeletionResult'; readonly id: ID['output'] }
}

export type AgaveLinkTokenQueryVariables = Exact<{
  companyIntegrationId: Scalars['ID']['input']
}>

export type AgaveLinkTokenQuery = {
  readonly __typename: 'Query'
  readonly agaveLinkToken: String['output']
}

export type UpdateAgaveIntegrationCredentialMutationVariables = Exact<{
  input: UpdateAgaveIntegrationCredentialInput
}>

export type UpdateAgaveIntegrationCredentialMutation = {
  readonly __typename: 'Mutation'
  readonly updateAgaveIntegrationCredential: {
    readonly __typename: 'CompanyIntegration'
    readonly id: ID['output']
    readonly credentialsUpdatedAt: DateTime['output'] | null
  }
}

export type IntegrationOnePasswordItemsQueryVariables = Exact<{ [key: string]: never }>

export type IntegrationOnePasswordItemsQuery = {
  readonly __typename: 'Query'
  readonly integrationOnePasswordItems: ReadonlyArray<{
    readonly __typename: 'IntegrationOnePasswordItem'
    readonly vaultId: ID['output']
    readonly itemId: ID['output']
    readonly title: String['output']
  }>
}

export type EnableIntegrationCredentialAutoRotationMutationVariables = Exact<{
  input: EnableIntegrationCredentialAutoRotationInput
}>

export type EnableIntegrationCredentialAutoRotationMutation = {
  readonly __typename: 'Mutation'
  readonly enableIntegrationCredentialAutoRotation: {
    readonly __typename: 'CompanyIntegration'
    readonly id: ID['output']
    readonly credentialsUpdatedAt: DateTime['output'] | null
    readonly credentialsReadyForAutoRotation: Boolean['output']
  }
}

export type GetGeneralContractorsQueryVariables = Exact<{
  companyId: Scalars['ID']['input']
}>

export type GetGeneralContractorsQuery = {
  readonly __typename: 'Query'
  readonly generalContractors: ReadonlyArray<{
    readonly __typename: 'Company'
    readonly id: ID['output']
    readonly name: String['output']
  }>
}

export type CreateLienWaiverFormTemplateSetMutationVariables = Exact<{
  input: CreateLienWaiverFormTemplateSetInput
}>

export type CreateLienWaiverFormTemplateSetMutation = {
  readonly __typename: 'Mutation'
  readonly createLienWaiverFormTemplateSet: {
    readonly __typename: 'Company'
    readonly id: ID['output']
    readonly primaryLienWaiverTemplateSets: ReadonlyArray<{
      readonly __typename: 'CompanyLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly name: String['output']
      readonly state: String['output'] | null
      readonly generalContractor: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      } | null
      readonly lienWaivers: {
        readonly __typename: 'SubcontractorLienWaiverTemplateSet'
        readonly id: ID['output']
        readonly conditionalFinalVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly conditionalProgressVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly unconditionalFinalVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly unconditionalProgressVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
      } | null
    }>
    readonly vendorLienWaiverTemplateSets: ReadonlyArray<{
      readonly __typename: 'CompanyLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly name: String['output']
      readonly state: String['output'] | null
      readonly generalContractor: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      } | null
      readonly lienWaivers: {
        readonly __typename: 'SubcontractorLienWaiverTemplateSet'
        readonly id: ID['output']
        readonly conditionalFinalVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly conditionalProgressVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly unconditionalFinalVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly unconditionalProgressVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
      } | null
    }>
  }
}

export type UpdateLienWaiverFormTemplateSetMutationVariables = Exact<{
  input: UpdateLienWaiverFormTemplateSetInput
}>

export type UpdateLienWaiverFormTemplateSetMutation = {
  readonly __typename: 'Mutation'
  readonly updateLienWaiverFormTemplateSet: {
    readonly __typename: 'Company'
    readonly id: ID['output']
    readonly primaryLienWaiverTemplateSets: ReadonlyArray<{
      readonly __typename: 'CompanyLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly name: String['output']
      readonly state: String['output'] | null
      readonly generalContractor: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      } | null
      readonly lienWaivers: {
        readonly __typename: 'SubcontractorLienWaiverTemplateSet'
        readonly id: ID['output']
        readonly conditionalFinalVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly conditionalProgressVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly unconditionalFinalVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly unconditionalProgressVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
      } | null
    }>
    readonly vendorLienWaiverTemplateSets: ReadonlyArray<{
      readonly __typename: 'CompanyLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly name: String['output']
      readonly state: String['output'] | null
      readonly generalContractor: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      } | null
      readonly lienWaivers: {
        readonly __typename: 'SubcontractorLienWaiverTemplateSet'
        readonly id: ID['output']
        readonly conditionalFinalVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly conditionalProgressVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly unconditionalFinalVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly unconditionalProgressVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
      } | null
    }>
  }
}

export type DeleteLienWaiverFormTemplateSetMutationVariables = Exact<{
  input: DeleteLienWaiverFormTemplateSetInput
}>

export type DeleteLienWaiverFormTemplateSetMutation = {
  readonly __typename: 'Mutation'
  readonly deleteLienWaiverFormTemplateSet: {
    readonly __typename: 'Company'
    readonly id: ID['output']
    readonly primaryLienWaiverTemplateSets: ReadonlyArray<{
      readonly __typename: 'CompanyLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly name: String['output']
      readonly state: String['output'] | null
      readonly generalContractor: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      } | null
      readonly lienWaivers: {
        readonly __typename: 'SubcontractorLienWaiverTemplateSet'
        readonly id: ID['output']
        readonly conditionalFinalVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly conditionalProgressVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly unconditionalFinalVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly unconditionalProgressVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
      } | null
    }>
    readonly vendorLienWaiverTemplateSets: ReadonlyArray<{
      readonly __typename: 'CompanyLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly name: String['output']
      readonly state: String['output'] | null
      readonly generalContractor: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      } | null
      readonly lienWaivers: {
        readonly __typename: 'SubcontractorLienWaiverTemplateSet'
        readonly id: ID['output']
        readonly conditionalFinalVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly conditionalProgressVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly unconditionalFinalVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly unconditionalProgressVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
      } | null
    }>
  }
}

export type CreatePayAppFormTemplateSetMutationVariables = Exact<{
  input: CreatePayAppFormTemplateSetInput
}>

export type CreatePayAppFormTemplateSetMutation = {
  readonly __typename: 'Mutation'
  readonly createPayAppFormTemplateSet: {
    readonly __typename: 'Company'
    readonly id: ID['output']
    readonly payAppFormTemplateSets: ReadonlyArray<{
      readonly __typename: 'CompanyPayAppFormTemplateSet'
      readonly id: ID['output']
      readonly name: String['output']
      readonly state: String['output'] | null
      readonly billingType: BillingType
      readonly generalContractor: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      } | null
      readonly payAppRequirementGroups: ReadonlyArray<{
        readonly __typename: 'PayAppRequirementGroup'
        readonly id: ID['output']
        readonly order: Int['output']
        readonly payAppRequirements: ReadonlyArray<{
          readonly __typename: 'PayAppRequirement'
          readonly id: ID['output']
          readonly groupOrder: Int['output']
          readonly conditions: ReadonlyArray<PayAppRequirementCondition>
          readonly templateVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly userVisibleName: String['output'] | null
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly isCustomerReady: Boolean['output']
            }
          } | null
        }>
      }>
    }>
  }
}

export type UpdatePayAppFormTemplateSetMutationVariables = Exact<{
  input: UpdatePayAppFormTemplateSetInput
}>

export type UpdatePayAppFormTemplateSetMutation = {
  readonly __typename: 'Mutation'
  readonly updatePayAppFormTemplateSet: {
    readonly __typename: 'Company'
    readonly id: ID['output']
    readonly payAppFormTemplateSets: ReadonlyArray<{
      readonly __typename: 'CompanyPayAppFormTemplateSet'
      readonly id: ID['output']
      readonly name: String['output']
      readonly state: String['output'] | null
      readonly billingType: BillingType
      readonly generalContractor: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      } | null
      readonly payAppRequirementGroups: ReadonlyArray<{
        readonly __typename: 'PayAppRequirementGroup'
        readonly id: ID['output']
        readonly order: Int['output']
        readonly payAppRequirements: ReadonlyArray<{
          readonly __typename: 'PayAppRequirement'
          readonly id: ID['output']
          readonly groupOrder: Int['output']
          readonly conditions: ReadonlyArray<PayAppRequirementCondition>
          readonly templateVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly userVisibleName: String['output'] | null
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly isCustomerReady: Boolean['output']
            }
          } | null
        }>
      }>
    }>
  }
}

export type DeletePayAppFormTemplateSetMutationVariables = Exact<{
  input: DeletePayAppFormTemplateSetInput
}>

export type DeletePayAppFormTemplateSetMutation = {
  readonly __typename: 'Mutation'
  readonly deletePayAppFormTemplateSet: {
    readonly __typename: 'Company'
    readonly id: ID['output']
    readonly payAppFormTemplateSets: ReadonlyArray<{
      readonly __typename: 'CompanyPayAppFormTemplateSet'
      readonly id: ID['output']
      readonly name: String['output']
      readonly state: String['output'] | null
      readonly billingType: BillingType
      readonly generalContractor: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      } | null
      readonly payAppRequirementGroups: ReadonlyArray<{
        readonly __typename: 'PayAppRequirementGroup'
        readonly id: ID['output']
        readonly order: Int['output']
        readonly payAppRequirements: ReadonlyArray<{
          readonly __typename: 'PayAppRequirement'
          readonly id: ID['output']
          readonly groupOrder: Int['output']
          readonly conditions: ReadonlyArray<PayAppRequirementCondition>
          readonly templateVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly userVisibleName: String['output'] | null
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly isCustomerReady: Boolean['output']
            }
          } | null
        }>
      }>
    }>
  }
}

export type GetContractQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type GetContractQuery = {
  readonly __typename: 'Query'
  readonly contract: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly role: CompanyProjectRole
    readonly status: ContractStatus
    readonly timeZone: String['output']
    readonly contractDate: DateTime['output'] | null
    readonly contractNumber: String['output'] | null
    readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
    readonly defaultRetentionPercent: Float['output'] | null
    readonly retentionTrackingLevel: RetentionTrackingLevel
    readonly daysBeforePayAppDue: Int['output']
    readonly billingType: BillingType
    readonly contractTemplateVariables: JSONObject['output']
    readonly internalProjectNumber: String['output'] | null
    readonly vendorNumber: String['output'] | null
    readonly pastPayAppCount: Int['output']
    readonly roundRetention: Boolean['output']
    readonly project: {
      readonly __typename: 'Project'
      readonly id: ID['output']
      readonly name: String['output']
      readonly projectNumber: String['output']
      readonly location: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }
      readonly generalContractor: {
        readonly __typename: 'CompanyProjectMetadata'
        readonly role: CompanyProjectRole
        readonly companyName: String['output'] | null
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
          readonly nickname: String['output'] | null
          readonly type: CompanyType
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly expectedNumberOfProjects: SafeInt['output'] | null
          readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
          readonly allowLumpSumOverbilling: Boolean['output']
          readonly notaryTags: ReadonlyArray<NotaryTag>
          readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
          readonly isDemoCompany: Boolean['output']
          readonly picture: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly notaryStamp: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly notarySignature: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly secondarySignature: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly locations: ReadonlyArray<{
            readonly __typename: 'Location'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly nickname: String['output'] | null
            readonly street1: String['output'] | null
            readonly street2: String['output'] | null
            readonly city: String['output']
            readonly county: String['output'] | null
            readonly state: String['output']
            readonly country: String['output']
            readonly postalCode: String['output'] | null
            readonly timeZone: String['output']
            readonly coordinates: LatLng['output']
          }>
          readonly metadata: {
            readonly __typename: 'CompanyMetadata'
            readonly subtrade: String['output'] | null
            readonly domains: ReadonlyArray<String['output']>
            readonly domainSignup: Boolean['output']
            readonly monthlyCreatePayAppReminderDate: Int['output'] | null
          }
          readonly defaultQuickBillingTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
          } | null
          readonly sitelinePointOfContact: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
          } | null
        }
        readonly selectedAddress: {
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        } | null
      } | null
    }
    readonly company: {
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
      readonly nickname: String['output'] | null
      readonly type: CompanyType
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly expectedNumberOfProjects: SafeInt['output'] | null
      readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
      readonly allowLumpSumOverbilling: Boolean['output']
      readonly notaryTags: ReadonlyArray<NotaryTag>
      readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
      readonly isDemoCompany: Boolean['output']
      readonly users: ReadonlyArray<{
        readonly __typename: 'CompanyUser'
        readonly id: ID['output']
        readonly role: CompanyUserRole
        readonly isSitelineAdmin: Boolean['output']
        readonly status: UserStatus
        readonly permissions: ReadonlyArray<Permission>
        readonly user: {
          readonly __typename: 'User'
          readonly blockedEmails: ReadonlyArray<EmailType>
          readonly blockedNotifications: ReadonlyArray<NotificationType>
          readonly policiesAcceptedAt: DateTime['output'] | null
          readonly id: ID['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
          readonly email: EmailAddress['output']
          readonly jobTitle: String['output'] | null
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly companies: ReadonlyArray<{
            readonly __typename: 'Company'
            readonly id: ID['output']
            readonly name: String['output']
            readonly nickname: String['output'] | null
            readonly type: CompanyType
            readonly phoneNumber: PhoneNumber['output'] | null
            readonly expectedNumberOfProjects: SafeInt['output'] | null
            readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
            readonly allowLumpSumOverbilling: Boolean['output']
            readonly notaryTags: ReadonlyArray<NotaryTag>
            readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
            readonly isDemoCompany: Boolean['output']
            readonly picture: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly notaryStamp: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly notarySignature: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly secondarySignature: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly locations: ReadonlyArray<{
              readonly __typename: 'Location'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly nickname: String['output'] | null
              readonly street1: String['output'] | null
              readonly street2: String['output'] | null
              readonly city: String['output']
              readonly county: String['output'] | null
              readonly state: String['output']
              readonly country: String['output']
              readonly postalCode: String['output'] | null
              readonly timeZone: String['output']
              readonly coordinates: LatLng['output']
            }>
            readonly metadata: {
              readonly __typename: 'CompanyMetadata'
              readonly subtrade: String['output'] | null
              readonly domains: ReadonlyArray<String['output']>
              readonly domainSignup: Boolean['output']
              readonly monthlyCreatePayAppReminderDate: Int['output'] | null
            }
            readonly defaultQuickBillingTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
            } | null
            readonly sitelinePointOfContact: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            } | null
          }>
          readonly companyUsers: ReadonlyArray<{
            readonly __typename: 'CompanyUser'
            readonly id: ID['output']
            readonly status: UserStatus
            readonly role: CompanyUserRole
            readonly company: {
              readonly __typename: 'Company'
              readonly id: ID['output']
              readonly name: String['output']
              readonly nickname: String['output'] | null
              readonly type: CompanyType
              readonly phoneNumber: PhoneNumber['output'] | null
              readonly expectedNumberOfProjects: SafeInt['output'] | null
              readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
              readonly allowLumpSumOverbilling: Boolean['output']
              readonly notaryTags: ReadonlyArray<NotaryTag>
              readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
              readonly isDemoCompany: Boolean['output']
              readonly picture: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly url: URL['output']
              } | null
              readonly notaryStamp: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly url: URL['output']
              } | null
              readonly notarySignature: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly url: URL['output']
              } | null
              readonly secondarySignature: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly url: URL['output']
              } | null
              readonly locations: ReadonlyArray<{
                readonly __typename: 'Location'
                readonly id: ID['output']
                readonly createdAt: DateTime['output']
                readonly nickname: String['output'] | null
                readonly street1: String['output'] | null
                readonly street2: String['output'] | null
                readonly city: String['output']
                readonly county: String['output'] | null
                readonly state: String['output']
                readonly country: String['output']
                readonly postalCode: String['output'] | null
                readonly timeZone: String['output']
                readonly coordinates: LatLng['output']
              }>
              readonly metadata: {
                readonly __typename: 'CompanyMetadata'
                readonly subtrade: String['output'] | null
                readonly domains: ReadonlyArray<String['output']>
                readonly domainSignup: Boolean['output']
                readonly monthlyCreatePayAppReminderDate: Int['output'] | null
              }
              readonly defaultQuickBillingTemplate: {
                readonly __typename: 'FormTemplate'
                readonly id: String['output']
              } | null
              readonly sitelinePointOfContact: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              } | null
            }
          }>
        }
      }>
      readonly companyIntegrations: ReadonlyArray<{
        readonly __typename: 'CompanyIntegration'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly type: IntegrationType
        readonly label: String['output'] | null
        readonly shortName: String['output']
        readonly longName: String['output']
        readonly credentialsUpdatedAt: DateTime['output'] | null
        readonly credentialsReadyForAutoRotation: Boolean['output']
        readonly archivedAt: DateTime['output'] | null
        readonly shouldImportNewProjectSov: Boolean['output']
        readonly metadata: JSONObject['output']
        readonly importChangeOrdersMethod: ImportIntegrationChangeOrdersMethod
        readonly importComboJobMethod: ImportIntegrationComboJobMethod
        readonly importProjectNameSource: ImportIntegrationProjectNameSource
        readonly revertPayAppsToDraftWhenTexturaInvoiceRejected: Boolean['output']
        readonly location: {
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        } | null
      }>
      readonly picture: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly notaryStamp: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly notarySignature: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly secondarySignature: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly locations: ReadonlyArray<{
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }>
      readonly metadata: {
        readonly __typename: 'CompanyMetadata'
        readonly subtrade: String['output'] | null
        readonly domains: ReadonlyArray<String['output']>
        readonly domainSignup: Boolean['output']
        readonly monthlyCreatePayAppReminderDate: Int['output'] | null
      }
      readonly defaultQuickBillingTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
      } | null
      readonly sitelinePointOfContact: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
      } | null
    }
    readonly users: ReadonlyArray<{
      readonly __typename: 'ContractUser'
      readonly id: ID['output']
      readonly user: {
        readonly __typename: 'User'
        readonly blockedEmails: ReadonlyArray<EmailType>
        readonly blockedNotifications: ReadonlyArray<NotificationType>
        readonly policiesAcceptedAt: DateTime['output'] | null
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
        readonly jobTitle: String['output'] | null
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly companies: ReadonlyArray<{
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
          readonly nickname: String['output'] | null
          readonly type: CompanyType
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly expectedNumberOfProjects: SafeInt['output'] | null
          readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
          readonly allowLumpSumOverbilling: Boolean['output']
          readonly notaryTags: ReadonlyArray<NotaryTag>
          readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
          readonly isDemoCompany: Boolean['output']
          readonly picture: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly notaryStamp: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly notarySignature: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly secondarySignature: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly locations: ReadonlyArray<{
            readonly __typename: 'Location'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly nickname: String['output'] | null
            readonly street1: String['output'] | null
            readonly street2: String['output'] | null
            readonly city: String['output']
            readonly county: String['output'] | null
            readonly state: String['output']
            readonly country: String['output']
            readonly postalCode: String['output'] | null
            readonly timeZone: String['output']
            readonly coordinates: LatLng['output']
          }>
          readonly metadata: {
            readonly __typename: 'CompanyMetadata'
            readonly subtrade: String['output'] | null
            readonly domains: ReadonlyArray<String['output']>
            readonly domainSignup: Boolean['output']
            readonly monthlyCreatePayAppReminderDate: Int['output'] | null
          }
          readonly defaultQuickBillingTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
          } | null
          readonly sitelinePointOfContact: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
          } | null
        }>
        readonly companyUsers: ReadonlyArray<{
          readonly __typename: 'CompanyUser'
          readonly id: ID['output']
          readonly status: UserStatus
          readonly role: CompanyUserRole
          readonly company: {
            readonly __typename: 'Company'
            readonly id: ID['output']
            readonly name: String['output']
            readonly nickname: String['output'] | null
            readonly type: CompanyType
            readonly phoneNumber: PhoneNumber['output'] | null
            readonly expectedNumberOfProjects: SafeInt['output'] | null
            readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
            readonly allowLumpSumOverbilling: Boolean['output']
            readonly notaryTags: ReadonlyArray<NotaryTag>
            readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
            readonly isDemoCompany: Boolean['output']
            readonly picture: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly notaryStamp: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly notarySignature: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly secondarySignature: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly locations: ReadonlyArray<{
              readonly __typename: 'Location'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly nickname: String['output'] | null
              readonly street1: String['output'] | null
              readonly street2: String['output'] | null
              readonly city: String['output']
              readonly county: String['output'] | null
              readonly state: String['output']
              readonly country: String['output']
              readonly postalCode: String['output'] | null
              readonly timeZone: String['output']
              readonly coordinates: LatLng['output']
            }>
            readonly metadata: {
              readonly __typename: 'CompanyMetadata'
              readonly subtrade: String['output'] | null
              readonly domains: ReadonlyArray<String['output']>
              readonly domainSignup: Boolean['output']
              readonly monthlyCreatePayAppReminderDate: Int['output'] | null
            }
            readonly defaultQuickBillingTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
            } | null
            readonly sitelinePointOfContact: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            } | null
          }
        }>
      }
    }>
    readonly payApps: ReadonlyArray<{
      readonly __typename: 'PayApp'
      readonly payAppNumber: SafeInt['output']
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly timeZone: String['output']
      readonly status: PayAppStatus
      readonly retentionOnly: Boolean['output']
      readonly billingStart: DateTime['output']
      readonly billingEnd: DateTime['output']
      readonly payAppDueDate: DateTime['output']
      readonly currentBilled: SafeInt['output']
      readonly currentRetention: SafeInt['output']
      readonly totalRetention: SafeInt['output']
      readonly previousRetentionBilled: SafeInt['output']
      readonly contract: {
        readonly __typename: 'Contract'
        readonly id: ID['output']
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
          readonly nickname: String['output'] | null
          readonly type: CompanyType
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly expectedNumberOfProjects: SafeInt['output'] | null
          readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
          readonly allowLumpSumOverbilling: Boolean['output']
          readonly notaryTags: ReadonlyArray<NotaryTag>
          readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
          readonly isDemoCompany: Boolean['output']
          readonly picture: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly notaryStamp: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly notarySignature: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly secondarySignature: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly locations: ReadonlyArray<{
            readonly __typename: 'Location'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly nickname: String['output'] | null
            readonly street1: String['output'] | null
            readonly street2: String['output'] | null
            readonly city: String['output']
            readonly county: String['output'] | null
            readonly state: String['output']
            readonly country: String['output']
            readonly postalCode: String['output'] | null
            readonly timeZone: String['output']
            readonly coordinates: LatLng['output']
          }>
          readonly metadata: {
            readonly __typename: 'CompanyMetadata'
            readonly subtrade: String['output'] | null
            readonly domains: ReadonlyArray<String['output']>
            readonly domainSignup: Boolean['output']
            readonly monthlyCreatePayAppReminderDate: Int['output'] | null
          }
          readonly defaultQuickBillingTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
          } | null
          readonly sitelinePointOfContact: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
          } | null
        }
      }
    }>
    readonly sov: {
      readonly __typename: 'Sov'
      readonly id: ID['output']
      readonly totalValue: SafeInt['output']
      readonly totalBilled: SafeInt['output']
      readonly totalRetention: SafeInt['output']
      readonly progressComplete: Float['output']
      readonly contract: {
        readonly __typename: 'Contract'
        readonly id: ID['output']
        readonly timeZone: String['output']
      }
      readonly lineItems: ReadonlyArray<{
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
        readonly code: String['output']
        readonly sortOrder: SafeInt['output']
        readonly name: String['output']
        readonly isChangeOrder: Boolean['output']
        readonly changeOrderApprovedDate: DateTime['output'] | null
        readonly latestTotalValue: SafeInt['output']
        readonly totalBilled: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly previousStoredMaterials: SafeInt['output']
        readonly totalRetention: SafeInt['output']
        readonly progressComplete: Float['output']
        readonly defaultRetentionPercent: Float['output'] | null
        readonly sovLineItemGroup: {
          readonly __typename: 'SovLineItemGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly code: String['output'] | null
        } | null
      }>
    } | null
    readonly lienWaiverTemplates: {
      readonly __typename: 'SubcontractorLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly conditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly conditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
    } | null
    readonly lowerTierLienWaiverTemplates: {
      readonly __typename: 'SubcontractorLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly conditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly conditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
    } | null
    readonly payAppRequirementGroups: ReadonlyArray<{
      readonly __typename: 'PayAppRequirementGroup'
      readonly id: ID['output']
      readonly order: Int['output']
      readonly payAppRequirements: ReadonlyArray<{
        readonly __typename: 'PayAppRequirement'
        readonly id: ID['output']
        readonly groupOrder: Int['output']
        readonly conditions: ReadonlyArray<PayAppRequirementCondition>
        readonly templateVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly internalName: String['output']
          readonly userVisibleName: String['output'] | null
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly isCustomerReady: Boolean['output']
          }
        } | null
      }>
    }>
    readonly legalRequirements: ReadonlyArray<{
      readonly __typename: 'LegalRequirement'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly name: String['output']
      readonly timeZone: String['output']
      readonly expirationFrequency: DocumentExpirationFrequency
      readonly startDate: DateTime['output'] | null
      readonly endDate: DateTime['output'] | null
      readonly isVendorRequirement: Boolean['output']
      readonly documents: ReadonlyArray<{
        readonly __typename: 'LegalDocument'
        readonly id: ID['output']
      }>
      readonly formTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
      } | null
    }>
    readonly selectedAddress: {
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    }
    readonly changeOrderRequestTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
    } | null
    readonly changeOrderLogTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
    } | null
    readonly integrations: ReadonlyArray<{
      readonly __typename: 'Integration'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly type: IntegrationType
      readonly credentialsUpdatedAt: DateTime['output'] | null
      readonly mappings: JSONObject['output']
      readonly isActive: Boolean['output']
      readonly shortName: String['output']
      readonly longName: String['output']
      readonly companyIntegration: {
        readonly __typename: 'CompanyIntegration'
        readonly id: ID['output']
      }
    }>
    readonly onboardedStatus: {
      readonly __typename: 'OnboardedProjectContractStatus'
      readonly selectedPayAppForms: Boolean['output']
      readonly onboardedPayAppForms: Boolean['output']
      readonly selectedPrimaryLienWaivers: Boolean['output']
      readonly onboardedPrimaryLienWaiverForms: Boolean['output']
      readonly selectedVendorLienWaivers: Boolean['output']
      readonly onboardedVendorLienWaiverForms: Boolean['output']
      readonly selectedChangeOrderRequestForms: Boolean['output']
      readonly onboardedChangeOrderRequestForms: Boolean['output']
      readonly addedSov: Boolean['output']
      readonly selectedRateTable: Boolean['output']
      readonly addedTeammates: Boolean['output']
      readonly addedGcContacts: Boolean['output']
      readonly startedBilling: Boolean['output']
      readonly notifiedOnboardedForms: Boolean['output']
    }
  }
}

export type UpdateOnboardedProjectContractStatusMutationVariables = Exact<{
  input: UpdateOnboardedProjectContractStatusInput
}>

export type UpdateOnboardedProjectContractStatusMutation = {
  readonly __typename: 'Mutation'
  readonly updateOnboardedProjectContractStatus: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly onboardedStatus: {
      readonly __typename: 'OnboardedProjectContractStatus'
      readonly selectedPayAppForms: Boolean['output']
      readonly onboardedPayAppForms: Boolean['output']
      readonly selectedPrimaryLienWaivers: Boolean['output']
      readonly onboardedPrimaryLienWaiverForms: Boolean['output']
      readonly selectedVendorLienWaivers: Boolean['output']
      readonly onboardedVendorLienWaiverForms: Boolean['output']
      readonly selectedChangeOrderRequestForms: Boolean['output']
      readonly onboardedChangeOrderRequestForms: Boolean['output']
      readonly addedSov: Boolean['output']
      readonly selectedRateTable: Boolean['output']
      readonly addedTeammates: Boolean['output']
      readonly addedGcContacts: Boolean['output']
      readonly startedBilling: Boolean['output']
      readonly notifiedOnboardedForms: Boolean['output']
    }
  }
}

export type UpdatePayAppRequirementGroupsMutationVariables = Exact<{
  input: UpdatePayAppRequirementGroupsInput
}>

export type UpdatePayAppRequirementGroupsMutation = {
  readonly __typename: 'Mutation'
  readonly updatePayAppRequirementGroups: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly role: CompanyProjectRole
    readonly status: ContractStatus
    readonly timeZone: String['output']
    readonly contractDate: DateTime['output'] | null
    readonly contractNumber: String['output'] | null
    readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
    readonly defaultRetentionPercent: Float['output'] | null
    readonly retentionTrackingLevel: RetentionTrackingLevel
    readonly daysBeforePayAppDue: Int['output']
    readonly billingType: BillingType
    readonly contractTemplateVariables: JSONObject['output']
    readonly internalProjectNumber: String['output'] | null
    readonly vendorNumber: String['output'] | null
    readonly pastPayAppCount: Int['output']
    readonly roundRetention: Boolean['output']
    readonly project: {
      readonly __typename: 'Project'
      readonly id: ID['output']
      readonly name: String['output']
      readonly projectNumber: String['output']
      readonly location: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }
      readonly generalContractor: {
        readonly __typename: 'CompanyProjectMetadata'
        readonly role: CompanyProjectRole
        readonly companyName: String['output'] | null
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
          readonly nickname: String['output'] | null
          readonly type: CompanyType
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly expectedNumberOfProjects: SafeInt['output'] | null
          readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
          readonly allowLumpSumOverbilling: Boolean['output']
          readonly notaryTags: ReadonlyArray<NotaryTag>
          readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
          readonly isDemoCompany: Boolean['output']
          readonly picture: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly notaryStamp: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly notarySignature: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly secondarySignature: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly locations: ReadonlyArray<{
            readonly __typename: 'Location'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly nickname: String['output'] | null
            readonly street1: String['output'] | null
            readonly street2: String['output'] | null
            readonly city: String['output']
            readonly county: String['output'] | null
            readonly state: String['output']
            readonly country: String['output']
            readonly postalCode: String['output'] | null
            readonly timeZone: String['output']
            readonly coordinates: LatLng['output']
          }>
          readonly metadata: {
            readonly __typename: 'CompanyMetadata'
            readonly subtrade: String['output'] | null
            readonly domains: ReadonlyArray<String['output']>
            readonly domainSignup: Boolean['output']
            readonly monthlyCreatePayAppReminderDate: Int['output'] | null
          }
          readonly defaultQuickBillingTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
          } | null
          readonly sitelinePointOfContact: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
          } | null
        }
        readonly selectedAddress: {
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        } | null
      } | null
    }
    readonly company: {
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
      readonly nickname: String['output'] | null
      readonly type: CompanyType
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly expectedNumberOfProjects: SafeInt['output'] | null
      readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
      readonly allowLumpSumOverbilling: Boolean['output']
      readonly notaryTags: ReadonlyArray<NotaryTag>
      readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
      readonly isDemoCompany: Boolean['output']
      readonly picture: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly notaryStamp: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly notarySignature: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly secondarySignature: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly locations: ReadonlyArray<{
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }>
      readonly metadata: {
        readonly __typename: 'CompanyMetadata'
        readonly subtrade: String['output'] | null
        readonly domains: ReadonlyArray<String['output']>
        readonly domainSignup: Boolean['output']
        readonly monthlyCreatePayAppReminderDate: Int['output'] | null
      }
      readonly defaultQuickBillingTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
      } | null
      readonly sitelinePointOfContact: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
      } | null
    }
    readonly sov: {
      readonly __typename: 'Sov'
      readonly id: ID['output']
      readonly totalValue: SafeInt['output']
      readonly totalBilled: SafeInt['output']
      readonly totalRetention: SafeInt['output']
      readonly progressComplete: Float['output']
      readonly contract: {
        readonly __typename: 'Contract'
        readonly id: ID['output']
        readonly timeZone: String['output']
      }
      readonly lineItems: ReadonlyArray<{
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
        readonly code: String['output']
        readonly sortOrder: SafeInt['output']
        readonly name: String['output']
        readonly isChangeOrder: Boolean['output']
        readonly changeOrderApprovedDate: DateTime['output'] | null
        readonly latestTotalValue: SafeInt['output']
        readonly totalBilled: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly previousStoredMaterials: SafeInt['output']
        readonly totalRetention: SafeInt['output']
        readonly progressComplete: Float['output']
        readonly defaultRetentionPercent: Float['output'] | null
        readonly sovLineItemGroup: {
          readonly __typename: 'SovLineItemGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly code: String['output'] | null
        } | null
      }>
    } | null
    readonly lienWaiverTemplates: {
      readonly __typename: 'SubcontractorLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly conditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly conditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
    } | null
    readonly lowerTierLienWaiverTemplates: {
      readonly __typename: 'SubcontractorLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly conditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly conditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
    } | null
    readonly payAppRequirementGroups: ReadonlyArray<{
      readonly __typename: 'PayAppRequirementGroup'
      readonly id: ID['output']
      readonly order: Int['output']
      readonly payAppRequirements: ReadonlyArray<{
        readonly __typename: 'PayAppRequirement'
        readonly id: ID['output']
        readonly groupOrder: Int['output']
        readonly conditions: ReadonlyArray<PayAppRequirementCondition>
        readonly templateVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly internalName: String['output']
          readonly userVisibleName: String['output'] | null
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly isCustomerReady: Boolean['output']
          }
        } | null
      }>
    }>
    readonly legalRequirements: ReadonlyArray<{
      readonly __typename: 'LegalRequirement'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly name: String['output']
      readonly timeZone: String['output']
      readonly expirationFrequency: DocumentExpirationFrequency
      readonly startDate: DateTime['output'] | null
      readonly endDate: DateTime['output'] | null
      readonly isVendorRequirement: Boolean['output']
      readonly documents: ReadonlyArray<{
        readonly __typename: 'LegalDocument'
        readonly id: ID['output']
      }>
      readonly formTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
      } | null
    }>
    readonly selectedAddress: {
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    }
    readonly changeOrderRequestTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
    } | null
    readonly changeOrderLogTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
    } | null
    readonly integrations: ReadonlyArray<{
      readonly __typename: 'Integration'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly type: IntegrationType
      readonly credentialsUpdatedAt: DateTime['output'] | null
      readonly mappings: JSONObject['output']
      readonly isActive: Boolean['output']
      readonly shortName: String['output']
      readonly longName: String['output']
      readonly companyIntegration: {
        readonly __typename: 'CompanyIntegration'
        readonly id: ID['output']
      }
    }>
    readonly onboardedStatus: {
      readonly __typename: 'OnboardedProjectContractStatus'
      readonly selectedPayAppForms: Boolean['output']
      readonly onboardedPayAppForms: Boolean['output']
      readonly selectedPrimaryLienWaivers: Boolean['output']
      readonly onboardedPrimaryLienWaiverForms: Boolean['output']
      readonly selectedVendorLienWaivers: Boolean['output']
      readonly onboardedVendorLienWaiverForms: Boolean['output']
      readonly selectedChangeOrderRequestForms: Boolean['output']
      readonly onboardedChangeOrderRequestForms: Boolean['output']
      readonly addedSov: Boolean['output']
      readonly selectedRateTable: Boolean['output']
      readonly addedTeammates: Boolean['output']
      readonly addedGcContacts: Boolean['output']
      readonly startedBilling: Boolean['output']
      readonly notifiedOnboardedForms: Boolean['output']
    }
  }
}

export type ContractFormTemplatesQueryVariables = Exact<{
  contractId: Scalars['ID']['input']
}>

export type ContractFormTemplatesQuery = {
  readonly __typename: 'Query'
  readonly contractFormTemplates: ReadonlyArray<{
    readonly __typename: 'FormTemplate'
    readonly id: String['output']
    readonly createdAt: DateTime['output']
    readonly type: FormTemplateType
    readonly userVisibleName: String['output']
    readonly status: FormTemplateStatus
    readonly skippedValidation: Boolean['output']
    readonly variants: ReadonlyArray<{
      readonly __typename: 'FormTemplateVariant'
      readonly id: ID['output']
      readonly isDefaultVariant: Boolean['output']
    }>
    readonly associatedContracts: ReadonlyArray<{
      readonly __typename: 'FormTemplateAssociatedContract'
      readonly id: String['output']
      readonly providedAsFormType: ProjectOnboardingFormType | null
      readonly ignoreProvidedAsFormType: Boolean['output']
      readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
    }>
  }>
}

export type IgnoreFormTemplateProvidedTypeMutationVariables = Exact<{
  input: IgnoreFormTemplateProvidedTypeInput
}>

export type IgnoreFormTemplateProvidedTypeMutation = {
  readonly __typename: 'Mutation'
  readonly ignoreFormTemplateProvidedType: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly associatedFormTemplates: ReadonlyArray<{
      readonly __typename: 'FormTemplateAssociatedContract'
      readonly id: String['output']
      readonly ignoreProvidedAsFormType: Boolean['output']
    }>
    readonly onboardedStatus: {
      readonly __typename: 'OnboardedProjectContractStatus'
      readonly selectedPayAppForms: Boolean['output']
      readonly onboardedPayAppForms: Boolean['output']
      readonly selectedPrimaryLienWaivers: Boolean['output']
      readonly onboardedPrimaryLienWaiverForms: Boolean['output']
      readonly selectedVendorLienWaivers: Boolean['output']
      readonly onboardedVendorLienWaiverForms: Boolean['output']
      readonly selectedChangeOrderRequestForms: Boolean['output']
      readonly onboardedChangeOrderRequestForms: Boolean['output']
      readonly notifiedOnboardedForms: Boolean['output']
    }
  }
}

export type UpdateContractUsersMutationVariables = Exact<{
  input: UpdateContractUsersInput
}>

export type UpdateContractUsersMutation = {
  readonly __typename: 'Mutation'
  readonly updateContractUsers: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly users: ReadonlyArray<{
      readonly __typename: 'ContractUser'
      readonly id: ID['output']
      readonly user: {
        readonly __typename: 'User'
        readonly blockedEmails: ReadonlyArray<EmailType>
        readonly blockedNotifications: ReadonlyArray<NotificationType>
        readonly policiesAcceptedAt: DateTime['output'] | null
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
        readonly jobTitle: String['output'] | null
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly companies: ReadonlyArray<{
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
          readonly nickname: String['output'] | null
          readonly type: CompanyType
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly expectedNumberOfProjects: SafeInt['output'] | null
          readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
          readonly allowLumpSumOverbilling: Boolean['output']
          readonly notaryTags: ReadonlyArray<NotaryTag>
          readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
          readonly isDemoCompany: Boolean['output']
          readonly picture: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly notaryStamp: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly notarySignature: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly secondarySignature: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly locations: ReadonlyArray<{
            readonly __typename: 'Location'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly nickname: String['output'] | null
            readonly street1: String['output'] | null
            readonly street2: String['output'] | null
            readonly city: String['output']
            readonly county: String['output'] | null
            readonly state: String['output']
            readonly country: String['output']
            readonly postalCode: String['output'] | null
            readonly timeZone: String['output']
            readonly coordinates: LatLng['output']
          }>
          readonly metadata: {
            readonly __typename: 'CompanyMetadata'
            readonly subtrade: String['output'] | null
            readonly domains: ReadonlyArray<String['output']>
            readonly domainSignup: Boolean['output']
            readonly monthlyCreatePayAppReminderDate: Int['output'] | null
          }
          readonly defaultQuickBillingTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
          } | null
          readonly sitelinePointOfContact: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
          } | null
        }>
        readonly companyUsers: ReadonlyArray<{
          readonly __typename: 'CompanyUser'
          readonly id: ID['output']
          readonly status: UserStatus
          readonly role: CompanyUserRole
          readonly company: {
            readonly __typename: 'Company'
            readonly id: ID['output']
            readonly name: String['output']
            readonly nickname: String['output'] | null
            readonly type: CompanyType
            readonly phoneNumber: PhoneNumber['output'] | null
            readonly expectedNumberOfProjects: SafeInt['output'] | null
            readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
            readonly allowLumpSumOverbilling: Boolean['output']
            readonly notaryTags: ReadonlyArray<NotaryTag>
            readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
            readonly isDemoCompany: Boolean['output']
            readonly picture: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly notaryStamp: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly notarySignature: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly secondarySignature: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly locations: ReadonlyArray<{
              readonly __typename: 'Location'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly nickname: String['output'] | null
              readonly street1: String['output'] | null
              readonly street2: String['output'] | null
              readonly city: String['output']
              readonly county: String['output'] | null
              readonly state: String['output']
              readonly country: String['output']
              readonly postalCode: String['output'] | null
              readonly timeZone: String['output']
              readonly coordinates: LatLng['output']
            }>
            readonly metadata: {
              readonly __typename: 'CompanyMetadata'
              readonly subtrade: String['output'] | null
              readonly domains: ReadonlyArray<String['output']>
              readonly domainSignup: Boolean['output']
              readonly monthlyCreatePayAppReminderDate: Int['output'] | null
            }
            readonly defaultQuickBillingTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
            } | null
            readonly sitelinePointOfContact: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            } | null
          }
        }>
      }
    }>
  }
}

export type AddIntegrationMutationVariables = Exact<{
  input: AddIntegrationInput
}>

export type AddIntegrationMutation = {
  readonly __typename: 'Mutation'
  readonly addIntegration: {
    readonly __typename: 'Integration'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly type: IntegrationType
    readonly credentialsUpdatedAt: DateTime['output'] | null
    readonly mappings: JSONObject['output']
    readonly isActive: Boolean['output']
    readonly shortName: String['output']
    readonly longName: String['output']
    readonly companyIntegration: {
      readonly __typename: 'CompanyIntegration'
      readonly id: ID['output']
    }
  }
}

export type FinishedOnboardingFormsMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type FinishedOnboardingFormsMutation = {
  readonly __typename: 'Mutation'
  readonly finishedOnboardingForms: { readonly __typename: 'Contract'; readonly id: ID['output'] }
}

export type DeleteIntegrationMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteIntegrationMutation = {
  readonly __typename: 'Mutation'
  readonly deleteIntegration: { readonly __typename: 'DeletionResult'; readonly id: ID['output'] }
}

export type UpdateLegalRequirementsMutationVariables = Exact<{
  input: UpdateLegalRequirementsInput
}>

export type UpdateLegalRequirementsMutation = {
  readonly __typename: 'Mutation'
  readonly updateLegalRequirements: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly role: CompanyProjectRole
    readonly status: ContractStatus
    readonly timeZone: String['output']
    readonly contractDate: DateTime['output'] | null
    readonly contractNumber: String['output'] | null
    readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
    readonly defaultRetentionPercent: Float['output'] | null
    readonly retentionTrackingLevel: RetentionTrackingLevel
    readonly daysBeforePayAppDue: Int['output']
    readonly billingType: BillingType
    readonly contractTemplateVariables: JSONObject['output']
    readonly internalProjectNumber: String['output'] | null
    readonly vendorNumber: String['output'] | null
    readonly pastPayAppCount: Int['output']
    readonly roundRetention: Boolean['output']
    readonly project: {
      readonly __typename: 'Project'
      readonly id: ID['output']
      readonly name: String['output']
      readonly projectNumber: String['output']
      readonly location: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }
      readonly generalContractor: {
        readonly __typename: 'CompanyProjectMetadata'
        readonly role: CompanyProjectRole
        readonly companyName: String['output'] | null
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
          readonly nickname: String['output'] | null
          readonly type: CompanyType
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly expectedNumberOfProjects: SafeInt['output'] | null
          readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
          readonly allowLumpSumOverbilling: Boolean['output']
          readonly notaryTags: ReadonlyArray<NotaryTag>
          readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
          readonly isDemoCompany: Boolean['output']
          readonly picture: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly notaryStamp: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly notarySignature: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly secondarySignature: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly locations: ReadonlyArray<{
            readonly __typename: 'Location'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly nickname: String['output'] | null
            readonly street1: String['output'] | null
            readonly street2: String['output'] | null
            readonly city: String['output']
            readonly county: String['output'] | null
            readonly state: String['output']
            readonly country: String['output']
            readonly postalCode: String['output'] | null
            readonly timeZone: String['output']
            readonly coordinates: LatLng['output']
          }>
          readonly metadata: {
            readonly __typename: 'CompanyMetadata'
            readonly subtrade: String['output'] | null
            readonly domains: ReadonlyArray<String['output']>
            readonly domainSignup: Boolean['output']
            readonly monthlyCreatePayAppReminderDate: Int['output'] | null
          }
          readonly defaultQuickBillingTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
          } | null
          readonly sitelinePointOfContact: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
          } | null
        }
        readonly selectedAddress: {
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        } | null
      } | null
    }
    readonly company: {
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
      readonly nickname: String['output'] | null
      readonly type: CompanyType
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly expectedNumberOfProjects: SafeInt['output'] | null
      readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
      readonly allowLumpSumOverbilling: Boolean['output']
      readonly notaryTags: ReadonlyArray<NotaryTag>
      readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
      readonly isDemoCompany: Boolean['output']
      readonly picture: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly notaryStamp: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly notarySignature: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly secondarySignature: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly locations: ReadonlyArray<{
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }>
      readonly metadata: {
        readonly __typename: 'CompanyMetadata'
        readonly subtrade: String['output'] | null
        readonly domains: ReadonlyArray<String['output']>
        readonly domainSignup: Boolean['output']
        readonly monthlyCreatePayAppReminderDate: Int['output'] | null
      }
      readonly defaultQuickBillingTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
      } | null
      readonly sitelinePointOfContact: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
      } | null
    }
    readonly sov: {
      readonly __typename: 'Sov'
      readonly id: ID['output']
      readonly totalValue: SafeInt['output']
      readonly totalBilled: SafeInt['output']
      readonly totalRetention: SafeInt['output']
      readonly progressComplete: Float['output']
      readonly contract: {
        readonly __typename: 'Contract'
        readonly id: ID['output']
        readonly timeZone: String['output']
      }
      readonly lineItems: ReadonlyArray<{
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
        readonly code: String['output']
        readonly sortOrder: SafeInt['output']
        readonly name: String['output']
        readonly isChangeOrder: Boolean['output']
        readonly changeOrderApprovedDate: DateTime['output'] | null
        readonly latestTotalValue: SafeInt['output']
        readonly totalBilled: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly previousStoredMaterials: SafeInt['output']
        readonly totalRetention: SafeInt['output']
        readonly progressComplete: Float['output']
        readonly defaultRetentionPercent: Float['output'] | null
        readonly sovLineItemGroup: {
          readonly __typename: 'SovLineItemGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly code: String['output'] | null
        } | null
      }>
    } | null
    readonly lienWaiverTemplates: {
      readonly __typename: 'SubcontractorLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly conditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly conditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
    } | null
    readonly lowerTierLienWaiverTemplates: {
      readonly __typename: 'SubcontractorLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly conditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly conditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
    } | null
    readonly payAppRequirementGroups: ReadonlyArray<{
      readonly __typename: 'PayAppRequirementGroup'
      readonly id: ID['output']
      readonly order: Int['output']
      readonly payAppRequirements: ReadonlyArray<{
        readonly __typename: 'PayAppRequirement'
        readonly id: ID['output']
        readonly groupOrder: Int['output']
        readonly conditions: ReadonlyArray<PayAppRequirementCondition>
        readonly templateVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly internalName: String['output']
          readonly userVisibleName: String['output'] | null
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly isCustomerReady: Boolean['output']
          }
        } | null
      }>
    }>
    readonly legalRequirements: ReadonlyArray<{
      readonly __typename: 'LegalRequirement'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly name: String['output']
      readonly timeZone: String['output']
      readonly expirationFrequency: DocumentExpirationFrequency
      readonly startDate: DateTime['output'] | null
      readonly endDate: DateTime['output'] | null
      readonly isVendorRequirement: Boolean['output']
      readonly documents: ReadonlyArray<{
        readonly __typename: 'LegalDocument'
        readonly id: ID['output']
      }>
      readonly formTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
      } | null
    }>
    readonly selectedAddress: {
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    }
    readonly changeOrderRequestTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
    } | null
    readonly changeOrderLogTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
    } | null
    readonly integrations: ReadonlyArray<{
      readonly __typename: 'Integration'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly type: IntegrationType
      readonly credentialsUpdatedAt: DateTime['output'] | null
      readonly mappings: JSONObject['output']
      readonly isActive: Boolean['output']
      readonly shortName: String['output']
      readonly longName: String['output']
      readonly companyIntegration: {
        readonly __typename: 'CompanyIntegration'
        readonly id: ID['output']
      }
    }>
    readonly onboardedStatus: {
      readonly __typename: 'OnboardedProjectContractStatus'
      readonly selectedPayAppForms: Boolean['output']
      readonly onboardedPayAppForms: Boolean['output']
      readonly selectedPrimaryLienWaivers: Boolean['output']
      readonly onboardedPrimaryLienWaiverForms: Boolean['output']
      readonly selectedVendorLienWaivers: Boolean['output']
      readonly onboardedVendorLienWaiverForms: Boolean['output']
      readonly selectedChangeOrderRequestForms: Boolean['output']
      readonly onboardedChangeOrderRequestForms: Boolean['output']
      readonly addedSov: Boolean['output']
      readonly selectedRateTable: Boolean['output']
      readonly addedTeammates: Boolean['output']
      readonly addedGcContacts: Boolean['output']
      readonly startedBilling: Boolean['output']
      readonly notifiedOnboardedForms: Boolean['output']
    }
  }
}

export type CreateSovLineItemGroupMutationVariables = Exact<{
  input: CreateSovLineItemGroupInput
}>

export type CreateSovLineItemGroupMutation = {
  readonly __typename: 'Mutation'
  readonly createSovLineItemGroup: {
    readonly __typename: 'SovLineItemGroup'
    readonly id: ID['output']
    readonly name: String['output']
    readonly code: String['output'] | null
  }
}

export type UpdateSovLineItemGroupMutationVariables = Exact<{
  input: UpdateSovLineItemGroupInput
}>

export type UpdateSovLineItemGroupMutation = {
  readonly __typename: 'Mutation'
  readonly updateSovLineItemGroup: {
    readonly __typename: 'SovLineItemGroup'
    readonly id: ID['output']
    readonly name: String['output']
    readonly code: String['output'] | null
  }
}

export type DeleteSovLineItemGroupMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteSovLineItemGroupMutation = {
  readonly __typename: 'Mutation'
  readonly deleteSovLineItemGroup: {
    readonly __typename: 'DeletionResult'
    readonly id: ID['output']
  }
}

export type SetLineItemsInGroupMutationVariables = Exact<{
  input: SetLineItemsInGroupInput
}>

export type SetLineItemsInGroupMutation = {
  readonly __typename: 'Mutation'
  readonly setLineItemsInGroup: {
    readonly __typename: 'SovLineItemGroup'
    readonly id: ID['output']
    readonly name: String['output']
    readonly code: String['output'] | null
  }
}

export type ContractForLienWaiverSettings = {
  readonly __typename: 'Contract'
  readonly id: ID['output']
  readonly lienWaiverTemplates: {
    readonly __typename: 'SubcontractorLienWaiverTemplateSet'
    readonly id: ID['output']
    readonly conditionalFinalVariant: {
      readonly __typename: 'FormTemplateVariant'
      readonly id: ID['output']
      readonly template: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly createdAt: DateTime['output']
        readonly type: FormTemplateType
        readonly status: FormTemplateStatus
        readonly skippedValidation: Boolean['output']
        readonly userVisibleName: String['output']
        readonly isCustomerReady: Boolean['output']
        readonly tags: ReadonlyArray<FormTemplateTag>
        readonly dueDate: DateTime['output'] | null
        readonly statusLogs: ReadonlyArray<{
          readonly __typename: 'FormTemplateStatusLog'
          readonly id: ID['output']
          readonly statusUpdatedAt: DateTime['output']
          readonly status: FormTemplateStatus
          readonly user: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
          }
        }>
        readonly associatedCompany: {
          readonly __typename: 'Company'
          readonly id: ID['output']
        } | null
        readonly requestingCompany: {
          readonly __typename: 'Company'
          readonly id: ID['output']
        } | null
        readonly associatedContracts: ReadonlyArray<{
          readonly __typename: 'FormTemplateAssociatedContract'
          readonly id: String['output']
          readonly providedAsFormType: ProjectOnboardingFormType | null
          readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
        }>
        readonly owner: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        }
        readonly builder: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        }
        readonly validator: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        } | null
        readonly versions: ReadonlyArray<{
          readonly __typename: 'FormTemplateVersion'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly updatedAt: DateTime['output']
          readonly versionNumber: Int['output']
          readonly isReadyForManualStoredMaterials: Boolean['output']
          readonly file: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          }
          readonly annotations: ReadonlyArray<{
            readonly __typename: 'FormTemplateAnnotation'
            readonly id: ID['output']
            readonly permanentId: ID['output']
            readonly syncTag: String['output'] | null
            readonly userVisibleName: String['output']
            readonly type: FormTemplateAnnotationType
            readonly xStart: Float['output']
            readonly yStart: Float['output']
            readonly width: Float['output']
            readonly height: Float['output']
            readonly pageNumber: Int['output']
            readonly fontFamily: FormTemplateFont
            readonly fontColor: String['output']
            readonly textAlignment: TextAlignment
            readonly wrapText: Boolean['output']
            readonly isOptional: Boolean['output']
            readonly selectedKey: String['output'] | null
            readonly defaultValueKey: String['output'] | null
            readonly prefix: String['output'] | null
            readonly suffix: String['output'] | null
            readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
            readonly doNotRetainOnReset: Boolean['output']
            readonly dynamicFieldTag: String['output'] | null
            readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
            readonly imageType: FormTemplateAnnotationImageType | null
            readonly signatureType: SignatureAnnotationType | null
          }>
        }>
        readonly variants: ReadonlyArray<{
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly internalName: String['output']
          readonly isDefaultVariant: Boolean['output']
        }>
        readonly comments: ReadonlyArray<{
          readonly __typename: 'FormTemplateComment'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly message: String['output']
          readonly isSystemGenerated: Boolean['output']
          readonly user: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
            readonly email: EmailAddress['output']
          }
        }>
        readonly originalFile: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
        } | null
        readonly duplicateTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly userVisibleName: String['output']
        } | null
      }
    } | null
    readonly conditionalProgressVariant: {
      readonly __typename: 'FormTemplateVariant'
      readonly id: ID['output']
      readonly template: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly createdAt: DateTime['output']
        readonly type: FormTemplateType
        readonly status: FormTemplateStatus
        readonly skippedValidation: Boolean['output']
        readonly userVisibleName: String['output']
        readonly isCustomerReady: Boolean['output']
        readonly tags: ReadonlyArray<FormTemplateTag>
        readonly dueDate: DateTime['output'] | null
        readonly statusLogs: ReadonlyArray<{
          readonly __typename: 'FormTemplateStatusLog'
          readonly id: ID['output']
          readonly statusUpdatedAt: DateTime['output']
          readonly status: FormTemplateStatus
          readonly user: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
          }
        }>
        readonly associatedCompany: {
          readonly __typename: 'Company'
          readonly id: ID['output']
        } | null
        readonly requestingCompany: {
          readonly __typename: 'Company'
          readonly id: ID['output']
        } | null
        readonly associatedContracts: ReadonlyArray<{
          readonly __typename: 'FormTemplateAssociatedContract'
          readonly id: String['output']
          readonly providedAsFormType: ProjectOnboardingFormType | null
          readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
        }>
        readonly owner: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        }
        readonly builder: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        }
        readonly validator: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        } | null
        readonly versions: ReadonlyArray<{
          readonly __typename: 'FormTemplateVersion'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly updatedAt: DateTime['output']
          readonly versionNumber: Int['output']
          readonly isReadyForManualStoredMaterials: Boolean['output']
          readonly file: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          }
          readonly annotations: ReadonlyArray<{
            readonly __typename: 'FormTemplateAnnotation'
            readonly id: ID['output']
            readonly permanentId: ID['output']
            readonly syncTag: String['output'] | null
            readonly userVisibleName: String['output']
            readonly type: FormTemplateAnnotationType
            readonly xStart: Float['output']
            readonly yStart: Float['output']
            readonly width: Float['output']
            readonly height: Float['output']
            readonly pageNumber: Int['output']
            readonly fontFamily: FormTemplateFont
            readonly fontColor: String['output']
            readonly textAlignment: TextAlignment
            readonly wrapText: Boolean['output']
            readonly isOptional: Boolean['output']
            readonly selectedKey: String['output'] | null
            readonly defaultValueKey: String['output'] | null
            readonly prefix: String['output'] | null
            readonly suffix: String['output'] | null
            readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
            readonly doNotRetainOnReset: Boolean['output']
            readonly dynamicFieldTag: String['output'] | null
            readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
            readonly imageType: FormTemplateAnnotationImageType | null
            readonly signatureType: SignatureAnnotationType | null
          }>
        }>
        readonly variants: ReadonlyArray<{
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly internalName: String['output']
          readonly isDefaultVariant: Boolean['output']
        }>
        readonly comments: ReadonlyArray<{
          readonly __typename: 'FormTemplateComment'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly message: String['output']
          readonly isSystemGenerated: Boolean['output']
          readonly user: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
            readonly email: EmailAddress['output']
          }
        }>
        readonly originalFile: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
        } | null
        readonly duplicateTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly userVisibleName: String['output']
        } | null
      }
    } | null
    readonly unconditionalFinalVariant: {
      readonly __typename: 'FormTemplateVariant'
      readonly id: ID['output']
      readonly template: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly createdAt: DateTime['output']
        readonly type: FormTemplateType
        readonly status: FormTemplateStatus
        readonly skippedValidation: Boolean['output']
        readonly userVisibleName: String['output']
        readonly isCustomerReady: Boolean['output']
        readonly tags: ReadonlyArray<FormTemplateTag>
        readonly dueDate: DateTime['output'] | null
        readonly statusLogs: ReadonlyArray<{
          readonly __typename: 'FormTemplateStatusLog'
          readonly id: ID['output']
          readonly statusUpdatedAt: DateTime['output']
          readonly status: FormTemplateStatus
          readonly user: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
          }
        }>
        readonly associatedCompany: {
          readonly __typename: 'Company'
          readonly id: ID['output']
        } | null
        readonly requestingCompany: {
          readonly __typename: 'Company'
          readonly id: ID['output']
        } | null
        readonly associatedContracts: ReadonlyArray<{
          readonly __typename: 'FormTemplateAssociatedContract'
          readonly id: String['output']
          readonly providedAsFormType: ProjectOnboardingFormType | null
          readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
        }>
        readonly owner: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        }
        readonly builder: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        }
        readonly validator: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        } | null
        readonly versions: ReadonlyArray<{
          readonly __typename: 'FormTemplateVersion'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly updatedAt: DateTime['output']
          readonly versionNumber: Int['output']
          readonly isReadyForManualStoredMaterials: Boolean['output']
          readonly file: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          }
          readonly annotations: ReadonlyArray<{
            readonly __typename: 'FormTemplateAnnotation'
            readonly id: ID['output']
            readonly permanentId: ID['output']
            readonly syncTag: String['output'] | null
            readonly userVisibleName: String['output']
            readonly type: FormTemplateAnnotationType
            readonly xStart: Float['output']
            readonly yStart: Float['output']
            readonly width: Float['output']
            readonly height: Float['output']
            readonly pageNumber: Int['output']
            readonly fontFamily: FormTemplateFont
            readonly fontColor: String['output']
            readonly textAlignment: TextAlignment
            readonly wrapText: Boolean['output']
            readonly isOptional: Boolean['output']
            readonly selectedKey: String['output'] | null
            readonly defaultValueKey: String['output'] | null
            readonly prefix: String['output'] | null
            readonly suffix: String['output'] | null
            readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
            readonly doNotRetainOnReset: Boolean['output']
            readonly dynamicFieldTag: String['output'] | null
            readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
            readonly imageType: FormTemplateAnnotationImageType | null
            readonly signatureType: SignatureAnnotationType | null
          }>
        }>
        readonly variants: ReadonlyArray<{
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly internalName: String['output']
          readonly isDefaultVariant: Boolean['output']
        }>
        readonly comments: ReadonlyArray<{
          readonly __typename: 'FormTemplateComment'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly message: String['output']
          readonly isSystemGenerated: Boolean['output']
          readonly user: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
            readonly email: EmailAddress['output']
          }
        }>
        readonly originalFile: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
        } | null
        readonly duplicateTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly userVisibleName: String['output']
        } | null
      }
    } | null
    readonly unconditionalProgressVariant: {
      readonly __typename: 'FormTemplateVariant'
      readonly id: ID['output']
      readonly template: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly createdAt: DateTime['output']
        readonly type: FormTemplateType
        readonly status: FormTemplateStatus
        readonly skippedValidation: Boolean['output']
        readonly userVisibleName: String['output']
        readonly isCustomerReady: Boolean['output']
        readonly tags: ReadonlyArray<FormTemplateTag>
        readonly dueDate: DateTime['output'] | null
        readonly statusLogs: ReadonlyArray<{
          readonly __typename: 'FormTemplateStatusLog'
          readonly id: ID['output']
          readonly statusUpdatedAt: DateTime['output']
          readonly status: FormTemplateStatus
          readonly user: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
          }
        }>
        readonly associatedCompany: {
          readonly __typename: 'Company'
          readonly id: ID['output']
        } | null
        readonly requestingCompany: {
          readonly __typename: 'Company'
          readonly id: ID['output']
        } | null
        readonly associatedContracts: ReadonlyArray<{
          readonly __typename: 'FormTemplateAssociatedContract'
          readonly id: String['output']
          readonly providedAsFormType: ProjectOnboardingFormType | null
          readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
        }>
        readonly owner: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        }
        readonly builder: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        }
        readonly validator: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        } | null
        readonly versions: ReadonlyArray<{
          readonly __typename: 'FormTemplateVersion'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly updatedAt: DateTime['output']
          readonly versionNumber: Int['output']
          readonly isReadyForManualStoredMaterials: Boolean['output']
          readonly file: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          }
          readonly annotations: ReadonlyArray<{
            readonly __typename: 'FormTemplateAnnotation'
            readonly id: ID['output']
            readonly permanentId: ID['output']
            readonly syncTag: String['output'] | null
            readonly userVisibleName: String['output']
            readonly type: FormTemplateAnnotationType
            readonly xStart: Float['output']
            readonly yStart: Float['output']
            readonly width: Float['output']
            readonly height: Float['output']
            readonly pageNumber: Int['output']
            readonly fontFamily: FormTemplateFont
            readonly fontColor: String['output']
            readonly textAlignment: TextAlignment
            readonly wrapText: Boolean['output']
            readonly isOptional: Boolean['output']
            readonly selectedKey: String['output'] | null
            readonly defaultValueKey: String['output'] | null
            readonly prefix: String['output'] | null
            readonly suffix: String['output'] | null
            readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
            readonly doNotRetainOnReset: Boolean['output']
            readonly dynamicFieldTag: String['output'] | null
            readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
            readonly imageType: FormTemplateAnnotationImageType | null
            readonly signatureType: SignatureAnnotationType | null
          }>
        }>
        readonly variants: ReadonlyArray<{
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly internalName: String['output']
          readonly isDefaultVariant: Boolean['output']
        }>
        readonly comments: ReadonlyArray<{
          readonly __typename: 'FormTemplateComment'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly message: String['output']
          readonly isSystemGenerated: Boolean['output']
          readonly user: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
            readonly email: EmailAddress['output']
          }
        }>
        readonly originalFile: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
        } | null
        readonly duplicateTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly userVisibleName: String['output']
        } | null
      }
    } | null
  } | null
  readonly lowerTierLienWaiverTemplates: {
    readonly __typename: 'SubcontractorLienWaiverTemplateSet'
    readonly id: ID['output']
    readonly conditionalFinalVariant: {
      readonly __typename: 'FormTemplateVariant'
      readonly id: ID['output']
      readonly template: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly createdAt: DateTime['output']
        readonly type: FormTemplateType
        readonly status: FormTemplateStatus
        readonly skippedValidation: Boolean['output']
        readonly userVisibleName: String['output']
        readonly isCustomerReady: Boolean['output']
        readonly tags: ReadonlyArray<FormTemplateTag>
        readonly dueDate: DateTime['output'] | null
        readonly statusLogs: ReadonlyArray<{
          readonly __typename: 'FormTemplateStatusLog'
          readonly id: ID['output']
          readonly statusUpdatedAt: DateTime['output']
          readonly status: FormTemplateStatus
          readonly user: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
          }
        }>
        readonly associatedCompany: {
          readonly __typename: 'Company'
          readonly id: ID['output']
        } | null
        readonly requestingCompany: {
          readonly __typename: 'Company'
          readonly id: ID['output']
        } | null
        readonly associatedContracts: ReadonlyArray<{
          readonly __typename: 'FormTemplateAssociatedContract'
          readonly id: String['output']
          readonly providedAsFormType: ProjectOnboardingFormType | null
          readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
        }>
        readonly owner: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        }
        readonly builder: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        }
        readonly validator: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        } | null
        readonly versions: ReadonlyArray<{
          readonly __typename: 'FormTemplateVersion'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly updatedAt: DateTime['output']
          readonly versionNumber: Int['output']
          readonly isReadyForManualStoredMaterials: Boolean['output']
          readonly file: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          }
          readonly annotations: ReadonlyArray<{
            readonly __typename: 'FormTemplateAnnotation'
            readonly id: ID['output']
            readonly permanentId: ID['output']
            readonly syncTag: String['output'] | null
            readonly userVisibleName: String['output']
            readonly type: FormTemplateAnnotationType
            readonly xStart: Float['output']
            readonly yStart: Float['output']
            readonly width: Float['output']
            readonly height: Float['output']
            readonly pageNumber: Int['output']
            readonly fontFamily: FormTemplateFont
            readonly fontColor: String['output']
            readonly textAlignment: TextAlignment
            readonly wrapText: Boolean['output']
            readonly isOptional: Boolean['output']
            readonly selectedKey: String['output'] | null
            readonly defaultValueKey: String['output'] | null
            readonly prefix: String['output'] | null
            readonly suffix: String['output'] | null
            readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
            readonly doNotRetainOnReset: Boolean['output']
            readonly dynamicFieldTag: String['output'] | null
            readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
            readonly imageType: FormTemplateAnnotationImageType | null
            readonly signatureType: SignatureAnnotationType | null
          }>
        }>
        readonly variants: ReadonlyArray<{
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly internalName: String['output']
          readonly isDefaultVariant: Boolean['output']
        }>
        readonly comments: ReadonlyArray<{
          readonly __typename: 'FormTemplateComment'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly message: String['output']
          readonly isSystemGenerated: Boolean['output']
          readonly user: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
            readonly email: EmailAddress['output']
          }
        }>
        readonly originalFile: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
        } | null
        readonly duplicateTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly userVisibleName: String['output']
        } | null
      }
    } | null
    readonly conditionalProgressVariant: {
      readonly __typename: 'FormTemplateVariant'
      readonly id: ID['output']
      readonly template: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly createdAt: DateTime['output']
        readonly type: FormTemplateType
        readonly status: FormTemplateStatus
        readonly skippedValidation: Boolean['output']
        readonly userVisibleName: String['output']
        readonly isCustomerReady: Boolean['output']
        readonly tags: ReadonlyArray<FormTemplateTag>
        readonly dueDate: DateTime['output'] | null
        readonly statusLogs: ReadonlyArray<{
          readonly __typename: 'FormTemplateStatusLog'
          readonly id: ID['output']
          readonly statusUpdatedAt: DateTime['output']
          readonly status: FormTemplateStatus
          readonly user: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
          }
        }>
        readonly associatedCompany: {
          readonly __typename: 'Company'
          readonly id: ID['output']
        } | null
        readonly requestingCompany: {
          readonly __typename: 'Company'
          readonly id: ID['output']
        } | null
        readonly associatedContracts: ReadonlyArray<{
          readonly __typename: 'FormTemplateAssociatedContract'
          readonly id: String['output']
          readonly providedAsFormType: ProjectOnboardingFormType | null
          readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
        }>
        readonly owner: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        }
        readonly builder: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        }
        readonly validator: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        } | null
        readonly versions: ReadonlyArray<{
          readonly __typename: 'FormTemplateVersion'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly updatedAt: DateTime['output']
          readonly versionNumber: Int['output']
          readonly isReadyForManualStoredMaterials: Boolean['output']
          readonly file: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          }
          readonly annotations: ReadonlyArray<{
            readonly __typename: 'FormTemplateAnnotation'
            readonly id: ID['output']
            readonly permanentId: ID['output']
            readonly syncTag: String['output'] | null
            readonly userVisibleName: String['output']
            readonly type: FormTemplateAnnotationType
            readonly xStart: Float['output']
            readonly yStart: Float['output']
            readonly width: Float['output']
            readonly height: Float['output']
            readonly pageNumber: Int['output']
            readonly fontFamily: FormTemplateFont
            readonly fontColor: String['output']
            readonly textAlignment: TextAlignment
            readonly wrapText: Boolean['output']
            readonly isOptional: Boolean['output']
            readonly selectedKey: String['output'] | null
            readonly defaultValueKey: String['output'] | null
            readonly prefix: String['output'] | null
            readonly suffix: String['output'] | null
            readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
            readonly doNotRetainOnReset: Boolean['output']
            readonly dynamicFieldTag: String['output'] | null
            readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
            readonly imageType: FormTemplateAnnotationImageType | null
            readonly signatureType: SignatureAnnotationType | null
          }>
        }>
        readonly variants: ReadonlyArray<{
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly internalName: String['output']
          readonly isDefaultVariant: Boolean['output']
        }>
        readonly comments: ReadonlyArray<{
          readonly __typename: 'FormTemplateComment'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly message: String['output']
          readonly isSystemGenerated: Boolean['output']
          readonly user: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
            readonly email: EmailAddress['output']
          }
        }>
        readonly originalFile: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
        } | null
        readonly duplicateTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly userVisibleName: String['output']
        } | null
      }
    } | null
    readonly unconditionalFinalVariant: {
      readonly __typename: 'FormTemplateVariant'
      readonly id: ID['output']
      readonly template: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly createdAt: DateTime['output']
        readonly type: FormTemplateType
        readonly status: FormTemplateStatus
        readonly skippedValidation: Boolean['output']
        readonly userVisibleName: String['output']
        readonly isCustomerReady: Boolean['output']
        readonly tags: ReadonlyArray<FormTemplateTag>
        readonly dueDate: DateTime['output'] | null
        readonly statusLogs: ReadonlyArray<{
          readonly __typename: 'FormTemplateStatusLog'
          readonly id: ID['output']
          readonly statusUpdatedAt: DateTime['output']
          readonly status: FormTemplateStatus
          readonly user: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
          }
        }>
        readonly associatedCompany: {
          readonly __typename: 'Company'
          readonly id: ID['output']
        } | null
        readonly requestingCompany: {
          readonly __typename: 'Company'
          readonly id: ID['output']
        } | null
        readonly associatedContracts: ReadonlyArray<{
          readonly __typename: 'FormTemplateAssociatedContract'
          readonly id: String['output']
          readonly providedAsFormType: ProjectOnboardingFormType | null
          readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
        }>
        readonly owner: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        }
        readonly builder: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        }
        readonly validator: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        } | null
        readonly versions: ReadonlyArray<{
          readonly __typename: 'FormTemplateVersion'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly updatedAt: DateTime['output']
          readonly versionNumber: Int['output']
          readonly isReadyForManualStoredMaterials: Boolean['output']
          readonly file: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          }
          readonly annotations: ReadonlyArray<{
            readonly __typename: 'FormTemplateAnnotation'
            readonly id: ID['output']
            readonly permanentId: ID['output']
            readonly syncTag: String['output'] | null
            readonly userVisibleName: String['output']
            readonly type: FormTemplateAnnotationType
            readonly xStart: Float['output']
            readonly yStart: Float['output']
            readonly width: Float['output']
            readonly height: Float['output']
            readonly pageNumber: Int['output']
            readonly fontFamily: FormTemplateFont
            readonly fontColor: String['output']
            readonly textAlignment: TextAlignment
            readonly wrapText: Boolean['output']
            readonly isOptional: Boolean['output']
            readonly selectedKey: String['output'] | null
            readonly defaultValueKey: String['output'] | null
            readonly prefix: String['output'] | null
            readonly suffix: String['output'] | null
            readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
            readonly doNotRetainOnReset: Boolean['output']
            readonly dynamicFieldTag: String['output'] | null
            readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
            readonly imageType: FormTemplateAnnotationImageType | null
            readonly signatureType: SignatureAnnotationType | null
          }>
        }>
        readonly variants: ReadonlyArray<{
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly internalName: String['output']
          readonly isDefaultVariant: Boolean['output']
        }>
        readonly comments: ReadonlyArray<{
          readonly __typename: 'FormTemplateComment'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly message: String['output']
          readonly isSystemGenerated: Boolean['output']
          readonly user: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
            readonly email: EmailAddress['output']
          }
        }>
        readonly originalFile: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
        } | null
        readonly duplicateTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly userVisibleName: String['output']
        } | null
      }
    } | null
    readonly unconditionalProgressVariant: {
      readonly __typename: 'FormTemplateVariant'
      readonly id: ID['output']
      readonly template: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly createdAt: DateTime['output']
        readonly type: FormTemplateType
        readonly status: FormTemplateStatus
        readonly skippedValidation: Boolean['output']
        readonly userVisibleName: String['output']
        readonly isCustomerReady: Boolean['output']
        readonly tags: ReadonlyArray<FormTemplateTag>
        readonly dueDate: DateTime['output'] | null
        readonly statusLogs: ReadonlyArray<{
          readonly __typename: 'FormTemplateStatusLog'
          readonly id: ID['output']
          readonly statusUpdatedAt: DateTime['output']
          readonly status: FormTemplateStatus
          readonly user: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
          }
        }>
        readonly associatedCompany: {
          readonly __typename: 'Company'
          readonly id: ID['output']
        } | null
        readonly requestingCompany: {
          readonly __typename: 'Company'
          readonly id: ID['output']
        } | null
        readonly associatedContracts: ReadonlyArray<{
          readonly __typename: 'FormTemplateAssociatedContract'
          readonly id: String['output']
          readonly providedAsFormType: ProjectOnboardingFormType | null
          readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
        }>
        readonly owner: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        }
        readonly builder: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        }
        readonly validator: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        } | null
        readonly versions: ReadonlyArray<{
          readonly __typename: 'FormTemplateVersion'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly updatedAt: DateTime['output']
          readonly versionNumber: Int['output']
          readonly isReadyForManualStoredMaterials: Boolean['output']
          readonly file: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          }
          readonly annotations: ReadonlyArray<{
            readonly __typename: 'FormTemplateAnnotation'
            readonly id: ID['output']
            readonly permanentId: ID['output']
            readonly syncTag: String['output'] | null
            readonly userVisibleName: String['output']
            readonly type: FormTemplateAnnotationType
            readonly xStart: Float['output']
            readonly yStart: Float['output']
            readonly width: Float['output']
            readonly height: Float['output']
            readonly pageNumber: Int['output']
            readonly fontFamily: FormTemplateFont
            readonly fontColor: String['output']
            readonly textAlignment: TextAlignment
            readonly wrapText: Boolean['output']
            readonly isOptional: Boolean['output']
            readonly selectedKey: String['output'] | null
            readonly defaultValueKey: String['output'] | null
            readonly prefix: String['output'] | null
            readonly suffix: String['output'] | null
            readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
            readonly doNotRetainOnReset: Boolean['output']
            readonly dynamicFieldTag: String['output'] | null
            readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
            readonly imageType: FormTemplateAnnotationImageType | null
            readonly signatureType: SignatureAnnotationType | null
          }>
        }>
        readonly variants: ReadonlyArray<{
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly internalName: String['output']
          readonly isDefaultVariant: Boolean['output']
        }>
        readonly comments: ReadonlyArray<{
          readonly __typename: 'FormTemplateComment'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly message: String['output']
          readonly isSystemGenerated: Boolean['output']
          readonly user: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
            readonly email: EmailAddress['output']
          }
        }>
        readonly originalFile: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
        } | null
        readonly duplicateTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly userVisibleName: String['output']
        } | null
      }
    } | null
  } | null
}

export type EnablePrimaryLienWaiversMutationVariables = Exact<{
  input: EnableLienWaiverInput
}>

export type EnablePrimaryLienWaiversMutation = {
  readonly __typename: 'Mutation'
  readonly enablePrimaryLienWaivers: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly lienWaiverTemplates: {
      readonly __typename: 'SubcontractorLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly conditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly conditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
    } | null
    readonly lowerTierLienWaiverTemplates: {
      readonly __typename: 'SubcontractorLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly conditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly conditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
    } | null
  }
}

export type DisablePrimaryLienWaiversMutationVariables = Exact<{
  contractId: Scalars['ID']['input']
}>

export type DisablePrimaryLienWaiversMutation = {
  readonly __typename: 'Mutation'
  readonly disablePrimaryLienWaivers: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly lienWaiverTemplates: {
      readonly __typename: 'SubcontractorLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly conditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly conditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
    } | null
    readonly lowerTierLienWaiverTemplates: {
      readonly __typename: 'SubcontractorLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly conditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly conditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
    } | null
  }
}

export type EnableLowerTierLienWaiversMutationVariables = Exact<{
  input: EnableLienWaiverInput
}>

export type EnableLowerTierLienWaiversMutation = {
  readonly __typename: 'Mutation'
  readonly enableLowerTierLienWaivers: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly lienWaiverTemplates: {
      readonly __typename: 'SubcontractorLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly conditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly conditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
    } | null
    readonly lowerTierLienWaiverTemplates: {
      readonly __typename: 'SubcontractorLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly conditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly conditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
    } | null
  }
}

export type DisableLowerTierLienWaiversMutationVariables = Exact<{
  contractId: Scalars['ID']['input']
}>

export type DisableLowerTierLienWaiversMutation = {
  readonly __typename: 'Mutation'
  readonly disableLowerTierLienWaivers: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly lienWaiverTemplates: {
      readonly __typename: 'SubcontractorLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly conditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly conditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
    } | null
    readonly lowerTierLienWaiverTemplates: {
      readonly __typename: 'SubcontractorLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly conditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly conditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
    } | null
  }
}

export type UpdateContractMutationVariables = Exact<{
  input: UpdateContractInput
}>

export type UpdateContractMutation = {
  readonly __typename: 'Mutation'
  readonly updateContract: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly role: CompanyProjectRole
    readonly status: ContractStatus
    readonly timeZone: String['output']
    readonly contractDate: DateTime['output'] | null
    readonly contractNumber: String['output'] | null
    readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
    readonly defaultRetentionPercent: Float['output'] | null
    readonly retentionTrackingLevel: RetentionTrackingLevel
    readonly daysBeforePayAppDue: Int['output']
    readonly billingType: BillingType
    readonly contractTemplateVariables: JSONObject['output']
    readonly internalProjectNumber: String['output'] | null
    readonly vendorNumber: String['output'] | null
    readonly pastPayAppCount: Int['output']
    readonly roundRetention: Boolean['output']
    readonly project: {
      readonly __typename: 'Project'
      readonly id: ID['output']
      readonly name: String['output']
      readonly projectNumber: String['output']
      readonly location: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }
      readonly generalContractor: {
        readonly __typename: 'CompanyProjectMetadata'
        readonly role: CompanyProjectRole
        readonly companyName: String['output'] | null
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
          readonly nickname: String['output'] | null
          readonly type: CompanyType
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly expectedNumberOfProjects: SafeInt['output'] | null
          readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
          readonly allowLumpSumOverbilling: Boolean['output']
          readonly notaryTags: ReadonlyArray<NotaryTag>
          readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
          readonly isDemoCompany: Boolean['output']
          readonly picture: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly notaryStamp: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly notarySignature: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly secondarySignature: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly locations: ReadonlyArray<{
            readonly __typename: 'Location'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly nickname: String['output'] | null
            readonly street1: String['output'] | null
            readonly street2: String['output'] | null
            readonly city: String['output']
            readonly county: String['output'] | null
            readonly state: String['output']
            readonly country: String['output']
            readonly postalCode: String['output'] | null
            readonly timeZone: String['output']
            readonly coordinates: LatLng['output']
          }>
          readonly metadata: {
            readonly __typename: 'CompanyMetadata'
            readonly subtrade: String['output'] | null
            readonly domains: ReadonlyArray<String['output']>
            readonly domainSignup: Boolean['output']
            readonly monthlyCreatePayAppReminderDate: Int['output'] | null
          }
          readonly defaultQuickBillingTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
          } | null
          readonly sitelinePointOfContact: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
          } | null
        }
        readonly selectedAddress: {
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        } | null
      } | null
    }
    readonly company: {
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
      readonly nickname: String['output'] | null
      readonly type: CompanyType
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly expectedNumberOfProjects: SafeInt['output'] | null
      readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
      readonly allowLumpSumOverbilling: Boolean['output']
      readonly notaryTags: ReadonlyArray<NotaryTag>
      readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
      readonly isDemoCompany: Boolean['output']
      readonly picture: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly notaryStamp: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly notarySignature: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly secondarySignature: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly locations: ReadonlyArray<{
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }>
      readonly metadata: {
        readonly __typename: 'CompanyMetadata'
        readonly subtrade: String['output'] | null
        readonly domains: ReadonlyArray<String['output']>
        readonly domainSignup: Boolean['output']
        readonly monthlyCreatePayAppReminderDate: Int['output'] | null
      }
      readonly defaultQuickBillingTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
      } | null
      readonly sitelinePointOfContact: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
      } | null
    }
    readonly sov: {
      readonly __typename: 'Sov'
      readonly id: ID['output']
      readonly totalValue: SafeInt['output']
      readonly totalBilled: SafeInt['output']
      readonly totalRetention: SafeInt['output']
      readonly progressComplete: Float['output']
      readonly contract: {
        readonly __typename: 'Contract'
        readonly id: ID['output']
        readonly timeZone: String['output']
      }
      readonly lineItems: ReadonlyArray<{
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
        readonly code: String['output']
        readonly sortOrder: SafeInt['output']
        readonly name: String['output']
        readonly isChangeOrder: Boolean['output']
        readonly changeOrderApprovedDate: DateTime['output'] | null
        readonly latestTotalValue: SafeInt['output']
        readonly totalBilled: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly previousStoredMaterials: SafeInt['output']
        readonly totalRetention: SafeInt['output']
        readonly progressComplete: Float['output']
        readonly defaultRetentionPercent: Float['output'] | null
        readonly sovLineItemGroup: {
          readonly __typename: 'SovLineItemGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly code: String['output'] | null
        } | null
      }>
    } | null
    readonly lienWaiverTemplates: {
      readonly __typename: 'SubcontractorLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly conditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly conditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
    } | null
    readonly lowerTierLienWaiverTemplates: {
      readonly __typename: 'SubcontractorLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly conditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly conditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
    } | null
    readonly payAppRequirementGroups: ReadonlyArray<{
      readonly __typename: 'PayAppRequirementGroup'
      readonly id: ID['output']
      readonly order: Int['output']
      readonly payAppRequirements: ReadonlyArray<{
        readonly __typename: 'PayAppRequirement'
        readonly id: ID['output']
        readonly groupOrder: Int['output']
        readonly conditions: ReadonlyArray<PayAppRequirementCondition>
        readonly templateVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly internalName: String['output']
          readonly userVisibleName: String['output'] | null
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly isCustomerReady: Boolean['output']
          }
        } | null
      }>
    }>
    readonly legalRequirements: ReadonlyArray<{
      readonly __typename: 'LegalRequirement'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly name: String['output']
      readonly timeZone: String['output']
      readonly expirationFrequency: DocumentExpirationFrequency
      readonly startDate: DateTime['output'] | null
      readonly endDate: DateTime['output'] | null
      readonly isVendorRequirement: Boolean['output']
      readonly documents: ReadonlyArray<{
        readonly __typename: 'LegalDocument'
        readonly id: ID['output']
      }>
      readonly formTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
      } | null
    }>
    readonly selectedAddress: {
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    }
    readonly changeOrderRequestTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
    } | null
    readonly changeOrderLogTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
    } | null
    readonly integrations: ReadonlyArray<{
      readonly __typename: 'Integration'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly type: IntegrationType
      readonly credentialsUpdatedAt: DateTime['output'] | null
      readonly mappings: JSONObject['output']
      readonly isActive: Boolean['output']
      readonly shortName: String['output']
      readonly longName: String['output']
      readonly companyIntegration: {
        readonly __typename: 'CompanyIntegration'
        readonly id: ID['output']
      }
    }>
    readonly onboardedStatus: {
      readonly __typename: 'OnboardedProjectContractStatus'
      readonly selectedPayAppForms: Boolean['output']
      readonly onboardedPayAppForms: Boolean['output']
      readonly selectedPrimaryLienWaivers: Boolean['output']
      readonly onboardedPrimaryLienWaiverForms: Boolean['output']
      readonly selectedVendorLienWaivers: Boolean['output']
      readonly onboardedVendorLienWaiverForms: Boolean['output']
      readonly selectedChangeOrderRequestForms: Boolean['output']
      readonly onboardedChangeOrderRequestForms: Boolean['output']
      readonly addedSov: Boolean['output']
      readonly selectedRateTable: Boolean['output']
      readonly addedTeammates: Boolean['output']
      readonly addedGcContacts: Boolean['output']
      readonly startedBilling: Boolean['output']
      readonly notifiedOnboardedForms: Boolean['output']
    }
  }
}

export type SovLineItemGroupsQueryVariables = Exact<{
  contractId: Scalars['ID']['input']
}>

export type SovLineItemGroupsQuery = {
  readonly __typename: 'Query'
  readonly sovLineItemGroups: ReadonlyArray<{
    readonly __typename: 'SovLineItemGroup'
    readonly id: ID['output']
    readonly name: String['output']
    readonly code: String['output'] | null
  }>
}

export type UpdateChangeOrderRequestFormMutationVariables = Exact<{
  input: UpdateChangeOrderRequestFormInput
}>

export type UpdateChangeOrderRequestFormMutation = {
  readonly __typename: 'Mutation'
  readonly updateChangeOrderRequestForm: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly changeOrderRequestTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
    } | null
  }
}

export type UpdateChangeOrderLogFormMutationVariables = Exact<{
  input: UpdateChangeOrderLogFormInput
}>

export type UpdateChangeOrderLogFormMutation = {
  readonly __typename: 'Mutation'
  readonly updateChangeOrderLogForm: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly changeOrderLogTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
    } | null
  }
}

export type ConvertRetentionTrackingLevelMutationVariables = Exact<{
  input: ConvertRetentionTrackingLevelInput
}>

export type ConvertRetentionTrackingLevelMutation = {
  readonly __typename: 'Mutation'
  readonly convertRetentionTrackingLevel: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly role: CompanyProjectRole
    readonly status: ContractStatus
    readonly timeZone: String['output']
    readonly contractDate: DateTime['output'] | null
    readonly contractNumber: String['output'] | null
    readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
    readonly defaultRetentionPercent: Float['output'] | null
    readonly retentionTrackingLevel: RetentionTrackingLevel
    readonly daysBeforePayAppDue: Int['output']
    readonly billingType: BillingType
    readonly contractTemplateVariables: JSONObject['output']
    readonly internalProjectNumber: String['output'] | null
    readonly vendorNumber: String['output'] | null
    readonly pastPayAppCount: Int['output']
    readonly roundRetention: Boolean['output']
    readonly project: {
      readonly __typename: 'Project'
      readonly id: ID['output']
      readonly name: String['output']
      readonly projectNumber: String['output']
      readonly location: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }
      readonly generalContractor: {
        readonly __typename: 'CompanyProjectMetadata'
        readonly role: CompanyProjectRole
        readonly companyName: String['output'] | null
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
          readonly nickname: String['output'] | null
          readonly type: CompanyType
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly expectedNumberOfProjects: SafeInt['output'] | null
          readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
          readonly allowLumpSumOverbilling: Boolean['output']
          readonly notaryTags: ReadonlyArray<NotaryTag>
          readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
          readonly isDemoCompany: Boolean['output']
          readonly picture: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly notaryStamp: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly notarySignature: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly secondarySignature: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly locations: ReadonlyArray<{
            readonly __typename: 'Location'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly nickname: String['output'] | null
            readonly street1: String['output'] | null
            readonly street2: String['output'] | null
            readonly city: String['output']
            readonly county: String['output'] | null
            readonly state: String['output']
            readonly country: String['output']
            readonly postalCode: String['output'] | null
            readonly timeZone: String['output']
            readonly coordinates: LatLng['output']
          }>
          readonly metadata: {
            readonly __typename: 'CompanyMetadata'
            readonly subtrade: String['output'] | null
            readonly domains: ReadonlyArray<String['output']>
            readonly domainSignup: Boolean['output']
            readonly monthlyCreatePayAppReminderDate: Int['output'] | null
          }
          readonly defaultQuickBillingTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
          } | null
          readonly sitelinePointOfContact: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
          } | null
        }
        readonly selectedAddress: {
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        } | null
      } | null
    }
    readonly company: {
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
      readonly nickname: String['output'] | null
      readonly type: CompanyType
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly expectedNumberOfProjects: SafeInt['output'] | null
      readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
      readonly allowLumpSumOverbilling: Boolean['output']
      readonly notaryTags: ReadonlyArray<NotaryTag>
      readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
      readonly isDemoCompany: Boolean['output']
      readonly picture: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly notaryStamp: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly notarySignature: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly secondarySignature: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly locations: ReadonlyArray<{
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }>
      readonly metadata: {
        readonly __typename: 'CompanyMetadata'
        readonly subtrade: String['output'] | null
        readonly domains: ReadonlyArray<String['output']>
        readonly domainSignup: Boolean['output']
        readonly monthlyCreatePayAppReminderDate: Int['output'] | null
      }
      readonly defaultQuickBillingTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
      } | null
      readonly sitelinePointOfContact: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
      } | null
    }
    readonly sov: {
      readonly __typename: 'Sov'
      readonly id: ID['output']
      readonly totalValue: SafeInt['output']
      readonly totalBilled: SafeInt['output']
      readonly totalRetention: SafeInt['output']
      readonly progressComplete: Float['output']
      readonly contract: {
        readonly __typename: 'Contract'
        readonly id: ID['output']
        readonly timeZone: String['output']
      }
      readonly lineItems: ReadonlyArray<{
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
        readonly code: String['output']
        readonly sortOrder: SafeInt['output']
        readonly name: String['output']
        readonly isChangeOrder: Boolean['output']
        readonly changeOrderApprovedDate: DateTime['output'] | null
        readonly latestTotalValue: SafeInt['output']
        readonly totalBilled: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly previousStoredMaterials: SafeInt['output']
        readonly totalRetention: SafeInt['output']
        readonly progressComplete: Float['output']
        readonly defaultRetentionPercent: Float['output'] | null
        readonly sovLineItemGroup: {
          readonly __typename: 'SovLineItemGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly code: String['output'] | null
        } | null
      }>
    } | null
    readonly lienWaiverTemplates: {
      readonly __typename: 'SubcontractorLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly conditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly conditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
    } | null
    readonly lowerTierLienWaiverTemplates: {
      readonly __typename: 'SubcontractorLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly conditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly conditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
    } | null
    readonly payAppRequirementGroups: ReadonlyArray<{
      readonly __typename: 'PayAppRequirementGroup'
      readonly id: ID['output']
      readonly order: Int['output']
      readonly payAppRequirements: ReadonlyArray<{
        readonly __typename: 'PayAppRequirement'
        readonly id: ID['output']
        readonly groupOrder: Int['output']
        readonly conditions: ReadonlyArray<PayAppRequirementCondition>
        readonly templateVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly internalName: String['output']
          readonly userVisibleName: String['output'] | null
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly isCustomerReady: Boolean['output']
          }
        } | null
      }>
    }>
    readonly legalRequirements: ReadonlyArray<{
      readonly __typename: 'LegalRequirement'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly name: String['output']
      readonly timeZone: String['output']
      readonly expirationFrequency: DocumentExpirationFrequency
      readonly startDate: DateTime['output'] | null
      readonly endDate: DateTime['output'] | null
      readonly isVendorRequirement: Boolean['output']
      readonly documents: ReadonlyArray<{
        readonly __typename: 'LegalDocument'
        readonly id: ID['output']
      }>
      readonly formTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
      } | null
    }>
    readonly selectedAddress: {
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    }
    readonly changeOrderRequestTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
    } | null
    readonly changeOrderLogTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
    } | null
    readonly integrations: ReadonlyArray<{
      readonly __typename: 'Integration'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly type: IntegrationType
      readonly credentialsUpdatedAt: DateTime['output'] | null
      readonly mappings: JSONObject['output']
      readonly isActive: Boolean['output']
      readonly shortName: String['output']
      readonly longName: String['output']
      readonly companyIntegration: {
        readonly __typename: 'CompanyIntegration'
        readonly id: ID['output']
      }
    }>
    readonly onboardedStatus: {
      readonly __typename: 'OnboardedProjectContractStatus'
      readonly selectedPayAppForms: Boolean['output']
      readonly onboardedPayAppForms: Boolean['output']
      readonly selectedPrimaryLienWaivers: Boolean['output']
      readonly onboardedPrimaryLienWaiverForms: Boolean['output']
      readonly selectedVendorLienWaivers: Boolean['output']
      readonly onboardedVendorLienWaiverForms: Boolean['output']
      readonly selectedChangeOrderRequestForms: Boolean['output']
      readonly onboardedChangeOrderRequestForms: Boolean['output']
      readonly addedSov: Boolean['output']
      readonly selectedRateTable: Boolean['output']
      readonly addedTeammates: Boolean['output']
      readonly addedGcContacts: Boolean['output']
      readonly startedBilling: Boolean['output']
      readonly notifiedOnboardedForms: Boolean['output']
    }
  }
}

export type UpdateRoundRetentionMutationVariables = Exact<{
  input: UpdateRoundRetentionInput
}>

export type UpdateRoundRetentionMutation = {
  readonly __typename: 'Mutation'
  readonly updateRoundRetention: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly role: CompanyProjectRole
    readonly status: ContractStatus
    readonly timeZone: String['output']
    readonly contractDate: DateTime['output'] | null
    readonly contractNumber: String['output'] | null
    readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
    readonly defaultRetentionPercent: Float['output'] | null
    readonly retentionTrackingLevel: RetentionTrackingLevel
    readonly daysBeforePayAppDue: Int['output']
    readonly billingType: BillingType
    readonly contractTemplateVariables: JSONObject['output']
    readonly internalProjectNumber: String['output'] | null
    readonly vendorNumber: String['output'] | null
    readonly pastPayAppCount: Int['output']
    readonly roundRetention: Boolean['output']
    readonly project: {
      readonly __typename: 'Project'
      readonly id: ID['output']
      readonly name: String['output']
      readonly projectNumber: String['output']
      readonly location: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }
      readonly generalContractor: {
        readonly __typename: 'CompanyProjectMetadata'
        readonly role: CompanyProjectRole
        readonly companyName: String['output'] | null
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
          readonly nickname: String['output'] | null
          readonly type: CompanyType
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly expectedNumberOfProjects: SafeInt['output'] | null
          readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
          readonly allowLumpSumOverbilling: Boolean['output']
          readonly notaryTags: ReadonlyArray<NotaryTag>
          readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
          readonly isDemoCompany: Boolean['output']
          readonly picture: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly notaryStamp: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly notarySignature: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly secondarySignature: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly locations: ReadonlyArray<{
            readonly __typename: 'Location'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly nickname: String['output'] | null
            readonly street1: String['output'] | null
            readonly street2: String['output'] | null
            readonly city: String['output']
            readonly county: String['output'] | null
            readonly state: String['output']
            readonly country: String['output']
            readonly postalCode: String['output'] | null
            readonly timeZone: String['output']
            readonly coordinates: LatLng['output']
          }>
          readonly metadata: {
            readonly __typename: 'CompanyMetadata'
            readonly subtrade: String['output'] | null
            readonly domains: ReadonlyArray<String['output']>
            readonly domainSignup: Boolean['output']
            readonly monthlyCreatePayAppReminderDate: Int['output'] | null
          }
          readonly defaultQuickBillingTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
          } | null
          readonly sitelinePointOfContact: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
          } | null
        }
        readonly selectedAddress: {
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        } | null
      } | null
    }
    readonly company: {
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
      readonly nickname: String['output'] | null
      readonly type: CompanyType
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly expectedNumberOfProjects: SafeInt['output'] | null
      readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
      readonly allowLumpSumOverbilling: Boolean['output']
      readonly notaryTags: ReadonlyArray<NotaryTag>
      readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
      readonly isDemoCompany: Boolean['output']
      readonly picture: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly notaryStamp: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly notarySignature: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly secondarySignature: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly locations: ReadonlyArray<{
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }>
      readonly metadata: {
        readonly __typename: 'CompanyMetadata'
        readonly subtrade: String['output'] | null
        readonly domains: ReadonlyArray<String['output']>
        readonly domainSignup: Boolean['output']
        readonly monthlyCreatePayAppReminderDate: Int['output'] | null
      }
      readonly defaultQuickBillingTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
      } | null
      readonly sitelinePointOfContact: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
      } | null
    }
    readonly sov: {
      readonly __typename: 'Sov'
      readonly id: ID['output']
      readonly totalValue: SafeInt['output']
      readonly totalBilled: SafeInt['output']
      readonly totalRetention: SafeInt['output']
      readonly progressComplete: Float['output']
      readonly contract: {
        readonly __typename: 'Contract'
        readonly id: ID['output']
        readonly timeZone: String['output']
      }
      readonly lineItems: ReadonlyArray<{
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
        readonly code: String['output']
        readonly sortOrder: SafeInt['output']
        readonly name: String['output']
        readonly isChangeOrder: Boolean['output']
        readonly changeOrderApprovedDate: DateTime['output'] | null
        readonly latestTotalValue: SafeInt['output']
        readonly totalBilled: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly previousStoredMaterials: SafeInt['output']
        readonly totalRetention: SafeInt['output']
        readonly progressComplete: Float['output']
        readonly defaultRetentionPercent: Float['output'] | null
        readonly sovLineItemGroup: {
          readonly __typename: 'SovLineItemGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly code: String['output'] | null
        } | null
      }>
    } | null
    readonly lienWaiverTemplates: {
      readonly __typename: 'SubcontractorLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly conditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly conditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
    } | null
    readonly lowerTierLienWaiverTemplates: {
      readonly __typename: 'SubcontractorLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly conditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly conditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
      readonly unconditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly createdAt: DateTime['output']
          readonly type: FormTemplateType
          readonly status: FormTemplateStatus
          readonly skippedValidation: Boolean['output']
          readonly userVisibleName: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly tags: ReadonlyArray<FormTemplateTag>
          readonly dueDate: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'FormTemplateStatusLog'
            readonly id: ID['output']
            readonly statusUpdatedAt: DateTime['output']
            readonly status: FormTemplateStatus
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly associatedCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly requestingCompany: {
            readonly __typename: 'Company'
            readonly id: ID['output']
          } | null
          readonly associatedContracts: ReadonlyArray<{
            readonly __typename: 'FormTemplateAssociatedContract'
            readonly id: String['output']
            readonly providedAsFormType: ProjectOnboardingFormType | null
            readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
          }>
          readonly owner: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly builder: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }
          readonly validator: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          } | null
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly updatedAt: DateTime['output']
            readonly versionNumber: Int['output']
            readonly isReadyForManualStoredMaterials: Boolean['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            }
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly permanentId: ID['output']
              readonly syncTag: String['output'] | null
              readonly userVisibleName: String['output']
              readonly type: FormTemplateAnnotationType
              readonly xStart: Float['output']
              readonly yStart: Float['output']
              readonly width: Float['output']
              readonly height: Float['output']
              readonly pageNumber: Int['output']
              readonly fontFamily: FormTemplateFont
              readonly fontColor: String['output']
              readonly textAlignment: TextAlignment
              readonly wrapText: Boolean['output']
              readonly isOptional: Boolean['output']
              readonly selectedKey: String['output'] | null
              readonly defaultValueKey: String['output'] | null
              readonly prefix: String['output'] | null
              readonly suffix: String['output'] | null
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
              readonly doNotRetainOnReset: Boolean['output']
              readonly dynamicFieldTag: String['output'] | null
              readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
              readonly imageType: FormTemplateAnnotationImageType | null
              readonly signatureType: SignatureAnnotationType | null
            }>
          }>
          readonly variants: ReadonlyArray<{
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly isDefaultVariant: Boolean['output']
          }>
          readonly comments: ReadonlyArray<{
            readonly __typename: 'FormTemplateComment'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly message: String['output']
            readonly isSystemGenerated: Boolean['output']
            readonly user: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            }
          }>
          readonly originalFile: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
          } | null
          readonly duplicateTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly userVisibleName: String['output']
          } | null
        }
      } | null
    } | null
    readonly payAppRequirementGroups: ReadonlyArray<{
      readonly __typename: 'PayAppRequirementGroup'
      readonly id: ID['output']
      readonly order: Int['output']
      readonly payAppRequirements: ReadonlyArray<{
        readonly __typename: 'PayAppRequirement'
        readonly id: ID['output']
        readonly groupOrder: Int['output']
        readonly conditions: ReadonlyArray<PayAppRequirementCondition>
        readonly templateVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly internalName: String['output']
          readonly userVisibleName: String['output'] | null
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly isCustomerReady: Boolean['output']
          }
        } | null
      }>
    }>
    readonly legalRequirements: ReadonlyArray<{
      readonly __typename: 'LegalRequirement'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly name: String['output']
      readonly timeZone: String['output']
      readonly expirationFrequency: DocumentExpirationFrequency
      readonly startDate: DateTime['output'] | null
      readonly endDate: DateTime['output'] | null
      readonly isVendorRequirement: Boolean['output']
      readonly documents: ReadonlyArray<{
        readonly __typename: 'LegalDocument'
        readonly id: ID['output']
      }>
      readonly formTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
      } | null
    }>
    readonly selectedAddress: {
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    }
    readonly changeOrderRequestTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
    } | null
    readonly changeOrderLogTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
    } | null
    readonly integrations: ReadonlyArray<{
      readonly __typename: 'Integration'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly type: IntegrationType
      readonly credentialsUpdatedAt: DateTime['output'] | null
      readonly mappings: JSONObject['output']
      readonly isActive: Boolean['output']
      readonly shortName: String['output']
      readonly longName: String['output']
      readonly companyIntegration: {
        readonly __typename: 'CompanyIntegration'
        readonly id: ID['output']
      }
    }>
    readonly onboardedStatus: {
      readonly __typename: 'OnboardedProjectContractStatus'
      readonly selectedPayAppForms: Boolean['output']
      readonly onboardedPayAppForms: Boolean['output']
      readonly selectedPrimaryLienWaivers: Boolean['output']
      readonly onboardedPrimaryLienWaiverForms: Boolean['output']
      readonly selectedVendorLienWaivers: Boolean['output']
      readonly onboardedVendorLienWaiverForms: Boolean['output']
      readonly selectedChangeOrderRequestForms: Boolean['output']
      readonly onboardedChangeOrderRequestForms: Boolean['output']
      readonly addedSov: Boolean['output']
      readonly selectedRateTable: Boolean['output']
      readonly addedTeammates: Boolean['output']
      readonly addedGcContacts: Boolean['output']
      readonly startedBilling: Boolean['output']
      readonly notifiedOnboardedForms: Boolean['output']
    }
  }
}

export type DeleteSovLineItemMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteSovLineItemMutation = {
  readonly __typename: 'Mutation'
  readonly deleteSovLineItem: { readonly __typename: 'DeletionResult'; readonly id: ID['output'] }
}

export type UpdateIntegrationMappingsMutationVariables = Exact<{
  input: UpdateIntegrationMappingsInput
}>

export type UpdateIntegrationMappingsMutation = {
  readonly __typename: 'Mutation'
  readonly updateIntegrationMappings: {
    readonly __typename: 'Integration'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly type: IntegrationType
    readonly credentialsUpdatedAt: DateTime['output'] | null
    readonly mappings: JSONObject['output']
    readonly isActive: Boolean['output']
    readonly shortName: String['output']
    readonly longName: String['output']
    readonly companyIntegration: {
      readonly __typename: 'CompanyIntegration'
      readonly id: ID['output']
    }
  }
}

export type RefreshIntegrationMappingsMutationVariables = Exact<{
  input: RefreshIntegrationMappingsInput
}>

export type RefreshIntegrationMappingsMutation = {
  readonly __typename: 'Mutation'
  readonly refreshIntegrationMappings: {
    readonly __typename: 'Integration'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly type: IntegrationType
    readonly credentialsUpdatedAt: DateTime['output'] | null
    readonly mappings: JSONObject['output']
    readonly isActive: Boolean['output']
    readonly shortName: String['output']
    readonly longName: String['output']
    readonly companyIntegration: {
      readonly __typename: 'CompanyIntegration'
      readonly id: ID['output']
    }
  }
}

export type ImportSovFromIntegrationMutationVariables = Exact<{
  input: ImportSovFromIntegrationInput
}>

export type ImportSovFromIntegrationMutation = {
  readonly __typename: 'Mutation'
  readonly importSovFromIntegration: {
    readonly __typename: 'Sov'
    readonly id: ID['output']
    readonly totalValue: SafeInt['output']
    readonly totalBilled: SafeInt['output']
    readonly totalRetention: SafeInt['output']
    readonly progressComplete: Float['output']
  }
}

export type AbandonedPayAppsQueryVariables = Exact<{ [key: string]: never }>

export type AbandonedPayAppsQuery = {
  readonly __typename: 'Query'
  readonly abandonedPayApps: ReadonlyArray<{
    readonly __typename: 'AbandonedPayAppsData'
    readonly companyId: ID['output']
    readonly companyName: String['output']
    readonly companyCreatedAt: DateTime['output']
    readonly abandonedPayAppsCount: SafeInt['output']
    readonly abandonedPayAppsIds: ReadonlyArray<ID['output']>
    readonly sitelinePointOfContactId: ID['output'] | null
    readonly sitelinePointOfContactName: String['output'] | null
  }>
}

export type BillingTotalsQueryVariables = Exact<{
  input: BillingTotalsInput
}>

export type BillingTotalsQuery = {
  readonly __typename: 'Query'
  readonly billingTotals: ReadonlyArray<{
    readonly __typename: 'BillingTotalsData'
    readonly month: DateTime['output']
    readonly companyId: ID['output']
    readonly companyCreatedAt: DateTime['output']
    readonly companyName: String['output']
    readonly submittedPayAppCount: SafeInt['output']
    readonly totalBilledThisMonth: Float['output']
    readonly sitelinePointOfContactId: ID['output'] | null
    readonly sitelinePointOfContactName: String['output'] | null
  }>
}

export type ChangeOrderLogUseQueryVariables = Exact<{ [key: string]: never }>

export type ChangeOrderLogUseQuery = {
  readonly __typename: 'Query'
  readonly changeOrderLogUse: ReadonlyArray<{
    readonly __typename: 'ChangeOrderLogUseData'
    readonly companyId: ID['output']
    readonly companyName: String['output']
    readonly companyCreatedAt: DateTime['output']
    readonly percentProjectsWithChangeOrder: Float['output']
    readonly sitelinePointOfContactId: ID['output'] | null
    readonly sitelinePointOfContactName: String['output'] | null
  }>
}

export type ComplianceUseQueryVariables = Exact<{ [key: string]: never }>

export type ComplianceUseQuery = {
  readonly __typename: 'Query'
  readonly complianceUse: ReadonlyArray<{
    readonly __typename: 'ComplianceUseData'
    readonly companyId: ID['output']
    readonly companyName: String['output']
    readonly companyCreatedAt: DateTime['output']
    readonly percentProjectsWithComplianceRequirements: Float['output']
    readonly sitelinePointOfContactId: ID['output'] | null
    readonly sitelinePointOfContactName: String['output'] | null
  }>
}

export type FormTrackerQueryVariables = Exact<{
  input: FormTrackerInput
}>

export type FormTrackerQuery = {
  readonly __typename: 'Query'
  readonly formTracker: ReadonlyArray<{
    readonly __typename: 'FormTrackerData'
    readonly companyId: ID['output']
    readonly companyName: String['output']
    readonly companyCreatedAt: DateTime['output']
    readonly requestedFormsCount: SafeInt['output']
    readonly requestedFormIds: ReadonlyArray<ID['output']>
    readonly completedFormsCount: SafeInt['output']
    readonly completedFormIds: ReadonlyArray<ID['output']>
    readonly formsCompletedRatio: Float['output']
    readonly sitelinePointOfContactId: ID['output'] | null
    readonly sitelinePointOfContactName: String['output'] | null
  }>
}

export type PayAppsSubmittedMonthQueryVariables = Exact<{
  input: PayAppsSubmittedMonthInput
}>

export type PayAppsSubmittedMonthQuery = {
  readonly __typename: 'Query'
  readonly payAppsSubmittedMonth: ReadonlyArray<{
    readonly __typename: 'PayAppsSubmittedData'
    readonly companyId: ID['output']
    readonly companyName: String['output']
    readonly companyCreatedAt: DateTime['output']
    readonly payAppsSubmittedThisMonth: SafeInt['output']
    readonly sitelinePointOfContactId: ID['output'] | null
    readonly sitelinePointOfContactName: String['output'] | null
  }>
}

export type TimeToValueQueryVariables = Exact<{ [key: string]: never }>

export type TimeToValueQuery = {
  readonly __typename: 'Query'
  readonly timeToValue: ReadonlyArray<{
    readonly __typename: 'TimeToValueData'
    readonly company: {
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly nickname: String['output'] | null
      readonly name: String['output']
      readonly expectedNumberOfProjects: SafeInt['output'] | null
      readonly sitelinePointOfContact: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      } | null
    }
    readonly billingByMonth: ReadonlyArray<{
      readonly __typename: 'TimeToValueMonth'
      readonly month: String['output']
      readonly cumulativeContractsBilled: SafeInt['output']
    }>
  }>
}

export type LienWaiversRequestedQueryVariables = Exact<{ [key: string]: never }>

export type LienWaiversRequestedQuery = {
  readonly __typename: 'Query'
  readonly lienWaiversRequested: ReadonlyArray<{
    readonly __typename: 'LienWaiverRequestData'
    readonly companyId: ID['output']
    readonly companyName: String['output']
    readonly companyCreatedAt: DateTime['output']
    readonly lienWaiversRequested: SafeInt['output']
    readonly sitelinePointOfContactId: ID['output'] | null
    readonly sitelinePointOfContactName: String['output'] | null
  }>
}

export type ProjectLocationsQueryVariables = Exact<{ [key: string]: never }>

export type ProjectLocationsQuery = {
  readonly __typename: 'Query'
  readonly projects: ReadonlyArray<{
    readonly __typename: 'Project'
    readonly id: ID['output']
    readonly name: String['output']
    readonly contracts: ReadonlyArray<{
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly users: ReadonlyArray<{
        readonly __typename: 'ContractUser'
        readonly id: ID['output']
        readonly user: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
        }
      }>
    }>
    readonly location: {
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    }
  }>
}

export type CompanyLocationsQueryVariables = Exact<{ [key: string]: never }>

export type CompanyLocationsQuery = {
  readonly __typename: 'Query'
  readonly companies: ReadonlyArray<{
    readonly __typename: 'Company'
    readonly id: ID['output']
    readonly name: String['output']
    readonly type: CompanyType
    readonly users: ReadonlyArray<{
      readonly __typename: 'CompanyUser'
      readonly id: ID['output']
      readonly status: UserStatus
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
      }
    }>
    readonly locations: ReadonlyArray<{
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    }>
  }>
}

export type DemoCompaniesQueryVariables = Exact<{ [key: string]: never }>

export type DemoCompaniesQuery = {
  readonly __typename: 'Query'
  readonly demoCompanies: ReadonlyArray<{
    readonly __typename: 'Company'
    readonly id: ID['output']
    readonly name: String['output']
    readonly nickname: String['output'] | null
  }>
}

export type MarkOldSubmittedPayAppsAsPaidMutationVariables = Exact<{
  input: MarkOldSubmittedPayAppsAsPaidInput
}>

export type MarkOldSubmittedPayAppsAsPaidMutation = {
  readonly __typename: 'Mutation'
  readonly markOldSubmittedPayAppsAsPaid: Boolean['output']
}

export type DeleteLienWaiverRequestsForMonthMutationVariables = Exact<{
  input: DeleteLienWaiverRequestsForMonthInput
}>

export type DeleteLienWaiverRequestsForMonthMutation = {
  readonly __typename: 'Mutation'
  readonly deleteLienWaiverRequestsForMonth: Boolean['output']
}

export type MarkLienWaiverRequestsAsSubmittedForMonthMutationVariables = Exact<{
  input: MarkLienWaiverRequestsAsSubmittedForMonthInput
}>

export type MarkLienWaiverRequestsAsSubmittedForMonthMutation = {
  readonly __typename: 'Mutation'
  readonly markLienWaiverRequestsAsSubmittedForMonth: Boolean['output']
}

export type CreateFormTemplateMutationVariables = Exact<{
  input: CreateFormTemplateInput
}>

export type CreateFormTemplateMutation = {
  readonly __typename: 'Mutation'
  readonly createFormTemplate: {
    readonly __typename: 'FormTemplate'
    readonly id: String['output']
    readonly createdAt: DateTime['output']
    readonly type: FormTemplateType
    readonly status: FormTemplateStatus
    readonly skippedValidation: Boolean['output']
    readonly userVisibleName: String['output']
    readonly isCustomerReady: Boolean['output']
    readonly tags: ReadonlyArray<FormTemplateTag>
    readonly dueDate: DateTime['output'] | null
    readonly statusLogs: ReadonlyArray<{
      readonly __typename: 'FormTemplateStatusLog'
      readonly id: ID['output']
      readonly statusUpdatedAt: DateTime['output']
      readonly status: FormTemplateStatus
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
      }
    }>
    readonly associatedCompany: { readonly __typename: 'Company'; readonly id: ID['output'] } | null
    readonly requestingCompany: { readonly __typename: 'Company'; readonly id: ID['output'] } | null
    readonly associatedContracts: ReadonlyArray<{
      readonly __typename: 'FormTemplateAssociatedContract'
      readonly id: String['output']
      readonly providedAsFormType: ProjectOnboardingFormType | null
      readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
    }>
    readonly owner: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
    readonly builder: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
    readonly validator: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    } | null
    readonly versions: ReadonlyArray<{
      readonly __typename: 'FormTemplateVersion'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly updatedAt: DateTime['output']
      readonly versionNumber: Int['output']
      readonly isReadyForManualStoredMaterials: Boolean['output']
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
      }
      readonly annotations: ReadonlyArray<{
        readonly __typename: 'FormTemplateAnnotation'
        readonly id: ID['output']
        readonly permanentId: ID['output']
        readonly syncTag: String['output'] | null
        readonly userVisibleName: String['output']
        readonly type: FormTemplateAnnotationType
        readonly xStart: Float['output']
        readonly yStart: Float['output']
        readonly width: Float['output']
        readonly height: Float['output']
        readonly pageNumber: Int['output']
        readonly fontFamily: FormTemplateFont
        readonly fontColor: String['output']
        readonly textAlignment: TextAlignment
        readonly wrapText: Boolean['output']
        readonly isOptional: Boolean['output']
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output']
        readonly dynamicFieldTag: String['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
        readonly imageType: FormTemplateAnnotationImageType | null
        readonly signatureType: SignatureAnnotationType | null
      }>
    }>
    readonly variants: ReadonlyArray<{
      readonly __typename: 'FormTemplateVariant'
      readonly id: ID['output']
      readonly internalName: String['output']
      readonly isDefaultVariant: Boolean['output']
    }>
    readonly comments: ReadonlyArray<{
      readonly __typename: 'FormTemplateComment'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly message: String['output']
      readonly isSystemGenerated: Boolean['output']
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
      }
    }>
    readonly originalFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
    } | null
    readonly duplicateTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly userVisibleName: String['output']
    } | null
  }
}

export type SitelineTeamMembersQueryVariables = Exact<{ [key: string]: never }>

export type SitelineTeamMembersQuery = {
  readonly __typename: 'Query'
  readonly sitelineTeamMembers: ReadonlyArray<{
    readonly __typename: 'User'
    readonly id: ID['output']
    readonly firstName: String['output']
    readonly lastName: String['output']
    readonly email: EmailAddress['output']
  }>
}

export type UpdateFormTemplateStatusMutationVariables = Exact<{
  input: UpdateFormTemplateStatusInput
}>

export type UpdateFormTemplateStatusMutation = {
  readonly __typename: 'Mutation'
  readonly updateFormTemplateStatus: {
    readonly __typename: 'FormTemplate'
    readonly id: String['output']
    readonly createdAt: DateTime['output']
    readonly type: FormTemplateType
    readonly status: FormTemplateStatus
    readonly skippedValidation: Boolean['output']
    readonly userVisibleName: String['output']
    readonly isCustomerReady: Boolean['output']
    readonly tags: ReadonlyArray<FormTemplateTag>
    readonly dueDate: DateTime['output'] | null
    readonly statusLogs: ReadonlyArray<{
      readonly __typename: 'FormTemplateStatusLog'
      readonly id: ID['output']
      readonly statusUpdatedAt: DateTime['output']
      readonly status: FormTemplateStatus
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
      }
    }>
    readonly associatedCompany: { readonly __typename: 'Company'; readonly id: ID['output'] } | null
    readonly requestingCompany: { readonly __typename: 'Company'; readonly id: ID['output'] } | null
    readonly associatedContracts: ReadonlyArray<{
      readonly __typename: 'FormTemplateAssociatedContract'
      readonly id: String['output']
      readonly providedAsFormType: ProjectOnboardingFormType | null
      readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
    }>
    readonly owner: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
    readonly builder: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
    readonly validator: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    } | null
    readonly versions: ReadonlyArray<{
      readonly __typename: 'FormTemplateVersion'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly updatedAt: DateTime['output']
      readonly versionNumber: Int['output']
      readonly isReadyForManualStoredMaterials: Boolean['output']
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
      }
      readonly annotations: ReadonlyArray<{
        readonly __typename: 'FormTemplateAnnotation'
        readonly id: ID['output']
        readonly permanentId: ID['output']
        readonly syncTag: String['output'] | null
        readonly userVisibleName: String['output']
        readonly type: FormTemplateAnnotationType
        readonly xStart: Float['output']
        readonly yStart: Float['output']
        readonly width: Float['output']
        readonly height: Float['output']
        readonly pageNumber: Int['output']
        readonly fontFamily: FormTemplateFont
        readonly fontColor: String['output']
        readonly textAlignment: TextAlignment
        readonly wrapText: Boolean['output']
        readonly isOptional: Boolean['output']
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output']
        readonly dynamicFieldTag: String['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
        readonly imageType: FormTemplateAnnotationImageType | null
        readonly signatureType: SignatureAnnotationType | null
      }>
    }>
    readonly variants: ReadonlyArray<{
      readonly __typename: 'FormTemplateVariant'
      readonly id: ID['output']
      readonly internalName: String['output']
      readonly isDefaultVariant: Boolean['output']
    }>
    readonly comments: ReadonlyArray<{
      readonly __typename: 'FormTemplateComment'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly message: String['output']
      readonly isSystemGenerated: Boolean['output']
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
      }
    }>
    readonly originalFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
    } | null
    readonly duplicateTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly userVisibleName: String['output']
    } | null
  }
}

export type UpdateFormTemplateOwnerMutationVariables = Exact<{
  input: UpdateFormTemplateUserInput
}>

export type UpdateFormTemplateOwnerMutation = {
  readonly __typename: 'Mutation'
  readonly updateFormTemplateOwner: {
    readonly __typename: 'FormTemplate'
    readonly id: String['output']
    readonly createdAt: DateTime['output']
    readonly type: FormTemplateType
    readonly status: FormTemplateStatus
    readonly skippedValidation: Boolean['output']
    readonly userVisibleName: String['output']
    readonly isCustomerReady: Boolean['output']
    readonly tags: ReadonlyArray<FormTemplateTag>
    readonly dueDate: DateTime['output'] | null
    readonly statusLogs: ReadonlyArray<{
      readonly __typename: 'FormTemplateStatusLog'
      readonly id: ID['output']
      readonly statusUpdatedAt: DateTime['output']
      readonly status: FormTemplateStatus
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
      }
    }>
    readonly associatedCompany: { readonly __typename: 'Company'; readonly id: ID['output'] } | null
    readonly requestingCompany: { readonly __typename: 'Company'; readonly id: ID['output'] } | null
    readonly associatedContracts: ReadonlyArray<{
      readonly __typename: 'FormTemplateAssociatedContract'
      readonly id: String['output']
      readonly providedAsFormType: ProjectOnboardingFormType | null
      readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
    }>
    readonly owner: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
    readonly builder: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
    readonly validator: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    } | null
    readonly versions: ReadonlyArray<{
      readonly __typename: 'FormTemplateVersion'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly updatedAt: DateTime['output']
      readonly versionNumber: Int['output']
      readonly isReadyForManualStoredMaterials: Boolean['output']
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
      }
      readonly annotations: ReadonlyArray<{
        readonly __typename: 'FormTemplateAnnotation'
        readonly id: ID['output']
        readonly permanentId: ID['output']
        readonly syncTag: String['output'] | null
        readonly userVisibleName: String['output']
        readonly type: FormTemplateAnnotationType
        readonly xStart: Float['output']
        readonly yStart: Float['output']
        readonly width: Float['output']
        readonly height: Float['output']
        readonly pageNumber: Int['output']
        readonly fontFamily: FormTemplateFont
        readonly fontColor: String['output']
        readonly textAlignment: TextAlignment
        readonly wrapText: Boolean['output']
        readonly isOptional: Boolean['output']
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output']
        readonly dynamicFieldTag: String['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
        readonly imageType: FormTemplateAnnotationImageType | null
        readonly signatureType: SignatureAnnotationType | null
      }>
    }>
    readonly variants: ReadonlyArray<{
      readonly __typename: 'FormTemplateVariant'
      readonly id: ID['output']
      readonly internalName: String['output']
      readonly isDefaultVariant: Boolean['output']
    }>
    readonly comments: ReadonlyArray<{
      readonly __typename: 'FormTemplateComment'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly message: String['output']
      readonly isSystemGenerated: Boolean['output']
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
      }
    }>
    readonly originalFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
    } | null
    readonly duplicateTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly userVisibleName: String['output']
    } | null
  }
}

export type UpdateFormTemplateBuilderMutationVariables = Exact<{
  input: UpdateFormTemplateUserInput
}>

export type UpdateFormTemplateBuilderMutation = {
  readonly __typename: 'Mutation'
  readonly updateFormTemplateBuilder: {
    readonly __typename: 'FormTemplate'
    readonly id: String['output']
    readonly createdAt: DateTime['output']
    readonly type: FormTemplateType
    readonly status: FormTemplateStatus
    readonly skippedValidation: Boolean['output']
    readonly userVisibleName: String['output']
    readonly isCustomerReady: Boolean['output']
    readonly tags: ReadonlyArray<FormTemplateTag>
    readonly dueDate: DateTime['output'] | null
    readonly statusLogs: ReadonlyArray<{
      readonly __typename: 'FormTemplateStatusLog'
      readonly id: ID['output']
      readonly statusUpdatedAt: DateTime['output']
      readonly status: FormTemplateStatus
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
      }
    }>
    readonly associatedCompany: { readonly __typename: 'Company'; readonly id: ID['output'] } | null
    readonly requestingCompany: { readonly __typename: 'Company'; readonly id: ID['output'] } | null
    readonly associatedContracts: ReadonlyArray<{
      readonly __typename: 'FormTemplateAssociatedContract'
      readonly id: String['output']
      readonly providedAsFormType: ProjectOnboardingFormType | null
      readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
    }>
    readonly owner: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
    readonly builder: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
    readonly validator: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    } | null
    readonly versions: ReadonlyArray<{
      readonly __typename: 'FormTemplateVersion'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly updatedAt: DateTime['output']
      readonly versionNumber: Int['output']
      readonly isReadyForManualStoredMaterials: Boolean['output']
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
      }
      readonly annotations: ReadonlyArray<{
        readonly __typename: 'FormTemplateAnnotation'
        readonly id: ID['output']
        readonly permanentId: ID['output']
        readonly syncTag: String['output'] | null
        readonly userVisibleName: String['output']
        readonly type: FormTemplateAnnotationType
        readonly xStart: Float['output']
        readonly yStart: Float['output']
        readonly width: Float['output']
        readonly height: Float['output']
        readonly pageNumber: Int['output']
        readonly fontFamily: FormTemplateFont
        readonly fontColor: String['output']
        readonly textAlignment: TextAlignment
        readonly wrapText: Boolean['output']
        readonly isOptional: Boolean['output']
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output']
        readonly dynamicFieldTag: String['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
        readonly imageType: FormTemplateAnnotationImageType | null
        readonly signatureType: SignatureAnnotationType | null
      }>
    }>
    readonly variants: ReadonlyArray<{
      readonly __typename: 'FormTemplateVariant'
      readonly id: ID['output']
      readonly internalName: String['output']
      readonly isDefaultVariant: Boolean['output']
    }>
    readonly comments: ReadonlyArray<{
      readonly __typename: 'FormTemplateComment'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly message: String['output']
      readonly isSystemGenerated: Boolean['output']
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
      }
    }>
    readonly originalFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
    } | null
    readonly duplicateTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly userVisibleName: String['output']
    } | null
  }
}

export type UpdateFormTemplateValidatorMutationVariables = Exact<{
  input: UpdateFormTemplateUserInput
}>

export type UpdateFormTemplateValidatorMutation = {
  readonly __typename: 'Mutation'
  readonly updateFormTemplateValidator: {
    readonly __typename: 'FormTemplate'
    readonly id: String['output']
    readonly createdAt: DateTime['output']
    readonly type: FormTemplateType
    readonly status: FormTemplateStatus
    readonly skippedValidation: Boolean['output']
    readonly userVisibleName: String['output']
    readonly isCustomerReady: Boolean['output']
    readonly tags: ReadonlyArray<FormTemplateTag>
    readonly dueDate: DateTime['output'] | null
    readonly statusLogs: ReadonlyArray<{
      readonly __typename: 'FormTemplateStatusLog'
      readonly id: ID['output']
      readonly statusUpdatedAt: DateTime['output']
      readonly status: FormTemplateStatus
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
      }
    }>
    readonly associatedCompany: { readonly __typename: 'Company'; readonly id: ID['output'] } | null
    readonly requestingCompany: { readonly __typename: 'Company'; readonly id: ID['output'] } | null
    readonly associatedContracts: ReadonlyArray<{
      readonly __typename: 'FormTemplateAssociatedContract'
      readonly id: String['output']
      readonly providedAsFormType: ProjectOnboardingFormType | null
      readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
    }>
    readonly owner: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
    readonly builder: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
    readonly validator: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    } | null
    readonly versions: ReadonlyArray<{
      readonly __typename: 'FormTemplateVersion'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly updatedAt: DateTime['output']
      readonly versionNumber: Int['output']
      readonly isReadyForManualStoredMaterials: Boolean['output']
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
      }
      readonly annotations: ReadonlyArray<{
        readonly __typename: 'FormTemplateAnnotation'
        readonly id: ID['output']
        readonly permanentId: ID['output']
        readonly syncTag: String['output'] | null
        readonly userVisibleName: String['output']
        readonly type: FormTemplateAnnotationType
        readonly xStart: Float['output']
        readonly yStart: Float['output']
        readonly width: Float['output']
        readonly height: Float['output']
        readonly pageNumber: Int['output']
        readonly fontFamily: FormTemplateFont
        readonly fontColor: String['output']
        readonly textAlignment: TextAlignment
        readonly wrapText: Boolean['output']
        readonly isOptional: Boolean['output']
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output']
        readonly dynamicFieldTag: String['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
        readonly imageType: FormTemplateAnnotationImageType | null
        readonly signatureType: SignatureAnnotationType | null
      }>
    }>
    readonly variants: ReadonlyArray<{
      readonly __typename: 'FormTemplateVariant'
      readonly id: ID['output']
      readonly internalName: String['output']
      readonly isDefaultVariant: Boolean['output']
    }>
    readonly comments: ReadonlyArray<{
      readonly __typename: 'FormTemplateComment'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly message: String['output']
      readonly isSystemGenerated: Boolean['output']
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
      }
    }>
    readonly originalFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
    } | null
    readonly duplicateTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly userVisibleName: String['output']
    } | null
  }
}

export type UpdateFormTemplateOriginalFileMutationVariables = Exact<{
  input: UpdateFormTemplateOriginalFileInput
}>

export type UpdateFormTemplateOriginalFileMutation = {
  readonly __typename: 'Mutation'
  readonly updateFormTemplateOriginalFile: {
    readonly __typename: 'FormTemplate'
    readonly id: String['output']
    readonly createdAt: DateTime['output']
    readonly type: FormTemplateType
    readonly status: FormTemplateStatus
    readonly skippedValidation: Boolean['output']
    readonly userVisibleName: String['output']
    readonly isCustomerReady: Boolean['output']
    readonly tags: ReadonlyArray<FormTemplateTag>
    readonly dueDate: DateTime['output'] | null
    readonly statusLogs: ReadonlyArray<{
      readonly __typename: 'FormTemplateStatusLog'
      readonly id: ID['output']
      readonly statusUpdatedAt: DateTime['output']
      readonly status: FormTemplateStatus
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
      }
    }>
    readonly associatedCompany: { readonly __typename: 'Company'; readonly id: ID['output'] } | null
    readonly requestingCompany: { readonly __typename: 'Company'; readonly id: ID['output'] } | null
    readonly associatedContracts: ReadonlyArray<{
      readonly __typename: 'FormTemplateAssociatedContract'
      readonly id: String['output']
      readonly providedAsFormType: ProjectOnboardingFormType | null
      readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
    }>
    readonly owner: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
    readonly builder: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
    readonly validator: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    } | null
    readonly versions: ReadonlyArray<{
      readonly __typename: 'FormTemplateVersion'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly updatedAt: DateTime['output']
      readonly versionNumber: Int['output']
      readonly isReadyForManualStoredMaterials: Boolean['output']
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
      }
      readonly annotations: ReadonlyArray<{
        readonly __typename: 'FormTemplateAnnotation'
        readonly id: ID['output']
        readonly permanentId: ID['output']
        readonly syncTag: String['output'] | null
        readonly userVisibleName: String['output']
        readonly type: FormTemplateAnnotationType
        readonly xStart: Float['output']
        readonly yStart: Float['output']
        readonly width: Float['output']
        readonly height: Float['output']
        readonly pageNumber: Int['output']
        readonly fontFamily: FormTemplateFont
        readonly fontColor: String['output']
        readonly textAlignment: TextAlignment
        readonly wrapText: Boolean['output']
        readonly isOptional: Boolean['output']
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output']
        readonly dynamicFieldTag: String['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
        readonly imageType: FormTemplateAnnotationImageType | null
        readonly signatureType: SignatureAnnotationType | null
      }>
    }>
    readonly variants: ReadonlyArray<{
      readonly __typename: 'FormTemplateVariant'
      readonly id: ID['output']
      readonly internalName: String['output']
      readonly isDefaultVariant: Boolean['output']
    }>
    readonly comments: ReadonlyArray<{
      readonly __typename: 'FormTemplateComment'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly message: String['output']
      readonly isSystemGenerated: Boolean['output']
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
      }
    }>
    readonly originalFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
    } | null
    readonly duplicateTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly userVisibleName: String['output']
    } | null
  }
}

export type UpdateFormTemplateDueDateMutationVariables = Exact<{
  input: UpdateFormTemplateDueDateInput
}>

export type UpdateFormTemplateDueDateMutation = {
  readonly __typename: 'Mutation'
  readonly updateFormTemplateDueDate: {
    readonly __typename: 'FormTemplate'
    readonly id: String['output']
    readonly createdAt: DateTime['output']
    readonly type: FormTemplateType
    readonly status: FormTemplateStatus
    readonly skippedValidation: Boolean['output']
    readonly userVisibleName: String['output']
    readonly isCustomerReady: Boolean['output']
    readonly tags: ReadonlyArray<FormTemplateTag>
    readonly dueDate: DateTime['output'] | null
    readonly statusLogs: ReadonlyArray<{
      readonly __typename: 'FormTemplateStatusLog'
      readonly id: ID['output']
      readonly statusUpdatedAt: DateTime['output']
      readonly status: FormTemplateStatus
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
      }
    }>
    readonly associatedCompany: { readonly __typename: 'Company'; readonly id: ID['output'] } | null
    readonly requestingCompany: { readonly __typename: 'Company'; readonly id: ID['output'] } | null
    readonly associatedContracts: ReadonlyArray<{
      readonly __typename: 'FormTemplateAssociatedContract'
      readonly id: String['output']
      readonly providedAsFormType: ProjectOnboardingFormType | null
      readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
    }>
    readonly owner: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
    readonly builder: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
    readonly validator: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    } | null
    readonly versions: ReadonlyArray<{
      readonly __typename: 'FormTemplateVersion'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly updatedAt: DateTime['output']
      readonly versionNumber: Int['output']
      readonly isReadyForManualStoredMaterials: Boolean['output']
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
      }
      readonly annotations: ReadonlyArray<{
        readonly __typename: 'FormTemplateAnnotation'
        readonly id: ID['output']
        readonly permanentId: ID['output']
        readonly syncTag: String['output'] | null
        readonly userVisibleName: String['output']
        readonly type: FormTemplateAnnotationType
        readonly xStart: Float['output']
        readonly yStart: Float['output']
        readonly width: Float['output']
        readonly height: Float['output']
        readonly pageNumber: Int['output']
        readonly fontFamily: FormTemplateFont
        readonly fontColor: String['output']
        readonly textAlignment: TextAlignment
        readonly wrapText: Boolean['output']
        readonly isOptional: Boolean['output']
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output']
        readonly dynamicFieldTag: String['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
        readonly imageType: FormTemplateAnnotationImageType | null
        readonly signatureType: SignatureAnnotationType | null
      }>
    }>
    readonly variants: ReadonlyArray<{
      readonly __typename: 'FormTemplateVariant'
      readonly id: ID['output']
      readonly internalName: String['output']
      readonly isDefaultVariant: Boolean['output']
    }>
    readonly comments: ReadonlyArray<{
      readonly __typename: 'FormTemplateComment'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly message: String['output']
      readonly isSystemGenerated: Boolean['output']
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
      }
    }>
    readonly originalFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
    } | null
    readonly duplicateTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly userVisibleName: String['output']
    } | null
  }
}

export type MarkFormTemplateAsDuplicateMutationVariables = Exact<{
  input: MarkFormTemplateAsDuplicateInput
}>

export type MarkFormTemplateAsDuplicateMutation = {
  readonly __typename: 'Mutation'
  readonly markFormTemplateAsDuplicate: {
    readonly __typename: 'FormTemplate'
    readonly id: String['output']
    readonly createdAt: DateTime['output']
    readonly type: FormTemplateType
    readonly status: FormTemplateStatus
    readonly skippedValidation: Boolean['output']
    readonly userVisibleName: String['output']
    readonly isCustomerReady: Boolean['output']
    readonly tags: ReadonlyArray<FormTemplateTag>
    readonly dueDate: DateTime['output'] | null
    readonly statusLogs: ReadonlyArray<{
      readonly __typename: 'FormTemplateStatusLog'
      readonly id: ID['output']
      readonly statusUpdatedAt: DateTime['output']
      readonly status: FormTemplateStatus
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
      }
    }>
    readonly associatedCompany: { readonly __typename: 'Company'; readonly id: ID['output'] } | null
    readonly requestingCompany: { readonly __typename: 'Company'; readonly id: ID['output'] } | null
    readonly associatedContracts: ReadonlyArray<{
      readonly __typename: 'FormTemplateAssociatedContract'
      readonly id: String['output']
      readonly providedAsFormType: ProjectOnboardingFormType | null
      readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
    }>
    readonly owner: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
    readonly builder: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
    readonly validator: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    } | null
    readonly versions: ReadonlyArray<{
      readonly __typename: 'FormTemplateVersion'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly updatedAt: DateTime['output']
      readonly versionNumber: Int['output']
      readonly isReadyForManualStoredMaterials: Boolean['output']
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
      }
      readonly annotations: ReadonlyArray<{
        readonly __typename: 'FormTemplateAnnotation'
        readonly id: ID['output']
        readonly permanentId: ID['output']
        readonly syncTag: String['output'] | null
        readonly userVisibleName: String['output']
        readonly type: FormTemplateAnnotationType
        readonly xStart: Float['output']
        readonly yStart: Float['output']
        readonly width: Float['output']
        readonly height: Float['output']
        readonly pageNumber: Int['output']
        readonly fontFamily: FormTemplateFont
        readonly fontColor: String['output']
        readonly textAlignment: TextAlignment
        readonly wrapText: Boolean['output']
        readonly isOptional: Boolean['output']
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output']
        readonly dynamicFieldTag: String['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
        readonly imageType: FormTemplateAnnotationImageType | null
        readonly signatureType: SignatureAnnotationType | null
      }>
    }>
    readonly variants: ReadonlyArray<{
      readonly __typename: 'FormTemplateVariant'
      readonly id: ID['output']
      readonly internalName: String['output']
      readonly isDefaultVariant: Boolean['output']
    }>
    readonly comments: ReadonlyArray<{
      readonly __typename: 'FormTemplateComment'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly message: String['output']
      readonly isSystemGenerated: Boolean['output']
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
      }
    }>
    readonly originalFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
    } | null
    readonly duplicateTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly userVisibleName: String['output']
    } | null
  }
}

export type AddFormTemplateCommentMutationVariables = Exact<{
  input: AddFormTemplateCommentInput
}>

export type AddFormTemplateCommentMutation = {
  readonly __typename: 'Mutation'
  readonly addFormTemplateComment: {
    readonly __typename: 'FormTemplateComment'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly message: String['output']
    readonly isSystemGenerated: Boolean['output']
    readonly user: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
      readonly email: EmailAddress['output']
    }
  }
}

export type DeleteFormTemplateCommentMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteFormTemplateCommentMutation = {
  readonly __typename: 'Mutation'
  readonly deleteFormTemplateComment: {
    readonly __typename: 'DeletionResult'
    readonly id: ID['output']
  }
}

export type GetFormTemplateQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type GetFormTemplateQuery = {
  readonly __typename: 'Query'
  readonly formTemplate: {
    readonly __typename: 'FormTemplate'
    readonly id: String['output']
    readonly createdAt: DateTime['output']
    readonly type: FormTemplateType
    readonly status: FormTemplateStatus
    readonly skippedValidation: Boolean['output']
    readonly userVisibleName: String['output']
    readonly isCustomerReady: Boolean['output']
    readonly tags: ReadonlyArray<FormTemplateTag>
    readonly dueDate: DateTime['output'] | null
    readonly requestingCompany: {
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
      readonly picture: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly notaryStamp: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly notarySignature: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly secondarySignature: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
    } | null
    readonly statusLogs: ReadonlyArray<{
      readonly __typename: 'FormTemplateStatusLog'
      readonly id: ID['output']
      readonly statusUpdatedAt: DateTime['output']
      readonly status: FormTemplateStatus
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
      }
    }>
    readonly associatedCompany: { readonly __typename: 'Company'; readonly id: ID['output'] } | null
    readonly associatedContracts: ReadonlyArray<{
      readonly __typename: 'FormTemplateAssociatedContract'
      readonly id: String['output']
      readonly providedAsFormType: ProjectOnboardingFormType | null
      readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
    }>
    readonly owner: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
    readonly builder: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
    readonly validator: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    } | null
    readonly versions: ReadonlyArray<{
      readonly __typename: 'FormTemplateVersion'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly updatedAt: DateTime['output']
      readonly versionNumber: Int['output']
      readonly isReadyForManualStoredMaterials: Boolean['output']
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
      }
      readonly annotations: ReadonlyArray<{
        readonly __typename: 'FormTemplateAnnotation'
        readonly id: ID['output']
        readonly permanentId: ID['output']
        readonly syncTag: String['output'] | null
        readonly userVisibleName: String['output']
        readonly type: FormTemplateAnnotationType
        readonly xStart: Float['output']
        readonly yStart: Float['output']
        readonly width: Float['output']
        readonly height: Float['output']
        readonly pageNumber: Int['output']
        readonly fontFamily: FormTemplateFont
        readonly fontColor: String['output']
        readonly textAlignment: TextAlignment
        readonly wrapText: Boolean['output']
        readonly isOptional: Boolean['output']
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output']
        readonly dynamicFieldTag: String['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
        readonly imageType: FormTemplateAnnotationImageType | null
        readonly signatureType: SignatureAnnotationType | null
      }>
    }>
    readonly variants: ReadonlyArray<{
      readonly __typename: 'FormTemplateVariant'
      readonly id: ID['output']
      readonly internalName: String['output']
      readonly isDefaultVariant: Boolean['output']
    }>
    readonly comments: ReadonlyArray<{
      readonly __typename: 'FormTemplateComment'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly message: String['output']
      readonly isSystemGenerated: Boolean['output']
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
      }
    }>
    readonly originalFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
    } | null
    readonly duplicateTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly userVisibleName: String['output']
    } | null
  }
}

export type FormTemplateForDetailsQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type FormTemplateForDetailsQuery = {
  readonly __typename: 'Query'
  readonly formTemplate: {
    readonly __typename: 'FormTemplate'
    readonly id: String['output']
    readonly createdAt: DateTime['output']
    readonly type: FormTemplateType
    readonly status: FormTemplateStatus
    readonly skippedValidation: Boolean['output']
    readonly userVisibleName: String['output']
    readonly isCustomerReady: Boolean['output']
    readonly tags: ReadonlyArray<FormTemplateTag>
    readonly dueDate: DateTime['output'] | null
    readonly statusLogs: ReadonlyArray<{
      readonly __typename: 'FormTemplateStatusLog'
      readonly id: ID['output']
      readonly statusUpdatedAt: DateTime['output']
      readonly status: FormTemplateStatus
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
      }
    }>
    readonly associatedCompany: { readonly __typename: 'Company'; readonly id: ID['output'] } | null
    readonly requestingCompany: { readonly __typename: 'Company'; readonly id: ID['output'] } | null
    readonly associatedContracts: ReadonlyArray<{
      readonly __typename: 'FormTemplateAssociatedContract'
      readonly id: String['output']
      readonly providedAsFormType: ProjectOnboardingFormType | null
      readonly contract: {
        readonly __typename: 'Contract'
        readonly id: ID['output']
        readonly daysBeforePayAppDue: Int['output']
        readonly project: {
          readonly __typename: 'Project'
          readonly id: ID['output']
          readonly name: String['output']
          readonly timeZone: String['output']
          readonly metadata: {
            readonly __typename: 'ProjectMetadata'
            readonly payAppDueOnDayOfMonth: Int['output']
          }
        }
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        }
      }
    }>
    readonly owner: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
    readonly builder: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
    readonly validator: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    } | null
    readonly versions: ReadonlyArray<{
      readonly __typename: 'FormTemplateVersion'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly updatedAt: DateTime['output']
      readonly versionNumber: Int['output']
      readonly isReadyForManualStoredMaterials: Boolean['output']
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
      }
      readonly annotations: ReadonlyArray<{
        readonly __typename: 'FormTemplateAnnotation'
        readonly id: ID['output']
        readonly permanentId: ID['output']
        readonly syncTag: String['output'] | null
        readonly userVisibleName: String['output']
        readonly type: FormTemplateAnnotationType
        readonly xStart: Float['output']
        readonly yStart: Float['output']
        readonly width: Float['output']
        readonly height: Float['output']
        readonly pageNumber: Int['output']
        readonly fontFamily: FormTemplateFont
        readonly fontColor: String['output']
        readonly textAlignment: TextAlignment
        readonly wrapText: Boolean['output']
        readonly isOptional: Boolean['output']
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output']
        readonly dynamicFieldTag: String['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
        readonly imageType: FormTemplateAnnotationImageType | null
        readonly signatureType: SignatureAnnotationType | null
      }>
    }>
    readonly variants: ReadonlyArray<{
      readonly __typename: 'FormTemplateVariant'
      readonly id: ID['output']
      readonly internalName: String['output']
      readonly isDefaultVariant: Boolean['output']
    }>
    readonly comments: ReadonlyArray<{
      readonly __typename: 'FormTemplateComment'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly message: String['output']
      readonly isSystemGenerated: Boolean['output']
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
      }
    }>
    readonly originalFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
    } | null
    readonly duplicateTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly userVisibleName: String['output']
    } | null
  }
}

export type UpdatedFormTemplateSubscriptionVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type UpdatedFormTemplateSubscription = {
  readonly __typename: 'Subscription'
  readonly updatedFormTemplate: {
    readonly __typename: 'FormTemplate'
    readonly id: String['output']
    readonly createdAt: DateTime['output']
    readonly type: FormTemplateType
    readonly status: FormTemplateStatus
    readonly skippedValidation: Boolean['output']
    readonly userVisibleName: String['output']
    readonly isCustomerReady: Boolean['output']
    readonly tags: ReadonlyArray<FormTemplateTag>
    readonly dueDate: DateTime['output'] | null
    readonly statusLogs: ReadonlyArray<{
      readonly __typename: 'FormTemplateStatusLog'
      readonly id: ID['output']
      readonly statusUpdatedAt: DateTime['output']
      readonly status: FormTemplateStatus
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
      }
    }>
    readonly associatedCompany: { readonly __typename: 'Company'; readonly id: ID['output'] } | null
    readonly requestingCompany: { readonly __typename: 'Company'; readonly id: ID['output'] } | null
    readonly associatedContracts: ReadonlyArray<{
      readonly __typename: 'FormTemplateAssociatedContract'
      readonly id: String['output']
      readonly providedAsFormType: ProjectOnboardingFormType | null
      readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
    }>
    readonly owner: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
    readonly builder: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
    readonly validator: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    } | null
    readonly versions: ReadonlyArray<{
      readonly __typename: 'FormTemplateVersion'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly updatedAt: DateTime['output']
      readonly versionNumber: Int['output']
      readonly isReadyForManualStoredMaterials: Boolean['output']
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
      }
      readonly annotations: ReadonlyArray<{
        readonly __typename: 'FormTemplateAnnotation'
        readonly id: ID['output']
        readonly permanentId: ID['output']
        readonly syncTag: String['output'] | null
        readonly userVisibleName: String['output']
        readonly type: FormTemplateAnnotationType
        readonly xStart: Float['output']
        readonly yStart: Float['output']
        readonly width: Float['output']
        readonly height: Float['output']
        readonly pageNumber: Int['output']
        readonly fontFamily: FormTemplateFont
        readonly fontColor: String['output']
        readonly textAlignment: TextAlignment
        readonly wrapText: Boolean['output']
        readonly isOptional: Boolean['output']
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output']
        readonly dynamicFieldTag: String['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
        readonly imageType: FormTemplateAnnotationImageType | null
        readonly signatureType: SignatureAnnotationType | null
      }>
    }>
    readonly variants: ReadonlyArray<{
      readonly __typename: 'FormTemplateVariant'
      readonly id: ID['output']
      readonly internalName: String['output']
      readonly isDefaultVariant: Boolean['output']
    }>
    readonly comments: ReadonlyArray<{
      readonly __typename: 'FormTemplateComment'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly message: String['output']
      readonly isSystemGenerated: Boolean['output']
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
      }
    }>
    readonly originalFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
    } | null
    readonly duplicateTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly userVisibleName: String['output']
    } | null
  }
}

export type ContractsWithTemplateQueryVariables = Exact<{
  input: GetPaginatedContractsInput
}>

export type ContractsWithTemplateQuery = {
  readonly __typename: 'Query'
  readonly paginatedContracts: {
    readonly __typename: 'PaginatedContractList'
    readonly hasNext: Boolean['output']
    readonly totalCount: Int['output']
    readonly cursor: ID['output'] | null
    readonly contracts: ReadonlyArray<{
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly name: String['output']
      }
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      }
    }>
  }
}

export type ContractsForAutocompleteQueryVariables = Exact<{
  input: GetPaginatedContractsInput
}>

export type ContractsForAutocompleteQuery = {
  readonly __typename: 'Query'
  readonly paginatedContracts: {
    readonly __typename: 'PaginatedContractList'
    readonly contracts: ReadonlyArray<{
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly daysBeforePayAppDue: Int['output']
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      }
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly name: String['output']
        readonly timeZone: String['output']
        readonly metadata: {
          readonly __typename: 'ProjectMetadata'
          readonly payAppDueOnDayOfMonth: Int['output']
        }
      }
    }>
  }
}

export type UpdateFormTemplateMutationVariables = Exact<{
  input: UpdateFormTemplateInput
}>

export type UpdateFormTemplateMutation = {
  readonly __typename: 'Mutation'
  readonly updateFormTemplate: {
    readonly __typename: 'FormTemplate'
    readonly id: String['output']
    readonly createdAt: DateTime['output']
    readonly type: FormTemplateType
    readonly status: FormTemplateStatus
    readonly skippedValidation: Boolean['output']
    readonly userVisibleName: String['output']
    readonly isCustomerReady: Boolean['output']
    readonly tags: ReadonlyArray<FormTemplateTag>
    readonly dueDate: DateTime['output'] | null
    readonly statusLogs: ReadonlyArray<{
      readonly __typename: 'FormTemplateStatusLog'
      readonly id: ID['output']
      readonly statusUpdatedAt: DateTime['output']
      readonly status: FormTemplateStatus
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
      }
    }>
    readonly associatedCompany: { readonly __typename: 'Company'; readonly id: ID['output'] } | null
    readonly requestingCompany: { readonly __typename: 'Company'; readonly id: ID['output'] } | null
    readonly associatedContracts: ReadonlyArray<{
      readonly __typename: 'FormTemplateAssociatedContract'
      readonly id: String['output']
      readonly providedAsFormType: ProjectOnboardingFormType | null
      readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
    }>
    readonly owner: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
    readonly builder: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
    readonly validator: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    } | null
    readonly versions: ReadonlyArray<{
      readonly __typename: 'FormTemplateVersion'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly updatedAt: DateTime['output']
      readonly versionNumber: Int['output']
      readonly isReadyForManualStoredMaterials: Boolean['output']
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
      }
      readonly annotations: ReadonlyArray<{
        readonly __typename: 'FormTemplateAnnotation'
        readonly id: ID['output']
        readonly permanentId: ID['output']
        readonly syncTag: String['output'] | null
        readonly userVisibleName: String['output']
        readonly type: FormTemplateAnnotationType
        readonly xStart: Float['output']
        readonly yStart: Float['output']
        readonly width: Float['output']
        readonly height: Float['output']
        readonly pageNumber: Int['output']
        readonly fontFamily: FormTemplateFont
        readonly fontColor: String['output']
        readonly textAlignment: TextAlignment
        readonly wrapText: Boolean['output']
        readonly isOptional: Boolean['output']
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output']
        readonly dynamicFieldTag: String['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
        readonly imageType: FormTemplateAnnotationImageType | null
        readonly signatureType: SignatureAnnotationType | null
      }>
    }>
    readonly variants: ReadonlyArray<{
      readonly __typename: 'FormTemplateVariant'
      readonly id: ID['output']
      readonly internalName: String['output']
      readonly isDefaultVariant: Boolean['output']
    }>
    readonly comments: ReadonlyArray<{
      readonly __typename: 'FormTemplateComment'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly message: String['output']
      readonly isSystemGenerated: Boolean['output']
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
      }
    }>
    readonly originalFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
    } | null
    readonly duplicateTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly userVisibleName: String['output']
    } | null
  }
}

export type UpdateFormTemplateAssociatedCompanyMutationVariables = Exact<{
  input: UpdateFormTemplateAssociatedCompanyInput
}>

export type UpdateFormTemplateAssociatedCompanyMutation = {
  readonly __typename: 'Mutation'
  readonly updateFormTemplateAssociatedCompany: {
    readonly __typename: 'FormTemplate'
    readonly id: String['output']
    readonly createdAt: DateTime['output']
    readonly type: FormTemplateType
    readonly status: FormTemplateStatus
    readonly skippedValidation: Boolean['output']
    readonly userVisibleName: String['output']
    readonly isCustomerReady: Boolean['output']
    readonly tags: ReadonlyArray<FormTemplateTag>
    readonly dueDate: DateTime['output'] | null
    readonly statusLogs: ReadonlyArray<{
      readonly __typename: 'FormTemplateStatusLog'
      readonly id: ID['output']
      readonly statusUpdatedAt: DateTime['output']
      readonly status: FormTemplateStatus
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
      }
    }>
    readonly associatedCompany: { readonly __typename: 'Company'; readonly id: ID['output'] } | null
    readonly requestingCompany: { readonly __typename: 'Company'; readonly id: ID['output'] } | null
    readonly associatedContracts: ReadonlyArray<{
      readonly __typename: 'FormTemplateAssociatedContract'
      readonly id: String['output']
      readonly providedAsFormType: ProjectOnboardingFormType | null
      readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
    }>
    readonly owner: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
    readonly builder: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
    readonly validator: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    } | null
    readonly versions: ReadonlyArray<{
      readonly __typename: 'FormTemplateVersion'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly updatedAt: DateTime['output']
      readonly versionNumber: Int['output']
      readonly isReadyForManualStoredMaterials: Boolean['output']
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
      }
      readonly annotations: ReadonlyArray<{
        readonly __typename: 'FormTemplateAnnotation'
        readonly id: ID['output']
        readonly permanentId: ID['output']
        readonly syncTag: String['output'] | null
        readonly userVisibleName: String['output']
        readonly type: FormTemplateAnnotationType
        readonly xStart: Float['output']
        readonly yStart: Float['output']
        readonly width: Float['output']
        readonly height: Float['output']
        readonly pageNumber: Int['output']
        readonly fontFamily: FormTemplateFont
        readonly fontColor: String['output']
        readonly textAlignment: TextAlignment
        readonly wrapText: Boolean['output']
        readonly isOptional: Boolean['output']
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output']
        readonly dynamicFieldTag: String['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
        readonly imageType: FormTemplateAnnotationImageType | null
        readonly signatureType: SignatureAnnotationType | null
      }>
    }>
    readonly variants: ReadonlyArray<{
      readonly __typename: 'FormTemplateVariant'
      readonly id: ID['output']
      readonly internalName: String['output']
      readonly isDefaultVariant: Boolean['output']
    }>
    readonly comments: ReadonlyArray<{
      readonly __typename: 'FormTemplateComment'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly message: String['output']
      readonly isSystemGenerated: Boolean['output']
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
      }
    }>
    readonly originalFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
    } | null
    readonly duplicateTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly userVisibleName: String['output']
    } | null
  }
}

export type UpdateFormTemplateRequestingCompanyMutationVariables = Exact<{
  input: UpdateFormTemplateRequestingCompanyInput
}>

export type UpdateFormTemplateRequestingCompanyMutation = {
  readonly __typename: 'Mutation'
  readonly updateFormTemplateRequestingCompany: {
    readonly __typename: 'FormTemplate'
    readonly id: String['output']
    readonly createdAt: DateTime['output']
    readonly type: FormTemplateType
    readonly status: FormTemplateStatus
    readonly skippedValidation: Boolean['output']
    readonly userVisibleName: String['output']
    readonly isCustomerReady: Boolean['output']
    readonly tags: ReadonlyArray<FormTemplateTag>
    readonly dueDate: DateTime['output'] | null
    readonly statusLogs: ReadonlyArray<{
      readonly __typename: 'FormTemplateStatusLog'
      readonly id: ID['output']
      readonly statusUpdatedAt: DateTime['output']
      readonly status: FormTemplateStatus
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
      }
    }>
    readonly associatedCompany: { readonly __typename: 'Company'; readonly id: ID['output'] } | null
    readonly requestingCompany: { readonly __typename: 'Company'; readonly id: ID['output'] } | null
    readonly associatedContracts: ReadonlyArray<{
      readonly __typename: 'FormTemplateAssociatedContract'
      readonly id: String['output']
      readonly providedAsFormType: ProjectOnboardingFormType | null
      readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
    }>
    readonly owner: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
    readonly builder: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
    readonly validator: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    } | null
    readonly versions: ReadonlyArray<{
      readonly __typename: 'FormTemplateVersion'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly updatedAt: DateTime['output']
      readonly versionNumber: Int['output']
      readonly isReadyForManualStoredMaterials: Boolean['output']
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
      }
      readonly annotations: ReadonlyArray<{
        readonly __typename: 'FormTemplateAnnotation'
        readonly id: ID['output']
        readonly permanentId: ID['output']
        readonly syncTag: String['output'] | null
        readonly userVisibleName: String['output']
        readonly type: FormTemplateAnnotationType
        readonly xStart: Float['output']
        readonly yStart: Float['output']
        readonly width: Float['output']
        readonly height: Float['output']
        readonly pageNumber: Int['output']
        readonly fontFamily: FormTemplateFont
        readonly fontColor: String['output']
        readonly textAlignment: TextAlignment
        readonly wrapText: Boolean['output']
        readonly isOptional: Boolean['output']
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output']
        readonly dynamicFieldTag: String['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
        readonly imageType: FormTemplateAnnotationImageType | null
        readonly signatureType: SignatureAnnotationType | null
      }>
    }>
    readonly variants: ReadonlyArray<{
      readonly __typename: 'FormTemplateVariant'
      readonly id: ID['output']
      readonly internalName: String['output']
      readonly isDefaultVariant: Boolean['output']
    }>
    readonly comments: ReadonlyArray<{
      readonly __typename: 'FormTemplateComment'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly message: String['output']
      readonly isSystemGenerated: Boolean['output']
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
      }
    }>
    readonly originalFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
    } | null
    readonly duplicateTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly userVisibleName: String['output']
    } | null
  }
}

export type UpdateFormTemplateAssociatedContractsMutationVariables = Exact<{
  input: UpdateFormTemplateAssociatedContractsInput
}>

export type UpdateFormTemplateAssociatedContractsMutation = {
  readonly __typename: 'Mutation'
  readonly updateFormTemplateAssociatedContracts: {
    readonly __typename: 'FormTemplate'
    readonly id: String['output']
    readonly createdAt: DateTime['output']
    readonly type: FormTemplateType
    readonly status: FormTemplateStatus
    readonly skippedValidation: Boolean['output']
    readonly userVisibleName: String['output']
    readonly isCustomerReady: Boolean['output']
    readonly tags: ReadonlyArray<FormTemplateTag>
    readonly dueDate: DateTime['output'] | null
    readonly statusLogs: ReadonlyArray<{
      readonly __typename: 'FormTemplateStatusLog'
      readonly id: ID['output']
      readonly statusUpdatedAt: DateTime['output']
      readonly status: FormTemplateStatus
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
      }
    }>
    readonly associatedCompany: { readonly __typename: 'Company'; readonly id: ID['output'] } | null
    readonly requestingCompany: { readonly __typename: 'Company'; readonly id: ID['output'] } | null
    readonly associatedContracts: ReadonlyArray<{
      readonly __typename: 'FormTemplateAssociatedContract'
      readonly id: String['output']
      readonly providedAsFormType: ProjectOnboardingFormType | null
      readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
    }>
    readonly owner: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
    readonly builder: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
    readonly validator: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    } | null
    readonly versions: ReadonlyArray<{
      readonly __typename: 'FormTemplateVersion'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly updatedAt: DateTime['output']
      readonly versionNumber: Int['output']
      readonly isReadyForManualStoredMaterials: Boolean['output']
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
      }
      readonly annotations: ReadonlyArray<{
        readonly __typename: 'FormTemplateAnnotation'
        readonly id: ID['output']
        readonly permanentId: ID['output']
        readonly syncTag: String['output'] | null
        readonly userVisibleName: String['output']
        readonly type: FormTemplateAnnotationType
        readonly xStart: Float['output']
        readonly yStart: Float['output']
        readonly width: Float['output']
        readonly height: Float['output']
        readonly pageNumber: Int['output']
        readonly fontFamily: FormTemplateFont
        readonly fontColor: String['output']
        readonly textAlignment: TextAlignment
        readonly wrapText: Boolean['output']
        readonly isOptional: Boolean['output']
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output']
        readonly dynamicFieldTag: String['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
        readonly imageType: FormTemplateAnnotationImageType | null
        readonly signatureType: SignatureAnnotationType | null
      }>
    }>
    readonly variants: ReadonlyArray<{
      readonly __typename: 'FormTemplateVariant'
      readonly id: ID['output']
      readonly internalName: String['output']
      readonly isDefaultVariant: Boolean['output']
    }>
    readonly comments: ReadonlyArray<{
      readonly __typename: 'FormTemplateComment'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly message: String['output']
      readonly isSystemGenerated: Boolean['output']
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
      }
    }>
    readonly originalFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
    } | null
    readonly duplicateTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly userVisibleName: String['output']
    } | null
  }
}

export type DeleteFormTemplateMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteFormTemplateMutation = {
  readonly __typename: 'Mutation'
  readonly deleteFormTemplate: { readonly __typename: 'DeletionResult'; readonly id: ID['output'] }
}

export type CloneFormTemplateMutationVariables = Exact<{
  input: CloneFormTemplateInput
}>

export type CloneFormTemplateMutation = {
  readonly __typename: 'Mutation'
  readonly cloneFormTemplate: {
    readonly __typename: 'FormTemplate'
    readonly id: String['output']
    readonly createdAt: DateTime['output']
    readonly type: FormTemplateType
    readonly status: FormTemplateStatus
    readonly skippedValidation: Boolean['output']
    readonly userVisibleName: String['output']
    readonly isCustomerReady: Boolean['output']
    readonly tags: ReadonlyArray<FormTemplateTag>
    readonly dueDate: DateTime['output'] | null
    readonly statusLogs: ReadonlyArray<{
      readonly __typename: 'FormTemplateStatusLog'
      readonly id: ID['output']
      readonly statusUpdatedAt: DateTime['output']
      readonly status: FormTemplateStatus
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
      }
    }>
    readonly associatedCompany: { readonly __typename: 'Company'; readonly id: ID['output'] } | null
    readonly requestingCompany: { readonly __typename: 'Company'; readonly id: ID['output'] } | null
    readonly associatedContracts: ReadonlyArray<{
      readonly __typename: 'FormTemplateAssociatedContract'
      readonly id: String['output']
      readonly providedAsFormType: ProjectOnboardingFormType | null
      readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
    }>
    readonly owner: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
    readonly builder: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
    readonly validator: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    } | null
    readonly versions: ReadonlyArray<{
      readonly __typename: 'FormTemplateVersion'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly updatedAt: DateTime['output']
      readonly versionNumber: Int['output']
      readonly isReadyForManualStoredMaterials: Boolean['output']
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
      }
      readonly annotations: ReadonlyArray<{
        readonly __typename: 'FormTemplateAnnotation'
        readonly id: ID['output']
        readonly permanentId: ID['output']
        readonly syncTag: String['output'] | null
        readonly userVisibleName: String['output']
        readonly type: FormTemplateAnnotationType
        readonly xStart: Float['output']
        readonly yStart: Float['output']
        readonly width: Float['output']
        readonly height: Float['output']
        readonly pageNumber: Int['output']
        readonly fontFamily: FormTemplateFont
        readonly fontColor: String['output']
        readonly textAlignment: TextAlignment
        readonly wrapText: Boolean['output']
        readonly isOptional: Boolean['output']
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output']
        readonly dynamicFieldTag: String['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
        readonly imageType: FormTemplateAnnotationImageType | null
        readonly signatureType: SignatureAnnotationType | null
      }>
    }>
    readonly variants: ReadonlyArray<{
      readonly __typename: 'FormTemplateVariant'
      readonly id: ID['output']
      readonly internalName: String['output']
      readonly isDefaultVariant: Boolean['output']
    }>
    readonly comments: ReadonlyArray<{
      readonly __typename: 'FormTemplateComment'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly message: String['output']
      readonly isSystemGenerated: Boolean['output']
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
      }
    }>
    readonly originalFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
    } | null
    readonly duplicateTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly userVisibleName: String['output']
    } | null
  }
}

export type SetFormTemplateTagsMutationVariables = Exact<{
  input: SetFormTemplateTagsInput
}>

export type SetFormTemplateTagsMutation = {
  readonly __typename: 'Mutation'
  readonly setFormTemplateTags: {
    readonly __typename: 'FormTemplate'
    readonly id: String['output']
    readonly createdAt: DateTime['output']
    readonly type: FormTemplateType
    readonly status: FormTemplateStatus
    readonly skippedValidation: Boolean['output']
    readonly userVisibleName: String['output']
    readonly isCustomerReady: Boolean['output']
    readonly tags: ReadonlyArray<FormTemplateTag>
    readonly dueDate: DateTime['output'] | null
    readonly statusLogs: ReadonlyArray<{
      readonly __typename: 'FormTemplateStatusLog'
      readonly id: ID['output']
      readonly statusUpdatedAt: DateTime['output']
      readonly status: FormTemplateStatus
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
      }
    }>
    readonly associatedCompany: { readonly __typename: 'Company'; readonly id: ID['output'] } | null
    readonly requestingCompany: { readonly __typename: 'Company'; readonly id: ID['output'] } | null
    readonly associatedContracts: ReadonlyArray<{
      readonly __typename: 'FormTemplateAssociatedContract'
      readonly id: String['output']
      readonly providedAsFormType: ProjectOnboardingFormType | null
      readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
    }>
    readonly owner: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
    readonly builder: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
    readonly validator: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    } | null
    readonly versions: ReadonlyArray<{
      readonly __typename: 'FormTemplateVersion'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly updatedAt: DateTime['output']
      readonly versionNumber: Int['output']
      readonly isReadyForManualStoredMaterials: Boolean['output']
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
      }
      readonly annotations: ReadonlyArray<{
        readonly __typename: 'FormTemplateAnnotation'
        readonly id: ID['output']
        readonly permanentId: ID['output']
        readonly syncTag: String['output'] | null
        readonly userVisibleName: String['output']
        readonly type: FormTemplateAnnotationType
        readonly xStart: Float['output']
        readonly yStart: Float['output']
        readonly width: Float['output']
        readonly height: Float['output']
        readonly pageNumber: Int['output']
        readonly fontFamily: FormTemplateFont
        readonly fontColor: String['output']
        readonly textAlignment: TextAlignment
        readonly wrapText: Boolean['output']
        readonly isOptional: Boolean['output']
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output']
        readonly dynamicFieldTag: String['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
        readonly imageType: FormTemplateAnnotationImageType | null
        readonly signatureType: SignatureAnnotationType | null
      }>
    }>
    readonly variants: ReadonlyArray<{
      readonly __typename: 'FormTemplateVariant'
      readonly id: ID['output']
      readonly internalName: String['output']
      readonly isDefaultVariant: Boolean['output']
    }>
    readonly comments: ReadonlyArray<{
      readonly __typename: 'FormTemplateComment'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly message: String['output']
      readonly isSystemGenerated: Boolean['output']
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
      }
    }>
    readonly originalFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
    } | null
    readonly duplicateTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly userVisibleName: String['output']
    } | null
  }
}

export type FormTemplateContractsForDueDateQueryVariables = Exact<{
  input: ContractsByIdInput
}>

export type FormTemplateContractsForDueDateQuery = {
  readonly __typename: 'Query'
  readonly contractsById: ReadonlyArray<{
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly skippedPayAppMonths: ReadonlyArray<String['output']>
    readonly daysBeforePayAppDue: Int['output']
    readonly timeZone: String['output']
    readonly project: {
      readonly __typename: 'Project'
      readonly id: ID['output']
      readonly name: String['output']
      readonly metadata: {
        readonly __typename: 'ProjectMetadata'
        readonly payAppDueOnDayOfMonth: Int['output']
      }
    }
    readonly payApps: ReadonlyArray<{
      readonly __typename: 'PayApp'
      readonly id: ID['output']
      readonly payAppNumber: SafeInt['output']
      readonly billingEnd: DateTime['output']
      readonly internalDueDate: DateTime['output']
      readonly status: PayAppStatus
    }>
  }>
}

export type CloneFormTemplateVersionMutationVariables = Exact<{
  formTemplateVersionId: Scalars['ID']['input']
}>

export type CloneFormTemplateVersionMutation = {
  readonly __typename: 'Mutation'
  readonly cloneFormTemplateVersion: {
    readonly __typename: 'FormTemplateVersion'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly updatedAt: DateTime['output']
    readonly versionNumber: Int['output']
    readonly isReadyForManualStoredMaterials: Boolean['output']
    readonly file: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
    }
    readonly annotations: ReadonlyArray<{
      readonly __typename: 'FormTemplateAnnotation'
      readonly id: ID['output']
      readonly permanentId: ID['output']
      readonly syncTag: String['output'] | null
      readonly userVisibleName: String['output']
      readonly type: FormTemplateAnnotationType
      readonly xStart: Float['output']
      readonly yStart: Float['output']
      readonly width: Float['output']
      readonly height: Float['output']
      readonly pageNumber: Int['output']
      readonly fontFamily: FormTemplateFont
      readonly fontColor: String['output']
      readonly textAlignment: TextAlignment
      readonly wrapText: Boolean['output']
      readonly isOptional: Boolean['output']
      readonly selectedKey: String['output'] | null
      readonly defaultValueKey: String['output'] | null
      readonly prefix: String['output'] | null
      readonly suffix: String['output'] | null
      readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
      readonly doNotRetainOnReset: Boolean['output']
      readonly dynamicFieldTag: String['output'] | null
      readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
      readonly imageType: FormTemplateAnnotationImageType | null
      readonly signatureType: SignatureAnnotationType | null
    }>
  }
}

export type SkipFormTemplateValidationMutationVariables = Exact<{
  input: SkipFormTemplateValidationInput
}>

export type SkipFormTemplateValidationMutation = {
  readonly __typename: 'Mutation'
  readonly skipFormTemplateValidation: {
    readonly __typename: 'FormTemplate'
    readonly id: String['output']
    readonly createdAt: DateTime['output']
    readonly type: FormTemplateType
    readonly status: FormTemplateStatus
    readonly skippedValidation: Boolean['output']
    readonly userVisibleName: String['output']
    readonly isCustomerReady: Boolean['output']
    readonly tags: ReadonlyArray<FormTemplateTag>
    readonly dueDate: DateTime['output'] | null
    readonly statusLogs: ReadonlyArray<{
      readonly __typename: 'FormTemplateStatusLog'
      readonly id: ID['output']
      readonly statusUpdatedAt: DateTime['output']
      readonly status: FormTemplateStatus
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
      }
    }>
    readonly associatedCompany: { readonly __typename: 'Company'; readonly id: ID['output'] } | null
    readonly requestingCompany: { readonly __typename: 'Company'; readonly id: ID['output'] } | null
    readonly associatedContracts: ReadonlyArray<{
      readonly __typename: 'FormTemplateAssociatedContract'
      readonly id: String['output']
      readonly providedAsFormType: ProjectOnboardingFormType | null
      readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
    }>
    readonly owner: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
    readonly builder: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
    readonly validator: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    } | null
    readonly versions: ReadonlyArray<{
      readonly __typename: 'FormTemplateVersion'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly updatedAt: DateTime['output']
      readonly versionNumber: Int['output']
      readonly isReadyForManualStoredMaterials: Boolean['output']
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
      }
      readonly annotations: ReadonlyArray<{
        readonly __typename: 'FormTemplateAnnotation'
        readonly id: ID['output']
        readonly permanentId: ID['output']
        readonly syncTag: String['output'] | null
        readonly userVisibleName: String['output']
        readonly type: FormTemplateAnnotationType
        readonly xStart: Float['output']
        readonly yStart: Float['output']
        readonly width: Float['output']
        readonly height: Float['output']
        readonly pageNumber: Int['output']
        readonly fontFamily: FormTemplateFont
        readonly fontColor: String['output']
        readonly textAlignment: TextAlignment
        readonly wrapText: Boolean['output']
        readonly isOptional: Boolean['output']
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output']
        readonly dynamicFieldTag: String['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
        readonly imageType: FormTemplateAnnotationImageType | null
        readonly signatureType: SignatureAnnotationType | null
      }>
    }>
    readonly variants: ReadonlyArray<{
      readonly __typename: 'FormTemplateVariant'
      readonly id: ID['output']
      readonly internalName: String['output']
      readonly isDefaultVariant: Boolean['output']
    }>
    readonly comments: ReadonlyArray<{
      readonly __typename: 'FormTemplateComment'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly message: String['output']
      readonly isSystemGenerated: Boolean['output']
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
      }
    }>
    readonly originalFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
    } | null
    readonly duplicateTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly userVisibleName: String['output']
    } | null
  }
}

export type ContractForAutocompleteQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ContractForAutocompleteQuery = {
  readonly __typename: 'Query'
  readonly contract: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly daysBeforePayAppDue: Int['output']
    readonly company: {
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
    }
    readonly project: {
      readonly __typename: 'Project'
      readonly id: ID['output']
      readonly name: String['output']
      readonly timeZone: String['output']
      readonly metadata: {
        readonly __typename: 'ProjectMetadata'
        readonly payAppDueOnDayOfMonth: Int['output']
      }
    }
  }
}

export type PaginatedTemplatesQueryVariables = Exact<{
  input: GetPaginatedTemplatesInput
}>

export type PaginatedTemplatesQuery = {
  readonly __typename: 'Query'
  readonly paginatedTemplates: {
    readonly __typename: 'PaginatedTemplateList'
    readonly hasNext: Boolean['output']
    readonly cursor: ID['output'] | null
    readonly totalCount: Int['output']
    readonly templates: ReadonlyArray<{
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly type: FormTemplateType
      readonly createdAt: DateTime['output']
      readonly userVisibleName: String['output']
      readonly status: FormTemplateStatus
      readonly skippedValidation: Boolean['output']
      readonly dueDate: DateTime['output'] | null
      readonly requestingCompany: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      } | null
      readonly owner: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
      }
      readonly builder: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
      }
      readonly validator: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
      } | null
    }>
  }
}

export type TemplateBuildStatsQueryVariables = Exact<{ [key: string]: never }>

export type TemplateBuildStatsQuery = {
  readonly __typename: 'Query'
  readonly templateBuildStats: {
    readonly __typename: 'TemplateBuildStats'
    readonly builders: ReadonlyArray<{
      readonly __typename: 'TemplateBuildStatsBuilder'
      readonly waitingOnCount: Int['output']
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
      }
    }>
  }
}

export type SimilarTemplatesQueryVariables = Exact<{
  upload: Scalars['Upload']['input']
}>

export type SimilarTemplatesQuery = {
  readonly __typename: 'Query'
  readonly similarTemplates: ReadonlyArray<{
    readonly __typename: 'SimilarTemplate'
    readonly score: Float['output']
    readonly template: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly createdAt: DateTime['output']
      readonly type: FormTemplateType
      readonly status: FormTemplateStatus
      readonly skippedValidation: Boolean['output']
      readonly userVisibleName: String['output']
      readonly isCustomerReady: Boolean['output']
      readonly tags: ReadonlyArray<FormTemplateTag>
      readonly dueDate: DateTime['output'] | null
      readonly statusLogs: ReadonlyArray<{
        readonly __typename: 'FormTemplateStatusLog'
        readonly id: ID['output']
        readonly statusUpdatedAt: DateTime['output']
        readonly status: FormTemplateStatus
        readonly user: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
        }
      }>
      readonly associatedCompany: {
        readonly __typename: 'Company'
        readonly id: ID['output']
      } | null
      readonly requestingCompany: {
        readonly __typename: 'Company'
        readonly id: ID['output']
      } | null
      readonly associatedContracts: ReadonlyArray<{
        readonly __typename: 'FormTemplateAssociatedContract'
        readonly id: String['output']
        readonly providedAsFormType: ProjectOnboardingFormType | null
        readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
      }>
      readonly owner: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
      readonly builder: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
      readonly validator: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      } | null
      readonly versions: ReadonlyArray<{
        readonly __typename: 'FormTemplateVersion'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly updatedAt: DateTime['output']
        readonly versionNumber: Int['output']
        readonly isReadyForManualStoredMaterials: Boolean['output']
        readonly file: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
        }
        readonly annotations: ReadonlyArray<{
          readonly __typename: 'FormTemplateAnnotation'
          readonly id: ID['output']
          readonly permanentId: ID['output']
          readonly syncTag: String['output'] | null
          readonly userVisibleName: String['output']
          readonly type: FormTemplateAnnotationType
          readonly xStart: Float['output']
          readonly yStart: Float['output']
          readonly width: Float['output']
          readonly height: Float['output']
          readonly pageNumber: Int['output']
          readonly fontFamily: FormTemplateFont
          readonly fontColor: String['output']
          readonly textAlignment: TextAlignment
          readonly wrapText: Boolean['output']
          readonly isOptional: Boolean['output']
          readonly selectedKey: String['output'] | null
          readonly defaultValueKey: String['output'] | null
          readonly prefix: String['output'] | null
          readonly suffix: String['output'] | null
          readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
          readonly doNotRetainOnReset: Boolean['output']
          readonly dynamicFieldTag: String['output'] | null
          readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
          readonly imageType: FormTemplateAnnotationImageType | null
          readonly signatureType: SignatureAnnotationType | null
        }>
      }>
      readonly variants: ReadonlyArray<{
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly internalName: String['output']
        readonly isDefaultVariant: Boolean['output']
      }>
      readonly comments: ReadonlyArray<{
        readonly __typename: 'FormTemplateComment'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly message: String['output']
        readonly isSystemGenerated: Boolean['output']
        readonly user: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
          readonly email: EmailAddress['output']
        }
      }>
      readonly originalFile: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
      } | null
      readonly duplicateTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly userVisibleName: String['output']
      } | null
    }
    readonly version: {
      readonly __typename: 'FormTemplateVersion'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly updatedAt: DateTime['output']
      readonly versionNumber: Int['output']
      readonly isReadyForManualStoredMaterials: Boolean['output']
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
      }
      readonly annotations: ReadonlyArray<{
        readonly __typename: 'FormTemplateAnnotation'
        readonly id: ID['output']
        readonly permanentId: ID['output']
        readonly syncTag: String['output'] | null
        readonly userVisibleName: String['output']
        readonly type: FormTemplateAnnotationType
        readonly xStart: Float['output']
        readonly yStart: Float['output']
        readonly width: Float['output']
        readonly height: Float['output']
        readonly pageNumber: Int['output']
        readonly fontFamily: FormTemplateFont
        readonly fontColor: String['output']
        readonly textAlignment: TextAlignment
        readonly wrapText: Boolean['output']
        readonly isOptional: Boolean['output']
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output']
        readonly dynamicFieldTag: String['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
        readonly imageType: FormTemplateAnnotationImageType | null
        readonly signatureType: SignatureAnnotationType | null
      }>
    }
  }>
}

export type FormTurnaroundDataQueryVariables = Exact<{
  input: FormTurnaroundDataInput
}>

export type FormTurnaroundDataQuery = {
  readonly __typename: 'Query'
  readonly formTurnaroundData: {
    readonly __typename: 'FormTurnaroundData'
    readonly forms: ReadonlyArray<{
      readonly __typename: 'FormTurnaroundFormData'
      readonly startDate: DateTime['output']
      readonly endDate: DateTime['output']
      readonly formTemplate: {
        readonly __typename: 'MinimalFormTurnaroundFormTemplate'
        readonly id: ID['output']
        readonly userVisibleName: String['output']
        readonly builderId: ID['output'] | null
        readonly requestingCompanyId: ID['output'] | null
        readonly validatorId: ID['output'] | null
        readonly ownerId: ID['output'] | null
      }
    }>
    readonly companies: ReadonlyArray<{
      readonly __typename: 'MinimalFormTurnaroundCompany'
      readonly id: ID['output']
      readonly name: String['output']
    }>
    readonly users: ReadonlyArray<{
      readonly __typename: 'MinimalFormTurnaroundUser'
      readonly id: ID['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }>
  }
}

export type CreateFormTemplateVersionMutationVariables = Exact<{
  input: CreateFormTemplateVersionInput
}>

export type CreateFormTemplateVersionMutation = {
  readonly __typename: 'Mutation'
  readonly createFormTemplateVersion: {
    readonly __typename: 'FormTemplateVersion'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly updatedAt: DateTime['output']
    readonly versionNumber: Int['output']
    readonly isReadyForManualStoredMaterials: Boolean['output']
    readonly file: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
    }
    readonly annotations: ReadonlyArray<{
      readonly __typename: 'FormTemplateAnnotation'
      readonly id: ID['output']
      readonly permanentId: ID['output']
      readonly syncTag: String['output'] | null
      readonly userVisibleName: String['output']
      readonly type: FormTemplateAnnotationType
      readonly xStart: Float['output']
      readonly yStart: Float['output']
      readonly width: Float['output']
      readonly height: Float['output']
      readonly pageNumber: Int['output']
      readonly fontFamily: FormTemplateFont
      readonly fontColor: String['output']
      readonly textAlignment: TextAlignment
      readonly wrapText: Boolean['output']
      readonly isOptional: Boolean['output']
      readonly selectedKey: String['output'] | null
      readonly defaultValueKey: String['output'] | null
      readonly prefix: String['output'] | null
      readonly suffix: String['output'] | null
      readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
      readonly doNotRetainOnReset: Boolean['output']
      readonly dynamicFieldTag: String['output'] | null
      readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
      readonly imageType: FormTemplateAnnotationImageType | null
      readonly signatureType: SignatureAnnotationType | null
    }>
  }
}

export type ReplaceFormTemplateAnnotationsMutationVariables = Exact<{
  input: ReplaceFormTemplateAnnotationsInput
}>

export type ReplaceFormTemplateAnnotationsMutation = {
  readonly __typename: 'Mutation'
  readonly replaceFormTemplateAnnotations: ReadonlyArray<{
    readonly __typename: 'FormTemplateAnnotation'
    readonly id: ID['output']
    readonly permanentId: ID['output']
    readonly syncTag: String['output'] | null
    readonly userVisibleName: String['output']
    readonly type: FormTemplateAnnotationType
    readonly xStart: Float['output']
    readonly yStart: Float['output']
    readonly width: Float['output']
    readonly height: Float['output']
    readonly pageNumber: Int['output']
    readonly fontFamily: FormTemplateFont
    readonly fontColor: String['output']
    readonly textAlignment: TextAlignment
    readonly wrapText: Boolean['output']
    readonly isOptional: Boolean['output']
    readonly selectedKey: String['output'] | null
    readonly defaultValueKey: String['output'] | null
    readonly prefix: String['output'] | null
    readonly suffix: String['output'] | null
    readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
    readonly doNotRetainOnReset: Boolean['output']
    readonly dynamicFieldTag: String['output'] | null
    readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
    readonly imageType: FormTemplateAnnotationImageType | null
    readonly signatureType: SignatureAnnotationType | null
  }>
}

export type TemplateForPreviewQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type TemplateForPreviewQuery = {
  readonly __typename: 'Query'
  readonly formTemplate: {
    readonly __typename: 'FormTemplate'
    readonly id: String['output']
    readonly updatedAt: DateTime['output']
    readonly status: FormTemplateStatus
    readonly versions: ReadonlyArray<{
      readonly __typename: 'FormTemplateVersion'
      readonly id: ID['output']
      readonly versionNumber: Int['output']
      readonly annotations: ReadonlyArray<{
        readonly __typename: 'FormTemplateAnnotation'
        readonly id: ID['output']
        readonly permanentId: ID['output']
        readonly syncTag: String['output'] | null
        readonly userVisibleName: String['output']
        readonly type: FormTemplateAnnotationType
        readonly xStart: Float['output']
        readonly yStart: Float['output']
        readonly width: Float['output']
        readonly height: Float['output']
        readonly pageNumber: Int['output']
        readonly fontFamily: FormTemplateFont
        readonly fontColor: String['output']
        readonly textAlignment: TextAlignment
        readonly wrapText: Boolean['output']
        readonly isOptional: Boolean['output']
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output']
        readonly dynamicFieldTag: String['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
        readonly imageType: FormTemplateAnnotationImageType | null
        readonly signatureType: SignatureAnnotationType | null
      }>
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
        readonly name: String['output']
      }
    }>
    readonly variants: ReadonlyArray<{
      readonly __typename: 'FormTemplateVariant'
      readonly id: ID['output']
      readonly internalName: String['output']
      readonly isDefaultVariant: Boolean['output']
      readonly hidesZeroDollarAmounts: Boolean['output'] | null
      readonly roundPercentages: Boolean['output'] | null
      readonly annotationOverrides: ReadonlyArray<{
        readonly __typename: 'AnnotationOverride'
        readonly id: ID['output']
        readonly annotationPermanentId: ID['output']
        readonly userVisibleName: String['output'] | null
        readonly fontFamily: FormTemplateFont | null
        readonly fontColor: String['output'] | null
        readonly textAlignment: TextAlignment | null
        readonly wrapText: Boolean['output'] | null
        readonly isOptional: Boolean['output'] | null
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output'] | null
        readonly imageType: FormTemplateAnnotationImageType | null
        readonly signatureType: SignatureAnnotationType | null
        readonly syncTag: String['output'] | null
      }>
    }>
  }
}

export type CreateTemplateVariantMutationVariables = Exact<{
  input: CreateTemplateVariantInput
}>

export type CreateTemplateVariantMutation = {
  readonly __typename: 'Mutation'
  readonly createTemplateVariant: {
    readonly __typename: 'FormTemplateVariant'
    readonly id: ID['output']
    readonly internalName: String['output']
    readonly userVisibleName: String['output'] | null
    readonly isDefaultVariant: Boolean['output']
    readonly hidesZeroDollarAmounts: Boolean['output'] | null
    readonly roundPercentages: Boolean['output'] | null
    readonly useCompanyNotarySignatureIfAvailable: Boolean['output'] | null
    readonly annotationOverrides: ReadonlyArray<{
      readonly __typename: 'AnnotationOverride'
      readonly id: ID['output']
      readonly annotationPermanentId: ID['output']
      readonly userVisibleName: String['output'] | null
      readonly fontFamily: FormTemplateFont | null
      readonly fontColor: String['output'] | null
      readonly textAlignment: TextAlignment | null
      readonly wrapText: Boolean['output'] | null
      readonly isOptional: Boolean['output'] | null
      readonly selectedKey: String['output'] | null
      readonly defaultValueKey: String['output'] | null
      readonly prefix: String['output'] | null
      readonly suffix: String['output'] | null
      readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
      readonly doNotRetainOnReset: Boolean['output'] | null
      readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output'] | null
      readonly imageType: FormTemplateAnnotationImageType | null
      readonly signatureType: SignatureAnnotationType | null
      readonly syncTag: String['output'] | null
    }>
  }
}

export type UpdateTemplateVariantMutationVariables = Exact<{
  input: UpdateTemplateVariantInput
}>

export type UpdateTemplateVariantMutation = {
  readonly __typename: 'Mutation'
  readonly updateTemplateVariant: {
    readonly __typename: 'FormTemplateVariant'
    readonly id: ID['output']
    readonly internalName: String['output']
    readonly userVisibleName: String['output'] | null
    readonly isDefaultVariant: Boolean['output']
    readonly hidesZeroDollarAmounts: Boolean['output'] | null
    readonly roundPercentages: Boolean['output'] | null
    readonly useCompanyNotarySignatureIfAvailable: Boolean['output'] | null
    readonly annotationOverrides: ReadonlyArray<{
      readonly __typename: 'AnnotationOverride'
      readonly id: ID['output']
      readonly annotationPermanentId: ID['output']
      readonly userVisibleName: String['output'] | null
      readonly fontFamily: FormTemplateFont | null
      readonly fontColor: String['output'] | null
      readonly textAlignment: TextAlignment | null
      readonly wrapText: Boolean['output'] | null
      readonly isOptional: Boolean['output'] | null
      readonly selectedKey: String['output'] | null
      readonly defaultValueKey: String['output'] | null
      readonly prefix: String['output'] | null
      readonly suffix: String['output'] | null
      readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
      readonly doNotRetainOnReset: Boolean['output'] | null
      readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output'] | null
      readonly imageType: FormTemplateAnnotationImageType | null
      readonly signatureType: SignatureAnnotationType | null
      readonly syncTag: String['output'] | null
    }>
  }
}

export type DeleteTemplateVariantMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteTemplateVariantMutation = {
  readonly __typename: 'Mutation'
  readonly deleteTemplateVariant: {
    readonly __typename: 'DeletionResult'
    readonly id: ID['output']
  }
}

export type CreateFormTemplateAnnotationMutationVariables = Exact<{
  input: CreateFormAnnotationInput
}>

export type CreateFormTemplateAnnotationMutation = {
  readonly __typename: 'Mutation'
  readonly createFormTemplateAnnotation: {
    readonly __typename: 'FormTemplateAnnotation'
    readonly id: ID['output']
    readonly permanentId: ID['output']
    readonly syncTag: String['output'] | null
    readonly userVisibleName: String['output']
    readonly type: FormTemplateAnnotationType
    readonly xStart: Float['output']
    readonly yStart: Float['output']
    readonly width: Float['output']
    readonly height: Float['output']
    readonly pageNumber: Int['output']
    readonly fontFamily: FormTemplateFont
    readonly fontColor: String['output']
    readonly textAlignment: TextAlignment
    readonly wrapText: Boolean['output']
    readonly isOptional: Boolean['output']
    readonly selectedKey: String['output'] | null
    readonly defaultValueKey: String['output'] | null
    readonly prefix: String['output'] | null
    readonly suffix: String['output'] | null
    readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
    readonly doNotRetainOnReset: Boolean['output']
    readonly dynamicFieldTag: String['output'] | null
    readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
    readonly imageType: FormTemplateAnnotationImageType | null
    readonly signatureType: SignatureAnnotationType | null
  }
}

export type CreateFormTemplateAnnotationsMutationVariables = Exact<{
  input: CreateFormAnnotationsInput
}>

export type CreateFormTemplateAnnotationsMutation = {
  readonly __typename: 'Mutation'
  readonly createFormTemplateAnnotations: ReadonlyArray<{
    readonly __typename: 'FormTemplateAnnotation'
    readonly id: ID['output']
    readonly permanentId: ID['output']
    readonly syncTag: String['output'] | null
    readonly userVisibleName: String['output']
    readonly type: FormTemplateAnnotationType
    readonly xStart: Float['output']
    readonly yStart: Float['output']
    readonly width: Float['output']
    readonly height: Float['output']
    readonly pageNumber: Int['output']
    readonly fontFamily: FormTemplateFont
    readonly fontColor: String['output']
    readonly textAlignment: TextAlignment
    readonly wrapText: Boolean['output']
    readonly isOptional: Boolean['output']
    readonly selectedKey: String['output'] | null
    readonly defaultValueKey: String['output'] | null
    readonly prefix: String['output'] | null
    readonly suffix: String['output'] | null
    readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
    readonly doNotRetainOnReset: Boolean['output']
    readonly dynamicFieldTag: String['output'] | null
    readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
    readonly imageType: FormTemplateAnnotationImageType | null
    readonly signatureType: SignatureAnnotationType | null
  }>
}

export type DeleteFormTemplateAnnotationsMutationVariables = Exact<{
  ids: ReadonlyArray<Scalars['ID']['input']> | Scalars['ID']['input']
}>

export type DeleteFormTemplateAnnotationsMutation = {
  readonly __typename: 'Mutation'
  readonly deleteFormTemplateAnnotations: ReadonlyArray<{
    readonly __typename: 'DeletionResult'
    readonly id: ID['output']
  }>
}

export type UpdateFormTemplateAnnotationsMutationVariables = Exact<{
  input: UpdateFormAnnotationsInput
}>

export type UpdateFormTemplateAnnotationsMutation = {
  readonly __typename: 'Mutation'
  readonly updateFormTemplateAnnotations: ReadonlyArray<{
    readonly __typename: 'FormTemplateAnnotation'
    readonly id: ID['output']
    readonly permanentId: ID['output']
    readonly syncTag: String['output'] | null
    readonly userVisibleName: String['output']
    readonly type: FormTemplateAnnotationType
    readonly xStart: Float['output']
    readonly yStart: Float['output']
    readonly width: Float['output']
    readonly height: Float['output']
    readonly pageNumber: Int['output']
    readonly fontFamily: FormTemplateFont
    readonly fontColor: String['output']
    readonly textAlignment: TextAlignment
    readonly wrapText: Boolean['output']
    readonly isOptional: Boolean['output']
    readonly selectedKey: String['output'] | null
    readonly defaultValueKey: String['output'] | null
    readonly prefix: String['output'] | null
    readonly suffix: String['output'] | null
    readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
    readonly doNotRetainOnReset: Boolean['output']
    readonly dynamicFieldTag: String['output'] | null
    readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
    readonly imageType: FormTemplateAnnotationImageType | null
    readonly signatureType: SignatureAnnotationType | null
  }>
}

export type ApplyAnnotationOverrideMutationVariables = Exact<{
  input: ApplyAnnotationOverrideInput
}>

export type ApplyAnnotationOverrideMutation = {
  readonly __typename: 'Mutation'
  readonly applyAnnotationOverride: {
    readonly __typename: 'AnnotationOverride'
    readonly id: ID['output']
    readonly annotationPermanentId: ID['output']
    readonly userVisibleName: String['output'] | null
    readonly fontFamily: FormTemplateFont | null
    readonly fontColor: String['output'] | null
    readonly textAlignment: TextAlignment | null
    readonly wrapText: Boolean['output'] | null
    readonly isOptional: Boolean['output'] | null
    readonly selectedKey: String['output'] | null
    readonly defaultValueKey: String['output'] | null
    readonly prefix: String['output'] | null
    readonly suffix: String['output'] | null
    readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
    readonly doNotRetainOnReset: Boolean['output'] | null
    readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output'] | null
    readonly imageType: FormTemplateAnnotationImageType | null
    readonly signatureType: SignatureAnnotationType | null
    readonly syncTag: String['output'] | null
  }
}

export type TemplateVersionForEditorQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type TemplateVersionForEditorQuery = {
  readonly __typename: 'Query'
  readonly formTemplateVersion: {
    readonly __typename: 'FormTemplateVersion'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly updatedAt: DateTime['output']
    readonly versionNumber: Int['output']
    readonly isReadyForManualStoredMaterials: Boolean['output']
    readonly file: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
    }
    readonly annotations: ReadonlyArray<{
      readonly __typename: 'FormTemplateAnnotation'
      readonly id: ID['output']
      readonly permanentId: ID['output']
      readonly syncTag: String['output'] | null
      readonly userVisibleName: String['output']
      readonly type: FormTemplateAnnotationType
      readonly xStart: Float['output']
      readonly yStart: Float['output']
      readonly width: Float['output']
      readonly height: Float['output']
      readonly pageNumber: Int['output']
      readonly fontFamily: FormTemplateFont
      readonly fontColor: String['output']
      readonly textAlignment: TextAlignment
      readonly wrapText: Boolean['output']
      readonly isOptional: Boolean['output']
      readonly selectedKey: String['output'] | null
      readonly defaultValueKey: String['output'] | null
      readonly prefix: String['output'] | null
      readonly suffix: String['output'] | null
      readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
      readonly doNotRetainOnReset: Boolean['output']
      readonly dynamicFieldTag: String['output'] | null
      readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
      readonly imageType: FormTemplateAnnotationImageType | null
      readonly signatureType: SignatureAnnotationType | null
    }>
  }
}

export type TemplateVariantForEditorQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type TemplateVariantForEditorQuery = {
  readonly __typename: 'Query'
  readonly formTemplateVariant: {
    readonly __typename: 'FormTemplateVariant'
    readonly id: ID['output']
    readonly internalName: String['output']
    readonly userVisibleName: String['output'] | null
    readonly isDefaultVariant: Boolean['output']
    readonly hidesZeroDollarAmounts: Boolean['output'] | null
    readonly roundPercentages: Boolean['output'] | null
    readonly useCompanyNotarySignatureIfAvailable: Boolean['output'] | null
    readonly annotationOverrides: ReadonlyArray<{
      readonly __typename: 'AnnotationOverride'
      readonly id: ID['output']
      readonly annotationPermanentId: ID['output']
      readonly userVisibleName: String['output'] | null
      readonly fontFamily: FormTemplateFont | null
      readonly fontColor: String['output'] | null
      readonly textAlignment: TextAlignment | null
      readonly wrapText: Boolean['output'] | null
      readonly isOptional: Boolean['output'] | null
      readonly selectedKey: String['output'] | null
      readonly defaultValueKey: String['output'] | null
      readonly prefix: String['output'] | null
      readonly suffix: String['output'] | null
      readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
      readonly doNotRetainOnReset: Boolean['output'] | null
      readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output'] | null
      readonly imageType: FormTemplateAnnotationImageType | null
      readonly signatureType: SignatureAnnotationType | null
      readonly syncTag: String['output'] | null
    }>
  }
}

export type UpdateFormTemplateVersionMutationVariables = Exact<{
  input: UpdateFormTemplateVersionInput
}>

export type UpdateFormTemplateVersionMutation = {
  readonly __typename: 'Mutation'
  readonly updateFormTemplateVersion: {
    readonly __typename: 'FormTemplateVersion'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly updatedAt: DateTime['output']
    readonly versionNumber: Int['output']
    readonly isReadyForManualStoredMaterials: Boolean['output']
    readonly file: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
    }
    readonly annotations: ReadonlyArray<{
      readonly __typename: 'FormTemplateAnnotation'
      readonly id: ID['output']
      readonly permanentId: ID['output']
      readonly syncTag: String['output'] | null
      readonly userVisibleName: String['output']
      readonly type: FormTemplateAnnotationType
      readonly xStart: Float['output']
      readonly yStart: Float['output']
      readonly width: Float['output']
      readonly height: Float['output']
      readonly pageNumber: Int['output']
      readonly fontFamily: FormTemplateFont
      readonly fontColor: String['output']
      readonly textAlignment: TextAlignment
      readonly wrapText: Boolean['output']
      readonly isOptional: Boolean['output']
      readonly selectedKey: String['output'] | null
      readonly defaultValueKey: String['output'] | null
      readonly prefix: String['output'] | null
      readonly suffix: String['output'] | null
      readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
      readonly doNotRetainOnReset: Boolean['output']
      readonly dynamicFieldTag: String['output'] | null
      readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
      readonly imageType: FormTemplateAnnotationImageType | null
      readonly signatureType: SignatureAnnotationType | null
    }>
  }
}

export type DeleteFormTemplateVersionMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteFormTemplateVersionMutation = {
  readonly __typename: 'Mutation'
  readonly deleteFormTemplateVersion: {
    readonly __typename: 'DeletionResult'
    readonly id: ID['output']
  }
}

export type GeneralContractorsForDedupeQueryVariables = Exact<{
  companyId: Scalars['ID']['input']
}>

export type GeneralContractorsForDedupeQuery = {
  readonly __typename: 'Query'
  readonly generalContractorsForDedupe: ReadonlyArray<{
    readonly __typename: 'GeneralContractorForDedupe'
    readonly contractCount: SafeInt['output']
    readonly company: {
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
      readonly locations: ReadonlyArray<{
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }>
    }
  }>
}

export type MergeGeneralContractorCompaniesMutationVariables = Exact<{
  input: MergeGeneralContractorCompaniesInput
}>

export type MergeGeneralContractorCompaniesMutation = {
  readonly __typename: 'Mutation'
  readonly mergeGeneralContractorCompanies: Boolean['output']
}

export type CloudLoggingLogsQueryVariables = Exact<{
  input: CloudLoggingLogsInput
}>

export type CloudLoggingLogsQuery = {
  readonly __typename: 'Query'
  readonly cloudLoggingLogs: {
    readonly __typename: 'CloudLoggingLogsResponse'
    readonly entries: ReadonlyArray<JSONObject['output']>
    readonly nextPageToken: String['output'] | null
  }
}

export type SetLegalDocumentSyncedMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type SetLegalDocumentSyncedMutation = {
  readonly __typename: 'Mutation'
  readonly setLegalDocumentSynced: {
    readonly __typename: 'LegalRequirement'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly name: String['output']
    readonly timeZone: String['output']
    readonly expirationFrequency: DocumentExpirationFrequency
    readonly startDate: DateTime['output'] | null
    readonly endDate: DateTime['output'] | null
    readonly isVendorRequirement: Boolean['output']
    readonly documents: ReadonlyArray<{
      readonly __typename: 'LegalDocument'
      readonly id: ID['output']
      readonly name: String['output']
      readonly periodStart: DateTime['output'] | null
      readonly periodEnd: DateTime['output'] | null
      readonly lastSyncIfSuccessful: {
        readonly __typename: 'WriteSyncOperation'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
      } | null
    }>
    readonly formTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
    } | null
  }
}

export type GetLegalRequirementQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type GetLegalRequirementQuery = {
  readonly __typename: 'Query'
  readonly legalRequirement: {
    readonly __typename: 'LegalRequirement'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly name: String['output']
    readonly timeZone: String['output']
    readonly expirationFrequency: DocumentExpirationFrequency
    readonly startDate: DateTime['output'] | null
    readonly endDate: DateTime['output'] | null
    readonly isVendorRequirement: Boolean['output']
    readonly documents: ReadonlyArray<{
      readonly __typename: 'LegalDocument'
      readonly id: ID['output']
      readonly name: String['output']
      readonly periodStart: DateTime['output'] | null
      readonly periodEnd: DateTime['output'] | null
      readonly lastSyncIfSuccessful: {
        readonly __typename: 'WriteSyncOperation'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
      } | null
    }>
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly integrations: ReadonlyArray<{
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly type: IntegrationType
        readonly credentialsUpdatedAt: DateTime['output'] | null
        readonly mappings: JSONObject['output']
        readonly isActive: Boolean['output']
        readonly shortName: String['output']
        readonly longName: String['output']
        readonly companyIntegration: {
          readonly __typename: 'CompanyIntegration'
          readonly id: ID['output']
        }
      }>
    }
    readonly formTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
    } | null
  }
}

export type GetPayAppForDetailsQueryVariables = Exact<{
  payAppId: Scalars['ID']['input']
}>

export type GetPayAppForDetailsQuery = {
  readonly __typename: 'Query'
  readonly payApp: {
    readonly __typename: 'PayApp'
    readonly hasPinnedTemplates: Boolean['output']
    readonly billingType: BillingType
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly timeZone: String['output']
    readonly status: PayAppStatus
    readonly retentionOnly: Boolean['output']
    readonly billingStart: DateTime['output']
    readonly billingEnd: DateTime['output']
    readonly payAppDueDate: DateTime['output']
    readonly currentBilled: SafeInt['output']
    readonly currentRetention: SafeInt['output']
    readonly totalRetention: SafeInt['output']
    readonly previousRetentionBilled: SafeInt['output']
    readonly formTemplates: ReadonlyArray<{
      readonly __typename: 'PinnedPayAppTemplate'
      readonly order: Int['output']
      readonly formTemplateVersion: {
        readonly __typename: 'FormTemplateVersion'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly updatedAt: DateTime['output']
        readonly versionNumber: Int['output']
        readonly isReadyForManualStoredMaterials: Boolean['output']
        readonly formTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly userVisibleName: String['output']
        }
        readonly file: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
        }
        readonly annotations: ReadonlyArray<{
          readonly __typename: 'FormTemplateAnnotation'
          readonly id: ID['output']
          readonly permanentId: ID['output']
          readonly syncTag: String['output'] | null
          readonly userVisibleName: String['output']
          readonly type: FormTemplateAnnotationType
          readonly xStart: Float['output']
          readonly yStart: Float['output']
          readonly width: Float['output']
          readonly height: Float['output']
          readonly pageNumber: Int['output']
          readonly fontFamily: FormTemplateFont
          readonly fontColor: String['output']
          readonly textAlignment: TextAlignment
          readonly wrapText: Boolean['output']
          readonly isOptional: Boolean['output']
          readonly selectedKey: String['output'] | null
          readonly defaultValueKey: String['output'] | null
          readonly prefix: String['output'] | null
          readonly suffix: String['output'] | null
          readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
          readonly doNotRetainOnReset: Boolean['output']
          readonly dynamicFieldTag: String['output'] | null
          readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
          readonly imageType: FormTemplateAnnotationImageType | null
          readonly signatureType: SignatureAnnotationType | null
        }>
      }
    }>
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly role: CompanyProjectRole
      readonly status: ContractStatus
      readonly timeZone: String['output']
      readonly contractDate: DateTime['output'] | null
      readonly contractNumber: String['output'] | null
      readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
      readonly defaultRetentionPercent: Float['output'] | null
      readonly retentionTrackingLevel: RetentionTrackingLevel
      readonly daysBeforePayAppDue: Int['output']
      readonly billingType: BillingType
      readonly contractTemplateVariables: JSONObject['output']
      readonly internalProjectNumber: String['output'] | null
      readonly vendorNumber: String['output'] | null
      readonly pastPayAppCount: Int['output']
      readonly roundRetention: Boolean['output']
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
        readonly nickname: String['output'] | null
        readonly type: CompanyType
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly expectedNumberOfProjects: SafeInt['output'] | null
        readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
        readonly allowLumpSumOverbilling: Boolean['output']
        readonly notaryTags: ReadonlyArray<NotaryTag>
        readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
        readonly isDemoCompany: Boolean['output']
        readonly picture: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notaryStamp: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly secondarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly locations: ReadonlyArray<{
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        }>
        readonly metadata: {
          readonly __typename: 'CompanyMetadata'
          readonly subtrade: String['output'] | null
          readonly domains: ReadonlyArray<String['output']>
          readonly domainSignup: Boolean['output']
          readonly monthlyCreatePayAppReminderDate: Int['output'] | null
        }
        readonly defaultQuickBillingTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
        } | null
        readonly sitelinePointOfContact: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
        } | null
      }
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly name: String['output']
        readonly projectNumber: String['output']
        readonly location: {
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        }
        readonly generalContractor: {
          readonly __typename: 'CompanyProjectMetadata'
          readonly role: CompanyProjectRole
          readonly companyName: String['output'] | null
          readonly company: {
            readonly __typename: 'Company'
            readonly id: ID['output']
            readonly name: String['output']
            readonly nickname: String['output'] | null
            readonly type: CompanyType
            readonly phoneNumber: PhoneNumber['output'] | null
            readonly expectedNumberOfProjects: SafeInt['output'] | null
            readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
            readonly allowLumpSumOverbilling: Boolean['output']
            readonly notaryTags: ReadonlyArray<NotaryTag>
            readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
            readonly isDemoCompany: Boolean['output']
            readonly picture: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly notaryStamp: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly notarySignature: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly secondarySignature: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly locations: ReadonlyArray<{
              readonly __typename: 'Location'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly nickname: String['output'] | null
              readonly street1: String['output'] | null
              readonly street2: String['output'] | null
              readonly city: String['output']
              readonly county: String['output'] | null
              readonly state: String['output']
              readonly country: String['output']
              readonly postalCode: String['output'] | null
              readonly timeZone: String['output']
              readonly coordinates: LatLng['output']
            }>
            readonly metadata: {
              readonly __typename: 'CompanyMetadata'
              readonly subtrade: String['output'] | null
              readonly domains: ReadonlyArray<String['output']>
              readonly domainSignup: Boolean['output']
              readonly monthlyCreatePayAppReminderDate: Int['output'] | null
            }
            readonly defaultQuickBillingTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
            } | null
            readonly sitelinePointOfContact: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            } | null
          }
          readonly selectedAddress: {
            readonly __typename: 'Location'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly nickname: String['output'] | null
            readonly street1: String['output'] | null
            readonly street2: String['output'] | null
            readonly city: String['output']
            readonly county: String['output'] | null
            readonly state: String['output']
            readonly country: String['output']
            readonly postalCode: String['output'] | null
            readonly timeZone: String['output']
            readonly coordinates: LatLng['output']
          } | null
        } | null
      }
      readonly sov: {
        readonly __typename: 'Sov'
        readonly id: ID['output']
        readonly totalValue: SafeInt['output']
        readonly totalBilled: SafeInt['output']
        readonly totalRetention: SafeInt['output']
        readonly progressComplete: Float['output']
        readonly contract: {
          readonly __typename: 'Contract'
          readonly id: ID['output']
          readonly timeZone: String['output']
        }
        readonly lineItems: ReadonlyArray<{
          readonly __typename: 'SovLineItem'
          readonly id: ID['output']
          readonly code: String['output']
          readonly sortOrder: SafeInt['output']
          readonly name: String['output']
          readonly isChangeOrder: Boolean['output']
          readonly changeOrderApprovedDate: DateTime['output'] | null
          readonly latestTotalValue: SafeInt['output']
          readonly totalBilled: SafeInt['output']
          readonly previousBilled: SafeInt['output']
          readonly previousStoredMaterials: SafeInt['output']
          readonly totalRetention: SafeInt['output']
          readonly progressComplete: Float['output']
          readonly defaultRetentionPercent: Float['output'] | null
          readonly sovLineItemGroup: {
            readonly __typename: 'SovLineItemGroup'
            readonly id: ID['output']
            readonly name: String['output']
            readonly code: String['output'] | null
          } | null
        }>
      } | null
      readonly lienWaiverTemplates: {
        readonly __typename: 'SubcontractorLienWaiverTemplateSet'
        readonly id: ID['output']
        readonly conditionalFinalVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly conditionalProgressVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly unconditionalFinalVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly unconditionalProgressVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
      } | null
      readonly lowerTierLienWaiverTemplates: {
        readonly __typename: 'SubcontractorLienWaiverTemplateSet'
        readonly id: ID['output']
        readonly conditionalFinalVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly conditionalProgressVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly unconditionalFinalVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly unconditionalProgressVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
      } | null
      readonly payAppRequirementGroups: ReadonlyArray<{
        readonly __typename: 'PayAppRequirementGroup'
        readonly id: ID['output']
        readonly order: Int['output']
        readonly payAppRequirements: ReadonlyArray<{
          readonly __typename: 'PayAppRequirement'
          readonly id: ID['output']
          readonly groupOrder: Int['output']
          readonly conditions: ReadonlyArray<PayAppRequirementCondition>
          readonly templateVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly userVisibleName: String['output'] | null
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly isCustomerReady: Boolean['output']
            }
          } | null
        }>
      }>
      readonly legalRequirements: ReadonlyArray<{
        readonly __typename: 'LegalRequirement'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly name: String['output']
        readonly timeZone: String['output']
        readonly expirationFrequency: DocumentExpirationFrequency
        readonly startDate: DateTime['output'] | null
        readonly endDate: DateTime['output'] | null
        readonly isVendorRequirement: Boolean['output']
        readonly documents: ReadonlyArray<{
          readonly __typename: 'LegalDocument'
          readonly id: ID['output']
        }>
        readonly formTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
        } | null
      }>
      readonly selectedAddress: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }
      readonly changeOrderRequestTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
      } | null
      readonly changeOrderLogTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
      } | null
      readonly integrations: ReadonlyArray<{
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly type: IntegrationType
        readonly credentialsUpdatedAt: DateTime['output'] | null
        readonly mappings: JSONObject['output']
        readonly isActive: Boolean['output']
        readonly shortName: String['output']
        readonly longName: String['output']
        readonly companyIntegration: {
          readonly __typename: 'CompanyIntegration'
          readonly id: ID['output']
        }
      }>
      readonly onboardedStatus: {
        readonly __typename: 'OnboardedProjectContractStatus'
        readonly selectedPayAppForms: Boolean['output']
        readonly onboardedPayAppForms: Boolean['output']
        readonly selectedPrimaryLienWaivers: Boolean['output']
        readonly onboardedPrimaryLienWaiverForms: Boolean['output']
        readonly selectedVendorLienWaivers: Boolean['output']
        readonly onboardedVendorLienWaiverForms: Boolean['output']
        readonly selectedChangeOrderRequestForms: Boolean['output']
        readonly onboardedChangeOrderRequestForms: Boolean['output']
        readonly addedSov: Boolean['output']
        readonly selectedRateTable: Boolean['output']
        readonly addedTeammates: Boolean['output']
        readonly addedGcContacts: Boolean['output']
        readonly startedBilling: Boolean['output']
        readonly notifiedOnboardedForms: Boolean['output']
      }
    }
    readonly progress: ReadonlyArray<{
      readonly __typename: 'SovLineItemProgress'
      readonly id: ID['output']
      readonly currentBilled: SafeInt['output']
      readonly progressBilled: SafeInt['output']
      readonly currentRetention: SafeInt['output']
      readonly previousBilled: SafeInt['output']
      readonly storedMaterialBilled: SafeInt['output']
      readonly previousRetention: SafeInt['output']
      readonly previousRetentionBilled: SafeInt['output']
      readonly totalValue: SafeInt['output']
      readonly sovLineItem: {
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
        readonly code: String['output']
        readonly sortOrder: SafeInt['output']
        readonly name: String['output']
        readonly isChangeOrder: Boolean['output']
        readonly changeOrderApprovedDate: DateTime['output'] | null
        readonly latestTotalValue: SafeInt['output']
        readonly totalBilled: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly previousStoredMaterials: SafeInt['output']
        readonly totalRetention: SafeInt['output']
        readonly progressComplete: Float['output']
        readonly defaultRetentionPercent: Float['output'] | null
        readonly sovLineItemGroup: {
          readonly __typename: 'SovLineItemGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly code: String['output'] | null
        } | null
      }
      readonly updatedBy: {
        readonly __typename: 'User'
        readonly blockedEmails: ReadonlyArray<EmailType>
        readonly blockedNotifications: ReadonlyArray<NotificationType>
        readonly policiesAcceptedAt: DateTime['output'] | null
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
        readonly jobTitle: String['output'] | null
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly companies: ReadonlyArray<{
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
          readonly nickname: String['output'] | null
          readonly type: CompanyType
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly expectedNumberOfProjects: SafeInt['output'] | null
          readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
          readonly allowLumpSumOverbilling: Boolean['output']
          readonly notaryTags: ReadonlyArray<NotaryTag>
          readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
          readonly isDemoCompany: Boolean['output']
          readonly picture: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly notaryStamp: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly notarySignature: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly secondarySignature: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly locations: ReadonlyArray<{
            readonly __typename: 'Location'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly nickname: String['output'] | null
            readonly street1: String['output'] | null
            readonly street2: String['output'] | null
            readonly city: String['output']
            readonly county: String['output'] | null
            readonly state: String['output']
            readonly country: String['output']
            readonly postalCode: String['output'] | null
            readonly timeZone: String['output']
            readonly coordinates: LatLng['output']
          }>
          readonly metadata: {
            readonly __typename: 'CompanyMetadata'
            readonly subtrade: String['output'] | null
            readonly domains: ReadonlyArray<String['output']>
            readonly domainSignup: Boolean['output']
            readonly monthlyCreatePayAppReminderDate: Int['output'] | null
          }
          readonly defaultQuickBillingTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
          } | null
          readonly sitelinePointOfContact: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
          } | null
        }>
        readonly companyUsers: ReadonlyArray<{
          readonly __typename: 'CompanyUser'
          readonly id: ID['output']
          readonly status: UserStatus
          readonly role: CompanyUserRole
          readonly company: {
            readonly __typename: 'Company'
            readonly id: ID['output']
            readonly name: String['output']
            readonly nickname: String['output'] | null
            readonly type: CompanyType
            readonly phoneNumber: PhoneNumber['output'] | null
            readonly expectedNumberOfProjects: SafeInt['output'] | null
            readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
            readonly allowLumpSumOverbilling: Boolean['output']
            readonly notaryTags: ReadonlyArray<NotaryTag>
            readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
            readonly isDemoCompany: Boolean['output']
            readonly picture: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly notaryStamp: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly notarySignature: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly secondarySignature: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly locations: ReadonlyArray<{
              readonly __typename: 'Location'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly nickname: String['output'] | null
              readonly street1: String['output'] | null
              readonly street2: String['output'] | null
              readonly city: String['output']
              readonly county: String['output'] | null
              readonly state: String['output']
              readonly country: String['output']
              readonly postalCode: String['output'] | null
              readonly timeZone: String['output']
              readonly coordinates: LatLng['output']
            }>
            readonly metadata: {
              readonly __typename: 'CompanyMetadata'
              readonly subtrade: String['output'] | null
              readonly domains: ReadonlyArray<String['output']>
              readonly domainSignup: Boolean['output']
              readonly monthlyCreatePayAppReminderDate: Int['output'] | null
            }
            readonly defaultQuickBillingTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
            } | null
            readonly sitelinePointOfContact: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            } | null
          }
        }>
      } | null
    }>
    readonly amountLineItems: ReadonlyArray<{
      readonly __typename: 'AmountLineItem'
      readonly id: ID['output']
      readonly description: String['output']
      readonly amount: SafeInt['output']
      readonly sortOrder: SafeInt['output']
      readonly feePercent: Float['output']
    }>
  }
}

export type ResetPayAppMutationVariables = Exact<{
  payAppId: Scalars['ID']['input']
}>

export type ResetPayAppMutation = {
  readonly __typename: 'Mutation'
  readonly resetPayApp: {
    readonly __typename: 'PayApp'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly timeZone: String['output']
    readonly status: PayAppStatus
    readonly retentionOnly: Boolean['output']
    readonly billingStart: DateTime['output']
    readonly billingEnd: DateTime['output']
    readonly payAppDueDate: DateTime['output']
    readonly currentBilled: SafeInt['output']
    readonly currentRetention: SafeInt['output']
    readonly totalRetention: SafeInt['output']
    readonly previousRetentionBilled: SafeInt['output']
    readonly progress: ReadonlyArray<{
      readonly __typename: 'SovLineItemProgress'
      readonly id: ID['output']
      readonly currentBilled: SafeInt['output']
      readonly progressBilled: SafeInt['output']
      readonly currentRetention: SafeInt['output']
      readonly previousBilled: SafeInt['output']
      readonly storedMaterialBilled: SafeInt['output']
      readonly previousRetention: SafeInt['output']
      readonly previousRetentionBilled: SafeInt['output']
      readonly totalValue: SafeInt['output']
      readonly sovLineItem: {
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
        readonly code: String['output']
        readonly sortOrder: SafeInt['output']
        readonly name: String['output']
        readonly isChangeOrder: Boolean['output']
        readonly changeOrderApprovedDate: DateTime['output'] | null
        readonly latestTotalValue: SafeInt['output']
        readonly totalBilled: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly previousStoredMaterials: SafeInt['output']
        readonly totalRetention: SafeInt['output']
        readonly progressComplete: Float['output']
        readonly defaultRetentionPercent: Float['output'] | null
        readonly sovLineItemGroup: {
          readonly __typename: 'SovLineItemGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly code: String['output'] | null
        } | null
      }
      readonly updatedBy: {
        readonly __typename: 'User'
        readonly blockedEmails: ReadonlyArray<EmailType>
        readonly blockedNotifications: ReadonlyArray<NotificationType>
        readonly policiesAcceptedAt: DateTime['output'] | null
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
        readonly jobTitle: String['output'] | null
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly companies: ReadonlyArray<{
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
          readonly nickname: String['output'] | null
          readonly type: CompanyType
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly expectedNumberOfProjects: SafeInt['output'] | null
          readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
          readonly allowLumpSumOverbilling: Boolean['output']
          readonly notaryTags: ReadonlyArray<NotaryTag>
          readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
          readonly isDemoCompany: Boolean['output']
          readonly picture: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly notaryStamp: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly notarySignature: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly secondarySignature: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly locations: ReadonlyArray<{
            readonly __typename: 'Location'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly nickname: String['output'] | null
            readonly street1: String['output'] | null
            readonly street2: String['output'] | null
            readonly city: String['output']
            readonly county: String['output'] | null
            readonly state: String['output']
            readonly country: String['output']
            readonly postalCode: String['output'] | null
            readonly timeZone: String['output']
            readonly coordinates: LatLng['output']
          }>
          readonly metadata: {
            readonly __typename: 'CompanyMetadata'
            readonly subtrade: String['output'] | null
            readonly domains: ReadonlyArray<String['output']>
            readonly domainSignup: Boolean['output']
            readonly monthlyCreatePayAppReminderDate: Int['output'] | null
          }
          readonly defaultQuickBillingTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
          } | null
          readonly sitelinePointOfContact: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
          } | null
        }>
        readonly companyUsers: ReadonlyArray<{
          readonly __typename: 'CompanyUser'
          readonly id: ID['output']
          readonly status: UserStatus
          readonly role: CompanyUserRole
          readonly company: {
            readonly __typename: 'Company'
            readonly id: ID['output']
            readonly name: String['output']
            readonly nickname: String['output'] | null
            readonly type: CompanyType
            readonly phoneNumber: PhoneNumber['output'] | null
            readonly expectedNumberOfProjects: SafeInt['output'] | null
            readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
            readonly allowLumpSumOverbilling: Boolean['output']
            readonly notaryTags: ReadonlyArray<NotaryTag>
            readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
            readonly isDemoCompany: Boolean['output']
            readonly picture: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly notaryStamp: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly notarySignature: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly secondarySignature: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly locations: ReadonlyArray<{
              readonly __typename: 'Location'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly nickname: String['output'] | null
              readonly street1: String['output'] | null
              readonly street2: String['output'] | null
              readonly city: String['output']
              readonly county: String['output'] | null
              readonly state: String['output']
              readonly country: String['output']
              readonly postalCode: String['output'] | null
              readonly timeZone: String['output']
              readonly coordinates: LatLng['output']
            }>
            readonly metadata: {
              readonly __typename: 'CompanyMetadata'
              readonly subtrade: String['output'] | null
              readonly domains: ReadonlyArray<String['output']>
              readonly domainSignup: Boolean['output']
              readonly monthlyCreatePayAppReminderDate: Int['output'] | null
            }
            readonly defaultQuickBillingTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
            } | null
            readonly sitelinePointOfContact: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            } | null
          }
        }>
      } | null
    }>
    readonly amountLineItems: ReadonlyArray<{
      readonly __typename: 'AmountLineItem'
      readonly id: ID['output']
      readonly description: String['output']
      readonly amount: SafeInt['output']
      readonly sortOrder: SafeInt['output']
      readonly feePercent: Float['output']
    }>
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
        readonly nickname: String['output'] | null
        readonly type: CompanyType
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly expectedNumberOfProjects: SafeInt['output'] | null
        readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
        readonly allowLumpSumOverbilling: Boolean['output']
        readonly notaryTags: ReadonlyArray<NotaryTag>
        readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
        readonly isDemoCompany: Boolean['output']
        readonly picture: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notaryStamp: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly secondarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly locations: ReadonlyArray<{
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        }>
        readonly metadata: {
          readonly __typename: 'CompanyMetadata'
          readonly subtrade: String['output'] | null
          readonly domains: ReadonlyArray<String['output']>
          readonly domainSignup: Boolean['output']
          readonly monthlyCreatePayAppReminderDate: Int['output'] | null
        }
        readonly defaultQuickBillingTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
        } | null
        readonly sitelinePointOfContact: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
        } | null
      }
    }
  }
}

export type ClearPayAppEventsMutationVariables = Exact<{
  payAppId: Scalars['ID']['input']
}>

export type ClearPayAppEventsMutation = {
  readonly __typename: 'Mutation'
  readonly clearPayAppEvents: {
    readonly __typename: 'PayApp'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly timeZone: String['output']
    readonly status: PayAppStatus
    readonly retentionOnly: Boolean['output']
    readonly billingStart: DateTime['output']
    readonly billingEnd: DateTime['output']
    readonly payAppDueDate: DateTime['output']
    readonly currentBilled: SafeInt['output']
    readonly currentRetention: SafeInt['output']
    readonly totalRetention: SafeInt['output']
    readonly previousRetentionBilled: SafeInt['output']
    readonly progress: ReadonlyArray<{
      readonly __typename: 'SovLineItemProgress'
      readonly id: ID['output']
      readonly currentBilled: SafeInt['output']
      readonly progressBilled: SafeInt['output']
      readonly currentRetention: SafeInt['output']
      readonly previousBilled: SafeInt['output']
      readonly storedMaterialBilled: SafeInt['output']
      readonly previousRetention: SafeInt['output']
      readonly previousRetentionBilled: SafeInt['output']
      readonly totalValue: SafeInt['output']
      readonly sovLineItem: {
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
        readonly code: String['output']
        readonly sortOrder: SafeInt['output']
        readonly name: String['output']
        readonly isChangeOrder: Boolean['output']
        readonly changeOrderApprovedDate: DateTime['output'] | null
        readonly latestTotalValue: SafeInt['output']
        readonly totalBilled: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly previousStoredMaterials: SafeInt['output']
        readonly totalRetention: SafeInt['output']
        readonly progressComplete: Float['output']
        readonly defaultRetentionPercent: Float['output'] | null
        readonly sovLineItemGroup: {
          readonly __typename: 'SovLineItemGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly code: String['output'] | null
        } | null
      }
      readonly updatedBy: {
        readonly __typename: 'User'
        readonly blockedEmails: ReadonlyArray<EmailType>
        readonly blockedNotifications: ReadonlyArray<NotificationType>
        readonly policiesAcceptedAt: DateTime['output'] | null
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
        readonly jobTitle: String['output'] | null
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly companies: ReadonlyArray<{
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
          readonly nickname: String['output'] | null
          readonly type: CompanyType
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly expectedNumberOfProjects: SafeInt['output'] | null
          readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
          readonly allowLumpSumOverbilling: Boolean['output']
          readonly notaryTags: ReadonlyArray<NotaryTag>
          readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
          readonly isDemoCompany: Boolean['output']
          readonly picture: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly notaryStamp: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly notarySignature: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly secondarySignature: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly locations: ReadonlyArray<{
            readonly __typename: 'Location'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly nickname: String['output'] | null
            readonly street1: String['output'] | null
            readonly street2: String['output'] | null
            readonly city: String['output']
            readonly county: String['output'] | null
            readonly state: String['output']
            readonly country: String['output']
            readonly postalCode: String['output'] | null
            readonly timeZone: String['output']
            readonly coordinates: LatLng['output']
          }>
          readonly metadata: {
            readonly __typename: 'CompanyMetadata'
            readonly subtrade: String['output'] | null
            readonly domains: ReadonlyArray<String['output']>
            readonly domainSignup: Boolean['output']
            readonly monthlyCreatePayAppReminderDate: Int['output'] | null
          }
          readonly defaultQuickBillingTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
          } | null
          readonly sitelinePointOfContact: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
          } | null
        }>
        readonly companyUsers: ReadonlyArray<{
          readonly __typename: 'CompanyUser'
          readonly id: ID['output']
          readonly status: UserStatus
          readonly role: CompanyUserRole
          readonly company: {
            readonly __typename: 'Company'
            readonly id: ID['output']
            readonly name: String['output']
            readonly nickname: String['output'] | null
            readonly type: CompanyType
            readonly phoneNumber: PhoneNumber['output'] | null
            readonly expectedNumberOfProjects: SafeInt['output'] | null
            readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
            readonly allowLumpSumOverbilling: Boolean['output']
            readonly notaryTags: ReadonlyArray<NotaryTag>
            readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
            readonly isDemoCompany: Boolean['output']
            readonly picture: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly notaryStamp: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly notarySignature: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly secondarySignature: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly locations: ReadonlyArray<{
              readonly __typename: 'Location'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly nickname: String['output'] | null
              readonly street1: String['output'] | null
              readonly street2: String['output'] | null
              readonly city: String['output']
              readonly county: String['output'] | null
              readonly state: String['output']
              readonly country: String['output']
              readonly postalCode: String['output'] | null
              readonly timeZone: String['output']
              readonly coordinates: LatLng['output']
            }>
            readonly metadata: {
              readonly __typename: 'CompanyMetadata'
              readonly subtrade: String['output'] | null
              readonly domains: ReadonlyArray<String['output']>
              readonly domainSignup: Boolean['output']
              readonly monthlyCreatePayAppReminderDate: Int['output'] | null
            }
            readonly defaultQuickBillingTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
            } | null
            readonly sitelinePointOfContact: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            } | null
          }
        }>
      } | null
    }>
    readonly amountLineItems: ReadonlyArray<{
      readonly __typename: 'AmountLineItem'
      readonly id: ID['output']
      readonly description: String['output']
      readonly amount: SafeInt['output']
      readonly sortOrder: SafeInt['output']
      readonly feePercent: Float['output']
    }>
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
        readonly nickname: String['output'] | null
        readonly type: CompanyType
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly expectedNumberOfProjects: SafeInt['output'] | null
        readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
        readonly allowLumpSumOverbilling: Boolean['output']
        readonly notaryTags: ReadonlyArray<NotaryTag>
        readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
        readonly isDemoCompany: Boolean['output']
        readonly picture: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notaryStamp: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly secondarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly locations: ReadonlyArray<{
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        }>
        readonly metadata: {
          readonly __typename: 'CompanyMetadata'
          readonly subtrade: String['output'] | null
          readonly domains: ReadonlyArray<String['output']>
          readonly domainSignup: Boolean['output']
          readonly monthlyCreatePayAppReminderDate: Int['output'] | null
        }
        readonly defaultQuickBillingTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
        } | null
        readonly sitelinePointOfContact: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
        } | null
      }
    }
  }
}

export type RetractPayAppMutationVariables = Exact<{
  payAppId: Scalars['ID']['input']
}>

export type RetractPayAppMutation = {
  readonly __typename: 'Mutation'
  readonly retractPayApp: {
    readonly __typename: 'PayApp'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly timeZone: String['output']
    readonly status: PayAppStatus
    readonly retentionOnly: Boolean['output']
    readonly billingStart: DateTime['output']
    readonly billingEnd: DateTime['output']
    readonly payAppDueDate: DateTime['output']
    readonly currentBilled: SafeInt['output']
    readonly currentRetention: SafeInt['output']
    readonly totalRetention: SafeInt['output']
    readonly previousRetentionBilled: SafeInt['output']
    readonly progress: ReadonlyArray<{
      readonly __typename: 'SovLineItemProgress'
      readonly id: ID['output']
      readonly currentBilled: SafeInt['output']
      readonly progressBilled: SafeInt['output']
      readonly currentRetention: SafeInt['output']
      readonly previousBilled: SafeInt['output']
      readonly storedMaterialBilled: SafeInt['output']
      readonly previousRetention: SafeInt['output']
      readonly previousRetentionBilled: SafeInt['output']
      readonly totalValue: SafeInt['output']
      readonly sovLineItem: {
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
        readonly code: String['output']
        readonly sortOrder: SafeInt['output']
        readonly name: String['output']
        readonly isChangeOrder: Boolean['output']
        readonly changeOrderApprovedDate: DateTime['output'] | null
        readonly latestTotalValue: SafeInt['output']
        readonly totalBilled: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly previousStoredMaterials: SafeInt['output']
        readonly totalRetention: SafeInt['output']
        readonly progressComplete: Float['output']
        readonly defaultRetentionPercent: Float['output'] | null
        readonly sovLineItemGroup: {
          readonly __typename: 'SovLineItemGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly code: String['output'] | null
        } | null
      }
      readonly updatedBy: {
        readonly __typename: 'User'
        readonly blockedEmails: ReadonlyArray<EmailType>
        readonly blockedNotifications: ReadonlyArray<NotificationType>
        readonly policiesAcceptedAt: DateTime['output'] | null
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
        readonly jobTitle: String['output'] | null
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly companies: ReadonlyArray<{
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
          readonly nickname: String['output'] | null
          readonly type: CompanyType
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly expectedNumberOfProjects: SafeInt['output'] | null
          readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
          readonly allowLumpSumOverbilling: Boolean['output']
          readonly notaryTags: ReadonlyArray<NotaryTag>
          readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
          readonly isDemoCompany: Boolean['output']
          readonly picture: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly notaryStamp: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly notarySignature: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly secondarySignature: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly locations: ReadonlyArray<{
            readonly __typename: 'Location'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly nickname: String['output'] | null
            readonly street1: String['output'] | null
            readonly street2: String['output'] | null
            readonly city: String['output']
            readonly county: String['output'] | null
            readonly state: String['output']
            readonly country: String['output']
            readonly postalCode: String['output'] | null
            readonly timeZone: String['output']
            readonly coordinates: LatLng['output']
          }>
          readonly metadata: {
            readonly __typename: 'CompanyMetadata'
            readonly subtrade: String['output'] | null
            readonly domains: ReadonlyArray<String['output']>
            readonly domainSignup: Boolean['output']
            readonly monthlyCreatePayAppReminderDate: Int['output'] | null
          }
          readonly defaultQuickBillingTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
          } | null
          readonly sitelinePointOfContact: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
          } | null
        }>
        readonly companyUsers: ReadonlyArray<{
          readonly __typename: 'CompanyUser'
          readonly id: ID['output']
          readonly status: UserStatus
          readonly role: CompanyUserRole
          readonly company: {
            readonly __typename: 'Company'
            readonly id: ID['output']
            readonly name: String['output']
            readonly nickname: String['output'] | null
            readonly type: CompanyType
            readonly phoneNumber: PhoneNumber['output'] | null
            readonly expectedNumberOfProjects: SafeInt['output'] | null
            readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
            readonly allowLumpSumOverbilling: Boolean['output']
            readonly notaryTags: ReadonlyArray<NotaryTag>
            readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
            readonly isDemoCompany: Boolean['output']
            readonly picture: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly notaryStamp: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly notarySignature: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly secondarySignature: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly locations: ReadonlyArray<{
              readonly __typename: 'Location'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly nickname: String['output'] | null
              readonly street1: String['output'] | null
              readonly street2: String['output'] | null
              readonly city: String['output']
              readonly county: String['output'] | null
              readonly state: String['output']
              readonly country: String['output']
              readonly postalCode: String['output'] | null
              readonly timeZone: String['output']
              readonly coordinates: LatLng['output']
            }>
            readonly metadata: {
              readonly __typename: 'CompanyMetadata'
              readonly subtrade: String['output'] | null
              readonly domains: ReadonlyArray<String['output']>
              readonly domainSignup: Boolean['output']
              readonly monthlyCreatePayAppReminderDate: Int['output'] | null
            }
            readonly defaultQuickBillingTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
            } | null
            readonly sitelinePointOfContact: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            } | null
          }
        }>
      } | null
    }>
    readonly amountLineItems: ReadonlyArray<{
      readonly __typename: 'AmountLineItem'
      readonly id: ID['output']
      readonly description: String['output']
      readonly amount: SafeInt['output']
      readonly sortOrder: SafeInt['output']
      readonly feePercent: Float['output']
    }>
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
        readonly nickname: String['output'] | null
        readonly type: CompanyType
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly expectedNumberOfProjects: SafeInt['output'] | null
        readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
        readonly allowLumpSumOverbilling: Boolean['output']
        readonly notaryTags: ReadonlyArray<NotaryTag>
        readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
        readonly isDemoCompany: Boolean['output']
        readonly picture: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notaryStamp: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly secondarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly locations: ReadonlyArray<{
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        }>
        readonly metadata: {
          readonly __typename: 'CompanyMetadata'
          readonly subtrade: String['output'] | null
          readonly domains: ReadonlyArray<String['output']>
          readonly domainSignup: Boolean['output']
          readonly monthlyCreatePayAppReminderDate: Int['output'] | null
        }
        readonly defaultQuickBillingTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
        } | null
        readonly sitelinePointOfContact: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
        } | null
      }
    }
  }
}

export type SetPayAppSyncedMutationVariables = Exact<{
  input: SetPayAppSyncedInput
}>

export type SetPayAppSyncedMutation = {
  readonly __typename: 'Mutation'
  readonly setPayAppSynced: {
    readonly __typename: 'PayApp'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly timeZone: String['output']
    readonly status: PayAppStatus
    readonly retentionOnly: Boolean['output']
    readonly billingStart: DateTime['output']
    readonly billingEnd: DateTime['output']
    readonly payAppDueDate: DateTime['output']
    readonly currentBilled: SafeInt['output']
    readonly currentRetention: SafeInt['output']
    readonly totalRetention: SafeInt['output']
    readonly previousRetentionBilled: SafeInt['output']
    readonly progress: ReadonlyArray<{
      readonly __typename: 'SovLineItemProgress'
      readonly id: ID['output']
      readonly currentBilled: SafeInt['output']
      readonly progressBilled: SafeInt['output']
      readonly currentRetention: SafeInt['output']
      readonly previousBilled: SafeInt['output']
      readonly storedMaterialBilled: SafeInt['output']
      readonly previousRetention: SafeInt['output']
      readonly previousRetentionBilled: SafeInt['output']
      readonly totalValue: SafeInt['output']
      readonly sovLineItem: {
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
        readonly code: String['output']
        readonly sortOrder: SafeInt['output']
        readonly name: String['output']
        readonly isChangeOrder: Boolean['output']
        readonly changeOrderApprovedDate: DateTime['output'] | null
        readonly latestTotalValue: SafeInt['output']
        readonly totalBilled: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly previousStoredMaterials: SafeInt['output']
        readonly totalRetention: SafeInt['output']
        readonly progressComplete: Float['output']
        readonly defaultRetentionPercent: Float['output'] | null
        readonly sovLineItemGroup: {
          readonly __typename: 'SovLineItemGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly code: String['output'] | null
        } | null
      }
      readonly updatedBy: {
        readonly __typename: 'User'
        readonly blockedEmails: ReadonlyArray<EmailType>
        readonly blockedNotifications: ReadonlyArray<NotificationType>
        readonly policiesAcceptedAt: DateTime['output'] | null
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
        readonly jobTitle: String['output'] | null
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly companies: ReadonlyArray<{
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
          readonly nickname: String['output'] | null
          readonly type: CompanyType
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly expectedNumberOfProjects: SafeInt['output'] | null
          readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
          readonly allowLumpSumOverbilling: Boolean['output']
          readonly notaryTags: ReadonlyArray<NotaryTag>
          readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
          readonly isDemoCompany: Boolean['output']
          readonly picture: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly notaryStamp: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly notarySignature: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly secondarySignature: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly locations: ReadonlyArray<{
            readonly __typename: 'Location'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly nickname: String['output'] | null
            readonly street1: String['output'] | null
            readonly street2: String['output'] | null
            readonly city: String['output']
            readonly county: String['output'] | null
            readonly state: String['output']
            readonly country: String['output']
            readonly postalCode: String['output'] | null
            readonly timeZone: String['output']
            readonly coordinates: LatLng['output']
          }>
          readonly metadata: {
            readonly __typename: 'CompanyMetadata'
            readonly subtrade: String['output'] | null
            readonly domains: ReadonlyArray<String['output']>
            readonly domainSignup: Boolean['output']
            readonly monthlyCreatePayAppReminderDate: Int['output'] | null
          }
          readonly defaultQuickBillingTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
          } | null
          readonly sitelinePointOfContact: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
          } | null
        }>
        readonly companyUsers: ReadonlyArray<{
          readonly __typename: 'CompanyUser'
          readonly id: ID['output']
          readonly status: UserStatus
          readonly role: CompanyUserRole
          readonly company: {
            readonly __typename: 'Company'
            readonly id: ID['output']
            readonly name: String['output']
            readonly nickname: String['output'] | null
            readonly type: CompanyType
            readonly phoneNumber: PhoneNumber['output'] | null
            readonly expectedNumberOfProjects: SafeInt['output'] | null
            readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
            readonly allowLumpSumOverbilling: Boolean['output']
            readonly notaryTags: ReadonlyArray<NotaryTag>
            readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
            readonly isDemoCompany: Boolean['output']
            readonly picture: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly notaryStamp: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly notarySignature: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly secondarySignature: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly locations: ReadonlyArray<{
              readonly __typename: 'Location'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly nickname: String['output'] | null
              readonly street1: String['output'] | null
              readonly street2: String['output'] | null
              readonly city: String['output']
              readonly county: String['output'] | null
              readonly state: String['output']
              readonly country: String['output']
              readonly postalCode: String['output'] | null
              readonly timeZone: String['output']
              readonly coordinates: LatLng['output']
            }>
            readonly metadata: {
              readonly __typename: 'CompanyMetadata'
              readonly subtrade: String['output'] | null
              readonly domains: ReadonlyArray<String['output']>
              readonly domainSignup: Boolean['output']
              readonly monthlyCreatePayAppReminderDate: Int['output'] | null
            }
            readonly defaultQuickBillingTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
            } | null
            readonly sitelinePointOfContact: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            } | null
          }
        }>
      } | null
    }>
    readonly amountLineItems: ReadonlyArray<{
      readonly __typename: 'AmountLineItem'
      readonly id: ID['output']
      readonly description: String['output']
      readonly amount: SafeInt['output']
      readonly sortOrder: SafeInt['output']
      readonly feePercent: Float['output']
    }>
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
        readonly nickname: String['output'] | null
        readonly type: CompanyType
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly expectedNumberOfProjects: SafeInt['output'] | null
        readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
        readonly allowLumpSumOverbilling: Boolean['output']
        readonly notaryTags: ReadonlyArray<NotaryTag>
        readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
        readonly isDemoCompany: Boolean['output']
        readonly picture: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notaryStamp: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly secondarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly locations: ReadonlyArray<{
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        }>
        readonly metadata: {
          readonly __typename: 'CompanyMetadata'
          readonly subtrade: String['output'] | null
          readonly domains: ReadonlyArray<String['output']>
          readonly domainSignup: Boolean['output']
          readonly monthlyCreatePayAppReminderDate: Int['output'] | null
        }
        readonly defaultQuickBillingTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
        } | null
        readonly sitelinePointOfContact: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
        } | null
      }
    }
  }
}

export type DeletePayAppMutationVariables = Exact<{
  payAppId: Scalars['ID']['input']
}>

export type DeletePayAppMutation = {
  readonly __typename: 'Mutation'
  readonly deletePayApp: { readonly __typename: 'DeletionResult'; readonly id: ID['output'] }
}

export type SetPayAppProposedMutationVariables = Exact<{
  input: SetPayAppProposedInput
}>

export type SetPayAppProposedMutation = {
  readonly __typename: 'Mutation'
  readonly setPayAppProposed: {
    readonly __typename: 'PayApp'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly timeZone: String['output']
    readonly status: PayAppStatus
    readonly retentionOnly: Boolean['output']
    readonly billingStart: DateTime['output']
    readonly billingEnd: DateTime['output']
    readonly payAppDueDate: DateTime['output']
    readonly currentBilled: SafeInt['output']
    readonly currentRetention: SafeInt['output']
    readonly totalRetention: SafeInt['output']
    readonly previousRetentionBilled: SafeInt['output']
    readonly progress: ReadonlyArray<{
      readonly __typename: 'SovLineItemProgress'
      readonly id: ID['output']
      readonly currentBilled: SafeInt['output']
      readonly progressBilled: SafeInt['output']
      readonly currentRetention: SafeInt['output']
      readonly previousBilled: SafeInt['output']
      readonly storedMaterialBilled: SafeInt['output']
      readonly previousRetention: SafeInt['output']
      readonly previousRetentionBilled: SafeInt['output']
      readonly totalValue: SafeInt['output']
      readonly sovLineItem: {
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
        readonly code: String['output']
        readonly sortOrder: SafeInt['output']
        readonly name: String['output']
        readonly isChangeOrder: Boolean['output']
        readonly changeOrderApprovedDate: DateTime['output'] | null
        readonly latestTotalValue: SafeInt['output']
        readonly totalBilled: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly previousStoredMaterials: SafeInt['output']
        readonly totalRetention: SafeInt['output']
        readonly progressComplete: Float['output']
        readonly defaultRetentionPercent: Float['output'] | null
        readonly sovLineItemGroup: {
          readonly __typename: 'SovLineItemGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly code: String['output'] | null
        } | null
      }
      readonly updatedBy: {
        readonly __typename: 'User'
        readonly blockedEmails: ReadonlyArray<EmailType>
        readonly blockedNotifications: ReadonlyArray<NotificationType>
        readonly policiesAcceptedAt: DateTime['output'] | null
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
        readonly jobTitle: String['output'] | null
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly companies: ReadonlyArray<{
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
          readonly nickname: String['output'] | null
          readonly type: CompanyType
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly expectedNumberOfProjects: SafeInt['output'] | null
          readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
          readonly allowLumpSumOverbilling: Boolean['output']
          readonly notaryTags: ReadonlyArray<NotaryTag>
          readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
          readonly isDemoCompany: Boolean['output']
          readonly picture: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly notaryStamp: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly notarySignature: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly secondarySignature: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly locations: ReadonlyArray<{
            readonly __typename: 'Location'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly nickname: String['output'] | null
            readonly street1: String['output'] | null
            readonly street2: String['output'] | null
            readonly city: String['output']
            readonly county: String['output'] | null
            readonly state: String['output']
            readonly country: String['output']
            readonly postalCode: String['output'] | null
            readonly timeZone: String['output']
            readonly coordinates: LatLng['output']
          }>
          readonly metadata: {
            readonly __typename: 'CompanyMetadata'
            readonly subtrade: String['output'] | null
            readonly domains: ReadonlyArray<String['output']>
            readonly domainSignup: Boolean['output']
            readonly monthlyCreatePayAppReminderDate: Int['output'] | null
          }
          readonly defaultQuickBillingTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
          } | null
          readonly sitelinePointOfContact: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
          } | null
        }>
        readonly companyUsers: ReadonlyArray<{
          readonly __typename: 'CompanyUser'
          readonly id: ID['output']
          readonly status: UserStatus
          readonly role: CompanyUserRole
          readonly company: {
            readonly __typename: 'Company'
            readonly id: ID['output']
            readonly name: String['output']
            readonly nickname: String['output'] | null
            readonly type: CompanyType
            readonly phoneNumber: PhoneNumber['output'] | null
            readonly expectedNumberOfProjects: SafeInt['output'] | null
            readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
            readonly allowLumpSumOverbilling: Boolean['output']
            readonly notaryTags: ReadonlyArray<NotaryTag>
            readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
            readonly isDemoCompany: Boolean['output']
            readonly picture: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly notaryStamp: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly notarySignature: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly secondarySignature: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly locations: ReadonlyArray<{
              readonly __typename: 'Location'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly nickname: String['output'] | null
              readonly street1: String['output'] | null
              readonly street2: String['output'] | null
              readonly city: String['output']
              readonly county: String['output'] | null
              readonly state: String['output']
              readonly country: String['output']
              readonly postalCode: String['output'] | null
              readonly timeZone: String['output']
              readonly coordinates: LatLng['output']
            }>
            readonly metadata: {
              readonly __typename: 'CompanyMetadata'
              readonly subtrade: String['output'] | null
              readonly domains: ReadonlyArray<String['output']>
              readonly domainSignup: Boolean['output']
              readonly monthlyCreatePayAppReminderDate: Int['output'] | null
            }
            readonly defaultQuickBillingTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
            } | null
            readonly sitelinePointOfContact: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            } | null
          }
        }>
      } | null
    }>
    readonly amountLineItems: ReadonlyArray<{
      readonly __typename: 'AmountLineItem'
      readonly id: ID['output']
      readonly description: String['output']
      readonly amount: SafeInt['output']
      readonly sortOrder: SafeInt['output']
      readonly feePercent: Float['output']
    }>
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
        readonly nickname: String['output'] | null
        readonly type: CompanyType
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly expectedNumberOfProjects: SafeInt['output'] | null
        readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
        readonly allowLumpSumOverbilling: Boolean['output']
        readonly notaryTags: ReadonlyArray<NotaryTag>
        readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
        readonly isDemoCompany: Boolean['output']
        readonly picture: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notaryStamp: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly secondarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly locations: ReadonlyArray<{
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        }>
        readonly metadata: {
          readonly __typename: 'CompanyMetadata'
          readonly subtrade: String['output'] | null
          readonly domains: ReadonlyArray<String['output']>
          readonly domainSignup: Boolean['output']
          readonly monthlyCreatePayAppReminderDate: Int['output'] | null
        }
        readonly defaultQuickBillingTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
        } | null
        readonly sitelinePointOfContact: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
        } | null
      }
    }
  }
}

export type UpdatePayAppMutationVariables = Exact<{
  input: UpdatePayAppInput
}>

export type UpdatePayAppMutation = {
  readonly __typename: 'Mutation'
  readonly updatePayApp: {
    readonly __typename: 'PayApp'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly timeZone: String['output']
    readonly status: PayAppStatus
    readonly retentionOnly: Boolean['output']
    readonly billingStart: DateTime['output']
    readonly billingEnd: DateTime['output']
    readonly payAppDueDate: DateTime['output']
    readonly currentBilled: SafeInt['output']
    readonly currentRetention: SafeInt['output']
    readonly totalRetention: SafeInt['output']
    readonly previousRetentionBilled: SafeInt['output']
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly name: String['output']
        readonly generalContractor: {
          readonly __typename: 'CompanyProjectMetadata'
          readonly company: {
            readonly __typename: 'Company'
            readonly id: ID['output']
            readonly name: String['output']
            readonly nickname: String['output'] | null
            readonly type: CompanyType
            readonly phoneNumber: PhoneNumber['output'] | null
            readonly expectedNumberOfProjects: SafeInt['output'] | null
            readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
            readonly allowLumpSumOverbilling: Boolean['output']
            readonly notaryTags: ReadonlyArray<NotaryTag>
            readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
            readonly isDemoCompany: Boolean['output']
            readonly picture: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly notaryStamp: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly notarySignature: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly secondarySignature: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly locations: ReadonlyArray<{
              readonly __typename: 'Location'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly nickname: String['output'] | null
              readonly street1: String['output'] | null
              readonly street2: String['output'] | null
              readonly city: String['output']
              readonly county: String['output'] | null
              readonly state: String['output']
              readonly country: String['output']
              readonly postalCode: String['output'] | null
              readonly timeZone: String['output']
              readonly coordinates: LatLng['output']
            }>
            readonly metadata: {
              readonly __typename: 'CompanyMetadata'
              readonly subtrade: String['output'] | null
              readonly domains: ReadonlyArray<String['output']>
              readonly domainSignup: Boolean['output']
              readonly monthlyCreatePayAppReminderDate: Int['output'] | null
            }
            readonly defaultQuickBillingTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
            } | null
            readonly sitelinePointOfContact: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            } | null
          }
        } | null
      }
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
        readonly nickname: String['output'] | null
        readonly type: CompanyType
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly expectedNumberOfProjects: SafeInt['output'] | null
        readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
        readonly allowLumpSumOverbilling: Boolean['output']
        readonly notaryTags: ReadonlyArray<NotaryTag>
        readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
        readonly isDemoCompany: Boolean['output']
        readonly picture: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notaryStamp: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly secondarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly locations: ReadonlyArray<{
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        }>
        readonly metadata: {
          readonly __typename: 'CompanyMetadata'
          readonly subtrade: String['output'] | null
          readonly domains: ReadonlyArray<String['output']>
          readonly domainSignup: Boolean['output']
          readonly monthlyCreatePayAppReminderDate: Int['output'] | null
        }
        readonly defaultQuickBillingTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
        } | null
        readonly sitelinePointOfContact: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
        } | null
      }
    }
    readonly progress: ReadonlyArray<{
      readonly __typename: 'SovLineItemProgress'
      readonly id: ID['output']
      readonly currentBilled: SafeInt['output']
      readonly progressBilled: SafeInt['output']
      readonly currentRetention: SafeInt['output']
      readonly previousBilled: SafeInt['output']
      readonly storedMaterialBilled: SafeInt['output']
      readonly previousRetention: SafeInt['output']
      readonly previousRetentionBilled: SafeInt['output']
      readonly totalValue: SafeInt['output']
      readonly sovLineItem: {
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
        readonly code: String['output']
        readonly sortOrder: SafeInt['output']
        readonly name: String['output']
        readonly isChangeOrder: Boolean['output']
        readonly changeOrderApprovedDate: DateTime['output'] | null
        readonly latestTotalValue: SafeInt['output']
        readonly totalBilled: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly previousStoredMaterials: SafeInt['output']
        readonly totalRetention: SafeInt['output']
        readonly progressComplete: Float['output']
        readonly defaultRetentionPercent: Float['output'] | null
        readonly sovLineItemGroup: {
          readonly __typename: 'SovLineItemGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly code: String['output'] | null
        } | null
      }
      readonly updatedBy: {
        readonly __typename: 'User'
        readonly blockedEmails: ReadonlyArray<EmailType>
        readonly blockedNotifications: ReadonlyArray<NotificationType>
        readonly policiesAcceptedAt: DateTime['output'] | null
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
        readonly jobTitle: String['output'] | null
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly companies: ReadonlyArray<{
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
          readonly nickname: String['output'] | null
          readonly type: CompanyType
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly expectedNumberOfProjects: SafeInt['output'] | null
          readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
          readonly allowLumpSumOverbilling: Boolean['output']
          readonly notaryTags: ReadonlyArray<NotaryTag>
          readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
          readonly isDemoCompany: Boolean['output']
          readonly picture: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly notaryStamp: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly notarySignature: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly secondarySignature: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly locations: ReadonlyArray<{
            readonly __typename: 'Location'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly nickname: String['output'] | null
            readonly street1: String['output'] | null
            readonly street2: String['output'] | null
            readonly city: String['output']
            readonly county: String['output'] | null
            readonly state: String['output']
            readonly country: String['output']
            readonly postalCode: String['output'] | null
            readonly timeZone: String['output']
            readonly coordinates: LatLng['output']
          }>
          readonly metadata: {
            readonly __typename: 'CompanyMetadata'
            readonly subtrade: String['output'] | null
            readonly domains: ReadonlyArray<String['output']>
            readonly domainSignup: Boolean['output']
            readonly monthlyCreatePayAppReminderDate: Int['output'] | null
          }
          readonly defaultQuickBillingTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
          } | null
          readonly sitelinePointOfContact: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
          } | null
        }>
        readonly companyUsers: ReadonlyArray<{
          readonly __typename: 'CompanyUser'
          readonly id: ID['output']
          readonly status: UserStatus
          readonly role: CompanyUserRole
          readonly company: {
            readonly __typename: 'Company'
            readonly id: ID['output']
            readonly name: String['output']
            readonly nickname: String['output'] | null
            readonly type: CompanyType
            readonly phoneNumber: PhoneNumber['output'] | null
            readonly expectedNumberOfProjects: SafeInt['output'] | null
            readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
            readonly allowLumpSumOverbilling: Boolean['output']
            readonly notaryTags: ReadonlyArray<NotaryTag>
            readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
            readonly isDemoCompany: Boolean['output']
            readonly picture: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly notaryStamp: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly notarySignature: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly secondarySignature: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly locations: ReadonlyArray<{
              readonly __typename: 'Location'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly nickname: String['output'] | null
              readonly street1: String['output'] | null
              readonly street2: String['output'] | null
              readonly city: String['output']
              readonly county: String['output'] | null
              readonly state: String['output']
              readonly country: String['output']
              readonly postalCode: String['output'] | null
              readonly timeZone: String['output']
              readonly coordinates: LatLng['output']
            }>
            readonly metadata: {
              readonly __typename: 'CompanyMetadata'
              readonly subtrade: String['output'] | null
              readonly domains: ReadonlyArray<String['output']>
              readonly domainSignup: Boolean['output']
              readonly monthlyCreatePayAppReminderDate: Int['output'] | null
            }
            readonly defaultQuickBillingTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
            } | null
            readonly sitelinePointOfContact: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            } | null
          }
        }>
      } | null
    }>
    readonly amountLineItems: ReadonlyArray<{
      readonly __typename: 'AmountLineItem'
      readonly id: ID['output']
      readonly description: String['output']
      readonly amount: SafeInt['output']
      readonly sortOrder: SafeInt['output']
      readonly feePercent: Float['output']
    }>
  }
}

export type ContractsForProcessingQueryVariables = Exact<{
  input: GetPaginatedContractsInput
}>

export type ContractsForProcessingQuery = {
  readonly __typename: 'Query'
  readonly paginatedContracts: {
    readonly __typename: 'PaginatedContractList'
    readonly hasNext: Boolean['output']
    readonly cursor: ID['output'] | null
    readonly totalCount: Int['output']
    readonly contracts: ReadonlyArray<{
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly internalProjectNumber: String['output'] | null
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly projectNumber: String['output']
        readonly name: String['output']
      }
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      }
      readonly onboardedStatus: {
        readonly __typename: 'OnboardedProjectContractStatus'
        readonly selectedPayAppForms: Boolean['output']
        readonly onboardedPayAppForms: Boolean['output']
        readonly selectedPrimaryLienWaivers: Boolean['output']
        readonly onboardedPrimaryLienWaiverForms: Boolean['output']
        readonly selectedVendorLienWaivers: Boolean['output']
        readonly onboardedVendorLienWaiverForms: Boolean['output']
        readonly selectedChangeOrderRequestForms: Boolean['output']
        readonly onboardedChangeOrderRequestForms: Boolean['output']
        readonly selectedChangeOrderLogForms: Boolean['output']
        readonly onboardedChangeOrderLogForms: Boolean['output']
      }
    }>
  }
}

export type GetProjectForDetailsQueryVariables = Exact<{
  projectId: Scalars['ID']['input']
}>

export type GetProjectForDetailsQuery = {
  readonly __typename: 'Query'
  readonly project: {
    readonly __typename: 'Project'
    readonly id: ID['output']
    readonly name: String['output']
    readonly projectNumber: String['output']
    readonly location: {
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    }
    readonly owner: {
      readonly __typename: 'CompanyProjectMetadata'
      readonly role: CompanyProjectRole
      readonly companyName: String['output'] | null
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
        readonly nickname: String['output'] | null
        readonly type: CompanyType
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly expectedNumberOfProjects: SafeInt['output'] | null
        readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
        readonly allowLumpSumOverbilling: Boolean['output']
        readonly notaryTags: ReadonlyArray<NotaryTag>
        readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
        readonly isDemoCompany: Boolean['output']
        readonly users: ReadonlyArray<{
          readonly __typename: 'CompanyUser'
          readonly id: ID['output']
          readonly role: CompanyUserRole
          readonly user: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
            readonly email: EmailAddress['output']
            readonly jobTitle: String['output'] | null
            readonly phoneNumber: PhoneNumber['output'] | null
            readonly companies: ReadonlyArray<{
              readonly __typename: 'Company'
              readonly id: ID['output']
              readonly name: String['output']
            }>
          }
        }>
        readonly picture: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notaryStamp: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly secondarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly locations: ReadonlyArray<{
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        }>
        readonly metadata: {
          readonly __typename: 'CompanyMetadata'
          readonly subtrade: String['output'] | null
          readonly domains: ReadonlyArray<String['output']>
          readonly domainSignup: Boolean['output']
          readonly monthlyCreatePayAppReminderDate: Int['output'] | null
        }
        readonly defaultQuickBillingTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
        } | null
        readonly sitelinePointOfContact: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
        } | null
      }
      readonly selectedAddress: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      } | null
    } | null
    readonly generalContractor: {
      readonly __typename: 'CompanyProjectMetadata'
      readonly role: CompanyProjectRole
      readonly companyName: String['output'] | null
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
        readonly nickname: String['output'] | null
        readonly type: CompanyType
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly expectedNumberOfProjects: SafeInt['output'] | null
        readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
        readonly allowLumpSumOverbilling: Boolean['output']
        readonly notaryTags: ReadonlyArray<NotaryTag>
        readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
        readonly isDemoCompany: Boolean['output']
        readonly users: ReadonlyArray<{
          readonly __typename: 'CompanyUser'
          readonly id: ID['output']
          readonly role: CompanyUserRole
          readonly user: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
            readonly email: EmailAddress['output']
            readonly jobTitle: String['output'] | null
            readonly phoneNumber: PhoneNumber['output'] | null
            readonly companies: ReadonlyArray<{
              readonly __typename: 'Company'
              readonly id: ID['output']
              readonly name: String['output']
            }>
          }
        }>
        readonly picture: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notaryStamp: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly secondarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly locations: ReadonlyArray<{
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        }>
        readonly metadata: {
          readonly __typename: 'CompanyMetadata'
          readonly subtrade: String['output'] | null
          readonly domains: ReadonlyArray<String['output']>
          readonly domainSignup: Boolean['output']
          readonly monthlyCreatePayAppReminderDate: Int['output'] | null
        }
        readonly defaultQuickBillingTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
        } | null
        readonly sitelinePointOfContact: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
        } | null
      }
      readonly selectedAddress: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      } | null
    } | null
    readonly architect: {
      readonly __typename: 'CompanyProjectMetadata'
      readonly role: CompanyProjectRole
      readonly companyName: String['output'] | null
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
        readonly nickname: String['output'] | null
        readonly type: CompanyType
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly expectedNumberOfProjects: SafeInt['output'] | null
        readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
        readonly allowLumpSumOverbilling: Boolean['output']
        readonly notaryTags: ReadonlyArray<NotaryTag>
        readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
        readonly isDemoCompany: Boolean['output']
        readonly picture: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notaryStamp: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly secondarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly locations: ReadonlyArray<{
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        }>
        readonly metadata: {
          readonly __typename: 'CompanyMetadata'
          readonly subtrade: String['output'] | null
          readonly domains: ReadonlyArray<String['output']>
          readonly domainSignup: Boolean['output']
          readonly monthlyCreatePayAppReminderDate: Int['output'] | null
        }
        readonly defaultQuickBillingTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
        } | null
        readonly sitelinePointOfContact: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
        } | null
      }
      readonly selectedAddress: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      } | null
    } | null
    readonly contracts: ReadonlyArray<{
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly role: CompanyProjectRole
      readonly status: ContractStatus
      readonly timeZone: String['output']
      readonly contractDate: DateTime['output'] | null
      readonly contractNumber: String['output'] | null
      readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
      readonly defaultRetentionPercent: Float['output'] | null
      readonly retentionTrackingLevel: RetentionTrackingLevel
      readonly daysBeforePayAppDue: Int['output']
      readonly billingType: BillingType
      readonly contractTemplateVariables: JSONObject['output']
      readonly internalProjectNumber: String['output'] | null
      readonly vendorNumber: String['output'] | null
      readonly pastPayAppCount: Int['output']
      readonly roundRetention: Boolean['output']
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
        readonly nickname: String['output'] | null
        readonly type: CompanyType
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly expectedNumberOfProjects: SafeInt['output'] | null
        readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
        readonly allowLumpSumOverbilling: Boolean['output']
        readonly notaryTags: ReadonlyArray<NotaryTag>
        readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
        readonly isDemoCompany: Boolean['output']
        readonly users: ReadonlyArray<{
          readonly __typename: 'CompanyUser'
          readonly id: ID['output']
          readonly role: CompanyUserRole
          readonly user: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
            readonly email: EmailAddress['output']
            readonly jobTitle: String['output'] | null
            readonly phoneNumber: PhoneNumber['output'] | null
            readonly companies: ReadonlyArray<{
              readonly __typename: 'Company'
              readonly id: ID['output']
              readonly name: String['output']
            }>
          }
        }>
        readonly picture: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notaryStamp: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly secondarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly locations: ReadonlyArray<{
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        }>
        readonly metadata: {
          readonly __typename: 'CompanyMetadata'
          readonly subtrade: String['output'] | null
          readonly domains: ReadonlyArray<String['output']>
          readonly domainSignup: Boolean['output']
          readonly monthlyCreatePayAppReminderDate: Int['output'] | null
        }
        readonly defaultQuickBillingTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
        } | null
        readonly sitelinePointOfContact: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
        } | null
      }
      readonly users: ReadonlyArray<{
        readonly __typename: 'ContractUser'
        readonly id: ID['output']
        readonly user: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
          readonly email: EmailAddress['output']
          readonly jobTitle: String['output'] | null
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly companies: ReadonlyArray<{
            readonly __typename: 'Company'
            readonly id: ID['output']
            readonly name: String['output']
          }>
        }
      }>
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly name: String['output']
        readonly projectNumber: String['output']
        readonly location: {
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        }
        readonly generalContractor: {
          readonly __typename: 'CompanyProjectMetadata'
          readonly role: CompanyProjectRole
          readonly companyName: String['output'] | null
          readonly company: {
            readonly __typename: 'Company'
            readonly id: ID['output']
            readonly name: String['output']
            readonly nickname: String['output'] | null
            readonly type: CompanyType
            readonly phoneNumber: PhoneNumber['output'] | null
            readonly expectedNumberOfProjects: SafeInt['output'] | null
            readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
            readonly allowLumpSumOverbilling: Boolean['output']
            readonly notaryTags: ReadonlyArray<NotaryTag>
            readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
            readonly isDemoCompany: Boolean['output']
            readonly picture: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly notaryStamp: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly notarySignature: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly secondarySignature: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly locations: ReadonlyArray<{
              readonly __typename: 'Location'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly nickname: String['output'] | null
              readonly street1: String['output'] | null
              readonly street2: String['output'] | null
              readonly city: String['output']
              readonly county: String['output'] | null
              readonly state: String['output']
              readonly country: String['output']
              readonly postalCode: String['output'] | null
              readonly timeZone: String['output']
              readonly coordinates: LatLng['output']
            }>
            readonly metadata: {
              readonly __typename: 'CompanyMetadata'
              readonly subtrade: String['output'] | null
              readonly domains: ReadonlyArray<String['output']>
              readonly domainSignup: Boolean['output']
              readonly monthlyCreatePayAppReminderDate: Int['output'] | null
            }
            readonly defaultQuickBillingTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
            } | null
            readonly sitelinePointOfContact: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            } | null
          }
          readonly selectedAddress: {
            readonly __typename: 'Location'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly nickname: String['output'] | null
            readonly street1: String['output'] | null
            readonly street2: String['output'] | null
            readonly city: String['output']
            readonly county: String['output'] | null
            readonly state: String['output']
            readonly country: String['output']
            readonly postalCode: String['output'] | null
            readonly timeZone: String['output']
            readonly coordinates: LatLng['output']
          } | null
        } | null
      }
      readonly sov: {
        readonly __typename: 'Sov'
        readonly id: ID['output']
        readonly totalValue: SafeInt['output']
        readonly totalBilled: SafeInt['output']
        readonly totalRetention: SafeInt['output']
        readonly progressComplete: Float['output']
        readonly contract: {
          readonly __typename: 'Contract'
          readonly id: ID['output']
          readonly timeZone: String['output']
        }
        readonly lineItems: ReadonlyArray<{
          readonly __typename: 'SovLineItem'
          readonly id: ID['output']
          readonly code: String['output']
          readonly sortOrder: SafeInt['output']
          readonly name: String['output']
          readonly isChangeOrder: Boolean['output']
          readonly changeOrderApprovedDate: DateTime['output'] | null
          readonly latestTotalValue: SafeInt['output']
          readonly totalBilled: SafeInt['output']
          readonly previousBilled: SafeInt['output']
          readonly previousStoredMaterials: SafeInt['output']
          readonly totalRetention: SafeInt['output']
          readonly progressComplete: Float['output']
          readonly defaultRetentionPercent: Float['output'] | null
          readonly sovLineItemGroup: {
            readonly __typename: 'SovLineItemGroup'
            readonly id: ID['output']
            readonly name: String['output']
            readonly code: String['output'] | null
          } | null
        }>
      } | null
      readonly lienWaiverTemplates: {
        readonly __typename: 'SubcontractorLienWaiverTemplateSet'
        readonly id: ID['output']
        readonly conditionalFinalVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly conditionalProgressVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly unconditionalFinalVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly unconditionalProgressVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
      } | null
      readonly lowerTierLienWaiverTemplates: {
        readonly __typename: 'SubcontractorLienWaiverTemplateSet'
        readonly id: ID['output']
        readonly conditionalFinalVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly conditionalProgressVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly unconditionalFinalVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly unconditionalProgressVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
      } | null
      readonly payAppRequirementGroups: ReadonlyArray<{
        readonly __typename: 'PayAppRequirementGroup'
        readonly id: ID['output']
        readonly order: Int['output']
        readonly payAppRequirements: ReadonlyArray<{
          readonly __typename: 'PayAppRequirement'
          readonly id: ID['output']
          readonly groupOrder: Int['output']
          readonly conditions: ReadonlyArray<PayAppRequirementCondition>
          readonly templateVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly userVisibleName: String['output'] | null
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly isCustomerReady: Boolean['output']
            }
          } | null
        }>
      }>
      readonly legalRequirements: ReadonlyArray<{
        readonly __typename: 'LegalRequirement'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly name: String['output']
        readonly timeZone: String['output']
        readonly expirationFrequency: DocumentExpirationFrequency
        readonly startDate: DateTime['output'] | null
        readonly endDate: DateTime['output'] | null
        readonly isVendorRequirement: Boolean['output']
        readonly documents: ReadonlyArray<{
          readonly __typename: 'LegalDocument'
          readonly id: ID['output']
        }>
        readonly formTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
        } | null
      }>
      readonly selectedAddress: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }
      readonly changeOrderRequestTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
      } | null
      readonly changeOrderLogTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
      } | null
      readonly integrations: ReadonlyArray<{
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly type: IntegrationType
        readonly credentialsUpdatedAt: DateTime['output'] | null
        readonly mappings: JSONObject['output']
        readonly isActive: Boolean['output']
        readonly shortName: String['output']
        readonly longName: String['output']
        readonly companyIntegration: {
          readonly __typename: 'CompanyIntegration'
          readonly id: ID['output']
        }
      }>
      readonly onboardedStatus: {
        readonly __typename: 'OnboardedProjectContractStatus'
        readonly selectedPayAppForms: Boolean['output']
        readonly onboardedPayAppForms: Boolean['output']
        readonly selectedPrimaryLienWaivers: Boolean['output']
        readonly onboardedPrimaryLienWaiverForms: Boolean['output']
        readonly selectedVendorLienWaivers: Boolean['output']
        readonly onboardedVendorLienWaiverForms: Boolean['output']
        readonly selectedChangeOrderRequestForms: Boolean['output']
        readonly onboardedChangeOrderRequestForms: Boolean['output']
        readonly addedSov: Boolean['output']
        readonly selectedRateTable: Boolean['output']
        readonly addedTeammates: Boolean['output']
        readonly addedGcContacts: Boolean['output']
        readonly startedBilling: Boolean['output']
        readonly notifiedOnboardedForms: Boolean['output']
      }
    }>
    readonly metadata: {
      readonly __typename: 'ProjectMetadata'
      readonly fieldProgressDueOnDayOfMonth: Int['output']
      readonly payAppDueOnDayOfMonth: Int['output']
    }
  }
}

export type UpdateProjectMutationVariables = Exact<{
  input: UpdateProjectInput
}>

export type UpdateProjectMutation = {
  readonly __typename: 'Mutation'
  readonly updateProject: {
    readonly __typename: 'Project'
    readonly id: ID['output']
    readonly name: String['output']
    readonly projectNumber: String['output']
    readonly location: {
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    }
    readonly owner: {
      readonly __typename: 'CompanyProjectMetadata'
      readonly role: CompanyProjectRole
      readonly companyName: String['output'] | null
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
        readonly nickname: String['output'] | null
        readonly type: CompanyType
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly expectedNumberOfProjects: SafeInt['output'] | null
        readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
        readonly allowLumpSumOverbilling: Boolean['output']
        readonly notaryTags: ReadonlyArray<NotaryTag>
        readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
        readonly isDemoCompany: Boolean['output']
        readonly users: ReadonlyArray<{
          readonly __typename: 'CompanyUser'
          readonly id: ID['output']
          readonly role: CompanyUserRole
          readonly user: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
            readonly email: EmailAddress['output']
            readonly jobTitle: String['output'] | null
            readonly phoneNumber: PhoneNumber['output'] | null
            readonly companies: ReadonlyArray<{
              readonly __typename: 'Company'
              readonly id: ID['output']
              readonly name: String['output']
            }>
          }
        }>
        readonly picture: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notaryStamp: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly secondarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly locations: ReadonlyArray<{
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        }>
        readonly metadata: {
          readonly __typename: 'CompanyMetadata'
          readonly subtrade: String['output'] | null
          readonly domains: ReadonlyArray<String['output']>
          readonly domainSignup: Boolean['output']
          readonly monthlyCreatePayAppReminderDate: Int['output'] | null
        }
        readonly defaultQuickBillingTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
        } | null
        readonly sitelinePointOfContact: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
        } | null
      }
      readonly selectedAddress: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      } | null
    } | null
    readonly generalContractor: {
      readonly __typename: 'CompanyProjectMetadata'
      readonly role: CompanyProjectRole
      readonly companyName: String['output'] | null
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
        readonly nickname: String['output'] | null
        readonly type: CompanyType
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly expectedNumberOfProjects: SafeInt['output'] | null
        readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
        readonly allowLumpSumOverbilling: Boolean['output']
        readonly notaryTags: ReadonlyArray<NotaryTag>
        readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
        readonly isDemoCompany: Boolean['output']
        readonly users: ReadonlyArray<{
          readonly __typename: 'CompanyUser'
          readonly id: ID['output']
          readonly role: CompanyUserRole
          readonly user: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
            readonly email: EmailAddress['output']
            readonly jobTitle: String['output'] | null
            readonly phoneNumber: PhoneNumber['output'] | null
            readonly companies: ReadonlyArray<{
              readonly __typename: 'Company'
              readonly id: ID['output']
              readonly name: String['output']
            }>
          }
        }>
        readonly picture: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notaryStamp: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly secondarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly locations: ReadonlyArray<{
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        }>
        readonly metadata: {
          readonly __typename: 'CompanyMetadata'
          readonly subtrade: String['output'] | null
          readonly domains: ReadonlyArray<String['output']>
          readonly domainSignup: Boolean['output']
          readonly monthlyCreatePayAppReminderDate: Int['output'] | null
        }
        readonly defaultQuickBillingTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
        } | null
        readonly sitelinePointOfContact: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
        } | null
      }
      readonly selectedAddress: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      } | null
    } | null
    readonly architect: {
      readonly __typename: 'CompanyProjectMetadata'
      readonly role: CompanyProjectRole
      readonly companyName: String['output'] | null
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
        readonly nickname: String['output'] | null
        readonly type: CompanyType
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly expectedNumberOfProjects: SafeInt['output'] | null
        readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
        readonly allowLumpSumOverbilling: Boolean['output']
        readonly notaryTags: ReadonlyArray<NotaryTag>
        readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
        readonly isDemoCompany: Boolean['output']
        readonly picture: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notaryStamp: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly secondarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly locations: ReadonlyArray<{
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        }>
        readonly metadata: {
          readonly __typename: 'CompanyMetadata'
          readonly subtrade: String['output'] | null
          readonly domains: ReadonlyArray<String['output']>
          readonly domainSignup: Boolean['output']
          readonly monthlyCreatePayAppReminderDate: Int['output'] | null
        }
        readonly defaultQuickBillingTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
        } | null
        readonly sitelinePointOfContact: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
        } | null
      }
      readonly selectedAddress: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      } | null
    } | null
    readonly contracts: ReadonlyArray<{
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly role: CompanyProjectRole
      readonly status: ContractStatus
      readonly timeZone: String['output']
      readonly contractDate: DateTime['output'] | null
      readonly contractNumber: String['output'] | null
      readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
      readonly defaultRetentionPercent: Float['output'] | null
      readonly retentionTrackingLevel: RetentionTrackingLevel
      readonly daysBeforePayAppDue: Int['output']
      readonly billingType: BillingType
      readonly contractTemplateVariables: JSONObject['output']
      readonly internalProjectNumber: String['output'] | null
      readonly vendorNumber: String['output'] | null
      readonly pastPayAppCount: Int['output']
      readonly roundRetention: Boolean['output']
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
        readonly nickname: String['output'] | null
        readonly type: CompanyType
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly expectedNumberOfProjects: SafeInt['output'] | null
        readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
        readonly allowLumpSumOverbilling: Boolean['output']
        readonly notaryTags: ReadonlyArray<NotaryTag>
        readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
        readonly isDemoCompany: Boolean['output']
        readonly users: ReadonlyArray<{
          readonly __typename: 'CompanyUser'
          readonly id: ID['output']
          readonly role: CompanyUserRole
          readonly user: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
            readonly email: EmailAddress['output']
            readonly jobTitle: String['output'] | null
            readonly phoneNumber: PhoneNumber['output'] | null
            readonly companies: ReadonlyArray<{
              readonly __typename: 'Company'
              readonly id: ID['output']
              readonly name: String['output']
            }>
          }
        }>
        readonly picture: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notaryStamp: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly secondarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly locations: ReadonlyArray<{
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        }>
        readonly metadata: {
          readonly __typename: 'CompanyMetadata'
          readonly subtrade: String['output'] | null
          readonly domains: ReadonlyArray<String['output']>
          readonly domainSignup: Boolean['output']
          readonly monthlyCreatePayAppReminderDate: Int['output'] | null
        }
        readonly defaultQuickBillingTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
        } | null
        readonly sitelinePointOfContact: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
        } | null
      }
      readonly users: ReadonlyArray<{
        readonly __typename: 'ContractUser'
        readonly id: ID['output']
        readonly user: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
          readonly email: EmailAddress['output']
          readonly jobTitle: String['output'] | null
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly companies: ReadonlyArray<{
            readonly __typename: 'Company'
            readonly id: ID['output']
            readonly name: String['output']
          }>
        }
      }>
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly name: String['output']
        readonly projectNumber: String['output']
        readonly location: {
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        }
        readonly generalContractor: {
          readonly __typename: 'CompanyProjectMetadata'
          readonly role: CompanyProjectRole
          readonly companyName: String['output'] | null
          readonly company: {
            readonly __typename: 'Company'
            readonly id: ID['output']
            readonly name: String['output']
            readonly nickname: String['output'] | null
            readonly type: CompanyType
            readonly phoneNumber: PhoneNumber['output'] | null
            readonly expectedNumberOfProjects: SafeInt['output'] | null
            readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
            readonly allowLumpSumOverbilling: Boolean['output']
            readonly notaryTags: ReadonlyArray<NotaryTag>
            readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
            readonly isDemoCompany: Boolean['output']
            readonly picture: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly notaryStamp: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly notarySignature: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly secondarySignature: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
            readonly locations: ReadonlyArray<{
              readonly __typename: 'Location'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly nickname: String['output'] | null
              readonly street1: String['output'] | null
              readonly street2: String['output'] | null
              readonly city: String['output']
              readonly county: String['output'] | null
              readonly state: String['output']
              readonly country: String['output']
              readonly postalCode: String['output'] | null
              readonly timeZone: String['output']
              readonly coordinates: LatLng['output']
            }>
            readonly metadata: {
              readonly __typename: 'CompanyMetadata'
              readonly subtrade: String['output'] | null
              readonly domains: ReadonlyArray<String['output']>
              readonly domainSignup: Boolean['output']
              readonly monthlyCreatePayAppReminderDate: Int['output'] | null
            }
            readonly defaultQuickBillingTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
            } | null
            readonly sitelinePointOfContact: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
            } | null
          }
          readonly selectedAddress: {
            readonly __typename: 'Location'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly nickname: String['output'] | null
            readonly street1: String['output'] | null
            readonly street2: String['output'] | null
            readonly city: String['output']
            readonly county: String['output'] | null
            readonly state: String['output']
            readonly country: String['output']
            readonly postalCode: String['output'] | null
            readonly timeZone: String['output']
            readonly coordinates: LatLng['output']
          } | null
        } | null
      }
      readonly sov: {
        readonly __typename: 'Sov'
        readonly id: ID['output']
        readonly totalValue: SafeInt['output']
        readonly totalBilled: SafeInt['output']
        readonly totalRetention: SafeInt['output']
        readonly progressComplete: Float['output']
        readonly contract: {
          readonly __typename: 'Contract'
          readonly id: ID['output']
          readonly timeZone: String['output']
        }
        readonly lineItems: ReadonlyArray<{
          readonly __typename: 'SovLineItem'
          readonly id: ID['output']
          readonly code: String['output']
          readonly sortOrder: SafeInt['output']
          readonly name: String['output']
          readonly isChangeOrder: Boolean['output']
          readonly changeOrderApprovedDate: DateTime['output'] | null
          readonly latestTotalValue: SafeInt['output']
          readonly totalBilled: SafeInt['output']
          readonly previousBilled: SafeInt['output']
          readonly previousStoredMaterials: SafeInt['output']
          readonly totalRetention: SafeInt['output']
          readonly progressComplete: Float['output']
          readonly defaultRetentionPercent: Float['output'] | null
          readonly sovLineItemGroup: {
            readonly __typename: 'SovLineItemGroup'
            readonly id: ID['output']
            readonly name: String['output']
            readonly code: String['output'] | null
          } | null
        }>
      } | null
      readonly lienWaiverTemplates: {
        readonly __typename: 'SubcontractorLienWaiverTemplateSet'
        readonly id: ID['output']
        readonly conditionalFinalVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly conditionalProgressVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly unconditionalFinalVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly unconditionalProgressVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
      } | null
      readonly lowerTierLienWaiverTemplates: {
        readonly __typename: 'SubcontractorLienWaiverTemplateSet'
        readonly id: ID['output']
        readonly conditionalFinalVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly conditionalProgressVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly unconditionalFinalVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
        readonly unconditionalProgressVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly createdAt: DateTime['output']
            readonly type: FormTemplateType
            readonly status: FormTemplateStatus
            readonly skippedValidation: Boolean['output']
            readonly userVisibleName: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly tags: ReadonlyArray<FormTemplateTag>
            readonly dueDate: DateTime['output'] | null
            readonly statusLogs: ReadonlyArray<{
              readonly __typename: 'FormTemplateStatusLog'
              readonly id: ID['output']
              readonly statusUpdatedAt: DateTime['output']
              readonly status: FormTemplateStatus
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly associatedCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly requestingCompany: {
              readonly __typename: 'Company'
              readonly id: ID['output']
            } | null
            readonly associatedContracts: ReadonlyArray<{
              readonly __typename: 'FormTemplateAssociatedContract'
              readonly id: String['output']
              readonly providedAsFormType: ProjectOnboardingFormType | null
              readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
            }>
            readonly owner: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly builder: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            }
            readonly validator: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly email: EmailAddress['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
            } | null
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly versionNumber: Int['output']
              readonly isReadyForManualStoredMaterials: Boolean['output']
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly type: StoredFileType
                readonly url: URL['output']
                readonly name: String['output']
              }
              readonly annotations: ReadonlyArray<{
                readonly __typename: 'FormTemplateAnnotation'
                readonly id: ID['output']
                readonly permanentId: ID['output']
                readonly syncTag: String['output'] | null
                readonly userVisibleName: String['output']
                readonly type: FormTemplateAnnotationType
                readonly xStart: Float['output']
                readonly yStart: Float['output']
                readonly width: Float['output']
                readonly height: Float['output']
                readonly pageNumber: Int['output']
                readonly fontFamily: FormTemplateFont
                readonly fontColor: String['output']
                readonly textAlignment: TextAlignment
                readonly wrapText: Boolean['output']
                readonly isOptional: Boolean['output']
                readonly selectedKey: String['output'] | null
                readonly defaultValueKey: String['output'] | null
                readonly prefix: String['output'] | null
                readonly suffix: String['output'] | null
                readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
                readonly doNotRetainOnReset: Boolean['output']
                readonly dynamicFieldTag: String['output'] | null
                readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
                readonly imageType: FormTemplateAnnotationImageType | null
                readonly signatureType: SignatureAnnotationType | null
              }>
            }>
            readonly variants: ReadonlyArray<{
              readonly __typename: 'FormTemplateVariant'
              readonly id: ID['output']
              readonly internalName: String['output']
              readonly isDefaultVariant: Boolean['output']
            }>
            readonly comments: ReadonlyArray<{
              readonly __typename: 'FormTemplateComment'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly message: String['output']
              readonly isSystemGenerated: Boolean['output']
              readonly user: {
                readonly __typename: 'User'
                readonly id: ID['output']
                readonly firstName: String['output']
                readonly lastName: String['output']
                readonly email: EmailAddress['output']
              }
            }>
            readonly originalFile: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
            } | null
            readonly duplicateTemplate: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly userVisibleName: String['output']
            } | null
          }
        } | null
      } | null
      readonly payAppRequirementGroups: ReadonlyArray<{
        readonly __typename: 'PayAppRequirementGroup'
        readonly id: ID['output']
        readonly order: Int['output']
        readonly payAppRequirements: ReadonlyArray<{
          readonly __typename: 'PayAppRequirement'
          readonly id: ID['output']
          readonly groupOrder: Int['output']
          readonly conditions: ReadonlyArray<PayAppRequirementCondition>
          readonly templateVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly internalName: String['output']
            readonly userVisibleName: String['output'] | null
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly isCustomerReady: Boolean['output']
            }
          } | null
        }>
      }>
      readonly legalRequirements: ReadonlyArray<{
        readonly __typename: 'LegalRequirement'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly name: String['output']
        readonly timeZone: String['output']
        readonly expirationFrequency: DocumentExpirationFrequency
        readonly startDate: DateTime['output'] | null
        readonly endDate: DateTime['output'] | null
        readonly isVendorRequirement: Boolean['output']
        readonly documents: ReadonlyArray<{
          readonly __typename: 'LegalDocument'
          readonly id: ID['output']
        }>
        readonly formTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
        } | null
      }>
      readonly selectedAddress: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }
      readonly changeOrderRequestTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
      } | null
      readonly changeOrderLogTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
      } | null
      readonly integrations: ReadonlyArray<{
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly type: IntegrationType
        readonly credentialsUpdatedAt: DateTime['output'] | null
        readonly mappings: JSONObject['output']
        readonly isActive: Boolean['output']
        readonly shortName: String['output']
        readonly longName: String['output']
        readonly companyIntegration: {
          readonly __typename: 'CompanyIntegration'
          readonly id: ID['output']
        }
      }>
      readonly onboardedStatus: {
        readonly __typename: 'OnboardedProjectContractStatus'
        readonly selectedPayAppForms: Boolean['output']
        readonly onboardedPayAppForms: Boolean['output']
        readonly selectedPrimaryLienWaivers: Boolean['output']
        readonly onboardedPrimaryLienWaiverForms: Boolean['output']
        readonly selectedVendorLienWaivers: Boolean['output']
        readonly onboardedVendorLienWaiverForms: Boolean['output']
        readonly selectedChangeOrderRequestForms: Boolean['output']
        readonly onboardedChangeOrderRequestForms: Boolean['output']
        readonly addedSov: Boolean['output']
        readonly selectedRateTable: Boolean['output']
        readonly addedTeammates: Boolean['output']
        readonly addedGcContacts: Boolean['output']
        readonly startedBilling: Boolean['output']
        readonly notifiedOnboardedForms: Boolean['output']
      }
    }>
    readonly metadata: {
      readonly __typename: 'ProjectMetadata'
      readonly fieldProgressDueOnDayOfMonth: Int['output']
      readonly payAppDueOnDayOfMonth: Int['output']
    }
  }
}

export type DeleteProjectMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteProjectMutation = {
  readonly __typename: 'Mutation'
  readonly deleteProject: { readonly __typename: 'DeletionResult'; readonly id: ID['output'] }
}

export type AllProjectUsersByCompanyQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type AllProjectUsersByCompanyQuery = {
  readonly __typename: 'Query'
  readonly allProjectUsersByCompany: ReadonlyArray<{
    readonly __typename: 'CompanyUser'
    readonly id: ID['output']
    readonly role: CompanyUserRole
    readonly isSitelineAdmin: Boolean['output']
    readonly status: UserStatus
    readonly permissions: ReadonlyArray<Permission>
    readonly user: {
      readonly __typename: 'User'
      readonly blockedEmails: ReadonlyArray<EmailType>
      readonly blockedNotifications: ReadonlyArray<NotificationType>
      readonly policiesAcceptedAt: DateTime['output'] | null
      readonly id: ID['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
      readonly email: EmailAddress['output']
      readonly jobTitle: String['output'] | null
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly companies: ReadonlyArray<{
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
        readonly nickname: String['output'] | null
        readonly type: CompanyType
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly expectedNumberOfProjects: SafeInt['output'] | null
        readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
        readonly allowLumpSumOverbilling: Boolean['output']
        readonly notaryTags: ReadonlyArray<NotaryTag>
        readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
        readonly isDemoCompany: Boolean['output']
        readonly picture: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notaryStamp: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly secondarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly locations: ReadonlyArray<{
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        }>
        readonly metadata: {
          readonly __typename: 'CompanyMetadata'
          readonly subtrade: String['output'] | null
          readonly domains: ReadonlyArray<String['output']>
          readonly domainSignup: Boolean['output']
          readonly monthlyCreatePayAppReminderDate: Int['output'] | null
        }
        readonly defaultQuickBillingTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
        } | null
        readonly sitelinePointOfContact: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
        } | null
      }>
      readonly companyUsers: ReadonlyArray<{
        readonly __typename: 'CompanyUser'
        readonly id: ID['output']
        readonly status: UserStatus
        readonly role: CompanyUserRole
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
          readonly nickname: String['output'] | null
          readonly type: CompanyType
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly expectedNumberOfProjects: SafeInt['output'] | null
          readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
          readonly allowLumpSumOverbilling: Boolean['output']
          readonly notaryTags: ReadonlyArray<NotaryTag>
          readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
          readonly isDemoCompany: Boolean['output']
          readonly picture: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly notaryStamp: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly notarySignature: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly secondarySignature: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
          readonly locations: ReadonlyArray<{
            readonly __typename: 'Location'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly nickname: String['output'] | null
            readonly street1: String['output'] | null
            readonly street2: String['output'] | null
            readonly city: String['output']
            readonly county: String['output'] | null
            readonly state: String['output']
            readonly country: String['output']
            readonly postalCode: String['output'] | null
            readonly timeZone: String['output']
            readonly coordinates: LatLng['output']
          }>
          readonly metadata: {
            readonly __typename: 'CompanyMetadata'
            readonly subtrade: String['output'] | null
            readonly domains: ReadonlyArray<String['output']>
            readonly domainSignup: Boolean['output']
            readonly monthlyCreatePayAppReminderDate: Int['output'] | null
          }
          readonly defaultQuickBillingTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
          } | null
          readonly sitelinePointOfContact: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly email: EmailAddress['output']
          } | null
        }
      }>
    }
  }>
}

export type SendCompanyMonthlyReportTestEmailMutationVariables = Exact<{
  input: SendCompanyMonthlyReportTestEmailInput
}>

export type SendCompanyMonthlyReportTestEmailMutation = {
  readonly __typename: 'Mutation'
  readonly sendCompanyMonthlyReportTestEmail: ReadonlyArray<{
    readonly __typename: 'User'
    readonly id: ID['output']
    readonly email: EmailAddress['output']
  }>
}

export type UserEducationCampaignQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type UserEducationCampaignQuery = {
  readonly __typename: 'Query'
  readonly userEducationCampaign: {
    readonly __typename: 'UserEducationCampaign'
    readonly id: ID['output']
    readonly name: String['output']
    readonly startDate: DateTime['output']
    readonly endDate: DateTime['output']
    readonly targetPermissions: ReadonlyArray<Permission>
    readonly showNewTag: Boolean['output']
    readonly title: String['output']
    readonly message: String['output']
    readonly buttonLabel: String['output'] | null
    readonly buttonLink: String['output'] | null
  }
}

export type CreateUserEducationCampaignMutationVariables = Exact<{
  input: CreateUserEducationCampaignInput
}>

export type CreateUserEducationCampaignMutation = {
  readonly __typename: 'Mutation'
  readonly createUserEducationCampaign: {
    readonly __typename: 'UserEducationCampaign'
    readonly id: ID['output']
    readonly name: String['output']
    readonly startDate: DateTime['output']
    readonly endDate: DateTime['output']
    readonly targetPermissions: ReadonlyArray<Permission>
    readonly showNewTag: Boolean['output']
    readonly title: String['output']
    readonly message: String['output']
    readonly buttonLabel: String['output'] | null
    readonly buttonLink: String['output'] | null
  }
}

export type UpdateUserEducationCampaignMutationVariables = Exact<{
  input: UpdateUserEducationCampaignInput
}>

export type UpdateUserEducationCampaignMutation = {
  readonly __typename: 'Mutation'
  readonly updateUserEducationCampaign: {
    readonly __typename: 'UserEducationCampaign'
    readonly id: ID['output']
    readonly name: String['output']
    readonly startDate: DateTime['output']
    readonly endDate: DateTime['output']
    readonly targetPermissions: ReadonlyArray<Permission>
    readonly showNewTag: Boolean['output']
    readonly title: String['output']
    readonly message: String['output']
    readonly buttonLabel: String['output'] | null
    readonly buttonLink: String['output'] | null
  }
}

export type UserEducationCampaignsQueryVariables = Exact<{ [key: string]: never }>

export type UserEducationCampaignsQuery = {
  readonly __typename: 'Query'
  readonly userEducationCampaigns: ReadonlyArray<{
    readonly __typename: 'UserEducationCampaign'
    readonly id: ID['output']
    readonly name: String['output']
    readonly startDate: DateTime['output']
    readonly endDate: DateTime['output']
    readonly targetPermissions: ReadonlyArray<Permission>
    readonly showNewTag: Boolean['output']
    readonly title: String['output']
    readonly message: String['output']
    readonly buttonLabel: String['output'] | null
    readonly buttonLink: String['output'] | null
  }>
}

export type GetUserForDetailsQueryVariables = Exact<{
  userId: Scalars['ID']['input']
}>

export type GetUserForDetailsQuery = {
  readonly __typename: 'Query'
  readonly user: {
    readonly __typename: 'User'
    readonly blockedEmails: ReadonlyArray<EmailType>
    readonly blockedNotifications: ReadonlyArray<NotificationType>
    readonly policiesAcceptedAt: DateTime['output'] | null
    readonly id: ID['output']
    readonly firstName: String['output']
    readonly lastName: String['output']
    readonly email: EmailAddress['output']
    readonly jobTitle: String['output'] | null
    readonly phoneNumber: PhoneNumber['output'] | null
    readonly companyUsers: ReadonlyArray<{
      readonly __typename: 'CompanyUser'
      readonly id: ID['output']
      readonly status: UserStatus
      readonly role: CompanyUserRole
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
        readonly nickname: String['output'] | null
        readonly type: CompanyType
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly expectedNumberOfProjects: SafeInt['output'] | null
        readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
        readonly allowLumpSumOverbilling: Boolean['output']
        readonly notaryTags: ReadonlyArray<NotaryTag>
        readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
        readonly isDemoCompany: Boolean['output']
        readonly picture: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notaryStamp: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly secondarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly locations: ReadonlyArray<{
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        }>
        readonly metadata: {
          readonly __typename: 'CompanyMetadata'
          readonly subtrade: String['output'] | null
          readonly domains: ReadonlyArray<String['output']>
          readonly domainSignup: Boolean['output']
          readonly monthlyCreatePayAppReminderDate: Int['output'] | null
        }
        readonly defaultQuickBillingTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
        } | null
        readonly sitelinePointOfContact: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
        } | null
      }
    }>
    readonly companies: ReadonlyArray<{
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
      readonly nickname: String['output'] | null
      readonly type: CompanyType
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly expectedNumberOfProjects: SafeInt['output'] | null
      readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
      readonly allowLumpSumOverbilling: Boolean['output']
      readonly notaryTags: ReadonlyArray<NotaryTag>
      readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
      readonly isDemoCompany: Boolean['output']
      readonly picture: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly notaryStamp: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly notarySignature: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly secondarySignature: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly locations: ReadonlyArray<{
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }>
      readonly metadata: {
        readonly __typename: 'CompanyMetadata'
        readonly subtrade: String['output'] | null
        readonly domains: ReadonlyArray<String['output']>
        readonly domainSignup: Boolean['output']
        readonly monthlyCreatePayAppReminderDate: Int['output'] | null
      }
      readonly defaultQuickBillingTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
      } | null
      readonly sitelinePointOfContact: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
      } | null
    }>
  }
}

export type ModifyNotificationPreferencesMutationVariables = Exact<{
  input: ModifyNotificationPreferencesInput
}>

export type ModifyNotificationPreferencesMutation = {
  readonly __typename: 'Mutation'
  readonly modifyNotificationPreferences: {
    readonly __typename: 'User'
    readonly blockedEmails: ReadonlyArray<EmailType>
    readonly blockedNotifications: ReadonlyArray<NotificationType>
    readonly policiesAcceptedAt: DateTime['output'] | null
    readonly id: ID['output']
    readonly firstName: String['output']
    readonly lastName: String['output']
    readonly email: EmailAddress['output']
    readonly jobTitle: String['output'] | null
    readonly phoneNumber: PhoneNumber['output'] | null
    readonly companies: ReadonlyArray<{
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
      readonly nickname: String['output'] | null
      readonly type: CompanyType
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly expectedNumberOfProjects: SafeInt['output'] | null
      readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
      readonly allowLumpSumOverbilling: Boolean['output']
      readonly notaryTags: ReadonlyArray<NotaryTag>
      readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
      readonly isDemoCompany: Boolean['output']
      readonly picture: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly notaryStamp: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly notarySignature: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly secondarySignature: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly locations: ReadonlyArray<{
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }>
      readonly metadata: {
        readonly __typename: 'CompanyMetadata'
        readonly subtrade: String['output'] | null
        readonly domains: ReadonlyArray<String['output']>
        readonly domainSignup: Boolean['output']
        readonly monthlyCreatePayAppReminderDate: Int['output'] | null
      }
      readonly defaultQuickBillingTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
      } | null
      readonly sitelinePointOfContact: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
      } | null
    }>
    readonly companyUsers: ReadonlyArray<{
      readonly __typename: 'CompanyUser'
      readonly id: ID['output']
      readonly status: UserStatus
      readonly role: CompanyUserRole
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
        readonly nickname: String['output'] | null
        readonly type: CompanyType
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly expectedNumberOfProjects: SafeInt['output'] | null
        readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
        readonly allowLumpSumOverbilling: Boolean['output']
        readonly notaryTags: ReadonlyArray<NotaryTag>
        readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
        readonly isDemoCompany: Boolean['output']
        readonly picture: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notaryStamp: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly secondarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly locations: ReadonlyArray<{
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        }>
        readonly metadata: {
          readonly __typename: 'CompanyMetadata'
          readonly subtrade: String['output'] | null
          readonly domains: ReadonlyArray<String['output']>
          readonly domainSignup: Boolean['output']
          readonly monthlyCreatePayAppReminderDate: Int['output'] | null
        }
        readonly defaultQuickBillingTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
        } | null
        readonly sitelinePointOfContact: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
        } | null
      }
    }>
  }
}

export type ModifyEmailPreferencesMutationVariables = Exact<{
  input: ModifyEmailPreferencesInput
}>

export type ModifyEmailPreferencesMutation = {
  readonly __typename: 'Mutation'
  readonly modifyEmailPreferences: {
    readonly __typename: 'User'
    readonly blockedEmails: ReadonlyArray<EmailType>
    readonly blockedNotifications: ReadonlyArray<NotificationType>
    readonly policiesAcceptedAt: DateTime['output'] | null
    readonly id: ID['output']
    readonly firstName: String['output']
    readonly lastName: String['output']
    readonly email: EmailAddress['output']
    readonly jobTitle: String['output'] | null
    readonly phoneNumber: PhoneNumber['output'] | null
    readonly companies: ReadonlyArray<{
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
      readonly nickname: String['output'] | null
      readonly type: CompanyType
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly expectedNumberOfProjects: SafeInt['output'] | null
      readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
      readonly allowLumpSumOverbilling: Boolean['output']
      readonly notaryTags: ReadonlyArray<NotaryTag>
      readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
      readonly isDemoCompany: Boolean['output']
      readonly picture: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly notaryStamp: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly notarySignature: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly secondarySignature: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly locations: ReadonlyArray<{
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }>
      readonly metadata: {
        readonly __typename: 'CompanyMetadata'
        readonly subtrade: String['output'] | null
        readonly domains: ReadonlyArray<String['output']>
        readonly domainSignup: Boolean['output']
        readonly monthlyCreatePayAppReminderDate: Int['output'] | null
      }
      readonly defaultQuickBillingTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
      } | null
      readonly sitelinePointOfContact: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
      } | null
    }>
    readonly companyUsers: ReadonlyArray<{
      readonly __typename: 'CompanyUser'
      readonly id: ID['output']
      readonly status: UserStatus
      readonly role: CompanyUserRole
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
        readonly nickname: String['output'] | null
        readonly type: CompanyType
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly expectedNumberOfProjects: SafeInt['output'] | null
        readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
        readonly allowLumpSumOverbilling: Boolean['output']
        readonly notaryTags: ReadonlyArray<NotaryTag>
        readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
        readonly isDemoCompany: Boolean['output']
        readonly picture: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notaryStamp: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly secondarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly locations: ReadonlyArray<{
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        }>
        readonly metadata: {
          readonly __typename: 'CompanyMetadata'
          readonly subtrade: String['output'] | null
          readonly domains: ReadonlyArray<String['output']>
          readonly domainSignup: Boolean['output']
          readonly monthlyCreatePayAppReminderDate: Int['output'] | null
        }
        readonly defaultQuickBillingTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
        } | null
        readonly sitelinePointOfContact: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
        } | null
      }
    }>
  }
}

export type ResendUserInviteMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ResendUserInviteMutation = {
  readonly __typename: 'Mutation'
  readonly resendUserInvite: { readonly __typename: 'User'; readonly id: ID['output'] }
}

export type ProjectsByUserQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ProjectsByUserQuery = {
  readonly __typename: 'Query'
  readonly projectsByUser: ReadonlyArray<{
    readonly __typename: 'Project'
    readonly id: ID['output']
    readonly name: String['output']
    readonly projectNumber: String['output']
    readonly location: {
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    }
    readonly generalContractor: {
      readonly __typename: 'CompanyProjectMetadata'
      readonly role: CompanyProjectRole
      readonly companyName: String['output'] | null
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
        readonly nickname: String['output'] | null
        readonly type: CompanyType
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly expectedNumberOfProjects: SafeInt['output'] | null
        readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
        readonly allowLumpSumOverbilling: Boolean['output']
        readonly notaryTags: ReadonlyArray<NotaryTag>
        readonly storedMaterialsCarryoverType: StoredMaterialsCarryoverType
        readonly isDemoCompany: Boolean['output']
        readonly picture: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notaryStamp: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly notarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly secondarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly locations: ReadonlyArray<{
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        }>
        readonly metadata: {
          readonly __typename: 'CompanyMetadata'
          readonly subtrade: String['output'] | null
          readonly domains: ReadonlyArray<String['output']>
          readonly domainSignup: Boolean['output']
          readonly monthlyCreatePayAppReminderDate: Int['output'] | null
        }
        readonly defaultQuickBillingTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
        } | null
        readonly sitelinePointOfContact: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
        } | null
      }
      readonly selectedAddress: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      } | null
    } | null
  }>
}

export type MarkWriteSyncAsFailedMutationVariables = Exact<{
  input: MarkWriteSyncAsFailedInput
}>

export type MarkWriteSyncAsFailedMutation = {
  readonly __typename: 'Mutation'
  readonly markWriteSyncAsFailed: {
    readonly __typename: 'WriteSyncOperation'
    readonly id: ID['output']
    readonly updatedAt: DateTime['output']
    readonly status: WriteSyncOperationStatus
    readonly result: JSONObject['output'] | null
  }
}

export type PaginatedWriteSyncOperationsQueryVariables = Exact<{
  input: GetPaginatedWriteSyncOperationsInput
}>

export type PaginatedWriteSyncOperationsQuery = {
  readonly __typename: 'Query'
  readonly paginatedWriteSyncOperations: {
    readonly __typename: 'PaginatedWriteSyncOperations'
    readonly hasNext: Boolean['output']
    readonly cursor: ID['output'] | null
    readonly totalCount: Int['output']
    readonly operations: ReadonlyArray<{
      readonly __typename: 'WriteSyncOperation'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly updatedAt: DateTime['output']
      readonly status: WriteSyncOperationStatus
      readonly payload: JSONObject['output']
      readonly result: JSONObject['output'] | null
      readonly createdBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly email: EmailAddress['output']
        readonly firstName: String['output']
      }
      readonly integration: {
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly contract: {
          readonly __typename: 'Contract'
          readonly id: ID['output']
          readonly internalProjectNumber: String['output'] | null
          readonly project: {
            readonly __typename: 'Project'
            readonly id: ID['output']
            readonly name: String['output']
            readonly projectNumber: String['output']
          }
          readonly company: {
            readonly __typename: 'Company'
            readonly id: ID['output']
            readonly name: String['output']
          }
        }
      }
    }>
  }
}

export type RequeueWriteSyncMutationVariables = Exact<{
  input: RequeueWriteSyncInput
}>

export type RequeueWriteSyncMutation = {
  readonly __typename: 'Mutation'
  readonly requeueWriteSync: {
    readonly __typename: 'WriteSyncOperation'
    readonly id: ID['output']
    readonly updatedAt: DateTime['output']
    readonly status: WriteSyncOperationStatus
    readonly result: JSONObject['output'] | null
  }
}

export const AnnotationOverridePropertiesDoc = gql`
  fragment AnnotationOverrideProperties on AnnotationOverride {
    id
    annotationPermanentId
    userVisibleName
    fontFamily
    fontColor
    textAlignment
    wrapText
    isOptional
    selectedKey
    defaultValueKey
    prefix
    suffix
    fieldType
    doNotRetainOnReset
    copyDefaultValueFromPreviousAnnotationValue
    imageType
    signatureType
    syncTag
  }
`
export const FormTemplateVariantPropertiesDoc = gql`
  fragment FormTemplateVariantProperties on FormTemplateVariant {
    id
    internalName
    userVisibleName
    isDefaultVariant
    hidesZeroDollarAmounts
    roundPercentages
    useCompanyNotarySignatureIfAvailable
    annotationOverrides {
      ...AnnotationOverrideProperties
    }
  }
  ${AnnotationOverridePropertiesDoc}
`
export const FormTemplateStatusLogPropertiesDoc = gql`
  fragment FormTemplateStatusLogProperties on FormTemplateStatusLog {
    id
    statusUpdatedAt
    status
    user {
      id
      email
    }
  }
`
export const StoredFilePropertiesDoc = gql`
  fragment StoredFileProperties on StoredFile {
    id
    type
    url
    name
  }
`
export const FormTemplateAnnotationPropertiesDoc = gql`
  fragment FormTemplateAnnotationProperties on FormTemplateAnnotation {
    id
    permanentId
    syncTag
    userVisibleName
    type
    xStart
    yStart
    width
    height
    pageNumber
    fontFamily
    fontColor
    textAlignment
    wrapText
    isOptional
    selectedKey
    defaultValueKey
    prefix
    suffix
    fieldType
    doNotRetainOnReset
    dynamicFieldTag
    copyDefaultValueFromPreviousAnnotationValue
    imageType
    signatureType
  }
`
export const FormTemplateVersionPropertiesDoc = gql`
  fragment FormTemplateVersionProperties on FormTemplateVersion {
    id
    createdAt
    updatedAt
    file {
      ...StoredFileProperties
    }
    annotations {
      ...FormTemplateAnnotationProperties
    }
    versionNumber
    isReadyForManualStoredMaterials
  }
  ${StoredFilePropertiesDoc}
  ${FormTemplateAnnotationPropertiesDoc}
`
export const FormTemplateCommentPropertiesDoc = gql`
  fragment FormTemplateCommentProperties on FormTemplateComment {
    id
    createdAt
    message
    isSystemGenerated
    user {
      id
      firstName
      lastName
      email
    }
  }
`
export const FormTemplatePropertiesDoc = gql`
  fragment FormTemplateProperties on FormTemplate {
    id
    createdAt
    type
    status
    statusLogs {
      ...FormTemplateStatusLogProperties
    }
    skippedValidation
    userVisibleName
    isCustomerReady
    tags
    dueDate
    associatedCompany {
      id
    }
    requestingCompany {
      id
    }
    associatedContracts {
      id
      providedAsFormType
      contract {
        id
      }
    }
    owner {
      id
      email
      firstName
      lastName
    }
    builder {
      id
      email
      firstName
      lastName
    }
    validator {
      id
      email
      firstName
      lastName
    }
    versions {
      ...FormTemplateVersionProperties
    }
    variants {
      id
      internalName
      isDefaultVariant
    }
    comments {
      ...FormTemplateCommentProperties
    }
    originalFile {
      ...StoredFileProperties
    }
    duplicateTemplate {
      id
      userVisibleName
    }
  }
  ${FormTemplateStatusLogPropertiesDoc}
  ${FormTemplateVersionPropertiesDoc}
  ${FormTemplateCommentPropertiesDoc}
  ${StoredFilePropertiesDoc}
`
export const SimilarTemplatePropertiesDoc = gql`
  fragment SimilarTemplateProperties on SimilarTemplate {
    template {
      ...FormTemplateProperties
    }
    version {
      ...FormTemplateVersionProperties
    }
    score
  }
  ${FormTemplatePropertiesDoc}
  ${FormTemplateVersionPropertiesDoc}
`
export const LocationPropertiesDoc = gql`
  fragment LocationProperties on Location {
    id
    createdAt
    nickname
    street1
    street2
    city
    county
    state
    country
    postalCode
    timeZone
    coordinates
  }
`
export const CompanyPropertiesDoc = gql`
  fragment CompanyProperties on Company {
    id
    name
    nickname
    type
    phoneNumber
    expectedNumberOfProjects
    defaultShowAuditItemOnSignedPackages
    allowLumpSumOverbilling
    notaryTags
    storedMaterialsCarryoverType
    isDemoCompany
    picture {
      id
      url
    }
    notaryStamp {
      id
      url
    }
    notarySignature {
      id
      url
    }
    secondarySignature {
      id
      url
    }
    locations {
      ...LocationProperties
    }
    metadata {
      subtrade
      domains
      domainSignup
      monthlyCreatePayAppReminderDate
    }
    defaultQuickBillingTemplate {
      id
    }
    sitelinePointOfContact {
      id
      email
    }
  }
  ${LocationPropertiesDoc}
`
export const PayAppNoProgressPropertiesDoc = gql`
  fragment PayAppNoProgressProperties on PayApp {
    id
    createdAt
    timeZone
    status
    retentionOnly
    billingStart
    billingEnd
    payAppDueDate
    currentBilled
    currentRetention
    totalRetention
    previousRetentionBilled
    contract {
      id
      company {
        ...CompanyProperties
      }
    }
  }
  ${CompanyPropertiesDoc}
`
export const SovLineItemGroupPropertiesDoc = gql`
  fragment SovLineItemGroupProperties on SovLineItemGroup {
    id
    name
    code
  }
`
export const SovLineItemPropertiesDoc = gql`
  fragment SovLineItemProperties on SovLineItem {
    id
    code
    sortOrder
    name
    isChangeOrder
    changeOrderApprovedDate
    latestTotalValue
    totalBilled
    previousBilled
    previousStoredMaterials
    totalRetention
    progressComplete
    defaultRetentionPercent
    sovLineItemGroup {
      ...SovLineItemGroupProperties
    }
  }
  ${SovLineItemGroupPropertiesDoc}
`
export const MinimalUserPropertiesDoc = gql`
  fragment MinimalUserProperties on User {
    id
    firstName
    lastName
    email
    jobTitle
    phoneNumber
    companies {
      id
      name
    }
  }
`
export const UserPropertiesDoc = gql`
  fragment UserProperties on User {
    ...MinimalUserProperties
    blockedEmails
    blockedNotifications
    policiesAcceptedAt
    companies {
      ...CompanyProperties
    }
    companyUsers {
      id
      status
      role
      company {
        ...CompanyProperties
      }
    }
  }
  ${MinimalUserPropertiesDoc}
  ${CompanyPropertiesDoc}
`
export const SovLineItemProgressPropertiesDoc = gql`
  fragment SovLineItemProgressProperties on SovLineItemProgress {
    id
    currentBilled
    progressBilled
    currentRetention
    previousBilled
    storedMaterialBilled
    previousRetention
    previousRetentionBilled
    totalValue
    sovLineItem {
      ...SovLineItemProperties
    }
    updatedBy {
      ...UserProperties
    }
  }
  ${SovLineItemPropertiesDoc}
  ${UserPropertiesDoc}
`
export const AmountLineItemPropertiesDoc = gql`
  fragment AmountLineItemProperties on AmountLineItem {
    id
    description
    amount
    sortOrder
    feePercent
  }
`
export const PayAppPropertiesDoc = gql`
  fragment PayAppProperties on PayApp {
    ...PayAppNoProgressProperties
    progress {
      ...SovLineItemProgressProperties
    }
    amountLineItems {
      ...AmountLineItemProperties
    }
  }
  ${PayAppNoProgressPropertiesDoc}
  ${SovLineItemProgressPropertiesDoc}
  ${AmountLineItemPropertiesDoc}
`
export const LegalDocumentPropertiesDoc = gql`
  fragment LegalDocumentProperties on LegalDocument {
    id
    name
    periodStart
    periodEnd
    lastSyncIfSuccessful {
      id
      createdAt
    }
  }
`
export const CompanyProjectMetadataPropertiesDoc = gql`
  fragment CompanyProjectMetadataProperties on CompanyProjectMetadata {
    role
    companyName
    company {
      ...CompanyProperties
    }
    selectedAddress {
      ...LocationProperties
    }
  }
  ${CompanyPropertiesDoc}
  ${LocationPropertiesDoc}
`
export const MinimalCompanyUserPropertiesDoc = gql`
  fragment MinimalCompanyUserProperties on CompanyUser {
    id
    role
    user {
      ...MinimalUserProperties
    }
  }
  ${MinimalUserPropertiesDoc}
`
export const ProjectPropertiesDoc = gql`
  fragment ProjectProperties on Project {
    id
    name
    projectNumber
    location {
      ...LocationProperties
    }
    generalContractor {
      ...CompanyProjectMetadataProperties
    }
  }
  ${LocationPropertiesDoc}
  ${CompanyProjectMetadataPropertiesDoc}
`
export const SovPropertiesDoc = gql`
  fragment SovProperties on Sov {
    id
    totalValue
    totalBilled
    totalRetention
    progressComplete
  }
`
export const CompanySovPropertiesDoc = gql`
  fragment CompanySovProperties on Sov {
    ...SovProperties
    contract {
      id
      timeZone
    }
    lineItems {
      ...SovLineItemProperties
    }
  }
  ${SovPropertiesDoc}
  ${SovLineItemPropertiesDoc}
`
export const LienWaiverFormTemplateVariantPropertiesDoc = gql`
  fragment LienWaiverFormTemplateVariantProperties on FormTemplateVariant {
    id
    template {
      ...FormTemplateProperties
    }
  }
  ${FormTemplatePropertiesDoc}
`
export const SubcontractorLienWaiverTemplateSetPropertiesDoc = gql`
  fragment SubcontractorLienWaiverTemplateSetProperties on SubcontractorLienWaiverTemplateSet {
    id
    conditionalFinalVariant {
      ...LienWaiverFormTemplateVariantProperties
    }
    conditionalProgressVariant {
      ...LienWaiverFormTemplateVariantProperties
    }
    unconditionalFinalVariant {
      ...LienWaiverFormTemplateVariantProperties
    }
    unconditionalProgressVariant {
      ...LienWaiverFormTemplateVariantProperties
    }
  }
  ${LienWaiverFormTemplateVariantPropertiesDoc}
`
export const PayAppRequirementPropertiesDoc = gql`
  fragment PayAppRequirementProperties on PayAppRequirement {
    id
    templateVariant {
      id
      internalName
      userVisibleName
      template {
        id
        isCustomerReady
      }
    }
    groupOrder
    conditions
  }
`
export const PayAppRequirementGroupPropertiesDoc = gql`
  fragment PayAppRequirementGroupProperties on PayAppRequirementGroup {
    id
    order
    payAppRequirements {
      ...PayAppRequirementProperties
    }
  }
  ${PayAppRequirementPropertiesDoc}
`
export const LegalRequirementPropertiesDoc = gql`
  fragment LegalRequirementProperties on LegalRequirement {
    id
    createdAt
    name
    timeZone
    expirationFrequency
    startDate
    endDate
    isVendorRequirement
    documents {
      id
    }
    formTemplate {
      id
    }
  }
`
export const IntegrationPropertiesDoc = gql`
  fragment IntegrationProperties on Integration {
    id
    createdAt
    type
    credentialsUpdatedAt
    mappings
    isActive
    shortName
    longName
    companyIntegration {
      id
    }
  }
`
export const ContractPropertiesDoc = gql`
  fragment ContractProperties on Contract {
    id
    role
    status
    timeZone
    contractDate
    contractNumber
    storedMaterialsCarryoverType
    project {
      ...ProjectProperties
    }
    company {
      ...CompanyProperties
    }
    sov {
      ...CompanySovProperties
    }
    defaultRetentionPercent
    retentionTrackingLevel
    daysBeforePayAppDue
    billingType
    contractTemplateVariables
    lienWaiverTemplates {
      ...SubcontractorLienWaiverTemplateSetProperties
    }
    lowerTierLienWaiverTemplates {
      ...SubcontractorLienWaiverTemplateSetProperties
    }
    internalProjectNumber
    vendorNumber
    pastPayAppCount
    payAppRequirementGroups {
      ...PayAppRequirementGroupProperties
    }
    legalRequirements {
      ...LegalRequirementProperties
    }
    selectedAddress {
      ...LocationProperties
    }
    changeOrderRequestTemplate {
      id
    }
    changeOrderLogTemplate {
      id
    }
    roundRetention
    integrations {
      ...IntegrationProperties
    }
    onboardedStatus {
      selectedPayAppForms
      onboardedPayAppForms
      selectedPrimaryLienWaivers
      onboardedPrimaryLienWaiverForms
      selectedVendorLienWaivers
      onboardedVendorLienWaiverForms
      selectedChangeOrderRequestForms
      onboardedChangeOrderRequestForms
      addedSov
      selectedRateTable
      addedTeammates
      addedGcContacts
      startedBilling
      notifiedOnboardedForms
    }
  }
  ${ProjectPropertiesDoc}
  ${CompanyPropertiesDoc}
  ${CompanySovPropertiesDoc}
  ${SubcontractorLienWaiverTemplateSetPropertiesDoc}
  ${PayAppRequirementGroupPropertiesDoc}
  ${LegalRequirementPropertiesDoc}
  ${LocationPropertiesDoc}
  ${IntegrationPropertiesDoc}
`
export const ProjectForDetailsPropertiesDoc = gql`
  fragment ProjectForDetailsProperties on Project {
    id
    name
    projectNumber
    location {
      ...LocationProperties
    }
    owner {
      ...CompanyProjectMetadataProperties
      company {
        id
        users {
          ...MinimalCompanyUserProperties
        }
      }
    }
    generalContractor {
      ...CompanyProjectMetadataProperties
      company {
        id
        users {
          ...MinimalCompanyUserProperties
        }
      }
    }
    architect {
      ...CompanyProjectMetadataProperties
    }
    contracts {
      ...ContractProperties
      company {
        id
        users {
          ...MinimalCompanyUserProperties
        }
      }
      users {
        id
        user {
          ...MinimalUserProperties
        }
      }
    }
    metadata {
      fieldProgressDueOnDayOfMonth
      payAppDueOnDayOfMonth
    }
  }
  ${LocationPropertiesDoc}
  ${CompanyProjectMetadataPropertiesDoc}
  ${MinimalCompanyUserPropertiesDoc}
  ${ContractPropertiesDoc}
  ${MinimalUserPropertiesDoc}
`
export const CompanyIntegrationPropertiesDoc = gql`
  fragment CompanyIntegrationProperties on CompanyIntegration {
    id
    createdAt
    type
    label
    shortName
    longName
    credentialsUpdatedAt
    credentialsReadyForAutoRotation
    archivedAt
    shouldImportNewProjectSov
    metadata
    importChangeOrdersMethod
    importComboJobMethod
    importProjectNameSource
    revertPayAppsToDraftWhenTexturaInvoiceRejected
    location {
      ...LocationProperties
    }
  }
  ${LocationPropertiesDoc}
`
export const CompanyUserPropertiesDoc = gql`
  fragment CompanyUserProperties on CompanyUser {
    id
    role
    isSitelineAdmin
    status
    permissions
    user {
      ...UserProperties
    }
  }
  ${UserPropertiesDoc}
`
export const VendorPropertiesDoc = gql`
  fragment VendorProperties on Vendor {
    id
    name
    contacts {
      id
      fullName
      email
      archivedAt
    }
  }
`
export const CompanyContactPropertiesDoc = gql`
  fragment CompanyContactProperties on CompanyContact {
    id
    fullName
    email
    companyName
    archivedAt
  }
`
export const CompanyPayAppFormTemplateSetPropertiesDoc = gql`
  fragment CompanyPayAppFormTemplateSetProperties on CompanyPayAppFormTemplateSet {
    id
    name
    state
    billingType
    generalContractor {
      id
      name
    }
    payAppRequirementGroups {
      ...PayAppRequirementGroupProperties
    }
  }
  ${PayAppRequirementGroupPropertiesDoc}
`
export const CompanyLienWaiverTemplateSetPropertiesDoc = gql`
  fragment CompanyLienWaiverTemplateSetProperties on CompanyLienWaiverTemplateSet {
    id
    name
    state
    generalContractor {
      id
      name
    }
    lienWaivers {
      ...SubcontractorLienWaiverTemplateSetProperties
    }
  }
  ${SubcontractorLienWaiverTemplateSetPropertiesDoc}
`
export const MinimalFormTemplatePropertiesDoc = gql`
  fragment MinimalFormTemplateProperties on FormTemplate {
    id
    createdAt
    isCustomerReady
    userVisibleName
    skippedValidation
    type
    status
    versions {
      ...FormTemplateVersionProperties
    }
    variants {
      id
      internalName
      isDefaultVariant
    }
  }
  ${FormTemplateVersionPropertiesDoc}
`
export const CompanyFormTemplateCollectionPropertiesDoc = gql`
  fragment CompanyFormTemplateCollectionProperties on CompanyFormTemplateCollection {
    id
    name
    state
    billingType
    generalContractor {
      id
      name
    }
    payAppForms {
      ...CompanyPayAppFormTemplateSetProperties
    }
    primaryLienWaivers {
      ...CompanyLienWaiverTemplateSetProperties
    }
    vendorLienWaivers {
      ...CompanyLienWaiverTemplateSetProperties
    }
    changeOrderRequest {
      id
      internalName
      isDefaultVariant
      template {
        ...MinimalFormTemplateProperties
      }
    }
    changeOrderLog {
      id
      internalName
      isDefaultVariant
      template {
        ...MinimalFormTemplateProperties
      }
    }
  }
  ${CompanyPayAppFormTemplateSetPropertiesDoc}
  ${CompanyLienWaiverTemplateSetPropertiesDoc}
  ${MinimalFormTemplatePropertiesDoc}
`
export const CompanyForDetailsPropertiesDoc = gql`
  fragment CompanyForDetailsProperties on Company {
    ...CompanyProperties
    companyIntegrations {
      ...CompanyIntegrationProperties
    }
    users {
      ...CompanyUserProperties
    }
    vendors {
      ...VendorProperties
    }
    contacts {
      ...CompanyContactProperties
    }
    formTemplateCollections {
      ...CompanyFormTemplateCollectionProperties
    }
    payAppFormTemplateSets {
      ...CompanyPayAppFormTemplateSetProperties
    }
    primaryLienWaiverTemplateSets {
      ...CompanyLienWaiverTemplateSetProperties
    }
    vendorLienWaiverTemplateSets {
      ...CompanyLienWaiverTemplateSetProperties
    }
    defaultChangeOrderRequestTemplate {
      id
    }
    defaultChangeOrderLogTemplate {
      id
    }
  }
  ${CompanyPropertiesDoc}
  ${CompanyIntegrationPropertiesDoc}
  ${CompanyUserPropertiesDoc}
  ${VendorPropertiesDoc}
  ${CompanyContactPropertiesDoc}
  ${CompanyFormTemplateCollectionPropertiesDoc}
  ${CompanyPayAppFormTemplateSetPropertiesDoc}
  ${CompanyLienWaiverTemplateSetPropertiesDoc}
`
export const UserEducationCampaignPropertiesDoc = gql`
  fragment UserEducationCampaignProperties on UserEducationCampaign {
    id
    name
    startDate
    endDate
    targetPermissions
    showNewTag
    title
    message
    buttonLabel
    buttonLink
  }
`
export const ContractForLienWaiverSettingsDoc = gql`
  fragment ContractForLienWaiverSettings on Contract {
    id
    lienWaiverTemplates {
      ...SubcontractorLienWaiverTemplateSetProperties
    }
    lowerTierLienWaiverTemplates {
      ...SubcontractorLienWaiverTemplateSetProperties
    }
  }
  ${SubcontractorLienWaiverTemplateSetPropertiesDoc}
`
export const CompaniesForAutocompleteDocument = gql`
  query companiesForAutocomplete($input: GetPaginatedCompaniesInput!) {
    paginatedCompanies(input: $input) {
      companies {
        id
        name
      }
    }
  }
`

/**
 * __useCompaniesForAutocompleteQuery__
 *
 * To run a query within a React component, call `useCompaniesForAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesForAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesForAutocompleteQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompaniesForAutocompleteQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompaniesForAutocompleteQuery,
    CompaniesForAutocompleteQueryVariables
  > &
    ({ variables: CompaniesForAutocompleteQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CompaniesForAutocompleteQuery, CompaniesForAutocompleteQueryVariables>(
    CompaniesForAutocompleteDocument,
    options
  )
}
export function useCompaniesForAutocompleteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompaniesForAutocompleteQuery,
    CompaniesForAutocompleteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CompaniesForAutocompleteQuery, CompaniesForAutocompleteQueryVariables>(
    CompaniesForAutocompleteDocument,
    options
  )
}
export function useCompaniesForAutocompleteSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CompaniesForAutocompleteQuery,
        CompaniesForAutocompleteQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    CompaniesForAutocompleteQuery,
    CompaniesForAutocompleteQueryVariables
  >(CompaniesForAutocompleteDocument, options)
}
export type CompaniesForAutocompleteQueryHookResult = ReturnType<
  typeof useCompaniesForAutocompleteQuery
>
export type CompaniesForAutocompleteLazyQueryHookResult = ReturnType<
  typeof useCompaniesForAutocompleteLazyQuery
>
export type CompaniesForAutocompleteSuspenseQueryHookResult = ReturnType<
  typeof useCompaniesForAutocompleteSuspenseQuery
>
export type CompaniesForAutocompleteQueryResult = Apollo.QueryResult<
  CompaniesForAutocompleteQuery,
  CompaniesForAutocompleteQueryVariables
>
export const CompanyForAutocompleteDocument = gql`
  query companyForAutocomplete($id: ID!) {
    company(id: $id) {
      id
      name
    }
  }
`

/**
 * __useCompanyForAutocompleteQuery__
 *
 * To run a query within a React component, call `useCompanyForAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyForAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyForAutocompleteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyForAutocompleteQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyForAutocompleteQuery,
    CompanyForAutocompleteQueryVariables
  > &
    ({ variables: CompanyForAutocompleteQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CompanyForAutocompleteQuery, CompanyForAutocompleteQueryVariables>(
    CompanyForAutocompleteDocument,
    options
  )
}
export function useCompanyForAutocompleteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyForAutocompleteQuery,
    CompanyForAutocompleteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CompanyForAutocompleteQuery, CompanyForAutocompleteQueryVariables>(
    CompanyForAutocompleteDocument,
    options
  )
}
export function useCompanyForAutocompleteSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CompanyForAutocompleteQuery,
        CompanyForAutocompleteQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<CompanyForAutocompleteQuery, CompanyForAutocompleteQueryVariables>(
    CompanyForAutocompleteDocument,
    options
  )
}
export type CompanyForAutocompleteQueryHookResult = ReturnType<
  typeof useCompanyForAutocompleteQuery
>
export type CompanyForAutocompleteLazyQueryHookResult = ReturnType<
  typeof useCompanyForAutocompleteLazyQuery
>
export type CompanyForAutocompleteSuspenseQueryHookResult = ReturnType<
  typeof useCompanyForAutocompleteSuspenseQuery
>
export type CompanyForAutocompleteQueryResult = Apollo.QueryResult<
  CompanyForAutocompleteQuery,
  CompanyForAutocompleteQueryVariables
>
export const UpdateCompanyDocument = gql`
  mutation updateCompany($input: UpdateCompanyInput!) {
    updateCompany(input: $input) {
      ...CompanyProperties
    }
  }
  ${CompanyPropertiesDoc}
`
export type UpdateCompanyMutationFn = Apollo.MutationFunction<
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables
>

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(
    UpdateCompanyDocument,
    options
  )
}
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>
export type UpdateCompanyMutationResult = Apollo.MutationResult<UpdateCompanyMutation>
export type UpdateCompanyMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables
>
export const DeleteCompanyDocument = gql`
  mutation deleteCompany($id: ID!) {
    deleteCompany(id: $id) {
      id
    }
  }
`
export type DeleteCompanyMutationFn = Apollo.MutationFunction<
  DeleteCompanyMutation,
  DeleteCompanyMutationVariables
>

/**
 * __useDeleteCompanyMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyMutation, { data, loading, error }] = useDeleteCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteCompanyMutation, DeleteCompanyMutationVariables>(
    DeleteCompanyDocument,
    options
  )
}
export type DeleteCompanyMutationHookResult = ReturnType<typeof useDeleteCompanyMutation>
export type DeleteCompanyMutationResult = Apollo.MutationResult<DeleteCompanyMutation>
export type DeleteCompanyMutationOptions = Apollo.BaseMutationOptions<
  DeleteCompanyMutation,
  DeleteCompanyMutationVariables
>
export const GetCompanyDocument = gql`
  query getCompany($companyId: ID!) {
    company(id: $companyId) {
      ...CompanyProperties
    }
  }
  ${CompanyPropertiesDoc}
`

/**
 * __useGetCompanyQuery__
 *
 * To run a query within a React component, call `useGetCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyQuery(
  baseOptions: Apollo.QueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables> &
    ({ variables: GetCompanyQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, options)
}
export function useGetCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, options)
}
export function useGetCompanySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetCompanyQuery, GetCompanyQueryVariables>(
    GetCompanyDocument,
    options
  )
}
export type GetCompanyQueryHookResult = ReturnType<typeof useGetCompanyQuery>
export type GetCompanyLazyQueryHookResult = ReturnType<typeof useGetCompanyLazyQuery>
export type GetCompanySuspenseQueryHookResult = ReturnType<typeof useGetCompanySuspenseQuery>
export type GetCompanyQueryResult = Apollo.QueryResult<GetCompanyQuery, GetCompanyQueryVariables>
export const PayAppForAutocompleteDocument = gql`
  query payAppForAutocomplete($id: ID!) {
    payApp(id: $id) {
      id
      billingEnd
      timeZone
      contract {
        id
        project {
          id
          name
        }
        company {
          id
          name
        }
      }
    }
  }
`

/**
 * __usePayAppForAutocompleteQuery__
 *
 * To run a query within a React component, call `usePayAppForAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayAppForAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayAppForAutocompleteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePayAppForAutocompleteQuery(
  baseOptions: Apollo.QueryHookOptions<
    PayAppForAutocompleteQuery,
    PayAppForAutocompleteQueryVariables
  > &
    ({ variables: PayAppForAutocompleteQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PayAppForAutocompleteQuery, PayAppForAutocompleteQueryVariables>(
    PayAppForAutocompleteDocument,
    options
  )
}
export function usePayAppForAutocompleteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PayAppForAutocompleteQuery,
    PayAppForAutocompleteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PayAppForAutocompleteQuery, PayAppForAutocompleteQueryVariables>(
    PayAppForAutocompleteDocument,
    options
  )
}
export function usePayAppForAutocompleteSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PayAppForAutocompleteQuery,
        PayAppForAutocompleteQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<PayAppForAutocompleteQuery, PayAppForAutocompleteQueryVariables>(
    PayAppForAutocompleteDocument,
    options
  )
}
export type PayAppForAutocompleteQueryHookResult = ReturnType<typeof usePayAppForAutocompleteQuery>
export type PayAppForAutocompleteLazyQueryHookResult = ReturnType<
  typeof usePayAppForAutocompleteLazyQuery
>
export type PayAppForAutocompleteSuspenseQueryHookResult = ReturnType<
  typeof usePayAppForAutocompleteSuspenseQuery
>
export type PayAppForAutocompleteQueryResult = Apollo.QueryResult<
  PayAppForAutocompleteQuery,
  PayAppForAutocompleteQueryVariables
>
export const TemplatesForAutocompleteDocument = gql`
  query templatesForAutocomplete($input: GetPaginatedTemplatesInput!) {
    paginatedTemplates(input: $input) {
      templates {
        id
        createdAt
        type
        userVisibleName
        status
        skippedValidation
        variants {
          id
          internalName
          isDefaultVariant
        }
      }
    }
  }
`

/**
 * __useTemplatesForAutocompleteQuery__
 *
 * To run a query within a React component, call `useTemplatesForAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplatesForAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplatesForAutocompleteQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTemplatesForAutocompleteQuery(
  baseOptions: Apollo.QueryHookOptions<
    TemplatesForAutocompleteQuery,
    TemplatesForAutocompleteQueryVariables
  > &
    ({ variables: TemplatesForAutocompleteQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TemplatesForAutocompleteQuery, TemplatesForAutocompleteQueryVariables>(
    TemplatesForAutocompleteDocument,
    options
  )
}
export function useTemplatesForAutocompleteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TemplatesForAutocompleteQuery,
    TemplatesForAutocompleteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TemplatesForAutocompleteQuery, TemplatesForAutocompleteQueryVariables>(
    TemplatesForAutocompleteDocument,
    options
  )
}
export function useTemplatesForAutocompleteSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        TemplatesForAutocompleteQuery,
        TemplatesForAutocompleteQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    TemplatesForAutocompleteQuery,
    TemplatesForAutocompleteQueryVariables
  >(TemplatesForAutocompleteDocument, options)
}
export type TemplatesForAutocompleteQueryHookResult = ReturnType<
  typeof useTemplatesForAutocompleteQuery
>
export type TemplatesForAutocompleteLazyQueryHookResult = ReturnType<
  typeof useTemplatesForAutocompleteLazyQuery
>
export type TemplatesForAutocompleteSuspenseQueryHookResult = ReturnType<
  typeof useTemplatesForAutocompleteSuspenseQuery
>
export type TemplatesForAutocompleteQueryResult = Apollo.QueryResult<
  TemplatesForAutocompleteQuery,
  TemplatesForAutocompleteQueryVariables
>
export const TemplateForAutocompleteDocument = gql`
  query templateForAutocomplete($id: ID!) {
    formTemplate(id: $id) {
      id
      createdAt
      userVisibleName
      type
      status
      skippedValidation
      variants {
        id
        internalName
        isDefaultVariant
      }
    }
  }
`

/**
 * __useTemplateForAutocompleteQuery__
 *
 * To run a query within a React component, call `useTemplateForAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateForAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateForAutocompleteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTemplateForAutocompleteQuery(
  baseOptions: Apollo.QueryHookOptions<
    TemplateForAutocompleteQuery,
    TemplateForAutocompleteQueryVariables
  > &
    ({ variables: TemplateForAutocompleteQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TemplateForAutocompleteQuery, TemplateForAutocompleteQueryVariables>(
    TemplateForAutocompleteDocument,
    options
  )
}
export function useTemplateForAutocompleteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TemplateForAutocompleteQuery,
    TemplateForAutocompleteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TemplateForAutocompleteQuery, TemplateForAutocompleteQueryVariables>(
    TemplateForAutocompleteDocument,
    options
  )
}
export function useTemplateForAutocompleteSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        TemplateForAutocompleteQuery,
        TemplateForAutocompleteQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    TemplateForAutocompleteQuery,
    TemplateForAutocompleteQueryVariables
  >(TemplateForAutocompleteDocument, options)
}
export type TemplateForAutocompleteQueryHookResult = ReturnType<
  typeof useTemplateForAutocompleteQuery
>
export type TemplateForAutocompleteLazyQueryHookResult = ReturnType<
  typeof useTemplateForAutocompleteLazyQuery
>
export type TemplateForAutocompleteSuspenseQueryHookResult = ReturnType<
  typeof useTemplateForAutocompleteSuspenseQuery
>
export type TemplateForAutocompleteQueryResult = Apollo.QueryResult<
  TemplateForAutocompleteQuery,
  TemplateForAutocompleteQueryVariables
>
export const SearchDocument = gql`
  query search($query: String!, $type: SearchResultType) {
    search(query: $query, type: $type) {
      id
      type
      description
      score
    }
  }
`

/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useSearchQuery(
  baseOptions: Apollo.QueryHookOptions<SearchQuery, SearchQueryVariables> &
    ({ variables: SearchQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options)
}
export function useSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchQuery, SearchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options)
}
export function useSearchSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<SearchQuery, SearchQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options)
}
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>
export type SearchSuspenseQueryHookResult = ReturnType<typeof useSearchSuspenseQuery>
export type SearchQueryResult = Apollo.QueryResult<SearchQuery, SearchQueryVariables>
export const CreateTestTemplateDocument = gql`
  mutation createTestTemplate($input: CreateTestTemplateInput!) {
    createTestTemplate(input: $input) {
      id
      versions {
        id
      }
    }
  }
`
export type CreateTestTemplateMutationFn = Apollo.MutationFunction<
  CreateTestTemplateMutation,
  CreateTestTemplateMutationVariables
>

/**
 * __useCreateTestTemplateMutation__
 *
 * To run a mutation, you first call `useCreateTestTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTestTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTestTemplateMutation, { data, loading, error }] = useCreateTestTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTestTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTestTemplateMutation,
    CreateTestTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateTestTemplateMutation, CreateTestTemplateMutationVariables>(
    CreateTestTemplateDocument,
    options
  )
}
export type CreateTestTemplateMutationHookResult = ReturnType<typeof useCreateTestTemplateMutation>
export type CreateTestTemplateMutationResult = Apollo.MutationResult<CreateTestTemplateMutation>
export type CreateTestTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateTestTemplateMutation,
  CreateTestTemplateMutationVariables
>
export const TemplateVariantForAutocompleteDocument = gql`
  query templateVariantForAutocomplete($id: ID!) {
    formTemplateVariant(id: $id) {
      id
      userVisibleName
      internalName
      isDefaultVariant
      hidesZeroDollarAmounts
      roundPercentages
      useCompanyNotarySignatureIfAvailable
      template {
        id
        createdAt
        type
        userVisibleName
        status
        skippedValidation
        versions {
          id
          versionNumber
          isReadyForManualStoredMaterials
        }
      }
    }
  }
`

/**
 * __useTemplateVariantForAutocompleteQuery__
 *
 * To run a query within a React component, call `useTemplateVariantForAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateVariantForAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateVariantForAutocompleteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTemplateVariantForAutocompleteQuery(
  baseOptions: Apollo.QueryHookOptions<
    TemplateVariantForAutocompleteQuery,
    TemplateVariantForAutocompleteQueryVariables
  > &
    (
      | { variables: TemplateVariantForAutocompleteQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    TemplateVariantForAutocompleteQuery,
    TemplateVariantForAutocompleteQueryVariables
  >(TemplateVariantForAutocompleteDocument, options)
}
export function useTemplateVariantForAutocompleteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TemplateVariantForAutocompleteQuery,
    TemplateVariantForAutocompleteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    TemplateVariantForAutocompleteQuery,
    TemplateVariantForAutocompleteQueryVariables
  >(TemplateVariantForAutocompleteDocument, options)
}
export function useTemplateVariantForAutocompleteSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        TemplateVariantForAutocompleteQuery,
        TemplateVariantForAutocompleteQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    TemplateVariantForAutocompleteQuery,
    TemplateVariantForAutocompleteQueryVariables
  >(TemplateVariantForAutocompleteDocument, options)
}
export type TemplateVariantForAutocompleteQueryHookResult = ReturnType<
  typeof useTemplateVariantForAutocompleteQuery
>
export type TemplateVariantForAutocompleteLazyQueryHookResult = ReturnType<
  typeof useTemplateVariantForAutocompleteLazyQuery
>
export type TemplateVariantForAutocompleteSuspenseQueryHookResult = ReturnType<
  typeof useTemplateVariantForAutocompleteSuspenseQuery
>
export type TemplateVariantForAutocompleteQueryResult = Apollo.QueryResult<
  TemplateVariantForAutocompleteQuery,
  TemplateVariantForAutocompleteQueryVariables
>
export const CreateAdminUserDocument = gql`
  mutation createAdminUser($id: ID!) {
    createAdminUser(id: $id) {
      id
    }
  }
`
export type CreateAdminUserMutationFn = Apollo.MutationFunction<
  CreateAdminUserMutation,
  CreateAdminUserMutationVariables
>

/**
 * __useCreateAdminUserMutation__
 *
 * To run a mutation, you first call `useCreateAdminUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdminUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdminUserMutation, { data, loading, error }] = useCreateAdminUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCreateAdminUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAdminUserMutation,
    CreateAdminUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateAdminUserMutation, CreateAdminUserMutationVariables>(
    CreateAdminUserDocument,
    options
  )
}
export type CreateAdminUserMutationHookResult = ReturnType<typeof useCreateAdminUserMutation>
export type CreateAdminUserMutationResult = Apollo.MutationResult<CreateAdminUserMutation>
export type CreateAdminUserMutationOptions = Apollo.BaseMutationOptions<
  CreateAdminUserMutation,
  CreateAdminUserMutationVariables
>
export const FullyBilledContractsDocument = gql`
  query fullyBilledContracts {
    fullyBilledContracts {
      id
      timeZone
      pastPayAppCount
      percentComplete
      project {
        id
        name
      }
      company {
        id
        name
      }
      payApps {
        id
        billingEnd
        balanceToFinish
        currentBilled
        payAppNumber
        retentionOnly
      }
      sov {
        id
        totalValue
        totalRetention
      }
    }
  }
`

/**
 * __useFullyBilledContractsQuery__
 *
 * To run a query within a React component, call `useFullyBilledContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFullyBilledContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFullyBilledContractsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFullyBilledContractsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FullyBilledContractsQuery,
    FullyBilledContractsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FullyBilledContractsQuery, FullyBilledContractsQueryVariables>(
    FullyBilledContractsDocument,
    options
  )
}
export function useFullyBilledContractsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FullyBilledContractsQuery,
    FullyBilledContractsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FullyBilledContractsQuery, FullyBilledContractsQueryVariables>(
    FullyBilledContractsDocument,
    options
  )
}
export function useFullyBilledContractsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FullyBilledContractsQuery, FullyBilledContractsQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<FullyBilledContractsQuery, FullyBilledContractsQueryVariables>(
    FullyBilledContractsDocument,
    options
  )
}
export type FullyBilledContractsQueryHookResult = ReturnType<typeof useFullyBilledContractsQuery>
export type FullyBilledContractsLazyQueryHookResult = ReturnType<
  typeof useFullyBilledContractsLazyQuery
>
export type FullyBilledContractsSuspenseQueryHookResult = ReturnType<
  typeof useFullyBilledContractsSuspenseQuery
>
export type FullyBilledContractsQueryResult = Apollo.QueryResult<
  FullyBilledContractsQuery,
  FullyBilledContractsQueryVariables
>
export const CacheKeysDocument = gql`
  query cacheKeys($input: GetCacheKeysInput!) {
    cacheKeys(input: $input)
  }
`

/**
 * __useCacheKeysQuery__
 *
 * To run a query within a React component, call `useCacheKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useCacheKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCacheKeysQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCacheKeysQuery(
  baseOptions: Apollo.QueryHookOptions<CacheKeysQuery, CacheKeysQueryVariables> &
    ({ variables: CacheKeysQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CacheKeysQuery, CacheKeysQueryVariables>(CacheKeysDocument, options)
}
export function useCacheKeysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CacheKeysQuery, CacheKeysQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CacheKeysQuery, CacheKeysQueryVariables>(CacheKeysDocument, options)
}
export function useCacheKeysSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<CacheKeysQuery, CacheKeysQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<CacheKeysQuery, CacheKeysQueryVariables>(
    CacheKeysDocument,
    options
  )
}
export type CacheKeysQueryHookResult = ReturnType<typeof useCacheKeysQuery>
export type CacheKeysLazyQueryHookResult = ReturnType<typeof useCacheKeysLazyQuery>
export type CacheKeysSuspenseQueryHookResult = ReturnType<typeof useCacheKeysSuspenseQuery>
export type CacheKeysQueryResult = Apollo.QueryResult<CacheKeysQuery, CacheKeysQueryVariables>
export const DeleteCacheKeysDocument = gql`
  mutation deleteCacheKeys($input: DeleteCacheKeysInput!) {
    deleteCacheKeys(input: $input)
  }
`
export type DeleteCacheKeysMutationFn = Apollo.MutationFunction<
  DeleteCacheKeysMutation,
  DeleteCacheKeysMutationVariables
>

/**
 * __useDeleteCacheKeysMutation__
 *
 * To run a mutation, you first call `useDeleteCacheKeysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCacheKeysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCacheKeysMutation, { data, loading, error }] = useDeleteCacheKeysMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCacheKeysMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCacheKeysMutation,
    DeleteCacheKeysMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteCacheKeysMutation, DeleteCacheKeysMutationVariables>(
    DeleteCacheKeysDocument,
    options
  )
}
export type DeleteCacheKeysMutationHookResult = ReturnType<typeof useDeleteCacheKeysMutation>
export type DeleteCacheKeysMutationResult = Apollo.MutationResult<DeleteCacheKeysMutation>
export type DeleteCacheKeysMutationOptions = Apollo.BaseMutationOptions<
  DeleteCacheKeysMutation,
  DeleteCacheKeysMutationVariables
>
export const CreateCompanyDocument = gql`
  mutation createCompany($input: CompanyInput!) {
    createCompany(company: $input) {
      ...CompanyProperties
    }
  }
  ${CompanyPropertiesDoc}
`
export type CreateCompanyMutationFn = Apollo.MutationFunction<
  CreateCompanyMutation,
  CreateCompanyMutationVariables
>

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(
    CreateCompanyDocument,
    options
  )
}
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>
export type CreateCompanyMutationResult = Apollo.MutationResult<CreateCompanyMutation>
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<
  CreateCompanyMutation,
  CreateCompanyMutationVariables
>
export const AddCompanyIntegrationDocument = gql`
  mutation addCompanyIntegration($input: AddCompanyIntegrationInput!) {
    addCompanyIntegration(input: $input) {
      ...CompanyIntegrationProperties
    }
  }
  ${CompanyIntegrationPropertiesDoc}
`
export type AddCompanyIntegrationMutationFn = Apollo.MutationFunction<
  AddCompanyIntegrationMutation,
  AddCompanyIntegrationMutationVariables
>

/**
 * __useAddCompanyIntegrationMutation__
 *
 * To run a mutation, you first call `useAddCompanyIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCompanyIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCompanyIntegrationMutation, { data, loading, error }] = useAddCompanyIntegrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCompanyIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddCompanyIntegrationMutation,
    AddCompanyIntegrationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AddCompanyIntegrationMutation, AddCompanyIntegrationMutationVariables>(
    AddCompanyIntegrationDocument,
    options
  )
}
export type AddCompanyIntegrationMutationHookResult = ReturnType<
  typeof useAddCompanyIntegrationMutation
>
export type AddCompanyIntegrationMutationResult =
  Apollo.MutationResult<AddCompanyIntegrationMutation>
export type AddCompanyIntegrationMutationOptions = Apollo.BaseMutationOptions<
  AddCompanyIntegrationMutation,
  AddCompanyIntegrationMutationVariables
>
export const UpdateCompanyIntegrationDocument = gql`
  mutation updateCompanyIntegration($input: UpdateCompanyIntegrationInput!) {
    updateCompanyIntegration(input: $input) {
      ...CompanyIntegrationProperties
    }
  }
  ${CompanyIntegrationPropertiesDoc}
`
export type UpdateCompanyIntegrationMutationFn = Apollo.MutationFunction<
  UpdateCompanyIntegrationMutation,
  UpdateCompanyIntegrationMutationVariables
>

/**
 * __useUpdateCompanyIntegrationMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyIntegrationMutation, { data, loading, error }] = useUpdateCompanyIntegrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCompanyIntegrationMutation,
    UpdateCompanyIntegrationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateCompanyIntegrationMutation,
    UpdateCompanyIntegrationMutationVariables
  >(UpdateCompanyIntegrationDocument, options)
}
export type UpdateCompanyIntegrationMutationHookResult = ReturnType<
  typeof useUpdateCompanyIntegrationMutation
>
export type UpdateCompanyIntegrationMutationResult =
  Apollo.MutationResult<UpdateCompanyIntegrationMutation>
export type UpdateCompanyIntegrationMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompanyIntegrationMutation,
  UpdateCompanyIntegrationMutationVariables
>
export const GetIntegrationInvoiceTypesDocument = gql`
  query getIntegrationInvoiceTypes($input: IntegrationInvoiceTypesInput!) {
    integrationInvoiceTypes(input: $input) {
      invoiceType
      hasAutoNumberingEnabled
    }
  }
`

/**
 * __useGetIntegrationInvoiceTypesQuery__
 *
 * To run a query within a React component, call `useGetIntegrationInvoiceTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIntegrationInvoiceTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIntegrationInvoiceTypesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetIntegrationInvoiceTypesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetIntegrationInvoiceTypesQuery,
    GetIntegrationInvoiceTypesQueryVariables
  > &
    ({ variables: GetIntegrationInvoiceTypesQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetIntegrationInvoiceTypesQuery, GetIntegrationInvoiceTypesQueryVariables>(
    GetIntegrationInvoiceTypesDocument,
    options
  )
}
export function useGetIntegrationInvoiceTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetIntegrationInvoiceTypesQuery,
    GetIntegrationInvoiceTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetIntegrationInvoiceTypesQuery,
    GetIntegrationInvoiceTypesQueryVariables
  >(GetIntegrationInvoiceTypesDocument, options)
}
export function useGetIntegrationInvoiceTypesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetIntegrationInvoiceTypesQuery,
        GetIntegrationInvoiceTypesQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetIntegrationInvoiceTypesQuery,
    GetIntegrationInvoiceTypesQueryVariables
  >(GetIntegrationInvoiceTypesDocument, options)
}
export type GetIntegrationInvoiceTypesQueryHookResult = ReturnType<
  typeof useGetIntegrationInvoiceTypesQuery
>
export type GetIntegrationInvoiceTypesLazyQueryHookResult = ReturnType<
  typeof useGetIntegrationInvoiceTypesLazyQuery
>
export type GetIntegrationInvoiceTypesSuspenseQueryHookResult = ReturnType<
  typeof useGetIntegrationInvoiceTypesSuspenseQuery
>
export type GetIntegrationInvoiceTypesQueryResult = Apollo.QueryResult<
  GetIntegrationInvoiceTypesQuery,
  GetIntegrationInvoiceTypesQueryVariables
>
export const InviteCompanyUserDocument = gql`
  mutation inviteCompanyUser($input: InviteCompanyUserInput!) {
    inviteCompanyUser(input: $input) {
      ...CompanyUserProperties
    }
  }
  ${CompanyUserPropertiesDoc}
`
export type InviteCompanyUserMutationFn = Apollo.MutationFunction<
  InviteCompanyUserMutation,
  InviteCompanyUserMutationVariables
>

/**
 * __useInviteCompanyUserMutation__
 *
 * To run a mutation, you first call `useInviteCompanyUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteCompanyUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteCompanyUserMutation, { data, loading, error }] = useInviteCompanyUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteCompanyUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteCompanyUserMutation,
    InviteCompanyUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<InviteCompanyUserMutation, InviteCompanyUserMutationVariables>(
    InviteCompanyUserDocument,
    options
  )
}
export type InviteCompanyUserMutationHookResult = ReturnType<typeof useInviteCompanyUserMutation>
export type InviteCompanyUserMutationResult = Apollo.MutationResult<InviteCompanyUserMutation>
export type InviteCompanyUserMutationOptions = Apollo.BaseMutationOptions<
  InviteCompanyUserMutation,
  InviteCompanyUserMutationVariables
>
export const CreateCompanyContactDocument = gql`
  mutation createCompanyContact($input: CreateCompanyContactInput!) {
    createCompanyContact(input: $input) {
      ...CompanyContactProperties
    }
  }
  ${CompanyContactPropertiesDoc}
`
export type CreateCompanyContactMutationFn = Apollo.MutationFunction<
  CreateCompanyContactMutation,
  CreateCompanyContactMutationVariables
>

/**
 * __useCreateCompanyContactMutation__
 *
 * To run a mutation, you first call `useCreateCompanyContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyContactMutation, { data, loading, error }] = useCreateCompanyContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompanyContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCompanyContactMutation,
    CreateCompanyContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateCompanyContactMutation, CreateCompanyContactMutationVariables>(
    CreateCompanyContactDocument,
    options
  )
}
export type CreateCompanyContactMutationHookResult = ReturnType<
  typeof useCreateCompanyContactMutation
>
export type CreateCompanyContactMutationResult = Apollo.MutationResult<CreateCompanyContactMutation>
export type CreateCompanyContactMutationOptions = Apollo.BaseMutationOptions<
  CreateCompanyContactMutation,
  CreateCompanyContactMutationVariables
>
export const UpdateCompanyContactDocument = gql`
  mutation updateCompanyContact($input: UpdateCompanyContactInput!) {
    updateCompanyContact(input: $input) {
      ...CompanyContactProperties
    }
  }
  ${CompanyContactPropertiesDoc}
`
export type UpdateCompanyContactMutationFn = Apollo.MutationFunction<
  UpdateCompanyContactMutation,
  UpdateCompanyContactMutationVariables
>

/**
 * __useUpdateCompanyContactMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyContactMutation, { data, loading, error }] = useUpdateCompanyContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCompanyContactMutation,
    UpdateCompanyContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateCompanyContactMutation, UpdateCompanyContactMutationVariables>(
    UpdateCompanyContactDocument,
    options
  )
}
export type UpdateCompanyContactMutationHookResult = ReturnType<
  typeof useUpdateCompanyContactMutation
>
export type UpdateCompanyContactMutationResult = Apollo.MutationResult<UpdateCompanyContactMutation>
export type UpdateCompanyContactMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompanyContactMutation,
  UpdateCompanyContactMutationVariables
>
export const ClearCompanyContactsDocument = gql`
  mutation clearCompanyContacts($id: ID!) {
    clearCompanyContacts(id: $id) {
      ...CompanyForDetailsProperties
    }
  }
  ${CompanyForDetailsPropertiesDoc}
`
export type ClearCompanyContactsMutationFn = Apollo.MutationFunction<
  ClearCompanyContactsMutation,
  ClearCompanyContactsMutationVariables
>

/**
 * __useClearCompanyContactsMutation__
 *
 * To run a mutation, you first call `useClearCompanyContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearCompanyContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearCompanyContactsMutation, { data, loading, error }] = useClearCompanyContactsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClearCompanyContactsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClearCompanyContactsMutation,
    ClearCompanyContactsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ClearCompanyContactsMutation, ClearCompanyContactsMutationVariables>(
    ClearCompanyContactsDocument,
    options
  )
}
export type ClearCompanyContactsMutationHookResult = ReturnType<
  typeof useClearCompanyContactsMutation
>
export type ClearCompanyContactsMutationResult = Apollo.MutationResult<ClearCompanyContactsMutation>
export type ClearCompanyContactsMutationOptions = Apollo.BaseMutationOptions<
  ClearCompanyContactsMutation,
  ClearCompanyContactsMutationVariables
>
export const GetCompanyForDetailsDocument = gql`
  query getCompanyForDetails($companyId: ID!) {
    company(id: $companyId) {
      ...CompanyForDetailsProperties
    }
  }
  ${CompanyForDetailsPropertiesDoc}
`

/**
 * __useGetCompanyForDetailsQuery__
 *
 * To run a query within a React component, call `useGetCompanyForDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyForDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyForDetailsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyForDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyForDetailsQuery,
    GetCompanyForDetailsQueryVariables
  > &
    ({ variables: GetCompanyForDetailsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCompanyForDetailsQuery, GetCompanyForDetailsQueryVariables>(
    GetCompanyForDetailsDocument,
    options
  )
}
export function useGetCompanyForDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyForDetailsQuery,
    GetCompanyForDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCompanyForDetailsQuery, GetCompanyForDetailsQueryVariables>(
    GetCompanyForDetailsDocument,
    options
  )
}
export function useGetCompanyForDetailsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetCompanyForDetailsQuery, GetCompanyForDetailsQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetCompanyForDetailsQuery, GetCompanyForDetailsQueryVariables>(
    GetCompanyForDetailsDocument,
    options
  )
}
export type GetCompanyForDetailsQueryHookResult = ReturnType<typeof useGetCompanyForDetailsQuery>
export type GetCompanyForDetailsLazyQueryHookResult = ReturnType<
  typeof useGetCompanyForDetailsLazyQuery
>
export type GetCompanyForDetailsSuspenseQueryHookResult = ReturnType<
  typeof useGetCompanyForDetailsSuspenseQuery
>
export type GetCompanyForDetailsQueryResult = Apollo.QueryResult<
  GetCompanyForDetailsQuery,
  GetCompanyForDetailsQueryVariables
>
export const UpdateDefaultChangeOrderLogFormDocument = gql`
  mutation updateDefaultChangeOrderLogForm($input: UpdateDefaultChangeOrderLogFormInput!) {
    updateDefaultChangeOrderLogForm(input: $input) {
      ...CompanyForDetailsProperties
    }
  }
  ${CompanyForDetailsPropertiesDoc}
`
export type UpdateDefaultChangeOrderLogFormMutationFn = Apollo.MutationFunction<
  UpdateDefaultChangeOrderLogFormMutation,
  UpdateDefaultChangeOrderLogFormMutationVariables
>

/**
 * __useUpdateDefaultChangeOrderLogFormMutation__
 *
 * To run a mutation, you first call `useUpdateDefaultChangeOrderLogFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDefaultChangeOrderLogFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDefaultChangeOrderLogFormMutation, { data, loading, error }] = useUpdateDefaultChangeOrderLogFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDefaultChangeOrderLogFormMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDefaultChangeOrderLogFormMutation,
    UpdateDefaultChangeOrderLogFormMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateDefaultChangeOrderLogFormMutation,
    UpdateDefaultChangeOrderLogFormMutationVariables
  >(UpdateDefaultChangeOrderLogFormDocument, options)
}
export type UpdateDefaultChangeOrderLogFormMutationHookResult = ReturnType<
  typeof useUpdateDefaultChangeOrderLogFormMutation
>
export type UpdateDefaultChangeOrderLogFormMutationResult =
  Apollo.MutationResult<UpdateDefaultChangeOrderLogFormMutation>
export type UpdateDefaultChangeOrderLogFormMutationOptions = Apollo.BaseMutationOptions<
  UpdateDefaultChangeOrderLogFormMutation,
  UpdateDefaultChangeOrderLogFormMutationVariables
>
export const UpdateDefaultChangeOrderRequestFormDocument = gql`
  mutation updateDefaultChangeOrderRequestForm($input: UpdateDefaultChangeOrderRequestFormInput!) {
    updateDefaultChangeOrderRequestForm(input: $input) {
      ...CompanyForDetailsProperties
    }
  }
  ${CompanyForDetailsPropertiesDoc}
`
export type UpdateDefaultChangeOrderRequestFormMutationFn = Apollo.MutationFunction<
  UpdateDefaultChangeOrderRequestFormMutation,
  UpdateDefaultChangeOrderRequestFormMutationVariables
>

/**
 * __useUpdateDefaultChangeOrderRequestFormMutation__
 *
 * To run a mutation, you first call `useUpdateDefaultChangeOrderRequestFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDefaultChangeOrderRequestFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDefaultChangeOrderRequestFormMutation, { data, loading, error }] = useUpdateDefaultChangeOrderRequestFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDefaultChangeOrderRequestFormMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDefaultChangeOrderRequestFormMutation,
    UpdateDefaultChangeOrderRequestFormMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateDefaultChangeOrderRequestFormMutation,
    UpdateDefaultChangeOrderRequestFormMutationVariables
  >(UpdateDefaultChangeOrderRequestFormDocument, options)
}
export type UpdateDefaultChangeOrderRequestFormMutationHookResult = ReturnType<
  typeof useUpdateDefaultChangeOrderRequestFormMutation
>
export type UpdateDefaultChangeOrderRequestFormMutationResult =
  Apollo.MutationResult<UpdateDefaultChangeOrderRequestFormMutation>
export type UpdateDefaultChangeOrderRequestFormMutationOptions = Apollo.BaseMutationOptions<
  UpdateDefaultChangeOrderRequestFormMutation,
  UpdateDefaultChangeOrderRequestFormMutationVariables
>
export const CreateCompanyFormTemplateCollectionDocument = gql`
  mutation createCompanyFormTemplateCollection($input: CreateCompanyFormTemplateCollectionInput!) {
    createCompanyFormTemplateCollection(input: $input) {
      id
      formTemplateCollections {
        ...CompanyFormTemplateCollectionProperties
      }
    }
  }
  ${CompanyFormTemplateCollectionPropertiesDoc}
`
export type CreateCompanyFormTemplateCollectionMutationFn = Apollo.MutationFunction<
  CreateCompanyFormTemplateCollectionMutation,
  CreateCompanyFormTemplateCollectionMutationVariables
>

/**
 * __useCreateCompanyFormTemplateCollectionMutation__
 *
 * To run a mutation, you first call `useCreateCompanyFormTemplateCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyFormTemplateCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyFormTemplateCollectionMutation, { data, loading, error }] = useCreateCompanyFormTemplateCollectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompanyFormTemplateCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCompanyFormTemplateCollectionMutation,
    CreateCompanyFormTemplateCollectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateCompanyFormTemplateCollectionMutation,
    CreateCompanyFormTemplateCollectionMutationVariables
  >(CreateCompanyFormTemplateCollectionDocument, options)
}
export type CreateCompanyFormTemplateCollectionMutationHookResult = ReturnType<
  typeof useCreateCompanyFormTemplateCollectionMutation
>
export type CreateCompanyFormTemplateCollectionMutationResult =
  Apollo.MutationResult<CreateCompanyFormTemplateCollectionMutation>
export type CreateCompanyFormTemplateCollectionMutationOptions = Apollo.BaseMutationOptions<
  CreateCompanyFormTemplateCollectionMutation,
  CreateCompanyFormTemplateCollectionMutationVariables
>
export const UpdateCompanyFormTemplateCollectionDocument = gql`
  mutation updateCompanyFormTemplateCollection($input: UpdateCompanyFormTemplateCollectionInput!) {
    updateCompanyFormTemplateCollection(input: $input) {
      id
      formTemplateCollections {
        ...CompanyFormTemplateCollectionProperties
      }
    }
  }
  ${CompanyFormTemplateCollectionPropertiesDoc}
`
export type UpdateCompanyFormTemplateCollectionMutationFn = Apollo.MutationFunction<
  UpdateCompanyFormTemplateCollectionMutation,
  UpdateCompanyFormTemplateCollectionMutationVariables
>

/**
 * __useUpdateCompanyFormTemplateCollectionMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyFormTemplateCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyFormTemplateCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyFormTemplateCollectionMutation, { data, loading, error }] = useUpdateCompanyFormTemplateCollectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyFormTemplateCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCompanyFormTemplateCollectionMutation,
    UpdateCompanyFormTemplateCollectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateCompanyFormTemplateCollectionMutation,
    UpdateCompanyFormTemplateCollectionMutationVariables
  >(UpdateCompanyFormTemplateCollectionDocument, options)
}
export type UpdateCompanyFormTemplateCollectionMutationHookResult = ReturnType<
  typeof useUpdateCompanyFormTemplateCollectionMutation
>
export type UpdateCompanyFormTemplateCollectionMutationResult =
  Apollo.MutationResult<UpdateCompanyFormTemplateCollectionMutation>
export type UpdateCompanyFormTemplateCollectionMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompanyFormTemplateCollectionMutation,
  UpdateCompanyFormTemplateCollectionMutationVariables
>
export const DeleteCompanyFormTemplateCollectionDocument = gql`
  mutation deleteCompanyFormTemplateCollection($input: DeleteCompanyFormTemplateCollectionInput!) {
    deleteCompanyFormTemplateCollection(input: $input) {
      id
      formTemplateCollections {
        ...CompanyFormTemplateCollectionProperties
      }
    }
  }
  ${CompanyFormTemplateCollectionPropertiesDoc}
`
export type DeleteCompanyFormTemplateCollectionMutationFn = Apollo.MutationFunction<
  DeleteCompanyFormTemplateCollectionMutation,
  DeleteCompanyFormTemplateCollectionMutationVariables
>

/**
 * __useDeleteCompanyFormTemplateCollectionMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyFormTemplateCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyFormTemplateCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyFormTemplateCollectionMutation, { data, loading, error }] = useDeleteCompanyFormTemplateCollectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCompanyFormTemplateCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCompanyFormTemplateCollectionMutation,
    DeleteCompanyFormTemplateCollectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteCompanyFormTemplateCollectionMutation,
    DeleteCompanyFormTemplateCollectionMutationVariables
  >(DeleteCompanyFormTemplateCollectionDocument, options)
}
export type DeleteCompanyFormTemplateCollectionMutationHookResult = ReturnType<
  typeof useDeleteCompanyFormTemplateCollectionMutation
>
export type DeleteCompanyFormTemplateCollectionMutationResult =
  Apollo.MutationResult<DeleteCompanyFormTemplateCollectionMutation>
export type DeleteCompanyFormTemplateCollectionMutationOptions = Apollo.BaseMutationOptions<
  DeleteCompanyFormTemplateCollectionMutation,
  DeleteCompanyFormTemplateCollectionMutationVariables
>
export const RotateExternalApiTokenDocument = gql`
  mutation rotateExternalApiToken($companyId: ID!) {
    rotateExternalApiToken(companyId: $companyId)
  }
`
export type RotateExternalApiTokenMutationFn = Apollo.MutationFunction<
  RotateExternalApiTokenMutation,
  RotateExternalApiTokenMutationVariables
>

/**
 * __useRotateExternalApiTokenMutation__
 *
 * To run a mutation, you first call `useRotateExternalApiTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRotateExternalApiTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rotateExternalApiTokenMutation, { data, loading, error }] = useRotateExternalApiTokenMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useRotateExternalApiTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RotateExternalApiTokenMutation,
    RotateExternalApiTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RotateExternalApiTokenMutation,
    RotateExternalApiTokenMutationVariables
  >(RotateExternalApiTokenDocument, options)
}
export type RotateExternalApiTokenMutationHookResult = ReturnType<
  typeof useRotateExternalApiTokenMutation
>
export type RotateExternalApiTokenMutationResult =
  Apollo.MutationResult<RotateExternalApiTokenMutation>
export type RotateExternalApiTokenMutationOptions = Apollo.BaseMutationOptions<
  RotateExternalApiTokenMutation,
  RotateExternalApiTokenMutationVariables
>
export const ArchiveCompanyIntegrationDocument = gql`
  mutation archiveCompanyIntegration($id: ID!) {
    archiveCompanyIntegration(id: $id) {
      ...CompanyIntegrationProperties
    }
  }
  ${CompanyIntegrationPropertiesDoc}
`
export type ArchiveCompanyIntegrationMutationFn = Apollo.MutationFunction<
  ArchiveCompanyIntegrationMutation,
  ArchiveCompanyIntegrationMutationVariables
>

/**
 * __useArchiveCompanyIntegrationMutation__
 *
 * To run a mutation, you first call `useArchiveCompanyIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveCompanyIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveCompanyIntegrationMutation, { data, loading, error }] = useArchiveCompanyIntegrationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveCompanyIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveCompanyIntegrationMutation,
    ArchiveCompanyIntegrationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ArchiveCompanyIntegrationMutation,
    ArchiveCompanyIntegrationMutationVariables
  >(ArchiveCompanyIntegrationDocument, options)
}
export type ArchiveCompanyIntegrationMutationHookResult = ReturnType<
  typeof useArchiveCompanyIntegrationMutation
>
export type ArchiveCompanyIntegrationMutationResult =
  Apollo.MutationResult<ArchiveCompanyIntegrationMutation>
export type ArchiveCompanyIntegrationMutationOptions = Apollo.BaseMutationOptions<
  ArchiveCompanyIntegrationMutation,
  ArchiveCompanyIntegrationMutationVariables
>
export const UnarchiveCompanyIntegrationDocument = gql`
  mutation unarchiveCompanyIntegration($id: ID!) {
    unarchiveCompanyIntegration(id: $id) {
      ...CompanyIntegrationProperties
    }
  }
  ${CompanyIntegrationPropertiesDoc}
`
export type UnarchiveCompanyIntegrationMutationFn = Apollo.MutationFunction<
  UnarchiveCompanyIntegrationMutation,
  UnarchiveCompanyIntegrationMutationVariables
>

/**
 * __useUnarchiveCompanyIntegrationMutation__
 *
 * To run a mutation, you first call `useUnarchiveCompanyIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnarchiveCompanyIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unarchiveCompanyIntegrationMutation, { data, loading, error }] = useUnarchiveCompanyIntegrationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnarchiveCompanyIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnarchiveCompanyIntegrationMutation,
    UnarchiveCompanyIntegrationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UnarchiveCompanyIntegrationMutation,
    UnarchiveCompanyIntegrationMutationVariables
  >(UnarchiveCompanyIntegrationDocument, options)
}
export type UnarchiveCompanyIntegrationMutationHookResult = ReturnType<
  typeof useUnarchiveCompanyIntegrationMutation
>
export type UnarchiveCompanyIntegrationMutationResult =
  Apollo.MutationResult<UnarchiveCompanyIntegrationMutation>
export type UnarchiveCompanyIntegrationMutationOptions = Apollo.BaseMutationOptions<
  UnarchiveCompanyIntegrationMutation,
  UnarchiveCompanyIntegrationMutationVariables
>
export const MigrateIntegrationContractsDocument = gql`
  mutation migrateIntegrationContracts($input: MigrateIntegrationContractsInput!) {
    migrateIntegrationContracts(input: $input) {
      contractsQueued {
        id
        internalProjectNumber
        project {
          id
          name
          projectNumber
        }
      }
      contractsIgnored {
        id
        internalProjectNumber
        project {
          id
          name
          projectNumber
        }
      }
    }
  }
`
export type MigrateIntegrationContractsMutationFn = Apollo.MutationFunction<
  MigrateIntegrationContractsMutation,
  MigrateIntegrationContractsMutationVariables
>

/**
 * __useMigrateIntegrationContractsMutation__
 *
 * To run a mutation, you first call `useMigrateIntegrationContractsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMigrateIntegrationContractsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [migrateIntegrationContractsMutation, { data, loading, error }] = useMigrateIntegrationContractsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMigrateIntegrationContractsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MigrateIntegrationContractsMutation,
    MigrateIntegrationContractsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    MigrateIntegrationContractsMutation,
    MigrateIntegrationContractsMutationVariables
  >(MigrateIntegrationContractsDocument, options)
}
export type MigrateIntegrationContractsMutationHookResult = ReturnType<
  typeof useMigrateIntegrationContractsMutation
>
export type MigrateIntegrationContractsMutationResult =
  Apollo.MutationResult<MigrateIntegrationContractsMutation>
export type MigrateIntegrationContractsMutationOptions = Apollo.BaseMutationOptions<
  MigrateIntegrationContractsMutation,
  MigrateIntegrationContractsMutationVariables
>
export const UpdateIntegrationCredentialDocument = gql`
  mutation updateIntegrationCredential($input: UpdateIntegrationCredentialInput!) {
    updateIntegrationCredential(input: $input) {
      ...CompanyIntegrationProperties
    }
  }
  ${CompanyIntegrationPropertiesDoc}
`
export type UpdateIntegrationCredentialMutationFn = Apollo.MutationFunction<
  UpdateIntegrationCredentialMutation,
  UpdateIntegrationCredentialMutationVariables
>

/**
 * __useUpdateIntegrationCredentialMutation__
 *
 * To run a mutation, you first call `useUpdateIntegrationCredentialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIntegrationCredentialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIntegrationCredentialMutation, { data, loading, error }] = useUpdateIntegrationCredentialMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateIntegrationCredentialMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateIntegrationCredentialMutation,
    UpdateIntegrationCredentialMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateIntegrationCredentialMutation,
    UpdateIntegrationCredentialMutationVariables
  >(UpdateIntegrationCredentialDocument, options)
}
export type UpdateIntegrationCredentialMutationHookResult = ReturnType<
  typeof useUpdateIntegrationCredentialMutation
>
export type UpdateIntegrationCredentialMutationResult =
  Apollo.MutationResult<UpdateIntegrationCredentialMutation>
export type UpdateIntegrationCredentialMutationOptions = Apollo.BaseMutationOptions<
  UpdateIntegrationCredentialMutation,
  UpdateIntegrationCredentialMutationVariables
>
export const ExportIntegrationCredentialsToPostmanDocument = gql`
  mutation exportIntegrationCredentialsToPostman(
    $input: ExportIntegrationCredentialsToPostmanInput!
  ) {
    exportIntegrationCredentialsToPostman(input: $input)
  }
`
export type ExportIntegrationCredentialsToPostmanMutationFn = Apollo.MutationFunction<
  ExportIntegrationCredentialsToPostmanMutation,
  ExportIntegrationCredentialsToPostmanMutationVariables
>

/**
 * __useExportIntegrationCredentialsToPostmanMutation__
 *
 * To run a mutation, you first call `useExportIntegrationCredentialsToPostmanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportIntegrationCredentialsToPostmanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportIntegrationCredentialsToPostmanMutation, { data, loading, error }] = useExportIntegrationCredentialsToPostmanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportIntegrationCredentialsToPostmanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExportIntegrationCredentialsToPostmanMutation,
    ExportIntegrationCredentialsToPostmanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ExportIntegrationCredentialsToPostmanMutation,
    ExportIntegrationCredentialsToPostmanMutationVariables
  >(ExportIntegrationCredentialsToPostmanDocument, options)
}
export type ExportIntegrationCredentialsToPostmanMutationHookResult = ReturnType<
  typeof useExportIntegrationCredentialsToPostmanMutation
>
export type ExportIntegrationCredentialsToPostmanMutationResult =
  Apollo.MutationResult<ExportIntegrationCredentialsToPostmanMutation>
export type ExportIntegrationCredentialsToPostmanMutationOptions = Apollo.BaseMutationOptions<
  ExportIntegrationCredentialsToPostmanMutation,
  ExportIntegrationCredentialsToPostmanMutationVariables
>
export const RotateIntegrationCredentialDocument = gql`
  mutation rotateIntegrationCredential($input: RotateIntegrationCredentialInput!) {
    rotateIntegrationCredential(input: $input) {
      ...CompanyIntegrationProperties
    }
  }
  ${CompanyIntegrationPropertiesDoc}
`
export type RotateIntegrationCredentialMutationFn = Apollo.MutationFunction<
  RotateIntegrationCredentialMutation,
  RotateIntegrationCredentialMutationVariables
>

/**
 * __useRotateIntegrationCredentialMutation__
 *
 * To run a mutation, you first call `useRotateIntegrationCredentialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRotateIntegrationCredentialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rotateIntegrationCredentialMutation, { data, loading, error }] = useRotateIntegrationCredentialMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRotateIntegrationCredentialMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RotateIntegrationCredentialMutation,
    RotateIntegrationCredentialMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RotateIntegrationCredentialMutation,
    RotateIntegrationCredentialMutationVariables
  >(RotateIntegrationCredentialDocument, options)
}
export type RotateIntegrationCredentialMutationHookResult = ReturnType<
  typeof useRotateIntegrationCredentialMutation
>
export type RotateIntegrationCredentialMutationResult =
  Apollo.MutationResult<RotateIntegrationCredentialMutation>
export type RotateIntegrationCredentialMutationOptions = Apollo.BaseMutationOptions<
  RotateIntegrationCredentialMutation,
  RotateIntegrationCredentialMutationVariables
>
export const CompanyForCredentialsDocument = gql`
  query companyForCredentials($id: ID!) {
    company(id: $id) {
      id
      companyIntegrations {
        ...CompanyIntegrationProperties
      }
    }
  }
  ${CompanyIntegrationPropertiesDoc}
`

/**
 * __useCompanyForCredentialsQuery__
 *
 * To run a query within a React component, call `useCompanyForCredentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyForCredentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyForCredentialsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyForCredentialsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyForCredentialsQuery,
    CompanyForCredentialsQueryVariables
  > &
    ({ variables: CompanyForCredentialsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CompanyForCredentialsQuery, CompanyForCredentialsQueryVariables>(
    CompanyForCredentialsDocument,
    options
  )
}
export function useCompanyForCredentialsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyForCredentialsQuery,
    CompanyForCredentialsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CompanyForCredentialsQuery, CompanyForCredentialsQueryVariables>(
    CompanyForCredentialsDocument,
    options
  )
}
export function useCompanyForCredentialsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CompanyForCredentialsQuery,
        CompanyForCredentialsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<CompanyForCredentialsQuery, CompanyForCredentialsQueryVariables>(
    CompanyForCredentialsDocument,
    options
  )
}
export type CompanyForCredentialsQueryHookResult = ReturnType<typeof useCompanyForCredentialsQuery>
export type CompanyForCredentialsLazyQueryHookResult = ReturnType<
  typeof useCompanyForCredentialsLazyQuery
>
export type CompanyForCredentialsSuspenseQueryHookResult = ReturnType<
  typeof useCompanyForCredentialsSuspenseQuery
>
export type CompanyForCredentialsQueryResult = Apollo.QueryResult<
  CompanyForCredentialsQuery,
  CompanyForCredentialsQueryVariables
>
export const AgaveDebuggerSessionUrlDocument = gql`
  query agaveDebuggerSessionUrl($input: AgaveDebuggerSessionUrlInput!) {
    agaveDebuggerSessionUrl(input: $input)
  }
`

/**
 * __useAgaveDebuggerSessionUrlQuery__
 *
 * To run a query within a React component, call `useAgaveDebuggerSessionUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgaveDebuggerSessionUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgaveDebuggerSessionUrlQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAgaveDebuggerSessionUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    AgaveDebuggerSessionUrlQuery,
    AgaveDebuggerSessionUrlQueryVariables
  > &
    ({ variables: AgaveDebuggerSessionUrlQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AgaveDebuggerSessionUrlQuery, AgaveDebuggerSessionUrlQueryVariables>(
    AgaveDebuggerSessionUrlDocument,
    options
  )
}
export function useAgaveDebuggerSessionUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AgaveDebuggerSessionUrlQuery,
    AgaveDebuggerSessionUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AgaveDebuggerSessionUrlQuery, AgaveDebuggerSessionUrlQueryVariables>(
    AgaveDebuggerSessionUrlDocument,
    options
  )
}
export function useAgaveDebuggerSessionUrlSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AgaveDebuggerSessionUrlQuery,
        AgaveDebuggerSessionUrlQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    AgaveDebuggerSessionUrlQuery,
    AgaveDebuggerSessionUrlQueryVariables
  >(AgaveDebuggerSessionUrlDocument, options)
}
export type AgaveDebuggerSessionUrlQueryHookResult = ReturnType<
  typeof useAgaveDebuggerSessionUrlQuery
>
export type AgaveDebuggerSessionUrlLazyQueryHookResult = ReturnType<
  typeof useAgaveDebuggerSessionUrlLazyQuery
>
export type AgaveDebuggerSessionUrlSuspenseQueryHookResult = ReturnType<
  typeof useAgaveDebuggerSessionUrlSuspenseQuery
>
export type AgaveDebuggerSessionUrlQueryResult = Apollo.QueryResult<
  AgaveDebuggerSessionUrlQuery,
  AgaveDebuggerSessionUrlQueryVariables
>
export const UpdateLocationRequestDocument = gql`
  mutation updateLocationRequest($input: UpdateLocationInput!) {
    updateLocation(input: $input) {
      ...LocationProperties
    }
  }
  ${LocationPropertiesDoc}
`
export type UpdateLocationRequestMutationFn = Apollo.MutationFunction<
  UpdateLocationRequestMutation,
  UpdateLocationRequestMutationVariables
>

/**
 * __useUpdateLocationRequestMutation__
 *
 * To run a mutation, you first call `useUpdateLocationRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationRequestMutation, { data, loading, error }] = useUpdateLocationRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLocationRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLocationRequestMutation,
    UpdateLocationRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateLocationRequestMutation, UpdateLocationRequestMutationVariables>(
    UpdateLocationRequestDocument,
    options
  )
}
export type UpdateLocationRequestMutationHookResult = ReturnType<
  typeof useUpdateLocationRequestMutation
>
export type UpdateLocationRequestMutationResult =
  Apollo.MutationResult<UpdateLocationRequestMutation>
export type UpdateLocationRequestMutationOptions = Apollo.BaseMutationOptions<
  UpdateLocationRequestMutation,
  UpdateLocationRequestMutationVariables
>
export const CreateLocationRequestDocument = gql`
  mutation createLocationRequest($input: CreateLocationInput!) {
    createLocation(input: $input) {
      ...LocationProperties
    }
  }
  ${LocationPropertiesDoc}
`
export type CreateLocationRequestMutationFn = Apollo.MutationFunction<
  CreateLocationRequestMutation,
  CreateLocationRequestMutationVariables
>

/**
 * __useCreateLocationRequestMutation__
 *
 * To run a mutation, you first call `useCreateLocationRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLocationRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLocationRequestMutation, { data, loading, error }] = useCreateLocationRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLocationRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateLocationRequestMutation,
    CreateLocationRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateLocationRequestMutation, CreateLocationRequestMutationVariables>(
    CreateLocationRequestDocument,
    options
  )
}
export type CreateLocationRequestMutationHookResult = ReturnType<
  typeof useCreateLocationRequestMutation
>
export type CreateLocationRequestMutationResult =
  Apollo.MutationResult<CreateLocationRequestMutation>
export type CreateLocationRequestMutationOptions = Apollo.BaseMutationOptions<
  CreateLocationRequestMutation,
  CreateLocationRequestMutationVariables
>
export const SetCompanyInternalSitelineNotesDocument = gql`
  mutation setCompanyInternalSitelineNotes($input: SetCompanyInternalSitelineNotesInput!) {
    setCompanyInternalSitelineNotes(input: $input)
  }
`
export type SetCompanyInternalSitelineNotesMutationFn = Apollo.MutationFunction<
  SetCompanyInternalSitelineNotesMutation,
  SetCompanyInternalSitelineNotesMutationVariables
>

/**
 * __useSetCompanyInternalSitelineNotesMutation__
 *
 * To run a mutation, you first call `useSetCompanyInternalSitelineNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCompanyInternalSitelineNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCompanyInternalSitelineNotesMutation, { data, loading, error }] = useSetCompanyInternalSitelineNotesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetCompanyInternalSitelineNotesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetCompanyInternalSitelineNotesMutation,
    SetCompanyInternalSitelineNotesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SetCompanyInternalSitelineNotesMutation,
    SetCompanyInternalSitelineNotesMutationVariables
  >(SetCompanyInternalSitelineNotesDocument, options)
}
export type SetCompanyInternalSitelineNotesMutationHookResult = ReturnType<
  typeof useSetCompanyInternalSitelineNotesMutation
>
export type SetCompanyInternalSitelineNotesMutationResult =
  Apollo.MutationResult<SetCompanyInternalSitelineNotesMutation>
export type SetCompanyInternalSitelineNotesMutationOptions = Apollo.BaseMutationOptions<
  SetCompanyInternalSitelineNotesMutation,
  SetCompanyInternalSitelineNotesMutationVariables
>
export const CompanyInternalSitelineNotesDocument = gql`
  query companyInternalSitelineNotes($companyId: ID!) {
    companyInternalSitelineNotes(companyId: $companyId)
  }
`

/**
 * __useCompanyInternalSitelineNotesQuery__
 *
 * To run a query within a React component, call `useCompanyInternalSitelineNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyInternalSitelineNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyInternalSitelineNotesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyInternalSitelineNotesQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyInternalSitelineNotesQuery,
    CompanyInternalSitelineNotesQueryVariables
  > &
    ({ variables: CompanyInternalSitelineNotesQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CompanyInternalSitelineNotesQuery,
    CompanyInternalSitelineNotesQueryVariables
  >(CompanyInternalSitelineNotesDocument, options)
}
export function useCompanyInternalSitelineNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyInternalSitelineNotesQuery,
    CompanyInternalSitelineNotesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CompanyInternalSitelineNotesQuery,
    CompanyInternalSitelineNotesQueryVariables
  >(CompanyInternalSitelineNotesDocument, options)
}
export function useCompanyInternalSitelineNotesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CompanyInternalSitelineNotesQuery,
        CompanyInternalSitelineNotesQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    CompanyInternalSitelineNotesQuery,
    CompanyInternalSitelineNotesQueryVariables
  >(CompanyInternalSitelineNotesDocument, options)
}
export type CompanyInternalSitelineNotesQueryHookResult = ReturnType<
  typeof useCompanyInternalSitelineNotesQuery
>
export type CompanyInternalSitelineNotesLazyQueryHookResult = ReturnType<
  typeof useCompanyInternalSitelineNotesLazyQuery
>
export type CompanyInternalSitelineNotesSuspenseQueryHookResult = ReturnType<
  typeof useCompanyInternalSitelineNotesSuspenseQuery
>
export type CompanyInternalSitelineNotesQueryResult = Apollo.QueryResult<
  CompanyInternalSitelineNotesQuery,
  CompanyInternalSitelineNotesQueryVariables
>
export const ProjectsByCompanyDocument = gql`
  query projectsByCompany($id: ID!) {
    projectsByCompany(id: $id) {
      ...ProjectProperties
      contracts {
        id
        contractNumber
        company {
          id
          name
        }
      }
    }
  }
  ${ProjectPropertiesDoc}
`

/**
 * __useProjectsByCompanyQuery__
 *
 * To run a query within a React component, call `useProjectsByCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsByCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsByCompanyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectsByCompanyQuery(
  baseOptions: Apollo.QueryHookOptions<ProjectsByCompanyQuery, ProjectsByCompanyQueryVariables> &
    ({ variables: ProjectsByCompanyQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ProjectsByCompanyQuery, ProjectsByCompanyQueryVariables>(
    ProjectsByCompanyDocument,
    options
  )
}
export function useProjectsByCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProjectsByCompanyQuery, ProjectsByCompanyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ProjectsByCompanyQuery, ProjectsByCompanyQueryVariables>(
    ProjectsByCompanyDocument,
    options
  )
}
export function useProjectsByCompanySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ProjectsByCompanyQuery, ProjectsByCompanyQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ProjectsByCompanyQuery, ProjectsByCompanyQueryVariables>(
    ProjectsByCompanyDocument,
    options
  )
}
export type ProjectsByCompanyQueryHookResult = ReturnType<typeof useProjectsByCompanyQuery>
export type ProjectsByCompanyLazyQueryHookResult = ReturnType<typeof useProjectsByCompanyLazyQuery>
export type ProjectsByCompanySuspenseQueryHookResult = ReturnType<
  typeof useProjectsByCompanySuspenseQuery
>
export type ProjectsByCompanyQueryResult = Apollo.QueryResult<
  ProjectsByCompanyQuery,
  ProjectsByCompanyQueryVariables
>
export const DisableUserDocument = gql`
  mutation disableUser($input: DisableUserInput!) {
    disableUser(input: $input) {
      ...UserProperties
    }
  }
  ${UserPropertiesDoc}
`
export type DisableUserMutationFn = Apollo.MutationFunction<
  DisableUserMutation,
  DisableUserMutationVariables
>

/**
 * __useDisableUserMutation__
 *
 * To run a mutation, you first call `useDisableUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableUserMutation, { data, loading, error }] = useDisableUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDisableUserMutation(
  baseOptions?: Apollo.MutationHookOptions<DisableUserMutation, DisableUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DisableUserMutation, DisableUserMutationVariables>(
    DisableUserDocument,
    options
  )
}
export type DisableUserMutationHookResult = ReturnType<typeof useDisableUserMutation>
export type DisableUserMutationResult = Apollo.MutationResult<DisableUserMutation>
export type DisableUserMutationOptions = Apollo.BaseMutationOptions<
  DisableUserMutation,
  DisableUserMutationVariables
>
export const EnableUserDocument = gql`
  mutation enableUser($input: EnableUserInput!) {
    enableUser(input: $input) {
      ...UserProperties
    }
  }
  ${UserPropertiesDoc}
`
export type EnableUserMutationFn = Apollo.MutationFunction<
  EnableUserMutation,
  EnableUserMutationVariables
>

/**
 * __useEnableUserMutation__
 *
 * To run a mutation, you first call `useEnableUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableUserMutation, { data, loading, error }] = useEnableUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnableUserMutation(
  baseOptions?: Apollo.MutationHookOptions<EnableUserMutation, EnableUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<EnableUserMutation, EnableUserMutationVariables>(
    EnableUserDocument,
    options
  )
}
export type EnableUserMutationHookResult = ReturnType<typeof useEnableUserMutation>
export type EnableUserMutationResult = Apollo.MutationResult<EnableUserMutation>
export type EnableUserMutationOptions = Apollo.BaseMutationOptions<
  EnableUserMutation,
  EnableUserMutationVariables
>
export const DeleteVendorDocument = gql`
  mutation deleteVendor($id: ID!) {
    deleteVendor(id: $id) {
      id
    }
  }
`
export type DeleteVendorMutationFn = Apollo.MutationFunction<
  DeleteVendorMutation,
  DeleteVendorMutationVariables
>

/**
 * __useDeleteVendorMutation__
 *
 * To run a mutation, you first call `useDeleteVendorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVendorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVendorMutation, { data, loading, error }] = useDeleteVendorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteVendorMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteVendorMutation, DeleteVendorMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteVendorMutation, DeleteVendorMutationVariables>(
    DeleteVendorDocument,
    options
  )
}
export type DeleteVendorMutationHookResult = ReturnType<typeof useDeleteVendorMutation>
export type DeleteVendorMutationResult = Apollo.MutationResult<DeleteVendorMutation>
export type DeleteVendorMutationOptions = Apollo.BaseMutationOptions<
  DeleteVendorMutation,
  DeleteVendorMutationVariables
>
export const AgaveLinkTokenDocument = gql`
  query agaveLinkToken($companyIntegrationId: ID!) {
    agaveLinkToken(companyIntegrationId: $companyIntegrationId)
  }
`

/**
 * __useAgaveLinkTokenQuery__
 *
 * To run a query within a React component, call `useAgaveLinkTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgaveLinkTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgaveLinkTokenQuery({
 *   variables: {
 *      companyIntegrationId: // value for 'companyIntegrationId'
 *   },
 * });
 */
export function useAgaveLinkTokenQuery(
  baseOptions: Apollo.QueryHookOptions<AgaveLinkTokenQuery, AgaveLinkTokenQueryVariables> &
    ({ variables: AgaveLinkTokenQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AgaveLinkTokenQuery, AgaveLinkTokenQueryVariables>(
    AgaveLinkTokenDocument,
    options
  )
}
export function useAgaveLinkTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AgaveLinkTokenQuery, AgaveLinkTokenQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AgaveLinkTokenQuery, AgaveLinkTokenQueryVariables>(
    AgaveLinkTokenDocument,
    options
  )
}
export function useAgaveLinkTokenSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AgaveLinkTokenQuery, AgaveLinkTokenQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<AgaveLinkTokenQuery, AgaveLinkTokenQueryVariables>(
    AgaveLinkTokenDocument,
    options
  )
}
export type AgaveLinkTokenQueryHookResult = ReturnType<typeof useAgaveLinkTokenQuery>
export type AgaveLinkTokenLazyQueryHookResult = ReturnType<typeof useAgaveLinkTokenLazyQuery>
export type AgaveLinkTokenSuspenseQueryHookResult = ReturnType<
  typeof useAgaveLinkTokenSuspenseQuery
>
export type AgaveLinkTokenQueryResult = Apollo.QueryResult<
  AgaveLinkTokenQuery,
  AgaveLinkTokenQueryVariables
>
export const UpdateAgaveIntegrationCredentialDocument = gql`
  mutation updateAgaveIntegrationCredential($input: UpdateAgaveIntegrationCredentialInput!) {
    updateAgaveIntegrationCredential(input: $input) {
      id
      credentialsUpdatedAt
    }
  }
`
export type UpdateAgaveIntegrationCredentialMutationFn = Apollo.MutationFunction<
  UpdateAgaveIntegrationCredentialMutation,
  UpdateAgaveIntegrationCredentialMutationVariables
>

/**
 * __useUpdateAgaveIntegrationCredentialMutation__
 *
 * To run a mutation, you first call `useUpdateAgaveIntegrationCredentialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAgaveIntegrationCredentialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAgaveIntegrationCredentialMutation, { data, loading, error }] = useUpdateAgaveIntegrationCredentialMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAgaveIntegrationCredentialMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAgaveIntegrationCredentialMutation,
    UpdateAgaveIntegrationCredentialMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateAgaveIntegrationCredentialMutation,
    UpdateAgaveIntegrationCredentialMutationVariables
  >(UpdateAgaveIntegrationCredentialDocument, options)
}
export type UpdateAgaveIntegrationCredentialMutationHookResult = ReturnType<
  typeof useUpdateAgaveIntegrationCredentialMutation
>
export type UpdateAgaveIntegrationCredentialMutationResult =
  Apollo.MutationResult<UpdateAgaveIntegrationCredentialMutation>
export type UpdateAgaveIntegrationCredentialMutationOptions = Apollo.BaseMutationOptions<
  UpdateAgaveIntegrationCredentialMutation,
  UpdateAgaveIntegrationCredentialMutationVariables
>
export const IntegrationOnePasswordItemsDocument = gql`
  query integrationOnePasswordItems {
    integrationOnePasswordItems {
      vaultId
      itemId
      title
    }
  }
`

/**
 * __useIntegrationOnePasswordItemsQuery__
 *
 * To run a query within a React component, call `useIntegrationOnePasswordItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntegrationOnePasswordItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntegrationOnePasswordItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useIntegrationOnePasswordItemsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IntegrationOnePasswordItemsQuery,
    IntegrationOnePasswordItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IntegrationOnePasswordItemsQuery,
    IntegrationOnePasswordItemsQueryVariables
  >(IntegrationOnePasswordItemsDocument, options)
}
export function useIntegrationOnePasswordItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IntegrationOnePasswordItemsQuery,
    IntegrationOnePasswordItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IntegrationOnePasswordItemsQuery,
    IntegrationOnePasswordItemsQueryVariables
  >(IntegrationOnePasswordItemsDocument, options)
}
export function useIntegrationOnePasswordItemsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        IntegrationOnePasswordItemsQuery,
        IntegrationOnePasswordItemsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    IntegrationOnePasswordItemsQuery,
    IntegrationOnePasswordItemsQueryVariables
  >(IntegrationOnePasswordItemsDocument, options)
}
export type IntegrationOnePasswordItemsQueryHookResult = ReturnType<
  typeof useIntegrationOnePasswordItemsQuery
>
export type IntegrationOnePasswordItemsLazyQueryHookResult = ReturnType<
  typeof useIntegrationOnePasswordItemsLazyQuery
>
export type IntegrationOnePasswordItemsSuspenseQueryHookResult = ReturnType<
  typeof useIntegrationOnePasswordItemsSuspenseQuery
>
export type IntegrationOnePasswordItemsQueryResult = Apollo.QueryResult<
  IntegrationOnePasswordItemsQuery,
  IntegrationOnePasswordItemsQueryVariables
>
export const EnableIntegrationCredentialAutoRotationDocument = gql`
  mutation enableIntegrationCredentialAutoRotation(
    $input: EnableIntegrationCredentialAutoRotationInput!
  ) {
    enableIntegrationCredentialAutoRotation(input: $input) {
      id
      credentialsUpdatedAt
      credentialsReadyForAutoRotation
    }
  }
`
export type EnableIntegrationCredentialAutoRotationMutationFn = Apollo.MutationFunction<
  EnableIntegrationCredentialAutoRotationMutation,
  EnableIntegrationCredentialAutoRotationMutationVariables
>

/**
 * __useEnableIntegrationCredentialAutoRotationMutation__
 *
 * To run a mutation, you first call `useEnableIntegrationCredentialAutoRotationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableIntegrationCredentialAutoRotationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableIntegrationCredentialAutoRotationMutation, { data, loading, error }] = useEnableIntegrationCredentialAutoRotationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnableIntegrationCredentialAutoRotationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EnableIntegrationCredentialAutoRotationMutation,
    EnableIntegrationCredentialAutoRotationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    EnableIntegrationCredentialAutoRotationMutation,
    EnableIntegrationCredentialAutoRotationMutationVariables
  >(EnableIntegrationCredentialAutoRotationDocument, options)
}
export type EnableIntegrationCredentialAutoRotationMutationHookResult = ReturnType<
  typeof useEnableIntegrationCredentialAutoRotationMutation
>
export type EnableIntegrationCredentialAutoRotationMutationResult =
  Apollo.MutationResult<EnableIntegrationCredentialAutoRotationMutation>
export type EnableIntegrationCredentialAutoRotationMutationOptions = Apollo.BaseMutationOptions<
  EnableIntegrationCredentialAutoRotationMutation,
  EnableIntegrationCredentialAutoRotationMutationVariables
>
export const GetGeneralContractorsDocument = gql`
  query getGeneralContractors($companyId: ID!) {
    generalContractors(companyId: $companyId) {
      id
      name
    }
  }
`

/**
 * __useGetGeneralContractorsQuery__
 *
 * To run a query within a React component, call `useGetGeneralContractorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneralContractorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneralContractorsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetGeneralContractorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetGeneralContractorsQuery,
    GetGeneralContractorsQueryVariables
  > &
    ({ variables: GetGeneralContractorsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetGeneralContractorsQuery, GetGeneralContractorsQueryVariables>(
    GetGeneralContractorsDocument,
    options
  )
}
export function useGetGeneralContractorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGeneralContractorsQuery,
    GetGeneralContractorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetGeneralContractorsQuery, GetGeneralContractorsQueryVariables>(
    GetGeneralContractorsDocument,
    options
  )
}
export function useGetGeneralContractorsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetGeneralContractorsQuery,
        GetGeneralContractorsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetGeneralContractorsQuery, GetGeneralContractorsQueryVariables>(
    GetGeneralContractorsDocument,
    options
  )
}
export type GetGeneralContractorsQueryHookResult = ReturnType<typeof useGetGeneralContractorsQuery>
export type GetGeneralContractorsLazyQueryHookResult = ReturnType<
  typeof useGetGeneralContractorsLazyQuery
>
export type GetGeneralContractorsSuspenseQueryHookResult = ReturnType<
  typeof useGetGeneralContractorsSuspenseQuery
>
export type GetGeneralContractorsQueryResult = Apollo.QueryResult<
  GetGeneralContractorsQuery,
  GetGeneralContractorsQueryVariables
>
export const CreateLienWaiverFormTemplateSetDocument = gql`
  mutation createLienWaiverFormTemplateSet($input: CreateLienWaiverFormTemplateSetInput!) {
    createLienWaiverFormTemplateSet(input: $input) {
      id
      primaryLienWaiverTemplateSets {
        ...CompanyLienWaiverTemplateSetProperties
      }
      vendorLienWaiverTemplateSets {
        ...CompanyLienWaiverTemplateSetProperties
      }
    }
  }
  ${CompanyLienWaiverTemplateSetPropertiesDoc}
`
export type CreateLienWaiverFormTemplateSetMutationFn = Apollo.MutationFunction<
  CreateLienWaiverFormTemplateSetMutation,
  CreateLienWaiverFormTemplateSetMutationVariables
>

/**
 * __useCreateLienWaiverFormTemplateSetMutation__
 *
 * To run a mutation, you first call `useCreateLienWaiverFormTemplateSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLienWaiverFormTemplateSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLienWaiverFormTemplateSetMutation, { data, loading, error }] = useCreateLienWaiverFormTemplateSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLienWaiverFormTemplateSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateLienWaiverFormTemplateSetMutation,
    CreateLienWaiverFormTemplateSetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateLienWaiverFormTemplateSetMutation,
    CreateLienWaiverFormTemplateSetMutationVariables
  >(CreateLienWaiverFormTemplateSetDocument, options)
}
export type CreateLienWaiverFormTemplateSetMutationHookResult = ReturnType<
  typeof useCreateLienWaiverFormTemplateSetMutation
>
export type CreateLienWaiverFormTemplateSetMutationResult =
  Apollo.MutationResult<CreateLienWaiverFormTemplateSetMutation>
export type CreateLienWaiverFormTemplateSetMutationOptions = Apollo.BaseMutationOptions<
  CreateLienWaiverFormTemplateSetMutation,
  CreateLienWaiverFormTemplateSetMutationVariables
>
export const UpdateLienWaiverFormTemplateSetDocument = gql`
  mutation updateLienWaiverFormTemplateSet($input: UpdateLienWaiverFormTemplateSetInput!) {
    updateLienWaiverFormTemplateSet(input: $input) {
      id
      primaryLienWaiverTemplateSets {
        ...CompanyLienWaiverTemplateSetProperties
      }
      vendorLienWaiverTemplateSets {
        ...CompanyLienWaiverTemplateSetProperties
      }
    }
  }
  ${CompanyLienWaiverTemplateSetPropertiesDoc}
`
export type UpdateLienWaiverFormTemplateSetMutationFn = Apollo.MutationFunction<
  UpdateLienWaiverFormTemplateSetMutation,
  UpdateLienWaiverFormTemplateSetMutationVariables
>

/**
 * __useUpdateLienWaiverFormTemplateSetMutation__
 *
 * To run a mutation, you first call `useUpdateLienWaiverFormTemplateSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLienWaiverFormTemplateSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLienWaiverFormTemplateSetMutation, { data, loading, error }] = useUpdateLienWaiverFormTemplateSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLienWaiverFormTemplateSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLienWaiverFormTemplateSetMutation,
    UpdateLienWaiverFormTemplateSetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateLienWaiverFormTemplateSetMutation,
    UpdateLienWaiverFormTemplateSetMutationVariables
  >(UpdateLienWaiverFormTemplateSetDocument, options)
}
export type UpdateLienWaiverFormTemplateSetMutationHookResult = ReturnType<
  typeof useUpdateLienWaiverFormTemplateSetMutation
>
export type UpdateLienWaiverFormTemplateSetMutationResult =
  Apollo.MutationResult<UpdateLienWaiverFormTemplateSetMutation>
export type UpdateLienWaiverFormTemplateSetMutationOptions = Apollo.BaseMutationOptions<
  UpdateLienWaiverFormTemplateSetMutation,
  UpdateLienWaiverFormTemplateSetMutationVariables
>
export const DeleteLienWaiverFormTemplateSetDocument = gql`
  mutation deleteLienWaiverFormTemplateSet($input: DeleteLienWaiverFormTemplateSetInput!) {
    deleteLienWaiverFormTemplateSet(input: $input) {
      id
      primaryLienWaiverTemplateSets {
        ...CompanyLienWaiverTemplateSetProperties
      }
      vendorLienWaiverTemplateSets {
        ...CompanyLienWaiverTemplateSetProperties
      }
    }
  }
  ${CompanyLienWaiverTemplateSetPropertiesDoc}
`
export type DeleteLienWaiverFormTemplateSetMutationFn = Apollo.MutationFunction<
  DeleteLienWaiverFormTemplateSetMutation,
  DeleteLienWaiverFormTemplateSetMutationVariables
>

/**
 * __useDeleteLienWaiverFormTemplateSetMutation__
 *
 * To run a mutation, you first call `useDeleteLienWaiverFormTemplateSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLienWaiverFormTemplateSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLienWaiverFormTemplateSetMutation, { data, loading, error }] = useDeleteLienWaiverFormTemplateSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteLienWaiverFormTemplateSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteLienWaiverFormTemplateSetMutation,
    DeleteLienWaiverFormTemplateSetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteLienWaiverFormTemplateSetMutation,
    DeleteLienWaiverFormTemplateSetMutationVariables
  >(DeleteLienWaiverFormTemplateSetDocument, options)
}
export type DeleteLienWaiverFormTemplateSetMutationHookResult = ReturnType<
  typeof useDeleteLienWaiverFormTemplateSetMutation
>
export type DeleteLienWaiverFormTemplateSetMutationResult =
  Apollo.MutationResult<DeleteLienWaiverFormTemplateSetMutation>
export type DeleteLienWaiverFormTemplateSetMutationOptions = Apollo.BaseMutationOptions<
  DeleteLienWaiverFormTemplateSetMutation,
  DeleteLienWaiverFormTemplateSetMutationVariables
>
export const CreatePayAppFormTemplateSetDocument = gql`
  mutation createPayAppFormTemplateSet($input: CreatePayAppFormTemplateSetInput!) {
    createPayAppFormTemplateSet(input: $input) {
      id
      payAppFormTemplateSets {
        ...CompanyPayAppFormTemplateSetProperties
      }
    }
  }
  ${CompanyPayAppFormTemplateSetPropertiesDoc}
`
export type CreatePayAppFormTemplateSetMutationFn = Apollo.MutationFunction<
  CreatePayAppFormTemplateSetMutation,
  CreatePayAppFormTemplateSetMutationVariables
>

/**
 * __useCreatePayAppFormTemplateSetMutation__
 *
 * To run a mutation, you first call `useCreatePayAppFormTemplateSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePayAppFormTemplateSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPayAppFormTemplateSetMutation, { data, loading, error }] = useCreatePayAppFormTemplateSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePayAppFormTemplateSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePayAppFormTemplateSetMutation,
    CreatePayAppFormTemplateSetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreatePayAppFormTemplateSetMutation,
    CreatePayAppFormTemplateSetMutationVariables
  >(CreatePayAppFormTemplateSetDocument, options)
}
export type CreatePayAppFormTemplateSetMutationHookResult = ReturnType<
  typeof useCreatePayAppFormTemplateSetMutation
>
export type CreatePayAppFormTemplateSetMutationResult =
  Apollo.MutationResult<CreatePayAppFormTemplateSetMutation>
export type CreatePayAppFormTemplateSetMutationOptions = Apollo.BaseMutationOptions<
  CreatePayAppFormTemplateSetMutation,
  CreatePayAppFormTemplateSetMutationVariables
>
export const UpdatePayAppFormTemplateSetDocument = gql`
  mutation updatePayAppFormTemplateSet($input: UpdatePayAppFormTemplateSetInput!) {
    updatePayAppFormTemplateSet(input: $input) {
      id
      payAppFormTemplateSets {
        ...CompanyPayAppFormTemplateSetProperties
      }
    }
  }
  ${CompanyPayAppFormTemplateSetPropertiesDoc}
`
export type UpdatePayAppFormTemplateSetMutationFn = Apollo.MutationFunction<
  UpdatePayAppFormTemplateSetMutation,
  UpdatePayAppFormTemplateSetMutationVariables
>

/**
 * __useUpdatePayAppFormTemplateSetMutation__
 *
 * To run a mutation, you first call `useUpdatePayAppFormTemplateSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePayAppFormTemplateSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePayAppFormTemplateSetMutation, { data, loading, error }] = useUpdatePayAppFormTemplateSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePayAppFormTemplateSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePayAppFormTemplateSetMutation,
    UpdatePayAppFormTemplateSetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdatePayAppFormTemplateSetMutation,
    UpdatePayAppFormTemplateSetMutationVariables
  >(UpdatePayAppFormTemplateSetDocument, options)
}
export type UpdatePayAppFormTemplateSetMutationHookResult = ReturnType<
  typeof useUpdatePayAppFormTemplateSetMutation
>
export type UpdatePayAppFormTemplateSetMutationResult =
  Apollo.MutationResult<UpdatePayAppFormTemplateSetMutation>
export type UpdatePayAppFormTemplateSetMutationOptions = Apollo.BaseMutationOptions<
  UpdatePayAppFormTemplateSetMutation,
  UpdatePayAppFormTemplateSetMutationVariables
>
export const DeletePayAppFormTemplateSetDocument = gql`
  mutation deletePayAppFormTemplateSet($input: DeletePayAppFormTemplateSetInput!) {
    deletePayAppFormTemplateSet(input: $input) {
      id
      payAppFormTemplateSets {
        ...CompanyPayAppFormTemplateSetProperties
      }
    }
  }
  ${CompanyPayAppFormTemplateSetPropertiesDoc}
`
export type DeletePayAppFormTemplateSetMutationFn = Apollo.MutationFunction<
  DeletePayAppFormTemplateSetMutation,
  DeletePayAppFormTemplateSetMutationVariables
>

/**
 * __useDeletePayAppFormTemplateSetMutation__
 *
 * To run a mutation, you first call `useDeletePayAppFormTemplateSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePayAppFormTemplateSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePayAppFormTemplateSetMutation, { data, loading, error }] = useDeletePayAppFormTemplateSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePayAppFormTemplateSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePayAppFormTemplateSetMutation,
    DeletePayAppFormTemplateSetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeletePayAppFormTemplateSetMutation,
    DeletePayAppFormTemplateSetMutationVariables
  >(DeletePayAppFormTemplateSetDocument, options)
}
export type DeletePayAppFormTemplateSetMutationHookResult = ReturnType<
  typeof useDeletePayAppFormTemplateSetMutation
>
export type DeletePayAppFormTemplateSetMutationResult =
  Apollo.MutationResult<DeletePayAppFormTemplateSetMutation>
export type DeletePayAppFormTemplateSetMutationOptions = Apollo.BaseMutationOptions<
  DeletePayAppFormTemplateSetMutation,
  DeletePayAppFormTemplateSetMutationVariables
>
export const GetContractDocument = gql`
  query getContract($id: ID!) {
    contract(id: $id) {
      ...ContractProperties
      project {
        ...ProjectProperties
      }
      company {
        ...CompanyProperties
        users {
          ...CompanyUserProperties
        }
        companyIntegrations {
          ...CompanyIntegrationProperties
        }
      }
      users {
        id
        user {
          ...UserProperties
        }
      }
      payApps {
        ...PayAppNoProgressProperties
        payAppNumber
      }
    }
  }
  ${ContractPropertiesDoc}
  ${ProjectPropertiesDoc}
  ${CompanyPropertiesDoc}
  ${CompanyUserPropertiesDoc}
  ${CompanyIntegrationPropertiesDoc}
  ${UserPropertiesDoc}
  ${PayAppNoProgressPropertiesDoc}
`

/**
 * __useGetContractQuery__
 *
 * To run a query within a React component, call `useGetContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContractQuery(
  baseOptions: Apollo.QueryHookOptions<GetContractQuery, GetContractQueryVariables> &
    ({ variables: GetContractQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetContractQuery, GetContractQueryVariables>(GetContractDocument, options)
}
export function useGetContractLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetContractQuery, GetContractQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetContractQuery, GetContractQueryVariables>(
    GetContractDocument,
    options
  )
}
export function useGetContractSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetContractQuery, GetContractQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetContractQuery, GetContractQueryVariables>(
    GetContractDocument,
    options
  )
}
export type GetContractQueryHookResult = ReturnType<typeof useGetContractQuery>
export type GetContractLazyQueryHookResult = ReturnType<typeof useGetContractLazyQuery>
export type GetContractSuspenseQueryHookResult = ReturnType<typeof useGetContractSuspenseQuery>
export type GetContractQueryResult = Apollo.QueryResult<GetContractQuery, GetContractQueryVariables>
export const UpdateOnboardedProjectContractStatusDocument = gql`
  mutation updateOnboardedProjectContractStatus(
    $input: UpdateOnboardedProjectContractStatusInput!
  ) {
    updateOnboardedProjectContractStatus(input: $input) {
      id
      onboardedStatus {
        selectedPayAppForms
        onboardedPayAppForms
        selectedPrimaryLienWaivers
        onboardedPrimaryLienWaiverForms
        selectedVendorLienWaivers
        onboardedVendorLienWaiverForms
        selectedChangeOrderRequestForms
        onboardedChangeOrderRequestForms
        addedSov
        selectedRateTable
        addedTeammates
        addedGcContacts
        startedBilling
        notifiedOnboardedForms
      }
    }
  }
`
export type UpdateOnboardedProjectContractStatusMutationFn = Apollo.MutationFunction<
  UpdateOnboardedProjectContractStatusMutation,
  UpdateOnboardedProjectContractStatusMutationVariables
>

/**
 * __useUpdateOnboardedProjectContractStatusMutation__
 *
 * To run a mutation, you first call `useUpdateOnboardedProjectContractStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOnboardedProjectContractStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOnboardedProjectContractStatusMutation, { data, loading, error }] = useUpdateOnboardedProjectContractStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOnboardedProjectContractStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOnboardedProjectContractStatusMutation,
    UpdateOnboardedProjectContractStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateOnboardedProjectContractStatusMutation,
    UpdateOnboardedProjectContractStatusMutationVariables
  >(UpdateOnboardedProjectContractStatusDocument, options)
}
export type UpdateOnboardedProjectContractStatusMutationHookResult = ReturnType<
  typeof useUpdateOnboardedProjectContractStatusMutation
>
export type UpdateOnboardedProjectContractStatusMutationResult =
  Apollo.MutationResult<UpdateOnboardedProjectContractStatusMutation>
export type UpdateOnboardedProjectContractStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateOnboardedProjectContractStatusMutation,
  UpdateOnboardedProjectContractStatusMutationVariables
>
export const UpdatePayAppRequirementGroupsDocument = gql`
  mutation updatePayAppRequirementGroups($input: UpdatePayAppRequirementGroupsInput!) {
    updatePayAppRequirementGroups(input: $input) {
      ...ContractProperties
    }
  }
  ${ContractPropertiesDoc}
`
export type UpdatePayAppRequirementGroupsMutationFn = Apollo.MutationFunction<
  UpdatePayAppRequirementGroupsMutation,
  UpdatePayAppRequirementGroupsMutationVariables
>

/**
 * __useUpdatePayAppRequirementGroupsMutation__
 *
 * To run a mutation, you first call `useUpdatePayAppRequirementGroupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePayAppRequirementGroupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePayAppRequirementGroupsMutation, { data, loading, error }] = useUpdatePayAppRequirementGroupsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePayAppRequirementGroupsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePayAppRequirementGroupsMutation,
    UpdatePayAppRequirementGroupsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdatePayAppRequirementGroupsMutation,
    UpdatePayAppRequirementGroupsMutationVariables
  >(UpdatePayAppRequirementGroupsDocument, options)
}
export type UpdatePayAppRequirementGroupsMutationHookResult = ReturnType<
  typeof useUpdatePayAppRequirementGroupsMutation
>
export type UpdatePayAppRequirementGroupsMutationResult =
  Apollo.MutationResult<UpdatePayAppRequirementGroupsMutation>
export type UpdatePayAppRequirementGroupsMutationOptions = Apollo.BaseMutationOptions<
  UpdatePayAppRequirementGroupsMutation,
  UpdatePayAppRequirementGroupsMutationVariables
>
export const ContractFormTemplatesDocument = gql`
  query contractFormTemplates($contractId: ID!) {
    contractFormTemplates(contractId: $contractId) {
      id
      createdAt
      type
      userVisibleName
      status
      skippedValidation
      variants {
        id
        isDefaultVariant
      }
      associatedContracts {
        id
        providedAsFormType
        ignoreProvidedAsFormType
        contract {
          id
        }
      }
    }
  }
`

/**
 * __useContractFormTemplatesQuery__
 *
 * To run a query within a React component, call `useContractFormTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractFormTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractFormTemplatesQuery({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useContractFormTemplatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ContractFormTemplatesQuery,
    ContractFormTemplatesQueryVariables
  > &
    ({ variables: ContractFormTemplatesQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ContractFormTemplatesQuery, ContractFormTemplatesQueryVariables>(
    ContractFormTemplatesDocument,
    options
  )
}
export function useContractFormTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContractFormTemplatesQuery,
    ContractFormTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ContractFormTemplatesQuery, ContractFormTemplatesQueryVariables>(
    ContractFormTemplatesDocument,
    options
  )
}
export function useContractFormTemplatesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ContractFormTemplatesQuery,
        ContractFormTemplatesQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ContractFormTemplatesQuery, ContractFormTemplatesQueryVariables>(
    ContractFormTemplatesDocument,
    options
  )
}
export type ContractFormTemplatesQueryHookResult = ReturnType<typeof useContractFormTemplatesQuery>
export type ContractFormTemplatesLazyQueryHookResult = ReturnType<
  typeof useContractFormTemplatesLazyQuery
>
export type ContractFormTemplatesSuspenseQueryHookResult = ReturnType<
  typeof useContractFormTemplatesSuspenseQuery
>
export type ContractFormTemplatesQueryResult = Apollo.QueryResult<
  ContractFormTemplatesQuery,
  ContractFormTemplatesQueryVariables
>
export const IgnoreFormTemplateProvidedTypeDocument = gql`
  mutation ignoreFormTemplateProvidedType($input: IgnoreFormTemplateProvidedTypeInput!) {
    ignoreFormTemplateProvidedType(input: $input) {
      id
      associatedFormTemplates {
        id
        ignoreProvidedAsFormType
      }
      onboardedStatus {
        selectedPayAppForms
        onboardedPayAppForms
        selectedPrimaryLienWaivers
        onboardedPrimaryLienWaiverForms
        selectedVendorLienWaivers
        onboardedVendorLienWaiverForms
        selectedChangeOrderRequestForms
        onboardedChangeOrderRequestForms
        notifiedOnboardedForms
      }
    }
  }
`
export type IgnoreFormTemplateProvidedTypeMutationFn = Apollo.MutationFunction<
  IgnoreFormTemplateProvidedTypeMutation,
  IgnoreFormTemplateProvidedTypeMutationVariables
>

/**
 * __useIgnoreFormTemplateProvidedTypeMutation__
 *
 * To run a mutation, you first call `useIgnoreFormTemplateProvidedTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIgnoreFormTemplateProvidedTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ignoreFormTemplateProvidedTypeMutation, { data, loading, error }] = useIgnoreFormTemplateProvidedTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIgnoreFormTemplateProvidedTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IgnoreFormTemplateProvidedTypeMutation,
    IgnoreFormTemplateProvidedTypeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IgnoreFormTemplateProvidedTypeMutation,
    IgnoreFormTemplateProvidedTypeMutationVariables
  >(IgnoreFormTemplateProvidedTypeDocument, options)
}
export type IgnoreFormTemplateProvidedTypeMutationHookResult = ReturnType<
  typeof useIgnoreFormTemplateProvidedTypeMutation
>
export type IgnoreFormTemplateProvidedTypeMutationResult =
  Apollo.MutationResult<IgnoreFormTemplateProvidedTypeMutation>
export type IgnoreFormTemplateProvidedTypeMutationOptions = Apollo.BaseMutationOptions<
  IgnoreFormTemplateProvidedTypeMutation,
  IgnoreFormTemplateProvidedTypeMutationVariables
>
export const UpdateContractUsersDocument = gql`
  mutation updateContractUsers($input: UpdateContractUsersInput!) {
    updateContractUsers(input: $input) {
      id
      users {
        id
        user {
          ...UserProperties
        }
      }
    }
  }
  ${UserPropertiesDoc}
`
export type UpdateContractUsersMutationFn = Apollo.MutationFunction<
  UpdateContractUsersMutation,
  UpdateContractUsersMutationVariables
>

/**
 * __useUpdateContractUsersMutation__
 *
 * To run a mutation, you first call `useUpdateContractUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractUsersMutation, { data, loading, error }] = useUpdateContractUsersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractUsersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateContractUsersMutation,
    UpdateContractUsersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateContractUsersMutation, UpdateContractUsersMutationVariables>(
    UpdateContractUsersDocument,
    options
  )
}
export type UpdateContractUsersMutationHookResult = ReturnType<
  typeof useUpdateContractUsersMutation
>
export type UpdateContractUsersMutationResult = Apollo.MutationResult<UpdateContractUsersMutation>
export type UpdateContractUsersMutationOptions = Apollo.BaseMutationOptions<
  UpdateContractUsersMutation,
  UpdateContractUsersMutationVariables
>
export const AddIntegrationDocument = gql`
  mutation addIntegration($input: AddIntegrationInput!) {
    addIntegration(input: $input) {
      ...IntegrationProperties
    }
  }
  ${IntegrationPropertiesDoc}
`
export type AddIntegrationMutationFn = Apollo.MutationFunction<
  AddIntegrationMutation,
  AddIntegrationMutationVariables
>

/**
 * __useAddIntegrationMutation__
 *
 * To run a mutation, you first call `useAddIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addIntegrationMutation, { data, loading, error }] = useAddIntegrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<AddIntegrationMutation, AddIntegrationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AddIntegrationMutation, AddIntegrationMutationVariables>(
    AddIntegrationDocument,
    options
  )
}
export type AddIntegrationMutationHookResult = ReturnType<typeof useAddIntegrationMutation>
export type AddIntegrationMutationResult = Apollo.MutationResult<AddIntegrationMutation>
export type AddIntegrationMutationOptions = Apollo.BaseMutationOptions<
  AddIntegrationMutation,
  AddIntegrationMutationVariables
>
export const FinishedOnboardingFormsDocument = gql`
  mutation finishedOnboardingForms($id: ID!) {
    finishedOnboardingForms(id: $id) {
      id
    }
  }
`
export type FinishedOnboardingFormsMutationFn = Apollo.MutationFunction<
  FinishedOnboardingFormsMutation,
  FinishedOnboardingFormsMutationVariables
>

/**
 * __useFinishedOnboardingFormsMutation__
 *
 * To run a mutation, you first call `useFinishedOnboardingFormsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishedOnboardingFormsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishedOnboardingFormsMutation, { data, loading, error }] = useFinishedOnboardingFormsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFinishedOnboardingFormsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FinishedOnboardingFormsMutation,
    FinishedOnboardingFormsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    FinishedOnboardingFormsMutation,
    FinishedOnboardingFormsMutationVariables
  >(FinishedOnboardingFormsDocument, options)
}
export type FinishedOnboardingFormsMutationHookResult = ReturnType<
  typeof useFinishedOnboardingFormsMutation
>
export type FinishedOnboardingFormsMutationResult =
  Apollo.MutationResult<FinishedOnboardingFormsMutation>
export type FinishedOnboardingFormsMutationOptions = Apollo.BaseMutationOptions<
  FinishedOnboardingFormsMutation,
  FinishedOnboardingFormsMutationVariables
>
export const DeleteIntegrationDocument = gql`
  mutation deleteIntegration($id: ID!) {
    deleteIntegration(id: $id) {
      id
    }
  }
`
export type DeleteIntegrationMutationFn = Apollo.MutationFunction<
  DeleteIntegrationMutation,
  DeleteIntegrationMutationVariables
>

/**
 * __useDeleteIntegrationMutation__
 *
 * To run a mutation, you first call `useDeleteIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteIntegrationMutation, { data, loading, error }] = useDeleteIntegrationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteIntegrationMutation,
    DeleteIntegrationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteIntegrationMutation, DeleteIntegrationMutationVariables>(
    DeleteIntegrationDocument,
    options
  )
}
export type DeleteIntegrationMutationHookResult = ReturnType<typeof useDeleteIntegrationMutation>
export type DeleteIntegrationMutationResult = Apollo.MutationResult<DeleteIntegrationMutation>
export type DeleteIntegrationMutationOptions = Apollo.BaseMutationOptions<
  DeleteIntegrationMutation,
  DeleteIntegrationMutationVariables
>
export const UpdateLegalRequirementsDocument = gql`
  mutation updateLegalRequirements($input: UpdateLegalRequirementsInput!) {
    updateLegalRequirements(input: $input) {
      ...ContractProperties
    }
  }
  ${ContractPropertiesDoc}
`
export type UpdateLegalRequirementsMutationFn = Apollo.MutationFunction<
  UpdateLegalRequirementsMutation,
  UpdateLegalRequirementsMutationVariables
>

/**
 * __useUpdateLegalRequirementsMutation__
 *
 * To run a mutation, you first call `useUpdateLegalRequirementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLegalRequirementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLegalRequirementsMutation, { data, loading, error }] = useUpdateLegalRequirementsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLegalRequirementsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLegalRequirementsMutation,
    UpdateLegalRequirementsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateLegalRequirementsMutation,
    UpdateLegalRequirementsMutationVariables
  >(UpdateLegalRequirementsDocument, options)
}
export type UpdateLegalRequirementsMutationHookResult = ReturnType<
  typeof useUpdateLegalRequirementsMutation
>
export type UpdateLegalRequirementsMutationResult =
  Apollo.MutationResult<UpdateLegalRequirementsMutation>
export type UpdateLegalRequirementsMutationOptions = Apollo.BaseMutationOptions<
  UpdateLegalRequirementsMutation,
  UpdateLegalRequirementsMutationVariables
>
export const CreateSovLineItemGroupDocument = gql`
  mutation createSovLineItemGroup($input: CreateSovLineItemGroupInput!) {
    createSovLineItemGroup(input: $input) {
      ...SovLineItemGroupProperties
    }
  }
  ${SovLineItemGroupPropertiesDoc}
`
export type CreateSovLineItemGroupMutationFn = Apollo.MutationFunction<
  CreateSovLineItemGroupMutation,
  CreateSovLineItemGroupMutationVariables
>

/**
 * __useCreateSovLineItemGroupMutation__
 *
 * To run a mutation, you first call `useCreateSovLineItemGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSovLineItemGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSovLineItemGroupMutation, { data, loading, error }] = useCreateSovLineItemGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSovLineItemGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSovLineItemGroupMutation,
    CreateSovLineItemGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateSovLineItemGroupMutation,
    CreateSovLineItemGroupMutationVariables
  >(CreateSovLineItemGroupDocument, options)
}
export type CreateSovLineItemGroupMutationHookResult = ReturnType<
  typeof useCreateSovLineItemGroupMutation
>
export type CreateSovLineItemGroupMutationResult =
  Apollo.MutationResult<CreateSovLineItemGroupMutation>
export type CreateSovLineItemGroupMutationOptions = Apollo.BaseMutationOptions<
  CreateSovLineItemGroupMutation,
  CreateSovLineItemGroupMutationVariables
>
export const UpdateSovLineItemGroupDocument = gql`
  mutation updateSovLineItemGroup($input: UpdateSovLineItemGroupInput!) {
    updateSovLineItemGroup(input: $input) {
      ...SovLineItemGroupProperties
    }
  }
  ${SovLineItemGroupPropertiesDoc}
`
export type UpdateSovLineItemGroupMutationFn = Apollo.MutationFunction<
  UpdateSovLineItemGroupMutation,
  UpdateSovLineItemGroupMutationVariables
>

/**
 * __useUpdateSovLineItemGroupMutation__
 *
 * To run a mutation, you first call `useUpdateSovLineItemGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSovLineItemGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSovLineItemGroupMutation, { data, loading, error }] = useUpdateSovLineItemGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSovLineItemGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSovLineItemGroupMutation,
    UpdateSovLineItemGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateSovLineItemGroupMutation,
    UpdateSovLineItemGroupMutationVariables
  >(UpdateSovLineItemGroupDocument, options)
}
export type UpdateSovLineItemGroupMutationHookResult = ReturnType<
  typeof useUpdateSovLineItemGroupMutation
>
export type UpdateSovLineItemGroupMutationResult =
  Apollo.MutationResult<UpdateSovLineItemGroupMutation>
export type UpdateSovLineItemGroupMutationOptions = Apollo.BaseMutationOptions<
  UpdateSovLineItemGroupMutation,
  UpdateSovLineItemGroupMutationVariables
>
export const DeleteSovLineItemGroupDocument = gql`
  mutation deleteSovLineItemGroup($id: ID!) {
    deleteSovLineItemGroup(id: $id) {
      id
    }
  }
`
export type DeleteSovLineItemGroupMutationFn = Apollo.MutationFunction<
  DeleteSovLineItemGroupMutation,
  DeleteSovLineItemGroupMutationVariables
>

/**
 * __useDeleteSovLineItemGroupMutation__
 *
 * To run a mutation, you first call `useDeleteSovLineItemGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSovLineItemGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSovLineItemGroupMutation, { data, loading, error }] = useDeleteSovLineItemGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSovLineItemGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSovLineItemGroupMutation,
    DeleteSovLineItemGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteSovLineItemGroupMutation,
    DeleteSovLineItemGroupMutationVariables
  >(DeleteSovLineItemGroupDocument, options)
}
export type DeleteSovLineItemGroupMutationHookResult = ReturnType<
  typeof useDeleteSovLineItemGroupMutation
>
export type DeleteSovLineItemGroupMutationResult =
  Apollo.MutationResult<DeleteSovLineItemGroupMutation>
export type DeleteSovLineItemGroupMutationOptions = Apollo.BaseMutationOptions<
  DeleteSovLineItemGroupMutation,
  DeleteSovLineItemGroupMutationVariables
>
export const SetLineItemsInGroupDocument = gql`
  mutation setLineItemsInGroup($input: SetLineItemsInGroupInput!) {
    setLineItemsInGroup(input: $input) {
      ...SovLineItemGroupProperties
    }
  }
  ${SovLineItemGroupPropertiesDoc}
`
export type SetLineItemsInGroupMutationFn = Apollo.MutationFunction<
  SetLineItemsInGroupMutation,
  SetLineItemsInGroupMutationVariables
>

/**
 * __useSetLineItemsInGroupMutation__
 *
 * To run a mutation, you first call `useSetLineItemsInGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetLineItemsInGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setLineItemsInGroupMutation, { data, loading, error }] = useSetLineItemsInGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetLineItemsInGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetLineItemsInGroupMutation,
    SetLineItemsInGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SetLineItemsInGroupMutation, SetLineItemsInGroupMutationVariables>(
    SetLineItemsInGroupDocument,
    options
  )
}
export type SetLineItemsInGroupMutationHookResult = ReturnType<
  typeof useSetLineItemsInGroupMutation
>
export type SetLineItemsInGroupMutationResult = Apollo.MutationResult<SetLineItemsInGroupMutation>
export type SetLineItemsInGroupMutationOptions = Apollo.BaseMutationOptions<
  SetLineItemsInGroupMutation,
  SetLineItemsInGroupMutationVariables
>
export const EnablePrimaryLienWaiversDocument = gql`
  mutation enablePrimaryLienWaivers($input: EnableLienWaiverInput!) {
    enablePrimaryLienWaivers(input: $input) {
      ...ContractForLienWaiverSettings
    }
  }
  ${ContractForLienWaiverSettingsDoc}
`
export type EnablePrimaryLienWaiversMutationFn = Apollo.MutationFunction<
  EnablePrimaryLienWaiversMutation,
  EnablePrimaryLienWaiversMutationVariables
>

/**
 * __useEnablePrimaryLienWaiversMutation__
 *
 * To run a mutation, you first call `useEnablePrimaryLienWaiversMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnablePrimaryLienWaiversMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enablePrimaryLienWaiversMutation, { data, loading, error }] = useEnablePrimaryLienWaiversMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnablePrimaryLienWaiversMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EnablePrimaryLienWaiversMutation,
    EnablePrimaryLienWaiversMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    EnablePrimaryLienWaiversMutation,
    EnablePrimaryLienWaiversMutationVariables
  >(EnablePrimaryLienWaiversDocument, options)
}
export type EnablePrimaryLienWaiversMutationHookResult = ReturnType<
  typeof useEnablePrimaryLienWaiversMutation
>
export type EnablePrimaryLienWaiversMutationResult =
  Apollo.MutationResult<EnablePrimaryLienWaiversMutation>
export type EnablePrimaryLienWaiversMutationOptions = Apollo.BaseMutationOptions<
  EnablePrimaryLienWaiversMutation,
  EnablePrimaryLienWaiversMutationVariables
>
export const DisablePrimaryLienWaiversDocument = gql`
  mutation disablePrimaryLienWaivers($contractId: ID!) {
    disablePrimaryLienWaivers(contractId: $contractId) {
      ...ContractForLienWaiverSettings
    }
  }
  ${ContractForLienWaiverSettingsDoc}
`
export type DisablePrimaryLienWaiversMutationFn = Apollo.MutationFunction<
  DisablePrimaryLienWaiversMutation,
  DisablePrimaryLienWaiversMutationVariables
>

/**
 * __useDisablePrimaryLienWaiversMutation__
 *
 * To run a mutation, you first call `useDisablePrimaryLienWaiversMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisablePrimaryLienWaiversMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disablePrimaryLienWaiversMutation, { data, loading, error }] = useDisablePrimaryLienWaiversMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useDisablePrimaryLienWaiversMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DisablePrimaryLienWaiversMutation,
    DisablePrimaryLienWaiversMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DisablePrimaryLienWaiversMutation,
    DisablePrimaryLienWaiversMutationVariables
  >(DisablePrimaryLienWaiversDocument, options)
}
export type DisablePrimaryLienWaiversMutationHookResult = ReturnType<
  typeof useDisablePrimaryLienWaiversMutation
>
export type DisablePrimaryLienWaiversMutationResult =
  Apollo.MutationResult<DisablePrimaryLienWaiversMutation>
export type DisablePrimaryLienWaiversMutationOptions = Apollo.BaseMutationOptions<
  DisablePrimaryLienWaiversMutation,
  DisablePrimaryLienWaiversMutationVariables
>
export const EnableLowerTierLienWaiversDocument = gql`
  mutation enableLowerTierLienWaivers($input: EnableLienWaiverInput!) {
    enableLowerTierLienWaivers(input: $input) {
      ...ContractForLienWaiverSettings
    }
  }
  ${ContractForLienWaiverSettingsDoc}
`
export type EnableLowerTierLienWaiversMutationFn = Apollo.MutationFunction<
  EnableLowerTierLienWaiversMutation,
  EnableLowerTierLienWaiversMutationVariables
>

/**
 * __useEnableLowerTierLienWaiversMutation__
 *
 * To run a mutation, you first call `useEnableLowerTierLienWaiversMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableLowerTierLienWaiversMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableLowerTierLienWaiversMutation, { data, loading, error }] = useEnableLowerTierLienWaiversMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnableLowerTierLienWaiversMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EnableLowerTierLienWaiversMutation,
    EnableLowerTierLienWaiversMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    EnableLowerTierLienWaiversMutation,
    EnableLowerTierLienWaiversMutationVariables
  >(EnableLowerTierLienWaiversDocument, options)
}
export type EnableLowerTierLienWaiversMutationHookResult = ReturnType<
  typeof useEnableLowerTierLienWaiversMutation
>
export type EnableLowerTierLienWaiversMutationResult =
  Apollo.MutationResult<EnableLowerTierLienWaiversMutation>
export type EnableLowerTierLienWaiversMutationOptions = Apollo.BaseMutationOptions<
  EnableLowerTierLienWaiversMutation,
  EnableLowerTierLienWaiversMutationVariables
>
export const DisableLowerTierLienWaiversDocument = gql`
  mutation disableLowerTierLienWaivers($contractId: ID!) {
    disableLowerTierLienWaivers(contractId: $contractId) {
      ...ContractForLienWaiverSettings
    }
  }
  ${ContractForLienWaiverSettingsDoc}
`
export type DisableLowerTierLienWaiversMutationFn = Apollo.MutationFunction<
  DisableLowerTierLienWaiversMutation,
  DisableLowerTierLienWaiversMutationVariables
>

/**
 * __useDisableLowerTierLienWaiversMutation__
 *
 * To run a mutation, you first call `useDisableLowerTierLienWaiversMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableLowerTierLienWaiversMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableLowerTierLienWaiversMutation, { data, loading, error }] = useDisableLowerTierLienWaiversMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useDisableLowerTierLienWaiversMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DisableLowerTierLienWaiversMutation,
    DisableLowerTierLienWaiversMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DisableLowerTierLienWaiversMutation,
    DisableLowerTierLienWaiversMutationVariables
  >(DisableLowerTierLienWaiversDocument, options)
}
export type DisableLowerTierLienWaiversMutationHookResult = ReturnType<
  typeof useDisableLowerTierLienWaiversMutation
>
export type DisableLowerTierLienWaiversMutationResult =
  Apollo.MutationResult<DisableLowerTierLienWaiversMutation>
export type DisableLowerTierLienWaiversMutationOptions = Apollo.BaseMutationOptions<
  DisableLowerTierLienWaiversMutation,
  DisableLowerTierLienWaiversMutationVariables
>
export const UpdateContractDocument = gql`
  mutation updateContract($input: UpdateContractInput!) {
    updateContract(input: $input) {
      ...ContractProperties
    }
  }
  ${ContractPropertiesDoc}
`
export type UpdateContractMutationFn = Apollo.MutationFunction<
  UpdateContractMutation,
  UpdateContractMutationVariables
>

/**
 * __useUpdateContractMutation__
 *
 * To run a mutation, you first call `useUpdateContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractMutation, { data, loading, error }] = useUpdateContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateContractMutation, UpdateContractMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateContractMutation, UpdateContractMutationVariables>(
    UpdateContractDocument,
    options
  )
}
export type UpdateContractMutationHookResult = ReturnType<typeof useUpdateContractMutation>
export type UpdateContractMutationResult = Apollo.MutationResult<UpdateContractMutation>
export type UpdateContractMutationOptions = Apollo.BaseMutationOptions<
  UpdateContractMutation,
  UpdateContractMutationVariables
>
export const SovLineItemGroupsDocument = gql`
  query sovLineItemGroups($contractId: ID!) {
    sovLineItemGroups(contractId: $contractId) {
      ...SovLineItemGroupProperties
    }
  }
  ${SovLineItemGroupPropertiesDoc}
`

/**
 * __useSovLineItemGroupsQuery__
 *
 * To run a query within a React component, call `useSovLineItemGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSovLineItemGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSovLineItemGroupsQuery({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useSovLineItemGroupsQuery(
  baseOptions: Apollo.QueryHookOptions<SovLineItemGroupsQuery, SovLineItemGroupsQueryVariables> &
    ({ variables: SovLineItemGroupsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SovLineItemGroupsQuery, SovLineItemGroupsQueryVariables>(
    SovLineItemGroupsDocument,
    options
  )
}
export function useSovLineItemGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SovLineItemGroupsQuery, SovLineItemGroupsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SovLineItemGroupsQuery, SovLineItemGroupsQueryVariables>(
    SovLineItemGroupsDocument,
    options
  )
}
export function useSovLineItemGroupsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<SovLineItemGroupsQuery, SovLineItemGroupsQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<SovLineItemGroupsQuery, SovLineItemGroupsQueryVariables>(
    SovLineItemGroupsDocument,
    options
  )
}
export type SovLineItemGroupsQueryHookResult = ReturnType<typeof useSovLineItemGroupsQuery>
export type SovLineItemGroupsLazyQueryHookResult = ReturnType<typeof useSovLineItemGroupsLazyQuery>
export type SovLineItemGroupsSuspenseQueryHookResult = ReturnType<
  typeof useSovLineItemGroupsSuspenseQuery
>
export type SovLineItemGroupsQueryResult = Apollo.QueryResult<
  SovLineItemGroupsQuery,
  SovLineItemGroupsQueryVariables
>
export const UpdateChangeOrderRequestFormDocument = gql`
  mutation updateChangeOrderRequestForm($input: UpdateChangeOrderRequestFormInput!) {
    updateChangeOrderRequestForm(input: $input) {
      id
      changeOrderRequestTemplate {
        id
      }
    }
  }
`
export type UpdateChangeOrderRequestFormMutationFn = Apollo.MutationFunction<
  UpdateChangeOrderRequestFormMutation,
  UpdateChangeOrderRequestFormMutationVariables
>

/**
 * __useUpdateChangeOrderRequestFormMutation__
 *
 * To run a mutation, you first call `useUpdateChangeOrderRequestFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChangeOrderRequestFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChangeOrderRequestFormMutation, { data, loading, error }] = useUpdateChangeOrderRequestFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateChangeOrderRequestFormMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateChangeOrderRequestFormMutation,
    UpdateChangeOrderRequestFormMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateChangeOrderRequestFormMutation,
    UpdateChangeOrderRequestFormMutationVariables
  >(UpdateChangeOrderRequestFormDocument, options)
}
export type UpdateChangeOrderRequestFormMutationHookResult = ReturnType<
  typeof useUpdateChangeOrderRequestFormMutation
>
export type UpdateChangeOrderRequestFormMutationResult =
  Apollo.MutationResult<UpdateChangeOrderRequestFormMutation>
export type UpdateChangeOrderRequestFormMutationOptions = Apollo.BaseMutationOptions<
  UpdateChangeOrderRequestFormMutation,
  UpdateChangeOrderRequestFormMutationVariables
>
export const UpdateChangeOrderLogFormDocument = gql`
  mutation updateChangeOrderLogForm($input: UpdateChangeOrderLogFormInput!) {
    updateChangeOrderLogForm(input: $input) {
      id
      changeOrderLogTemplate {
        id
      }
    }
  }
`
export type UpdateChangeOrderLogFormMutationFn = Apollo.MutationFunction<
  UpdateChangeOrderLogFormMutation,
  UpdateChangeOrderLogFormMutationVariables
>

/**
 * __useUpdateChangeOrderLogFormMutation__
 *
 * To run a mutation, you first call `useUpdateChangeOrderLogFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChangeOrderLogFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChangeOrderLogFormMutation, { data, loading, error }] = useUpdateChangeOrderLogFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateChangeOrderLogFormMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateChangeOrderLogFormMutation,
    UpdateChangeOrderLogFormMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateChangeOrderLogFormMutation,
    UpdateChangeOrderLogFormMutationVariables
  >(UpdateChangeOrderLogFormDocument, options)
}
export type UpdateChangeOrderLogFormMutationHookResult = ReturnType<
  typeof useUpdateChangeOrderLogFormMutation
>
export type UpdateChangeOrderLogFormMutationResult =
  Apollo.MutationResult<UpdateChangeOrderLogFormMutation>
export type UpdateChangeOrderLogFormMutationOptions = Apollo.BaseMutationOptions<
  UpdateChangeOrderLogFormMutation,
  UpdateChangeOrderLogFormMutationVariables
>
export const ConvertRetentionTrackingLevelDocument = gql`
  mutation convertRetentionTrackingLevel($input: ConvertRetentionTrackingLevelInput!) {
    convertRetentionTrackingLevel(input: $input) {
      ...ContractProperties
    }
  }
  ${ContractPropertiesDoc}
`
export type ConvertRetentionTrackingLevelMutationFn = Apollo.MutationFunction<
  ConvertRetentionTrackingLevelMutation,
  ConvertRetentionTrackingLevelMutationVariables
>

/**
 * __useConvertRetentionTrackingLevelMutation__
 *
 * To run a mutation, you first call `useConvertRetentionTrackingLevelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConvertRetentionTrackingLevelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [convertRetentionTrackingLevelMutation, { data, loading, error }] = useConvertRetentionTrackingLevelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConvertRetentionTrackingLevelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConvertRetentionTrackingLevelMutation,
    ConvertRetentionTrackingLevelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ConvertRetentionTrackingLevelMutation,
    ConvertRetentionTrackingLevelMutationVariables
  >(ConvertRetentionTrackingLevelDocument, options)
}
export type ConvertRetentionTrackingLevelMutationHookResult = ReturnType<
  typeof useConvertRetentionTrackingLevelMutation
>
export type ConvertRetentionTrackingLevelMutationResult =
  Apollo.MutationResult<ConvertRetentionTrackingLevelMutation>
export type ConvertRetentionTrackingLevelMutationOptions = Apollo.BaseMutationOptions<
  ConvertRetentionTrackingLevelMutation,
  ConvertRetentionTrackingLevelMutationVariables
>
export const UpdateRoundRetentionDocument = gql`
  mutation updateRoundRetention($input: UpdateRoundRetentionInput!) {
    updateRoundRetention(input: $input) {
      ...ContractProperties
    }
  }
  ${ContractPropertiesDoc}
`
export type UpdateRoundRetentionMutationFn = Apollo.MutationFunction<
  UpdateRoundRetentionMutation,
  UpdateRoundRetentionMutationVariables
>

/**
 * __useUpdateRoundRetentionMutation__
 *
 * To run a mutation, you first call `useUpdateRoundRetentionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoundRetentionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoundRetentionMutation, { data, loading, error }] = useUpdateRoundRetentionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoundRetentionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRoundRetentionMutation,
    UpdateRoundRetentionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateRoundRetentionMutation, UpdateRoundRetentionMutationVariables>(
    UpdateRoundRetentionDocument,
    options
  )
}
export type UpdateRoundRetentionMutationHookResult = ReturnType<
  typeof useUpdateRoundRetentionMutation
>
export type UpdateRoundRetentionMutationResult = Apollo.MutationResult<UpdateRoundRetentionMutation>
export type UpdateRoundRetentionMutationOptions = Apollo.BaseMutationOptions<
  UpdateRoundRetentionMutation,
  UpdateRoundRetentionMutationVariables
>
export const DeleteSovLineItemDocument = gql`
  mutation deleteSovLineItem($id: ID!) {
    deleteSovLineItem(id: $id) {
      id
    }
  }
`
export type DeleteSovLineItemMutationFn = Apollo.MutationFunction<
  DeleteSovLineItemMutation,
  DeleteSovLineItemMutationVariables
>

/**
 * __useDeleteSovLineItemMutation__
 *
 * To run a mutation, you first call `useDeleteSovLineItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSovLineItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSovLineItemMutation, { data, loading, error }] = useDeleteSovLineItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSovLineItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSovLineItemMutation,
    DeleteSovLineItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteSovLineItemMutation, DeleteSovLineItemMutationVariables>(
    DeleteSovLineItemDocument,
    options
  )
}
export type DeleteSovLineItemMutationHookResult = ReturnType<typeof useDeleteSovLineItemMutation>
export type DeleteSovLineItemMutationResult = Apollo.MutationResult<DeleteSovLineItemMutation>
export type DeleteSovLineItemMutationOptions = Apollo.BaseMutationOptions<
  DeleteSovLineItemMutation,
  DeleteSovLineItemMutationVariables
>
export const UpdateIntegrationMappingsDocument = gql`
  mutation updateIntegrationMappings($input: UpdateIntegrationMappingsInput!) {
    updateIntegrationMappings(input: $input) {
      ...IntegrationProperties
    }
  }
  ${IntegrationPropertiesDoc}
`
export type UpdateIntegrationMappingsMutationFn = Apollo.MutationFunction<
  UpdateIntegrationMappingsMutation,
  UpdateIntegrationMappingsMutationVariables
>

/**
 * __useUpdateIntegrationMappingsMutation__
 *
 * To run a mutation, you first call `useUpdateIntegrationMappingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIntegrationMappingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIntegrationMappingsMutation, { data, loading, error }] = useUpdateIntegrationMappingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateIntegrationMappingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateIntegrationMappingsMutation,
    UpdateIntegrationMappingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateIntegrationMappingsMutation,
    UpdateIntegrationMappingsMutationVariables
  >(UpdateIntegrationMappingsDocument, options)
}
export type UpdateIntegrationMappingsMutationHookResult = ReturnType<
  typeof useUpdateIntegrationMappingsMutation
>
export type UpdateIntegrationMappingsMutationResult =
  Apollo.MutationResult<UpdateIntegrationMappingsMutation>
export type UpdateIntegrationMappingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateIntegrationMappingsMutation,
  UpdateIntegrationMappingsMutationVariables
>
export const RefreshIntegrationMappingsDocument = gql`
  mutation refreshIntegrationMappings($input: RefreshIntegrationMappingsInput!) {
    refreshIntegrationMappings(input: $input) {
      ...IntegrationProperties
    }
  }
  ${IntegrationPropertiesDoc}
`
export type RefreshIntegrationMappingsMutationFn = Apollo.MutationFunction<
  RefreshIntegrationMappingsMutation,
  RefreshIntegrationMappingsMutationVariables
>

/**
 * __useRefreshIntegrationMappingsMutation__
 *
 * To run a mutation, you first call `useRefreshIntegrationMappingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshIntegrationMappingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshIntegrationMappingsMutation, { data, loading, error }] = useRefreshIntegrationMappingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRefreshIntegrationMappingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshIntegrationMappingsMutation,
    RefreshIntegrationMappingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RefreshIntegrationMappingsMutation,
    RefreshIntegrationMappingsMutationVariables
  >(RefreshIntegrationMappingsDocument, options)
}
export type RefreshIntegrationMappingsMutationHookResult = ReturnType<
  typeof useRefreshIntegrationMappingsMutation
>
export type RefreshIntegrationMappingsMutationResult =
  Apollo.MutationResult<RefreshIntegrationMappingsMutation>
export type RefreshIntegrationMappingsMutationOptions = Apollo.BaseMutationOptions<
  RefreshIntegrationMappingsMutation,
  RefreshIntegrationMappingsMutationVariables
>
export const ImportSovFromIntegrationDocument = gql`
  mutation importSovFromIntegration($input: ImportSovFromIntegrationInput!) {
    importSovFromIntegration(input: $input) {
      ...SovProperties
    }
  }
  ${SovPropertiesDoc}
`
export type ImportSovFromIntegrationMutationFn = Apollo.MutationFunction<
  ImportSovFromIntegrationMutation,
  ImportSovFromIntegrationMutationVariables
>

/**
 * __useImportSovFromIntegrationMutation__
 *
 * To run a mutation, you first call `useImportSovFromIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportSovFromIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importSovFromIntegrationMutation, { data, loading, error }] = useImportSovFromIntegrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportSovFromIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ImportSovFromIntegrationMutation,
    ImportSovFromIntegrationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ImportSovFromIntegrationMutation,
    ImportSovFromIntegrationMutationVariables
  >(ImportSovFromIntegrationDocument, options)
}
export type ImportSovFromIntegrationMutationHookResult = ReturnType<
  typeof useImportSovFromIntegrationMutation
>
export type ImportSovFromIntegrationMutationResult =
  Apollo.MutationResult<ImportSovFromIntegrationMutation>
export type ImportSovFromIntegrationMutationOptions = Apollo.BaseMutationOptions<
  ImportSovFromIntegrationMutation,
  ImportSovFromIntegrationMutationVariables
>
export const AbandonedPayAppsDocument = gql`
  query abandonedPayApps {
    abandonedPayApps {
      companyId
      companyName
      companyCreatedAt
      abandonedPayAppsCount
      abandonedPayAppsIds
      sitelinePointOfContactId
      sitelinePointOfContactName
    }
  }
`

/**
 * __useAbandonedPayAppsQuery__
 *
 * To run a query within a React component, call `useAbandonedPayAppsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAbandonedPayAppsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAbandonedPayAppsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAbandonedPayAppsQuery(
  baseOptions?: Apollo.QueryHookOptions<AbandonedPayAppsQuery, AbandonedPayAppsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AbandonedPayAppsQuery, AbandonedPayAppsQueryVariables>(
    AbandonedPayAppsDocument,
    options
  )
}
export function useAbandonedPayAppsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AbandonedPayAppsQuery, AbandonedPayAppsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AbandonedPayAppsQuery, AbandonedPayAppsQueryVariables>(
    AbandonedPayAppsDocument,
    options
  )
}
export function useAbandonedPayAppsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AbandonedPayAppsQuery, AbandonedPayAppsQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<AbandonedPayAppsQuery, AbandonedPayAppsQueryVariables>(
    AbandonedPayAppsDocument,
    options
  )
}
export type AbandonedPayAppsQueryHookResult = ReturnType<typeof useAbandonedPayAppsQuery>
export type AbandonedPayAppsLazyQueryHookResult = ReturnType<typeof useAbandonedPayAppsLazyQuery>
export type AbandonedPayAppsSuspenseQueryHookResult = ReturnType<
  typeof useAbandonedPayAppsSuspenseQuery
>
export type AbandonedPayAppsQueryResult = Apollo.QueryResult<
  AbandonedPayAppsQuery,
  AbandonedPayAppsQueryVariables
>
export const BillingTotalsDocument = gql`
  query billingTotals($input: BillingTotalsInput!) {
    billingTotals(input: $input) {
      month
      companyId
      companyCreatedAt
      companyName
      submittedPayAppCount
      totalBilledThisMonth
      sitelinePointOfContactId
      sitelinePointOfContactName
    }
  }
`

/**
 * __useBillingTotalsQuery__
 *
 * To run a query within a React component, call `useBillingTotalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingTotalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingTotalsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBillingTotalsQuery(
  baseOptions: Apollo.QueryHookOptions<BillingTotalsQuery, BillingTotalsQueryVariables> &
    ({ variables: BillingTotalsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<BillingTotalsQuery, BillingTotalsQueryVariables>(
    BillingTotalsDocument,
    options
  )
}
export function useBillingTotalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BillingTotalsQuery, BillingTotalsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<BillingTotalsQuery, BillingTotalsQueryVariables>(
    BillingTotalsDocument,
    options
  )
}
export function useBillingTotalsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<BillingTotalsQuery, BillingTotalsQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<BillingTotalsQuery, BillingTotalsQueryVariables>(
    BillingTotalsDocument,
    options
  )
}
export type BillingTotalsQueryHookResult = ReturnType<typeof useBillingTotalsQuery>
export type BillingTotalsLazyQueryHookResult = ReturnType<typeof useBillingTotalsLazyQuery>
export type BillingTotalsSuspenseQueryHookResult = ReturnType<typeof useBillingTotalsSuspenseQuery>
export type BillingTotalsQueryResult = Apollo.QueryResult<
  BillingTotalsQuery,
  BillingTotalsQueryVariables
>
export const ChangeOrderLogUseDocument = gql`
  query changeOrderLogUse {
    changeOrderLogUse {
      companyId
      companyName
      companyCreatedAt
      percentProjectsWithChangeOrder
      sitelinePointOfContactId
      sitelinePointOfContactName
    }
  }
`

/**
 * __useChangeOrderLogUseQuery__
 *
 * To run a query within a React component, call `useChangeOrderLogUseQuery` and pass it any options that fit your needs.
 * When your component renders, `useChangeOrderLogUseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChangeOrderLogUseQuery({
 *   variables: {
 *   },
 * });
 */
export function useChangeOrderLogUseQuery(
  baseOptions?: Apollo.QueryHookOptions<ChangeOrderLogUseQuery, ChangeOrderLogUseQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ChangeOrderLogUseQuery, ChangeOrderLogUseQueryVariables>(
    ChangeOrderLogUseDocument,
    options
  )
}
export function useChangeOrderLogUseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ChangeOrderLogUseQuery, ChangeOrderLogUseQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ChangeOrderLogUseQuery, ChangeOrderLogUseQueryVariables>(
    ChangeOrderLogUseDocument,
    options
  )
}
export function useChangeOrderLogUseSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ChangeOrderLogUseQuery, ChangeOrderLogUseQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ChangeOrderLogUseQuery, ChangeOrderLogUseQueryVariables>(
    ChangeOrderLogUseDocument,
    options
  )
}
export type ChangeOrderLogUseQueryHookResult = ReturnType<typeof useChangeOrderLogUseQuery>
export type ChangeOrderLogUseLazyQueryHookResult = ReturnType<typeof useChangeOrderLogUseLazyQuery>
export type ChangeOrderLogUseSuspenseQueryHookResult = ReturnType<
  typeof useChangeOrderLogUseSuspenseQuery
>
export type ChangeOrderLogUseQueryResult = Apollo.QueryResult<
  ChangeOrderLogUseQuery,
  ChangeOrderLogUseQueryVariables
>
export const ComplianceUseDocument = gql`
  query complianceUse {
    complianceUse {
      companyId
      companyName
      companyCreatedAt
      percentProjectsWithComplianceRequirements
      sitelinePointOfContactId
      sitelinePointOfContactName
    }
  }
`

/**
 * __useComplianceUseQuery__
 *
 * To run a query within a React component, call `useComplianceUseQuery` and pass it any options that fit your needs.
 * When your component renders, `useComplianceUseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useComplianceUseQuery({
 *   variables: {
 *   },
 * });
 */
export function useComplianceUseQuery(
  baseOptions?: Apollo.QueryHookOptions<ComplianceUseQuery, ComplianceUseQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ComplianceUseQuery, ComplianceUseQueryVariables>(
    ComplianceUseDocument,
    options
  )
}
export function useComplianceUseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ComplianceUseQuery, ComplianceUseQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ComplianceUseQuery, ComplianceUseQueryVariables>(
    ComplianceUseDocument,
    options
  )
}
export function useComplianceUseSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ComplianceUseQuery, ComplianceUseQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ComplianceUseQuery, ComplianceUseQueryVariables>(
    ComplianceUseDocument,
    options
  )
}
export type ComplianceUseQueryHookResult = ReturnType<typeof useComplianceUseQuery>
export type ComplianceUseLazyQueryHookResult = ReturnType<typeof useComplianceUseLazyQuery>
export type ComplianceUseSuspenseQueryHookResult = ReturnType<typeof useComplianceUseSuspenseQuery>
export type ComplianceUseQueryResult = Apollo.QueryResult<
  ComplianceUseQuery,
  ComplianceUseQueryVariables
>
export const FormTrackerDocument = gql`
  query formTracker($input: FormTrackerInput!) {
    formTracker(input: $input) {
      companyId
      companyName
      companyCreatedAt
      requestedFormsCount
      requestedFormIds
      completedFormsCount
      completedFormIds
      formsCompletedRatio
      sitelinePointOfContactId
      sitelinePointOfContactName
    }
  }
`

/**
 * __useFormTrackerQuery__
 *
 * To run a query within a React component, call `useFormTrackerQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormTrackerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormTrackerQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFormTrackerQuery(
  baseOptions: Apollo.QueryHookOptions<FormTrackerQuery, FormTrackerQueryVariables> &
    ({ variables: FormTrackerQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FormTrackerQuery, FormTrackerQueryVariables>(FormTrackerDocument, options)
}
export function useFormTrackerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FormTrackerQuery, FormTrackerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FormTrackerQuery, FormTrackerQueryVariables>(
    FormTrackerDocument,
    options
  )
}
export function useFormTrackerSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FormTrackerQuery, FormTrackerQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<FormTrackerQuery, FormTrackerQueryVariables>(
    FormTrackerDocument,
    options
  )
}
export type FormTrackerQueryHookResult = ReturnType<typeof useFormTrackerQuery>
export type FormTrackerLazyQueryHookResult = ReturnType<typeof useFormTrackerLazyQuery>
export type FormTrackerSuspenseQueryHookResult = ReturnType<typeof useFormTrackerSuspenseQuery>
export type FormTrackerQueryResult = Apollo.QueryResult<FormTrackerQuery, FormTrackerQueryVariables>
export const PayAppsSubmittedMonthDocument = gql`
  query payAppsSubmittedMonth($input: PayAppsSubmittedMonthInput!) {
    payAppsSubmittedMonth(input: $input) {
      companyId
      companyName
      companyCreatedAt
      payAppsSubmittedThisMonth
      sitelinePointOfContactId
      sitelinePointOfContactName
    }
  }
`

/**
 * __usePayAppsSubmittedMonthQuery__
 *
 * To run a query within a React component, call `usePayAppsSubmittedMonthQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayAppsSubmittedMonthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayAppsSubmittedMonthQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePayAppsSubmittedMonthQuery(
  baseOptions: Apollo.QueryHookOptions<
    PayAppsSubmittedMonthQuery,
    PayAppsSubmittedMonthQueryVariables
  > &
    ({ variables: PayAppsSubmittedMonthQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PayAppsSubmittedMonthQuery, PayAppsSubmittedMonthQueryVariables>(
    PayAppsSubmittedMonthDocument,
    options
  )
}
export function usePayAppsSubmittedMonthLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PayAppsSubmittedMonthQuery,
    PayAppsSubmittedMonthQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PayAppsSubmittedMonthQuery, PayAppsSubmittedMonthQueryVariables>(
    PayAppsSubmittedMonthDocument,
    options
  )
}
export function usePayAppsSubmittedMonthSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PayAppsSubmittedMonthQuery,
        PayAppsSubmittedMonthQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<PayAppsSubmittedMonthQuery, PayAppsSubmittedMonthQueryVariables>(
    PayAppsSubmittedMonthDocument,
    options
  )
}
export type PayAppsSubmittedMonthQueryHookResult = ReturnType<typeof usePayAppsSubmittedMonthQuery>
export type PayAppsSubmittedMonthLazyQueryHookResult = ReturnType<
  typeof usePayAppsSubmittedMonthLazyQuery
>
export type PayAppsSubmittedMonthSuspenseQueryHookResult = ReturnType<
  typeof usePayAppsSubmittedMonthSuspenseQuery
>
export type PayAppsSubmittedMonthQueryResult = Apollo.QueryResult<
  PayAppsSubmittedMonthQuery,
  PayAppsSubmittedMonthQueryVariables
>
export const TimeToValueDocument = gql`
  query timeToValue {
    timeToValue {
      company {
        id
        nickname
        name
        expectedNumberOfProjects
        sitelinePointOfContact {
          id
          firstName
          lastName
        }
      }
      billingByMonth {
        month
        cumulativeContractsBilled
      }
    }
  }
`

/**
 * __useTimeToValueQuery__
 *
 * To run a query within a React component, call `useTimeToValueQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeToValueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeToValueQuery({
 *   variables: {
 *   },
 * });
 */
export function useTimeToValueQuery(
  baseOptions?: Apollo.QueryHookOptions<TimeToValueQuery, TimeToValueQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TimeToValueQuery, TimeToValueQueryVariables>(TimeToValueDocument, options)
}
export function useTimeToValueLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TimeToValueQuery, TimeToValueQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TimeToValueQuery, TimeToValueQueryVariables>(
    TimeToValueDocument,
    options
  )
}
export function useTimeToValueSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<TimeToValueQuery, TimeToValueQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<TimeToValueQuery, TimeToValueQueryVariables>(
    TimeToValueDocument,
    options
  )
}
export type TimeToValueQueryHookResult = ReturnType<typeof useTimeToValueQuery>
export type TimeToValueLazyQueryHookResult = ReturnType<typeof useTimeToValueLazyQuery>
export type TimeToValueSuspenseQueryHookResult = ReturnType<typeof useTimeToValueSuspenseQuery>
export type TimeToValueQueryResult = Apollo.QueryResult<TimeToValueQuery, TimeToValueQueryVariables>
export const LienWaiversRequestedDocument = gql`
  query lienWaiversRequested {
    lienWaiversRequested {
      companyId
      companyName
      companyCreatedAt
      lienWaiversRequested
      sitelinePointOfContactId
      sitelinePointOfContactName
    }
  }
`

/**
 * __useLienWaiversRequestedQuery__
 *
 * To run a query within a React component, call `useLienWaiversRequestedQuery` and pass it any options that fit your needs.
 * When your component renders, `useLienWaiversRequestedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLienWaiversRequestedQuery({
 *   variables: {
 *   },
 * });
 */
export function useLienWaiversRequestedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LienWaiversRequestedQuery,
    LienWaiversRequestedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LienWaiversRequestedQuery, LienWaiversRequestedQueryVariables>(
    LienWaiversRequestedDocument,
    options
  )
}
export function useLienWaiversRequestedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LienWaiversRequestedQuery,
    LienWaiversRequestedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LienWaiversRequestedQuery, LienWaiversRequestedQueryVariables>(
    LienWaiversRequestedDocument,
    options
  )
}
export function useLienWaiversRequestedSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<LienWaiversRequestedQuery, LienWaiversRequestedQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<LienWaiversRequestedQuery, LienWaiversRequestedQueryVariables>(
    LienWaiversRequestedDocument,
    options
  )
}
export type LienWaiversRequestedQueryHookResult = ReturnType<typeof useLienWaiversRequestedQuery>
export type LienWaiversRequestedLazyQueryHookResult = ReturnType<
  typeof useLienWaiversRequestedLazyQuery
>
export type LienWaiversRequestedSuspenseQueryHookResult = ReturnType<
  typeof useLienWaiversRequestedSuspenseQuery
>
export type LienWaiversRequestedQueryResult = Apollo.QueryResult<
  LienWaiversRequestedQuery,
  LienWaiversRequestedQueryVariables
>
export const ProjectLocationsDocument = gql`
  query projectLocations {
    projects {
      id
      name
      contracts {
        id
        users {
          id
          user {
            id
            email
          }
        }
      }
      location {
        ...LocationProperties
      }
    }
  }
  ${LocationPropertiesDoc}
`

/**
 * __useProjectLocationsQuery__
 *
 * To run a query within a React component, call `useProjectLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectLocationsQuery(
  baseOptions?: Apollo.QueryHookOptions<ProjectLocationsQuery, ProjectLocationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ProjectLocationsQuery, ProjectLocationsQueryVariables>(
    ProjectLocationsDocument,
    options
  )
}
export function useProjectLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProjectLocationsQuery, ProjectLocationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ProjectLocationsQuery, ProjectLocationsQueryVariables>(
    ProjectLocationsDocument,
    options
  )
}
export function useProjectLocationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ProjectLocationsQuery, ProjectLocationsQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ProjectLocationsQuery, ProjectLocationsQueryVariables>(
    ProjectLocationsDocument,
    options
  )
}
export type ProjectLocationsQueryHookResult = ReturnType<typeof useProjectLocationsQuery>
export type ProjectLocationsLazyQueryHookResult = ReturnType<typeof useProjectLocationsLazyQuery>
export type ProjectLocationsSuspenseQueryHookResult = ReturnType<
  typeof useProjectLocationsSuspenseQuery
>
export type ProjectLocationsQueryResult = Apollo.QueryResult<
  ProjectLocationsQuery,
  ProjectLocationsQueryVariables
>
export const CompanyLocationsDocument = gql`
  query companyLocations {
    companies {
      id
      name
      type
      users {
        id
        status
        user {
          id
          email
        }
      }
      locations {
        ...LocationProperties
      }
    }
  }
  ${LocationPropertiesDoc}
`

/**
 * __useCompanyLocationsQuery__
 *
 * To run a query within a React component, call `useCompanyLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyLocationsQuery(
  baseOptions?: Apollo.QueryHookOptions<CompanyLocationsQuery, CompanyLocationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CompanyLocationsQuery, CompanyLocationsQueryVariables>(
    CompanyLocationsDocument,
    options
  )
}
export function useCompanyLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CompanyLocationsQuery, CompanyLocationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CompanyLocationsQuery, CompanyLocationsQueryVariables>(
    CompanyLocationsDocument,
    options
  )
}
export function useCompanyLocationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<CompanyLocationsQuery, CompanyLocationsQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<CompanyLocationsQuery, CompanyLocationsQueryVariables>(
    CompanyLocationsDocument,
    options
  )
}
export type CompanyLocationsQueryHookResult = ReturnType<typeof useCompanyLocationsQuery>
export type CompanyLocationsLazyQueryHookResult = ReturnType<typeof useCompanyLocationsLazyQuery>
export type CompanyLocationsSuspenseQueryHookResult = ReturnType<
  typeof useCompanyLocationsSuspenseQuery
>
export type CompanyLocationsQueryResult = Apollo.QueryResult<
  CompanyLocationsQuery,
  CompanyLocationsQueryVariables
>
export const DemoCompaniesDocument = gql`
  query demoCompanies {
    demoCompanies {
      id
      name
      nickname
    }
  }
`

/**
 * __useDemoCompaniesQuery__
 *
 * To run a query within a React component, call `useDemoCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDemoCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDemoCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useDemoCompaniesQuery(
  baseOptions?: Apollo.QueryHookOptions<DemoCompaniesQuery, DemoCompaniesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<DemoCompaniesQuery, DemoCompaniesQueryVariables>(
    DemoCompaniesDocument,
    options
  )
}
export function useDemoCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DemoCompaniesQuery, DemoCompaniesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<DemoCompaniesQuery, DemoCompaniesQueryVariables>(
    DemoCompaniesDocument,
    options
  )
}
export function useDemoCompaniesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<DemoCompaniesQuery, DemoCompaniesQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<DemoCompaniesQuery, DemoCompaniesQueryVariables>(
    DemoCompaniesDocument,
    options
  )
}
export type DemoCompaniesQueryHookResult = ReturnType<typeof useDemoCompaniesQuery>
export type DemoCompaniesLazyQueryHookResult = ReturnType<typeof useDemoCompaniesLazyQuery>
export type DemoCompaniesSuspenseQueryHookResult = ReturnType<typeof useDemoCompaniesSuspenseQuery>
export type DemoCompaniesQueryResult = Apollo.QueryResult<
  DemoCompaniesQuery,
  DemoCompaniesQueryVariables
>
export const MarkOldSubmittedPayAppsAsPaidDocument = gql`
  mutation markOldSubmittedPayAppsAsPaid($input: MarkOldSubmittedPayAppsAsPaidInput!) {
    markOldSubmittedPayAppsAsPaid(input: $input)
  }
`
export type MarkOldSubmittedPayAppsAsPaidMutationFn = Apollo.MutationFunction<
  MarkOldSubmittedPayAppsAsPaidMutation,
  MarkOldSubmittedPayAppsAsPaidMutationVariables
>

/**
 * __useMarkOldSubmittedPayAppsAsPaidMutation__
 *
 * To run a mutation, you first call `useMarkOldSubmittedPayAppsAsPaidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkOldSubmittedPayAppsAsPaidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markOldSubmittedPayAppsAsPaidMutation, { data, loading, error }] = useMarkOldSubmittedPayAppsAsPaidMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkOldSubmittedPayAppsAsPaidMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkOldSubmittedPayAppsAsPaidMutation,
    MarkOldSubmittedPayAppsAsPaidMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    MarkOldSubmittedPayAppsAsPaidMutation,
    MarkOldSubmittedPayAppsAsPaidMutationVariables
  >(MarkOldSubmittedPayAppsAsPaidDocument, options)
}
export type MarkOldSubmittedPayAppsAsPaidMutationHookResult = ReturnType<
  typeof useMarkOldSubmittedPayAppsAsPaidMutation
>
export type MarkOldSubmittedPayAppsAsPaidMutationResult =
  Apollo.MutationResult<MarkOldSubmittedPayAppsAsPaidMutation>
export type MarkOldSubmittedPayAppsAsPaidMutationOptions = Apollo.BaseMutationOptions<
  MarkOldSubmittedPayAppsAsPaidMutation,
  MarkOldSubmittedPayAppsAsPaidMutationVariables
>
export const DeleteLienWaiverRequestsForMonthDocument = gql`
  mutation deleteLienWaiverRequestsForMonth($input: DeleteLienWaiverRequestsForMonthInput!) {
    deleteLienWaiverRequestsForMonth(input: $input)
  }
`
export type DeleteLienWaiverRequestsForMonthMutationFn = Apollo.MutationFunction<
  DeleteLienWaiverRequestsForMonthMutation,
  DeleteLienWaiverRequestsForMonthMutationVariables
>

/**
 * __useDeleteLienWaiverRequestsForMonthMutation__
 *
 * To run a mutation, you first call `useDeleteLienWaiverRequestsForMonthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLienWaiverRequestsForMonthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLienWaiverRequestsForMonthMutation, { data, loading, error }] = useDeleteLienWaiverRequestsForMonthMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteLienWaiverRequestsForMonthMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteLienWaiverRequestsForMonthMutation,
    DeleteLienWaiverRequestsForMonthMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteLienWaiverRequestsForMonthMutation,
    DeleteLienWaiverRequestsForMonthMutationVariables
  >(DeleteLienWaiverRequestsForMonthDocument, options)
}
export type DeleteLienWaiverRequestsForMonthMutationHookResult = ReturnType<
  typeof useDeleteLienWaiverRequestsForMonthMutation
>
export type DeleteLienWaiverRequestsForMonthMutationResult =
  Apollo.MutationResult<DeleteLienWaiverRequestsForMonthMutation>
export type DeleteLienWaiverRequestsForMonthMutationOptions = Apollo.BaseMutationOptions<
  DeleteLienWaiverRequestsForMonthMutation,
  DeleteLienWaiverRequestsForMonthMutationVariables
>
export const MarkLienWaiverRequestsAsSubmittedForMonthDocument = gql`
  mutation markLienWaiverRequestsAsSubmittedForMonth(
    $input: MarkLienWaiverRequestsAsSubmittedForMonthInput!
  ) {
    markLienWaiverRequestsAsSubmittedForMonth(input: $input)
  }
`
export type MarkLienWaiverRequestsAsSubmittedForMonthMutationFn = Apollo.MutationFunction<
  MarkLienWaiverRequestsAsSubmittedForMonthMutation,
  MarkLienWaiverRequestsAsSubmittedForMonthMutationVariables
>

/**
 * __useMarkLienWaiverRequestsAsSubmittedForMonthMutation__
 *
 * To run a mutation, you first call `useMarkLienWaiverRequestsAsSubmittedForMonthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkLienWaiverRequestsAsSubmittedForMonthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markLienWaiverRequestsAsSubmittedForMonthMutation, { data, loading, error }] = useMarkLienWaiverRequestsAsSubmittedForMonthMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkLienWaiverRequestsAsSubmittedForMonthMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkLienWaiverRequestsAsSubmittedForMonthMutation,
    MarkLienWaiverRequestsAsSubmittedForMonthMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    MarkLienWaiverRequestsAsSubmittedForMonthMutation,
    MarkLienWaiverRequestsAsSubmittedForMonthMutationVariables
  >(MarkLienWaiverRequestsAsSubmittedForMonthDocument, options)
}
export type MarkLienWaiverRequestsAsSubmittedForMonthMutationHookResult = ReturnType<
  typeof useMarkLienWaiverRequestsAsSubmittedForMonthMutation
>
export type MarkLienWaiverRequestsAsSubmittedForMonthMutationResult =
  Apollo.MutationResult<MarkLienWaiverRequestsAsSubmittedForMonthMutation>
export type MarkLienWaiverRequestsAsSubmittedForMonthMutationOptions = Apollo.BaseMutationOptions<
  MarkLienWaiverRequestsAsSubmittedForMonthMutation,
  MarkLienWaiverRequestsAsSubmittedForMonthMutationVariables
>
export const CreateFormTemplateDocument = gql`
  mutation createFormTemplate($input: CreateFormTemplateInput!) {
    createFormTemplate(input: $input) {
      ...FormTemplateProperties
    }
  }
  ${FormTemplatePropertiesDoc}
`
export type CreateFormTemplateMutationFn = Apollo.MutationFunction<
  CreateFormTemplateMutation,
  CreateFormTemplateMutationVariables
>

/**
 * __useCreateFormTemplateMutation__
 *
 * To run a mutation, you first call `useCreateFormTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFormTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFormTemplateMutation, { data, loading, error }] = useCreateFormTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFormTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFormTemplateMutation,
    CreateFormTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateFormTemplateMutation, CreateFormTemplateMutationVariables>(
    CreateFormTemplateDocument,
    options
  )
}
export type CreateFormTemplateMutationHookResult = ReturnType<typeof useCreateFormTemplateMutation>
export type CreateFormTemplateMutationResult = Apollo.MutationResult<CreateFormTemplateMutation>
export type CreateFormTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateFormTemplateMutation,
  CreateFormTemplateMutationVariables
>
export const SitelineTeamMembersDocument = gql`
  query sitelineTeamMembers {
    sitelineTeamMembers {
      id
      firstName
      lastName
      email
    }
  }
`

/**
 * __useSitelineTeamMembersQuery__
 *
 * To run a query within a React component, call `useSitelineTeamMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSitelineTeamMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSitelineTeamMembersQuery({
 *   variables: {
 *   },
 * });
 */
export function useSitelineTeamMembersQuery(
  baseOptions?: Apollo.QueryHookOptions<SitelineTeamMembersQuery, SitelineTeamMembersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SitelineTeamMembersQuery, SitelineTeamMembersQueryVariables>(
    SitelineTeamMembersDocument,
    options
  )
}
export function useSitelineTeamMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SitelineTeamMembersQuery,
    SitelineTeamMembersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SitelineTeamMembersQuery, SitelineTeamMembersQueryVariables>(
    SitelineTeamMembersDocument,
    options
  )
}
export function useSitelineTeamMembersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<SitelineTeamMembersQuery, SitelineTeamMembersQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<SitelineTeamMembersQuery, SitelineTeamMembersQueryVariables>(
    SitelineTeamMembersDocument,
    options
  )
}
export type SitelineTeamMembersQueryHookResult = ReturnType<typeof useSitelineTeamMembersQuery>
export type SitelineTeamMembersLazyQueryHookResult = ReturnType<
  typeof useSitelineTeamMembersLazyQuery
>
export type SitelineTeamMembersSuspenseQueryHookResult = ReturnType<
  typeof useSitelineTeamMembersSuspenseQuery
>
export type SitelineTeamMembersQueryResult = Apollo.QueryResult<
  SitelineTeamMembersQuery,
  SitelineTeamMembersQueryVariables
>
export const UpdateFormTemplateStatusDocument = gql`
  mutation updateFormTemplateStatus($input: UpdateFormTemplateStatusInput!) {
    updateFormTemplateStatus(input: $input) {
      ...FormTemplateProperties
    }
  }
  ${FormTemplatePropertiesDoc}
`
export type UpdateFormTemplateStatusMutationFn = Apollo.MutationFunction<
  UpdateFormTemplateStatusMutation,
  UpdateFormTemplateStatusMutationVariables
>

/**
 * __useUpdateFormTemplateStatusMutation__
 *
 * To run a mutation, you first call `useUpdateFormTemplateStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFormTemplateStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFormTemplateStatusMutation, { data, loading, error }] = useUpdateFormTemplateStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFormTemplateStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFormTemplateStatusMutation,
    UpdateFormTemplateStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateFormTemplateStatusMutation,
    UpdateFormTemplateStatusMutationVariables
  >(UpdateFormTemplateStatusDocument, options)
}
export type UpdateFormTemplateStatusMutationHookResult = ReturnType<
  typeof useUpdateFormTemplateStatusMutation
>
export type UpdateFormTemplateStatusMutationResult =
  Apollo.MutationResult<UpdateFormTemplateStatusMutation>
export type UpdateFormTemplateStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateFormTemplateStatusMutation,
  UpdateFormTemplateStatusMutationVariables
>
export const UpdateFormTemplateOwnerDocument = gql`
  mutation updateFormTemplateOwner($input: UpdateFormTemplateUserInput!) {
    updateFormTemplateOwner(input: $input) {
      ...FormTemplateProperties
    }
  }
  ${FormTemplatePropertiesDoc}
`
export type UpdateFormTemplateOwnerMutationFn = Apollo.MutationFunction<
  UpdateFormTemplateOwnerMutation,
  UpdateFormTemplateOwnerMutationVariables
>

/**
 * __useUpdateFormTemplateOwnerMutation__
 *
 * To run a mutation, you first call `useUpdateFormTemplateOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFormTemplateOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFormTemplateOwnerMutation, { data, loading, error }] = useUpdateFormTemplateOwnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFormTemplateOwnerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFormTemplateOwnerMutation,
    UpdateFormTemplateOwnerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateFormTemplateOwnerMutation,
    UpdateFormTemplateOwnerMutationVariables
  >(UpdateFormTemplateOwnerDocument, options)
}
export type UpdateFormTemplateOwnerMutationHookResult = ReturnType<
  typeof useUpdateFormTemplateOwnerMutation
>
export type UpdateFormTemplateOwnerMutationResult =
  Apollo.MutationResult<UpdateFormTemplateOwnerMutation>
export type UpdateFormTemplateOwnerMutationOptions = Apollo.BaseMutationOptions<
  UpdateFormTemplateOwnerMutation,
  UpdateFormTemplateOwnerMutationVariables
>
export const UpdateFormTemplateBuilderDocument = gql`
  mutation updateFormTemplateBuilder($input: UpdateFormTemplateUserInput!) {
    updateFormTemplateBuilder(input: $input) {
      ...FormTemplateProperties
    }
  }
  ${FormTemplatePropertiesDoc}
`
export type UpdateFormTemplateBuilderMutationFn = Apollo.MutationFunction<
  UpdateFormTemplateBuilderMutation,
  UpdateFormTemplateBuilderMutationVariables
>

/**
 * __useUpdateFormTemplateBuilderMutation__
 *
 * To run a mutation, you first call `useUpdateFormTemplateBuilderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFormTemplateBuilderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFormTemplateBuilderMutation, { data, loading, error }] = useUpdateFormTemplateBuilderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFormTemplateBuilderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFormTemplateBuilderMutation,
    UpdateFormTemplateBuilderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateFormTemplateBuilderMutation,
    UpdateFormTemplateBuilderMutationVariables
  >(UpdateFormTemplateBuilderDocument, options)
}
export type UpdateFormTemplateBuilderMutationHookResult = ReturnType<
  typeof useUpdateFormTemplateBuilderMutation
>
export type UpdateFormTemplateBuilderMutationResult =
  Apollo.MutationResult<UpdateFormTemplateBuilderMutation>
export type UpdateFormTemplateBuilderMutationOptions = Apollo.BaseMutationOptions<
  UpdateFormTemplateBuilderMutation,
  UpdateFormTemplateBuilderMutationVariables
>
export const UpdateFormTemplateValidatorDocument = gql`
  mutation updateFormTemplateValidator($input: UpdateFormTemplateUserInput!) {
    updateFormTemplateValidator(input: $input) {
      ...FormTemplateProperties
    }
  }
  ${FormTemplatePropertiesDoc}
`
export type UpdateFormTemplateValidatorMutationFn = Apollo.MutationFunction<
  UpdateFormTemplateValidatorMutation,
  UpdateFormTemplateValidatorMutationVariables
>

/**
 * __useUpdateFormTemplateValidatorMutation__
 *
 * To run a mutation, you first call `useUpdateFormTemplateValidatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFormTemplateValidatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFormTemplateValidatorMutation, { data, loading, error }] = useUpdateFormTemplateValidatorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFormTemplateValidatorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFormTemplateValidatorMutation,
    UpdateFormTemplateValidatorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateFormTemplateValidatorMutation,
    UpdateFormTemplateValidatorMutationVariables
  >(UpdateFormTemplateValidatorDocument, options)
}
export type UpdateFormTemplateValidatorMutationHookResult = ReturnType<
  typeof useUpdateFormTemplateValidatorMutation
>
export type UpdateFormTemplateValidatorMutationResult =
  Apollo.MutationResult<UpdateFormTemplateValidatorMutation>
export type UpdateFormTemplateValidatorMutationOptions = Apollo.BaseMutationOptions<
  UpdateFormTemplateValidatorMutation,
  UpdateFormTemplateValidatorMutationVariables
>
export const UpdateFormTemplateOriginalFileDocument = gql`
  mutation updateFormTemplateOriginalFile($input: UpdateFormTemplateOriginalFileInput!) {
    updateFormTemplateOriginalFile(input: $input) {
      ...FormTemplateProperties
    }
  }
  ${FormTemplatePropertiesDoc}
`
export type UpdateFormTemplateOriginalFileMutationFn = Apollo.MutationFunction<
  UpdateFormTemplateOriginalFileMutation,
  UpdateFormTemplateOriginalFileMutationVariables
>

/**
 * __useUpdateFormTemplateOriginalFileMutation__
 *
 * To run a mutation, you first call `useUpdateFormTemplateOriginalFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFormTemplateOriginalFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFormTemplateOriginalFileMutation, { data, loading, error }] = useUpdateFormTemplateOriginalFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFormTemplateOriginalFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFormTemplateOriginalFileMutation,
    UpdateFormTemplateOriginalFileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateFormTemplateOriginalFileMutation,
    UpdateFormTemplateOriginalFileMutationVariables
  >(UpdateFormTemplateOriginalFileDocument, options)
}
export type UpdateFormTemplateOriginalFileMutationHookResult = ReturnType<
  typeof useUpdateFormTemplateOriginalFileMutation
>
export type UpdateFormTemplateOriginalFileMutationResult =
  Apollo.MutationResult<UpdateFormTemplateOriginalFileMutation>
export type UpdateFormTemplateOriginalFileMutationOptions = Apollo.BaseMutationOptions<
  UpdateFormTemplateOriginalFileMutation,
  UpdateFormTemplateOriginalFileMutationVariables
>
export const UpdateFormTemplateDueDateDocument = gql`
  mutation updateFormTemplateDueDate($input: UpdateFormTemplateDueDateInput!) {
    updateFormTemplateDueDate(input: $input) {
      ...FormTemplateProperties
    }
  }
  ${FormTemplatePropertiesDoc}
`
export type UpdateFormTemplateDueDateMutationFn = Apollo.MutationFunction<
  UpdateFormTemplateDueDateMutation,
  UpdateFormTemplateDueDateMutationVariables
>

/**
 * __useUpdateFormTemplateDueDateMutation__
 *
 * To run a mutation, you first call `useUpdateFormTemplateDueDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFormTemplateDueDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFormTemplateDueDateMutation, { data, loading, error }] = useUpdateFormTemplateDueDateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFormTemplateDueDateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFormTemplateDueDateMutation,
    UpdateFormTemplateDueDateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateFormTemplateDueDateMutation,
    UpdateFormTemplateDueDateMutationVariables
  >(UpdateFormTemplateDueDateDocument, options)
}
export type UpdateFormTemplateDueDateMutationHookResult = ReturnType<
  typeof useUpdateFormTemplateDueDateMutation
>
export type UpdateFormTemplateDueDateMutationResult =
  Apollo.MutationResult<UpdateFormTemplateDueDateMutation>
export type UpdateFormTemplateDueDateMutationOptions = Apollo.BaseMutationOptions<
  UpdateFormTemplateDueDateMutation,
  UpdateFormTemplateDueDateMutationVariables
>
export const MarkFormTemplateAsDuplicateDocument = gql`
  mutation markFormTemplateAsDuplicate($input: MarkFormTemplateAsDuplicateInput!) {
    markFormTemplateAsDuplicate(input: $input) {
      ...FormTemplateProperties
    }
  }
  ${FormTemplatePropertiesDoc}
`
export type MarkFormTemplateAsDuplicateMutationFn = Apollo.MutationFunction<
  MarkFormTemplateAsDuplicateMutation,
  MarkFormTemplateAsDuplicateMutationVariables
>

/**
 * __useMarkFormTemplateAsDuplicateMutation__
 *
 * To run a mutation, you first call `useMarkFormTemplateAsDuplicateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkFormTemplateAsDuplicateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markFormTemplateAsDuplicateMutation, { data, loading, error }] = useMarkFormTemplateAsDuplicateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkFormTemplateAsDuplicateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkFormTemplateAsDuplicateMutation,
    MarkFormTemplateAsDuplicateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    MarkFormTemplateAsDuplicateMutation,
    MarkFormTemplateAsDuplicateMutationVariables
  >(MarkFormTemplateAsDuplicateDocument, options)
}
export type MarkFormTemplateAsDuplicateMutationHookResult = ReturnType<
  typeof useMarkFormTemplateAsDuplicateMutation
>
export type MarkFormTemplateAsDuplicateMutationResult =
  Apollo.MutationResult<MarkFormTemplateAsDuplicateMutation>
export type MarkFormTemplateAsDuplicateMutationOptions = Apollo.BaseMutationOptions<
  MarkFormTemplateAsDuplicateMutation,
  MarkFormTemplateAsDuplicateMutationVariables
>
export const AddFormTemplateCommentDocument = gql`
  mutation addFormTemplateComment($input: AddFormTemplateCommentInput!) {
    addFormTemplateComment(input: $input) {
      ...FormTemplateCommentProperties
    }
  }
  ${FormTemplateCommentPropertiesDoc}
`
export type AddFormTemplateCommentMutationFn = Apollo.MutationFunction<
  AddFormTemplateCommentMutation,
  AddFormTemplateCommentMutationVariables
>

/**
 * __useAddFormTemplateCommentMutation__
 *
 * To run a mutation, you first call `useAddFormTemplateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFormTemplateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFormTemplateCommentMutation, { data, loading, error }] = useAddFormTemplateCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddFormTemplateCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddFormTemplateCommentMutation,
    AddFormTemplateCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddFormTemplateCommentMutation,
    AddFormTemplateCommentMutationVariables
  >(AddFormTemplateCommentDocument, options)
}
export type AddFormTemplateCommentMutationHookResult = ReturnType<
  typeof useAddFormTemplateCommentMutation
>
export type AddFormTemplateCommentMutationResult =
  Apollo.MutationResult<AddFormTemplateCommentMutation>
export type AddFormTemplateCommentMutationOptions = Apollo.BaseMutationOptions<
  AddFormTemplateCommentMutation,
  AddFormTemplateCommentMutationVariables
>
export const DeleteFormTemplateCommentDocument = gql`
  mutation deleteFormTemplateComment($id: ID!) {
    deleteFormTemplateComment(id: $id) {
      id
    }
  }
`
export type DeleteFormTemplateCommentMutationFn = Apollo.MutationFunction<
  DeleteFormTemplateCommentMutation,
  DeleteFormTemplateCommentMutationVariables
>

/**
 * __useDeleteFormTemplateCommentMutation__
 *
 * To run a mutation, you first call `useDeleteFormTemplateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFormTemplateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFormTemplateCommentMutation, { data, loading, error }] = useDeleteFormTemplateCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFormTemplateCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteFormTemplateCommentMutation,
    DeleteFormTemplateCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteFormTemplateCommentMutation,
    DeleteFormTemplateCommentMutationVariables
  >(DeleteFormTemplateCommentDocument, options)
}
export type DeleteFormTemplateCommentMutationHookResult = ReturnType<
  typeof useDeleteFormTemplateCommentMutation
>
export type DeleteFormTemplateCommentMutationResult =
  Apollo.MutationResult<DeleteFormTemplateCommentMutation>
export type DeleteFormTemplateCommentMutationOptions = Apollo.BaseMutationOptions<
  DeleteFormTemplateCommentMutation,
  DeleteFormTemplateCommentMutationVariables
>
export const GetFormTemplateDocument = gql`
  query getFormTemplate($id: ID!) {
    formTemplate(id: $id) {
      ...FormTemplateProperties
      requestingCompany {
        id
        name
        picture {
          id
          url
        }
        notaryStamp {
          id
          url
        }
        notarySignature {
          id
          url
        }
        secondarySignature {
          id
          url
        }
      }
    }
  }
  ${FormTemplatePropertiesDoc}
`

/**
 * __useGetFormTemplateQuery__
 *
 * To run a query within a React component, call `useGetFormTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFormTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<GetFormTemplateQuery, GetFormTemplateQueryVariables> &
    ({ variables: GetFormTemplateQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetFormTemplateQuery, GetFormTemplateQueryVariables>(
    GetFormTemplateDocument,
    options
  )
}
export function useGetFormTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFormTemplateQuery, GetFormTemplateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetFormTemplateQuery, GetFormTemplateQueryVariables>(
    GetFormTemplateDocument,
    options
  )
}
export function useGetFormTemplateSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetFormTemplateQuery, GetFormTemplateQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetFormTemplateQuery, GetFormTemplateQueryVariables>(
    GetFormTemplateDocument,
    options
  )
}
export type GetFormTemplateQueryHookResult = ReturnType<typeof useGetFormTemplateQuery>
export type GetFormTemplateLazyQueryHookResult = ReturnType<typeof useGetFormTemplateLazyQuery>
export type GetFormTemplateSuspenseQueryHookResult = ReturnType<
  typeof useGetFormTemplateSuspenseQuery
>
export type GetFormTemplateQueryResult = Apollo.QueryResult<
  GetFormTemplateQuery,
  GetFormTemplateQueryVariables
>
export const FormTemplateForDetailsDocument = gql`
  query formTemplateForDetails($id: ID!) {
    formTemplate(id: $id) {
      id
      createdAt
      type
      status
      statusLogs {
        ...FormTemplateStatusLogProperties
      }
      skippedValidation
      userVisibleName
      isCustomerReady
      tags
      dueDate
      associatedCompany {
        id
      }
      requestingCompany {
        id
      }
      associatedContracts {
        id
        providedAsFormType
        contract {
          id
          daysBeforePayAppDue
          project {
            id
            name
            timeZone
            metadata {
              payAppDueOnDayOfMonth
            }
          }
          company {
            id
            name
          }
        }
      }
      owner {
        id
        email
        firstName
        lastName
      }
      builder {
        id
        email
        firstName
        lastName
      }
      validator {
        id
        email
        firstName
        lastName
      }
      versions {
        ...FormTemplateVersionProperties
      }
      variants {
        id
        internalName
        isDefaultVariant
      }
      comments {
        ...FormTemplateCommentProperties
      }
      originalFile {
        ...StoredFileProperties
      }
      duplicateTemplate {
        id
        userVisibleName
      }
    }
  }
  ${FormTemplateStatusLogPropertiesDoc}
  ${FormTemplateVersionPropertiesDoc}
  ${FormTemplateCommentPropertiesDoc}
  ${StoredFilePropertiesDoc}
`

/**
 * __useFormTemplateForDetailsQuery__
 *
 * To run a query within a React component, call `useFormTemplateForDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormTemplateForDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormTemplateForDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFormTemplateForDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FormTemplateForDetailsQuery,
    FormTemplateForDetailsQueryVariables
  > &
    ({ variables: FormTemplateForDetailsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FormTemplateForDetailsQuery, FormTemplateForDetailsQueryVariables>(
    FormTemplateForDetailsDocument,
    options
  )
}
export function useFormTemplateForDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FormTemplateForDetailsQuery,
    FormTemplateForDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FormTemplateForDetailsQuery, FormTemplateForDetailsQueryVariables>(
    FormTemplateForDetailsDocument,
    options
  )
}
export function useFormTemplateForDetailsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FormTemplateForDetailsQuery,
        FormTemplateForDetailsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<FormTemplateForDetailsQuery, FormTemplateForDetailsQueryVariables>(
    FormTemplateForDetailsDocument,
    options
  )
}
export type FormTemplateForDetailsQueryHookResult = ReturnType<
  typeof useFormTemplateForDetailsQuery
>
export type FormTemplateForDetailsLazyQueryHookResult = ReturnType<
  typeof useFormTemplateForDetailsLazyQuery
>
export type FormTemplateForDetailsSuspenseQueryHookResult = ReturnType<
  typeof useFormTemplateForDetailsSuspenseQuery
>
export type FormTemplateForDetailsQueryResult = Apollo.QueryResult<
  FormTemplateForDetailsQuery,
  FormTemplateForDetailsQueryVariables
>
export const UpdatedFormTemplateDocument = gql`
  subscription updatedFormTemplate($id: ID!) {
    updatedFormTemplate(id: $id) {
      ...FormTemplateProperties
    }
  }
  ${FormTemplatePropertiesDoc}
`

/**
 * __useUpdatedFormTemplateSubscription__
 *
 * To run a query within a React component, call `useUpdatedFormTemplateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUpdatedFormTemplateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdatedFormTemplateSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdatedFormTemplateSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    UpdatedFormTemplateSubscription,
    UpdatedFormTemplateSubscriptionVariables
  > &
    ({ variables: UpdatedFormTemplateSubscriptionVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    UpdatedFormTemplateSubscription,
    UpdatedFormTemplateSubscriptionVariables
  >(UpdatedFormTemplateDocument, options)
}
export type UpdatedFormTemplateSubscriptionHookResult = ReturnType<
  typeof useUpdatedFormTemplateSubscription
>
export type UpdatedFormTemplateSubscriptionResult =
  Apollo.SubscriptionResult<UpdatedFormTemplateSubscription>
export const ContractsWithTemplateDocument = gql`
  query contractsWithTemplate($input: GetPaginatedContractsInput!) {
    paginatedContracts(input: $input) {
      hasNext
      totalCount
      cursor
      contracts {
        id
        project {
          id
          name
        }
        company {
          id
          name
        }
      }
    }
  }
`

/**
 * __useContractsWithTemplateQuery__
 *
 * To run a query within a React component, call `useContractsWithTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractsWithTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractsWithTemplateQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContractsWithTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<
    ContractsWithTemplateQuery,
    ContractsWithTemplateQueryVariables
  > &
    ({ variables: ContractsWithTemplateQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ContractsWithTemplateQuery, ContractsWithTemplateQueryVariables>(
    ContractsWithTemplateDocument,
    options
  )
}
export function useContractsWithTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContractsWithTemplateQuery,
    ContractsWithTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ContractsWithTemplateQuery, ContractsWithTemplateQueryVariables>(
    ContractsWithTemplateDocument,
    options
  )
}
export function useContractsWithTemplateSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ContractsWithTemplateQuery,
        ContractsWithTemplateQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ContractsWithTemplateQuery, ContractsWithTemplateQueryVariables>(
    ContractsWithTemplateDocument,
    options
  )
}
export type ContractsWithTemplateQueryHookResult = ReturnType<typeof useContractsWithTemplateQuery>
export type ContractsWithTemplateLazyQueryHookResult = ReturnType<
  typeof useContractsWithTemplateLazyQuery
>
export type ContractsWithTemplateSuspenseQueryHookResult = ReturnType<
  typeof useContractsWithTemplateSuspenseQuery
>
export type ContractsWithTemplateQueryResult = Apollo.QueryResult<
  ContractsWithTemplateQuery,
  ContractsWithTemplateQueryVariables
>
export const ContractsForAutocompleteDocument = gql`
  query contractsForAutocomplete($input: GetPaginatedContractsInput!) {
    paginatedContracts(input: $input) {
      contracts {
        id
        daysBeforePayAppDue
        company {
          id
          name
        }
        project {
          id
          name
          timeZone
          metadata {
            payAppDueOnDayOfMonth
          }
        }
      }
    }
  }
`

/**
 * __useContractsForAutocompleteQuery__
 *
 * To run a query within a React component, call `useContractsForAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractsForAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractsForAutocompleteQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContractsForAutocompleteQuery(
  baseOptions: Apollo.QueryHookOptions<
    ContractsForAutocompleteQuery,
    ContractsForAutocompleteQueryVariables
  > &
    ({ variables: ContractsForAutocompleteQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ContractsForAutocompleteQuery, ContractsForAutocompleteQueryVariables>(
    ContractsForAutocompleteDocument,
    options
  )
}
export function useContractsForAutocompleteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContractsForAutocompleteQuery,
    ContractsForAutocompleteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ContractsForAutocompleteQuery, ContractsForAutocompleteQueryVariables>(
    ContractsForAutocompleteDocument,
    options
  )
}
export function useContractsForAutocompleteSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ContractsForAutocompleteQuery,
        ContractsForAutocompleteQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ContractsForAutocompleteQuery,
    ContractsForAutocompleteQueryVariables
  >(ContractsForAutocompleteDocument, options)
}
export type ContractsForAutocompleteQueryHookResult = ReturnType<
  typeof useContractsForAutocompleteQuery
>
export type ContractsForAutocompleteLazyQueryHookResult = ReturnType<
  typeof useContractsForAutocompleteLazyQuery
>
export type ContractsForAutocompleteSuspenseQueryHookResult = ReturnType<
  typeof useContractsForAutocompleteSuspenseQuery
>
export type ContractsForAutocompleteQueryResult = Apollo.QueryResult<
  ContractsForAutocompleteQuery,
  ContractsForAutocompleteQueryVariables
>
export const UpdateFormTemplateDocument = gql`
  mutation updateFormTemplate($input: UpdateFormTemplateInput!) {
    updateFormTemplate(input: $input) {
      ...FormTemplateProperties
    }
  }
  ${FormTemplatePropertiesDoc}
`
export type UpdateFormTemplateMutationFn = Apollo.MutationFunction<
  UpdateFormTemplateMutation,
  UpdateFormTemplateMutationVariables
>

/**
 * __useUpdateFormTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateFormTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFormTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFormTemplateMutation, { data, loading, error }] = useUpdateFormTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFormTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFormTemplateMutation,
    UpdateFormTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateFormTemplateMutation, UpdateFormTemplateMutationVariables>(
    UpdateFormTemplateDocument,
    options
  )
}
export type UpdateFormTemplateMutationHookResult = ReturnType<typeof useUpdateFormTemplateMutation>
export type UpdateFormTemplateMutationResult = Apollo.MutationResult<UpdateFormTemplateMutation>
export type UpdateFormTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpdateFormTemplateMutation,
  UpdateFormTemplateMutationVariables
>
export const UpdateFormTemplateAssociatedCompanyDocument = gql`
  mutation updateFormTemplateAssociatedCompany($input: UpdateFormTemplateAssociatedCompanyInput!) {
    updateFormTemplateAssociatedCompany(input: $input) {
      ...FormTemplateProperties
    }
  }
  ${FormTemplatePropertiesDoc}
`
export type UpdateFormTemplateAssociatedCompanyMutationFn = Apollo.MutationFunction<
  UpdateFormTemplateAssociatedCompanyMutation,
  UpdateFormTemplateAssociatedCompanyMutationVariables
>

/**
 * __useUpdateFormTemplateAssociatedCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateFormTemplateAssociatedCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFormTemplateAssociatedCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFormTemplateAssociatedCompanyMutation, { data, loading, error }] = useUpdateFormTemplateAssociatedCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFormTemplateAssociatedCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFormTemplateAssociatedCompanyMutation,
    UpdateFormTemplateAssociatedCompanyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateFormTemplateAssociatedCompanyMutation,
    UpdateFormTemplateAssociatedCompanyMutationVariables
  >(UpdateFormTemplateAssociatedCompanyDocument, options)
}
export type UpdateFormTemplateAssociatedCompanyMutationHookResult = ReturnType<
  typeof useUpdateFormTemplateAssociatedCompanyMutation
>
export type UpdateFormTemplateAssociatedCompanyMutationResult =
  Apollo.MutationResult<UpdateFormTemplateAssociatedCompanyMutation>
export type UpdateFormTemplateAssociatedCompanyMutationOptions = Apollo.BaseMutationOptions<
  UpdateFormTemplateAssociatedCompanyMutation,
  UpdateFormTemplateAssociatedCompanyMutationVariables
>
export const UpdateFormTemplateRequestingCompanyDocument = gql`
  mutation updateFormTemplateRequestingCompany($input: UpdateFormTemplateRequestingCompanyInput!) {
    updateFormTemplateRequestingCompany(input: $input) {
      ...FormTemplateProperties
    }
  }
  ${FormTemplatePropertiesDoc}
`
export type UpdateFormTemplateRequestingCompanyMutationFn = Apollo.MutationFunction<
  UpdateFormTemplateRequestingCompanyMutation,
  UpdateFormTemplateRequestingCompanyMutationVariables
>

/**
 * __useUpdateFormTemplateRequestingCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateFormTemplateRequestingCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFormTemplateRequestingCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFormTemplateRequestingCompanyMutation, { data, loading, error }] = useUpdateFormTemplateRequestingCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFormTemplateRequestingCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFormTemplateRequestingCompanyMutation,
    UpdateFormTemplateRequestingCompanyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateFormTemplateRequestingCompanyMutation,
    UpdateFormTemplateRequestingCompanyMutationVariables
  >(UpdateFormTemplateRequestingCompanyDocument, options)
}
export type UpdateFormTemplateRequestingCompanyMutationHookResult = ReturnType<
  typeof useUpdateFormTemplateRequestingCompanyMutation
>
export type UpdateFormTemplateRequestingCompanyMutationResult =
  Apollo.MutationResult<UpdateFormTemplateRequestingCompanyMutation>
export type UpdateFormTemplateRequestingCompanyMutationOptions = Apollo.BaseMutationOptions<
  UpdateFormTemplateRequestingCompanyMutation,
  UpdateFormTemplateRequestingCompanyMutationVariables
>
export const UpdateFormTemplateAssociatedContractsDocument = gql`
  mutation updateFormTemplateAssociatedContracts(
    $input: UpdateFormTemplateAssociatedContractsInput!
  ) {
    updateFormTemplateAssociatedContracts(input: $input) {
      ...FormTemplateProperties
    }
  }
  ${FormTemplatePropertiesDoc}
`
export type UpdateFormTemplateAssociatedContractsMutationFn = Apollo.MutationFunction<
  UpdateFormTemplateAssociatedContractsMutation,
  UpdateFormTemplateAssociatedContractsMutationVariables
>

/**
 * __useUpdateFormTemplateAssociatedContractsMutation__
 *
 * To run a mutation, you first call `useUpdateFormTemplateAssociatedContractsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFormTemplateAssociatedContractsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFormTemplateAssociatedContractsMutation, { data, loading, error }] = useUpdateFormTemplateAssociatedContractsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFormTemplateAssociatedContractsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFormTemplateAssociatedContractsMutation,
    UpdateFormTemplateAssociatedContractsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateFormTemplateAssociatedContractsMutation,
    UpdateFormTemplateAssociatedContractsMutationVariables
  >(UpdateFormTemplateAssociatedContractsDocument, options)
}
export type UpdateFormTemplateAssociatedContractsMutationHookResult = ReturnType<
  typeof useUpdateFormTemplateAssociatedContractsMutation
>
export type UpdateFormTemplateAssociatedContractsMutationResult =
  Apollo.MutationResult<UpdateFormTemplateAssociatedContractsMutation>
export type UpdateFormTemplateAssociatedContractsMutationOptions = Apollo.BaseMutationOptions<
  UpdateFormTemplateAssociatedContractsMutation,
  UpdateFormTemplateAssociatedContractsMutationVariables
>
export const DeleteFormTemplateDocument = gql`
  mutation deleteFormTemplate($id: ID!) {
    deleteFormTemplate(id: $id) {
      id
    }
  }
`
export type DeleteFormTemplateMutationFn = Apollo.MutationFunction<
  DeleteFormTemplateMutation,
  DeleteFormTemplateMutationVariables
>

/**
 * __useDeleteFormTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteFormTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFormTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFormTemplateMutation, { data, loading, error }] = useDeleteFormTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFormTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteFormTemplateMutation,
    DeleteFormTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteFormTemplateMutation, DeleteFormTemplateMutationVariables>(
    DeleteFormTemplateDocument,
    options
  )
}
export type DeleteFormTemplateMutationHookResult = ReturnType<typeof useDeleteFormTemplateMutation>
export type DeleteFormTemplateMutationResult = Apollo.MutationResult<DeleteFormTemplateMutation>
export type DeleteFormTemplateMutationOptions = Apollo.BaseMutationOptions<
  DeleteFormTemplateMutation,
  DeleteFormTemplateMutationVariables
>
export const CloneFormTemplateDocument = gql`
  mutation cloneFormTemplate($input: CloneFormTemplateInput!) {
    cloneFormTemplate(input: $input) {
      ...FormTemplateProperties
    }
  }
  ${FormTemplatePropertiesDoc}
`
export type CloneFormTemplateMutationFn = Apollo.MutationFunction<
  CloneFormTemplateMutation,
  CloneFormTemplateMutationVariables
>

/**
 * __useCloneFormTemplateMutation__
 *
 * To run a mutation, you first call `useCloneFormTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneFormTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneFormTemplateMutation, { data, loading, error }] = useCloneFormTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCloneFormTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CloneFormTemplateMutation,
    CloneFormTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CloneFormTemplateMutation, CloneFormTemplateMutationVariables>(
    CloneFormTemplateDocument,
    options
  )
}
export type CloneFormTemplateMutationHookResult = ReturnType<typeof useCloneFormTemplateMutation>
export type CloneFormTemplateMutationResult = Apollo.MutationResult<CloneFormTemplateMutation>
export type CloneFormTemplateMutationOptions = Apollo.BaseMutationOptions<
  CloneFormTemplateMutation,
  CloneFormTemplateMutationVariables
>
export const SetFormTemplateTagsDocument = gql`
  mutation setFormTemplateTags($input: SetFormTemplateTagsInput!) {
    setFormTemplateTags(input: $input) {
      ...FormTemplateProperties
    }
  }
  ${FormTemplatePropertiesDoc}
`
export type SetFormTemplateTagsMutationFn = Apollo.MutationFunction<
  SetFormTemplateTagsMutation,
  SetFormTemplateTagsMutationVariables
>

/**
 * __useSetFormTemplateTagsMutation__
 *
 * To run a mutation, you first call `useSetFormTemplateTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetFormTemplateTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setFormTemplateTagsMutation, { data, loading, error }] = useSetFormTemplateTagsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetFormTemplateTagsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetFormTemplateTagsMutation,
    SetFormTemplateTagsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SetFormTemplateTagsMutation, SetFormTemplateTagsMutationVariables>(
    SetFormTemplateTagsDocument,
    options
  )
}
export type SetFormTemplateTagsMutationHookResult = ReturnType<
  typeof useSetFormTemplateTagsMutation
>
export type SetFormTemplateTagsMutationResult = Apollo.MutationResult<SetFormTemplateTagsMutation>
export type SetFormTemplateTagsMutationOptions = Apollo.BaseMutationOptions<
  SetFormTemplateTagsMutation,
  SetFormTemplateTagsMutationVariables
>
export const FormTemplateContractsForDueDateDocument = gql`
  query formTemplateContractsForDueDate($input: ContractsByIdInput!) {
    contractsById(input: $input) {
      id
      skippedPayAppMonths
      daysBeforePayAppDue
      timeZone
      project {
        id
        name
        metadata {
          payAppDueOnDayOfMonth
        }
      }
      payApps {
        id
        payAppNumber
        billingEnd
        internalDueDate
        status
      }
    }
  }
`

/**
 * __useFormTemplateContractsForDueDateQuery__
 *
 * To run a query within a React component, call `useFormTemplateContractsForDueDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormTemplateContractsForDueDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormTemplateContractsForDueDateQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFormTemplateContractsForDueDateQuery(
  baseOptions: Apollo.QueryHookOptions<
    FormTemplateContractsForDueDateQuery,
    FormTemplateContractsForDueDateQueryVariables
  > &
    (
      | { variables: FormTemplateContractsForDueDateQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FormTemplateContractsForDueDateQuery,
    FormTemplateContractsForDueDateQueryVariables
  >(FormTemplateContractsForDueDateDocument, options)
}
export function useFormTemplateContractsForDueDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FormTemplateContractsForDueDateQuery,
    FormTemplateContractsForDueDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FormTemplateContractsForDueDateQuery,
    FormTemplateContractsForDueDateQueryVariables
  >(FormTemplateContractsForDueDateDocument, options)
}
export function useFormTemplateContractsForDueDateSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FormTemplateContractsForDueDateQuery,
        FormTemplateContractsForDueDateQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    FormTemplateContractsForDueDateQuery,
    FormTemplateContractsForDueDateQueryVariables
  >(FormTemplateContractsForDueDateDocument, options)
}
export type FormTemplateContractsForDueDateQueryHookResult = ReturnType<
  typeof useFormTemplateContractsForDueDateQuery
>
export type FormTemplateContractsForDueDateLazyQueryHookResult = ReturnType<
  typeof useFormTemplateContractsForDueDateLazyQuery
>
export type FormTemplateContractsForDueDateSuspenseQueryHookResult = ReturnType<
  typeof useFormTemplateContractsForDueDateSuspenseQuery
>
export type FormTemplateContractsForDueDateQueryResult = Apollo.QueryResult<
  FormTemplateContractsForDueDateQuery,
  FormTemplateContractsForDueDateQueryVariables
>
export const CloneFormTemplateVersionDocument = gql`
  mutation cloneFormTemplateVersion($formTemplateVersionId: ID!) {
    cloneFormTemplateVersion(formTemplateVersionId: $formTemplateVersionId) {
      ...FormTemplateVersionProperties
    }
  }
  ${FormTemplateVersionPropertiesDoc}
`
export type CloneFormTemplateVersionMutationFn = Apollo.MutationFunction<
  CloneFormTemplateVersionMutation,
  CloneFormTemplateVersionMutationVariables
>

/**
 * __useCloneFormTemplateVersionMutation__
 *
 * To run a mutation, you first call `useCloneFormTemplateVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneFormTemplateVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneFormTemplateVersionMutation, { data, loading, error }] = useCloneFormTemplateVersionMutation({
 *   variables: {
 *      formTemplateVersionId: // value for 'formTemplateVersionId'
 *   },
 * });
 */
export function useCloneFormTemplateVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CloneFormTemplateVersionMutation,
    CloneFormTemplateVersionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CloneFormTemplateVersionMutation,
    CloneFormTemplateVersionMutationVariables
  >(CloneFormTemplateVersionDocument, options)
}
export type CloneFormTemplateVersionMutationHookResult = ReturnType<
  typeof useCloneFormTemplateVersionMutation
>
export type CloneFormTemplateVersionMutationResult =
  Apollo.MutationResult<CloneFormTemplateVersionMutation>
export type CloneFormTemplateVersionMutationOptions = Apollo.BaseMutationOptions<
  CloneFormTemplateVersionMutation,
  CloneFormTemplateVersionMutationVariables
>
export const SkipFormTemplateValidationDocument = gql`
  mutation skipFormTemplateValidation($input: SkipFormTemplateValidationInput!) {
    skipFormTemplateValidation(input: $input) {
      ...FormTemplateProperties
    }
  }
  ${FormTemplatePropertiesDoc}
`
export type SkipFormTemplateValidationMutationFn = Apollo.MutationFunction<
  SkipFormTemplateValidationMutation,
  SkipFormTemplateValidationMutationVariables
>

/**
 * __useSkipFormTemplateValidationMutation__
 *
 * To run a mutation, you first call `useSkipFormTemplateValidationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSkipFormTemplateValidationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [skipFormTemplateValidationMutation, { data, loading, error }] = useSkipFormTemplateValidationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSkipFormTemplateValidationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SkipFormTemplateValidationMutation,
    SkipFormTemplateValidationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SkipFormTemplateValidationMutation,
    SkipFormTemplateValidationMutationVariables
  >(SkipFormTemplateValidationDocument, options)
}
export type SkipFormTemplateValidationMutationHookResult = ReturnType<
  typeof useSkipFormTemplateValidationMutation
>
export type SkipFormTemplateValidationMutationResult =
  Apollo.MutationResult<SkipFormTemplateValidationMutation>
export type SkipFormTemplateValidationMutationOptions = Apollo.BaseMutationOptions<
  SkipFormTemplateValidationMutation,
  SkipFormTemplateValidationMutationVariables
>
export const ContractForAutocompleteDocument = gql`
  query contractForAutocomplete($id: ID!) {
    contract(id: $id) {
      id
      daysBeforePayAppDue
      company {
        id
        name
      }
      project {
        id
        name
        timeZone
        metadata {
          payAppDueOnDayOfMonth
        }
      }
    }
  }
`

/**
 * __useContractForAutocompleteQuery__
 *
 * To run a query within a React component, call `useContractForAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractForAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractForAutocompleteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContractForAutocompleteQuery(
  baseOptions: Apollo.QueryHookOptions<
    ContractForAutocompleteQuery,
    ContractForAutocompleteQueryVariables
  > &
    ({ variables: ContractForAutocompleteQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ContractForAutocompleteQuery, ContractForAutocompleteQueryVariables>(
    ContractForAutocompleteDocument,
    options
  )
}
export function useContractForAutocompleteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContractForAutocompleteQuery,
    ContractForAutocompleteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ContractForAutocompleteQuery, ContractForAutocompleteQueryVariables>(
    ContractForAutocompleteDocument,
    options
  )
}
export function useContractForAutocompleteSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ContractForAutocompleteQuery,
        ContractForAutocompleteQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ContractForAutocompleteQuery,
    ContractForAutocompleteQueryVariables
  >(ContractForAutocompleteDocument, options)
}
export type ContractForAutocompleteQueryHookResult = ReturnType<
  typeof useContractForAutocompleteQuery
>
export type ContractForAutocompleteLazyQueryHookResult = ReturnType<
  typeof useContractForAutocompleteLazyQuery
>
export type ContractForAutocompleteSuspenseQueryHookResult = ReturnType<
  typeof useContractForAutocompleteSuspenseQuery
>
export type ContractForAutocompleteQueryResult = Apollo.QueryResult<
  ContractForAutocompleteQuery,
  ContractForAutocompleteQueryVariables
>
export const PaginatedTemplatesDocument = gql`
  query paginatedTemplates($input: GetPaginatedTemplatesInput!) {
    paginatedTemplates(input: $input) {
      hasNext
      cursor
      totalCount
      templates {
        id
        type
        createdAt
        userVisibleName
        status
        skippedValidation
        dueDate
        requestingCompany {
          id
          name
        }
        owner {
          id
          firstName
        }
        builder {
          id
          firstName
        }
        validator {
          id
          firstName
        }
      }
    }
  }
`

/**
 * __usePaginatedTemplatesQuery__
 *
 * To run a query within a React component, call `usePaginatedTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedTemplatesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePaginatedTemplatesQuery(
  baseOptions: Apollo.QueryHookOptions<PaginatedTemplatesQuery, PaginatedTemplatesQueryVariables> &
    ({ variables: PaginatedTemplatesQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PaginatedTemplatesQuery, PaginatedTemplatesQueryVariables>(
    PaginatedTemplatesDocument,
    options
  )
}
export function usePaginatedTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaginatedTemplatesQuery,
    PaginatedTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PaginatedTemplatesQuery, PaginatedTemplatesQueryVariables>(
    PaginatedTemplatesDocument,
    options
  )
}
export function usePaginatedTemplatesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<PaginatedTemplatesQuery, PaginatedTemplatesQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<PaginatedTemplatesQuery, PaginatedTemplatesQueryVariables>(
    PaginatedTemplatesDocument,
    options
  )
}
export type PaginatedTemplatesQueryHookResult = ReturnType<typeof usePaginatedTemplatesQuery>
export type PaginatedTemplatesLazyQueryHookResult = ReturnType<
  typeof usePaginatedTemplatesLazyQuery
>
export type PaginatedTemplatesSuspenseQueryHookResult = ReturnType<
  typeof usePaginatedTemplatesSuspenseQuery
>
export type PaginatedTemplatesQueryResult = Apollo.QueryResult<
  PaginatedTemplatesQuery,
  PaginatedTemplatesQueryVariables
>
export const TemplateBuildStatsDocument = gql`
  query templateBuildStats {
    templateBuildStats {
      builders {
        user {
          id
          firstName
        }
        waitingOnCount
      }
    }
  }
`

/**
 * __useTemplateBuildStatsQuery__
 *
 * To run a query within a React component, call `useTemplateBuildStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateBuildStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateBuildStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTemplateBuildStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<TemplateBuildStatsQuery, TemplateBuildStatsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TemplateBuildStatsQuery, TemplateBuildStatsQueryVariables>(
    TemplateBuildStatsDocument,
    options
  )
}
export function useTemplateBuildStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TemplateBuildStatsQuery,
    TemplateBuildStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TemplateBuildStatsQuery, TemplateBuildStatsQueryVariables>(
    TemplateBuildStatsDocument,
    options
  )
}
export function useTemplateBuildStatsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<TemplateBuildStatsQuery, TemplateBuildStatsQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<TemplateBuildStatsQuery, TemplateBuildStatsQueryVariables>(
    TemplateBuildStatsDocument,
    options
  )
}
export type TemplateBuildStatsQueryHookResult = ReturnType<typeof useTemplateBuildStatsQuery>
export type TemplateBuildStatsLazyQueryHookResult = ReturnType<
  typeof useTemplateBuildStatsLazyQuery
>
export type TemplateBuildStatsSuspenseQueryHookResult = ReturnType<
  typeof useTemplateBuildStatsSuspenseQuery
>
export type TemplateBuildStatsQueryResult = Apollo.QueryResult<
  TemplateBuildStatsQuery,
  TemplateBuildStatsQueryVariables
>
export const SimilarTemplatesDocument = gql`
  query similarTemplates($upload: Upload!) {
    similarTemplates(upload: $upload) {
      ...SimilarTemplateProperties
    }
  }
  ${SimilarTemplatePropertiesDoc}
`

/**
 * __useSimilarTemplatesQuery__
 *
 * To run a query within a React component, call `useSimilarTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimilarTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimilarTemplatesQuery({
 *   variables: {
 *      upload: // value for 'upload'
 *   },
 * });
 */
export function useSimilarTemplatesQuery(
  baseOptions: Apollo.QueryHookOptions<SimilarTemplatesQuery, SimilarTemplatesQueryVariables> &
    ({ variables: SimilarTemplatesQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SimilarTemplatesQuery, SimilarTemplatesQueryVariables>(
    SimilarTemplatesDocument,
    options
  )
}
export function useSimilarTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SimilarTemplatesQuery, SimilarTemplatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SimilarTemplatesQuery, SimilarTemplatesQueryVariables>(
    SimilarTemplatesDocument,
    options
  )
}
export function useSimilarTemplatesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<SimilarTemplatesQuery, SimilarTemplatesQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<SimilarTemplatesQuery, SimilarTemplatesQueryVariables>(
    SimilarTemplatesDocument,
    options
  )
}
export type SimilarTemplatesQueryHookResult = ReturnType<typeof useSimilarTemplatesQuery>
export type SimilarTemplatesLazyQueryHookResult = ReturnType<typeof useSimilarTemplatesLazyQuery>
export type SimilarTemplatesSuspenseQueryHookResult = ReturnType<
  typeof useSimilarTemplatesSuspenseQuery
>
export type SimilarTemplatesQueryResult = Apollo.QueryResult<
  SimilarTemplatesQuery,
  SimilarTemplatesQueryVariables
>
export const FormTurnaroundDataDocument = gql`
  query formTurnaroundData($input: FormTurnaroundDataInput!) {
    formTurnaroundData(input: $input) {
      forms {
        startDate
        endDate
        formTemplate {
          id
          userVisibleName
          builderId
          requestingCompanyId
          validatorId
          ownerId
        }
      }
      companies {
        id
        name
      }
      users {
        id
        firstName
        lastName
      }
    }
  }
`

/**
 * __useFormTurnaroundDataQuery__
 *
 * To run a query within a React component, call `useFormTurnaroundDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormTurnaroundDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormTurnaroundDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFormTurnaroundDataQuery(
  baseOptions: Apollo.QueryHookOptions<FormTurnaroundDataQuery, FormTurnaroundDataQueryVariables> &
    ({ variables: FormTurnaroundDataQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FormTurnaroundDataQuery, FormTurnaroundDataQueryVariables>(
    FormTurnaroundDataDocument,
    options
  )
}
export function useFormTurnaroundDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FormTurnaroundDataQuery,
    FormTurnaroundDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FormTurnaroundDataQuery, FormTurnaroundDataQueryVariables>(
    FormTurnaroundDataDocument,
    options
  )
}
export function useFormTurnaroundDataSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FormTurnaroundDataQuery, FormTurnaroundDataQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<FormTurnaroundDataQuery, FormTurnaroundDataQueryVariables>(
    FormTurnaroundDataDocument,
    options
  )
}
export type FormTurnaroundDataQueryHookResult = ReturnType<typeof useFormTurnaroundDataQuery>
export type FormTurnaroundDataLazyQueryHookResult = ReturnType<
  typeof useFormTurnaroundDataLazyQuery
>
export type FormTurnaroundDataSuspenseQueryHookResult = ReturnType<
  typeof useFormTurnaroundDataSuspenseQuery
>
export type FormTurnaroundDataQueryResult = Apollo.QueryResult<
  FormTurnaroundDataQuery,
  FormTurnaroundDataQueryVariables
>
export const CreateFormTemplateVersionDocument = gql`
  mutation createFormTemplateVersion($input: CreateFormTemplateVersionInput!) {
    createFormTemplateVersion(input: $input) {
      ...FormTemplateVersionProperties
    }
  }
  ${FormTemplateVersionPropertiesDoc}
`
export type CreateFormTemplateVersionMutationFn = Apollo.MutationFunction<
  CreateFormTemplateVersionMutation,
  CreateFormTemplateVersionMutationVariables
>

/**
 * __useCreateFormTemplateVersionMutation__
 *
 * To run a mutation, you first call `useCreateFormTemplateVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFormTemplateVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFormTemplateVersionMutation, { data, loading, error }] = useCreateFormTemplateVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFormTemplateVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFormTemplateVersionMutation,
    CreateFormTemplateVersionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateFormTemplateVersionMutation,
    CreateFormTemplateVersionMutationVariables
  >(CreateFormTemplateVersionDocument, options)
}
export type CreateFormTemplateVersionMutationHookResult = ReturnType<
  typeof useCreateFormTemplateVersionMutation
>
export type CreateFormTemplateVersionMutationResult =
  Apollo.MutationResult<CreateFormTemplateVersionMutation>
export type CreateFormTemplateVersionMutationOptions = Apollo.BaseMutationOptions<
  CreateFormTemplateVersionMutation,
  CreateFormTemplateVersionMutationVariables
>
export const ReplaceFormTemplateAnnotationsDocument = gql`
  mutation replaceFormTemplateAnnotations($input: ReplaceFormTemplateAnnotationsInput!) {
    replaceFormTemplateAnnotations(input: $input) {
      ...FormTemplateAnnotationProperties
    }
  }
  ${FormTemplateAnnotationPropertiesDoc}
`
export type ReplaceFormTemplateAnnotationsMutationFn = Apollo.MutationFunction<
  ReplaceFormTemplateAnnotationsMutation,
  ReplaceFormTemplateAnnotationsMutationVariables
>

/**
 * __useReplaceFormTemplateAnnotationsMutation__
 *
 * To run a mutation, you first call `useReplaceFormTemplateAnnotationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplaceFormTemplateAnnotationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replaceFormTemplateAnnotationsMutation, { data, loading, error }] = useReplaceFormTemplateAnnotationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReplaceFormTemplateAnnotationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReplaceFormTemplateAnnotationsMutation,
    ReplaceFormTemplateAnnotationsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ReplaceFormTemplateAnnotationsMutation,
    ReplaceFormTemplateAnnotationsMutationVariables
  >(ReplaceFormTemplateAnnotationsDocument, options)
}
export type ReplaceFormTemplateAnnotationsMutationHookResult = ReturnType<
  typeof useReplaceFormTemplateAnnotationsMutation
>
export type ReplaceFormTemplateAnnotationsMutationResult =
  Apollo.MutationResult<ReplaceFormTemplateAnnotationsMutation>
export type ReplaceFormTemplateAnnotationsMutationOptions = Apollo.BaseMutationOptions<
  ReplaceFormTemplateAnnotationsMutation,
  ReplaceFormTemplateAnnotationsMutationVariables
>
export const TemplateForPreviewDocument = gql`
  query templateForPreview($id: ID!) {
    formTemplate(id: $id) {
      id
      updatedAt
      status
      versions {
        id
        versionNumber
        annotations {
          ...FormTemplateAnnotationProperties
        }
        file {
          id
          url
          name
        }
      }
      variants {
        id
        internalName
        isDefaultVariant
        hidesZeroDollarAmounts
        roundPercentages
        annotationOverrides {
          ...AnnotationOverrideProperties
        }
      }
    }
  }
  ${FormTemplateAnnotationPropertiesDoc}
  ${AnnotationOverridePropertiesDoc}
`

/**
 * __useTemplateForPreviewQuery__
 *
 * To run a query within a React component, call `useTemplateForPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateForPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateForPreviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTemplateForPreviewQuery(
  baseOptions: Apollo.QueryHookOptions<TemplateForPreviewQuery, TemplateForPreviewQueryVariables> &
    ({ variables: TemplateForPreviewQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TemplateForPreviewQuery, TemplateForPreviewQueryVariables>(
    TemplateForPreviewDocument,
    options
  )
}
export function useTemplateForPreviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TemplateForPreviewQuery,
    TemplateForPreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TemplateForPreviewQuery, TemplateForPreviewQueryVariables>(
    TemplateForPreviewDocument,
    options
  )
}
export function useTemplateForPreviewSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<TemplateForPreviewQuery, TemplateForPreviewQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<TemplateForPreviewQuery, TemplateForPreviewQueryVariables>(
    TemplateForPreviewDocument,
    options
  )
}
export type TemplateForPreviewQueryHookResult = ReturnType<typeof useTemplateForPreviewQuery>
export type TemplateForPreviewLazyQueryHookResult = ReturnType<
  typeof useTemplateForPreviewLazyQuery
>
export type TemplateForPreviewSuspenseQueryHookResult = ReturnType<
  typeof useTemplateForPreviewSuspenseQuery
>
export type TemplateForPreviewQueryResult = Apollo.QueryResult<
  TemplateForPreviewQuery,
  TemplateForPreviewQueryVariables
>
export const CreateTemplateVariantDocument = gql`
  mutation createTemplateVariant($input: CreateTemplateVariantInput!) {
    createTemplateVariant(input: $input) {
      ...FormTemplateVariantProperties
    }
  }
  ${FormTemplateVariantPropertiesDoc}
`
export type CreateTemplateVariantMutationFn = Apollo.MutationFunction<
  CreateTemplateVariantMutation,
  CreateTemplateVariantMutationVariables
>

/**
 * __useCreateTemplateVariantMutation__
 *
 * To run a mutation, you first call `useCreateTemplateVariantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTemplateVariantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTemplateVariantMutation, { data, loading, error }] = useCreateTemplateVariantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTemplateVariantMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTemplateVariantMutation,
    CreateTemplateVariantMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateTemplateVariantMutation, CreateTemplateVariantMutationVariables>(
    CreateTemplateVariantDocument,
    options
  )
}
export type CreateTemplateVariantMutationHookResult = ReturnType<
  typeof useCreateTemplateVariantMutation
>
export type CreateTemplateVariantMutationResult =
  Apollo.MutationResult<CreateTemplateVariantMutation>
export type CreateTemplateVariantMutationOptions = Apollo.BaseMutationOptions<
  CreateTemplateVariantMutation,
  CreateTemplateVariantMutationVariables
>
export const UpdateTemplateVariantDocument = gql`
  mutation updateTemplateVariant($input: UpdateTemplateVariantInput!) {
    updateTemplateVariant(input: $input) {
      ...FormTemplateVariantProperties
    }
  }
  ${FormTemplateVariantPropertiesDoc}
`
export type UpdateTemplateVariantMutationFn = Apollo.MutationFunction<
  UpdateTemplateVariantMutation,
  UpdateTemplateVariantMutationVariables
>

/**
 * __useUpdateTemplateVariantMutation__
 *
 * To run a mutation, you first call `useUpdateTemplateVariantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTemplateVariantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTemplateVariantMutation, { data, loading, error }] = useUpdateTemplateVariantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTemplateVariantMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTemplateVariantMutation,
    UpdateTemplateVariantMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateTemplateVariantMutation, UpdateTemplateVariantMutationVariables>(
    UpdateTemplateVariantDocument,
    options
  )
}
export type UpdateTemplateVariantMutationHookResult = ReturnType<
  typeof useUpdateTemplateVariantMutation
>
export type UpdateTemplateVariantMutationResult =
  Apollo.MutationResult<UpdateTemplateVariantMutation>
export type UpdateTemplateVariantMutationOptions = Apollo.BaseMutationOptions<
  UpdateTemplateVariantMutation,
  UpdateTemplateVariantMutationVariables
>
export const DeleteTemplateVariantDocument = gql`
  mutation deleteTemplateVariant($id: ID!) {
    deleteTemplateVariant(id: $id) {
      id
    }
  }
`
export type DeleteTemplateVariantMutationFn = Apollo.MutationFunction<
  DeleteTemplateVariantMutation,
  DeleteTemplateVariantMutationVariables
>

/**
 * __useDeleteTemplateVariantMutation__
 *
 * To run a mutation, you first call `useDeleteTemplateVariantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTemplateVariantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTemplateVariantMutation, { data, loading, error }] = useDeleteTemplateVariantMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTemplateVariantMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTemplateVariantMutation,
    DeleteTemplateVariantMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteTemplateVariantMutation, DeleteTemplateVariantMutationVariables>(
    DeleteTemplateVariantDocument,
    options
  )
}
export type DeleteTemplateVariantMutationHookResult = ReturnType<
  typeof useDeleteTemplateVariantMutation
>
export type DeleteTemplateVariantMutationResult =
  Apollo.MutationResult<DeleteTemplateVariantMutation>
export type DeleteTemplateVariantMutationOptions = Apollo.BaseMutationOptions<
  DeleteTemplateVariantMutation,
  DeleteTemplateVariantMutationVariables
>
export const CreateFormTemplateAnnotationDocument = gql`
  mutation createFormTemplateAnnotation($input: CreateFormAnnotationInput!) {
    createFormTemplateAnnotation(input: $input) {
      ...FormTemplateAnnotationProperties
    }
  }
  ${FormTemplateAnnotationPropertiesDoc}
`
export type CreateFormTemplateAnnotationMutationFn = Apollo.MutationFunction<
  CreateFormTemplateAnnotationMutation,
  CreateFormTemplateAnnotationMutationVariables
>

/**
 * __useCreateFormTemplateAnnotationMutation__
 *
 * To run a mutation, you first call `useCreateFormTemplateAnnotationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFormTemplateAnnotationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFormTemplateAnnotationMutation, { data, loading, error }] = useCreateFormTemplateAnnotationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFormTemplateAnnotationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFormTemplateAnnotationMutation,
    CreateFormTemplateAnnotationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateFormTemplateAnnotationMutation,
    CreateFormTemplateAnnotationMutationVariables
  >(CreateFormTemplateAnnotationDocument, options)
}
export type CreateFormTemplateAnnotationMutationHookResult = ReturnType<
  typeof useCreateFormTemplateAnnotationMutation
>
export type CreateFormTemplateAnnotationMutationResult =
  Apollo.MutationResult<CreateFormTemplateAnnotationMutation>
export type CreateFormTemplateAnnotationMutationOptions = Apollo.BaseMutationOptions<
  CreateFormTemplateAnnotationMutation,
  CreateFormTemplateAnnotationMutationVariables
>
export const CreateFormTemplateAnnotationsDocument = gql`
  mutation createFormTemplateAnnotations($input: CreateFormAnnotationsInput!) {
    createFormTemplateAnnotations(input: $input) {
      ...FormTemplateAnnotationProperties
    }
  }
  ${FormTemplateAnnotationPropertiesDoc}
`
export type CreateFormTemplateAnnotationsMutationFn = Apollo.MutationFunction<
  CreateFormTemplateAnnotationsMutation,
  CreateFormTemplateAnnotationsMutationVariables
>

/**
 * __useCreateFormTemplateAnnotationsMutation__
 *
 * To run a mutation, you first call `useCreateFormTemplateAnnotationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFormTemplateAnnotationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFormTemplateAnnotationsMutation, { data, loading, error }] = useCreateFormTemplateAnnotationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFormTemplateAnnotationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFormTemplateAnnotationsMutation,
    CreateFormTemplateAnnotationsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateFormTemplateAnnotationsMutation,
    CreateFormTemplateAnnotationsMutationVariables
  >(CreateFormTemplateAnnotationsDocument, options)
}
export type CreateFormTemplateAnnotationsMutationHookResult = ReturnType<
  typeof useCreateFormTemplateAnnotationsMutation
>
export type CreateFormTemplateAnnotationsMutationResult =
  Apollo.MutationResult<CreateFormTemplateAnnotationsMutation>
export type CreateFormTemplateAnnotationsMutationOptions = Apollo.BaseMutationOptions<
  CreateFormTemplateAnnotationsMutation,
  CreateFormTemplateAnnotationsMutationVariables
>
export const DeleteFormTemplateAnnotationsDocument = gql`
  mutation deleteFormTemplateAnnotations($ids: [ID!]!) {
    deleteFormTemplateAnnotations(ids: $ids) {
      id
    }
  }
`
export type DeleteFormTemplateAnnotationsMutationFn = Apollo.MutationFunction<
  DeleteFormTemplateAnnotationsMutation,
  DeleteFormTemplateAnnotationsMutationVariables
>

/**
 * __useDeleteFormTemplateAnnotationsMutation__
 *
 * To run a mutation, you first call `useDeleteFormTemplateAnnotationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFormTemplateAnnotationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFormTemplateAnnotationsMutation, { data, loading, error }] = useDeleteFormTemplateAnnotationsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteFormTemplateAnnotationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteFormTemplateAnnotationsMutation,
    DeleteFormTemplateAnnotationsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteFormTemplateAnnotationsMutation,
    DeleteFormTemplateAnnotationsMutationVariables
  >(DeleteFormTemplateAnnotationsDocument, options)
}
export type DeleteFormTemplateAnnotationsMutationHookResult = ReturnType<
  typeof useDeleteFormTemplateAnnotationsMutation
>
export type DeleteFormTemplateAnnotationsMutationResult =
  Apollo.MutationResult<DeleteFormTemplateAnnotationsMutation>
export type DeleteFormTemplateAnnotationsMutationOptions = Apollo.BaseMutationOptions<
  DeleteFormTemplateAnnotationsMutation,
  DeleteFormTemplateAnnotationsMutationVariables
>
export const UpdateFormTemplateAnnotationsDocument = gql`
  mutation updateFormTemplateAnnotations($input: UpdateFormAnnotationsInput!) {
    updateFormTemplateAnnotations(input: $input) {
      ...FormTemplateAnnotationProperties
    }
  }
  ${FormTemplateAnnotationPropertiesDoc}
`
export type UpdateFormTemplateAnnotationsMutationFn = Apollo.MutationFunction<
  UpdateFormTemplateAnnotationsMutation,
  UpdateFormTemplateAnnotationsMutationVariables
>

/**
 * __useUpdateFormTemplateAnnotationsMutation__
 *
 * To run a mutation, you first call `useUpdateFormTemplateAnnotationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFormTemplateAnnotationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFormTemplateAnnotationsMutation, { data, loading, error }] = useUpdateFormTemplateAnnotationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFormTemplateAnnotationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFormTemplateAnnotationsMutation,
    UpdateFormTemplateAnnotationsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateFormTemplateAnnotationsMutation,
    UpdateFormTemplateAnnotationsMutationVariables
  >(UpdateFormTemplateAnnotationsDocument, options)
}
export type UpdateFormTemplateAnnotationsMutationHookResult = ReturnType<
  typeof useUpdateFormTemplateAnnotationsMutation
>
export type UpdateFormTemplateAnnotationsMutationResult =
  Apollo.MutationResult<UpdateFormTemplateAnnotationsMutation>
export type UpdateFormTemplateAnnotationsMutationOptions = Apollo.BaseMutationOptions<
  UpdateFormTemplateAnnotationsMutation,
  UpdateFormTemplateAnnotationsMutationVariables
>
export const ApplyAnnotationOverrideDocument = gql`
  mutation applyAnnotationOverride($input: ApplyAnnotationOverrideInput!) {
    applyAnnotationOverride(input: $input) {
      ...AnnotationOverrideProperties
    }
  }
  ${AnnotationOverridePropertiesDoc}
`
export type ApplyAnnotationOverrideMutationFn = Apollo.MutationFunction<
  ApplyAnnotationOverrideMutation,
  ApplyAnnotationOverrideMutationVariables
>

/**
 * __useApplyAnnotationOverrideMutation__
 *
 * To run a mutation, you first call `useApplyAnnotationOverrideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyAnnotationOverrideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyAnnotationOverrideMutation, { data, loading, error }] = useApplyAnnotationOverrideMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApplyAnnotationOverrideMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApplyAnnotationOverrideMutation,
    ApplyAnnotationOverrideMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ApplyAnnotationOverrideMutation,
    ApplyAnnotationOverrideMutationVariables
  >(ApplyAnnotationOverrideDocument, options)
}
export type ApplyAnnotationOverrideMutationHookResult = ReturnType<
  typeof useApplyAnnotationOverrideMutation
>
export type ApplyAnnotationOverrideMutationResult =
  Apollo.MutationResult<ApplyAnnotationOverrideMutation>
export type ApplyAnnotationOverrideMutationOptions = Apollo.BaseMutationOptions<
  ApplyAnnotationOverrideMutation,
  ApplyAnnotationOverrideMutationVariables
>
export const TemplateVersionForEditorDocument = gql`
  query templateVersionForEditor($id: ID!) {
    formTemplateVersion(id: $id) {
      ...FormTemplateVersionProperties
    }
  }
  ${FormTemplateVersionPropertiesDoc}
`

/**
 * __useTemplateVersionForEditorQuery__
 *
 * To run a query within a React component, call `useTemplateVersionForEditorQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateVersionForEditorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateVersionForEditorQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTemplateVersionForEditorQuery(
  baseOptions: Apollo.QueryHookOptions<
    TemplateVersionForEditorQuery,
    TemplateVersionForEditorQueryVariables
  > &
    ({ variables: TemplateVersionForEditorQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TemplateVersionForEditorQuery, TemplateVersionForEditorQueryVariables>(
    TemplateVersionForEditorDocument,
    options
  )
}
export function useTemplateVersionForEditorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TemplateVersionForEditorQuery,
    TemplateVersionForEditorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TemplateVersionForEditorQuery, TemplateVersionForEditorQueryVariables>(
    TemplateVersionForEditorDocument,
    options
  )
}
export function useTemplateVersionForEditorSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        TemplateVersionForEditorQuery,
        TemplateVersionForEditorQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    TemplateVersionForEditorQuery,
    TemplateVersionForEditorQueryVariables
  >(TemplateVersionForEditorDocument, options)
}
export type TemplateVersionForEditorQueryHookResult = ReturnType<
  typeof useTemplateVersionForEditorQuery
>
export type TemplateVersionForEditorLazyQueryHookResult = ReturnType<
  typeof useTemplateVersionForEditorLazyQuery
>
export type TemplateVersionForEditorSuspenseQueryHookResult = ReturnType<
  typeof useTemplateVersionForEditorSuspenseQuery
>
export type TemplateVersionForEditorQueryResult = Apollo.QueryResult<
  TemplateVersionForEditorQuery,
  TemplateVersionForEditorQueryVariables
>
export const TemplateVariantForEditorDocument = gql`
  query templateVariantForEditor($id: ID!) {
    formTemplateVariant(id: $id) {
      ...FormTemplateVariantProperties
    }
  }
  ${FormTemplateVariantPropertiesDoc}
`

/**
 * __useTemplateVariantForEditorQuery__
 *
 * To run a query within a React component, call `useTemplateVariantForEditorQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateVariantForEditorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateVariantForEditorQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTemplateVariantForEditorQuery(
  baseOptions: Apollo.QueryHookOptions<
    TemplateVariantForEditorQuery,
    TemplateVariantForEditorQueryVariables
  > &
    ({ variables: TemplateVariantForEditorQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TemplateVariantForEditorQuery, TemplateVariantForEditorQueryVariables>(
    TemplateVariantForEditorDocument,
    options
  )
}
export function useTemplateVariantForEditorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TemplateVariantForEditorQuery,
    TemplateVariantForEditorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TemplateVariantForEditorQuery, TemplateVariantForEditorQueryVariables>(
    TemplateVariantForEditorDocument,
    options
  )
}
export function useTemplateVariantForEditorSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        TemplateVariantForEditorQuery,
        TemplateVariantForEditorQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    TemplateVariantForEditorQuery,
    TemplateVariantForEditorQueryVariables
  >(TemplateVariantForEditorDocument, options)
}
export type TemplateVariantForEditorQueryHookResult = ReturnType<
  typeof useTemplateVariantForEditorQuery
>
export type TemplateVariantForEditorLazyQueryHookResult = ReturnType<
  typeof useTemplateVariantForEditorLazyQuery
>
export type TemplateVariantForEditorSuspenseQueryHookResult = ReturnType<
  typeof useTemplateVariantForEditorSuspenseQuery
>
export type TemplateVariantForEditorQueryResult = Apollo.QueryResult<
  TemplateVariantForEditorQuery,
  TemplateVariantForEditorQueryVariables
>
export const UpdateFormTemplateVersionDocument = gql`
  mutation updateFormTemplateVersion($input: UpdateFormTemplateVersionInput!) {
    updateFormTemplateVersion(input: $input) {
      ...FormTemplateVersionProperties
    }
  }
  ${FormTemplateVersionPropertiesDoc}
`
export type UpdateFormTemplateVersionMutationFn = Apollo.MutationFunction<
  UpdateFormTemplateVersionMutation,
  UpdateFormTemplateVersionMutationVariables
>

/**
 * __useUpdateFormTemplateVersionMutation__
 *
 * To run a mutation, you first call `useUpdateFormTemplateVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFormTemplateVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFormTemplateVersionMutation, { data, loading, error }] = useUpdateFormTemplateVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFormTemplateVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFormTemplateVersionMutation,
    UpdateFormTemplateVersionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateFormTemplateVersionMutation,
    UpdateFormTemplateVersionMutationVariables
  >(UpdateFormTemplateVersionDocument, options)
}
export type UpdateFormTemplateVersionMutationHookResult = ReturnType<
  typeof useUpdateFormTemplateVersionMutation
>
export type UpdateFormTemplateVersionMutationResult =
  Apollo.MutationResult<UpdateFormTemplateVersionMutation>
export type UpdateFormTemplateVersionMutationOptions = Apollo.BaseMutationOptions<
  UpdateFormTemplateVersionMutation,
  UpdateFormTemplateVersionMutationVariables
>
export const DeleteFormTemplateVersionDocument = gql`
  mutation deleteFormTemplateVersion($id: ID!) {
    deleteFormTemplateVersion(id: $id) {
      id
    }
  }
`
export type DeleteFormTemplateVersionMutationFn = Apollo.MutationFunction<
  DeleteFormTemplateVersionMutation,
  DeleteFormTemplateVersionMutationVariables
>

/**
 * __useDeleteFormTemplateVersionMutation__
 *
 * To run a mutation, you first call `useDeleteFormTemplateVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFormTemplateVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFormTemplateVersionMutation, { data, loading, error }] = useDeleteFormTemplateVersionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFormTemplateVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteFormTemplateVersionMutation,
    DeleteFormTemplateVersionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteFormTemplateVersionMutation,
    DeleteFormTemplateVersionMutationVariables
  >(DeleteFormTemplateVersionDocument, options)
}
export type DeleteFormTemplateVersionMutationHookResult = ReturnType<
  typeof useDeleteFormTemplateVersionMutation
>
export type DeleteFormTemplateVersionMutationResult =
  Apollo.MutationResult<DeleteFormTemplateVersionMutation>
export type DeleteFormTemplateVersionMutationOptions = Apollo.BaseMutationOptions<
  DeleteFormTemplateVersionMutation,
  DeleteFormTemplateVersionMutationVariables
>
export const GeneralContractorsForDedupeDocument = gql`
  query generalContractorsForDedupe($companyId: ID!) {
    generalContractorsForDedupe(companyId: $companyId) {
      company {
        id
        name
        locations {
          ...LocationProperties
        }
      }
      contractCount
    }
  }
  ${LocationPropertiesDoc}
`

/**
 * __useGeneralContractorsForDedupeQuery__
 *
 * To run a query within a React component, call `useGeneralContractorsForDedupeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeneralContractorsForDedupeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeneralContractorsForDedupeQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGeneralContractorsForDedupeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GeneralContractorsForDedupeQuery,
    GeneralContractorsForDedupeQueryVariables
  > &
    ({ variables: GeneralContractorsForDedupeQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GeneralContractorsForDedupeQuery,
    GeneralContractorsForDedupeQueryVariables
  >(GeneralContractorsForDedupeDocument, options)
}
export function useGeneralContractorsForDedupeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneralContractorsForDedupeQuery,
    GeneralContractorsForDedupeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GeneralContractorsForDedupeQuery,
    GeneralContractorsForDedupeQueryVariables
  >(GeneralContractorsForDedupeDocument, options)
}
export function useGeneralContractorsForDedupeSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneralContractorsForDedupeQuery,
        GeneralContractorsForDedupeQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GeneralContractorsForDedupeQuery,
    GeneralContractorsForDedupeQueryVariables
  >(GeneralContractorsForDedupeDocument, options)
}
export type GeneralContractorsForDedupeQueryHookResult = ReturnType<
  typeof useGeneralContractorsForDedupeQuery
>
export type GeneralContractorsForDedupeLazyQueryHookResult = ReturnType<
  typeof useGeneralContractorsForDedupeLazyQuery
>
export type GeneralContractorsForDedupeSuspenseQueryHookResult = ReturnType<
  typeof useGeneralContractorsForDedupeSuspenseQuery
>
export type GeneralContractorsForDedupeQueryResult = Apollo.QueryResult<
  GeneralContractorsForDedupeQuery,
  GeneralContractorsForDedupeQueryVariables
>
export const MergeGeneralContractorCompaniesDocument = gql`
  mutation mergeGeneralContractorCompanies($input: MergeGeneralContractorCompaniesInput!) {
    mergeGeneralContractorCompanies(input: $input)
  }
`
export type MergeGeneralContractorCompaniesMutationFn = Apollo.MutationFunction<
  MergeGeneralContractorCompaniesMutation,
  MergeGeneralContractorCompaniesMutationVariables
>

/**
 * __useMergeGeneralContractorCompaniesMutation__
 *
 * To run a mutation, you first call `useMergeGeneralContractorCompaniesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMergeGeneralContractorCompaniesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergeGeneralContractorCompaniesMutation, { data, loading, error }] = useMergeGeneralContractorCompaniesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMergeGeneralContractorCompaniesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MergeGeneralContractorCompaniesMutation,
    MergeGeneralContractorCompaniesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    MergeGeneralContractorCompaniesMutation,
    MergeGeneralContractorCompaniesMutationVariables
  >(MergeGeneralContractorCompaniesDocument, options)
}
export type MergeGeneralContractorCompaniesMutationHookResult = ReturnType<
  typeof useMergeGeneralContractorCompaniesMutation
>
export type MergeGeneralContractorCompaniesMutationResult =
  Apollo.MutationResult<MergeGeneralContractorCompaniesMutation>
export type MergeGeneralContractorCompaniesMutationOptions = Apollo.BaseMutationOptions<
  MergeGeneralContractorCompaniesMutation,
  MergeGeneralContractorCompaniesMutationVariables
>
export const CloudLoggingLogsDocument = gql`
  query cloudLoggingLogs($input: CloudLoggingLogsInput!) {
    cloudLoggingLogs(input: $input) {
      entries
      nextPageToken
    }
  }
`

/**
 * __useCloudLoggingLogsQuery__
 *
 * To run a query within a React component, call `useCloudLoggingLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCloudLoggingLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCloudLoggingLogsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCloudLoggingLogsQuery(
  baseOptions: Apollo.QueryHookOptions<CloudLoggingLogsQuery, CloudLoggingLogsQueryVariables> &
    ({ variables: CloudLoggingLogsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CloudLoggingLogsQuery, CloudLoggingLogsQueryVariables>(
    CloudLoggingLogsDocument,
    options
  )
}
export function useCloudLoggingLogsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CloudLoggingLogsQuery, CloudLoggingLogsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CloudLoggingLogsQuery, CloudLoggingLogsQueryVariables>(
    CloudLoggingLogsDocument,
    options
  )
}
export function useCloudLoggingLogsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<CloudLoggingLogsQuery, CloudLoggingLogsQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<CloudLoggingLogsQuery, CloudLoggingLogsQueryVariables>(
    CloudLoggingLogsDocument,
    options
  )
}
export type CloudLoggingLogsQueryHookResult = ReturnType<typeof useCloudLoggingLogsQuery>
export type CloudLoggingLogsLazyQueryHookResult = ReturnType<typeof useCloudLoggingLogsLazyQuery>
export type CloudLoggingLogsSuspenseQueryHookResult = ReturnType<
  typeof useCloudLoggingLogsSuspenseQuery
>
export type CloudLoggingLogsQueryResult = Apollo.QueryResult<
  CloudLoggingLogsQuery,
  CloudLoggingLogsQueryVariables
>
export const SetLegalDocumentSyncedDocument = gql`
  mutation setLegalDocumentSynced($id: ID!) {
    setLegalDocumentSynced(id: $id) {
      ...LegalRequirementProperties
      documents {
        ...LegalDocumentProperties
      }
    }
  }
  ${LegalRequirementPropertiesDoc}
  ${LegalDocumentPropertiesDoc}
`
export type SetLegalDocumentSyncedMutationFn = Apollo.MutationFunction<
  SetLegalDocumentSyncedMutation,
  SetLegalDocumentSyncedMutationVariables
>

/**
 * __useSetLegalDocumentSyncedMutation__
 *
 * To run a mutation, you first call `useSetLegalDocumentSyncedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetLegalDocumentSyncedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setLegalDocumentSyncedMutation, { data, loading, error }] = useSetLegalDocumentSyncedMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSetLegalDocumentSyncedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetLegalDocumentSyncedMutation,
    SetLegalDocumentSyncedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SetLegalDocumentSyncedMutation,
    SetLegalDocumentSyncedMutationVariables
  >(SetLegalDocumentSyncedDocument, options)
}
export type SetLegalDocumentSyncedMutationHookResult = ReturnType<
  typeof useSetLegalDocumentSyncedMutation
>
export type SetLegalDocumentSyncedMutationResult =
  Apollo.MutationResult<SetLegalDocumentSyncedMutation>
export type SetLegalDocumentSyncedMutationOptions = Apollo.BaseMutationOptions<
  SetLegalDocumentSyncedMutation,
  SetLegalDocumentSyncedMutationVariables
>
export const GetLegalRequirementDocument = gql`
  query getLegalRequirement($id: ID!) {
    legalRequirement(id: $id) {
      ...LegalRequirementProperties
      documents {
        ...LegalDocumentProperties
      }
      contract {
        id
        integrations {
          ...IntegrationProperties
        }
      }
    }
  }
  ${LegalRequirementPropertiesDoc}
  ${LegalDocumentPropertiesDoc}
  ${IntegrationPropertiesDoc}
`

/**
 * __useGetLegalRequirementQuery__
 *
 * To run a query within a React component, call `useGetLegalRequirementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLegalRequirementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLegalRequirementQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLegalRequirementQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLegalRequirementQuery,
    GetLegalRequirementQueryVariables
  > &
    ({ variables: GetLegalRequirementQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetLegalRequirementQuery, GetLegalRequirementQueryVariables>(
    GetLegalRequirementDocument,
    options
  )
}
export function useGetLegalRequirementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLegalRequirementQuery,
    GetLegalRequirementQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetLegalRequirementQuery, GetLegalRequirementQueryVariables>(
    GetLegalRequirementDocument,
    options
  )
}
export function useGetLegalRequirementSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetLegalRequirementQuery, GetLegalRequirementQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetLegalRequirementQuery, GetLegalRequirementQueryVariables>(
    GetLegalRequirementDocument,
    options
  )
}
export type GetLegalRequirementQueryHookResult = ReturnType<typeof useGetLegalRequirementQuery>
export type GetLegalRequirementLazyQueryHookResult = ReturnType<
  typeof useGetLegalRequirementLazyQuery
>
export type GetLegalRequirementSuspenseQueryHookResult = ReturnType<
  typeof useGetLegalRequirementSuspenseQuery
>
export type GetLegalRequirementQueryResult = Apollo.QueryResult<
  GetLegalRequirementQuery,
  GetLegalRequirementQueryVariables
>
export const GetPayAppForDetailsDocument = gql`
  query getPayAppForDetails($payAppId: ID!) {
    payApp(id: $payAppId) {
      ...PayAppProperties
      hasPinnedTemplates
      billingType
      formTemplates {
        order
        formTemplateVersion {
          ...FormTemplateVersionProperties
          formTemplate {
            id
            userVisibleName
          }
        }
      }
      contract {
        ...ContractProperties
      }
    }
  }
  ${PayAppPropertiesDoc}
  ${FormTemplateVersionPropertiesDoc}
  ${ContractPropertiesDoc}
`

/**
 * __useGetPayAppForDetailsQuery__
 *
 * To run a query within a React component, call `useGetPayAppForDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayAppForDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayAppForDetailsQuery({
 *   variables: {
 *      payAppId: // value for 'payAppId'
 *   },
 * });
 */
export function useGetPayAppForDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPayAppForDetailsQuery,
    GetPayAppForDetailsQueryVariables
  > &
    ({ variables: GetPayAppForDetailsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPayAppForDetailsQuery, GetPayAppForDetailsQueryVariables>(
    GetPayAppForDetailsDocument,
    options
  )
}
export function useGetPayAppForDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPayAppForDetailsQuery,
    GetPayAppForDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetPayAppForDetailsQuery, GetPayAppForDetailsQueryVariables>(
    GetPayAppForDetailsDocument,
    options
  )
}
export function useGetPayAppForDetailsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetPayAppForDetailsQuery, GetPayAppForDetailsQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetPayAppForDetailsQuery, GetPayAppForDetailsQueryVariables>(
    GetPayAppForDetailsDocument,
    options
  )
}
export type GetPayAppForDetailsQueryHookResult = ReturnType<typeof useGetPayAppForDetailsQuery>
export type GetPayAppForDetailsLazyQueryHookResult = ReturnType<
  typeof useGetPayAppForDetailsLazyQuery
>
export type GetPayAppForDetailsSuspenseQueryHookResult = ReturnType<
  typeof useGetPayAppForDetailsSuspenseQuery
>
export type GetPayAppForDetailsQueryResult = Apollo.QueryResult<
  GetPayAppForDetailsQuery,
  GetPayAppForDetailsQueryVariables
>
export const ResetPayAppDocument = gql`
  mutation resetPayApp($payAppId: ID!) {
    resetPayApp(payAppId: $payAppId) {
      ...PayAppProperties
    }
  }
  ${PayAppPropertiesDoc}
`
export type ResetPayAppMutationFn = Apollo.MutationFunction<
  ResetPayAppMutation,
  ResetPayAppMutationVariables
>

/**
 * __useResetPayAppMutation__
 *
 * To run a mutation, you first call `useResetPayAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPayAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPayAppMutation, { data, loading, error }] = useResetPayAppMutation({
 *   variables: {
 *      payAppId: // value for 'payAppId'
 *   },
 * });
 */
export function useResetPayAppMutation(
  baseOptions?: Apollo.MutationHookOptions<ResetPayAppMutation, ResetPayAppMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ResetPayAppMutation, ResetPayAppMutationVariables>(
    ResetPayAppDocument,
    options
  )
}
export type ResetPayAppMutationHookResult = ReturnType<typeof useResetPayAppMutation>
export type ResetPayAppMutationResult = Apollo.MutationResult<ResetPayAppMutation>
export type ResetPayAppMutationOptions = Apollo.BaseMutationOptions<
  ResetPayAppMutation,
  ResetPayAppMutationVariables
>
export const ClearPayAppEventsDocument = gql`
  mutation clearPayAppEvents($payAppId: ID!) {
    clearPayAppEvents(payAppId: $payAppId) {
      ...PayAppProperties
    }
  }
  ${PayAppPropertiesDoc}
`
export type ClearPayAppEventsMutationFn = Apollo.MutationFunction<
  ClearPayAppEventsMutation,
  ClearPayAppEventsMutationVariables
>

/**
 * __useClearPayAppEventsMutation__
 *
 * To run a mutation, you first call `useClearPayAppEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearPayAppEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearPayAppEventsMutation, { data, loading, error }] = useClearPayAppEventsMutation({
 *   variables: {
 *      payAppId: // value for 'payAppId'
 *   },
 * });
 */
export function useClearPayAppEventsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClearPayAppEventsMutation,
    ClearPayAppEventsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ClearPayAppEventsMutation, ClearPayAppEventsMutationVariables>(
    ClearPayAppEventsDocument,
    options
  )
}
export type ClearPayAppEventsMutationHookResult = ReturnType<typeof useClearPayAppEventsMutation>
export type ClearPayAppEventsMutationResult = Apollo.MutationResult<ClearPayAppEventsMutation>
export type ClearPayAppEventsMutationOptions = Apollo.BaseMutationOptions<
  ClearPayAppEventsMutation,
  ClearPayAppEventsMutationVariables
>
export const RetractPayAppDocument = gql`
  mutation retractPayApp($payAppId: ID!) {
    retractPayApp(payAppId: $payAppId) {
      ...PayAppProperties
    }
  }
  ${PayAppPropertiesDoc}
`
export type RetractPayAppMutationFn = Apollo.MutationFunction<
  RetractPayAppMutation,
  RetractPayAppMutationVariables
>

/**
 * __useRetractPayAppMutation__
 *
 * To run a mutation, you first call `useRetractPayAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetractPayAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retractPayAppMutation, { data, loading, error }] = useRetractPayAppMutation({
 *   variables: {
 *      payAppId: // value for 'payAppId'
 *   },
 * });
 */
export function useRetractPayAppMutation(
  baseOptions?: Apollo.MutationHookOptions<RetractPayAppMutation, RetractPayAppMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RetractPayAppMutation, RetractPayAppMutationVariables>(
    RetractPayAppDocument,
    options
  )
}
export type RetractPayAppMutationHookResult = ReturnType<typeof useRetractPayAppMutation>
export type RetractPayAppMutationResult = Apollo.MutationResult<RetractPayAppMutation>
export type RetractPayAppMutationOptions = Apollo.BaseMutationOptions<
  RetractPayAppMutation,
  RetractPayAppMutationVariables
>
export const SetPayAppSyncedDocument = gql`
  mutation setPayAppSynced($input: SetPayAppSyncedInput!) {
    setPayAppSynced(input: $input) {
      ...PayAppProperties
    }
  }
  ${PayAppPropertiesDoc}
`
export type SetPayAppSyncedMutationFn = Apollo.MutationFunction<
  SetPayAppSyncedMutation,
  SetPayAppSyncedMutationVariables
>

/**
 * __useSetPayAppSyncedMutation__
 *
 * To run a mutation, you first call `useSetPayAppSyncedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPayAppSyncedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPayAppSyncedMutation, { data, loading, error }] = useSetPayAppSyncedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetPayAppSyncedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetPayAppSyncedMutation,
    SetPayAppSyncedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SetPayAppSyncedMutation, SetPayAppSyncedMutationVariables>(
    SetPayAppSyncedDocument,
    options
  )
}
export type SetPayAppSyncedMutationHookResult = ReturnType<typeof useSetPayAppSyncedMutation>
export type SetPayAppSyncedMutationResult = Apollo.MutationResult<SetPayAppSyncedMutation>
export type SetPayAppSyncedMutationOptions = Apollo.BaseMutationOptions<
  SetPayAppSyncedMutation,
  SetPayAppSyncedMutationVariables
>
export const DeletePayAppDocument = gql`
  mutation deletePayApp($payAppId: ID!) {
    deletePayApp(payAppId: $payAppId) {
      id
    }
  }
`
export type DeletePayAppMutationFn = Apollo.MutationFunction<
  DeletePayAppMutation,
  DeletePayAppMutationVariables
>

/**
 * __useDeletePayAppMutation__
 *
 * To run a mutation, you first call `useDeletePayAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePayAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePayAppMutation, { data, loading, error }] = useDeletePayAppMutation({
 *   variables: {
 *      payAppId: // value for 'payAppId'
 *   },
 * });
 */
export function useDeletePayAppMutation(
  baseOptions?: Apollo.MutationHookOptions<DeletePayAppMutation, DeletePayAppMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeletePayAppMutation, DeletePayAppMutationVariables>(
    DeletePayAppDocument,
    options
  )
}
export type DeletePayAppMutationHookResult = ReturnType<typeof useDeletePayAppMutation>
export type DeletePayAppMutationResult = Apollo.MutationResult<DeletePayAppMutation>
export type DeletePayAppMutationOptions = Apollo.BaseMutationOptions<
  DeletePayAppMutation,
  DeletePayAppMutationVariables
>
export const SetPayAppProposedDocument = gql`
  mutation setPayAppProposed($input: SetPayAppProposedInput!) {
    setPayAppProposed(input: $input) {
      ...PayAppProperties
    }
  }
  ${PayAppPropertiesDoc}
`
export type SetPayAppProposedMutationFn = Apollo.MutationFunction<
  SetPayAppProposedMutation,
  SetPayAppProposedMutationVariables
>

/**
 * __useSetPayAppProposedMutation__
 *
 * To run a mutation, you first call `useSetPayAppProposedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPayAppProposedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPayAppProposedMutation, { data, loading, error }] = useSetPayAppProposedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetPayAppProposedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetPayAppProposedMutation,
    SetPayAppProposedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SetPayAppProposedMutation, SetPayAppProposedMutationVariables>(
    SetPayAppProposedDocument,
    options
  )
}
export type SetPayAppProposedMutationHookResult = ReturnType<typeof useSetPayAppProposedMutation>
export type SetPayAppProposedMutationResult = Apollo.MutationResult<SetPayAppProposedMutation>
export type SetPayAppProposedMutationOptions = Apollo.BaseMutationOptions<
  SetPayAppProposedMutation,
  SetPayAppProposedMutationVariables
>
export const UpdatePayAppDocument = gql`
  mutation updatePayApp($input: UpdatePayAppInput!) {
    updatePayApp(input: $input) {
      ...PayAppProperties
      contract {
        id
        project {
          id
          name
          generalContractor {
            company {
              ...CompanyProperties
            }
          }
        }
      }
    }
  }
  ${PayAppPropertiesDoc}
  ${CompanyPropertiesDoc}
`
export type UpdatePayAppMutationFn = Apollo.MutationFunction<
  UpdatePayAppMutation,
  UpdatePayAppMutationVariables
>

/**
 * __useUpdatePayAppMutation__
 *
 * To run a mutation, you first call `useUpdatePayAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePayAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePayAppMutation, { data, loading, error }] = useUpdatePayAppMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePayAppMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePayAppMutation, UpdatePayAppMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdatePayAppMutation, UpdatePayAppMutationVariables>(
    UpdatePayAppDocument,
    options
  )
}
export type UpdatePayAppMutationHookResult = ReturnType<typeof useUpdatePayAppMutation>
export type UpdatePayAppMutationResult = Apollo.MutationResult<UpdatePayAppMutation>
export type UpdatePayAppMutationOptions = Apollo.BaseMutationOptions<
  UpdatePayAppMutation,
  UpdatePayAppMutationVariables
>
export const ContractsForProcessingDocument = gql`
  query contractsForProcessing($input: GetPaginatedContractsInput!) {
    paginatedContracts(input: $input) {
      hasNext
      cursor
      totalCount
      contracts {
        id
        internalProjectNumber
        project {
          id
          projectNumber
          name
        }
        company {
          id
          name
        }
        onboardedStatus {
          selectedPayAppForms
          onboardedPayAppForms
          selectedPrimaryLienWaivers
          onboardedPrimaryLienWaiverForms
          selectedVendorLienWaivers
          onboardedVendorLienWaiverForms
          selectedChangeOrderRequestForms
          onboardedChangeOrderRequestForms
          selectedChangeOrderLogForms
          onboardedChangeOrderLogForms
        }
      }
    }
  }
`

/**
 * __useContractsForProcessingQuery__
 *
 * To run a query within a React component, call `useContractsForProcessingQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractsForProcessingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractsForProcessingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContractsForProcessingQuery(
  baseOptions: Apollo.QueryHookOptions<
    ContractsForProcessingQuery,
    ContractsForProcessingQueryVariables
  > &
    ({ variables: ContractsForProcessingQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ContractsForProcessingQuery, ContractsForProcessingQueryVariables>(
    ContractsForProcessingDocument,
    options
  )
}
export function useContractsForProcessingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContractsForProcessingQuery,
    ContractsForProcessingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ContractsForProcessingQuery, ContractsForProcessingQueryVariables>(
    ContractsForProcessingDocument,
    options
  )
}
export function useContractsForProcessingSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ContractsForProcessingQuery,
        ContractsForProcessingQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ContractsForProcessingQuery, ContractsForProcessingQueryVariables>(
    ContractsForProcessingDocument,
    options
  )
}
export type ContractsForProcessingQueryHookResult = ReturnType<
  typeof useContractsForProcessingQuery
>
export type ContractsForProcessingLazyQueryHookResult = ReturnType<
  typeof useContractsForProcessingLazyQuery
>
export type ContractsForProcessingSuspenseQueryHookResult = ReturnType<
  typeof useContractsForProcessingSuspenseQuery
>
export type ContractsForProcessingQueryResult = Apollo.QueryResult<
  ContractsForProcessingQuery,
  ContractsForProcessingQueryVariables
>
export const GetProjectForDetailsDocument = gql`
  query getProjectForDetails($projectId: ID!) {
    project(id: $projectId) {
      ...ProjectForDetailsProperties
    }
  }
  ${ProjectForDetailsPropertiesDoc}
`

/**
 * __useGetProjectForDetailsQuery__
 *
 * To run a query within a React component, call `useGetProjectForDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectForDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectForDetailsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectForDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectForDetailsQuery,
    GetProjectForDetailsQueryVariables
  > &
    ({ variables: GetProjectForDetailsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetProjectForDetailsQuery, GetProjectForDetailsQueryVariables>(
    GetProjectForDetailsDocument,
    options
  )
}
export function useGetProjectForDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectForDetailsQuery,
    GetProjectForDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetProjectForDetailsQuery, GetProjectForDetailsQueryVariables>(
    GetProjectForDetailsDocument,
    options
  )
}
export function useGetProjectForDetailsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetProjectForDetailsQuery, GetProjectForDetailsQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetProjectForDetailsQuery, GetProjectForDetailsQueryVariables>(
    GetProjectForDetailsDocument,
    options
  )
}
export type GetProjectForDetailsQueryHookResult = ReturnType<typeof useGetProjectForDetailsQuery>
export type GetProjectForDetailsLazyQueryHookResult = ReturnType<
  typeof useGetProjectForDetailsLazyQuery
>
export type GetProjectForDetailsSuspenseQueryHookResult = ReturnType<
  typeof useGetProjectForDetailsSuspenseQuery
>
export type GetProjectForDetailsQueryResult = Apollo.QueryResult<
  GetProjectForDetailsQuery,
  GetProjectForDetailsQueryVariables
>
export const UpdateProjectDocument = gql`
  mutation updateProject($input: UpdateProjectInput!) {
    updateProject(input: $input) {
      ...ProjectForDetailsProperties
    }
  }
  ${ProjectForDetailsPropertiesDoc}
`
export type UpdateProjectMutationFn = Apollo.MutationFunction<
  UpdateProjectMutation,
  UpdateProjectMutationVariables
>

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateProjectMutation, UpdateProjectMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(
    UpdateProjectDocument,
    options
  )
}
export type UpdateProjectMutationHookResult = ReturnType<typeof useUpdateProjectMutation>
export type UpdateProjectMutationResult = Apollo.MutationResult<UpdateProjectMutation>
export type UpdateProjectMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectMutation,
  UpdateProjectMutationVariables
>
export const DeleteProjectDocument = gql`
  mutation deleteProject($id: ID!) {
    deleteProject(id: $id) {
      id
    }
  }
`
export type DeleteProjectMutationFn = Apollo.MutationFunction<
  DeleteProjectMutation,
  DeleteProjectMutationVariables
>

/**
 * __useDeleteProjectMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMutation, { data, loading, error }] = useDeleteProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteProjectMutation, DeleteProjectMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteProjectMutation, DeleteProjectMutationVariables>(
    DeleteProjectDocument,
    options
  )
}
export type DeleteProjectMutationHookResult = ReturnType<typeof useDeleteProjectMutation>
export type DeleteProjectMutationResult = Apollo.MutationResult<DeleteProjectMutation>
export type DeleteProjectMutationOptions = Apollo.BaseMutationOptions<
  DeleteProjectMutation,
  DeleteProjectMutationVariables
>
export const AllProjectUsersByCompanyDocument = gql`
  query allProjectUsersByCompany($id: ID!) {
    allProjectUsersByCompany(id: $id) {
      ...CompanyUserProperties
      user {
        ...UserProperties
        blockedEmails
      }
    }
  }
  ${CompanyUserPropertiesDoc}
  ${UserPropertiesDoc}
`

/**
 * __useAllProjectUsersByCompanyQuery__
 *
 * To run a query within a React component, call `useAllProjectUsersByCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllProjectUsersByCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllProjectUsersByCompanyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAllProjectUsersByCompanyQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllProjectUsersByCompanyQuery,
    AllProjectUsersByCompanyQueryVariables
  > &
    ({ variables: AllProjectUsersByCompanyQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AllProjectUsersByCompanyQuery, AllProjectUsersByCompanyQueryVariables>(
    AllProjectUsersByCompanyDocument,
    options
  )
}
export function useAllProjectUsersByCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllProjectUsersByCompanyQuery,
    AllProjectUsersByCompanyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AllProjectUsersByCompanyQuery, AllProjectUsersByCompanyQueryVariables>(
    AllProjectUsersByCompanyDocument,
    options
  )
}
export function useAllProjectUsersByCompanySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AllProjectUsersByCompanyQuery,
        AllProjectUsersByCompanyQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    AllProjectUsersByCompanyQuery,
    AllProjectUsersByCompanyQueryVariables
  >(AllProjectUsersByCompanyDocument, options)
}
export type AllProjectUsersByCompanyQueryHookResult = ReturnType<
  typeof useAllProjectUsersByCompanyQuery
>
export type AllProjectUsersByCompanyLazyQueryHookResult = ReturnType<
  typeof useAllProjectUsersByCompanyLazyQuery
>
export type AllProjectUsersByCompanySuspenseQueryHookResult = ReturnType<
  typeof useAllProjectUsersByCompanySuspenseQuery
>
export type AllProjectUsersByCompanyQueryResult = Apollo.QueryResult<
  AllProjectUsersByCompanyQuery,
  AllProjectUsersByCompanyQueryVariables
>
export const SendCompanyMonthlyReportTestEmailDocument = gql`
  mutation sendCompanyMonthlyReportTestEmail($input: SendCompanyMonthlyReportTestEmailInput!) {
    sendCompanyMonthlyReportTestEmail(input: $input) {
      id
      email
    }
  }
`
export type SendCompanyMonthlyReportTestEmailMutationFn = Apollo.MutationFunction<
  SendCompanyMonthlyReportTestEmailMutation,
  SendCompanyMonthlyReportTestEmailMutationVariables
>

/**
 * __useSendCompanyMonthlyReportTestEmailMutation__
 *
 * To run a mutation, you first call `useSendCompanyMonthlyReportTestEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendCompanyMonthlyReportTestEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendCompanyMonthlyReportTestEmailMutation, { data, loading, error }] = useSendCompanyMonthlyReportTestEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendCompanyMonthlyReportTestEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendCompanyMonthlyReportTestEmailMutation,
    SendCompanyMonthlyReportTestEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SendCompanyMonthlyReportTestEmailMutation,
    SendCompanyMonthlyReportTestEmailMutationVariables
  >(SendCompanyMonthlyReportTestEmailDocument, options)
}
export type SendCompanyMonthlyReportTestEmailMutationHookResult = ReturnType<
  typeof useSendCompanyMonthlyReportTestEmailMutation
>
export type SendCompanyMonthlyReportTestEmailMutationResult =
  Apollo.MutationResult<SendCompanyMonthlyReportTestEmailMutation>
export type SendCompanyMonthlyReportTestEmailMutationOptions = Apollo.BaseMutationOptions<
  SendCompanyMonthlyReportTestEmailMutation,
  SendCompanyMonthlyReportTestEmailMutationVariables
>
export const UserEducationCampaignDocument = gql`
  query userEducationCampaign($id: ID!) {
    userEducationCampaign(id: $id) {
      ...UserEducationCampaignProperties
    }
  }
  ${UserEducationCampaignPropertiesDoc}
`

/**
 * __useUserEducationCampaignQuery__
 *
 * To run a query within a React component, call `useUserEducationCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserEducationCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserEducationCampaignQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserEducationCampaignQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserEducationCampaignQuery,
    UserEducationCampaignQueryVariables
  > &
    ({ variables: UserEducationCampaignQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserEducationCampaignQuery, UserEducationCampaignQueryVariables>(
    UserEducationCampaignDocument,
    options
  )
}
export function useUserEducationCampaignLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserEducationCampaignQuery,
    UserEducationCampaignQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserEducationCampaignQuery, UserEducationCampaignQueryVariables>(
    UserEducationCampaignDocument,
    options
  )
}
export function useUserEducationCampaignSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        UserEducationCampaignQuery,
        UserEducationCampaignQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<UserEducationCampaignQuery, UserEducationCampaignQueryVariables>(
    UserEducationCampaignDocument,
    options
  )
}
export type UserEducationCampaignQueryHookResult = ReturnType<typeof useUserEducationCampaignQuery>
export type UserEducationCampaignLazyQueryHookResult = ReturnType<
  typeof useUserEducationCampaignLazyQuery
>
export type UserEducationCampaignSuspenseQueryHookResult = ReturnType<
  typeof useUserEducationCampaignSuspenseQuery
>
export type UserEducationCampaignQueryResult = Apollo.QueryResult<
  UserEducationCampaignQuery,
  UserEducationCampaignQueryVariables
>
export const CreateUserEducationCampaignDocument = gql`
  mutation createUserEducationCampaign($input: CreateUserEducationCampaignInput!) {
    createUserEducationCampaign(input: $input) {
      ...UserEducationCampaignProperties
    }
  }
  ${UserEducationCampaignPropertiesDoc}
`
export type CreateUserEducationCampaignMutationFn = Apollo.MutationFunction<
  CreateUserEducationCampaignMutation,
  CreateUserEducationCampaignMutationVariables
>

/**
 * __useCreateUserEducationCampaignMutation__
 *
 * To run a mutation, you first call `useCreateUserEducationCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserEducationCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserEducationCampaignMutation, { data, loading, error }] = useCreateUserEducationCampaignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserEducationCampaignMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserEducationCampaignMutation,
    CreateUserEducationCampaignMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateUserEducationCampaignMutation,
    CreateUserEducationCampaignMutationVariables
  >(CreateUserEducationCampaignDocument, options)
}
export type CreateUserEducationCampaignMutationHookResult = ReturnType<
  typeof useCreateUserEducationCampaignMutation
>
export type CreateUserEducationCampaignMutationResult =
  Apollo.MutationResult<CreateUserEducationCampaignMutation>
export type CreateUserEducationCampaignMutationOptions = Apollo.BaseMutationOptions<
  CreateUserEducationCampaignMutation,
  CreateUserEducationCampaignMutationVariables
>
export const UpdateUserEducationCampaignDocument = gql`
  mutation updateUserEducationCampaign($input: UpdateUserEducationCampaignInput!) {
    updateUserEducationCampaign(input: $input) {
      ...UserEducationCampaignProperties
    }
  }
  ${UserEducationCampaignPropertiesDoc}
`
export type UpdateUserEducationCampaignMutationFn = Apollo.MutationFunction<
  UpdateUserEducationCampaignMutation,
  UpdateUserEducationCampaignMutationVariables
>

/**
 * __useUpdateUserEducationCampaignMutation__
 *
 * To run a mutation, you first call `useUpdateUserEducationCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserEducationCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserEducationCampaignMutation, { data, loading, error }] = useUpdateUserEducationCampaignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserEducationCampaignMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserEducationCampaignMutation,
    UpdateUserEducationCampaignMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateUserEducationCampaignMutation,
    UpdateUserEducationCampaignMutationVariables
  >(UpdateUserEducationCampaignDocument, options)
}
export type UpdateUserEducationCampaignMutationHookResult = ReturnType<
  typeof useUpdateUserEducationCampaignMutation
>
export type UpdateUserEducationCampaignMutationResult =
  Apollo.MutationResult<UpdateUserEducationCampaignMutation>
export type UpdateUserEducationCampaignMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserEducationCampaignMutation,
  UpdateUserEducationCampaignMutationVariables
>
export const UserEducationCampaignsDocument = gql`
  query userEducationCampaigns {
    userEducationCampaigns {
      ...UserEducationCampaignProperties
    }
  }
  ${UserEducationCampaignPropertiesDoc}
`

/**
 * __useUserEducationCampaignsQuery__
 *
 * To run a query within a React component, call `useUserEducationCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserEducationCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserEducationCampaignsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserEducationCampaignsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserEducationCampaignsQuery,
    UserEducationCampaignsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserEducationCampaignsQuery, UserEducationCampaignsQueryVariables>(
    UserEducationCampaignsDocument,
    options
  )
}
export function useUserEducationCampaignsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserEducationCampaignsQuery,
    UserEducationCampaignsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserEducationCampaignsQuery, UserEducationCampaignsQueryVariables>(
    UserEducationCampaignsDocument,
    options
  )
}
export function useUserEducationCampaignsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        UserEducationCampaignsQuery,
        UserEducationCampaignsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<UserEducationCampaignsQuery, UserEducationCampaignsQueryVariables>(
    UserEducationCampaignsDocument,
    options
  )
}
export type UserEducationCampaignsQueryHookResult = ReturnType<
  typeof useUserEducationCampaignsQuery
>
export type UserEducationCampaignsLazyQueryHookResult = ReturnType<
  typeof useUserEducationCampaignsLazyQuery
>
export type UserEducationCampaignsSuspenseQueryHookResult = ReturnType<
  typeof useUserEducationCampaignsSuspenseQuery
>
export type UserEducationCampaignsQueryResult = Apollo.QueryResult<
  UserEducationCampaignsQuery,
  UserEducationCampaignsQueryVariables
>
export const GetUserForDetailsDocument = gql`
  query getUserForDetails($userId: ID!) {
    user(id: $userId) {
      ...UserProperties
      companyUsers {
        id
        status
        role
        company {
          ...CompanyProperties
        }
      }
    }
  }
  ${UserPropertiesDoc}
  ${CompanyPropertiesDoc}
`

/**
 * __useGetUserForDetailsQuery__
 *
 * To run a query within a React component, call `useGetUserForDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserForDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserForDetailsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserForDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserForDetailsQuery, GetUserForDetailsQueryVariables> &
    ({ variables: GetUserForDetailsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetUserForDetailsQuery, GetUserForDetailsQueryVariables>(
    GetUserForDetailsDocument,
    options
  )
}
export function useGetUserForDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserForDetailsQuery, GetUserForDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetUserForDetailsQuery, GetUserForDetailsQueryVariables>(
    GetUserForDetailsDocument,
    options
  )
}
export function useGetUserForDetailsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetUserForDetailsQuery, GetUserForDetailsQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetUserForDetailsQuery, GetUserForDetailsQueryVariables>(
    GetUserForDetailsDocument,
    options
  )
}
export type GetUserForDetailsQueryHookResult = ReturnType<typeof useGetUserForDetailsQuery>
export type GetUserForDetailsLazyQueryHookResult = ReturnType<typeof useGetUserForDetailsLazyQuery>
export type GetUserForDetailsSuspenseQueryHookResult = ReturnType<
  typeof useGetUserForDetailsSuspenseQuery
>
export type GetUserForDetailsQueryResult = Apollo.QueryResult<
  GetUserForDetailsQuery,
  GetUserForDetailsQueryVariables
>
export const ModifyNotificationPreferencesDocument = gql`
  mutation modifyNotificationPreferences($input: ModifyNotificationPreferencesInput!) {
    modifyNotificationPreferences(input: $input) {
      ...UserProperties
    }
  }
  ${UserPropertiesDoc}
`
export type ModifyNotificationPreferencesMutationFn = Apollo.MutationFunction<
  ModifyNotificationPreferencesMutation,
  ModifyNotificationPreferencesMutationVariables
>

/**
 * __useModifyNotificationPreferencesMutation__
 *
 * To run a mutation, you first call `useModifyNotificationPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyNotificationPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyNotificationPreferencesMutation, { data, loading, error }] = useModifyNotificationPreferencesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyNotificationPreferencesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ModifyNotificationPreferencesMutation,
    ModifyNotificationPreferencesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ModifyNotificationPreferencesMutation,
    ModifyNotificationPreferencesMutationVariables
  >(ModifyNotificationPreferencesDocument, options)
}
export type ModifyNotificationPreferencesMutationHookResult = ReturnType<
  typeof useModifyNotificationPreferencesMutation
>
export type ModifyNotificationPreferencesMutationResult =
  Apollo.MutationResult<ModifyNotificationPreferencesMutation>
export type ModifyNotificationPreferencesMutationOptions = Apollo.BaseMutationOptions<
  ModifyNotificationPreferencesMutation,
  ModifyNotificationPreferencesMutationVariables
>
export const ModifyEmailPreferencesDocument = gql`
  mutation modifyEmailPreferences($input: ModifyEmailPreferencesInput!) {
    modifyEmailPreferences(input: $input) {
      ...UserProperties
    }
  }
  ${UserPropertiesDoc}
`
export type ModifyEmailPreferencesMutationFn = Apollo.MutationFunction<
  ModifyEmailPreferencesMutation,
  ModifyEmailPreferencesMutationVariables
>

/**
 * __useModifyEmailPreferencesMutation__
 *
 * To run a mutation, you first call `useModifyEmailPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyEmailPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyEmailPreferencesMutation, { data, loading, error }] = useModifyEmailPreferencesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyEmailPreferencesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ModifyEmailPreferencesMutation,
    ModifyEmailPreferencesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ModifyEmailPreferencesMutation,
    ModifyEmailPreferencesMutationVariables
  >(ModifyEmailPreferencesDocument, options)
}
export type ModifyEmailPreferencesMutationHookResult = ReturnType<
  typeof useModifyEmailPreferencesMutation
>
export type ModifyEmailPreferencesMutationResult =
  Apollo.MutationResult<ModifyEmailPreferencesMutation>
export type ModifyEmailPreferencesMutationOptions = Apollo.BaseMutationOptions<
  ModifyEmailPreferencesMutation,
  ModifyEmailPreferencesMutationVariables
>
export const ResendUserInviteDocument = gql`
  mutation resendUserInvite($id: ID!) {
    resendUserInvite(id: $id) {
      id
    }
  }
`
export type ResendUserInviteMutationFn = Apollo.MutationFunction<
  ResendUserInviteMutation,
  ResendUserInviteMutationVariables
>

/**
 * __useResendUserInviteMutation__
 *
 * To run a mutation, you first call `useResendUserInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendUserInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendUserInviteMutation, { data, loading, error }] = useResendUserInviteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResendUserInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendUserInviteMutation,
    ResendUserInviteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ResendUserInviteMutation, ResendUserInviteMutationVariables>(
    ResendUserInviteDocument,
    options
  )
}
export type ResendUserInviteMutationHookResult = ReturnType<typeof useResendUserInviteMutation>
export type ResendUserInviteMutationResult = Apollo.MutationResult<ResendUserInviteMutation>
export type ResendUserInviteMutationOptions = Apollo.BaseMutationOptions<
  ResendUserInviteMutation,
  ResendUserInviteMutationVariables
>
export const ProjectsByUserDocument = gql`
  query projectsByUser($id: ID!) {
    projectsByUser(id: $id) {
      ...ProjectProperties
    }
  }
  ${ProjectPropertiesDoc}
`

/**
 * __useProjectsByUserQuery__
 *
 * To run a query within a React component, call `useProjectsByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsByUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectsByUserQuery(
  baseOptions: Apollo.QueryHookOptions<ProjectsByUserQuery, ProjectsByUserQueryVariables> &
    ({ variables: ProjectsByUserQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ProjectsByUserQuery, ProjectsByUserQueryVariables>(
    ProjectsByUserDocument,
    options
  )
}
export function useProjectsByUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProjectsByUserQuery, ProjectsByUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ProjectsByUserQuery, ProjectsByUserQueryVariables>(
    ProjectsByUserDocument,
    options
  )
}
export function useProjectsByUserSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ProjectsByUserQuery, ProjectsByUserQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ProjectsByUserQuery, ProjectsByUserQueryVariables>(
    ProjectsByUserDocument,
    options
  )
}
export type ProjectsByUserQueryHookResult = ReturnType<typeof useProjectsByUserQuery>
export type ProjectsByUserLazyQueryHookResult = ReturnType<typeof useProjectsByUserLazyQuery>
export type ProjectsByUserSuspenseQueryHookResult = ReturnType<
  typeof useProjectsByUserSuspenseQuery
>
export type ProjectsByUserQueryResult = Apollo.QueryResult<
  ProjectsByUserQuery,
  ProjectsByUserQueryVariables
>
export const MarkWriteSyncAsFailedDocument = gql`
  mutation markWriteSyncAsFailed($input: MarkWriteSyncAsFailedInput!) {
    markWriteSyncAsFailed(input: $input) {
      id
      updatedAt
      status
      result
    }
  }
`
export type MarkWriteSyncAsFailedMutationFn = Apollo.MutationFunction<
  MarkWriteSyncAsFailedMutation,
  MarkWriteSyncAsFailedMutationVariables
>

/**
 * __useMarkWriteSyncAsFailedMutation__
 *
 * To run a mutation, you first call `useMarkWriteSyncAsFailedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkWriteSyncAsFailedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markWriteSyncAsFailedMutation, { data, loading, error }] = useMarkWriteSyncAsFailedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkWriteSyncAsFailedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkWriteSyncAsFailedMutation,
    MarkWriteSyncAsFailedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<MarkWriteSyncAsFailedMutation, MarkWriteSyncAsFailedMutationVariables>(
    MarkWriteSyncAsFailedDocument,
    options
  )
}
export type MarkWriteSyncAsFailedMutationHookResult = ReturnType<
  typeof useMarkWriteSyncAsFailedMutation
>
export type MarkWriteSyncAsFailedMutationResult =
  Apollo.MutationResult<MarkWriteSyncAsFailedMutation>
export type MarkWriteSyncAsFailedMutationOptions = Apollo.BaseMutationOptions<
  MarkWriteSyncAsFailedMutation,
  MarkWriteSyncAsFailedMutationVariables
>
export const PaginatedWriteSyncOperationsDocument = gql`
  query paginatedWriteSyncOperations($input: GetPaginatedWriteSyncOperationsInput!) {
    paginatedWriteSyncOperations(input: $input) {
      hasNext
      cursor
      totalCount
      operations {
        id
        createdAt
        updatedAt
        status
        payload
        result
        createdBy {
          id
          email
          firstName
        }
        integration {
          id
          type
          contract {
            id
            internalProjectNumber
            project {
              id
              name
              projectNumber
            }
            company {
              id
              name
            }
          }
        }
      }
    }
  }
`

/**
 * __usePaginatedWriteSyncOperationsQuery__
 *
 * To run a query within a React component, call `usePaginatedWriteSyncOperationsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedWriteSyncOperationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedWriteSyncOperationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePaginatedWriteSyncOperationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    PaginatedWriteSyncOperationsQuery,
    PaginatedWriteSyncOperationsQueryVariables
  > &
    ({ variables: PaginatedWriteSyncOperationsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    PaginatedWriteSyncOperationsQuery,
    PaginatedWriteSyncOperationsQueryVariables
  >(PaginatedWriteSyncOperationsDocument, options)
}
export function usePaginatedWriteSyncOperationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaginatedWriteSyncOperationsQuery,
    PaginatedWriteSyncOperationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    PaginatedWriteSyncOperationsQuery,
    PaginatedWriteSyncOperationsQueryVariables
  >(PaginatedWriteSyncOperationsDocument, options)
}
export function usePaginatedWriteSyncOperationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PaginatedWriteSyncOperationsQuery,
        PaginatedWriteSyncOperationsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    PaginatedWriteSyncOperationsQuery,
    PaginatedWriteSyncOperationsQueryVariables
  >(PaginatedWriteSyncOperationsDocument, options)
}
export type PaginatedWriteSyncOperationsQueryHookResult = ReturnType<
  typeof usePaginatedWriteSyncOperationsQuery
>
export type PaginatedWriteSyncOperationsLazyQueryHookResult = ReturnType<
  typeof usePaginatedWriteSyncOperationsLazyQuery
>
export type PaginatedWriteSyncOperationsSuspenseQueryHookResult = ReturnType<
  typeof usePaginatedWriteSyncOperationsSuspenseQuery
>
export type PaginatedWriteSyncOperationsQueryResult = Apollo.QueryResult<
  PaginatedWriteSyncOperationsQuery,
  PaginatedWriteSyncOperationsQueryVariables
>
export const RequeueWriteSyncDocument = gql`
  mutation requeueWriteSync($input: RequeueWriteSyncInput!) {
    requeueWriteSync(input: $input) {
      id
      updatedAt
      status
      result
    }
  }
`
export type RequeueWriteSyncMutationFn = Apollo.MutationFunction<
  RequeueWriteSyncMutation,
  RequeueWriteSyncMutationVariables
>

/**
 * __useRequeueWriteSyncMutation__
 *
 * To run a mutation, you first call `useRequeueWriteSyncMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequeueWriteSyncMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requeueWriteSyncMutation, { data, loading, error }] = useRequeueWriteSyncMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequeueWriteSyncMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequeueWriteSyncMutation,
    RequeueWriteSyncMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RequeueWriteSyncMutation, RequeueWriteSyncMutationVariables>(
    RequeueWriteSyncDocument,
    options
  )
}
export type RequeueWriteSyncMutationHookResult = ReturnType<typeof useRequeueWriteSyncMutation>
export type RequeueWriteSyncMutationResult = Apollo.MutationResult<RequeueWriteSyncMutation>
export type RequeueWriteSyncMutationOptions = Apollo.BaseMutationOptions<
  RequeueWriteSyncMutation,
  RequeueWriteSyncMutationVariables
>
