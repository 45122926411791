import _ from 'lodash'
import { FormTemplateType } from '../enums.js'
import { templateVariablesDocumentation } from './documentation.js'

/**
 * Recursively maps our template variables to a flat array of keys
 * Example in:
 *    {
 *      "billingStart": "The start of the billing period",
 *      "project": {
 *        "name": "The name of the project",
 *        "number": "The general contractor's number for this project"
 *      },
 *      "sov": {
 *        "lineItems": [
 *          { code: "0001" }
 *        ]
 *      }
 *    }
 * Example out:
 *    [
 *      "billingStart",
 *      "project.name",
 *      "project.number",
 *      "sov.lineItems[0].code"
 *    ]
 */
function templateVariablesToKeys(values: Record<string, unknown>, keyBase = ''): string[] {
  return _.chain(values)
    .keys()
    .map((currentKey) => {
      const value = values[currentKey]
      const key = keyBase ? [keyBase, currentKey].join('.') : currentKey

      // If value is a plain object, flatten its child keys
      if (_.isPlainObject(value)) {
        return templateVariablesToKeys(value as Record<string, unknown>, key)

        // If value is an array, index must be passed explicitly and defaults to 0
      } else if (_.isArray(value)) {
        return templateVariablesToKeys((value as Record<string, unknown>[])[0], `${key}[0]`)
      }

      // In other cases, we can use the key directly
      return key
    })
    .flatten()
    .sort((a, b) => {
      // The reason we sort by parts is so that the <Autocomplete> element groups them correctly.
      const aDepth = a.split('.').length
      const bDepth = b.split('.').length
      if (aDepth === bDepth) {
        return a > b ? 1 : -1
      } else {
        return aDepth > bDepth ? 1 : -1
      }
    })
    .value()
}

/**
 * Returns all template variable keys.
 * Usage: getTemplateVariablesKeys(templateVariablesDocumentation.payAppLumpSum)
 */
export function getTemplateVariablesKeys(
  documentation: (typeof templateVariablesDocumentation)[keyof typeof templateVariablesDocumentation]
): string[] {
  return templateVariablesToKeys(documentation)
}

export function getTemplateVariablesKeysForTemplateType(templateType: FormTemplateType): string[] {
  switch (templateType) {
    case FormTemplateType.PAY_APP_LUMP_SUM:
      return getTemplateVariablesKeys(templateVariablesDocumentation.payAppLumpSum)
    case FormTemplateType.PAY_APP_QUICK:
      return getTemplateVariablesKeys(templateVariablesDocumentation.payAppQuick)
    case FormTemplateType.PAY_APP_UNIT_PRICE:
      return getTemplateVariablesKeys(templateVariablesDocumentation.payAppUnitPrice)
    case FormTemplateType.PAY_APP_TIME_AND_MATERIALS:
      return getTemplateVariablesKeys(templateVariablesDocumentation.payAppTimeAndMaterials)
    case FormTemplateType.CHANGE_ORDER_REQUEST:
      return getTemplateVariablesKeys(templateVariablesDocumentation.changeOrderRequest)
    case FormTemplateType.CHANGE_ORDER_LOG:
      return getTemplateVariablesKeys(templateVariablesDocumentation.changeOrderLog)
    case FormTemplateType.LEGAL_DOCUMENT:
      return getTemplateVariablesKeys(templateVariablesDocumentation.legalRequirement)
    case FormTemplateType.LIEN_WAIVER:
      return getTemplateVariablesKeys(templateVariablesDocumentation.lienWaiver)
  }
}

/**
 * Returns the value of a template variable given a key.
 * Because template variables can be boolean (eg: isPositive), we need to handle them properly.
 * Returns undefined if the key doesn't exist or doesn't resolve to a leaf (for instance `sov` is an object).
 */
export function getTemplateVariableValue(
  templateVariables: Record<string, unknown>,
  key: string
): string | undefined {
  const defaultValue = _.get(templateVariables, key)
  if (defaultValue === true) {
    return 'Yes'
  }
  if (defaultValue === false) {
    return 'No'
  }
  if (_.isString(defaultValue)) {
    return defaultValue
  }
  return undefined
}

function pickFunc<T extends object, K extends keyof T>(
  obj: T,
  predicate: (k: string) => boolean
): Pick<T, K> {
  const emptyObject = {} as Pick<T, K>
  return Object.keys(obj)
    .filter(predicate)
    .reduce(
      (filteredObj: Pick<T, K>, key) => ({
        ...filteredObj,
        [key]: obj[key as keyof T],
      }),
      emptyObject
    )
}

/**
 * Typesafe version of lodash's _.pick function.
 * Adapted from https://gist.github.com/supermacro/b6464ce192fa298b2329090669a0c57b
 */
export const pick = <T extends object, K extends keyof T>(obj: T, keys: Array<K>): Pick<T, K> =>
  pickFunc(obj, (k) => keys.includes(k as K))
