export const OPT_OUT_FORMAT = 'MM-YYYY'
export const DAY_FORMAT = 'YYYY-MM-DD'
export const MONTH_FORMAT = 'YYYY-MM'
export const MONTH_NAME_FORMAT = 'MMMM'
export const HOUR_MINUTE_TZ_FORMAT = 'h:mma z'

export interface MonthAndYear {
  month: number
  year: number
}

/**
 * Given a zero-indexed month, subtract a number of months. Return the resulting month and year
 * combo.
 * @param month Given month
 * @param year Given year
 * @param monthsToSubtract How many months to subtract
 */
export function subtractFromMonth(
  monthAndYear: MonthAndYear,
  monthsToSubtract: number
): MonthAndYear {
  let newMonth = monthAndYear.month - monthsToSubtract
  let newYear = monthAndYear.year
  while (newMonth < 0) {
    newYear -= 1
    newMonth += 12
  }
  return { month: newMonth, year: newYear }
}
